import { useMutation } from '@apollo/client';
import {
  Tooltip,
  Body,
  Box,
  IconButton,
  Tr,
  Td,
  Toggle,
  LoadingOverlay,
} from '@hover/blueprint';
import { iEdit, iTrash, iCopy } from '@hover/icons';
import { useHistory } from 'react-router-dom';

import { InspectionConfigChecklistTemplateStateEnum as TemplateStates } from 'src/api/graphql-global-types';
import { inspectionConfigChecklistTemplate_inspectionConfigChecklistTemplate as ChecklistTemplateType } from 'src/api/types/inspectionConfigChecklistTemplate';
import {
  UPDATE_TEMPLATE,
  COPY_TEMPLATE,
} from 'src/features/settings/api/mutations/inspectionChecklist';
import type { CurrentTemplate } from 'src/features/settings/components/ChecklistTemplates/InspectionTable';
import { useTracking } from 'src/hooks';
import { EventNames } from 'src/types/actionTypes';

interface InspectionRowProps {
  template: ChecklistTemplateType;
  showConfirmDeleteModal: (template: CurrentTemplate) => void;
}

export const InspectionRow: React.FC<InspectionRowProps> = ({
  template,
  showConfirmDeleteModal,
}) => {
  const history = useHistory();
  const [toggleStatus, { loading }] = useMutation(UPDATE_TEMPLATE, {
    refetchQueries: ['inspectionConfigChecklistTemplates'],
  });

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const [copyChecklistTemplate, { loading: copyingTemplate }] = useMutation(
    COPY_TEMPLATE,
    {
      refetchQueries: ['inspectionConfigChecklistTemplates'],
    },
  );
  const hasValidSections = template?.sections?.some(
    (section) => section.questions.length > 0,
  );
  const isToggleDisabled =
    !hasValidSections && template.state === TemplateStates.INACTIVE;

  const editTemplate = (id: ChecklistTemplateType['id']) => {
    typewriter.buttonPressed({
      button_text: 'Edit Template',
      page_or_screen_name: EventNames.settings.inspectionReport.page,
      primary_cta: false,
      ...commonTrackingProps,
    });

    history.push(`/workflows/inspection_templates/${id}`);
  };

  const onToggleChange = () => {
    if (loading) return;
    const newState =
      template.state === TemplateStates.ACTIVE
        ? TemplateStates.INACTIVE
        : TemplateStates.ACTIVE;

    toggleStatus({
      variables: {
        checklistTemplateAttributes: {
          state: newState,
        },
        id: template.id,
      },
      optimisticResponse: {
        inspectionConfigChecklistTemplateUpdate: {
          checklistTemplate: {
            id: template.id,
            name: template.name,
            state: newState,
            logoUrl: template.logoUrl || '',
            __typename: 'ChecklistTemplate',
          },
          errors: [],
        },
      },
    });
    typewriter.checkboxSelected({
      selection: newState,
      page_or_screen_name: EventNames.settings.inspectionReport.page,
      primary_cta: false,
      options: 'Active/Inactive',
      ...commonTrackingProps,
    });
  };

  const copyTemplate = async (id: ChecklistTemplateType['id']) => {
    if (copyingTemplate) return;
    await copyChecklistTemplate({ variables: { id } });
    typewriter.buttonPressed({
      button_text: 'Duplicate Checklist Template',
      page_or_screen_name: EventNames.settings.inspectionReport.page,
      primary_cta: false,
      ...commonTrackingProps,
    });
  };

  const removeTemplate = () => {
    typewriter.buttonPressed({
      button_text: 'Delete Checklist Template',
      page_or_screen_name: EventNames.settings.inspectionReport.page,
      primary_cta: false,
      ...commonTrackingProps,
    });
    showConfirmDeleteModal({ id: template.id, name: template.name });
  };

  const renderToggle = () => (
    <Box alignItems="center">
      <Toggle
        isDisabled={isToggleDisabled}
        value={template.state === TemplateStates.ACTIVE}
        onChange={onToggleChange}
        marginRight={200}
      >
        <Body color="neutral.600" as="span">
          {template.state === TemplateStates.ACTIVE ? 'Active' : 'Inactive'}
        </Body>
      </Toggle>
    </Box>
  );

  return (
    <Tr key={template.id} _hover={{ backgroundColor: 'primary100' }}>
      <LoadingOverlay isLoading={copyingTemplate} />
      <Td width="35%"> {template.name}</Td>
      <Td>
        <Box alignItems="center">
          {isToggleDisabled ? (
            <Tooltip
              label="A template is empty and cannot be set to active."
              placement="top-start"
              mb={1}
              hasArrow
            >
              {renderToggle()}
            </Tooltip>
          ) : (
            renderToggle()
          )}
        </Box>
      </Td>
      <Td>
        <Box display="inline-flex" testId="template-updatedAt">
          {new Date(template.updatedAt).toLocaleString()}
        </Box>
      </Td>
      <Td>
        <Box>
          <IconButton
            label="edit"
            icon={iEdit}
            fill="minimal"
            shape="square"
            size="medium"
            color="neutral"
            onClick={() => editTemplate(template.id)}
            data-testid="editIcon"
          />
          <IconButton
            label="copy"
            icon={iCopy}
            fill="minimal"
            shape="square"
            size="medium"
            color="neutral"
            onClick={() => copyTemplate(template.id)}
            data-testid="copyIcon"
          />
          <IconButton
            label="remove"
            icon={iTrash}
            fill="minimal"
            shape="square"
            size="medium"
            color="neutral"
            onClick={() => {
              removeTemplate();
            }}
            data-testid="removeIcon"
          />
        </Box>
      </Td>
    </Tr>
  );
};
