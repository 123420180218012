import { useState, useEffect } from 'react';

import { useQuery, useMutation } from '@apollo/client';
import {
  Body,
  Box,
  Button,
  Heading,
  Icon,
  Link,
  LoadingOverlay,
  Panel,
  PanelProps,
} from '@hover/blueprint';
import { hGripVertical, iChevronRight, iPlus } from '@hover/icons';
import { sortBy } from 'lodash';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { ProjectManagementConfigProposalTemplatePageTypeEnum } from 'src/api/graphql-global-types';
import {
  projectManagementConfigProposalTemplate as projectManagementConfigProposalTemplateType,
  projectManagementConfigProposalTemplate_projectManagementConfigProposalTemplate_pages as ProposalTemplatePages,
} from 'src/api/types/projectManagementConfigProposalTemplate';
import { projectManagementConfigProposalTemplatesPagesSortOrderUpdate } from 'src/api/types/projectManagementConfigProposalTemplatesPagesSortOrderUpdate';
import {
  LinkOrComponent,
  DragDropContext,
  DroppableBox,
  DraggableBox,
  DropResult,
} from 'src/components';
import { GridSpacer } from 'src/components/GridSpacer';
import {
  GET_PROJECT_MANAGEMENT_CONFIG_PROPOSAL_TEMPLATE,
  UPDATE_PROPOSAL_PAGES_SORT_ORDER,
} from 'src/features/settings/api/queries/proposals';
import { DeleteProposal } from 'src/features/settings/components/Proposal/DeleteProposal';
import { HoverContentBadge } from 'src/features/settings/components/Proposal/HoverContentBadge';
import type { ViewMode } from 'src/features/settings/components/Proposal/Proposal';
import { useTracking } from 'src/hooks';
import { getUserTrackingProps, getUserOrgId } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { sentenceCase } from 'src/utils/Formatters';
import { reorderList } from 'src/utils/reorderList';

export const showHoverContentBadge = (pageType: string) =>
  pageType === 'LIST_ITEMS';

type SectionPanelProps = {
  testId: string;
  backgroundColor?: string;
  gridTemplateColumns?: PanelProps['gridTemplateColumns'];
  isDragging?: boolean;
};

const SectionPanel: React.FC<SectionPanelProps> = ({
  children,
  testId,
  backgroundColor,
  gridTemplateColumns = '0.6fr 6fr 3fr 2fr 1fr 1fr',
}) => (
  <Panel
    boxShadow="distance500"
    display="inline-grid"
    gridTemplateColumns={gridTemplateColumns}
    justifyContent="space-between"
    marginBottom={200}
    padding={0}
    data-testid={testId}
    width="100%"
    alignItems="center"
    backgroundColor={backgroundColor ?? 'neutral000'}
  >
    {children}
  </Panel>
);

type DraggableProposalProps = {
  id: string;
  viewMode: string;
  title: string | null;
  index: number;
  pageType: string;
  tradeType: string | null;
  isDragging?: boolean;
};

const DraggableProposal: React.FC<DraggableProposalProps> = ({
  id,
  viewMode,
  index,
  title,
  pageType,
  tradeType,
  ...props
}) => {
  const textColor = props.isDragging ? 'neutral0' : 'neutral700';

  return (
    <LinkOrComponent
      key={id}
      to={`/workflows/proposal/section/${id}`}
      disabled={viewMode === 'EDIT'}
      width={1}
    >
      <SectionPanel
        testId="proposalSectionItem"
        backgroundColor={props.isDragging ? 'primary700' : ''}
      >
        <Box flexDirection="row" marginLeft={400}>
          {viewMode === 'EDIT' ? (
            <Icon
              color="neutral300"
              icon={hGripVertical}
              style={{
                alignSelf: 'center',
                justifySelf: 'end',
              }}
            />
          ) : (
            <Box width="22px" />
          )}
          <Body
            size={500}
            style={{ fontWeight: 700 }}
            marginLeft={200}
            color={textColor}
          >
            {index + 1} {/* start at 1, not 0 */}
          </Body>
        </Box>
        <Body
          size={500}
          data-testid="proposalSectionTitle"
          marginLeft={200}
          color={textColor}
        >
          {title}
        </Body>
        {showHoverContentBadge(pageType as string) ? (
          <HoverContentBadge />
        ) : (
          <GridSpacer />
        )}
        <Body size={500} data-testid="tradeName" color={textColor}>
          {sentenceCase(tradeType as string)}
        </Body>
        {viewMode === 'SHOW' && (
          <>
            {pageType !==
            ProjectManagementConfigProposalTemplatePageTypeEnum.LIST_ITEMS ? (
              <DeleteProposal id={id} title={title} />
            ) : (
              <GridSpacer />
            )}
            <Icon
              color="neutral300"
              icon={iChevronRight}
              marginRight={500}
              style={{
                alignSelf: 'center',
                justifySelf: 'end',
              }}
              data-testid="chevron-right-icon"
            />
          </>
        )}
      </SectionPanel>
    </LinkOrComponent>
  );
};

type ProposalSectionsProps = {
  viewMode: ViewMode;
  setViewMode: React.Dispatch<React.SetStateAction<ViewMode>>;
};

export const ProposalSections: React.FC<ProposalSectionsProps> = ({
  viewMode,
  setViewMode,
}) => {
  const [pages, setPages] = useState<ProposalTemplatePages[]>([]);
  const [isSortOrderDirty, setIsSortOrderDirty] = useState<boolean>(false);
  const orgId = useSelector(getUserOrgId);

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const [updateSortOrder, { loading: isSaving, data: updatedData }] =
    useMutation<projectManagementConfigProposalTemplatesPagesSortOrderUpdate>(
      UPDATE_PROPOSAL_PAGES_SORT_ORDER,
      {
        onCompleted: (data) => {
          if (
            data.projectManagementConfigProposalTemplatesPagesSortOrderUpdate
              ?.proposalTemplate
          ) {
            setPages(
              sortBy(
                data
                  .projectManagementConfigProposalTemplatesPagesSortOrderUpdate
                  .proposalTemplate.pages,
                'sortOrder',
              ),
            );
            setViewMode('SHOW');
          }
        },
      },
    );

  const handleClickEditSortOrder = () => {
    setViewMode('EDIT');
    typewriter.buttonPressed({
      button_text: 'Edit section order',
      page_or_screen_name: EventNames.settings.proposal.page,
      primary_cta: false,
      ...commonTrackingProps,
    });
  };

  const { data, loading: loadingSections } =
    useQuery<projectManagementConfigProposalTemplateType>(
      GET_PROJECT_MANAGEMENT_CONFIG_PROPOSAL_TEMPLATE,
      {
        variables: { orgId },
      },
    );

  useEffect(() => {
    typewriter.pageViewed({
      page_or_screen_name: EventNames.settings.proposal.page,
      ...commonTrackingProps,
    });
  }, [commonTrackingProps]);

  useEffect(() => {
    if (data?.projectManagementConfigProposalTemplate?.pages) {
      setPages(
        sortBy(data.projectManagementConfigProposalTemplate.pages, 'sortOrder'),
      );
    }
  }, [data?.projectManagementConfigProposalTemplate?.pages]);

  const handleSave = () => {
    setIsSortOrderDirty(false);
    typewriter.buttonPressed({
      button_text: 'Save',
      page_or_screen_name: EventNames.settings.proposal.page,
      primary_cta: false,
      ...commonTrackingProps,
    });

    updateSortOrder({
      variables: {
        orgId,
        proposalTemplatePageIds: pages.map(({ id }) => id),
      },
    });
  };

  const handleClickCancel = () => {
    setIsSortOrderDirty(false);
    typewriter.buttonPressed({
      button_text: 'Cancel',
      page_or_screen_name: EventNames.settings.proposal.page,
      primary_cta: false,
      ...commonTrackingProps,
    });

    setViewMode('SHOW');
    // revert any sort changes
    // use latest data (if previously saved once, the mutation data is the latest)
    if (
      updatedData?.projectManagementConfigProposalTemplatesPagesSortOrderUpdate
        ?.proposalTemplate
    ) {
      setPages(
        sortBy(
          updatedData
            .projectManagementConfigProposalTemplatesPagesSortOrderUpdate
            .proposalTemplate.pages,
          'sortOrder',
        ),
      );
      return;
    }
    // else use the original query data
    if (data?.projectManagementConfigProposalTemplate?.pages?.length) {
      setPages(
        sortBy(data.projectManagementConfigProposalTemplate.pages, 'sortOrder'),
      );
    }
  };

  const handleAddSectionPressed = () => {
    typewriter.buttonPressed({
      button_text: 'Add Section',
      page_or_screen_name: EventNames.settings.proposal.page,
      primary_cta: false,
      ...commonTrackingProps,
    });
  };

  const onDragEnd = (dropResult: DropResult) => {
    const { destination, source } = dropResult;
    setIsSortOrderDirty(true);

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const reorderedPages = reorderList(pages, source.index, destination.index);
    setPages(reorderedPages);
  };

  const draggableProposalSections = () => (
    <>
      <LoadingOverlay isLoading={loadingSections || isSaving} />
      <DragDropContext onDragEnd={onDragEnd}>
        <DroppableBox droppableId="proposalSections" flexDirection="column">
          {pages.map(({ id, title, tradeType, pageType }, index) => {
            return (
              <DraggableBox
                data-testid="draggableProposalPage"
                key={id}
                draggableId={id}
                index={index}
                isDragDisabled={viewMode !== 'EDIT'}
              >
                <DraggableProposal
                  id={id}
                  index={index}
                  pageType={pageType}
                  viewMode={viewMode}
                  title={title}
                  tradeType={tradeType}
                />
              </DraggableBox>
            );
          })}
        </DroppableBox>
      </DragDropContext>
    </>
  );

  return (
    <Box flexDirection="column" testId="proposalSections">
      <Heading
        size={400}
        marginBottom={400}
        data-testid="proposalSectionHeading"
      >
        {
          {
            SHOW: 'Sections',
            EDIT: 'Edit Sections Order',
          }[viewMode]
        }
      </Heading>
      {draggableProposalSections()}
      {viewMode === 'SHOW' && (
        <Link
          as={RouterLink}
          to="/workflows/proposal/section"
          onPress={handleAddSectionPressed}
        >
          <SectionPanel
            testId="proposalAddSection"
            backgroundColor="neutral100"
          >
            <Icon
              size="small"
              icon={iPlus}
              color="primaryBase"
              marginTop="16px"
              marginBottom="20px"
              marginLeft={500}
            />
            <Body size={500} color="primaryBase">
              Add Section
            </Body>
          </SectionPanel>
        </Link>
      )}
      <Box mt={500} as="footer">
        {
          {
            EDIT: (
              <Box>
                <Button
                  mr={400}
                  onClick={handleSave}
                  isLoading={isSaving}
                  isDisabled={!isSortOrderDirty}
                >
                  Save
                </Button>
                <Button fill="outline" onClick={handleClickCancel}>
                  Cancel
                </Button>
              </Box>
            ),
            SHOW: (
              <Button
                fill="outline"
                onClick={handleClickEditSortOrder}
                isDisabled={pages.length <= 1}
              >
                Edit Section Order
              </Button>
            ),
          }[viewMode]
        }
      </Box>
    </Box>
  );
};
