/* eslint-disable no-param-reassign */
import { useCallback, useEffect, useRef, useState } from 'react';

import { Box, Spinner, AspectRatio } from '@hover/blueprint';
import { colors } from '@hover/blueprint/foundation';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import { TradeTypeEnum } from 'src/api/graphql-global-types';
import { EstimateGroup_estimationEstimateGroup_facets as Facet } from 'src/api/types/EstimateGroup';
import { EstimatorApi } from 'src/features/exteriorEstimator/apis/estimator';
import { IconMessaging } from 'src/features/exteriorEstimator/components/EstimationTool/Machete/IconMessaging';
import {
  getPages,
  getQuestionResponses,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { FacetPageTradeTypes } from 'src/features/exteriorEstimator/types';
import { PartialRoofUtils } from 'src/features/exteriorEstimator/utils/PartialRoofUtils';
import { PartialSidingUtils } from 'src/features/exteriorEstimator/utils/PartialSidingUtils';
import { useGetJobDetails } from 'src/features/projectEstimator/hooks/useGetJobDetails';
import { useSearchParams } from 'src/hooks/useSearchParams';
import { renderMachete } from 'src/lib/machete';
import { emitPageAction, NewRelicEventType } from 'src/utils/newrelic/page';

export interface OnFeatureClickProps {
  featureName: string;
  trade: FacetPageTradeTypes;
}

interface Props {
  onFeatureClick?: (props: OnFeatureClickProps) => void;
  trades?: FacetPageTradeTypes[];
  widescreen?: boolean;
  facets?: Facet[]; // if available, this param indicates which facets are selected
  projectEstimatorEnabled?: boolean;
}

export const ModelSection: React.FC<Props> = ({
  onFeatureClick,
  facets,
  widescreen,
  trades,
  projectEstimatorEnabled,
}) => {
  const [geometry, setGeometry] = useState<any>(null);
  const [metadata, setMetadata] = useState<any>(null);

  const { searchParams } = useSearchParams();
  const jobId = searchParams.get('jobId');

  const { data: jobDetails } = useGetJobDetails({ jobId: Number(jobId) });
  const job = jobDetails?.jobs?.results[0];

  const { geometryUrl, metadataUrl } = job?.threeDFiles || {};

  // TODO: use zustand store to get pages and question responses
  const pages = useSelector(getPages);

  const initializeMacheteData = useCallback(() => {
    if (!geometryUrl || !metadataUrl) return;

    const geometryPromise = EstimatorApi.getMacheteJSON(geometryUrl);
    const metadataPromise = EstimatorApi.getMacheteJSON(metadataUrl);
    Promise.all<any>([geometryPromise, metadataPromise]).then((values) => {
      emitPageAction(NewRelicEventType.GEOMETRY_AND_METADATA_LOADED);
      setGeometry(values[0].data);
      setMetadata(values[1].data);
    });
  }, [geometryUrl, metadataUrl]);

  const questionResponses = useSelector(getQuestionResponses);
  const [building, setBuilding] = useState<any>(null);
  const [isBuildingReady, setIsBuildingReady] = useState<boolean>(false);

  const canvasRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!geometry && !metadata && geometryUrl && metadataUrl) {
      initializeMacheteData();
    }
  }, [geometryUrl, metadataUrl, initializeMacheteData]);

  useEffect(() => {
    const readyForModel = facets ? true : !isEmpty(pages);
    if (geometry && metadata && readyForModel) {
      renderMachete({
        canvasRef,
        geometry,
        metadata,
        grey: projectEstimatorEnabled,
      })
        .then((macheteStuff) => {
          console.log({ macheteStuff });
          emitPageAction(NewRelicEventType.BUILDING_FULLY_LOADED);
          if (macheteStuff.building) {
            setBuilding(macheteStuff.building);
            setIsBuildingReady(true);
          }
        })
        .catch((error) => {
          console.log('Error initializing machete', error);
        });
    }
  }, [geometry, metadata, pages, facets]);

  const handleModelFacetClick = useCallback(
    (event: React.ChangeEvent<{ name: string }>) => {
      const facet = event.target;

      // need to use window here instead of props because of something weird with
      // machete closures on event handlers
      let trade: FacetPageTradeTypes = TradeTypeEnum.ROOF;
      if (
        window.location.href.includes('partial_siding_selection') ||
        window.location.href.includes('siding_facet_selection_3d')
      ) {
        trade = TradeTypeEnum.SIDING;
      }

      if (window.location.href.includes('stone_facet_selection_3d')) {
        trade = TradeTypeEnum.STONE;
      }

      if (onFeatureClick) {
        if (
          (PartialSidingUtils.isQuestionPartialSidingSelection(facet.name) &&
            (window.location.href.includes('partial_siding_selection') ||
              window.location.href.includes('siding_facet_selection_3d') ||
              window.location.href.includes('stone_facet_selection_3d'))) ||
          (PartialRoofUtils.isQuestionPartialRoofFacetSelection(facet.name) &&
            window.location.href.includes('roof_facet_selection_3d'))
        ) {
          onFeatureClick({
            featureName: facet.name,
            trade,
          });
        }
      }
    },
    [onFeatureClick],
  );

  const setSelected = (facet: any) => {
    // eslint-disable-next-line prefer-destructuring
    facet.highlight.config.color = projectEstimatorEnabled
      ? '#496ff7'
      : colors.primary[100];
    facet.highlight.visible = true;

    facet.outline_color = colors.primary.base;
    facet.outline_thickness = 0.08;

    facet.name_tag.config.backgroundColor = colors.primary.base;
    facet.name_tag.visible = true;
  };

  const setUnselected = (facet: any) => {
    facet.highlight.config.color = '#EDECED';
    facet.highlight.visible = false;

    // eslint-disable-next-line prefer-destructuring
    facet.outline_color = colors.neutral[300];
    facet.outline_thickness = 0.08;

    // eslint-disable-next-line prefer-destructuring
    facet.name_tag.config.backgroundColor = colors.neutral[500];
    facet.name_tag.visible = true;
  };

  const setModel = () => {
    const { walls, roofs } = building.features;
    if (!facets) return;

    walls.forEach((wall: any) => {
      if (facets.find((f) => f.identifier === wall.name)) {
        setSelected(wall);
      } else {
        setUnselected(wall);
      }
    });

    roofs.forEach((roof: any) => {
      if (facets.find((f) => f.identifier === roof.name)) {
        setSelected(roof);
      } else {
        setUnselected(roof);
      }
    });
  };

  useEffect(() => {
    if (!isBuildingReady) return;

    const { walls, roofs } = building.features;

    // base outline for all features, not just walls
    building.features.forEach((feature: any) => {
      // eslint-disable-next-line prefer-destructuring
      feature.outline_color = colors.neutral[300];
      feature.outline_thickness = 0.05;
      feature.display_outline = true;
      feature.color = '#EDECED';
    });

    roofs.forEach((roof: any) => {
      roof.addEventListener('click', handleModelFacetClick);
      roof.name_tag.config.fontSize = '40px';
    });

    walls.forEach((wall: any) => {
      wall.addEventListener('click', handleModelFacetClick);
      wall.name_tag.config.fontSize = '40px';
    });
    // don't listen to building changes to avoid unnecessary re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBuildingReady]);

  // when building is ready and facets are available, initialize model based on facets
  useEffect(() => {
    if (!isBuildingReady) return;

    if (facets) {
      setModel();
    }
    // don't listen to building changes to avoid unnecessary re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBuildingReady, facets]);

  // listen to questionResponses changes
  useEffect(() => {
    if (!isBuildingReady) return;
    if (facets) return;
    const { walls, roofs } = building.features;

    walls.forEach((wall: any) => {
      if (
        questionResponses[wall.name] &&
        trades?.includes(TradeTypeEnum.SIDING)
      ) {
        setSelected(wall);
      } else {
        setUnselected(wall);
      }
    });

    roofs.forEach((roof: any) => {
      if (
        questionResponses[roof.name] &&
        trades?.includes(TradeTypeEnum.ROOF)
      ) {
        setSelected(roof);
      } else {
        setUnselected(roof);
      }
    });

    // don't listen to building changes to avoid unnecessary re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionResponses, isBuildingReady, facets]);

  return (
    <Box
      flex={{ base: 0, tablet: 1 }}
      height="100%"
      flexDirection="column"
      alignSelf="center"
      padding={projectEstimatorEnabled ? 0 : 300}
      paddingTop={projectEstimatorEnabled ? 300 : 0}
      data-testid="3DModel"
      width={1}
    >
      <AspectRatio ratio={widescreen ? 16 / 9 : 4 / 3}>
        <Box
          flex={1}
          ref={canvasRef}
          borderRadius={projectEstimatorEnabled ? '10px' : 0}
        >
          {!building && (
            <Box
              flex={1}
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Spinner label="Loading model" size="large" />
              <Box>Loading model...</Box>
            </Box>
          )}
        </Box>
      </AspectRatio>
      <IconMessaging />
    </Box>
  );
};
/* eslint-enable no-param-reassign */
