import { useState } from 'react';

import { Button, Body, Box, Panel } from '@hover/blueprint';
import { useSelector } from 'react-redux';

import { EnableVariantsFilteringModal } from 'src/features/settings/components/MaterialList/EnableVariantsFiltering/EnableVariantsFilteringModal';
import { useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

interface Props {
  isProductFilteringEnabled: boolean;
}

export const EnableVariantsFiltering: React.FC<Props> = ({
  isProductFilteringEnabled,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const enableVariantFilteringText = 'Enable variants filtering';

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const handlePress = () => {
    setIsOpen(true);
    typewriter.buttonPressed({
      button_text: enableVariantFilteringText,
      primary_cta: true,
      page_or_screen_name: EventNames.settings.materialsList.page,
      ...commonTrackingProps,
    });
  };

  return (
    <Box flexDirection="column">
      <EnableVariantsFilteringModal isOpen={isOpen} setIsOpen={setIsOpen} />
      {!isProductFilteringEnabled && (
        <Panel
          boxShadow="distance500"
          data-testid="EnableVariantsFiltering"
          marginBottom={400}
        >
          <Box
            width={1}
            paddingX={400}
            paddingY={100}
            justifyContent="space-between"
          >
            <Box flexDirection="column">
              <Body size={400} margin={0}>
                Your team can always access all variants by default.
              </Body>
              <Body size={400} margin={0}>
                You can limit variants for materials by enabling variants
                filtering.
              </Body>
            </Box>
            <Button onClick={handlePress}>{enableVariantFilteringText}</Button>
          </Box>
        </Panel>
      )}
    </Box>
  );
};
