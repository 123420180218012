import { useMemo } from 'react';

import { Box } from '@hover/blueprint';
import { toNumber } from 'lodash';
import { useSelector } from 'react-redux';

import { TradeTypeEnum } from 'src/api/graphql-global-types';
import { FACADE_TYPES } from 'src/features/exteriorEstimator/constants/sidingConstants';
import {
  getRoofFacetInputs,
  getRoofLineSegmentInputs,
  getSidingFacetInputs,
  getSidingLineSegmentInputs,
  getStoneFacetInputs,
  getStoneLineSegmentInputs,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import {
  QuestionResponses,
  Input,
  FacetPageTradeTypes,
} from 'src/features/exteriorEstimator/types';
import { PartialRoofUtils } from 'src/features/exteriorEstimator/utils/PartialRoofUtils';

import { TilesByType } from './TilesByType';

type Props = {
  trade: FacetPageTradeTypes;
  updateAnswers: (responses: QuestionResponses) => void;
};

export const SelectionTiles: React.FC<Props> = ({ trade, updateAnswers }) => {
  const sidingFacetInputs = useSelector(getSidingFacetInputs);
  const stoneFacetInputs = useSelector(getStoneFacetInputs);
  const roofFacetInputs = useSelector(getRoofFacetInputs);
  const roofLineSegmentInputs = useSelector(getRoofLineSegmentInputs);
  const sidingLineSegmentInputs = useSelector(getSidingLineSegmentInputs);
  const stoneLineSegmentInputs = useSelector(getStoneLineSegmentInputs);

  const lineSegmentInputs = {
    [TradeTypeEnum.SIDING]: sidingLineSegmentInputs,
    [TradeTypeEnum.STONE]: stoneLineSegmentInputs,
    [TradeTypeEnum.ROOF]: roofLineSegmentInputs,
  }[trade];

  const facetInputs = {
    [TradeTypeEnum.SIDING]: sidingFacetInputs,
    [TradeTypeEnum.STONE]: stoneFacetInputs,
    [TradeTypeEnum.ROOF]: roofFacetInputs,
  }[trade];

  const facetInputsByType = useMemo(
    () =>
      facetInputs?.reduce<{ [facadeType: string]: Input[] }>(
        (acc, question) => {
          const facadeType =
            FACADE_TYPES[question.name?.split('-').shift() ?? ''];

          if (!acc[facadeType]) {
            acc[facadeType] = [question];
          } else {
            acc[facadeType].push(question);
            acc[facadeType].sort((a, b) => {
              const aSortOrder = toNumber(a.name?.split('-').pop());
              const bSortOrder = toNumber(b.name?.split('-').pop());
              return aSortOrder - bSortOrder;
            });
          }
          return acc;
        },
        {},
      ),
    [facetInputs],
  );

  const partialRoofQuestionsByPitch =
    PartialRoofUtils.organizeByPitch(roofFacetInputs);

  if (
    (trade === TradeTypeEnum.SIDING || trade === TradeTypeEnum.STONE) &&
    !facetInputsByType
  )
    return null;

  if (trade === TradeTypeEnum.ROOF && !partialRoofQuestionsByPitch) return null;

  return (
    <Box flex={1} overflowY="scroll" flexDirection="column" padding="none">
      {(trade === TradeTypeEnum.SIDING || trade === TradeTypeEnum.STONE) &&
        !!facetInputsByType &&
        Object.entries(facetInputsByType)
          .sort(([aKey], [bKey]) => {
            if (trade === TradeTypeEnum.STONE) {
              if (aKey === 'Stone') return -1;
              if (bKey === 'Stone') return 1;
              if (aKey === 'Siding') return -1;
              if (bKey === 'Siding') return 1;
            }
            if (aKey === 'Siding') return -1;
            if (bKey === 'Siding') return 1;
            if (aKey === 'Stone') return -1;
            if (bKey === 'Stone') return 1;
            return 0;
          })
          .map(([type, questions]) => (
            <TilesByType
              grouping={type}
              questions={questions}
              key={type}
              updateAnswers={updateAnswers}
              trade={trade}
              lineSegmentInputs={lineSegmentInputs}
            />
          ))}
      {trade === TradeTypeEnum.ROOF && !!partialRoofQuestionsByPitch && (
        <>
          {Object.entries(partialRoofQuestionsByPitch).map(
            ([pitch, questions]) => (
              <TilesByType
                grouping={pitch}
                questions={questions}
                key={pitch}
                updateAnswers={updateAnswers}
                trade={trade}
                lineSegmentInputs={lineSegmentInputs}
              />
            ),
          )}
        </>
      )}
    </Box>
  );
};
