import { useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { Box } from '@hover/blueprint';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { distributionApprovedBranchesQuery } from 'src/api/types/distributionApprovedBranchesQuery';
import { messages } from 'src/constants/messages';
import { GET_DISTRIBUTION_APPROVED_BRANCHES } from 'src/features/project/apis/graphql/queries/queries';
import { OrderDetailContent } from 'src/features/project/components/OrderDetail/OrderDetailContent';
import { getDistributor } from 'src/features/project/redux/selectors/projectSelectors';
import { ToastStatusEnum, useToastEhi, useTracking } from 'src/hooks';
import { getOrgIdParam } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

const enum TOAST_IDS {
  GET_DISTRIBUTION_APPROVED_BRANCHES_TOAST,
}

export const OrderDetail: React.FC = () => {
  const jobId = Number(get(useParams(), 'jobId'));

  const toast = useToastEhi();
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  // Segment tracking.
  useEffect(() => {
    typewriter.pageViewed({
      page_or_screen_name: EventNames.project.orderDetails.page,
      job_id: Number(jobId),
      ...commonTrackingProps,
    });
  }, [jobId, commonTrackingProps]);

  // Get available branches for org, for branch/jobAccount selection menu.
  const distributor = useSelector(getDistributor);
  const orgId = useSelector(getOrgIdParam);
  const {
    data: branchesData,
    loading: loadingBranches,
  }: {
    data?: distributionApprovedBranchesQuery;
    loading: boolean;
  } = useQuery(GET_DISTRIBUTION_APPROVED_BRANCHES, {
    onError: () => {
      toast({
        id: TOAST_IDS.GET_DISTRIBUTION_APPROVED_BRANCHES_TOAST,
        description:
          messages.projectScope.errors.query.productionList
            .getDistributionApprovedBranches,
        status: ToastStatusEnum.ERROR,
      });
    },
    variables: {
      orgId,
      distributorId: distributor?.id,
    },
    fetchPolicy: 'cache-first', // default
  });

  const branches = branchesData?.distributionApprovedBranches;

  return (
    <Box flexDirection="column" flex="auto" backgroundColor="neutral.100">
      <OrderDetailContent
        loadingBranches={loadingBranches}
        branches={branches}
        jobId={jobId}
      />
    </Box>
  );
};
