import { Box, Link, Tag } from '@hover/blueprint';
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { TradeTypeEnum } from 'src/api/graphql-global-types';
import { ListItemIdsByTrade } from 'src/features/project/types';
import { useTracking } from 'src/hooks';
import { EventNames } from 'src/types';
import { sentenceCase } from 'src/utils/Formatters';

export interface ListControlTotalsProps {
  selectedListItemIds: ListItemIdsByTrade | null;
  trades: string[];
  lineItemType: string;
}

export function ListControlTotals({
  trades,
  selectedListItemIds,
  lineItemType,
}: ListControlTotalsProps) {
  const location = useLocation();
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  return (
    <Box display={{ base: 'flex', desktop: 'block' }}>
      {trades.map((tradeType) => (
        <Tag
          key={tradeType}
          colorScheme="dark"
          marginRight={2}
          marginY="2px"
          onClick={() => {
            typewriter.buttonPressed({
              page_or_screen_name:
                EventNames.project.scope.quickNavigationTradeType,
              button_text: sentenceCase(tradeType),
              primary_cta: false,
              ...commonTrackingProps,
            });
          }}
        >
          <Link
            data-testid={`tradeTypeLink-${tradeType}`}
            as={HashLink}
            to={{
              ...location,
              ...{ hash: `#${lineItemType}.${tradeType}` },
            }}
            color="neutral.0"
            _active={{
              color: 'neutral.0',
              textDecorationColor: 'transparent',
            }}
            _hover={{
              color: 'neutral.0',
              textDecorationColor: 'transparent',
            }}
          >
            {`${sentenceCase(tradeType)}
          (${
            selectedListItemIds &&
            selectedListItemIds[tradeType as TradeTypeEnum] &&
            selectedListItemIds[tradeType as TradeTypeEnum].length
          })`}
          </Link>
        </Tag>
      ))}
    </Box>
  );
}
