import React, { useMemo } from 'react';

import styled from '@emotion/styled';
import { Box, Button, Icon, Body } from '@hover/blueprint';
import { iDownload } from '@hover/icons';
import { isNil, sum, compact } from 'lodash';
import { useSelector } from 'react-redux';

import { LineItemTypeEnum } from 'src/api/graphql-global-types';
import type { productCatalogConfigOrgDistributors_productCatalogConfigOrgDistributors as Distributor } from 'src/api/types/productCatalogConfigOrgDistributors';
import { projectManagementProductionList_projectManagementProductionList_listItems as ListItems } from 'src/api/types/projectManagementProductionList';
import { getSelectedListItemIds } from 'src/features/project/redux/selectors/projectSelectors';
import { useTracking } from 'src/hooks';
import { useIsMobileBreakpoint } from 'src/hooks/useIsMobileBreakpoint';
import { EventNames } from 'src/types/actionTypes';

import { formattedNumber } from '../../util/utils';
import { DirectOrderCtaModal } from './DirectOrderCtaModal';
import { useDirectOrderCtaModal } from './hooks/useDirectOrderCtaModal';

const InlineBoldedText = styled(Body)`
  margin-right: 10px;
  margin-left: 10px;
  display: inline-block;
`;

type Props = {
  openDownloadPdfModal: (lineItemtype: LineItemTypeEnum) => void;
  selectDistributor: () => void;
  distributors?: Distributor[];
  listItems: ListItems[] | null;
};

export const ProjectScopeFooter: React.FC<Props> = React.memo(
  ({ openDownloadPdfModal, selectDistributor, distributors, listItems }) => {
    const { useTypewriter, useCommonTrackingProps } = useTracking();
    const commonTrackingProps = useCommonTrackingProps();
    const typewriter = useTypewriter();
    const isMobile = useIsMobileBreakpoint();

    const selectedListItemIds = useSelector(getSelectedListItemIds);

    const {
      showDirectOrderCtaModal,
      openDirectOrderCtaModal,
      closeDirectOrderCtaModal,
    } = useDirectOrderCtaModal();

    const flattenedSelectedMaterialListIds = useMemo(() => {
      const selectedMaterialListItemIds =
        selectedListItemIds && selectedListItemIds[LineItemTypeEnum.MATERIAL];
      return selectedMaterialListItemIds
        ? Object.values(selectedMaterialListItemIds).flat()
        : [];
    }, [selectedListItemIds]);

    const numberOfSelectedItems = useMemo(() => {
      return !flattenedSelectedMaterialListIds
        ? 0
        : flattenedSelectedMaterialListIds.length;
    }, [flattenedSelectedMaterialListIds]);

    const selectedItemsTotalCost = useMemo(() => {
      if (!listItems?.length || !numberOfSelectedItems) {
        return 0;
      }
      const costOfCurrentlySelectedArray = listItems
        .filter((listItem) =>
          flattenedSelectedMaterialListIds.includes(listItem.id.toString()),
        )
        .map((item) => item.totalCost || 0);
      return sum(compact(costOfCurrentlySelectedArray));
    }, [numberOfSelectedItems, listItems]);

    const selectDistributorsOrOpenCtaModal = () => {
      const hasDistributorsIntegrated = distributors?.length;
      if (hasDistributorsIntegrated) {
        selectDistributor();
      } else {
        openDirectOrderCtaModal();
      }
    };

    const handleOrderMaterials = () => {
      typewriter.buttonPressed({
        page_or_screen_name:
          EventNames.project.scope.orderMaterialsButtonPressed,
        button_text: 'Order materials',
        primary_cta: false,
        feature: 'material-list',
        ...commonTrackingProps,
      });
      selectDistributorsOrOpenCtaModal();
    };

    return (
      <Box
        sx={{ position: 'sticky', bottom: '0px', zIndex: 1 }}
        height={500}
        backgroundColor="white"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        paddingLeft={{ base: 400, desktop: 800 }}
        paddingRight={{ base: 400, desktop: 800 }}
        boxShadow="distance600"
        data-testid="project-scope-footer"
      >
        <DirectOrderCtaModal
          isOpen={showDirectOrderCtaModal}
          onCancel={closeDirectOrderCtaModal}
        />
        <Box alignItems="baseline">
          {!isMobile && <Body fontWeight={700}>Selected Materials:</Body>}
          {!isMobile && (
            <InlineBoldedText data-testid="ProjectScope-Footer-NumberOfSelectedItems">
              {numberOfSelectedItems}
            </InlineBoldedText>
          )}
          {isMobile && (
            <Box
              p={6}
              backgroundColor="neutral.100"
              color="neutral.900"
              fontWeight={600}
              borderRadius="6px"
            >
              {numberOfSelectedItems}
            </Box>
          )}
          {!isMobile && '\u2022'}
          <InlineBoldedText data-testid="ProjectScope-Footer-selectedItemsTotalCost">
            {' $ '}
            {formattedNumber(selectedItemsTotalCost)}
          </InlineBoldedText>
        </Box>

        {!isMobile && (
          <Box display="flex" flexDirection="row">
            <Button
              color="primary"
              fill="outline"
              marginRight={300}
              onClick={() => {
                openDownloadPdfModal(LineItemTypeEnum.MATERIAL);
              }}
              isDisabled={
                isNil(selectedListItemIds) || numberOfSelectedItems === 0
              }
              data-testid="ProjectScope-Footer-DownloadMaterialList"
              iconBefore={iDownload}
            >
              Download material list
            </Button>

            <Button
              onClick={handleOrderMaterials}
              color="primary"
              isDisabled={
                isNil(selectedListItemIds) || numberOfSelectedItems === 0
              }
              data-testid="ProjectScope-Footer-OrderMaterials"
            >
              Order materials
            </Button>
          </Box>
        )}
      </Box>
    );
  },
);
