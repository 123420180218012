import { useEffect, useState } from 'react';

import appSettings from 'src/appSettings';
import { HDF_CUTOFF_DATE } from 'src/constants';
import { HoverRequest } from 'src/lib/HoverRequestWrapper';
import { EstimationSummarizedJsonMeasurements } from 'src/types/HdfMeasurements';

import { useGetJobDetails } from './useGetJobDetails';

export const useGetHdf = ({ jobId }: { jobId: number }) => {
  const { data: jobDetails } = useGetJobDetails({ jobId });

  const [data, setData] = useState<EstimationSummarizedJsonMeasurements | null>(
    null,
  );
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);

  useEffect(() => {
    const fetchHdf = async () => {
      if (
        !jobId ||
        jobDetails?.jobs.results[0]?.hdfCreatedAt < new Date(HDF_CUTOFF_DATE) ||
        hasFetched
      )
        return;

      setHasFetched(true);
      setLoading(true);
      try {
        const res: { data: EstimationSummarizedJsonMeasurements } =
          await HoverRequest.get({
            path: `/api/v2/jobs/${jobId}/estimation_summarized_hdf.json`,
            baseUrl: appSettings.HYPERION_API_SERVER,
            options: { withCredentials: true },
          });

        setData(res?.data);
      } catch (err) {
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchHdf();
  }, [jobId, jobDetails?.jobs.results, hasFetched]);

  return { data, loading, error };
};
