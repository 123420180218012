import { PureComponent } from 'react';

import autobind from 'autobind-decorator';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import { withTypewriter } from 'src/components/WithTypewriter';
import { getUserTrackingProps } from 'src/redux/selectors';
import { RootAction, RootState } from 'src/types/reduxStore';
import { sentenceCase } from 'src/utils/Formatters';
import { jobProps } from 'src/utils/trackingUtils';

import * as actions from '../../../redux/actions';
import { getJobDetails } from '../../../redux/selectors/estimatorProductionSelectors';
import { FilterWrapper, Text } from './styled';

export const mapStateToProps = (state: RootState) => ({
  jobDetails: getJobDetails(state),
  commonProps: getUserTrackingProps(state),
});

export const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      updateTradeFilter: actions.updateTradeFilter,
    },
    dispatch,
  );

export interface ComponentProps {
  isActive: boolean;
  tradeType: string;
  value?: string;
  typewriter: any;
}

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ComponentProps;

export class Filter extends PureComponent<Props> {
  @autobind
  handleClick() {
    const {
      updateTradeFilter,
      tradeType,
      jobDetails,
      commonProps,
      typewriter,
    } = this.props;
    updateTradeFilter(tradeType);

    typewriter.optionSelected({
      selection: tradeType,
      option_type: 'dropdown',
      options: 'PMP trade type filter',
      ...commonProps,
      ...jobProps(jobDetails),
    });
  }

  public render() {
    const { isActive, tradeType, value } = this.props;
    const dataTestId = isActive ? 'active-filter' : 'filter';
    return (
      <FilterWrapper
        onClick={this.handleClick}
        data-testid={dataTestId}
        isActive={isActive}
      >
        <Text>{value || sentenceCase(tradeType)}</Text>
      </FilterWrapper>
    );
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTypewriter(Filter));
