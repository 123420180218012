/* eslint-disable class-methods-use-this */
import { get, set, cloneDeep, flatten } from 'lodash';

import { JobMeasurements } from 'src/features/exteriorEstimator/types';
import { EhiOrgSettings } from 'src/redux/reducers/ehiReducer';
import {
  Measurements,
  Sides,
  UnknownsConnectedToSidingMap,
  AreaPerLabelEntity,
  OtherSidingTypes,
  Facades,
} from 'src/types/EstimationMeasurementTypes';

import PartialSidingUtils from './PartialSidingUtils';
/* eslint-disable camelcase */

interface Windows {
  window_count_total?: number;
  window_ui_total?: number;
}

interface CombinedAreaWithTrimForFacade {
  [facade: string]: {
    areaWithOpenings: number;
    openingsTrim: number;
    trimArea: number;
    combinedArea: number;
  };
}

interface Siding extends OtherSidingTypes {
  siding_area_total?: number;
  siding_zero_waste_area_total?: number;
  siding_with_openings_area_total?: number;
  siding_facade_area?: number;
  siding_unknown_area_total?: number;
  sides?: Sides;
  unknownsConnectedToSidingMap?: UnknownsConnectedToSidingMap;
  brick_with_openings_area?: number;
  brick_zero_waste_area?: number;
  stucco_with_openings_area?: number;
  stucco_zero_waste_area?: number;
  metal_with_openings_area?: number;
  metal_zero_waste_area?: number;
  stone_with_openings_area?: number;
  stone_zero_waste_area?: number;
  wrap_with_openings_area?: number;
  wrap_zero_waste_area?: number;
  tudor_with_openings_area?: number;
  tudor_zero_waste_area?: number;
  unknown_with_openings_area?: number;
  unknown_zero_waste_area?: number;
  shutter_quantity_siding_total?: number;
  combinedAreaWithTrim?: CombinedAreaWithTrimForFacade;
}

const PATHS_TO_MEASUREMENTS_IN_INCHES = ['roof.soffit_total_length'];

export class EstimationMeasurementParser {
  orgSettings: EhiOrgSettings | null;

  results: JobMeasurements;

  constructor(
    estimationMeasurements: Measurements,
    orgSettings: EhiOrgSettings,
  ) {
    this.orgSettings = orgSettings;

    const results = {
      ...this.roof(estimationMeasurements),
      ...this.windows(estimationMeasurements),
      ...this.siding(estimationMeasurements),
      ...this.facades(estimationMeasurements),
      ...this.shutters(estimationMeasurements),
      ...this.vents(estimationMeasurements),
      ...this.fascia(estimationMeasurements),
      ...this.gutters(estimationMeasurements),
      ...this.cornices(estimationMeasurements),
    };

    this.results = this.convertSpecificMeasurementsToFeet(results);
  }

  convertSpecificMeasurementsToFeet(results: JobMeasurements) {
    // most are in feet, but a few are in inches
    PATHS_TO_MEASUREMENTS_IN_INCHES.forEach((pathToMeasurement) => {
      const result = get(results, pathToMeasurement);
      if (result) set(results, pathToMeasurement, result / 12.0);
    });
    return results;
  }

  roof(estimationMeasurements: Measurements) {
    if (
      !estimationMeasurements ||
      !estimationMeasurements?.roof ||
      !estimationMeasurements?.summary
    )
      return {};
    const { facets } = estimationMeasurements.roof;
    const { roof } = estimationMeasurements.summary;
    if (!facets) return {};

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const results: any = { roof: { pitch: [] } };
    results.roof.pitch = facets.map((facet, index) => ({
      ...facet,
      label: facet.facet,
      id: index,
    }));

    results.roof.roof_total =
      roof?.roof_facets?.area ?? roof?.roof_facets?.length;
    results.roof.roof_count = roof?.roof_facets?.total;
    results.roof.ridge_total = roof?.ridges?.area ?? roof?.ridges?.length;
    results.roof.ridge_count = roof?.ridges?.total;
    results.roof.hip_total = roof?.hips?.area ?? roof?.hips?.length;
    results.roof.hip_count = roof?.hips?.total;
    results.roof.valley_total = roof?.valleys?.area ?? roof?.valleys?.length;
    results.roof.valley_count = roof?.valleys?.total;
    results.roof.eave_total = roof?.eaves?.area ?? roof?.eaves?.length;
    results.roof.eave_count = roof?.eaves?.total;
    results.roof.rake_total = roof?.rakes?.area ?? roof?.rakes?.length;
    results.roof.rake_count = roof?.rakes?.total;
    results.roof.flashing_total =
      roof?.flashing?.area ?? roof?.flashing?.length;
    results.roof.flashing_count = roof?.flashing?.total;
    results.roof.step_flashing_total =
      roof?.step_flashing?.area ?? roof?.step_flashing?.length;
    results.roof.step_flashing_count = roof?.step_flashing?.total;
    results.roof.soffit_total_area = roof?.soffits?.total_area;
    results.roof.soffit_total_length = roof?.soffits?.total_length;

    return results;
  }

  windows({ openings }: Measurements) {
    if (!openings) return {};
    const { windows } = openings;
    const results: Windows = {};

    if (!windows) return results;

    results.window_count_total = windows?.length;
    results.window_ui_total = windows.reduce((total, window) => {
      return total + parseInt(window.united_inches, 10);
    }, 0);
    return results;
  }

  /**
   * Older versions of measurements json only includes siding facades in the sides object.
   * We want to show all types of facades, this function will add any facades not included in sides
   * to the front side (we don't have information about what side the facade is actually on)
   */
  addMissingFacades(sides: Sides, facades: Facades) {
    const result = cloneDeep(sides);

    // Flatten sides to {[name]: area} so we can check if a facade already exists in sides before adding it
    let flattenedSides = {};
    Object.values(sides).forEach((side) => {
      side.area_per_label.forEach((facade) => {
        flattenedSides = { ...flattenedSides, ...facade };
      });
    });

    // Find any facades that are missing from sides
    const missingFacades: AreaPerLabelEntity[] = [];
    Object.values(facades).forEach((facadeArray) => {
      facadeArray.forEach(({ facade, area }) => {
        if (!(facade in flattenedSides)) {
          missingFacades.push({ [facade]: area });
        }
      });
    });

    // Add the missing facades to the front side
    if (missingFacades.length) {
      const existingFacades = result.front?.area_per_label || [];
      const mergedFacades = existingFacades.concat(missingFacades);
      result.front = result.front || { total: 0 };
      result.front.area_per_label = mergedFacades;
    }

    return result;
  }

  siding(estimationMeasurements: Measurements) {
    const { facades, summary, elevations } = estimationMeasurements;
    const results: Siding = {};
    if (!summary) return results;

    const sides = this.addMissingFacades(elevations?.sides || {}, facades);

    const flattenedFacades = flatten(
      Object.values(estimationMeasurements.facades || {}),
    );

    Object.values(sides).forEach((side) => {
      let per_side_total = 0;
      const unkConnectsToSidingMap: UnknownsConnectedToSidingMap = {};
      side.area_per_label.forEach((areas: AreaPerLabelEntity) => {
        const key = Object.keys(areas)[0];
        const area = flattenedFacades.find((facade) => facade.facade === key);
        const facade_total =
          area?.area_with_waste_factor_calculation?.with_openings ?? 0;
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        areas[key] = facade_total;
        per_side_total += facade_total;
        if (key.includes('UN'))
          unkConnectsToSidingMap[key] = area?.connects_to_siding;
      });
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-param-reassign
      side.total = per_side_total;
      results.unknownsConnectedToSidingMap = {
        ...results.unknownsConnectedToSidingMap,
        ...unkConnectsToSidingMap,
      };
    });

    results.siding_zero_waste_area_total = summary?.siding_waste?.zero;
    results.siding_with_openings_area_total =
      summary?.siding_waste?.with_openings;
    results.siding_area_total = PartialSidingUtils.shouldCalculateWithOpenings(
      this.orgSettings,
    )
      ? summary?.siding_waste?.with_openings
      : summary?.siding_waste?.zero;
    results.siding_facade_area = summary?.area?.facades.siding;
    results.siding_unknown_area_total = summary?.area?.unknown?.siding;
    results.sides = sides;
    results.brick_with_openings_area = summary?.brick_waste?.with_openings;
    results.brick_zero_waste_area = summary?.brick_waste?.zero;
    results.stucco_with_openings_area = summary?.stucco_waste?.with_openings;
    results.stucco_zero_waste_area = summary?.stucco_waste?.zero;
    results.metal_with_openings_area = summary?.metal_waste?.with_openings;
    results.metal_zero_waste_area = summary?.metal_waste?.zero;
    results.stone_with_openings_area = summary?.stone_waste?.with_openings;
    results.stone_zero_waste_area = summary?.stone_waste?.zero;
    results.wrap_with_openings_area = summary?.wrap_waste?.with_openings;
    results.wrap_zero_waste_area = summary?.wrap_waste?.zero;
    results.tudor_with_openings_area = summary?.tudor_waste?.with_openings;
    results.tudor_zero_waste_area = summary?.tudor_waste?.zero;
    results.unknown_with_openings_area = summary?.unknown_waste?.with_openings;
    results.unknown_zero_waste_area = summary?.unknown_waste?.zero;

    // Siding Line Segment Adjustment
    results.shutter_quantity_siding_total =
      summary?.accessories?.shutter_qty.siding;

    // if the facade connects to siding, we already calculate it so we have to remove it from here
    const unknownFacades = facades?.unknown ?? [];
    unknownFacades.forEach((facade) => {
      if (facade.connects_to_siding) {
        results.unknown_with_openings_area = results?.unknown_with_openings_area
          ? results.unknown_with_openings_area -
            facade.area_with_waste_factor_calculation.with_openings
          : undefined;
      }
    });

    results.combinedAreaWithTrim = PartialSidingUtils.getCombinedAreaForFacades(
      Object.values(estimationMeasurements.facades).flat(),
      PartialSidingUtils.shouldCalculateWithOpenings(this.orgSettings),
      PartialSidingUtils.shouldCalculateWithTrim(this.orgSettings),
      PartialSidingUtils.shouldCalculateWithOpeningsTrim(this.orgSettings),
    );

    return results;
  }

  facades({ summary }: Measurements) {
    if (!summary) return {};
    const { openings, corners, trim } = summary;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const results: any = {};

    results.openings_top_length_siding_total = openings?.tops_length?.siding;
    results.openings_top_length_other_total = openings?.tops_length?.other;
    results.openings_sill_length_siding_total = openings?.sills_length?.siding;
    results.openings_sill_length_other_total = openings?.sills_length?.other;
    results.openings_sides_length_siding_total = openings?.sides_length?.siding;
    results.openings_sides_length_other_total = openings?.sides_length?.other;
    results.inside_corner_siding_count_total =
      corners?.inside_corners_qty?.siding;
    results.inside_corner_other_count_total =
      corners?.inside_corners_qty?.other;
    results.outside_corner_siding_count_total =
      corners?.outside_corners_qty?.siding;
    results.outside_corner_other_count_total =
      corners?.outside_corners_qty?.other;
    results.inside_corner_siding_length_total =
      corners?.inside_corners_len?.siding;
    results.inside_corner_other_length_total =
      corners?.inside_corners_len?.other;
    results.outside_corner_siding_length_total =
      corners?.outside_corners_len?.siding;
    results.outside_corner_other_length_total =
      corners?.outside_corners_len?.other;
    results.level_starter_siding_length_total = trim?.level_starter?.siding;
    results.level_starter_other_length_total = trim?.level_starter?.other;
    results.sloped_trim_siding_length_total = trim?.sloped_trim?.siding;
    results.sloped_trim_other_length_total = trim?.sloped_trim?.other;
    results.vertical_trim_siding_length_total = trim?.vertical_trim?.siding;
    results.vertical_trim_other_length_total = trim?.vertical_trim?.other;

    return results;
  }

  shutters({ summary }: Measurements) {
    if (!summary) return {};
    const { accessories } = summary;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const results: any = {};
    results.shutter_quantity_siding_total = accessories?.shutter_qty?.siding;
    results.shutter_quantity_other_total = accessories?.shutter_qty?.other;

    return results;
  }

  vents({ summary }: Measurements) {
    if (!summary) return {};
    const { accessories } = summary;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const results: any = {};
    results.vent_quantity_other_total = accessories?.vents_qty?.other;
    results.vent_quantity_siding_total = accessories?.vents_qty?.siding;

    return results;
  }

  fascia({ summary }: Measurements) {
    if (!summary) return {};
    const { roofline } = summary;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const results: any = {};
    results.eaves_fascia_total = roofline?.eaves_fascia?.length;
    results.rakes_fascia_total = roofline?.rakes_fascia?.length;
    results.level_frieze_board_length_total =
      roofline?.level_frieze_board?.length;
    results.level_frieze_board_avg_depth =
      roofline?.level_frieze_board?.avg_depth;
    results.level_frieze_board_soffit_area =
      roofline?.level_frieze_board?.soffit_area;
    results.sloped_frieze_board_length_total =
      roofline?.sloped_frieze_board?.length;
    results.sloped_frieze_board_avg_depth =
      roofline?.sloped_frieze_board?.avg_depth;
    results.sloped_frieze_board_soffit_area =
      roofline?.sloped_frieze_board?.soffit_area;

    return results;
  }

  gutters({ summary }: Measurements) {
    if (!summary) return {};
    const { roof } = summary;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const results: any = {};
    results.gutter_length_total = roof?.gutters?.total_length;
    results.downspout_length_total = roof?.downspouts?.total_length;

    return results;
  }

  cornices({ summary }: Measurements) {
    if (!summary) return {};
    const { roof } = summary;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const results: any = {};
    results.cornice_returns_length_total =
      roof?.cornices?.returns?.total_length;
    results.cornice_returns_count_total = roof?.cornices?.returns?.total_count;

    return results;
  }
}
