import { Box, Heading } from '@hover/blueprint';
import { lowerCase, startCase } from 'lodash';

interface AnswerSectionProps {
  name: string;
}

export const AnswerSection: React.FC<AnswerSectionProps> = ({
  name,
  children,
}) => {
  return (
    <Box data-testid={`InputSummary-${name}`} flexDirection="column" my={300}>
      <Box mb={400}>
        <Heading size={500}>
          {startCase(lowerCase(name.replace('_', ' ')))}
        </Heading>
      </Box>
      {children}
    </Box>
  );
};
