import { useCallback } from 'react';

import { Box, Heading, Icon } from '@hover/blueprint';
import { iChevronRight } from '@hover/icons';
import moment from 'moment';
import styled from 'styled-components';

import { FormattedNumber } from 'src/components/FormattedNumber';
import { StatusBadge } from 'src/features/exteriorEstimator/components/EstimationTool/Proposal/StatusBadge';
import { isValidProjectManagementSignatureRequestState } from 'src/features/exteriorEstimator/constants/ProjectManagementSignatureRequestStates';
import { ProposalViews, Proposal } from 'src/features/exteriorEstimator/types';
import { sortProposalsByCreatedAtDesc } from 'src/features/exteriorEstimator/utils/proposalUtils';

import { Props } from './ProposalModals';

export const ProposalCreateTime = styled.span`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.defaultGray};
`;

interface IProps {
  proposals: Proposal[];
  setSelectedProposal: Props['setSelectedProposal'];
  setProposalModalView: Props['setProposalModalView'];
  hideTotalPrice: boolean;
}

export const ProposalListBody: React.FC<IProps> = ({
  proposals,
  setSelectedProposal,
  setProposalModalView,
  hideTotalPrice,
}) => {
  const handleClickProposal = useCallback((id: string) => {
    setSelectedProposal({ id });
    setProposalModalView(ProposalViews.SHOW);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortedProposals = sortProposalsByCreatedAtDesc(proposals);

  return (
    <Box
      flex={1}
      flexDirection="column"
      alignItems="stretch"
      paddingBottom={500}
      data-testid="proposalsList"
    >
      {sortedProposals.map((proposal) => (
        <Box
          marginLeft={400}
          marginRight={400}
          marginTop={400}
          key={proposal.id}
          justifyContent="space-between"
          flexShrink={0}
          _hover={{ cursor: 'pointer', backgroundColor: 'primary100' }}
          onClick={() => handleClickProposal(proposal.id)}
          borderRadius={5}
          padding={100}
          paddingLeft={200}
          data-testid={`proposalClick-${proposal.id}`}
        >
          <Box justifyContent="space-between" width={1} flexWrap="wrap">
            <Box flexDirection="column" flex={1} marginRight={200}>
              <Box flexDirection="row">
                <Box>
                  <Heading size={200}>Proposal</Heading>
                </Box>
                <Box marginLeft={300}>
                  <Heading size={200}>
                    {!hideTotalPrice && (
                      <FormattedNumber
                        value={proposal.totalPrice ?? 0}
                        format="$0,0.00"
                        data-testid="proposalTotal"
                      />
                    )}
                  </Heading>
                </Box>
              </Box>
              <Box flexDirection="row">
                <Box>
                  <ProposalCreateTime>
                    {moment(proposal.createdAt).format('YYYY/MM/DD')}
                  </ProposalCreateTime>
                </Box>
                <Box marginLeft={300}>
                  <ProposalCreateTime>
                    {moment(proposal.createdAt).format('HH:mm A')}
                  </ProposalCreateTime>
                </Box>
              </Box>
            </Box>
            {proposal.signatureRequest && (
              <>
                {isValidProjectManagementSignatureRequestState(
                  proposal.signatureRequest.state,
                ) && (
                  <StatusBadge
                    signatureRequestState={proposal.signatureRequest.state}
                    marginY={200}
                  />
                )}
              </>
            )}
          </Box>
          <Box alignItems="center">
            <Icon icon={iChevronRight} color="neutral600" />
          </Box>
        </Box>
      ))}
    </Box>
  );
};
