import {
  EHI_LOCAL_STORAGE_OAUTHREDIRECT_KEY,
  EHI_LOCAL_STORAGE_OAUTHURL_KEY,
} from 'src/constants';

export const getRedirectUrlFromCache = (): string | null => {
  const url = localStorage.getItem(EHI_LOCAL_STORAGE_OAUTHREDIRECT_KEY);
  return url;
};

export const saveRedirectUrlToCache = (url: string): void => {
  localStorage.setItem(EHI_LOCAL_STORAGE_OAUTHREDIRECT_KEY, url);
};

export const clearRedirectUrlFromCache = (): void => {
  localStorage.removeItem(EHI_LOCAL_STORAGE_OAUTHREDIRECT_KEY);
};

export const clearOauthUrlFromCache = (): void => {
  localStorage.removeItem(EHI_LOCAL_STORAGE_OAUTHURL_KEY);
};
