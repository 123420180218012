import React, { useEffect, useCallback } from 'react';

import { useQuery } from '@apollo/client';
import { Box, Heading, Loader } from '@hover/blueprint';
import { useSelector, useDispatch } from 'react-redux';

import {
  EstimationConfigTemplateCollectionTypeEnum,
  TradeTypeEnum,
} from 'src/api/graphql-global-types';
import { GET_TEMPLATE_COLLECTIONS } from 'src/api/queries/queries';
import {
  configTemplateCollectionsForOrg_estimationConfigTemplateCollectionsForOrg_templateSections as TemplateSection,
  configTemplateCollectionsForOrg_estimationConfigTemplateCollectionsForOrg_templateSections_templatesByTradeType_templates as Template,
} from 'src/api/types/configTemplateCollectionsForOrg';
import { SearchFilterInput } from 'src/components/MaterialList/SearchFilterInput';
import { TemplateCardsSection } from 'src/components/MaterialList/TemplateCardsSection';
import { EstimatorResponsiveWrapper } from 'src/features/exteriorEstimator/components/common/EstimatorResponsiveWrapper';
import { EstimatorError } from 'src/features/exteriorEstimator/components/EstimationTool/Error/EstimatorError';
import { useMachete } from 'src/features/exteriorEstimator/hooks';
import { initializeSelectedTemplates } from 'src/features/exteriorEstimator/redux/actions/templatesActions';
import {
  getJobDetails,
  getGeometry,
  getMetadata,
  getSelectedTemplateIds,
  getTemplateSearchFilter,
  getCurrentQuestionCategoryIndex,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { getTemplateIdsFromUrl } from 'src/features/exteriorEstimator/utils/templateUtils';
import {
  REACT_NATIVE_EVENTS,
  triggerReactNativeEvent,
} from 'src/features/project/util/nativeMobileEvents';
import {
  useEffectOnMount,
  useMaterialListTemplates,
  useToastEhi,
  ToastStatusEnum,
} from 'src/hooks';
import { getMaterialListFeature, getUserOrgId } from 'src/redux/selectors';
import { templateCollectionsHaveEmptyCustomTemplates } from 'src/utils/templateUtils';

export const SelectMaterialListTemplates: React.FC = () => {
  const dispatch = useDispatch();
  const { initializeMacheteData } = useMachete();
  const {
    dispatchTemplatesFromSections,
    toggleTemplateAndUnselectOthersInSameTrade,
  } = useMaterialListTemplates();

  const orgId = useSelector(getUserOrgId);
  const geometryUrl = useSelector(getJobDetails)?.threeDFiles?.geometryUrl;
  const metadataUrl = useSelector(getJobDetails)?.threeDFiles?.metadataUrl;
  const materialListFeatureEnabled = useSelector(getMaterialListFeature);
  const currentQuestionCategoryIndex = useSelector(
    getCurrentQuestionCategoryIndex,
  );
  const toast = useToastEhi();
  const geometry = useSelector(getGeometry);
  const metadata = useSelector(getMetadata);

  let selectedTemplateIds = useSelector(getSelectedTemplateIds);
  const templateSearchFilter = useSelector(getTemplateSearchFilter);

  if (!selectedTemplateIds) {
    selectedTemplateIds = getTemplateIdsFromUrl();
    dispatch(initializeSelectedTemplates({ templateIds: selectedTemplateIds }));
  }

  const {
    data: templateCollectionsData,
    loading: templateCollectionsLoading,
    error: templateCollectionsError,
  } = useQuery(GET_TEMPLATE_COLLECTIONS, {
    variables: {
      orgId,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      toast({
        id: 'error-retrieving-template-collections',
        description:
          'Sorry, there was a problem retrieving your collection of templates.',
        status: ToastStatusEnum.ERROR,
      });
    },
  });

  const handleCardClick = useCallback(
    (template: Template, templateTradeType: TradeTypeEnum) => {
      toggleTemplateAndUnselectOthersInSameTrade(
        template.id,
        templateTradeType,
      );
    },
    [templateCollectionsData, selectedTemplateIds],
  );

  useEffect(() => {
    triggerReactNativeEvent(REACT_NATIVE_EVENTS.START_RECALCULATION_EVENT);
    triggerReactNativeEvent(REACT_NATIVE_EVENTS.SHOW_HEADER_CLOSE_BUTTON_EVENT);
  }, []);

  useEffect(() => {
    // safely deconstruct templateCollectionsData so that we don't get an error if it's undefined or its templateSections is undefined
    const {
      estimationConfigTemplateCollectionsForOrg: { templateSections } = [],
    } = templateCollectionsData || {};

    if (!templateSections?.length) {
      return;
    }

    dispatchTemplatesFromSections(templateSections);
  }, [templateCollectionsData]);

  useEffectOnMount(() => {
    if (!geometry && !metadata && geometryUrl && metadataUrl) {
      initializeMacheteData(geometryUrl, metadataUrl);
    }
  });

  // render loading spinner if template collections are still loading
  if (templateCollectionsLoading) {
    return (
      <Box flex-direction="column" width="100%" height="100vh">
        <EstimatorResponsiveWrapper flex={1}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Loader
              size="huge"
              data-testid="SelectMaterialListTemplates-Loader"
            />
          </Box>
        </EstimatorResponsiveWrapper>
      </Box>
    );
  }

  // if an error occurred while fetching the template collections, show the error page
  if (templateCollectionsError) {
    return <EstimatorError />;
  }

  // otherwise get the template sections from the data
  const {
    estimationConfigTemplateCollectionsForOrg: { templateSections },
  } = templateCollectionsData;
  const customTemplatesAreEmpty =
    templateCollectionsHaveEmptyCustomTemplates(templateSections);

  const isSelectingTemplates = currentQuestionCategoryIndex < 1;
  const showMatListTemplateSearchFilter =
    isSelectingTemplates && materialListFeatureEnabled;

  // and render the template cards
  return (
    <Box flex-direction="column" width="100%" height="100vh">
      <EstimatorResponsiveWrapper data-testid="select-templates-page" flex={1}>
        <Box flexDirection="column" width="100%">
          <Box marginBottom="24px" flexDirection="column">
            <Heading
              size={{ base: 500, tablet: 600 }}
              marginBottom={20}
              marginRight={800}
              whiteSpace="nowrap"
            >
              Select Templates
            </Heading>

            {showMatListTemplateSearchFilter && <SearchFilterInput />}
          </Box>

          {templateSections.map((section: TemplateSection) => {
            return section.collectionType ===
              EstimationConfigTemplateCollectionTypeEnum.CUSTOM &&
              customTemplatesAreEmpty ? null : (
              <TemplateCardsSection
                templateCollection={section}
                key={`${section.name}-template-cards-section`}
                handleCardClick={handleCardClick}
                searchFilterValue={templateSearchFilter}
                enableCollapseFunctionality={!customTemplatesAreEmpty}
              />
            );
          })}
        </Box>
      </EstimatorResponsiveWrapper>
    </Box>
  );
};
