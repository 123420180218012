/* eslint-disable no-underscore-dangle */

import { useState, useEffect } from 'react';

import { TextInput } from '@hover/blueprint';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
  inputs,
  poNumberValueField,
} from 'src/features/projectManagement/constants';
import * as actions from 'src/features/projectManagement/redux/actions';
import {
  isEmpty,
  isValidLength,
  hasNoEmojis,
} from 'src/features/projectManagement/utils/InputValidator';
import { RootState, RootAction, ChangeEvent } from 'src/types';

import InputWrapper from '../InputWrapper';

export const mapStateToProps = (state: RootState) => ({
  poNumber:
    state?.estimatorProductionTools?.orderDetailsForm?.values?.poNumber ?? '',
});

export const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      updateOrderDetailsForm: actions.updateOrderDetailsForm,
    },
    dispatch,
  );

interface ComponentProps {
  width?: number;
  labelSize?: number;
}

type Props = ComponentProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const { labelText } = inputs[poNumberValueField];
const inputId = poNumberValueField;

export const PONumber: React.FC<Props> = ({
  labelSize,
  width,
  poNumber: initialValue,
  updateOrderDetailsForm,
}) => {
  const [poNumber, setPoNumber] = useState(initialValue);
  const [isPristine, setIsPristine] = useState(true);
  const [isPoNumberInvalid, setIsPoNumberInvalid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const isValidUserInput = (value: string | undefined) => {
    if (value === undefined) return false;
    return isValidLength(22)(value) && hasNoEmojis(value);
  };

  useEffect(() => {
    // Update redux with prefilled data on component mount
    const isInvalidInput = !isValidUserInput(poNumber) || isEmpty(poNumber);
    updateOrderDetailsForm({
      values: {
        [inputs[inputId].valueField]: poNumber,
      },
      errors: {
        [inputs[inputId].errorField]: isInvalidInput,
      },
    });
  }, []);

  useEffect(() => {
    updateOrderDetailsForm({
      values: { [poNumberValueField]: poNumber },
    });
  }, [poNumber, updateOrderDetailsForm]);

  const checkIfEmpty = (targetValue: string) => {
    const _isEmpty = isEmpty(targetValue);
    const _errorMessage = _isEmpty ? `PO number cannot be empty.` : '';
    setIsPoNumberInvalid(_isEmpty);
    setErrorMessage(_errorMessage);
    updateOrderDetailsForm({
      errors: { [inputs[poNumberValueField].errorField]: _isEmpty },
    });
  };

  const handleChange = ({ target: { value } }: ChangeEvent) => {
    setIsPristine(false);
    if (!isValidUserInput(value)) return;
    setPoNumber(value);
    checkIfEmpty(value);
  };

  return (
    <InputWrapper
      isRequired
      isPristine={isPristine}
      labelText={labelText}
      isInvalid={isPoNumberInvalid}
      errorMessage={errorMessage}
      inputId={inputId}
      width={width}
      labelSize={labelSize}
    >
      <TextInput
        autoFocus
        name={inputId}
        id={inputId}
        display="block"
        onChange={handleChange}
        value={poNumber}
        data-testid="purchaseOrderNumberInput"
        isInvalid={isPoNumberInvalid}
        maxlength="22"
      />
    </InputWrapper>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps, mapDispatchToProps)(PONumber);
