import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { TradeTypeEnum } from 'src/api/graphql-global-types';
import { AreaCounter } from 'src/features/exteriorEstimator/components/EstimationTool/BottomBar/AreaCounter';
import { UpdateAnswer } from 'src/features/exteriorEstimator/redux/actions/answerActions';
import {
  getJob,
  getQuestionResponses,
  getSidingTotalArea,
  getSidingTotalQuestion,
  getPartialsMeasurements,
  getHdfMeasurements,
  getSidingWithOpeningsAreaQuestion,
  getSidingWithOpeningsTotalArea,
  getStoneWithOpeningsTotalArea,
  getStoneTotalQuestion,
} from 'src/features/exteriorEstimator/redux/sagas/selectors/estimatorSelectors';
import { LineSegmentCalculator } from 'src/features/exteriorEstimator/utils/LineSegmentCalculator';
import { LineSegmentCalculatorHdf } from 'src/features/exteriorEstimator/utils/LineSegmentCalculatorHdf';
import { getOrgSettings } from 'src/redux/selectors';

type Props = {
  updateAnswer: (updateProps: UpdateAnswer) => void;
  trade: TradeTypeEnum.SIDING | TradeTypeEnum.STONE;
};

export const SelectedPartialSidingAreaCounter: React.FC<Props> = ({
  trade,
  updateAnswer,
}) => {
  const sidingTotalQuestion = useSelector(getSidingTotalQuestion);
  const sidingTotalArea = useSelector(getSidingTotalArea);
  const sidingWithOpeningsAreaQuestion = useSelector(
    getSidingWithOpeningsAreaQuestion,
  );
  const sidingWithOpeningsTotalArea = useSelector(
    getSidingWithOpeningsTotalArea,
  );

  const { plainMeasurements, fullMeasurements } = useSelector(getJob);
  const partialsMeasurements = useSelector(getPartialsMeasurements);
  const questionResponses = useSelector(getQuestionResponses);
  const hdfMeasurements = useSelector(getHdfMeasurements);
  const orgSettings = useSelector(getOrgSettings);

  const stoneTotalQuestion = useSelector(getStoneTotalQuestion);
  const stoneWithOpeningsTotalArea = useSelector(getStoneWithOpeningsTotalArea);

  const areaTotalQuestion = {
    [TradeTypeEnum.SIDING]: sidingTotalQuestion,
    [TradeTypeEnum.STONE]: stoneTotalQuestion,
  }[trade];

  const areaWithOpeningsQuestion = {
    [TradeTypeEnum.SIDING]: sidingWithOpeningsAreaQuestion,
    [TradeTypeEnum.STONE]: stoneTotalQuestion,
  }[trade];

  const totalArea = {
    [TradeTypeEnum.SIDING]: sidingTotalArea,
    [TradeTypeEnum.STONE]: stoneWithOpeningsTotalArea,
  }[trade];

  const areaWithOpenings = {
    [TradeTypeEnum.SIDING]: sidingWithOpeningsTotalArea,
    [TradeTypeEnum.STONE]: stoneWithOpeningsTotalArea,
  }[trade];

  useEffect(() => {
    if (
      !!plainMeasurements &&
      !!fullMeasurements &&
      !!areaTotalQuestion &&
      !!orgSettings
    ) {
      if (!!partialsMeasurements) {
        const { edges, facades, windowsForFacade } = partialsMeasurements;
        const hdfLineSegmentCalculator = new LineSegmentCalculatorHdf({
          edges,
          facades,
          windowsForFacade,
          questionResponses,
          hdfMeasurements,
          orgSettings,
          isStone: trade === TradeTypeEnum.STONE,
        });
        // only update the store if the calculated value
        // does not match what's already in redux, using hdf calculator
        if (hdfLineSegmentCalculator.selectedAreaWithTrim !== totalArea) {
          updateAnswer({
            questionId: areaTotalQuestion?.id,
            answer: hdfLineSegmentCalculator.selectedAreaWithTrim,
          });
        }

        // In the case where and org is using the old formulas we
        // need to continue to update the siding_with_openings_area_total in
        // using the HDF calculator
        if (
          !!areaWithOpeningsQuestion &&
          hdfLineSegmentCalculator.selectedAreaWithTrim !== areaWithOpenings
        ) {
          updateAnswer({
            questionId: areaWithOpeningsQuestion?.id,
            answer: hdfLineSegmentCalculator.selectedAreaWithTrim,
          });
        }
      } else {
        // only update the store if the calculated value
        // does not match what's already in redux, using non-hdf calculator
        const lineSegmentCalculator = new LineSegmentCalculator({
          pristinePlainMeasurements: plainMeasurements,
          estimationJson: fullMeasurements,
          questionResponses,
          orgSettings,
          trade,
        });

        const { selectedAreaWithTrim } = lineSegmentCalculator;
        if (selectedAreaWithTrim !== totalArea) {
          updateAnswer({
            questionId: areaTotalQuestion?.id,
            answer: selectedAreaWithTrim,
          });
        }

        // In the case where and org is using the old formulas we
        // need to continue to update the siding_with_openings_area_total in
        if (
          !!areaWithOpeningsQuestion &&
          selectedAreaWithTrim !== areaWithOpenings
        ) {
          updateAnswer({
            questionId: areaWithOpeningsQuestion?.id,
            answer: selectedAreaWithTrim,
          });
        }
      }
    }
  }, [
    areaTotalQuestion,
    areaWithOpenings,
    areaWithOpeningsQuestion,
    fullMeasurements,
    hdfMeasurements,
    orgSettings,
    partialsMeasurements,
    plainMeasurements,
    questionResponses,
    totalArea,
    trade,
    updateAnswer,
  ]);

  return <AreaCounter area={totalArea} />;
};
