import React from 'react';

import { Box, Body, Badge } from '@hover/blueprint';
import { useSelector } from 'react-redux';

import type {
  projectManagementProductionList_projectManagementProductionList_estimateGroup_salesOpportunity_job as Job,
  projectManagementProductionList_projectManagementProductionList as ProjectManagementProductionList,
} from 'src/api/types/projectManagementProductionList';
import {
  VariationMatchingToggle,
  MatchingGroups,
} from 'src/components/VariationMatchingToggle';
import { buildAddressNameFromJob } from 'src/features/project/util/utils';
import { useIsMobileBreakpoint } from 'src/hooks/useIsMobileBreakpoint';
import { getMaterialListFeature } from 'src/redux/selectors';

import { RecalculateMaterialListButton } from '../ProjectScopeTypeListControls/RecalculateMaterialListButton';
import { HelpDrawerButton } from './HelpDrawerButton';
import { TipsDrawerButton } from './TipsDrawerButton';

type Props = {
  job: Job;
  productionList: ProjectManagementProductionList;
};

export const ProjectScopeHeader = ({ job, productionList }: Props) => {
  const materialListFeatureEnabled = useSelector(getMaterialListFeature);
  const isMobile = useIsMobileBreakpoint({ ssr: false });

  return (
    <Box
      padding={{
        base: '24px',
        tablet: '0px',
      }}
      backgroundColor="white"
      height="100px"
      data-testid="ProjectScope-Header"
    >
      <Box
        padding={!isMobile ? '0px 40px' : '0px'}
        width="100%"
        justifyContent="space-between"
      >
        <Box flexDirection="column" justifyContent="center">
          <Body
            margin="0"
            fontSize={isMobile ? 500 : 100}
            fontWeight={isMobile ? 700 : 600}
            color={isMobile ? 'black' : 'neutral.500'}
            whiteSpace={isMobile ? 'normal' : 'nowrap'}
            data-testid="ProjectScope-JobName"
          >
            {buildAddressNameFromJob(job, true)}
          </Body>
          {!isMobile && (
            <Box flexDirection="row" alignItems="center">
              <Body
                fontWeight="700"
                fontSize={{
                  base: 600,
                  tablet: 600,
                }}
                margin="0px 0px 16px 0px"
                whiteSpace="nowrap"
              >
                {`${
                  materialListFeatureEnabled ? 'Material' : 'Production'
                } List`}
              </Body>
              {materialListFeatureEnabled && (
                <Badge colorScheme="warning" margin="0px 0px 16px 16px">
                  BETA
                </Badge>
              )}
            </Box>
          )}
        </Box>
        <Box alignItems="center">
          {!isMobile && (
            <VariationMatchingToggle
              type={MatchingGroups.PRODUCTION_LIST}
              id={productionList.id}
              enableVariationMatching={productionList.enableVariationMatching}
            />
          )}
          {materialListFeatureEnabled && (
            <>
              {!isMobile && <HelpDrawerButton />}
              {!isMobile && <TipsDrawerButton />}
              {isMobile && <RecalculateMaterialListButton jobId={job?.id} />}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
