import { find, isNil } from 'lodash';

import {
  TradeTypeCategoryEnum,
  TradeTypeEnum,
} from 'src/api/graphql-global-types';
import { tradeTypes_tradeTypes as TradeType } from 'src/api/types/tradeTypes';
import { InputWithValue } from 'src/features/exteriorEstimator/components/EstimationTool/CustomQuestionPages/WasteFactorPage/WasteFactorSlider';
import { JobMeasurements } from 'src/features/exteriorEstimator/types';
import {
  inchesToFeet,
  squaredFeetToRoofSquares,
} from 'src/lib/conversionUtils';
import { EhiOrgSettings } from 'src/redux/reducers/ehiReducer';

export class WasteFactorCalculator {
  measurements: JobMeasurements;

  measurementQuestionsAndResponses: InputWithValue[];

  tradeTypes: TradeType[];

  orgSettings: EhiOrgSettings;

  constructor(
    measurements: JobMeasurements,
    measurementQuestionsAndResponses: InputWithValue[],
    tradeTypes: TradeType[],
    orgSettings: EhiOrgSettings,
  ) {
    this.measurements = measurements;
    this.measurementQuestionsAndResponses = measurementQuestionsAndResponses;
    this.tradeTypes = tradeTypes;
    this.orgSettings = orgSettings;
  }

  private roofCalculator = () => {
    const roofTotal =
      find(this.measurementQuestionsAndResponses, {
        argument: 'roof_total',
      })?.value ??
      this.measurements.roof?.roof_total ??
      0;
    return squaredFeetToRoofSquares(roofTotal);
  };

  private sidingCalculator = () => {
    const sidingTotalQuestion = find(this.measurementQuestionsAndResponses, {
      argument: 'siding_area_total',
    });
    const sidingWithOpeningsAreaQuestion = find(
      this.measurementQuestionsAndResponses,
      {
        argument: 'siding_with_openings_area_total',
      },
    );
    const sidingTotalMeasurementsValue = this.measurements.siding_area_total;

    // if we have generic siding_area_total input use that value
    if (!isNil(sidingTotalQuestion)) {
      return sidingTotalQuestion.value ?? 0;
    }
    // else if we have the siding_with_openings_area_total input use that value
    if (!isNil(sidingWithOpeningsAreaQuestion)) {
      return sidingWithOpeningsAreaQuestion.value ?? 0;
    }
    // else if we have the siding_area_total measurements value use that
    if (!isNil(sidingTotalMeasurementsValue)) {
      return sidingTotalMeasurementsValue;
    }
    return 0;
  };

  private stoneCalculator = () => {
    const stoneTotalQuestion = find(this.measurementQuestionsAndResponses, {
      argument: 'stone_with_openings_area_measurement',
    });

    // if we have generic siding_area_total input use that value
    if (!isNil(stoneTotalQuestion)) {
      return stoneTotalQuestion.value ?? 0;
    }

    // else if we have the siding_area_total measurements value use that
    const stoneTotalMeasurementsValue =
      this.measurements.stone_with_openings_area;

    if (!isNil(stoneTotalMeasurementsValue)) {
      return stoneTotalMeasurementsValue;
    }
    return 0;
  };

  private guttersCalculator = () => {
    const downspoutLengthTotal =
      find(this.measurementQuestionsAndResponses, {
        argument: 'downspout_length_total',
      })?.value ??
      this.measurements.downspout_length_total ??
      0;

    const eaveTotal =
      find(this.measurementQuestionsAndResponses, {
        argument: 'eave_total',
      })?.value ??
      this.measurements.roof?.eave_total ??
      0;
    return inchesToFeet(downspoutLengthTotal) + eaveTotal;
  };

  private fasciaCalculator = () => {
    const rakeTotal =
      find(this.measurementQuestionsAndResponses, {
        argument: 'rake_total',
      })?.value ??
      this.measurements.roof?.rake_total ??
      0;
    const eaveTotal =
      find(this.measurementQuestionsAndResponses, {
        argument: 'eave_total',
      })?.value ??
      this.measurements.roof?.eave_total ??
      0;
    return rakeTotal + eaveTotal;
  };

  private soffitCalculator = () => {
    return (
      find(this.measurementQuestionsAndResponses, {
        argument: 'soffit_total_area',
      })?.value ??
      this.measurements.roof?.soffit_total_area ??
      0
    );
  };

  public getTradeTotalAmount(
    tradeType: TradeTypeEnum,
    tradeTypeCategory: TradeTypeCategoryEnum,
  ) {
    switch (tradeTypeCategory) {
      case TradeTypeCategoryEnum.ROOF:
        return this.roofCalculator();
      case TradeTypeCategoryEnum.SIDING:
        switch (tradeType) {
          case TradeTypeEnum.SIDING: {
            return this.sidingCalculator();
          }
          case TradeTypeEnum.STONE: {
            return this.stoneCalculator();
          }
          default:
            return 0;
        }
      case TradeTypeCategoryEnum.GENERIC:
        switch (tradeType) {
          case TradeTypeEnum.SIDING: {
            return this.sidingCalculator();
          }
          case TradeTypeEnum.GUTTERS: {
            return this.guttersCalculator();
          }
          case TradeTypeEnum.FASCIA: {
            return this.fasciaCalculator();
          }
          case TradeTypeEnum.SOFFIT: {
            return this.soffitCalculator();
          }
          default:
            return 0;
        }
      default:
        return 0;
    }
  }
}
