import { Project } from '@hover/breaker-react';
import * as Sentry from '@sentry/react';
import { SplitSdk } from '@splitsoftware/splitio-react';

import * as flags from 'src/lib/FeatureFlag/flags';
import { getSplitApiKey } from 'src/utils/EnvUtils';

interface Client extends SplitIO.IClient {
  _getTreatment?: (
    splitName: string,
    attributes?: SplitIO.Attributes,
  ) => SplitIO.Treatment;
}

interface Status {
  isInitializing: boolean;
  isReady: boolean;
}

export type TrafficType = 'user' | 'org';

interface DefaultAttributes {
  partnerId?: string;
}

export class Split {
  factory: SplitIO.IBrowserSDK | undefined = undefined;

  client: Client | null = null;

  status: Status = {
    isInitializing: false,
    isReady: false,
  };

  config: SplitIO.IBrowserSettings = {
    core: {
      authorizationKey: getSplitApiKey(),
      key: 'anonymous',
      trafficType: 'user',
    },
  };

  defaultAttributes: DefaultAttributes = {};

  private initializePromise: Promise<Split> | null = null;

  async init({
    orgId,
    userId,
    config,
    defaultAttributes,
  }: {
    orgId: number;
    userId: string;
    config?: SplitIO.IBrowserSettings;
    defaultAttributes: DefaultAttributes;
  }) {
    if (this.initializePromise) return this.initializePromise;

    this.defaultAttributes = defaultAttributes;

    this.initConfig({ orgId, config });
    this.factory = SplitSdk(this.config);

    this.client = this.factory.client(userId, 'user');
    this.status = { isInitializing: true, isReady: false };

    const clientPromise: Promise<Split> = new Promise((resolve) => {
      const { client, status } = this;

      return client?.on(client.Event.SDK_READY, () => {
        status.isInitializing = false;
        status.isReady = true;
        resolve(this);
      });
    });

    this.initializePromise = clientPromise.then(() => this);
    return this.initializePromise;
  }

  initConfig({
    orgId,
    config,
  }: {
    orgId: number;
    config?: SplitIO.IBrowserSettings;
  }) {
    if (config) {
      // if passed config directly (eg. in tests), override config
      this.config = {
        ...this.config,
        ...config,
      };
    }
    this.config.core.key = orgId.toString();
    if (this.config.core.authorizationKey === 'localhost') {
      this.config.features = window.SPLIT_OVERRIDES ?? {}; // window.SPLIT_OVERRIDES used in browser tests
    }
  }

  convertSplitsToBreakerProject() {
    const splitProject: Project = {
      flags: [],
      key: 'Split',
      name: 'Split',
    };

    const customAttributes: SplitIO.Attributes = {
      // TS string conversion needed from Split SplitIO.SplitKey type.
      org_id: this.config.core.key.toString(),
      orgId: this.config.core.key.toString(),
    };

    if (this.defaultAttributes?.partnerId) {
      customAttributes.partnerId = this.defaultAttributes.partnerId;
    }

    splitProject.flags = Object.values(flags).map((flag) => ({
      key: flag,
      name: flag.replace(/_/g, '-'),
      description: flag,
      enabled: this.client?.getTreatment(flag, customAttributes) === 'on',
    }));

    return splitProject;
  }

  isEnabled(flagName: string) {
    const { isReady } = this.status;
    // Create a custom attributes object containing the logged-in
    // user's orgId as both orgId AND org_id, so Split targeting rules can use it with consistency
    // and without needing to know the exact case of the attribute name.
    const customAttributes: SplitIO.Attributes = {
      // TS string conversion needed from Split SplitIO.SplitKey type.
      org_id: this.config.core.key.toString(),
      orgId: this.config.core.key.toString(),
    };

    if (this.defaultAttributes?.partnerId) {
      customAttributes.partnerId = this.defaultAttributes.partnerId;
    }

    if (!isReady) return false;

    // Provide the orgId to the getTreatment() method via the custom
    // attributes, so orgId can be used in Split targeting rules.
    const treatment = this.client?.getTreatment?.(flagName, customAttributes);

    if (!treatment) {
      Sentry.captureMessage(
        `isEnabled expects valid flagName. Received: ${flagName}`,
      );
      return false;
    }
    switch (treatment) {
      case 'on':
        return true;
      case 'off':
        return false;
      case 'control':
        // https://help.split.io/hc/en-us/articles/360020528072-Control-treatment
        return false;
      default:
        Sentry.captureMessage(
          `isEnabled expects "on" or "off". Received: ${treatment}`,
        );
        return false;
    }
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default new Split();
