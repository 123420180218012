import React from 'react';

import { Box, Button, Heading, Icon } from '@hover/blueprint';
import { iAlertOctagon } from '@hover/icons';

export const RequiredInputsErrorScreen: React.FC<{ goBack: () => void }> = ({
  goBack,
}) => {
  return (
    <Box justifyContent="center" flexDirection="column" alignItems="center">
      <Icon icon={iAlertOctagon} size="large" />
      <Box marginY={200}>
        <Heading>Could not fetch data. Please try again.</Heading>
      </Box>
      <Button onClick={goBack}>Go back</Button>
    </Box>
  );
};
