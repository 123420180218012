import { memo } from 'react';

import { Box } from '@hover/blueprint';
import { useSelector } from 'react-redux';

import { LineItemTypeEnum } from 'src/api/graphql-global-types';
import { estimationQuantityUnits_estimationQuantityUnits as estimationQuantityUnit } from 'src/api/types/estimationQuantityUnits';
import type { productCatalogConfigOrgDistributors_productCatalogConfigOrgDistributors as Distributor } from 'src/api/types/productCatalogConfigOrgDistributors';
import type { projectManagementProductionList_projectManagementProductionList_estimateGroup as EstimateGroup } from 'src/api/types/projectManagementProductionList';
import {
  ListItemIdsByTrade,
  ListItemsByTrade,
} from 'src/features/project/types';
import { getTradeTypesSorted } from 'src/redux/selectors';

import { ProjectScopeTypeListControls } from '../ProjectScopeTypeListControls';
import { ProjectScopeTradeListMemoized } from './ProjectScopeTradeList';

type Props = {
  jobId: number;
  orgId: string;
  lineItemType: LineItemTypeEnum;
  selectedListItemIds: ListItemIdsByTrade | null;
  itemsByTrade: ListItemsByTrade;
  distributors?: Distributor[];
  productionListId: number;
  quantityUnits: estimationQuantityUnit[];
  openDownloadPdfModal: (lineItemType: LineItemTypeEnum) => void;
  openAddEditMaterialModal: (lineItemType: LineItemTypeEnum) => void;
  estimateGroup: EstimateGroup;
};

export const ProjectScopeTypeList = memo(
  ({
    jobId,
    orgId,
    lineItemType,
    selectedListItemIds,
    itemsByTrade,
    distributors,
    productionListId,
    quantityUnits,
    openDownloadPdfModal,
    openAddEditMaterialModal,
    estimateGroup,
  }: Props) => {
    const tradeTypesSorted = useSelector(getTradeTypesSorted).filter(
      (tradeType) => itemsByTrade[tradeType.tradeTypeEnumValue],
    );

    return (
      <Box
        marginY={200}
        backgroundColor="neutral.0"
        flexDirection="column"
        data-testid="ProjectScope-ListItemsTable"
      >
        <ProjectScopeTypeListControls
          jobId={jobId}
          lineItemType={lineItemType}
          selectedListItemIds={selectedListItemIds}
          itemsByTrade={itemsByTrade}
          openDownloadPdfModal={openDownloadPdfModal}
          openAddEditMaterialModal={openAddEditMaterialModal}
          estimateGroup={estimateGroup}
          productionListId={productionListId}
          sx={{ position: 'sticky', top: '0px', zIndex: 1 }}
        />

        <Box
          paddingX={500}
          flex="1 1 auto"
          flexDirection="column"
          position="relative"
        >
          {tradeTypesSorted.map((tradeTypeObj) => {
            // Map over the global set of sorted trade types, rendering the trade types
            // in order that exist in the pre-mapped `listItemsByTypeAndTrade` object.
            const tradeType = tradeTypeObj.tradeTypeEnumValue;

            return (
              <ProjectScopeTradeListMemoized
                key={tradeType}
                orgId={orgId}
                jobId={jobId}
                lineItemType={lineItemType}
                tradeType={tradeType}
                items={itemsByTrade[tradeType]}
                selectedItemsIds={
                  selectedListItemIds &&
                  selectedListItemIds[tradeType] && [
                    ...selectedListItemIds[tradeType],
                  ]
                }
                distributors={distributors}
                productionListId={productionListId}
                quantityUnits={quantityUnits}
              />
            );
          })}
        </Box>
      </Box>
    );
  },
);
