import { useState, useCallback } from 'react';

import { Textarea } from '@hover/blueprint';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import * as actions from 'src/features/projectManagement/redux/actions';
import { RootAction } from 'src/types/reduxStore';

import InputWrapper from '../InputWrapper';

export const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      updateOrderDetailsForm: actions.updateOrderDetailsForm,
    },
    dispatch,
  );

type Props = ReturnType<typeof mapDispatchToProps>;

const labelText = 'Order instructions';
const inputId = 'orderInstructions';

export const OrderInstructions: React.FC<Props> = ({
  updateOrderDetailsForm,
}) => {
  const [orderInstructions, setOrderInstructions] = useState('');

  const handleChange = useCallback(
    ({ target: { value } }) => {
      setOrderInstructions(value);
      updateOrderDetailsForm({
        values: { notes: value },
      });
    },
    [orderInstructions],
  );

  return (
    <InputWrapper isPristine labelText={labelText} width={1} inputId={inputId}>
      <Textarea
        name={inputId}
        id={inputId}
        sx={{ borderRadius: '4px', borderColor: '#767676' }}
        minHeight="96px"
        onChange={handleChange}
        value={orderInstructions}
        data-testid="orderInstructionsTextarea"
        maxlength="234"
      />
    </InputWrapper>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default connect(undefined, mapDispatchToProps)(OrderInstructions);
