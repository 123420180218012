import { PureComponent } from 'react';

import autobind from 'autobind-decorator';
import { connect } from 'react-redux';

import {
  ListItemUpdate as ListItemUpdateParams,
  LineItemTypeEnum,
} from 'src/api/graphql-global-types';
import { FormattedNumber } from 'src/components/FormattedNumber';
import { withTypewriter } from 'src/components/WithTypewriter';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { RootState } from 'src/types/reduxStore';
import { sentenceCase } from 'src/utils/Formatters';
import { jobProps } from 'src/utils/trackingUtils';
import { lineItemQuantityUnits } from 'src/utils/unitsMap';

import { ListItemType, VendorType } from '../../../../types';
import { FormattedCostInputWithSaveOnBlur } from '../../Common/FormattedCostInputWithSaveOnBlur';
import { MeasurementCell } from '../../Common/MeasurementCell';
import {
  InputContainer,
  StyledFormattedInput,
} from '../../Common/StyledFormattedInput';
import { VendorTableData } from '../../Common/VendorTableData';
import { ColorInput } from '../../Inputs/ColorInput';
import CustomInputValueIndicator from '../../Inputs/CustomInputValueIndicator';
import {
  ColumnCellSizedWrapper,
  TableRow,
  TableData,
  TableDataContent,
  TableDataText,
  TableSmallText,
  TableCellDisplayUnit,
} from '../styled';
import { QuantityInput } from './QuantityInput';

export const mapStateToProps = (state: RootState) => ({
  jobDetails: state.estimatorProductionTools.jobDetails,
  commonProps: getUserTrackingProps(state),
});

interface TradeItemProps {
  listItem: ListItemType;
  tableType: LineItemTypeEnum;
  updateListItem: (listItemId: number, params: ListItemUpdateParams) => void;
  vendors: VendorType[];
  typewriter: any;
}

type Props = ReturnType<typeof mapStateToProps> & TradeItemProps;

class TradeItemComp extends PureComponent<Props> {
  state = {
    unitCostOverrideValue: '',
  };

  @autobind
  public onFieldBlur(fieldName: string) {
    return (event: React.FormEvent<HTMLInputElement>) => {
      const {
        currentTarget: { value },
      } = event;
      const {
        listItem: { id },
        updateListItem,
      } = this.props;
      const params: ListItemUpdateParams = {
        [fieldName]: event.currentTarget.value,
      };
      if (fieldName === 'unitCost') {
        params.userSetCustomUnitCost = true;
        this.trackInput(fieldName, value);
      } else if (fieldName === 'quantity') {
        this.trackInput(fieldName, value);
      }
      updateListItem(id, params);
    };
  }

  @autobind
  private trackInput(field: string, value: string) {
    const { jobDetails, commonProps, typewriter } = this.props;
    const eventName =
      field === 'unitCost'
        ? EventNames.pmp.unitCostFilled
        : EventNames.pmp.quantityFilled;

    typewriter.textInput({
      input_value: value,
      input_label: eventName,
      ...jobProps(jobDetails),
      ...commonProps,
    });
  }

  public render() {
    const {
      listItem,
      listItem: {
        id,
        name,
        quantityUnits,
        calculatedQuantity,
        quantity,
        unitCost,
        vendor,
        pretaxCost,
        tradeType,
      },
      vendors,
      updateListItem,
      tableType,
    } = this.props;

    const { unitCostOverrideValue } = this.state;

    const displayUnits = lineItemQuantityUnits(quantityUnits);
    const currentVendor = vendor ? vendor.vendorName : '--';
    return (
      <TableRow data-list-item-id={id} data-testid="trade-item">
        <TableData>
          <TableDataContent>
            <ColumnCellSizedWrapper>
              <TableDataText data-testid="itemName">{name}</TableDataText>
            </ColumnCellSizedWrapper>
            {tableType === LineItemTypeEnum.LABOR ? null : (
              <ColumnCellSizedWrapper>
                <ColorInput listItem={listItem} data-testid="itemVariations" />
              </ColumnCellSizedWrapper>
            )}
          </TableDataContent>
        </TableData>
        <VendorTableData
          vendors={vendors}
          currentVendor={currentVendor}
          updateListItem={updateListItem}
          listItemId={id}
          listItem={listItem}
        />
        <TableData data-testid="trade-column-value">
          <TableDataContent>
            <TableDataText>{sentenceCase(tradeType)}</TableDataText>
          </TableDataContent>
        </TableData>
        <TableData>
          <MeasurementCell listItem={listItem} />
        </TableData>
        <TableData>
          <TableDataContent>
            <TableDataText>
              <QuantityInput
                quantity={quantity}
                supportsProductCatalog={
                  vendor?.distributor?.supportsProductCatalog
                }
                updateListItem={updateListItem}
                id={id}
                aria-label="quantity-input"
              />
              {displayUnits && (
                <TableCellDisplayUnit data-testid="materialItemUnits">
                  {displayUnits}
                </TableCellDisplayUnit>
              )}
            </TableDataText>
            {calculatedQuantity && (
              <TableSmallText>
                <FormattedNumber value={calculatedQuantity} />
              </TableSmallText>
            )}
          </TableDataContent>
        </TableData>
        <TableData>
          <TableDataContent>
            <TableDataText>
              <>
                <CustomInputValueIndicator
                  parent="unitCostInput"
                  margin="0 0 0 auto"
                  width="auto"
                  maxWidth="88px"
                  isCustomized={!!unitCostOverrideValue}
                >
                  {unitCost ? (
                    <FormattedCostInputWithSaveOnBlur
                      type="number"
                      value={unitCost || unitCostOverrideValue}
                      dataTest="item-unit-cost-input"
                      listItemId={id}
                      fieldName="unitCost"
                      trackInput={this.trackInput}
                    />
                  ) : (
                    <InputContainer>
                      <StyledFormattedInput
                        onBlur={this.onFieldBlur('unitCost')}
                        data-testid="item-unit-cost-input-NA"
                        value={unitCostOverrideValue}
                        placeholder="$N/A"
                        onChange={(e) =>
                          this.setState({
                            unitCostOverrideValue: e.target.value,
                          })
                        }
                      />
                    </InputContainer>
                  )}
                  {displayUnits && (
                    <TableCellDisplayUnit>/{displayUnits}</TableCellDisplayUnit>
                  )}
                </CustomInputValueIndicator>
              </>
            </TableDataText>
          </TableDataContent>
        </TableData>
        <TableData>
          <TableDataContent>
            <TableDataText data-testid="itemTotal">
              <FormattedNumber value={pretaxCost} format="$0,0.00" />
            </TableDataText>
          </TableDataContent>
        </TableData>
      </TableRow>
    );
  }
}

export const TradeItem = connect(mapStateToProps)(
  withTypewriter(TradeItemComp),
);
