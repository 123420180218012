import { memo, useState, useEffect, useMemo } from 'react';

import { useMutation } from '@apollo/client';
import {
  Box,
  Checkbox,
  IconButton,
  Link,
  Spinner,
  Flex,
  Td,
  Tr,
  Dialog,
} from '@hover/blueprint';
import { iTrash2 } from '@hover/icons';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import {
  DistributionOrderStateEnum,
  LineItemTypeEnum,
  TradeTypeEnum,
} from 'src/api/graphql-global-types';
import { estimationQuantityUnits_estimationQuantityUnits as estimationQuantityUnit } from 'src/api/types/estimationQuantityUnits';
import type { productCatalogConfigOrgDistributors_productCatalogConfigOrgDistributors as Distributor } from 'src/api/types/productCatalogConfigOrgDistributors';
import type {
  projectManagementProductionList_projectManagementProductionList_listItems as ListItem,
  projectManagementProductionList_projectManagementProductionList_listItems_distributionOrders as Order,
} from 'src/api/types/projectManagementProductionList';
import { messages } from 'src/constants/messages';
import {
  GET_PRODUCTION_LIST,
  UPDATE_PRODUCTION_LIST_ITEMS,
} from 'src/features/project/apis/graphql/queries/queries';
import { toggleSelectedItem } from 'src/features/project/redux/actions';
import {
  formattedCost,
  omitListItemAttributes,
} from 'src/features/project/util/utils';
import { ToastStatusEnum, useToastEhi } from 'src/hooks';

import { ListItemMeasurementsColumn } from './ContentTable/Item/ListItemMeasurementsColumn';
import { ListItemNameColumn } from './ContentTable/Item/ListItemNameColumn';
import { ListItemQuantityColumn } from './ContentTable/Item/ListItemQuantityColumn';
import { ListItemUnitCostColumn } from './ContentTable/Item/ListItemUnitCostColumn';
import { ListItemUnitOfMeasureColumn } from './ContentTable/Item/ListItemUnitOfMeasureColumn';
import { ListItemVariationColumn } from './ContentTable/Item/ListItemVariationColumn';
import { useProjectScopeTracker } from './hooks/useProjectScopeTracker';

interface LineItemProps {
  listItem: ListItem;
  lineItemType: LineItemTypeEnum;
  tradeType: TradeTypeEnum;
  jobId: number;
  orgId: string;
  selected: boolean;
  quantityUnits: estimationQuantityUnit[];
  distributors?: Distributor[];
  productionListId: number;
  onDelete: (listItem: ListItem) => void;
  isMobile: boolean;
}

const enum TOAST_IDS {
  GET_QUANTITY_UNITS_TOAST,
  UPDATE_PRODUCTION_LIST_ITEMS_TOAST,
}

export const ProductionListLineItem: React.FC<LineItemProps> = ({
  jobId,
  orgId,
  listItem,
  selected,
  lineItemType,
  tradeType,
  distributors,
  productionListId,
  quantityUnits,
  onDelete,
  isMobile,
}) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const isMaterial = listItem?.type === LineItemTypeEnum.MATERIAL;
  const dispatch = useDispatch();
  const toast = useToastEhi();
  const {
    trackOrderLink,
    trackInlineEditingDataUpdated,
    trackDeleteItemCanceled,
    trackDeleteItemConfirmed,
    trackDeleteItem,
  } = useProjectScopeTracker({
    jobId,
  });

  const unitsMap: Map<string, string> = useMemo(() => {
    const map = new Map();
    quantityUnits?.forEach((entry: estimationQuantityUnit) => {
      map.set(entry.value, entry.abbreviation);
    });
    return map;
  }, [quantityUnits]);

  const orders: Array<Order> = useMemo(
    () =>
      listItem.distributionOrders.filter((order) => {
        return (
          order.state === DistributionOrderStateEnum.submitted ||
          order.state === DistributionOrderStateEnum.pending_submitted ||
          order.state === DistributionOrderStateEnum.canceled
        );
      }),
    [listItem.distributionOrders],
  );

  const handleToggleSelection = () => {
    dispatch(toggleSelectedItem(lineItemType, tradeType, listItem.id));
  };

  const formDefaultValues = useMemo(
    () => ({
      id: listItem.id,
      name: listItem.name,
      quantityUnits: listItem.quantityUnits,
      quantity: listItem.quantity,
      calculatedQuantity: listItem.calculatedQuantity,
      color: listItem.color,
      externalVariationId: listItem.externalVariationId,
      unitCost: listItem.unitCost,
      totalCost: listItem.totalCost,
      sku: listItem.sku,
      productCatalogProductId: listItem.productCatalogProductId,
      product: listItem.product,
      measurement: listItem.measurement,
      measurementUnits: listItem.measurementUnits,
      wasteFactor: listItem.wasteFactor,
      userSetCustomColor: listItem.userSetCustomColor,
      requiresProductVariationSelection:
        listItem.requiresProductVariationSelection,
      type: lineItemType,
      tradeType,
    }),
    // eslint-disable-next-line
    [listItem],
  );

  const methods = useForm({
    defaultValues: formDefaultValues,
    mode: 'onBlur',
  });

  const [updateProductionList, { loading: updatingProductionList }] =
    useMutation(UPDATE_PRODUCTION_LIST_ITEMS, {
      onError: () => {
        toast({
          id: TOAST_IDS.UPDATE_PRODUCTION_LIST_ITEMS_TOAST,
          description: messages.projectScope.errors.query.productionList.update,
          status: ToastStatusEnum.ERROR,
        });
      },
      refetchQueries: [
        {
          query: GET_PRODUCTION_LIST,
          variables: {
            orgId,
            jobId,
          },
        },
      ],
    });

  const handleDelete = () => {
    trackDeleteItem();
    setShowDeleteDialog(true);
  };

  const handleCloseDeleteConfirmationDialog = () => {
    setShowDeleteDialog(false);
  };

  const handleConfirmDelete = () => {
    updateProductionList({
      variables: {
        productionListId,
        productionListAttributes: {
          listItemsAttributes: {
            id: listItem.id,
            destroy: true,
          },
        },
        orgId,
      },
    });

    trackDeleteItemConfirmed();

    if (onDelete) {
      onDelete(listItem);
    }

    handleCloseDeleteConfirmationDialog();
  };

  const handleUpdateProductLineItem = (inputLabel: string) => {
    const listItemsAttributes = omitListItemAttributes(
      methods.getValues() as ListItem,
    );

    // Post update flags
    if (listItem.unitCost !== listItemsAttributes.unitCost) {
      listItemsAttributes.userSetCustomUnitCost = true;
    }

    updateProductionList({
      variables: {
        productionListId,
        productionListAttributes: {
          listItemsAttributes,
        },
        orgId,
      },
    });

    trackInlineEditingDataUpdated(lineItemType, tradeType, inputLabel);
  };

  const handleUpdateProductSuggestedItems = (listItemId: number) => {
    updateProductionList({
      variables: {
        productionListId,
        productionListAttributes: {
          listItemsAttributes: [
            { id: listItemId, activeForTemplateLineItemGroup: true },
          ],
        },
        orgId,
      },
    });
    dispatch(toggleSelectedItem(lineItemType, tradeType, listItem.id));
    dispatch(toggleSelectedItem(lineItemType, tradeType, listItemId));
  };

  const quantityUnitFormValue = methods.getValues().quantityUnits || '';
  const quantityUnit = useMemo(
    () => unitsMap.get(quantityUnitFormValue.toUpperCase())?.toUpperCase(),
    [quantityUnitFormValue, unitsMap],
  ) as string;

  useEffect(() => {
    methods.reset(formDefaultValues);
    // eslint-disable-next-line
  }, [listItem]);

  return (
    <Tr
      key={listItem.id}
      data-testid={`ListItemRow-${listItem.id}`}
      lineHeight="1rem"
      verticalAlign="baseline"
      borderBottom="1px solid"
      borderColor="neutral.200"
      display={{ base: 'flex', tablet: 'table-row' }}
      padding={{ base: 400, tablet: 0 }}
      flexDirection={{ base: 'row' }}
      flexWrap="wrap"
    >
      <FormProvider {...methods}>
        {!isMobile && (
          <Td
            _first={{ paddingLeft: '800' }}
            paddingTop={{ base: 300, tablet: 600 }}
            paddingBottom={{ base: 0, tablet: 400 }}
            verticalAlign="top"
          >
            {!updatingProductionList && (
              <Checkbox
                marginLeft={1}
                value={listItem.id?.toString()}
                aria-label={listItem.name}
                key={listItem.id}
                data-testid="checkboxMaterialItem"
                flex="0"
                isChecked={selected}
                onChange={handleToggleSelection}
              />
            )}
            {updatingProductionList && (
              <Spinner data-testid={`${listItem.id}-EditingLineItemLoader`} />
            )}
          </Td>
        )}
        <ListItemNameColumn
          isMaterial={isMaterial}
          isDisabled={updatingProductionList}
          listItem={listItem}
          distributors={distributors}
          orgId={orgId}
          jobId={jobId}
          onUpdate={handleUpdateProductLineItem}
          onSuggestedItemsUpdate={handleUpdateProductSuggestedItems}
        />
        {isMaterial && (
          <ListItemVariationColumn
            listItem={listItem}
            isUpdating={updatingProductionList}
            onUpdate={handleUpdateProductLineItem}
            jobId={jobId}
          />
        )}
        <Td
          paddingTop={{ base: 300, tablet: 600 }}
          paddingBottom={{ base: 0, tablet: 400 }}
          verticalAlign="top"
          flexBasis="100%"
        >
          <ListItemMeasurementsColumn listItem={listItem} />
        </Td>
        <ListItemQuantityColumn
          listItem={listItem}
          unitsMap={unitsMap}
          jobId={jobId}
          orgId={orgId}
          onUpdate={handleUpdateProductLineItem}
        />
        <ListItemUnitOfMeasureColumn
          listItem={listItem}
          isDisabled={updatingProductionList}
          unitsMap={unitsMap}
          quantityUnit={quantityUnit}
          jobId={jobId}
          onUpdate={handleUpdateProductLineItem}
        />
        <ListItemUnitCostColumn
          listItem={listItem}
          isDisabled={updatingProductionList}
          jobId={jobId}
          onUpdate={handleUpdateProductLineItem}
        />

        <Td
          color="neutral.500"
          verticalAlign="top"
          paddingY={{ base: 300, tablet: 500 }}
          paddingLeft={{ base: 300, tablet: 100 }}
          flexBasis="50%"
          textAlign={{ base: 'start', tablet: 'end' }}
        >
          <Box marginTop={{ base: 0, tablet: 200 }} display="inline-block">
            {formattedCost(listItem.totalCost, true)}
          </Box>
        </Td>

        {!isMobile && (
          <Td paddingLeft={700}>
            {orders.map((order: Order) => (
              <Box key={order.id}>
                <Link
                  as={RouterLink}
                  to={`/project/${jobId}/order/${order.id}?orgId=${orgId}`}
                  onClick={trackOrderLink}
                >
                  {order.purchaseOrderNumber}
                </Link>
              </Box>
            ))}
          </Td>
        )}

        <Td
          paddingY={{ base: 100, tablet: 500 }}
          paddingRight={{ base: '0 !important' }}
          padding={{ base: 0, tablet: '' }}
          flexBasis="100%"
        >
          <Flex justifyContent={{ base: 'flex-end', tablet: 'center' }}>
            <IconButton
              data-testid={`${listItem.id}-edit-delete`}
              label="delete-item"
              fill="minimal"
              icon={iTrash2}
              onClick={handleDelete}
              fontWeight="normal"
              color="danger"
              top="3px"
            />

            {showDeleteDialog && (
              <Dialog
                cancelText="Cancel"
                confirmText="Remove"
                header={`Remove ${listItem.name}`}
                isDestructive
                isOpen
                onCancelClick={() => {
                  trackDeleteItemCanceled();
                  handleCloseDeleteConfirmationDialog();
                }}
                onClose={() => {
                  handleCloseDeleteConfirmationDialog();
                }}
                onConfirmClick={handleConfirmDelete}
              >
                <span data-testid="deleteDialogContent">
                  Remove item from project scope. This action cannot be done.
                </span>
              </Dialog>
            )}
          </Flex>
        </Td>
      </FormProvider>
    </Tr>
  );
};

export const ProductionListLineItemMemoized = memo(ProductionListLineItem);
