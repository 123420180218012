import { facadesSortOrderByType as sidingPrefixes } from 'src/features/exteriorEstimator/constants/SidingPartialSortOrder';
import {
  COMMERCE_BRICK_AND_STONE_ESTIMATION,
  isEnabled,
} from 'src/lib/FeatureFlag';
import { EhiOrgSettings } from 'src/redux/reducers/ehiReducer';
import { EstimationMeasurementsFacade } from 'src/types/EstimationMeasurementTypes';
import {
  shouldCalculateWithOpenings,
  shouldCalculateWithTrim,
  shouldCalculateWithOpeningsTrim,
} from 'src/utils/PartialSidingUtils';

import { QuestionId } from '../types';

export interface CombinedAreaWithTrimForFacade {
  [facade: string]: {
    areaWithOpenings: number;
    trimArea: number;
    openingsTrim: number;
    combinedArea: number;
    areaWithoutOpenings: number;
  };
}

export class PartialSidingUtils {
  // determines if question is for selecting partial siding facets
  // ie. if questionId is prefixed with ['SI', 'UN', 'STC', 'TD', 'STO', 'BR', 'WR']
  static isQuestionPartialSidingSelection(questionId: QuestionId) {
    return (
      typeof questionId === 'string' &&
      sidingPrefixes.includes(questionId.split('-')[0])
    );
  }

  // this function sets the default value for the partial siding selection question
  static setDefaultPartialSidingSelection(
    // If using a siding template all siding facades should be selected by default
    // Unknown facades if 'connected to siding' should be selected by default
    // all other facades [Brick (BR-), stucco (ST-) etc.] should default to false

    // if using Stone template then stone faces should be selected by default
    // this will need to be handled more gracefully when orgs are allowed to select
    // stone AND siding templates. for v1 we can only select either stone OR siding
    questionId: QuestionId,
    unknownsConnectedToSidingMap: { [key: string]: boolean | undefined },
    isUsingStoneTemplate: boolean,
  ) {
    const wallTypePrefix = questionId.toString().split('-')[0];
    const isSiding = wallTypePrefix === 'SI';
    const isUnkWallConnectedToSiding =
      wallTypePrefix === 'UN'
        ? unknownsConnectedToSidingMap[questionId]
        : false;

    if (isEnabled(COMMERCE_BRICK_AND_STONE_ESTIMATION)) {
      if (isUsingStoneTemplate) {
        return wallTypePrefix === 'STO';
      }
    }

    return isSiding || isUnkWallConnectedToSiding;
  }

  static getCombinedAreaForFacades(
    facades: EstimationMeasurementsFacade[],
    shouldCalcWithOpenings: boolean,
    shouldCalcWithTrim: boolean,
    shouldCalcWithOpeningsTrim: boolean,
  ) {
    // memory placeholders for deduping trims and opening_trims shared among facades
    const trims: { [sortIndex: number]: 1 } = {};
    const openingTrims: { [sortIndex: number]: 1 } = {};

    return facades.reduce<CombinedAreaWithTrimForFacade>((acc, facade) => {
      const areaWithoutOpenings =
        facade?.area_with_waste_factor_calculation?.zero ?? 0;

      const areaWithOpenings =
        facade?.area_with_waste_factor_calculation?.with_openings ?? 0;

      const trimArea =
        facade?.trim?.connected_areas?.reduce<number>((sum, next) => {
          if (!trims[next.sort_index]) {
            trims[next.sort_index] = 1;
            return sum + (next?.area_in_sqrft ?? 0);
          }
          return sum;
        }, 0) ?? 0;

      const openingsTrim =
        facade?.openings_trim?.connected_areas?.reduce<number>((sum, next) => {
          if (!openingTrims[next.sort_index]) {
            openingTrims[next.sort_index] = 1;
            return sum + (next?.area_in_sqrft ?? 0);
          }
          return sum;
        }, 0) ?? 0;

      let combinedArea = 0;

      if (shouldCalcWithTrim) {
        combinedArea += trimArea;
      }
      if (shouldCalcWithOpeningsTrim) {
        combinedArea += openingsTrim;
      }

      combinedArea += shouldCalcWithOpenings
        ? areaWithOpenings
        : areaWithoutOpenings;

      acc[facade.facade] = {
        areaWithOpenings,
        trimArea,
        openingsTrim,
        combinedArea,
        areaWithoutOpenings,
      };

      return acc;
    }, {});
  }

  static shouldCalculateWithOpenings = (orgSettings: EhiOrgSettings | null) => {
    return shouldCalculateWithOpenings(orgSettings);
  };

  static shouldCalculateWithTrim = (orgSettings: EhiOrgSettings | null) => {
    return shouldCalculateWithTrim(orgSettings);
  };

  static shouldCalculateWithOpeningsTrim = (
    orgSettings: EhiOrgSettings | null,
  ) => {
    return shouldCalculateWithOpeningsTrim(orgSettings);
  };
}
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default PartialSidingUtils;
