import React, { useState } from 'react';

import {
  Input,
  Td,
  Menu,
  Link,
  Box,
  Icon,
  MenuGroup,
  MenuItem,
} from '@hover/blueprint';
import { iChevronDown } from '@hover/icons';
import { get } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { EditedStatusEnum } from 'src/api/graphql-global-types';
import type { productCatalogConfigOrgDistributors_productCatalogConfigOrgDistributors as Distributor } from 'src/api/types/productCatalogConfigOrgDistributors';
import { projectManagementProductionList_projectManagementProductionList_listItems as ListItem } from 'src/api/types/projectManagementProductionList';
import { useProjectScopeTracker } from 'src/features/project/components/ProjectScope/hooks/useProjectScopeTracker';
import { usePrevious } from 'src/hooks/usePrevious';
import { getMaterialListFeature } from 'src/redux/selectors';

import { ProductNameInput } from './Inputs/ProductNameInput';

type Props = {
  isMaterial: boolean;
  isDisabled?: boolean;
  listItem: ListItem;
  distributors?: Distributor[];
  orgId: string;
  jobId: number;
  onUpdate: (inputLabel: string) => void;
  onSuggestedItemsUpdate: (listItemId: number) => void;
};

export const ListItemNameColumn = ({
  isMaterial,
  isDisabled = false,
  listItem,
  distributors,
  orgId,
  jobId,
  onUpdate,
  onSuggestedItemsUpdate,
}: Props) => {
  /* Component state */

  const [name, setName] = useState(listItem.name);
  const { trackInlineEditingInputPressed } = useProjectScopeTracker({ jobId });

  const prevName = usePrevious(listItem.name);

  /* React hook form */

  const {
    setValue,
    register,
    trigger,
    formState: { errors: formErrors },
  } = useFormContext();

  const handleInputUpdate = () => {
    if (Object.keys(formErrors).length > 0) {
      return;
    }

    onUpdate('Product Name');
  };

  const handleSuggestedItemsClick = (listItemId: number) => {
    onSuggestedItemsUpdate(listItemId);
  };

  /* Feature flags and access */

  const materialListFeatureEnabled = useSelector(getMaterialListFeature);

  if (isMaterial) {
    return (
      <Td
        paddingRight={{ base: 0, tablet: 800 }}
        paddingY={{ tablet: 500 }}
        paddingLeft="0 !important"
        verticalAlign={{
          base: '',
          tablet: 'top',
        }}
        display={{ base: 'flex', tablet: 'table-cell' }}
        flexDirection="column"
        flexBasis="100%"
      >
        <ProductNameInput
          label={name}
          name={name}
          orgId={orgId}
          jobId={jobId}
          listItem={listItem}
          distributors={distributors}
          setName={setName}
          onUpdate={handleInputUpdate}
        />

        {listItem.templateListItemGroupListItems.length > 0 && (
          <Menu
            trigger={
              <Link
                as="button"
                data-testid="grouped-list-items-dropdown-button"
              >
                <Box display="flex" flexDir="row" alignItems="center">
                  Suggested items <Icon icon={iChevronDown} />
                </Box>
              </Link>
            }
          >
            <MenuGroup title="Suggestions">
              {listItem.templateListItemGroupListItems.map((groupListItem) => (
                <MenuItem
                  onClick={() => handleSuggestedItemsClick(groupListItem.id)}
                >
                  {groupListItem.name}
                </MenuItem>
              ))}
            </MenuGroup>
          </Menu>
        )}

        {materialListFeatureEnabled &&
          listItem.editedStatus === EditedStatusEnum.EDITED && (
            <Box color="neutral.500" fontSize={100} marginTop={100}>
              Item manually edited
            </Box>
          )}
      </Td>
    );
  }

  return (
    <Td>
      <Input
        size="tiny"
        {...register('name', {
          required: 'Item name is required',
        })}
        disabled={isDisabled}
        defaultValue={listItem.name}
        isInvalid={!!get(formErrors, 'name')}
        onChange={() => {
          trackInlineEditingInputPressed('Name');
        }}
        onBlur={async (event) => {
          // Set the new value based on the blur event, then trigger validation and wait for completion.
          setValue('name', event.target.value);
          await trigger('name');

          // If new value has no errors, update the value. Else, revert it.
          if (!get(formErrors, 'name')) {
            onUpdate('Name');
          } else {
            setValue('name', prevName);
          }
        }}
      />
    </Td>
  );
};
