import { useEffect } from 'react';

import { Box } from '@hover/blueprint';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-unresolved
import { VendorQuery_vendors_distributor_capabilities_deliveryTimes as DeliveryTimes } from 'src/features/projectManagement/apis/graphql/queries/types';

import { RadioGroup, RadioButton } from 'src/components/Radio';
import {
  deliveryTimeValueField,
  deliveryTimeErrorField,
} from 'src/features/projectManagement/constants';
import { DistributionDeliveryTimeEnum } from 'src/features/projectManagement/types';

import { UpdateOrderDetailsParam } from './DeliveryDetails';

export interface Props {
  selectedDeliveryTime?: DistributionDeliveryTimeEnum | null;
  deliveryTimes: DeliveryTimes[];
  onChange: (params: UpdateOrderDetailsParam) => void;
}

export const DeliveryTime: React.FC<Props> = ({
  selectedDeliveryTime,
  deliveryTimes,
  onChange,
}) => {
  useEffect(() => {
    onChange({
      errorAttribute: deliveryTimeErrorField,
      isInvalid: !selectedDeliveryTime,
    });
    // we want to only run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value: string) => {
    onChange({
      valueAttribute: deliveryTimeValueField,
      value,
      errorAttribute: deliveryTimeErrorField,
      isInvalid: !value,
    });
  };

  const sortedDeliveryTimes = [...deliveryTimes].sort(
    (a, b) => (a?.sortOrder ?? 0) - (b?.sortOrder ?? 0),
  );

  return (
    <Box testId="delivery-time">
      <RadioGroup
        marginBottom={600}
        heading="Requested delivery time"
        onChange={handleChange}
      >
        {sortedDeliveryTimes.map(({ id, label, deliveryTime }) => (
          <RadioButton
            label={label}
            name="deliveryTime"
            key={id}
            value={deliveryTime}
          />
        ))}
      </RadioGroup>
    </Box>
  );
};
