import { Body, Box } from '@hover/blueprint';
import { useSelector } from 'react-redux';

import type { projectManagementProductionList_projectManagementProductionList as ProjectManagementProductionList } from 'src/api/types/projectManagementProductionList';
import { NumberInput } from 'src/components';
import { MobileExternalLink } from 'src/features/project/components/ProjectScope/MaterialList/MobileExternalLink';
import { useTracking } from 'src/hooks';
import { useIsMobileBreakpoint } from 'src/hooks/useIsMobileBreakpoint';
import { getMaterialListFeature } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

import {
  ProjectDetailsPanel,
  SectionHeading,
  FinancialsSection,
  FinancialsRow,
  FinancialsBody,
  MobileSectionHeading,
} from './components';

interface Props {
  productionList: ProjectManagementProductionList;
}

export const Financials: React.FC<Props> = ({ productionList }) => {
  const {
    contractPreDiscountPrice,
    totalDiscount,
    contractTotalPrice,
    profitAmount,
    materialTotalCost,
    laborTotalCost,
    otherTotalCost,
    totalCost,
  } = productionList;
  const materialListFeatureEnabled = useSelector(getMaterialListFeature);
  const profitMargin = productionList?.profitMargin ?? 0;
  const discounts = [];
  productionList?.estimateGroup?.estimates?.forEach((estimate) => {
    if (estimate.discounts) discounts.push(...estimate.discounts);
  });
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const isMobile = useIsMobileBreakpoint();

  if (materialListFeatureEnabled) {
    const handleLearnMoreClick = () => {
      typewriter.linkPressed({
        link_text: 'learn more',
        page_or_screen_name:
          EventNames.project.scope.financialsPanel.learnMorePressed,
        primary_cta: false,
        feature: 'material-list',
        link_location: 'project scope',
        ...commonTrackingProps,
      });
    };
    return (
      <>
        <ProjectDetailsPanel data-testid="financialsSection">
          {isMobile ? (
            <MobileSectionHeading>Financials</MobileSectionHeading>
          ) : (
            <SectionHeading marginBottom={200}>Financials</SectionHeading>
          )}

          {!isMobile && (
            <FinancialsSection>
              <FinancialsRow>
                <FinancialsBody size={400}>Material total</FinancialsBody>
                <FinancialsBody size={400}>
                  <NumberInput
                    value={materialTotalCost || 0}
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                    displayType="text"
                    thousandSeparator=","
                  />
                </FinancialsBody>
              </FinancialsRow>
            </FinancialsSection>
          )}

          <FinancialsSection flexGrow={isMobile ? 1 : 0}>
            <FinancialsRow flexDirection={isMobile ? 'column' : 'row'}>
              <Body
                size={500}
                fontSize={isMobile ? 100 : 300}
                fontWeight="bold"
                color="neutral700"
                mt={0}
                mb={isMobile ? 0 : 400}
              >
                Total cost
              </Body>
              <Body
                size={500}
                fontSize={isMobile ? 100 : 300}
                fontWeight="bold"
                color="neutral700"
                mt={0}
                mb={isMobile ? 0 : 400}
              >
                <NumberInput
                  value={totalCost || 0}
                  prefix="$"
                  decimalScale={2}
                  fixedDecimalScale
                  displayType="text"
                  thousandSeparator=","
                />
              </Body>
            </FinancialsRow>
          </FinancialsSection>

          {!isMobile && (
            <Body
              m={0}
              marginBottom={200}
              size={400}
              color="neutral.500"
              width="60%"
              flexGrow="1"
            >
              Additional financial information is available only for projects
              with cost estimates under the Transform Membership Plan
            </Body>
          )}

          <MobileExternalLink
            href="https://hover.to/transform-plan/"
            onClick={handleLearnMoreClick}
            fontSize={isMobile ? 200 : 300}
          >
            Learn more
          </MobileExternalLink>
        </ProjectDetailsPanel>
      </>
    );
  }

  return (
    <ProjectDetailsPanel data-testid="financialsSection">
      <SectionHeading marginBottom={200}>Financials</SectionHeading>
      <FinancialsSection>
        <FinancialsRow>
          <FinancialsBody size={400}>Estimate price</FinancialsBody>
          <FinancialsBody size={400}>
            <NumberInput
              value={contractPreDiscountPrice || 0}
              prefix="$"
              decimalScale={2}
              fixedDecimalScale
              displayType="text"
              thousandSeparator=","
            />
          </FinancialsBody>
        </FinancialsRow>
        <FinancialsRow>
          <FinancialsBody size={400}>
            Promotions({discounts.length})
          </FinancialsBody>
          <FinancialsBody size={400}>
            {totalDiscount > 0 && '- '}
            <NumberInput
              value={totalDiscount || 0}
              prefix="$"
              decimalScale={2}
              fixedDecimalScale
              displayType="text"
              thousandSeparator=","
            />
          </FinancialsBody>
        </FinancialsRow>
        <FinancialsRow>
          <Body size={500} fontWeight="bold" color="neutral700" mt={0} mb={400}>
            Contract Price
          </Body>
          <Body size={500} fontWeight="bold" color="neutral700" mt={0} mb={400}>
            <NumberInput
              value={contractTotalPrice || 0}
              prefix="$"
              decimalScale={2}
              fixedDecimalScale
              displayType="text"
              thousandSeparator=","
            />
          </Body>
        </FinancialsRow>
      </FinancialsSection>
      <FinancialsSection>
        <FinancialsRow>
          <FinancialsBody size={400}>Material total</FinancialsBody>
          <FinancialsBody size={400}>
            <NumberInput
              value={materialTotalCost || 0}
              prefix="$"
              decimalScale={2}
              fixedDecimalScale
              displayType="text"
              thousandSeparator=","
            />
          </FinancialsBody>
        </FinancialsRow>
        <FinancialsRow>
          <FinancialsBody size={400}>Labor total</FinancialsBody>
          <FinancialsBody size={400}>
            <NumberInput
              value={laborTotalCost || 0}
              prefix="$"
              decimalScale={2}
              fixedDecimalScale
              displayType="text"
              thousandSeparator=","
            />
          </FinancialsBody>
        </FinancialsRow>
        <FinancialsRow>
          <FinancialsBody size={400}>Other total</FinancialsBody>
          <FinancialsBody size={400}>
            <NumberInput
              value={otherTotalCost || 0}
              prefix="$"
              decimalScale={2}
              fixedDecimalScale
              displayType="text"
              thousandSeparator=","
            />
          </FinancialsBody>
        </FinancialsRow>
        <FinancialsRow>
          <Body size={500} fontWeight="bold" color="neutral700" mt={0} mb={400}>
            Total cost
          </Body>
          <Body size={500} fontWeight="bold" color="neutral700" mt={0} mb={400}>
            <NumberInput
              value={totalCost || 0}
              prefix="$"
              decimalScale={2}
              fixedDecimalScale
              displayType="text"
              thousandSeparator=","
            />
          </Body>
        </FinancialsRow>
      </FinancialsSection>
      <Box flexDirection="column">
        <FinancialsRow>
          <Body size={500} fontWeight="bold" color="neutral700" mt={0} mb={400}>
            Profit margin (
            <NumberInput
              value={profitMargin || 0}
              suffix="%"
              decimalScale={2}
              fixedDecimalScale
              displayType="text"
              thousandSeparator=","
            />
            )
          </Body>

          <Body size={500} fontWeight="bold" color="neutral700" mt={0} mb={400}>
            <NumberInput
              value={profitAmount || 0}
              prefix="$"
              decimalScale={2}
              fixedDecimalScale
              displayType="text"
              thousandSeparator=","
            />
          </Body>
        </FinancialsRow>
      </Box>
    </ProjectDetailsPanel>
  );
};
