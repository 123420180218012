import { createSelector } from 'reselect';

import {
  LineItemTypeEnum,
  DistributionOrderStateEnum,
} from 'src/api/graphql-global-types';
import { getSalesOpportunities_salesOpportunities_productionList_orders as Order } from 'src/api/types/getSalesOpportunities';
import { ListItemBaseFields } from 'src/api/types/ListItemBaseFields';
import {
  addressLineOneValueField,
  addressLineTwoValueField,
  cityValueField,
  zipCodeValueField,
  stateProvinceValueField,
} from 'src/features/projectManagement/constants';
import { ListItemType, VendorType } from 'src/features/projectManagement/types';
import { RootState } from 'src/types/reduxStore';

import {
  getProductionList,
  getMaterialListItemsByVendor,
  getJobDetails,
  getOrderDetailsForm,
  getProjectManagementOrderData,
  getVendorForOrder,
} from './estimatorProductionSelectors';

export const getBillingFormValues = (state: RootState) =>
  state.estimatorProductionTools.billingFormValues;

export const getPONumber = createSelector(
  getOrderDetailsForm,
  getBillingFormValues,
  (orderDetailsForm) => orderDetailsForm?.values?.poNumber ?? '',
);

export const getProviderOrderAttributes = (vendorForOrder: VendorType) =>
  createSelector(
    getProductionList,
    getMaterialListItemsByVendor(vendorForOrder),
    getJobDetails,
    getOrderDetailsForm,
    getPONumber,
    ({ id }, providerListItems, jobDetails, orderDetailsForm, poNumber) => ({
      productionListId: id.toString(),
      listItemIds: providerListItems.map((listItem: ListItemBaseFields) =>
        listItem.id.toString(),
      ),
      externalIdentifier: poNumber,
      shippingAddressFirstName: jobDetails?.createdBy?.firstName ?? '',
      shippingAddressLastName: jobDetails?.createdBy?.lastName ?? '',
      shippingAddressLine1:
        orderDetailsForm?.values?.[addressLineOneValueField] ??
        jobDetails?.locationLine1 ??
        '',
      shippingAddressLine2:
        orderDetailsForm?.values?.[addressLineTwoValueField] ??
        jobDetails?.locationLine2 ??
        '',
      shippingAddressCity:
        orderDetailsForm?.values?.[cityValueField] ??
        jobDetails?.locationCity ??
        '',
      shippingAddressState:
        orderDetailsForm?.values?.[stateProvinceValueField] ??
        jobDetails?.locationRegion ??
        '',
      shippingAddressPostalCode:
        orderDetailsForm?.values?.[zipCodeValueField] ??
        jobDetails?.locationPostalCode ??
        '',
      shippingAddressCountry: jobDetails?.locationCountry ?? '',
      notes: orderDetailsForm?.values?.notes ?? '',
      deliveryTime: orderDetailsForm?.values?.deliveryTime,
      deliveryType: orderDetailsForm?.values?.deliveryType,
      deliveryDate: orderDetailsForm?.values?.deliveryDate?.toISOString(),
      deliveryMethod: orderDetailsForm?.values?.deliveryMethod,
    }),
  );

export type GetProviderOrderAttributesResults = ReturnType<
  ReturnType<typeof getProviderOrderAttributes>
>;

export const getDistributionOrder = (state: RootState) =>
  state?.estimatorProductionTools?.projectManagementOrderData
    ?.distributionOrder;

export const getProjectManagementOrder = (state: RootState) =>
  state.estimatorProductionTools.projectManagementOrderData
    .projectManagementOrder;

export const getOrderOrDistributionOrder = createSelector(
  getProjectManagementOrderData,
  (projectManagementOrderData) => projectManagementOrderData.distributionOrder,
);

export const getVendorForOrderMaterialListItems = createSelector(
  getProductionList,
  getVendorForOrder,
  (productionList, vendor) => {
    if (!productionList || !vendor) return [];
    const { listItems = [] } = productionList;
    return listItems.filter(
      (listItem: ListItemType) =>
        listItem.type === LineItemTypeEnum.MATERIAL &&
        listItem.vendor &&
        listItem.vendor.distributorId === vendor.distributorId,
    );
  },
);

export const getSubmittedOrders = (state: RootState, identifier: string) =>
  state.estimatorProductionTools?.productionList?.orders
    .filter(
      (order: Order) =>
        order.distributionOrder &&
        order.distributionOrder.distributor?.identifier === identifier &&
        order.distributionOrder.state === DistributionOrderStateEnum.submitted,
    )
    .map((order: Order) => order.distributionOrder);

export const areAnyOrderDetailsInputsInvalid = createSelector(
  getOrderDetailsForm,
  ({ errors }) =>
    errors ? Object.values(errors).some((isInvalid) => isInvalid) : false,
);

export const getOAuthErrorMessage = createSelector(
  getDistributionOrder,
  (distributionOrder) => {
    if (!distributionOrder) return null;
    const { orderCheck } = distributionOrder;
    const hasOAuthError = orderCheck?.errors.some(
      ({ attribute, errors }) =>
        attribute === 'distributor' &&
        errors.includes('has not been connected'),
    );
    return hasOAuthError ? 'Distributor has not been connected' : null;
  },
);

export const getDistributor = (state: RootState) =>
  state.estimatorProductionTools?.projectManagementOrderData?.vendorForOrder
    ?.distributor;

export const getErrorMessage = (state: RootState) =>
  state.estimatorProductionTools?.projectManagementOrderData?.errorMessage;

export const getDistributorCapabilities = createSelector(
  getDistributor,
  (distributor) => distributor?.capabilities,
);
