import { isNil } from 'lodash';

import { EhiOrgSettings } from 'src/redux/reducers/ehiReducer';
import { EstimationSummarizedJsonMeasurements } from 'src/types/HdfMeasurements';

// return the setting, or TRUE if setting is not set
export const shouldCalculateWithOpenings = (
  orgSettings: EhiOrgSettings | null,
) => {
  let shouldCalcWithOpenings = true;

  if (!isNil(orgSettings?.includeOpeningsLessThan33Sqft)) {
    shouldCalcWithOpenings = !!orgSettings?.includeOpeningsLessThan33Sqft;
  }

  return shouldCalcWithOpenings;
};

export const shouldCalculateWithTrim = (orgSettings: EhiOrgSettings | null) => {
  let shouldCalcWithTrim = true;

  if (!isNil(orgSettings?.includeSidingTrim)) {
    shouldCalcWithTrim = !!orgSettings?.includeSidingTrim;
  }

  return shouldCalcWithTrim;
};

export const shouldCalculateWithOpeningsTrim = (
  orgSettings: EhiOrgSettings | null,
) => {
  let shouldCalcWithOpeningsTrim = true;

  if (!isNil(orgSettings?.includeSidingOpeningsTrim)) {
    shouldCalcWithOpeningsTrim = !!orgSettings?.includeSidingOpeningsTrim;
  }

  return shouldCalcWithOpeningsTrim;
};

export const getSidingFacetsArea = (
  orgSettings: EhiOrgSettings | null,
  hdf: EstimationSummarizedJsonMeasurements,
) => {
  const useSidingAreaWithOpenings = shouldCalculateWithOpenings(orgSettings);
  const sidingFacetsFromHdf = useSidingAreaWithOpenings
    ? hdf.combinedAreaWithTrim
    : hdf.combinedAreaWithTrimNoOpenings;

  const sidingFacets = Object.entries(sidingFacetsFromHdf).map(
    ([key, value]) => {
      let facetArea;
      if (
        shouldCalculateWithTrim(orgSettings) &&
        !shouldCalculateWithOpeningsTrim(orgSettings)
      ) {
        // if shouldCalculateWithTrim ONLY, use siding withSidingTrim
        facetArea = value.withSidingTrim;
      } else if (
        !shouldCalculateWithTrim(orgSettings) &&
        shouldCalculateWithOpeningsTrim(orgSettings)
      ) {
        // if shouldCalculateWithOpeningsTrim ONLY, use withSidingOpeningsTrim
        facetArea = value.withSidingOpeningsTrim;
      } else if (
        shouldCalculateWithTrim(orgSettings) &&
        shouldCalculateWithOpeningsTrim(orgSettings)
      ) {
        // if BOTH, use combinedArea
        facetArea = value.combinedArea;
      } else {
        // if neither, use area/areaWithOpenings
        facetArea = value.area ?? value.areaWithOpenings;
      }

      return {
        display_label: key,
        id: key,
        area: facetArea,
      };
    },
  );
  return sidingFacets;
};
