import { gql } from '@apollo/client';

export const GET_PRODUCT_CATALOG_PRODUCT = gql`
  query productCatalogProduct(
    $id: ID!
    $orgId: ID!
    $filterVariationsByOrg: Boolean
  ) {
    productCatalogProduct(id: $id) {
      active
      id
      name
      orgVariationsCount(orgId: $orgId)
      variationsCount
      variations(filterByOrg: $filterVariationsByOrg, orgId: $orgId) {
        id
        name
        orgVariation(orgId: $orgId) {
          id
        }
      }
      provider {
        id
        name
      }
    }
  }
`;

export const GET_PRODUCT_CATALOG_PRODUCTS = gql`
  query productCatalogProducts(
    $orgId: ID!
    $after: String
    $filterByOrg: Boolean
  ) {
    productCatalogProducts(
      orgId: $orgId
      after: $after
      filterByOrg: $filterByOrg
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      nodes {
        id
        name
        variationsCount
        productCatalogCategory {
          id
          name
        }
        productCatalogCategories {
          id
          name
        }
        orgVariationsCount(orgId: $orgId)
        provider {
          id
          name
        }
      }
    }
  }
`;

export const PRODUCT_CATALOG_PRODUCT_SEARCH_V2 = gql`
  query productCatalogProductsSearchMaterialsPage(
    $searchTerm: String!
    $orgId: ID!
    $distributorIds: [ID!]
    $distributionBranchIds: [ID!]
    $after: String
  ) {
    productCatalogProductsSearch(
      orgId: $orgId
      searchTerm: $searchTerm
      distributorIds: $distributorIds
      distributionBranchIds: $distributionBranchIds
      after: $after
      first: 20
    ) {
      nodes {
        id
        name
        variationsCount
        productCatalogCategory {
          name
        }
        productCatalogCategories {
          id
          name
        }
        orgVariationsCount(orgId: $orgId)
        provider {
          id
          name
        }
      }
      edges {
        node {
          id
          name
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
