/* eslint-disable camelcase */
/* eslint-disable no-extra-boolean-cast */
import { get } from 'lodash';
import queryString from 'query-string';
import { createSelector } from 'reselect';

import { LineItemTypeEnum } from 'src/api/graphql-global-types';
import { profile_profile_user } from 'src/api/types/profile';
import { RootState } from 'src/types/reduxStore';

import { TradeTypes } from '../../constants/multiTrades';
import {
  LineItemCategoriesSortedByVendorsType,
  VendorNamesSortedAlphabeticallyType,
  ListItemType,
  Product,
  ProductsMap,
  VendorType,
  OrderDocumentType,
} from '../../types';
import {
  filterCategorizedListItemsByTrade,
  filterItemsByTrade,
} from '../../utils/MultiTradesUtils';
import {
  categorizeListItems,
  sortByVendorWithTax,
} from '../../utils/ProductionListUtils';

interface HoverType {
  userProfile: profile_profile_user;
}

export const getParams = (state: RootState) =>
  queryString.parse(state.router.location.search);
// orgId querystring param must be required, to support suborg functionality;
// there is no typing that can enforce that the param is present in the URL querystring,
// so this selector and return typing present the querystring param as required.
export const getOrgIdParam = (state: RootState) => {
  return (getParams(state).orgId ?? '') as string;
};
export const getUserProfile = (state: RootState) =>
  (state.hover as HoverType).userProfile;
export const getSalesOpportunities = (state: RootState) =>
  state.estimatorProductionTools.salesOpportunities;
export const getProductionList = (state: RootState) =>
  state.estimatorProductionTools.productionList;
export const getTradeFilter = (state: RootState) =>
  state.estimatorProductionTools.tradeFilter;

export const getPdf = (state: RootState) => state.estimatorProductionTools.pdf;

export const getListItemsByPdfVendorName = ({
  listItems,
  vendorName,
}: {
  listItems: ListItemType[];
  vendorName: string | undefined;
}) =>
  !!vendorName
    ? listItems.filter(
        (listItem: ListItemType) => listItem?.vendor?.vendorName === vendorName,
      )
    : listItems;

export const getListItemById = (id: number) =>
  createSelector(
    getProductionList,
    ({ listItems }) =>
      listItems.find((item: ListItemType) => item.id === id) || null,
  );

export const getPdfListItems = (state: RootState) =>
  createSelector(
    getProductionList,
    getTradeFilter,
    (productionList, tradeFilter) => {
      if (!productionList) return null;
      const { pdf } = state.estimatorProductionTools;
      const listItems = filterItemsByTrade(
        productionList.listItems,
        tradeFilter,
      ).filter(
        (listItem: ListItemType) =>
          listItem.type === pdf.type &&
          listItem.vendor &&
          (pdf.type !== LineItemTypeEnum.OTHER
            ? listItem.vendor.distributorId === pdf.vendor?.distributorId
            : true),
      );
      return sortByVendorWithTax(listItems);
    },
  )(state);

export const getJobDetails = (state: RootState) =>
  state.estimatorProductionTools.jobDetails;
export const getEstimate = (state: RootState) =>
  state.estimatorProductionTools.estimateDetails;
export const getJobMeasurements = (state: RootState) =>
  state.estimatorProductionTools.jobMeasurements;

export const getListItems = createSelector(getEstimate, (estimateDetails) => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const res: any = [];
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  estimateDetails?.estimates.forEach((estimate: any) =>
    res.push(...estimate.lineItems),
  );
  return res;
});

export const getListItemCategories = (state: RootState) =>
  categorizeListItems(state.estimatorProductionTools.productionList);

export const getItemsSortedByCategoriesAndVendors = (
  state: RootState,
): LineItemCategoriesSortedByVendorsType =>
  createSelector(
    getListItemCategories,
    getTradeFilter,
    (categorizedItems, selectedTradeType) => {
      let filteredItems = categorizedItems;
      if (selectedTradeType !== TradeTypes.ALL_TRADES) {
        filteredItems = filterCategorizedListItemsByTrade(
          categorizedItems,
          selectedTradeType,
        );
      }
      const sortedByVendors: LineItemCategoriesSortedByVendorsType =
        Object.keys(filteredItems).reduce((categories, category) => {
          const sortedCategory = sortByVendorWithTax(filteredItems[category]);
          return { ...categories, [category]: sortedCategory };
        }, {});

      return sortedByVendors;
    },
  )(state);

export const getVendorsPerCategoryAlphabetized = (
  state: RootState,
): VendorNamesSortedAlphabeticallyType =>
  createSelector(
    getItemsSortedByCategoriesAndVendors,
    (itemsSorted: LineItemCategoriesSortedByVendorsType) =>
      Object.keys(itemsSorted).reduce((categories, category) => {
        const categorySorted = Object.keys(itemsSorted[category]).sort(
          (a: string, b: string) =>
            Number(a === 'OTHER') - Number(b === 'OTHER') ||
            +(a > b) ||
            -(a < b),
        );
        return { ...categories, [category]: categorySorted };
      }, {}),
  )(state);

export const getListItemsWithExternalProductIds =
  (listItems: ListItemType[]) => () =>
    listItems.filter((listItem: ListItemType) => !!listItem.externalProductId);

export const getListItemsWithVendorDistributorIds =
  (listItems: ListItemType[]) => () =>
    listItems.filter(
      (listItem: ListItemType) =>
        !!listItem.vendor && !!listItem.vendor.distributorId,
    );

export const getExternalProductIds = (listItems: ListItemType[]) =>
  createSelector(
    getListItemsWithExternalProductIds(listItems),
    (listItemsWithExternalProductIds: ListItemType[]) =>
      listItemsWithExternalProductIds.map(
        (listItem: ListItemType) => listItem.externalProductId,
      ),
  );

export const getVendorDistributorIds = (listItems: ListItemType[]) =>
  createSelector(
    getListItemsWithVendorDistributorIds(listItems),
    (listItemsWithVendorDistributorIds: ListItemType[]) =>
      listItemsWithVendorDistributorIds.map((listItem: ListItemType) =>
        get(listItem, 'vendor.distributorId', null),
      ),
  );

export const getMaterialListItemsByVendor = (vendor: VendorType | null) =>
  createSelector(getProductionList, ({ listItems = [] }) => {
    if (!vendor) return [];
    return listItems.filter(
      (listItem: ListItemType) =>
        listItem.type === LineItemTypeEnum.MATERIAL &&
        listItem.vendor &&
        listItem.vendor.id === vendor.id,
    );
  });

export const getVendorForOrder = (state: RootState) =>
  get(
    state,
    'estimatorProductionTools.projectManagementOrderData.vendorForOrder',
    null,
  );

export const getMaterialListItemsForOrderVendor = createSelector(
  getProductionList,
  getVendorForOrder,
  ({ listItems = [] }, vendor) => {
    if (!vendor) return [];
    return listItems.filter(
      (listItem: ListItemType) =>
        listItem.type === LineItemTypeEnum.MATERIAL &&
        listItem.vendor &&
        listItem.vendor.id === vendor.id,
    );
  },
);

export const getProductsMap = (products: Product[]) => () => {
  const productsMap: ProductsMap = {};
  products.forEach((product) => {
    productsMap[product.id] = product;
  });
  return productsMap;
};

export const getListItemsInReduxStore = createSelector(
  getProductionList,
  (productionList) => get(productionList, 'listItems', []),
);

export const getProductionListId = createSelector(
  getProductionList,
  (productionList) => productionList?.id,
);

export const getIsPollingProductionList = (state: RootState) =>
  state.estimatorProductionTools.projectManagementOrderData
    .isPollingProductionList;

export const getProjectManagementOrderData = (state: RootState) =>
  state.estimatorProductionTools.projectManagementOrderData;

export const getOrderCheck = (state: RootState) =>
  state?.estimatorProductionTools?.projectManagementOrderData?.distributionOrder
    ?.orderCheck;

export const getOrderDetailsForm = (state: RootState) =>
  state.estimatorProductionTools.orderDetailsForm;

export const getOrderDocumentsByOrderDocumentState =
  (orderDocumentState: string) => (state: RootState) =>
    state.estimatorProductionTools.orderDocuments.filter(
      (orderDocument: OrderDocumentType) =>
        orderDocument.state === orderDocumentState,
    );

export const getProductionListTradeTypes = createSelector(
  getProductionList,
  ({ listItems }) =>
    Array.from<string>(
      new Set(listItems.map((item: ListItemType) => item.tradeType)),
    ),
);

export const getDoesOrderCheckListItemHaveErrors = createSelector(
  getOrderCheck,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (orderCheck?: any) => {
    const listItems = orderCheck?.lineItems ?? [];
    let hasErrors = false;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    listItems.forEach((listItem: any) => {
      if (!!listItem?.errors?.length) hasErrors = true;
    });

    return hasErrors;
  },
);
