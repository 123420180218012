import { PureComponent } from 'react';

import { Icon } from '@hover/blueprint';
import { iPlus } from '@hover/icons';
import autobind from 'autobind-decorator';
import { connect } from 'react-redux';
import Redux, { bindActionCreators } from 'redux';

import {
  ListItemCreate as ListItemCreateParams,
  LineItemTypeEnum,
} from 'src/api/graphql-global-types';
import { withTypewriter } from 'src/components/WithTypewriter';
import { AddListItemFormModal } from 'src/features/projectManagement/components/ProductionView/AddListItemFormModal/Modal';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { RootState, RootAction } from 'src/types/reduxStore';

import * as EstimatorProductionActions from '../../../redux/actions';
import { getJobDetails } from '../../../redux/selectors/estimatorProductionSelectors';
import { AddButton } from './indexStyled';

export const mapStateToProps = (state: RootState) => ({
  productionList: state.estimatorProductionTools.productionList,
  shouldShowAddListItemModal:
    state.estimatorProductionTools.shouldShowAddListItemModal,
  jobDetails: getJobDetails(state),
  commonProps: getUserTrackingProps(state),
});

export const mapDispatchToProps = (dispatch: Redux.Dispatch<RootAction>) =>
  bindActionCreators(
    {
      createListItem: EstimatorProductionActions.createListItem,
      toggleShouldShowAddListItemModal:
        EstimatorProductionActions.toggleShouldShowAddListItemModal,
      updateAddListItemToast: EstimatorProductionActions.updateAddListItemToast,
    },
    dispatch,
  );

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    typewriter: any;
  };

export class AddListItemComponent extends PureComponent<Props> {
  @autobind
  toggleAddItemModal() {
    const {
      shouldShowAddListItemModal,
      toggleShouldShowAddListItemModal,
      updateAddListItemToast,
      commonProps,
      jobDetails,
      typewriter,
    } = this.props;
    toggleShouldShowAddListItemModal(!shouldShowAddListItemModal);
    // clear any old toasts
    updateAddListItemToast('', false);

    typewriter.buttonPressed({
      button_text: 'Add New List Item',
      primary_cta: false,
      page_or_screen_name: EventNames.pmp.page,
      job_id: Number(jobDetails?.id),
      ...commonProps,
    });
  }

  @autobind
  createListItemWithProductionListId(
    params: ListItemCreateParams = {
      name: '',
      type: LineItemTypeEnum.MATERIAL,
    },
  ) {
    const {
      createListItem,
      productionList,
      updateAddListItemToast,
      typewriter,
    } = this.props;

    createListItem(productionList.id, params);
    updateAddListItemToast(params.name, true);
  }

  public render() {
    return (
      <>
        <AddButton
          data-testid="open-add-item-modal-button"
          onClick={this.toggleAddItemModal}
        >
          <Icon icon={iPlus} />
        </AddButton>
        <AddListItemFormModal
          closeModalFn={this.toggleAddItemModal}
          createListItemFn={this.createListItemWithProductionListId}
        />
      </>
    );
  }
}

export const AddListItem = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTypewriter(AddListItemComponent));
