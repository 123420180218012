import { Box, Heading, Icon, Body, Image, Popover } from '@hover/blueprint';
import { motion } from '@hover/blueprint/foundation';
import { hHoverSolid, iCheck } from '@hover/icons';

import { configTemplateCollectionsForOrg_estimationConfigTemplateCollectionsForOrg_templateSections_templatesByTradeType_templates as Template } from 'src/api/types/configTemplateCollectionsForOrg';
import { useTracking } from 'src/hooks';
import { useIsMobileBreakpoint } from 'src/hooks/useIsMobileBreakpoint';
import { EventNames } from 'src/types';

export const TemplateTile: React.FC<{
  template: Template;
  selectedTemplateIds?: number[];
  setSelectedTemplateIds?: React.Dispatch<React.SetStateAction<number[]>>;
}> = ({ template, selectedTemplateIds, setSelectedTemplateIds }) => {
  const { useCommonTrackingProps, useTypewriter } = useTracking();
  const typewriter = useTypewriter();
  const commonTrackingProps = useCommonTrackingProps();
  const isMobile = useIsMobileBreakpoint();

  const imageUrl = template.templateImage?.image?.redirectUrl;
  const altText = template.templateImage?.image?.filename || '';

  const handleClick = () => {
    if (setSelectedTemplateIds && selectedTemplateIds) {
      const newTemplateIds = [...selectedTemplateIds];
      const isNewTemplateSelected = newTemplateIds[template.id];

      // if template is already selected, remove it from the list else add it
      if (newTemplateIds.includes(template.id)) {
        newTemplateIds.splice(newTemplateIds.indexOf(template.id), 1);
      } else {
        newTemplateIds.push(template.id);
      }

      typewriter.buttonPressed({
        button_text: `${template.name}, ${template.brand}`,
        button_type: `${
          isNewTemplateSelected ? 'deselect' : 'select'
        }_template`,
        page_or_screen_name: EventNames.projectEstimator.selectView.page,
        ...commonTrackingProps,
      });

      setSelectedTemplateIds(newTemplateIds);
    }
  };

  const isTemplateSelected = selectedTemplateIds?.includes(template.id);

  const renderTile = () => (
    <Box
      borderRadius="5px"
      minHeight={{ base: '85px', tablet: '175px', desktop: '175px' }}
      borderStyle="solid"
      borderWidth="2px"
      borderColor={{
        base: 'neutral.200',
        tablet: isTemplateSelected ? 'neutral.900' : 'neutral.200',
      }}
      gap={400}
      padding={200}
      flexDirection={{ base: 'row', tablet: 'column' }}
      alignItems={{ base: 'center', tablet: 'flex-start' }}
      justifyContent={{ base: 'space-between' }}
      onClick={handleClick}
      transition={`background-color ${motion.easings[250]}, filter 250ms cubic-bezier(0.25, 0.1, 0.25, 1) 0s`}
      _hover={{ backgroundColor: 'neutral.200', cursor: 'pointer' }}
    >
      <Box
        display={{ base: 'flex', tablet: 'flex' }}
        width={{ base: undefined, tablet: '100%' }}
        flexDirection={{ base: undefined, tablet: 'row' }}
        alignItems={{ base: 'center', tablet: 'flex-end' }}
        paddingLeft={0}
        paddingTop={{ base: undefined, tablet: 200 }}
      >
        {imageUrl ? (
          <Image
            objectFit="cover"
            style={{ aspectRatio: '1/1' }}
            borderRadius="50%"
            maxH={{ base: '24px', tablet: '35px' }}
            padding={0}
            marginBottom={{ base: 0, tablet: 200 }}
            src={imageUrl}
            alt={altText}
          />
        ) : (
          <Icon
            icon={hHoverSolid}
            color="primary.600"
            size="large"
            margin={0}
            padding={0}
            marginBottom={{ base: 0, tablet: 200 }}
            height={{ base: '24px', tablet: '35px' }}
          />
        )}
      </Box>
      <Box
        flexDirection={{ base: 'column' }}
        alignItems={{ base: 'baseline' }}
        justifyContent="flex-start"
        flex={1}
        width="100%"
      >
        <Box
          width="100%"
          flex={1}
          marginLeft={{ base: 200, tablet: 0 }}
          alignItems={{ base: 'center', tablet: 'normal' }}
        >
          <Heading size={200} margin="0" padding="0" noOfLines={{ base: 3 }}>
            {template.name}
          </Heading>
        </Box>
        <Box flexDirection="row" justifyContent="space-between" width="100%">
          <Body marginY={0} color="neutral.400">
            {template.brand ?? 'Generic'}
          </Body>

          {isTemplateSelected && (
            <Icon
              icon={iCheck}
              color="neutral.900"
              size="small"
              display={{ base: 'none', tablet: 'flex' }}
            />
          )}
        </Box>
      </Box>
      {isMobile && isTemplateSelected && (
        <Box>
          <Icon
            icon={iCheck}
            color="neutral.900"
            size="small"
            display={{ base: 'flex', tablet: 'none' }}
          />
        </Box>
      )}
    </Box>
  );

  if (template.description)
    return (
      <Popover isHover colorScheme="dark" trigger={renderTile()}>
        {template.description}
      </Popover>
    );

  return renderTile();
};
