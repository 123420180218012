import { Body, Label, Select, Box } from '@hover/blueprint';
import { useFormContext, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { fillArrayRange } from 'src/features/settings/utils/proposals';
import { useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types';

export const ProposalMaxSignee = () => {
  const { control } = useFormContext();

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    typewriter.optionSelected({
      option_type: 'list',
      selection: e.target.value,
      page_or_screen_name: EventNames.settings.proposal.page,
      primary_cta: false,
      options: 'How Many Signees',
      ...commonTrackingProps,
    });
  };

  const maxSignees = fillArrayRange(2);

  return (
    <Box flexDirection="column" marginTop={400}>
      <Body as="span" size={500} fontWeight="bold">
        Document Signees Preference
      </Body>
      <Label
        htmlFor="maxSigneeCount"
        color="neutral600"
        size={400}
        marginY={200}
        flex={1}
      >
        Manage how many signees can Sales Rep add per proposal
      </Label>
      <Controller
        control={control}
        name="maxSigneeCount"
        render={({ field: { value, onChange } }) => (
          <Select
            data-testid="maxSigneeCount"
            width="80px"
            size="small"
            id="maxSigneeCount"
            onChange={(e) => {
              onChange(e.target.value);
              handleChange(e);
            }}
            value={value}
          >
            {maxSignees.map((n) => (
              <option
                data-testid={`maxSigneeCountOption${n}`}
                key={n}
                value={n}
              >
                {n}
              </option>
            ))}
          </Select>
        )}
      />
    </Box>
  );
};
