// @TODO remove when new header gets released to Mobile

import { isReactNativeWebview } from 'src/utils/EnvUtils';

// Old events
const START_RECALCULATION_EVENT = 'startMaterialListRecalculation';
const MATERIAL_LIST_CREATED_EVENT = 'materialListCreated';
const OPEN_EXTERNAL_LINK_EVENT = 'open_external_link';

// Header controls
const SHOW_HEADER_HELP_BUTTON_EVENT = 'show_help_button';
const SHOW_HEADER_CLOSE_BUTTON_EVENT = 'show_close_button';
const SHOW_HEADER_BACK_BUTTON_EVENT = 'show_back_button';

// Navigation controls
const RESET_NAVIGATION_HISTORY_EVENT = 'nav_reset_history';

// Material List
const DOWNLOAD_PDF_EVENT = 'download_pdf';

export const REACT_NATIVE_EVENTS = {
  START_RECALCULATION_EVENT,
  MATERIAL_LIST_CREATED_EVENT,
  OPEN_EXTERNAL_LINK_EVENT,
  SHOW_HEADER_HELP_BUTTON_EVENT,
  SHOW_HEADER_CLOSE_BUTTON_EVENT,
  SHOW_HEADER_BACK_BUTTON_EVENT,
  RESET_NAVIGATION_HISTORY_EVENT,
  DOWNLOAD_PDF_EVENT,
};

export const triggerReactNativeEvent = (
  type: string,
  data: any | undefined = {},
) => {
  if (!isReactNativeWebview()) {
    return;
  }

  const eventData = { type, ...data };

  window.ReactNativeWebView.postMessage(JSON.stringify(eventData));
};
