import { PureComponent } from 'react';

import { CustomInputValueUserIcon, CustomInputValueContainer } from './styled';

interface Props {
  isCustomized: boolean;
  parent: string;
  margin?: string;
  width?: React.CSSProperties['width'];
  maxWidth?: React.CSSProperties['maxWidth'];
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default class CustomInputValueIndicator extends PureComponent<Props> {
  render() {
    const { isCustomized, parent, margin, width, maxWidth } = this.props;
    return (
      <CustomInputValueContainer
        margin={margin}
        width={width}
        maxWidth={maxWidth}
      >
        <CustomInputValueUserIcon
          data-testid={`CustomInputValueUserIcon-${parent}`}
          shouldShow={isCustomized}
          icon="user"
          family="fontAwesome"
          iconSize={14}
          color="lightGrayFour"
        />
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {this.props.children}
      </CustomInputValueContainer>
    );
  }
}
