import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { getIsEstimateGroupSold } from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { getCanViewProductionConsole } from 'src/redux/selectors';
import { Modal } from 'style-guide';

import { ChangeSoldStatusModal } from './ChangeSoldStatusModal';
import { UserCannotChangeStatus } from './UserCannotChangeStatus';

export const ModalContentStyled = styled.div`
  max-width: 350px;
  padding: 32px;
  width: 100%;
`;

type Props = {
  setShowModal: (show: boolean) => void;
  isOpen: boolean;
};

export const SalesOpModal: React.FC<Props> = ({ setShowModal, isOpen }) => {
  const canViewProductionConsole = useSelector(getCanViewProductionConsole);
  const isEstimateGroupSold = useSelector(getIsEstimateGroupSold);

  const renderModals = () => {
    if (isEstimateGroupSold) {
      if (canViewProductionConsole) {
        return <ChangeSoldStatusModal setShowModal={setShowModal} isSold />;
      }
      return <UserCannotChangeStatus setShowModal={setShowModal} />;
    }
    return <ChangeSoldStatusModal setShowModal={setShowModal} />;
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalContentStyled data-testid="sales-op-modal">
        {renderModals()}
      </ModalContentStyled>
    </Modal>
  );
};
