import { useQuery } from '@apollo/client';

import { JOB_DETAILS } from 'src/api/queries/queries';
import { jobDetails, jobDetailsVariables } from 'src/api/types/jobDetails';

export const useGetJobDetails = ({ jobId }: { jobId?: number | null }) => {
  return useQuery<jobDetails, jobDetailsVariables>(JOB_DETAILS, {
    skip: !jobId,
    variables: { ids: [jobId as number] },
  });
};
