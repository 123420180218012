import { useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Icon,
  Loader,
  Table,
  Tbody,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@hover/blueprint';
import { iInfo } from '@hover/icons';
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';

import { inspectionConfigChecklistTemplate_inspectionConfigChecklistTemplate as ChecklistTemplateType } from 'src/api/types/inspectionConfigChecklistTemplate';
import {
  ARCHIVE_TEMPLATE,
  GENERATE_TEMPLATES,
} from 'src/features/settings/api/mutations/inspectionChecklist';
import { GET_INSPECTION_CHECKLIST_TEMPLATES } from 'src/features/settings/api/queries/inspectionChecklist';
import { InspectionRow } from 'src/features/settings/components/ChecklistTemplates/InspectionRow';
import { ConfirmDeleteModal } from 'src/features/settings/components/common/ConfirmDeleteModal';
import { getUserOrgId } from 'src/redux/selectors';

export interface CurrentTemplate {
  id: ChecklistTemplateType['id'];
  name: ChecklistTemplateType['name'];
}

export const InspectionTable: React.FC = () => {
  const orgId = useSelector(getUserOrgId);
  const [isPolling, setIsPolling] = useState(false);

  const { data, loading, startPolling, stopPolling } = useQuery(
    GET_INSPECTION_CHECKLIST_TEMPLATES,
    {
      variables: {
        orgId,
      },
    },
  );

  const [generateDefaultInspectionTemplates] = useMutation(GENERATE_TEMPLATES, {
    variables: { orgId: String(orgId) },
  });

  const [archiveTemplate, { loading: archiveLoading }] = useMutation(
    ARCHIVE_TEMPLATE,
    {
      refetchQueries: ['inspectionConfigChecklistTemplates'],
    },
  );

  const templates = data?.inspectionConfigChecklistTemplates
    ?.nodes as ChecklistTemplateType[];

  useEffect(() => {
    if (
      Number.isInteger(templates?.length) &&
      templates?.length === 0 &&
      !isPolling
    ) {
      generateDefaultInspectionTemplates().catch((err) =>
        Sentry.captureException(err),
      );
      startPolling(500);
    }
  }, [templates?.length]);

  useEffect(() => {
    if (isPolling && templates?.length) {
      stopPolling();
      setIsPolling(false);
    }
  }, [isPolling, templates?.length]);

  const [currentTemplate, setCurrentTemplate] = useState({ id: '', name: '' });

  const handleClickCancelDelete = () => {
    setCurrentTemplate({ id: '', name: '' });
  };

  const handleClickConfirmDelete = async () => {
    await archiveTemplate({ variables: { id: currentTemplate.id } });
    handleClickCancelDelete();
  };

  const showConfirmDeleteModal = (template: CurrentTemplate) => {
    setCurrentTemplate(template);
  };

  if (loading) {
    return <Loader size="large" />;
  }

  return (
    <Box flexDirection="column" width="100%">
      <Table size="small">
        <Thead>
          <Tr>
            <Th>Checklist Name</Th>
            <Th>
              <Box alignItems="center">
                Status
                <Tooltip
                  hasArrow
                  placement="top-start"
                  label="Active templates will be visible and accessible to all users. Inactive templates will not be visible to any user."
                >
                  <Icon icon={iInfo} color="neutral.600" marginLeft={200} />
                </Tooltip>
              </Box>
            </Th>
            <Th>Last update</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody data-testid="checklistTemplatesTable">
          {templates?.map((template) => (
            <InspectionRow
              key={template.id}
              template={template}
              showConfirmDeleteModal={showConfirmDeleteModal}
            />
          ))}
        </Tbody>
      </Table>
      <ConfirmDeleteModal
        isOpen={currentTemplate.id !== ''}
        isLoading={archiveLoading}
        message={`Are you sure you want to delete the ${currentTemplate.name} template?`}
        onCancel={handleClickCancelDelete}
        onConfirm={handleClickConfirmDelete}
      />
    </Box>
  );
};
