import { useCallback } from 'react';

import { Button } from '@hover/blueprint';

import { NAVIGATION_DIRECTION } from 'src/features/exteriorEstimator/types/Navigation';

interface OwnProps {
  onClickBack?: () => void;
  navigateCategories: (
    direction: NAVIGATION_DIRECTION,
    forcedTargetCategoryIndex: number | null,
  ) => void;
}

export const BackButton: React.FC<OwnProps> = ({
  onClickBack,
  navigateCategories,
}) => {
  const handleBackButtonClick = useCallback(
    (event) => {
      event.stopPropagation();
      if (onClickBack) onClickBack();
      navigateCategories(NAVIGATION_DIRECTION.BACKWARD, null);
    },
    [navigateCategories, onClickBack],
  );

  return (
    <Button
      shape="pill"
      size="large"
      fill="outline"
      onClick={handleBackButtonClick}
      data-testid="estimatorBackButton"
    >
      Back
    </Button>
  );
};
