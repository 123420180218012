/* eslint-disable camelcase */

import {
  TradeTypeCategoryEnum,
  TradeTypeEnum,
} from 'src/api/graphql-global-types';
import { EstimateGroup_estimationEstimateGroup as EstimateGroupData } from 'src/api/types/EstimateGroup';
import { estimationConfigInputCategories_estimationConfigInputCategories_edges_node as InputCategory } from 'src/api/types/estimationConfigInputCategories';
import {
  estimationConfigRequiredInputs_estimationConfigRequiredInputs_edges_node_inputConditions as InputCondition,
  estimationConfigRequiredInputs_estimationConfigRequiredInputs_edges_node_dependentInputConditions as DependentInputCondition,
  estimationConfigRequiredInputs_estimationConfigRequiredInputs_edges_node as RequiredInput,
} from 'src/api/types/estimationConfigRequiredInputs';
import { estimationConfigTemplates_estimationConfigTemplates_nodes as Template } from 'src/api/types/estimationConfigTemplates';
import { estimationEstimateFields_estimates_discounts as Discount } from 'src/api/types/estimationEstimateFields';
import { wasteFactorRoof_wasteFactorRoof as WasteFactorRoof } from 'src/api/types/wasteFactorRoof';

export type {
  InputCondition,
  EstimateGroupData,
  InputCategory,
  RequiredInput,
  WasteFactorRoof,
  Template,
  Discount,
};

export type Tab = {
  category: string;
  questions: Input[];
};

export interface Page {
  category: string;
  description?: string | null | undefined;
  questions?: Input[];
  shouldRender: boolean;
  tabs?: Tab[];
}

export interface OrgQuestion {
  id: number;
  question: string;
  org_id: number;
  input_id: number;
}

export interface Input {
  description: string | null | undefined;
  id: number | string;
  name: string | null;
  questionDefaultValue?: string | number | boolean | null;
  question: string | null;
  face?: string;
  total?: number | string;
  pitch?: string;
  area?: number;
  sortOrder?: number | null;
  tradeTypes?: TradeTypeEnum[] | null;
  trade_type?: TradeTypeEnum | null;
  tradeTypeCategories?: TradeTypeCategoryEnum[] | null;
  isEditable?: boolean;
  inputType: string;
  inputCategory: {
    name: string;
    id: string;
    description: string | null | undefined;
  };
  inputOptions?: InputOption[];
  inputConditions?: InputCondition[];
  dependentInputConditions?: DependentInputCondition[];
  argument?: string;
  measurementUnits?: string;
  answer?: QuestionAnswer;
  combinedArea?: number;
  trimArea?: number;
  openingsTrim?: number;
  areaWithOpenings?: number;
}

export interface PristineMeasurement {
  questionId: number | string;
  answer?: QuestionAnswer;
}

export interface InputOption {
  label: string;
  value: string;
  sortOrder?: number | null;
  updatedAt?: string;
  id?: string;
}

export type QuestionId = string | number;
export type QuestionAnswer = string | number | boolean | undefined | null;

export type QuestionResponses = {
  [key in QuestionId]: QuestionAnswer;
};

export enum CustomLineItemErrorInput {
  KEY = 'KEY',
  TRADE_TYPE = 'TRADE_TYPE',
}

export interface CustomLineItem {
  key: string;
  value: string;
  error?: {
    text: string;
    input: CustomLineItemErrorInput;
  };
  tradeType?: string;
  type?: string;
}

export type FacetPageTradeTypes =
  | TradeTypeEnum.ROOF
  | TradeTypeEnum.SIDING
  | TradeTypeEnum.STONE;
