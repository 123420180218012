/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, useTheme, Theme, Heading, Tabs, TabList } from '@hover/blueprint';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { SettingsSection } from 'src/features/settings/components/Settings';
import { SidebarItem } from 'src/features/settings/components/Sidebar/SidebarItem';
import { useIsMobileBreakpoint } from 'src/hooks/useIsMobileBreakpoint';

const SidebarContainer = styled(Box)<{ theme: Theme }>`
  ${({ theme }) =>
    css`
      border-right: 2px solid ${theme.colors.neutral200};
      background-color: ${theme.colors.neutral100};
    `}
`;

export const Sidebar: React.FC<{
  sections: SettingsSection[] | null;
  tabIndex: number | null;
  handleTabsChange: (index: number | null) => void;
  hideSidebarRoutes: string[];
}> = ({ sections, tabIndex, hideSidebarRoutes, handleTabsChange }) => {
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useIsMobileBreakpoint();

  if (!sections || (isMobile && hideSidebarRoutes.includes(location.pathname)))
    return null;

  return (
    <SidebarContainer
      flex="0 0 auto"
      flexDirection="column"
      theme={theme}
      data-testid="workflowsSidebar"
      width="310px"
    >
      <Box
        px={400}
        py={200}
        borderBottomStyle="solid"
        borderBottomWidth="1px"
        borderBottomColor="neutral200"
      >
        <Heading size={200} paddingLeft="20px" paddingTop={500}>
          MANAGE WORKFLOWS
        </Heading>
      </Box>

      {/* @ts-ignore */}
      <Tabs orientation="vertical" index={tabIndex} onChange={handleTabsChange}>
        <TabList borderRight="none">
          {sections?.map((section, index) => {
            return (
              <SidebarItem
                key={section.url}
                label={section.title}
                url={section.url}
                index={index}
                setIndex={handleTabsChange}
                isNew={section.isNew}
              />
            );
          })}
        </TabList>
      </Tabs>
    </SidebarContainer>
  );
};
