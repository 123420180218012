import {
  Field,
  RadioGroup,
  SimpleGrid,
  TileRadio,
  Select,
} from '@hover/blueprint';

import { jobDetails_jobs_results as JobDetails } from 'src/api/types/jobDetails';
import { Input } from 'src/features/exteriorEstimator/types';
import type { QuestionAnswer } from 'src/features/exteriorEstimator/types';
import { sortQuestionInputOptions } from 'src/features/exteriorEstimator/utils/questionsUtils';
import { useTracking } from 'src/hooks';
import {
  ESTIMATOR_WORKFLOW_DROPDOWN_IN_SELECT_QUESTIONS,
  isEnabled,
} from 'src/lib/FeatureFlag';
import { questionProps, jobProps } from 'src/utils/trackingUtils';

type Props = {
  question: Input;
  value?: string;
  jobDetails?: JobDetails | null;
  questionText: string;
  handleInputChange: (answer: QuestionAnswer) => void;
};

export const SelectTileQuestion: React.FC<Props> = ({
  question,
  value,
  jobDetails,
  questionText,
  handleInputChange,
}) => {
  const { inputOptions } = question;

  const sortedOptions = sortQuestionInputOptions(inputOptions ?? []);

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const shouldUseSelect =
    sortedOptions.length >= 5 &&
    isEnabled(ESTIMATOR_WORKFLOW_DROPDOWN_IN_SELECT_QUESTIONS);

  const handleSelectOption = (newValue: string) => {
    handleInputChange(newValue);

    typewriter.optionSelected({
      selection: newValue,
      option_type: shouldUseSelect ? 'select' : 'radio',
      options: question?.name ?? '',
      ...commonTrackingProps,
      ...jobProps(jobDetails),
      ...questionProps(jobDetails, question, newValue),
    });
  };

  return (
    <Field label={questionText} name="radioTileInput">
      {shouldUseSelect ? (
        <Select
          value={value}
          onChange={(e) => handleSelectOption(e.target.value)}
        >
          {sortedOptions.map((option) => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      ) : (
        <RadioGroup value={value} onChange={handleSelectOption}>
          <SimpleGrid gap={400} minChildWidth="128px">
            {sortedOptions.map((option) => (
              <TileRadio value={option.value} key={option.value}>
                {option.label}
              </TileRadio>
            ))}
          </SimpleGrid>
        </RadioGroup>
      )}
    </Field>
  );
};
