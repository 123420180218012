import React from 'react';

import { Box } from '@hover/blueprint';
import { useSelector } from 'react-redux';

import type {
  projectManagementProductionList_projectManagementProductionList_estimateGroup as EstimateGroup,
  projectManagementProductionList_projectManagementProductionList as ProjectManagementProductionList,
} from 'src/api/types/projectManagementProductionList';
import { Financials } from 'src/features/project/components/ProjectScope/ProjectDetails/Financials';
import { FinancialsTeaser } from 'src/features/project/components/ProjectScope/ProjectDetails/FinancialsTeaser';
import { MobileProjectBox } from 'src/features/project/components/ProjectScope/ProjectDetails/Mobile/MobileProjectBox';
import { Project } from 'src/features/project/components/ProjectScope/ProjectDetails/Project';
import { ProjectEstimatorProject } from 'src/features/project/components/ProjectScope/ProjectDetails/ProjectEstimatorProject';
import { Property } from 'src/features/project/components/ProjectScope/ProjectDetails/Property';
import { useIsMobileBreakpoint } from 'src/hooks/useIsMobileBreakpoint';
import { COMMERCE_PROJECT_ESTIMATOR, isEnabled } from 'src/lib/FeatureFlag';
import { getOrgSettings } from 'src/redux/selectors';

interface Props {
  estimateGroup: EstimateGroup;
  productionList: ProjectManagementProductionList;
}

export const ProjectDetails: React.FC<Props> = React.memo(
  ({ productionList, estimateGroup }) => {
    const showOrderingFlow = useSelector(getOrgSettings)?.showOrderingFlow;
    const isMobile = useIsMobileBreakpoint();

    const renderProjectDetails = () => {
      // eslint-disable-next-line no-unused-expressions
      return isEnabled(COMMERCE_PROJECT_ESTIMATOR) ? (
        <ProjectEstimatorProject productionList={productionList} />
      ) : (
        <Project productionList={productionList} />
      );
    };

    return (
      <Box justifyContent="space-between" gap={isMobile ? '8px' : 0} flex="1">
        {/* Property Panel */}
        {!isMobile && <Property estimateGroup={estimateGroup} />}

        {/* Project Panel */}
        {isMobile ? (
          <MobileProjectBox estimateGroup={estimateGroup} />
        ) : (
          renderProjectDetails()
        )}

        {/* Financials Panel */}
        {showOrderingFlow ? (
          <FinancialsTeaser />
        ) : (
          <Financials productionList={productionList} />
        )}
      </Box>
    );
  },
);
