import { PureComponent } from 'react';

import { Body, Box } from '@hover/blueprint';
import { connect } from 'react-redux';

import {
  getCurrentQuestionCategoryIndex,
  getPages,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import {
  getMaterialListFeature,
  getOrderingOnlyFeatureAccess,
  getOrgSettings,
} from 'src/redux/selectors';
import { RootState } from 'src/types/reduxStore';

import { MeasurementsLink } from './MeasurementsLink';

export const mapStateToProps = (state: RootState) => ({
  currentQuestionCategoryIndex: getCurrentQuestionCategoryIndex(state),
  numCategories: getPages(state).length,
  jobDetails: state.exteriorEstimator.job.jobDetails,
  showOrderingFlow: getOrgSettings(state)?.showOrderingFlow,
  materialListFeatureEnabled: getMaterialListFeature(state),
  orderingOnlyFeatureAccess: getOrderingOnlyFeatureAccess(state),
});

type Props = ReturnType<typeof mapStateToProps>;
export class TopBarComponent extends PureComponent<Props> {
  get progress() {
    const { numCategories, currentQuestionCategoryIndex } = this.props;
    return (currentQuestionCategoryIndex / (numCategories - 1)) * 100;
  }

  get titleText() {
    const {
      jobDetails,
      showOrderingFlow,
      materialListFeatureEnabled,
      orderingOnlyFeatureAccess,
    } = this.props;
    const showOrderingVersion = orderingOnlyFeatureAccess && showOrderingFlow;
    const showOrderOrMaterialListVersion =
      showOrderingVersion || materialListFeatureEnabled;

    if (showOrderOrMaterialListVersion)
      return `Calculate materials${
        jobDetails ? ` for ${jobDetails.name}` : ''
      }`;

    return `Estimate${jobDetails ? ` for ${jobDetails.name}` : ''}`;
  }

  public render() {
    const { jobDetails } = this.props;

    const isHOVERApp = !!window.HOVERApp;
    const shouldShowMeasurementsLink = !!jobDetails && !isHOVERApp; // mobile app injects HOVERApp object into window
    const isReactNative =
      navigator.product === 'ReactNative' || !!window.ReactNativeWebView; // check if running react-native - https://github.com/facebook/react-native/commit/3c65e62183ce05893be0822da217cb803b121c61

    return isHOVERApp || isReactNative ? null : (
      <Box width={1} zIndex={1} height={500} testId="top-bar">
        <Box
          position="relative"
          height="500"
          width={1}
          backgroundColor="neutral.0"
          top="0"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.2)"
          data-testid="topBarWrapper"
          _before={{
            position: 'absolute',
            width: 1,
            top: '0',
            height: '500',
            left: '0',
            zIndex: '1',
            backgroundColor: 'neutral.0',
          }}
        >
          {/* Progress Bar */}
          <Box
            aria-hidden
            width={`${this.progress}%`}
            height="25"
            backgroundColor="primary.600"
            position="absolute"
            bottom="0"
            left="0"
            zIndex="2"
            transition="300ms"
          />
          {/* Container for Measurements Link */}
          <Box
            height={1}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width={1}
            zIndex={2}
            position="relative"
          >
            <Body paddingLeft="2em" color="neutral.700">
              {this.titleText}
            </Body>
            <Box display="flex" justifyContent="center">
              {shouldShowMeasurementsLink && (
                <MeasurementsLink job={jobDetails} />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

export const TopBar = connect(mapStateToProps)(TopBarComponent);
