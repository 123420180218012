import { Box, Panel } from '@hover/blueprint';
import { connect } from 'react-redux';

import {
  addressLineOneValueField,
  addressLineTwoValueField,
  cityValueField,
  zipCodeValueField,
  stateProvinceValueField,
  inputs,
} from 'src/features/projectManagement/constants';
import {
  isValidLength,
  isAlphaNumeric,
  hasNoEmojis,
} from 'src/features/projectManagement/utils/InputValidator';
import { RootState } from 'src/types/reduxStore';

import AddressInput from '../../../OrderModal/Content/OrderDetailsForm/ShippingAddress/AddressInput';

export const mapStateToProps = (state: RootState) => ({
  job: state.estimatorProductionTools.jobDetails,
});

type Props = ReturnType<typeof mapStateToProps>;

export const DeliveryFormComponent: React.FC<Props> = ({ job }) => {
  const validators = [hasNoEmojis, isAlphaNumeric];

  return (
    <Box flexDirection="column" testId="delivery-form">
      <Panel
        boxShadow="distance500"
        marginBottom={500}
        paddingX={600}
        paddingY={800}
      >
        <Box flexDirection="column">
          {/* https://hoverinc.atlassian.net/browse/EHI-3677
            <Box>
              <AddressInput
                dataTestId="delivery-name-input"
                initialValue={job?.createdBy?.name || ''}
                validators={[isValidLength(30), ...validators]}
                placeholder={`${inputs[customerNameValueField].labelText} (Optional)`}
                labelText={inputs[customerNameValueField].labelText}
                inputId={customerNameValueField}
                width={0.5}
                marginRight={500}
              />
              <AddressInput
                inputType="number"
                dataTestId="delivery-number-input"
                validators={[isValidLength(30), ...validators]}
                placeholder={`${inputs[phoneNumberValueField].labelText} (Optional)`}
                labelText={inputs[phoneNumberValueField].labelText}
                inputId={phoneNumberValueField}
                width={0.5}
              />
            </Box> 
          */}
          <Box>
            <AddressInput
              dataTestId="delivery-address1-input"
              initialValue={job?.locationLine1 ?? ''}
              validators={[isValidLength(30), ...validators]}
              inputId={addressLineOneValueField}
              isRequired
              labelText={inputs[addressLineOneValueField].labelText}
              placeholder="Job Address 1"
              width={0.5}
              marginRight={500}
            />
            <AddressInput
              dataTestId="delivery-address2-input"
              initialValue={job?.locationLine2 ?? ''}
              validators={[isValidLength(30), ...validators]}
              inputId={addressLineTwoValueField}
              isRequired={false}
              labelText={inputs[addressLineTwoValueField].labelText}
              placeholder="Job Address 2 (Optional)"
              width={0.5}
            />
          </Box>
          <Box>
            <Box width={0.5} marginRight={500}>
              <AddressInput
                dataTestId="delivery-city-input"
                initialValue={job?.locationCity ?? ''}
                validators={[isValidLength(25), ...validators]}
                inputId={cityValueField}
                isRequired
                labelText={inputs[cityValueField].labelText}
                placeholder="City"
                width={1}
              />
            </Box>
            <Box width={0.5}>
              <AddressInput
                dataTestId="delivery-region-input"
                initialValue={job?.locationRegion ?? ''}
                validators={[isValidLength(2), ...validators]}
                inputId={stateProvinceValueField}
                isRequired
                labelText={inputs[stateProvinceValueField].labelText}
                placeholder="CA"
                width={0.5}
                marginRight={500}
              />
              <AddressInput
                dataTestId="delivery-postalCode-input"
                initialValue={job?.locationPostalCode ?? ''}
                validators={[isValidLength(10), ...validators]}
                inputId={zipCodeValueField}
                isRequired
                labelText={inputs[zipCodeValueField].labelText}
                placeholder="12345"
                width={0.5}
              />
            </Box>
          </Box>
        </Box>
      </Panel>
    </Box>
  );
};

export const DeliveryForm = connect(mapStateToProps)(DeliveryFormComponent);
