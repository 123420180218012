import React from 'react';

import { Box, Heading, VStack } from '@hover/blueprint';
import { Route, Switch, useLocation } from 'react-router-dom';

import { EstimationConfigInputInputTypeEnum } from 'src/api/graphql-global-types';
import { RecalculateHeader } from 'src/features/projectEstimator/components/EstimatorContainer/RecalculateHeader';
import { EstimatorFooter } from 'src/features/projectEstimator/components/EstimatorFooter/EstimatorFooter';
import { ProjectEstimatorInput } from 'src/features/projectEstimator/components/GenericPage/ProjectEstimatorInput';
import {
  ContentSection,
  Header,
} from 'src/features/projectEstimator/components/SelectTemplates/structuralComponents';
import {
  GENERIC_PAGE_URL,
  MEASUREMENT_SCREEN,
} from 'src/features/projectEstimator/constants';
import { useTemplateStore } from 'src/features/projectEstimator/hooks';
import { areAllInputsAnswered } from 'src/features/projectEstimator/hooks/utils/pageStoreUtils';
import { getTemplatesWithInputsFilteredByCategory } from 'src/features/projectEstimator/hooks/utils/selectors';
import { TemplatesState } from 'src/features/projectEstimator/types';
import { formatCategoryName } from 'src/utils/Formatters';
import { getParams } from 'src/utils/urlUtils';

export const GenericPage: React.FC<{
  category: string;
  templates: TemplatesState;
  padding: number;
}> = ({ category, templates, padding }) => {
  const templatesWithInputs = getTemplatesWithInputsFilteredByCategory(
    category,
    templates,
  );
  const { search } = useLocation();
  const { jobId } = getParams(search);

  // booleans and number inputs that are not a measurement input are  allowed to be null/0
  const inputsThatMustBeAnswered =
    category === MEASUREMENT_SCREEN
      ? Object.values(templatesWithInputs)
          .map((t) => t.inputs)
          .flat()
      : Object.values(templatesWithInputs)
          .map((t) => t.inputs)
          .flat()
          .filter(
            (input) =>
              input.inputType !== EstimationConfigInputInputTypeEnum.NUMBER &&
              input.inputType !== EstimationConfigInputInputTypeEnum.BOOLEAN,
          );

  const nextEnabled = areAllInputsAnswered(inputsThatMustBeAnswered);

  return (
    <Box flexDirection="column" flex={1}>
      <Header padding={padding} paddingX={{ base: 300, tablet: padding }}>
        <RecalculateHeader jobId={jobId as string} />
      </Header>
      <ContentSection
        padding={{ base: 300 }}
        contentBottom={100}
        contentTop={100}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        handleScroll={() => {}}
      >
        <Box
          flexDirection="column"
          width={{ base: 1, tablet: 0.8, desktop: 0.5 }}
          gap={300}
        >
          <Heading>{formatCategoryName(category)}</Heading>
          {Object.entries(templatesWithInputs).map(
            ([templateId, templateWithInputs]) => {
              return (
                <Box
                  flexDirection="column"
                  key={templateWithInputs.name}
                  marginBottom={400}
                >
                  <Heading size={400} marginBottom={200}>
                    {templateWithInputs.name}
                  </Heading>
                  <Box
                    borderColor="neutral.200"
                    borderStyle="solid"
                    borderWidth="1px"
                    flexDirection="column"
                    borderRadius="12px"
                    padding={400}
                  >
                    {templateWithInputs.inputs.map((input) => {
                      return (
                        <Box key={input.id} paddingY={200}>
                          <ProjectEstimatorInput
                            input={input}
                            templateId={templateId}
                          />
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              );
            },
          )}
        </Box>
      </ContentSection>
      <EstimatorFooter nextEnabled={nextEnabled} />
    </Box>
  );
};

export const GenericPageRouter: React.FC<{ padding: number }> = ({
  padding,
}) => {
  const location = useLocation();
  const pages = useTemplateStore((state) => state.pages);
  const templates = useTemplateStore((state) => state.templates);

  const genericPages = pages.filter((page) =>
    page?.url?.startsWith(GENERIC_PAGE_URL),
  );

  return (
    <VStack>
      <Switch location={location}>
        {genericPages.map((page) => (
          <Route key={page.name} path={`${page.url}`}>
            <GenericPage
              category={page.name}
              templates={templates}
              padding={padding}
            />
          </Route>
        ))}
      </Switch>
    </VStack>
  );
};
