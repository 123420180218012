import React, { useMemo } from 'react';

import { IconButton, TextInput } from '@hover/blueprint';
import { iSearch } from '@hover/icons';
import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';

import { updateTemplateSearchFilter } from 'src/features/exteriorEstimator/redux/actions/templatesActions';
import { useEffectOnMount } from 'src/hooks';

export const SearchFilterInput = () => {
  const dispatch = useDispatch();
  const setSearchFilterValue = (value: string) => {
    dispatch(updateTemplateSearchFilter({ value }));
  };

  // we memoize the function here so that it doesn't get recreated on every render
  // and avoids the debounce function being reset and exhaustive deps warning from eslint
  const debouncedHandleUpdate = useMemo(
    () => debounce(setSearchFilterValue, 200),
    [],
  );

  useEffectOnMount(() => {
    dispatch(updateTemplateSearchFilter({ value: '' }));
  });

  return (
    <TextInput
      data-testid="MaterialListSearchFilter"
      placeholder="Search By Name"
      onKeyUp={(event) => {
        debouncedHandleUpdate(event.currentTarget.value);
      }}
      elementBefore={
        <IconButton
          label="search"
          fill="minimal"
          shape="square"
          color="neutral"
          icon={iSearch}
        />
      }
      borderColor="neutral.500"
    />
  );
};
