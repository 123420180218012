import { useCallback } from 'react';

import { Body, Toggle, Box, Button, Icon } from '@hover/blueprint';
import { iHelpCircle } from '@hover/icons';

import { wasteFactorRoof_wasteFactorRoof as WasteFactorRoof } from 'src/api/types/wasteFactorRoof';

export const RecommendedWaste = ({
  openWasteFactorCalcModal,
  predictedWasteFactorRoof,
  onToggleChange,
}: {
  openWasteFactorCalcModal: (shouldShow: boolean) => void;
  predictedWasteFactorRoof: WasteFactorRoof['predictedWasteFactor'] | undefined;
  onToggleChange: (
    predictedWasteFactorAnswer: WasteFactorRoof['predictedWasteFactor'],
  ) => void;
}) => {
  const handleInfoButtonPress = useCallback(() => {
    openWasteFactorCalcModal(true);
  }, [openWasteFactorCalcModal]);

  const handleToggleChange = useCallback(() => {
    onToggleChange(Number(predictedWasteFactorRoof));
  }, [onToggleChange, predictedWasteFactorRoof]);

  return (
    <Box alignItems="center" justifyContent="space-between" marginTop={500}>
      <Box
        data-testid="waste-factor-rec-subtext"
        mb={0}
        marginTop={0}
        alignItems="center"
      >
        <Body
          marginTop={0}
          marginBottom={0}
          size={400}
          color="neutral600"
          data-testid="recommended-waste-body"
        >
          {`Recommended Waste ${predictedWasteFactorRoof}%`}
        </Body>
        <Button
          testId="waste-factor-question-icon"
          fill="minimal"
          shape="circle"
          onClick={handleInfoButtonPress}
          marginLeft={100}
          label="wasteFactorInfoButton"
        >
          <Icon icon={iHelpCircle} color="neutral600" size="medium" />
        </Button>
      </Box>
      <Toggle
        id="recommendedWaste"
        size="small"
        onChange={handleToggleChange}
        testId="waste-factor-toggle"
      />
    </Box>
  );
};

export const NewMeasurementDetails = ({
  label,
  measurementUnits,
  wasteFactorCalcResult,
}: {
  label: string;
  measurementUnits: string;
  wasteFactorCalcResult: string;
}) => (
  <Box
    alignItems="flex-start"
    justifyContent="space-between"
    data-testid="new-measurement-details"
  >
    <Body
      mt={0}
      mb={0}
      size={400}
      color="neutral600"
      data-testid="waste-factor-label"
    >
      {label}
    </Body>
    <Body
      mb={0}
      mt={0}
      size={400}
      color="neutral600"
      data-testid="waste-factor-result"
    >
      {wasteFactorCalcResult} {measurementUnits}
    </Body>
  </Box>
);
