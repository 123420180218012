import { useState } from 'react';

import * as Sentry from '@sentry/react';
import { get, isEmpty, toNumber } from 'lodash';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { TradeTypeEnum } from 'src/api/graphql-global-types';
import { estimationConfigTemplates_estimationConfigTemplates_nodes as Template } from 'src/api/types/estimationConfigTemplates';
import { HDF_CUTOFF_DATE } from 'src/constants';
import { EstimatorApi } from 'src/features/exteriorEstimator/apis/estimator';
import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import * as checklistActions from 'src/features/exteriorEstimator/redux/actions/checklistActions';
import * as inputCategoriesActions from 'src/features/exteriorEstimator/redux/actions/inputCategoriesActions';
import * as measurementsActions from 'src/features/exteriorEstimator/redux/actions/measurementsActions';
import * as pageActions from 'src/features/exteriorEstimator/redux/actions/pageActions';
import * as requiredInputsActions from 'src/features/exteriorEstimator/redux/actions/requiredInputsActions';
import {
  getJobDetails,
  getParams,
  getQuestionResponses,
} from 'src/features/exteriorEstimator/redux/sagas/selectors/estimatorSelectors';
import {
  PlainMeasurements,
  TradeTypeEnumOrg,
} from 'src/features/exteriorEstimator/types';
import { JobMeasurements } from 'src/features/exteriorEstimator/types/jobMeasurements';
import { Input, Page } from 'src/features/exteriorEstimator/types/Questions';
import { EstimationMeasurementParser } from 'src/features/exteriorEstimator/utils/EstimationMeasurementParser';
import {
  getCategories,
  getRequiredInputs,
  getTemplates,
} from 'src/features/exteriorEstimator/utils/gqlUtils';
import * as measurementsUtils from 'src/features/exteriorEstimator/utils/measurementsUtils';
import PartialSidingUtils from 'src/features/exteriorEstimator/utils/PartialSidingUtils';
import { PristineMeasurementsGenerator } from 'src/features/exteriorEstimator/utils/PristineMeasurementsGenerator';
import { QuestionResponsesGenerator } from 'src/features/exteriorEstimator/utils/QuestionResponsesGenerator';
import { EstimatorFlowGenerator } from 'src/features/exteriorEstimator/utils/QuestionsGenerator';
import { EhiOrgSettings } from 'src/redux/reducers/ehiReducer';
import { getTradeTypesSorted } from 'src/redux/selectors';
import { getUserOrgId } from 'src/redux/selectors/orgSelectors';
import { Measurements } from 'src/types/EstimationMeasurementTypes';
import {
  EstimationSummarizedJsonMeasurements,
  HDFMeasurements,
  PartialsMeasurements,
} from 'src/types/HdfMeasurements';

export function useEstimate() {
  const jobIdFromSelector = toNumber(useSelector(getParams).jobId);
  const jobIdFromUrl = Number(get(useParams(), 'jobId'));
  const shouldResetInputs = useSelector(getParams).shouldResetInputs === 'true';
  const jobId = jobIdFromSelector || jobIdFromUrl;
  const orgId = useSelector(getUserOrgId);
  const tradeTypes = useSelector(getTradeTypesSorted);
  const questionResponses = useSelector(getQuestionResponses);
  const jobDetails = useSelector(getJobDetails);

  const [isFetchingQuestions, setIsFetchingQuestions] =
    useState<boolean>(false);

  const dispatch = useDispatch();

  const setPristineMeasurements = (questions: Input[]) => {
    const pristineMeasurements =
      PristineMeasurementsGenerator.initialize(questions);
    dispatch(
      measurementsActions.storePristineMeasurements({ pristineMeasurements }),
    );
  };

  const setQuestionResponses = async ({
    questions,
    tradeTypeToWasteFactorMap,
    templateIds,
  }: {
    questions: Input[];
    tradeTypeToWasteFactorMap: Map<TradeTypeEnum, number>;
    templateIds: number[];
  }) => {
    const responses = QuestionResponsesGenerator.getInitialResponses(
      questions,
      tradeTypeToWasteFactorMap,
    );

    const { responses: responsesFromSummary, customLineItems } =
      await QuestionResponsesGenerator.updateResponsesFromEstimateGroup({
        jobId,
        orgId,
        shouldResetInputs,
        responses,
        questions,
        templateIds,
      });

    if (customLineItems.length) {
      dispatch(
        estimatorActions.getCustomLineItems.success({ customLineItems }),
      );
    }

    dispatch(
      estimatorActions.setQuestionResponsesEnd({
        questionResponses: { ...responses, ...responsesFromSummary },
        jobId,
      }),
    );
    return questionResponses;
  };

  const getOrInitializeSelectedTemplates = (
    selectedTemplates: number[] | null,
  ) => {
    let thisSelectedTemplates = selectedTemplates;
    if (!thisSelectedTemplates || thisSelectedTemplates.length === 0) {
      const parsedHash = queryString.parse(window.location.hash)[
        'template_ids[]'
      ];

      let templateIdsArray: Array<string | null> = [];
      if (parsedHash) {
        if (Array.isArray(parsedHash)) {
          templateIdsArray = parsedHash;
        } else {
          templateIdsArray = [parsedHash];
        }
      }

      const defaultSelectedTemplates: number[] = [];

      thisSelectedTemplates = templateIdsArray.length
        ? templateIdsArray.map((id) => Number(id))
        : defaultSelectedTemplates;
    }

    dispatch(
      estimatorActions.initializeSelectedTemplates({
        templateIds: thisSelectedTemplates,
      }),
    );
    return thisSelectedTemplates;
  };

  // return measurements from estimation json if it exists, or machete model measurements
  const getEstimationJsonMeasurements = async (orgSettings: EhiOrgSettings) => {
    let fullMeasurements: Measurements | null = null;
    let jobMeasurements: JobMeasurements | null = null;
    let plainJsonMeasurements: PlainMeasurements | null = null;

    try {
      fullMeasurements = (
        (await EstimatorApi.getMeasurementsEstimation(Number(jobId))) as any
      ).data as Measurements;

      jobMeasurements = new EstimationMeasurementParser(
        fullMeasurements as Measurements,
        orgSettings,
      ).results;
    } catch (_e) {
      //  else fallback to the machete model measurements
      try {
        const getModelsResponse: any = await EstimatorApi.getModels(
          Number(jobId),
        );
        const { models } = getModelsResponse.data;
        dispatch(estimatorActions.getModels({ models }));
        fullMeasurements = (
          models[0]?.machete_model_measurements?.url
            ? await EstimatorApi.getJobMeasurements(
                models[0].machete_model_measurements.url,
              )
            : null
        ) as any;
        jobMeasurements = fullMeasurements
          ? measurementsUtils.filterJobMeasurements(fullMeasurements as any)
          : {};
      } catch (error) {
        console.log(`error fetching estimationJson for job ${jobId}`, error);
        Sentry.captureException(error);
        dispatch(estimatorActions.setError(error as any));
      }
    }

    try {
      plainJsonMeasurements = (
        await EstimatorApi.getMeasurementsPlainJson(Number(jobId))
      ).data as PlainMeasurements;
    } catch (error) {
      dispatch(estimatorActions.getMeasurementsPlainJson.failure(error as any));
    }
    return { jobMeasurements, fullMeasurements, plainJsonMeasurements };
  };

  const getQuestionsAndPages = async (
    templates: Template[],
    currentlySelectedTemplates: number[],
    showOrderingVersion: boolean,
    orgSettings: EhiOrgSettings,
    tradeTypeToWasteFactorMap: Map<TradeTypeEnum, number>,
  ) => {
    let fullMeasurements: Measurements | null = null;
    let jobMeasurements: JobMeasurements | null = null;
    let hdfMeasurements: HDFMeasurements | null = null;
    let plainJsonMeasurements: PlainMeasurements | null = null;
    let partialsMeasurements: PartialsMeasurements | null = null;

    // store inputs
    const requiredInputs = await getRequiredInputs(
      orgId,
      currentlySelectedTemplates,
    );
    dispatch(requiredInputsActions.storeRequiredInputs(requiredInputs));

    // store categories
    const categories = await getCategories();
    dispatch(inputCategoriesActions.storeInputCategories(categories));

    // store checklist
    const checklist = (
      await EstimatorApi.getChecklist({
        orgId: orgId.toString(),
        jobId: jobId?.toString() || '',
      })
    ).data.inspectionChecklist;
    dispatch(checklistActions.storeChecklist(checklist));

    const isJobMoreRecentThanHdfCutoffDate =
      jobDetails && jobDetails?.hdfCreatedAt
        ? new Date(jobDetails?.hdfCreatedAt) > new Date(HDF_CUTOFF_DATE)
        : false; // this comparison with new Date () with an invalid date will return false

    if (isJobMoreRecentThanHdfCutoffDate) {
      try {
        const measurements = (
          await EstimatorApi.getHDFMeasurements(Number(jobId))
        ).data as EstimationSummarizedJsonMeasurements;

        measurements.siding_area_total =
          PartialSidingUtils.shouldCalculateWithOpenings(orgSettings)
            ? measurements.siding_with_openings_area_total
            : measurements.siding_zero_waste_area_total;

        dispatch(
          estimatorActions.storeHdfMeasurements({
            hdfMeasurements: measurements,
          }),
        );
        hdfMeasurements = measurements;

        partialsMeasurements = {
          edges: measurements.edges,
          facades: measurements.facades,
          windowsForFacade: measurements.windows_for_facade,
        };
        dispatch(
          estimatorActions.storePartialsMeasurements({
            partialsMeasurements,
          }),
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(`error fetching/parsing HDF for job ${jobId}`, error);
        Sentry.captureException(error);
      }
    }

    if (!hdfMeasurements) {
      // fetch estimationJson
      const estimationJsonResponse = await getEstimationJsonMeasurements(
        orgSettings,
      );
      if (estimationJsonResponse.fullMeasurements) {
        fullMeasurements = estimationJsonResponse.fullMeasurements;
        dispatch(estimatorActions.storeFullMeasurements({ fullMeasurements }));
      }

      if (estimationJsonResponse.jobMeasurements) {
        jobMeasurements = estimationJsonResponse.jobMeasurements;
        dispatch(estimatorActions.storeJobMeasurements({ jobMeasurements }));
      }

      if (estimationJsonResponse.plainJsonMeasurements) {
        plainJsonMeasurements = estimationJsonResponse.plainJsonMeasurements;
        dispatch(
          estimatorActions.getMeasurementsPlainJson.success(
            plainJsonMeasurements,
          ),
        );
      }
    }

    const selected = templates.filter((template: Template) =>
      currentlySelectedTemplates.includes(Number(template.id)),
    );

    const generator = EstimatorFlowGenerator.generatePages({
      inputs: requiredInputs as Input[],
      categories,
      templates,
      tradeTypes,
      jobMeasurements: hdfMeasurements || jobMeasurements,
      selectedTemplates: selected,
      checklist,
      showOrderingVersion,
      plainMeasurements: plainJsonMeasurements,
      estimationJson: fullMeasurements,
      hdfMeasurements,
      partialsMeasurements,
      tradeTypeToWasteFactorMap,
      orgSettings,
      questionResponses: !isEmpty(questionResponses) ? questionResponses : null,
    });
    const pages = generator.getSortedPagesWithQuestions();

    dispatch(
      pageActions.storePages({
        pages,
        isManualMeasurementsNeeded: generator.isManualMeasurementsNeeded(),
      }),
    );

    return {
      pages,
      isManualMeasurementsNeeded: !fullMeasurements,
    };
  };

  const getPages = async (
    templates: Template[],
    currentlySelectedTemplates: number[],
    showOrderingVersion: boolean,
    orgSettings: EhiOrgSettings | null,
  ) => {
    if (!templates || templates.length === 0 || !orgSettings) return;
    setIsFetchingQuestions(true);

    const tradeTypeToWasteFactorMap = new Map();
    const tradeTypeOrgEnums = (await EstimatorApi.getTradeTypeEnumOrgs(orgId))
      ?.data?.estimationConfigTradeTypeEnumOrgs?.nodes;

    tradeTypeOrgEnums?.forEach((tradeTypeOrgEnum: TradeTypeEnumOrg) => {
      tradeTypeToWasteFactorMap.set(
        tradeTypeOrgEnum.tradeType,
        tradeTypeOrgEnum.wasteFactor,
      );
    });

    if (currentlySelectedTemplates && currentlySelectedTemplates.length > 0) {
      const { pages } = await getQuestionsAndPages(
        templates,
        currentlySelectedTemplates,
        showOrderingVersion,
        orgSettings,
        tradeTypeToWasteFactorMap,
      );

      const questions = pages.reduce(
        (qs: Input[], p: Page) => qs.concat(p?.questions || []),
        [],
      );

      setPristineMeasurements(questions);

      await setQuestionResponses({
        questions,
        tradeTypeToWasteFactorMap,
        templateIds: currentlySelectedTemplates,
      });
    }
    dispatch(estimatorActions.setupEstimatorEnd());
    setIsFetchingQuestions(false);
  };

  const setupEstimatorForRefresh = async (
    selectedTemplates: number[] | null,
    showOrderingVersion: boolean,
    orgSettings: EhiOrgSettings,
  ) => {
    // fetch templates
    const templates = await getTemplates(orgId);
    // store templates
    dispatch(estimatorActions.getTemplatesEnd({ templates }));

    // get selected templates
    const currentlySelectedTemplates =
      getOrInitializeSelectedTemplates(selectedTemplates);

    // organize inputs and assign them to pages
    await getPages(
      templates,
      currentlySelectedTemplates,
      showOrderingVersion,
      orgSettings,
    );
  };

  const setupEstimator = async ({
    currentlySelectedTemplates,
    templates,
    setIsInitializing,
    showOrderingVersion,
    orgSettings,
  }: {
    currentlySelectedTemplates: number[];
    templates: Template[];
    setIsInitializing: (value: boolean) => void;
    showOrderingVersion: boolean;
    orgSettings: EhiOrgSettings | null;
  }) => {
    setIsInitializing(true);
    await getPages(
      templates,
      currentlySelectedTemplates,
      showOrderingVersion,
      orgSettings,
    );
    setIsInitializing(false);
  };

  return {
    setupEstimatorForRefresh,
    setupEstimator,
    isFetchingQuestions,
  };
}
