import { useMutation } from '@apollo/client';
import { Body, Box, Label, LoadingOverlay, Toggle } from '@hover/blueprint';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

import type { EstimateGroup_estimationEstimateGroup as EstimateGroup } from 'src/api/types/EstimateGroup';
import type { projectManagementProductionList_projectManagementProductionList as ProductionList } from 'src/api/types/projectManagementProductionList';
import { projectManagementProductionListUpdate as ProductionListUpdateData } from 'src/api/types/projectManagementProductionListUpdate';
import { messages } from 'src/constants/messages';
import { TOGGLE_VARIATIONS_MATCHING as TOGGLE_VARIATIONS_MATCHING_ESTIMATE_GROUP } from 'src/features/exteriorEstimator/apis/mutations/estimateGroup';
import { getJobDetails } from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { TOGGLE_VARIATIONS_MATCHING as TOGGLE_VARIATIONS_MATCHING_PRODUCTION_LIST } from 'src/features/project/apis/graphql/queries/queries';
import { useToastEhi, ToastStatusEnum, useTracking } from 'src/hooks';
import { EventNames } from 'src/types/actionTypes';
import { jobProps } from 'src/utils/trackingUtils';

export const enum MatchingGroups {
  PRODUCTION_LIST = 'productionList',
  SELECT_VARIATIONS = 'selectVariations',
}

type ToggleVariationMatchingProps = {
  type: MatchingGroups;
  id: ProductionList['id'] | EstimateGroup['id'];
  enableVariationMatching:
    | ProductionList['enableVariationMatching']
    | EstimateGroup['enableVariationMatching'];
};

const enum TOAST_IDS {
  TOGGLE_VARIATIONS_MATCHING_ERROR,
  TOGGLE_VARIATIONS_MATCHING_SUCCESS,
}

const gqlParams = {
  [MatchingGroups.PRODUCTION_LIST]: {
    query: TOGGLE_VARIATIONS_MATCHING_PRODUCTION_LIST,
    isEnabledPath:
      'projectManagementProductionListUpdate.productionList.enableVariationMatching',
  },
  [MatchingGroups.SELECT_VARIATIONS]: {
    query: TOGGLE_VARIATIONS_MATCHING_ESTIMATE_GROUP,
    isEnabledPath:
      'estimationEstimateGroupUpdate.estimateGroup.enableVariationMatching',
  },
};

export const VariationMatchingToggle: React.FC<
  ToggleVariationMatchingProps
> = ({ type, id, enableVariationMatching }) => {
  const jobDetails = useSelector(getJobDetails);
  const toast = useToastEhi();
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const [toggleVariationsMatching, { loading }] = useMutation(
    gqlParams[type].query,
    {
      onCompleted: (data: ProductionListUpdateData) => {
        const isEnabled = get(data, gqlParams[type].isEnabledPath);
        toast({
          id: TOAST_IDS.TOGGLE_VARIATIONS_MATCHING_SUCCESS,
          description: isEnabled
            ? 'Material variation matching active'
            : 'Material variation matching inactive',
        });
      },
      onError: () => {
        toast({
          id: TOAST_IDS.TOGGLE_VARIATIONS_MATCHING_ERROR,
          description:
            messages.variationMatching.errors.query.settingUpdateFailed,
          status: ToastStatusEnum.ERROR,
        });
      },
    },
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    const variables = {
      [MatchingGroups.PRODUCTION_LIST]: {
        productionListId: id,
        productionListAttributes: {
          enableVariationMatching: checked,
        },
      },
      [MatchingGroups.SELECT_VARIATIONS]: {
        estimateGroupId: id,
        estimateGroupAttributes: {
          enableVariationMatching: checked,
        },
      },
    };

    toggleVariationsMatching({ variables: variables[type] });

    typewriter.checkboxSelected({
      selection: checked
        ? 'Variations Matching Active'
        : 'Variations Matching Inactive',
      page_or_screen_name:
        type === MatchingGroups.SELECT_VARIATIONS
          ? EventNames.estimator.colorSelection
          : EventNames.project.scope.page,
      primary_cta: false,
      options: 'true/false',
      ...commonTrackingProps,
      ...jobProps(jobDetails),
    });
  };

  if (enableVariationMatching === undefined) return null;
  return (
    <Box>
      <LoadingOverlay isLoading={loading} />
      <Label
        box
        flexDirection={{ base: 'row-reverse', tablet: 'row' }}
        alignItems="center"
        width={{ base: '100%', tablet: 'inherit' }}
        justifyContent={{ base: 'left', tablet: 'inherit' }}
      >
        <Body
          as="span"
          size={400}
          marginLeft={{ base: 0, desktop: 200 }}
          marginRight={200}
          whiteSpace="nowrap"
        >
          Material variation auto-matching
        </Body>
        <Toggle
          defaultChecked={enableVariationMatching}
          onChange={onChange}
          size="small"
          height="fit-content"
          alignSelf="center"
          marginRight={{ base: 200, tablet: 0 }}
        />
      </Label>
    </Box>
  );
};
