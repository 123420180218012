import { PureComponent } from 'react';

import { Box } from '@hover/blueprint';
import { connect } from 'react-redux';

import { FormattedNumber } from 'src/components/FormattedNumber';
import { Heading } from 'src/features/exteriorEstimator/components/common/EstimatorHeading';
import { EstimatorResponsiveWrapper } from 'src/features/exteriorEstimator/components/common/EstimatorResponsiveWrapper';
import { EstimatorWarning } from 'src/features/exteriorEstimator/components/common/EstimatorWarning';
import { UpdateAnswer } from 'src/features/exteriorEstimator/redux/actions/answerActions';
import {
  PristineMeasurement,
  QuestionResponses,
  Input,
} from 'src/features/exteriorEstimator/types';
import { RootState } from 'src/types/reduxStore';
import { UNITS_MAP } from 'src/utils/unitsMap';

import {
  getQuestionsForCategory,
  getPristineMeasurements,
  getRoofTotalFromPitches,
  getRoofTotalQuestion,
} from '../../../redux/sagas/selectors';
import { generateMeasurementQuestions } from '../../../utils/measurementQuestionUtils';
import { Question } from '../QuestionView/Question/Question';

export const mapStateToProps = (state: RootState) => ({
  questions: getQuestionsForCategory(state),
  questionResponses: state.exteriorEstimator.inputs.questionResponses,
  pristineMeasurements: getPristineMeasurements(state),
  roofTotalArea: getRoofTotalFromPitches(state),
  roofTotalQuestion: getRoofTotalQuestion(state),
});

interface OwnProps {
  pristineMeasurements: PristineMeasurement[];
  questions?: Input[];
  questionResponses: QuestionResponses;
  updateAnswer: (updateProps: UpdateAnswer) => void;
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps;

class MeasurementPageComponent extends PureComponent<Props> {
  componentDidUpdate(prevProps: Props) {
    const {
      roofTotalArea, // roofTotalArea is computed based on questionResponses
      roofTotalQuestion,
      updateAnswer,
    } = this.props;

    // when component updates check to see if a new computed value exists for roofTotalArea
    // if yes, then update that input in redux
    if (prevProps.roofTotalArea !== roofTotalArea) {
      // typescript guard
      if (roofTotalQuestion) {
        updateAnswer({
          questionId: roofTotalQuestion?.id,
          answer: roofTotalArea,
        });
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-explicit-any
  renderEditableInput({ question, key }: { question: Input; key: any }) {
    const { updateAnswer } = this.props;

    return (
      <Question
        question={question}
        hideText
        key={key}
        updateAnswer={updateAnswer}
      />
    );
  }

  renderQuestionOrStop(question: Input) {
    const { pristineMeasurements } = this.props;

    const { isEditable } = question;
    if (!isEditable && pristineMeasurements.length === 0) {
      return null;
    }

    return this.renderQuestion(question);
  }

  renderQuestionOrStopMultiTrades(question: Input) {
    const { isEditable } = question;
    if (!isEditable) {
      return null;
    }

    return this.renderQuestion(question);
  }

  renderQuestion(question: Input) {
    const { questionResponses, pristineMeasurements } = this.props;
    const { id, name, isEditable, measurementUnits } = question;

    const response = questionResponses[id];
    const pristineMeasurement = pristineMeasurements.find(
      (pristineMeasure) => pristineMeasure.questionId === id,
    )?.answer;

    const measurement = isEditable
      ? parseFloat(response?.toString() ?? '0')
      : parseFloat(pristineMeasurement?.toString() ?? '0');

    const key = `${question.id}:${measurement}`;

    const shouldRender = isEditable || measurement;
    if (!shouldRender) return null;

    const measurementValue = (
      <Box alignItems="center">
        {isEditable ? (
          this.renderEditableInput({ question, key })
        ) : (
          <Box testId="measurement-input-value" fontWeight="bold">
            <FormattedNumber value={measurement} format="0.[00]" />
          </Box>
        )}
        <Box
          fontWeight={900}
          marginLeft="13px"
          width="36px"
          textAlign="left"
          marginBottom={400}
          fontSize="0.625rem"
        >
          {measurementUnits ? UNITS_MAP[measurementUnits] : null}
        </Box>
      </Box>
    );
    return (
      <Box
        justifyContent="space-between"
        alignItems="center"
        height="56px"
        key={name}
        _notFirst={{
          borderTopWidth: '1px',
          borderTopStyle: 'solid',
          borderTopColor: 'neutral.300',
        }}
      >
        <Box textColor="neutral.600" testId="measurement-name">
          {name}
        </Box>
        {measurementValue}
      </Box>
    );
  }

  renderMultiTradeMeasurements() {
    const { questions } = this.props;

    if (!questions) return null;
    const organizedQuestions = generateMeasurementQuestions(questions);

    return Object.entries(organizedQuestions).map(([trade, tradeQuestions]) => {
      const tradeNeedsEditing = tradeQuestions?.some(
        (question) => question.isEditable,
      );
      if (!tradeNeedsEditing) return null;
      return (
        <div key={trade}>
          <Box
            padding="5px"
            margin=" 20px 0 10px 0"
            textTransform="capitalize"
            fontWeight="bold"
          >
            {trade}
          </Box>
          {tradeQuestions &&
            tradeQuestions.map((question) =>
              this.renderQuestionOrStopMultiTrades(question),
            )}
        </div>
      );
    });
  }

  render() {
    return (
      <EstimatorResponsiveWrapper data-testid="manual-measurement-page">
        <Box paddingTop={600} width="100%" flexDirection="column">
          <Heading>Measurements</Heading>
          <Box flexDirection="column">
            {this.renderMultiTradeMeasurements()}
          </Box>
        </Box>
        <EstimatorWarning text="Enter these measurements to estimate for these trade(s)." />
      </EstimatorResponsiveWrapper>
    );
  }
}

export const MeasurementPage = connect(mapStateToProps)(
  MeasurementPageComponent,
);
