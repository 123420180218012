import { createRef, PureComponent } from 'react';

import { Box, LoadingOverlay, Body } from '@hover/blueprint';
import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';

import { TradeTypeEnum } from 'src/api/graphql-global-types';
import { withTypewriter } from 'src/components/WithTypewriter';
import { Heading } from 'src/features/exteriorEstimator/components/common/EstimatorHeading';
import { EstimatorResponsiveWrapper } from 'src/features/exteriorEstimator/components/common/EstimatorResponsiveWrapper';
import { AdjustSidingMeasurement } from 'src/features/exteriorEstimator/components/EstimationTool/CustomQuestionPages/AdjustSidingMeasurement/AdjustSidingMeasurement';
import { CustomLineItemsPage } from 'src/features/exteriorEstimator/components/EstimationTool/CustomQuestionPages/CustomLineItemsPage/CustomLineItemsPage';
import { MeasurementPage } from 'src/features/exteriorEstimator/components/EstimationTool/CustomQuestionPages/MeasurementPage';
import { SelectMaterialListTemplates } from 'src/features/exteriorEstimator/components/EstimationTool/CustomQuestionPages/SelectTemplates/SelectMaterialListTemplates';
import { SelectTemplates } from 'src/features/exteriorEstimator/components/EstimationTool/CustomQuestionPages/SelectTemplates/SelectTemplates';
import { WasteFactor } from 'src/features/exteriorEstimator/components/EstimationTool/CustomQuestionPages/WasteFactorPage/WasteFactor';
import { TopBar } from 'src/features/exteriorEstimator/components/EstimationTool/MultiTrades/TopBar/TopBar';
import { Question } from 'src/features/exteriorEstimator/components/EstimationTool/QuestionView/Question/Question';
import {
  MEASUREMENT,
  CUSTOM_LINE_ITEMS,
  WASTE_FACTOR,
  SELECT_TEMPLATES,
  ADJUST_SIDING_MEASUREMENT,
  ROOF_FACET_SELECTION_3D,
  SIDING_FACET_SELECTION_3D,
  STONE_FACET_SELECTION_3D,
} from 'src/features/exteriorEstimator/constants/questionCategories';
import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import {
  getQuestionsForCategory,
  getCategories,
  getPages,
  getParams,
  nextRenderablePage as getNextRenderablePage,
  prevRenderablePage as getPrevRenderablePage,
  getCurrentQuestionCategory,
  getJob,
  getPristineMeasurements,
  getMeasurementQuestions,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { FacetPageTradeTypes } from 'src/features/exteriorEstimator/types';
import { NAVIGATION_DIRECTION } from 'src/features/exteriorEstimator/types/Navigation';
import { QuestionResponses } from 'src/features/exteriorEstimator/types/Questions';
import { getEstimatorQuestionsUrl } from 'src/features/exteriorEstimator/utils/miscUtils';
import {
  getUserTrackingProps,
  getMaterialListFeature,
} from 'src/redux/selectors';
import { RootState, RootAction } from 'src/types/reduxStore';

import { FacetSelection } from '../CustomQuestionPages/FacetSelection/FacetSelection';
import { QuestionViewFooter } from './QuestionViewFooter';

interface State {
  isInitializing: boolean;
}

export interface UpdateLineSegmentProps {
  facetLabel?: string;
  answer?: boolean;
  answers?: QuestionResponses;
  type?: 'SIDING' | 'ROOF' | 'STONE';
}

export const mapStateToProps = (state: RootState) => ({
  jobDetails: state.exteriorEstimator.job.jobDetails,
  questions: getQuestionsForCategory(state),
  questionResponses: state.exteriorEstimator.inputs.questionResponses,
  category: state.exteriorEstimator.inputs.currentQuestionCategory,
  categories: getCategories(state),
  jobId: getParams(state).jobId,
  job: getJob(state),
  pages: getPages(state),
  nextRenderablePage: getNextRenderablePage(state),
  prevRenderablePage: getPrevRenderablePage(state),
  currentQuestionCategory: getCurrentQuestionCategory(state),
  pristineMeasurements: getPristineMeasurements(state),
  measurementQuestions: getMeasurementQuestions(state),
  commonProps: getUserTrackingProps(state),
  materialListFeatureEnabled: getMaterialListFeature(state),
});

export const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      pushRoute: push,
      setCurrentQuestionCategory: estimatorActions.setCurrentQuestionCategory,
      updateAnswer: estimatorActions.updateAnswer,
      updateAnswers: estimatorActions.updateAnswers,
    },
    dispatch,
  );

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{ category: 'category' }> & {
    typewriter: any;
    isFetchingQuestions: boolean;
  };

export class QuestionViewComponent extends PureComponent<Props, State> {
  containerRef = createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
    this.state = { isInitializing: false };
  }

  componentDidMount() {
    const {
      match: {
        params: { category },
      },
    } = this.props;

    this.updateCurrentCategory(category);
  }

  // We want to make sure that when the question category changes from the url,
  // we want to actually change the questions
  componentDidUpdate(prevProps: Props, prevState: State) {
    const {
      match: {
        params: { category },
      },
      categories,
    } = this.props;
    const { isInitializing } = this.state;
    const {
      match: {
        params: { category: prevCategory },
      },
      categories: oldCategories,
    } = prevProps;

    if (category !== prevCategory) {
      this.updateCurrentCategory(category);
      this.containerRef.current?.scrollTo?.(0, 0);
    }
    if (categories.length > 0 && oldCategories.length === 0) {
      this.updateCurrentCategory(category);
    }
    if (prevState.isInitializing && !isInitializing) {
      this.navigateCategories(NAVIGATION_DIRECTION.FORWARD);
    }
  }

  @autobind
  setIsInitializing(newState: boolean) {
    this.setState({ isInitializing: newState });
  }

  // If the category is unlisted, take them to the first category
  @autobind
  updateCurrentCategory(category: string) {
    const {
      setCurrentQuestionCategory,
      categories,
      jobDetails,
      commonProps,
      typewriter,
    } = this.props;
    let standardizedCategory = category.toUpperCase();

    if (standardizedCategory === 'MEASUREMENT_VALUES')
      standardizedCategory = MEASUREMENT;

    if (categories.length > 0 && !categories.includes(standardizedCategory)) {
      this.navigateCategories(NAVIGATION_DIRECTION.FORWARD, 0);
      return;
    }

    setCurrentQuestionCategory(standardizedCategory);

    typewriter.pageViewed({
      page_or_screen_name: standardizedCategory,
      job_id: Number(jobDetails?.id),
      ...commonProps,
    });
  }

  @autobind
  renderStandardQuestionsView() {
    const {
      questions,
      category = '',
      updateAnswer,
      currentQuestionCategory,
      pages,
    } = this.props;
    const currentPage = pages.find(
      (page) => page.category === currentQuestionCategory,
    );
    const categoryParsed = category?.toLowerCase().replace(/_/g, ' ');
    const categoryDescription = currentPage?.description;

    return (
      <EstimatorResponsiveWrapper>
        <Heading data-testid={`${categoryParsed}-page-header`}>
          {categoryParsed}
        </Heading>
        {categoryDescription && (
          <Body color="neutralTextLight">{categoryDescription}</Body>
        )}
        <Box width={1} padding={100} flexDirection="column">
          {questions &&
            questions.map((question) => (
              <Question
                key={question.id}
                question={question}
                updateAnswer={updateAnswer}
              />
            ))}
        </Box>
      </EstimatorResponsiveWrapper>
    );
  }

  @autobind
  navigateCategories(
    direction: NAVIGATION_DIRECTION,
    forcedTargetCategoryIndex: number | null = null,
  ) {
    const { jobId, pages, prevRenderablePage, nextRenderablePage, pushRoute } =
      this.props;
    if (!pages.length || !jobId) return;
    let targetCategoryIndex;

    if (forcedTargetCategoryIndex === null) {
      targetCategoryIndex =
        direction === NAVIGATION_DIRECTION.FORWARD
          ? nextRenderablePage
          : prevRenderablePage;
    } else {
      targetCategoryIndex = forcedTargetCategoryIndex;
    }

    const targetCategory = pages[targetCategoryIndex]
      ? pages[targetCategoryIndex].category
      : pages[0].category;
    const questionUrl = getEstimatorQuestionsUrl({
      jobId: Number(jobId),
      category: targetCategory,
    });

    pushRoute(questionUrl);
  }

  @autobind
  renderQuestions() {
    const {
      currentQuestionCategory,
      questions,
      materialListFeatureEnabled,
      updateAnswer,
      updateAnswers,
    } = this.props;

    switch (currentQuestionCategory) {
      case MEASUREMENT:
        return <MeasurementPage updateAnswer={updateAnswer} />;
      case SELECT_TEMPLATES:
        return materialListFeatureEnabled ? (
          <SelectMaterialListTemplates />
        ) : (
          <SelectTemplates />
        );
      case ROOF_FACET_SELECTION_3D:
      case SIDING_FACET_SELECTION_3D:
      case STONE_FACET_SELECTION_3D:
        return (
          <FacetSelection
            trade={
              {
                [ROOF_FACET_SELECTION_3D]: TradeTypeEnum.ROOF,
                [SIDING_FACET_SELECTION_3D]: TradeTypeEnum.SIDING,
                [STONE_FACET_SELECTION_3D]: TradeTypeEnum.STONE,
              }[
                currentQuestionCategory as
                  | typeof ROOF_FACET_SELECTION_3D
                  | typeof SIDING_FACET_SELECTION_3D
                  | typeof STONE_FACET_SELECTION_3D
              ] as FacetPageTradeTypes
            }
            updateAnswer={updateAnswer}
            updateAnswers={updateAnswers}
          />
        );
      case CUSTOM_LINE_ITEMS:
        return <CustomLineItemsPage />;
      case WASTE_FACTOR:
        return <WasteFactor updateAnswer={updateAnswer} />;
      case ADJUST_SIDING_MEASUREMENT:
        return (
          <AdjustSidingMeasurement
            questions={questions}
            updateAnswer={updateAnswer}
          />
        );
      default:
        return this.renderStandardQuestionsView();
    }
  }

  render() {
    const {
      isFetchingQuestions,
      jobDetails,
      category,
      questions,
      nextRenderablePage,
      updateAnswer,
    } = this.props;
    const { isInitializing } = this.state;

    const questionsNotReady =
      questions &&
      questions.length === 0 &&
      category !== SELECT_TEMPLATES &&
      category !== CUSTOM_LINE_ITEMS;

    if (
      !jobDetails ||
      isFetchingQuestions ||
      isInitializing ||
      questionsNotReady
    ) {
      return <LoadingOverlay isLoading />;
    }

    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        opacity={1}
        transition="300ms"
        flex={1}
        maxHeight={1}
        key={category}
      >
        {/* key prop is necessary to force a redraw when the route changes. this forces new pages to scroll to the top when they are loaded */}
        <TopBar />
        <Box
          width={1}
          flex={1}
          overflowY="auto"
          overflowX="hidden"
          ref={this.containerRef}
        >
          <Box height={1} overflowX="hidden" flex={1} margin="auto">
            {this.renderQuestions()}
          </Box>
        </Box>
        <QuestionViewFooter
          navigateCategories={this.navigateCategories}
          setIsInitializing={this.setIsInitializing}
          updateAnswer={updateAnswer}
          hasNextPage={nextRenderablePage !== -1}
        />
      </Box>
    );
  }
}

export const QuestionView = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTypewriter(QuestionViewComponent));
