import { PureComponent } from 'react';

import { Label, Textarea } from '@hover/blueprint';
import autobind from 'autobind-decorator';
import { upperFirst } from 'lodash';
import { renderToStaticMarkup } from 'react-dom/server';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { LineItemTypeEnum } from 'src/api/graphql-global-types';
import { LoaderSpinner } from 'src/components/LoaderSpinner';
import { withTypewriter } from 'src/components/WithTypewriter';
import { LineItemTypeToLowercase } from 'src/features/projectManagement/constants';
import * as actions from 'src/features/projectManagement/redux/actions';
import {
  getUserProfile,
  getJobDetails,
  getProductionListTradeTypes,
  getItemsSortedByCategoriesAndVendors,
  getVendorsPerCategoryAlphabetized,
} from 'src/features/projectManagement/redux/selectors/estimatorProductionSelectors';
import { stringifyProductionListTradeTypesForPDF } from 'src/features/projectManagement/utils/MultiTradesUtils';
import {
  getJob,
  getProductionContact,
  getBilling,
} from 'src/features/projectManagement/utils/PdfUtils';
import { getTradeTypesSorted, getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { RootAction, RootState } from 'src/types/reduxStore';
import { jobProps } from 'src/utils/trackingUtils';

import { DumbInput } from '../Common/DumbInput';
import { PdfFooter } from '../PdfOutput/PdfFooter';
import { PdfOutput } from '../PdfOutput/PdfOutput';
import { DownloadPdfButton } from './DownloadPdfButton';
import {
  Modal,
  contentStyle,
  footerStyle,
  Content,
  ContentBox,
  ContentLeft,
  LabelWrapper,
  OptionalWrapper,
  Checkbox,
  CheckboxLabelText,
  ErrorMessage,
} from './styled';

export const mapStateToProps = (state: RootState) => ({
  shouldShowPdfModal: state.estimatorProductionTools.shouldShowPdfModal,
  pdf: state.estimatorProductionTools.pdf,
  userProfile: getUserProfile(state),
  jobDetails: getJobDetails(state),
  itemsByCategoryAndVendor: getItemsSortedByCategoriesAndVendors(state),
  vendorsPerCategoryAlphabetized: getVendorsPerCategoryAlphabetized(state),
  productionList: state.estimatorProductionTools.productionList,
  tradeFilter: state.estimatorProductionTools.tradeFilter,
  productionListTradeTypes: getProductionListTradeTypes(state),
  tradeTypes: getTradeTypesSorted(state),
  commonProps: getUserTrackingProps(state),
});

export const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      toggle: actions.togglePdfModal,
      createPdf: actions.createPdf.request,
      customizePdfOutput: actions.customizePdfOutput,
    },
    dispatch,
  );

interface OwnProps {
  refetch: () => void;
  didUserNavigateToNewFromExisting: boolean;
  switchStateToExisting: () => void;
  typewriter: any;
}

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  OwnProps;

type PdfTitle = 'Modal' | 'PDF';

export class PDFModal extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      purchaseOrderNumber: '',
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { shouldShowPdfModal, commonProps, jobDetails, typewriter } =
      this.props;
    if (!prevProps.shouldShowPdfModal && shouldShowPdfModal) {
      // if true, modal appeared

      typewriter.pageViewed({
        page_or_screen_name: EventNames.pmp.pdf.downloadNew,
        ...jobProps(jobDetails),
        ...commonProps,
      });
    }
  }

  @autobind
  handleCancelClick() {
    const { toggle, switchStateToExisting } = this.props;
    this.setState({ purchaseOrderNumber: '' });
    toggle({ show: false });
    switchStateToExisting();
  }

  getListItems() {
    const {
      itemsByCategoryAndVendor: { materialItems, laborItems, otherItems },
      pdf: {
        type,
        vendor: { vendorName },
      },
    } = this.props;

    switch (type) {
      case LineItemTypeEnum.MATERIAL:
        return { [vendorName]: materialItems[vendorName] };
      case LineItemTypeEnum.LABOR:
        return { [vendorName]: laborItems[vendorName] };
      case LineItemTypeEnum.OTHER:
        return { [vendorName]: otherItems[vendorName] };
      default:
        return { [vendorName]: otherItems[vendorName] };
    }
  }

  @autobind
  handleActionClick(orderId?: string) {
    const {
      refetch,
      createPdf,
      userProfile,
      jobDetails,
      productionList: { id: productionListId },
      tradeFilter,
      productionListTradeTypes,
      didUserNavigateToNewFromExisting,
      tradeTypes,
      commonProps,
      typewriter,
      pdf = { vendor: {} },
    } = this.props;
    const eventName = didUserNavigateToNewFromExisting
      ? EventNames.pmp.pdf.historicalExportsDownloadNewPDFDownloadPDFPressed
      : EventNames.pmp.pdf.downloadPdfPressed;

    const job = getJob(jobDetails);
    const productionContact = getProductionContact(userProfile);
    const billing = getBilling(userProfile);
    const listItems = this.getListItems();
    const typeListItems = listItems[pdf.vendor.vendorName].listItems;

    const tradeFilterList = stringifyProductionListTradeTypesForPDF(
      productionListTradeTypes,
      tradeFilter,
    );
    const { purchaseOrderNumber } = this.state;
    const output = renderToStaticMarkup(
      <PdfOutput
        title={this.getTitle('PDF')}
        purchaseOrderNumber={purchaseOrderNumber}
        job={job}
        productionContact={productionContact}
        billing={billing}
        vendorName={pdf?.vendor?.vendorName}
        listItems={listItems}
        pdf={pdf}
        tradeFilter={tradeFilter}
        tradeFilterList={tradeFilterList}
        tradeTypes={tradeTypes}
      />,
    );
    const footer = renderToStaticMarkup(
      <PdfFooter
        jobId={job.id}
        productionContactTime={productionContact.time}
      />,
    );

    const { purchaseOrderNumber: externalIdentifier } = this.state;
    typewriter.buttonPressed({
      button_text: 'Create PDF',
      page_or_screen_name: 'Project Management Create PDF Modal',
      primary_cta: false,
      ...jobProps(jobDetails),
      ...commonProps,
    });
    createPdf({
      eventName,
      externalIdentifier,
      orderDocumentAttributes: {
        inputHtml: output,
        footerHtml: footer,
        notes: pdf.notes,
        productionListId,
        vendorId: pdf?.vendor?.id,
        orderId,
      },
      listItemIds: typeListItems
        ? typeListItems.map((listItem) => listItem.id.toString())
        : [],
      refetch,
    });
  }

  @autobind
  handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ purchaseOrderNumber: e.target.value });
  }

  @autobind
  handleBlur(event: React.ChangeEvent<HTMLInputElement>) {
    const { jobDetails, commonProps, typewriter } = this.props;

    typewriter.textInput({
      input_value: event.target.value,
      input_label: 'Purchase Order Number',
      ...commonProps,
      ...jobProps(jobDetails),
    });
  }

  @autobind
  getTitle(mode: PdfTitle) {
    const { pdf } = this.props;
    const title =
      mode === 'Modal' ? 'Summary Export Preferences' : 'list order';

    let fullTitle;
    if (pdf.type === LineItemTypeEnum.OTHER) {
      fullTitle = 'Other';
    } else {
      fullTitle = upperFirst(`${LineItemTypeToLowercase[pdf.type]} ${title}`);
    }

    if (pdf.vendor) {
      fullTitle += ` - ${pdf.vendor.vendorName}`;
    }
    return fullTitle;
  }

  @autobind
  handleNotesChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { customizePdfOutput } = this.props;
    customizePdfOutput({ notes: e.target.value });
  }

  @autobind
  handleCheckCostDetails() {
    const {
      pdf: { shouldShowCostDetails },
      customizePdfOutput,
      commonProps,
      jobDetails,
      didUserNavigateToNewFromExisting,
      typewriter,
    } = this.props;
    customizePdfOutput({
      shouldShowCostDetails: !shouldShowCostDetails,
    });
    const eventName = didUserNavigateToNewFromExisting
      ? EventNames.pmp.pdf.historicalExportsDownloadNewShowItemCostSelected
      : EventNames.pmp.pdf.showItemCostSelected;

    typewriter.checkboxSelected({
      selection: eventName,
      options: 'Show item cost details',
      ...jobProps(jobDetails),
      ...commonProps,
    });
  }

  @autobind
  handleCheckTotalCost() {
    const {
      pdf: { shouldShowTotalCost },
      customizePdfOutput,
      commonProps,
      jobDetails,
      didUserNavigateToNewFromExisting,
      typewriter,
    } = this.props;
    customizePdfOutput({
      shouldShowTotalCost: !shouldShowTotalCost,
    });
    const eventName = didUserNavigateToNewFromExisting
      ? EventNames.pmp.pdf.historicalExportsDownloadNewShowTotalCostSelected
      : EventNames.pmp.pdf.showTotalCostSelected;

    typewriter.checkboxSelected({
      selection: eventName,
      options: 'Show total cost',
      ...jobProps(jobDetails),
      ...commonProps,
    });
  }

  @autobind
  handleCheckItemMeasurements() {
    const {
      pdf: { shouldShowItemMeasurements },
      customizePdfOutput,
      commonProps,
      jobDetails,
      didUserNavigateToNewFromExisting,
      typewriter,
    } = this.props;
    customizePdfOutput({
      shouldShowItemMeasurements: !shouldShowItemMeasurements,
    });
    const eventName = didUserNavigateToNewFromExisting
      ? EventNames.pmp.pdf
          .historicalExportsDownloadNewShowItemMeasurementsSelected
      : EventNames.pmp.pdf.showItemMeasurementsSelected;

    typewriter.checkboxSelected({
      selection: eventName,
      options: 'Show total cost',
      ...jobProps(jobDetails),
      ...commonProps,
    });
  }

  render() {
    const {
      shouldShowPdfModal,
      pdf: {
        shouldShowCostDetails,
        shouldShowItemMeasurements,
        shouldShowTotalCost,
        notes,
        isFetching,
        error,
        vendor,
      },
    } = this.props;
    const { purchaseOrderNumber } = this.state;
    return (
      <>
        {isFetching && <LoaderSpinner show />}
        <Modal
          isFetching={isFetching}
          data-testid="generatePdfModal-downloadNew"
          isOpen={shouldShowPdfModal}
          contentStyle={contentStyle}
          textAlign="left"
          title={this.getTitle('Modal')}
          lineHeight="23px"
          justifyFooter="flex-end"
          padding="20px 32px 20px"
          footerStyle={footerStyle}
          closeButton={this.handleCancelClick}
          footerContent={
            error ? (
              <ErrorMessage data-testid="downloadPdfErrorMessage">
                We&quot;re unable to generate your Order Summary at the moment.
                Please try again.
              </ErrorMessage>
            ) : null
          }
          action={
            <DownloadPdfButton
              vendor={vendor}
              handleCreatePdf={this.handleActionClick}
            />
          }
        >
          <Content flexDirection="column">
            <ContentBox width={1}>
              <ContentLeft>
                <LabelWrapper>Purchase Order No.</LabelWrapper>
              </ContentLeft>
              <ContentBox>
                <DumbInput
                  name="purchaseOrderNumber"
                  value={purchaseOrderNumber || ''}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  data-testid="pdfModal-purchaseOrderNumber"
                  margin="1em 0 1em 22px"
                />
                <LabelWrapper>
                  <OptionalWrapper>Optional</OptionalWrapper>
                </LabelWrapper>
              </ContentBox>
            </ContentBox>
            <ContentBox width={1}>
              <ContentLeft>
                <LabelWrapper>Notes</LabelWrapper>
              </ContentLeft>
              <ContentBox>
                <Textarea
                  name="notes"
                  value={notes || ''}
                  onChange={this.handleNotesChange}
                  display="block"
                  data-testid="pdfModal-notes"
                  sx={{ margin: '1em 0 1em 22px' }}
                />
                <LabelWrapper>
                  <OptionalWrapper>Optional</OptionalWrapper>
                </LabelWrapper>
              </ContentBox>
            </ContentBox>
            <Label
              box
              flexDirection="row"
              paddingLeft="calc(40% + 22px)"
              alignItems="center"
            >
              <Checkbox
                id="costDetails"
                name="costDetails"
                onChange={this.handleCheckCostDetails}
                checked={shouldShowCostDetails}
                data-testid="costDetailsCheckbox"
              />
              <CheckboxLabelText>Show item cost details</CheckboxLabelText>
            </Label>
            <Label
              box
              flexDirection="row"
              paddingLeft="calc(40% + 22px)"
              alignItems="center"
            >
              <Checkbox
                id="itemMeasurements"
                name="itemMeasurements"
                onChange={this.handleCheckItemMeasurements}
                checked={shouldShowItemMeasurements}
                data-testid="totalCostCheckbox"
              />
              <CheckboxLabelText>Show item measurements</CheckboxLabelText>
            </Label>
            <Label
              box
              flexDirection="row"
              paddingLeft="calc(40% + 22px)"
              alignItems="center"
            >
              <Checkbox
                id="totalCost"
                name="totalCost"
                onChange={this.handleCheckTotalCost}
                checked={shouldShowTotalCost}
                data-testid="totalCostCheckbox"
              />
              <CheckboxLabelText>Show total cost</CheckboxLabelText>
            </Label>
          </Content>
        </Modal>
      </>
    );
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTypewriter(PDFModal));
