import { PureComponent } from 'react';

import { Moment } from 'moment-timezone';

import {
  footerStyle,
  footerWrapperStyle,
} from 'src/features/projectManagement/styles/OrderPdfStyles';

export interface PdfFooterParams {
  jobId?: number;
  productionContactTime: Moment;
}

export class PdfFooter extends PureComponent<PdfFooterParams> {
  render() {
    const { jobId, productionContactTime } = this.props;
    return (
      <div data-testid="footer" style={footerWrapperStyle}>
        <div style={footerStyle}>
          <span>
            Powered by HOVER | Property ID: {jobId} | Generated{' '}
            {productionContactTime?.format('MM/DD/YYYY-hh:mm A')}
          </span>
        </div>
      </div>
    );
  }
}
