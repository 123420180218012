import { PureComponent } from 'react';

import * as Sentry from '@sentry/react';
import autobind from 'autobind-decorator';
import { find } from 'lodash';
import numeral from 'numeral';
import { connect } from 'react-redux';

import {
  TradeTypeEnum,
  EstimationConfigInputMeasurementUnitEnum as MeasurementUnitEnum,
} from 'src/api/graphql-global-types';
import { tradeTypes_tradeTypes as TradeType } from 'src/api/types/tradeTypes';
import { withTypewriter } from 'src/components/WithTypewriter';
import { UpdateAnswer } from 'src/features/exteriorEstimator/redux/actions/answerActions';
import { predictedWasteFactorRoofPercent } from 'src/features/exteriorEstimator/redux/sagas/selectors';
import {
  JobMeasurements,
  Input,
  QuestionResponses,
} from 'src/features/exteriorEstimator/types';
import PartialSidingUtils from 'src/features/exteriorEstimator/utils/PartialSidingUtils';
import {
  getInputTradeType,
  getTradeTypeEnumsOfRoofCategory,
  isRoofTradeType,
} from 'src/features/exteriorEstimator/utils/questionsUtils';
import { WasteFactorCalculator } from 'src/features/exteriorEstimator/utils/WasteFactorCalculator';
import {
  getOrgSettings,
  getTradeTypesSorted,
  getUserTrackingProps,
} from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { RootState } from 'src/types/reduxStore';
import { jobProps } from 'src/utils/trackingUtils';
import { UNITS_MAP } from 'src/utils/unitsMap';

import { SliderWithPercentage } from './SliderWithPercentage/SliderWithPercentage';

export const mapStateToProps = (state: RootState) => ({
  jobDetails: state.exteriorEstimator.job.jobDetails,
  predictedWasteFactorRoof: predictedWasteFactorRoofPercent(state),
  tradeTypes: getTradeTypesSorted(state),
  commonProps: getUserTrackingProps(state),
  orgSettings: getOrgSettings(state),
});

export interface InputWithValue extends Input {
  value?: number;
}

interface State {
  shouldDisableSlider: boolean;
}

type Props = {
  typewriter: any;
  question: Input;
  jobMeasurements: JobMeasurements;
  measurementQuestionsAndResponses: InputWithValue[];
  questionResponses: QuestionResponses;
  openWasteFactorCalcModal: () => void;
  updateAnswer: (updateProps: UpdateAnswer) => void;
} & ReturnType<typeof mapStateToProps>;

type TradeToLabelType = {
  [key in TradeTypeEnum]?: string;
};

class WasteFactorSliderComponent extends PureComponent<Props, State> {
  Calculator: WasteFactorCalculator;

  tradeTypes: TradeType[];

  TRADE_TO_LABEL: TradeToLabelType;

  measurements: JobMeasurements;

  measurementQuestionsAndResponses: InputWithValue[];

  constructor(props: Props) {
    super(props);
    this.state = { shouldDisableSlider: false };

    this.tradeTypes = props.tradeTypes;
    this.measurements = props.jobMeasurements;
    this.measurementQuestionsAndResponses =
      props.measurementQuestionsAndResponses;
    this.Calculator = new WasteFactorCalculator(
      this.measurements,
      this.measurementQuestionsAndResponses,
      this.tradeTypes,
      props.orgSettings,
    );

    this.TRADE_TO_LABEL = {
      [TradeTypeEnum.SIDING]: PartialSidingUtils.shouldCalculateWithOpenings(
        props.orgSettings,
      )
        ? 'Apply waste factor for total siding area including openings less than 33 SQFT'
        : 'Apply waste factor for total siding area excluding openings',
      [TradeTypeEnum.GUTTERS]: this.guttersLabel(),
      [TradeTypeEnum.DOOR]: undefined,
      [TradeTypeEnum.FASCIA]: 'Apply waste factor for fascia measurements',
      [TradeTypeEnum.SKYLIGHTS]: undefined,
      [TradeTypeEnum.SOFFIT]: 'Apply waste factor for soffit measurements',
      [TradeTypeEnum.STONE]: 'Apply waste factor for stone measurements',
      [TradeTypeEnum.WINDOWS]: undefined,
    };
  }

  @autobind
  setShouldDisableSlider(newState: boolean) {
    this.setState({ shouldDisableSlider: newState });
  }

  private guttersLabel = () => {
    const downspoutLengthTotal =
      find(this.measurementQuestionsAndResponses, {
        argument: 'downspout_length_total',
      })?.value ??
      this.measurements.downspout_length_total ??
      0;
    let label = 'Total eaves';
    if (downspoutLengthTotal > 0) label = label.concat(' and downspouts');
    return label;
  };

  isRoofTradeType(tradeType: TradeTypeEnum) {
    return getTradeTypeEnumsOfRoofCategory(this.tradeTypes).includes(tradeType);
  }

  getFormattedNewTotal(value: number, total: number, tradeType: TradeTypeEnum) {
    const format = this.isRoofTradeType(tradeType) ? '0,0.00' : '0,0';

    return numeral(total + total * (value / 100)).format(format);
  }

  getTradeLabel(tradeType: TradeTypeEnum) {
    if (isRoofTradeType(tradeType, this.tradeTypes)) {
      return 'Apply waste factor for total roofing area';
    }

    return this.TRADE_TO_LABEL[tradeType] || '';
  }

  /**
   *
   * @returns 'LINEAR_FEET' | 'PIECES' | 'SQUARES' | etc...
   */
  getMeasurementUnits(question: Props['question']) {
    const unit = question.measurementUnits;
    const tradeType = getInputTradeType(question);
    if (!unit || !tradeType) return '';

    if (this.isRoofTradeType(tradeType)) {
      return MeasurementUnitEnum.SQUARES;
    }

    return unit;
  }

  /**
   *
   * @returns 'LF' | 'SQFT' |'SQ' | etc...
   */
  getFormattedMeasurementUnits(question: Props['question']) {
    const unit = (
      UNITS_MAP[this.getMeasurementUnits(question)] ?? ''
    ).toLocaleUpperCase();
    if (!unit) {
      Sentry.captureMessage(
        `measurementUnit not configured for waste factor input id: ${question.id}`,
      );
    }
    return unit;
  }

  trackAndUpdateAnswer(
    predictedWasteFactorAnswer: number,
    useRecommended = false,
  ) {
    const { question, updateAnswer, commonProps, jobDetails, typewriter } =
      this.props;
    updateAnswer({
      questionId: question.id,
      answer: predictedWasteFactorAnswer,
    });

    typewriter.textInput({
      input_value: predictedWasteFactorAnswer.toString(),
      input_label: `${getInputTradeType(question)} Waste Factor`,
      page_or_screen_name: EventNames.estimator.wasteFactor.page,
      ...this.wasteFactorTrackingProps(useRecommended),
      ...commonProps,
      ...jobProps(jobDetails),
    });
  }

  wasteFactorTrackingProps(useRecommended: boolean) {
    const { predictedWasteFactorRoof } = this.props;
    return {
      recommended_value: predictedWasteFactorRoof,
      recommended_value_selected: useRecommended,
    };
  }

  onToggleChange(predictedWasteFactorAnswer: number) {
    const { shouldDisableSlider } = this.state;
    // updateAnswer to predicted waste value
    this.trackAndUpdateAnswer(predictedWasteFactorAnswer, true);
    // toggle disabled state
    this.setShouldDisableSlider(!shouldDisableSlider);
  }

  render() {
    const { question, questionResponses, openWasteFactorCalcModal } =
      this.props;

    const { shouldDisableSlider } = this.state;

    const tradeType = getInputTradeType(question);
    const tradeTypeCategory = question.tradeTypeCategories?.[0];

    if (!tradeType || !tradeTypeCategory) return null;
    const calc = this.Calculator;
    const total = calc.getTradeTotalAmount(tradeType, tradeTypeCategory);
    const label = this.getTradeLabel(tradeType);
    const value = Number(questionResponses[question.id]) ?? 0;

    return (
      <SliderWithPercentage
        tradeType={tradeType}
        heading={question.question ?? ''}
        initialValue={value}
        value={value}
        onChange={(_value) => {
          this.trackAndUpdateAnswer(_value);
        }}
        max={50}
        key={question.id}
        measurementUnits={this.getFormattedMeasurementUnits(question)}
        wasteFactorCalcResult={this.getFormattedNewTotal(
          value,
          total,
          tradeType,
        )}
        label={label}
        onToggleChange={(predictedNum) => this.onToggleChange(predictedNum)}
        openWasteFactorCalcModal={openWasteFactorCalcModal}
        shouldDisableWasteFactorSliderRoof={shouldDisableSlider}
      />
    );
  }
}

export const WasteFactorSlider = connect(mapStateToProps)(
  withTypewriter(WasteFactorSliderComponent),
);
