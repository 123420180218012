/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect } from 'react';

import { Box, Icon, NextProvider } from '@hover/blueprint';
import { hLoader, iAlertOctagon, iGithub } from '@hover/icons';
import {
  Route,
  RouteProps,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

import { useTracking } from 'src/hooks';
import { COMMERCE_PROJECT_ESTIMATOR, isEnabled } from 'src/lib/FeatureFlag';

export interface RouteConfigRoute {
  path: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any;
  routes?: RouteConfigRoute[];
  exact?: boolean;
}

type Props = RouteProps & { routes: RouteConfigRoute[] };

export const ProjectEstimator: React.FC<Props> = ({ routes }) => {
  const location = useLocation();
  const match = useRouteMatch();

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const contentPaddingInPixels = 48;

  useEffect(() => {
    typewriter.pageViewed({
      page_or_screen_name: location.pathname,
      ...commonTrackingProps,
    });
  }, [commonTrackingProps, typewriter, location.pathname]);
  if (!isEnabled(COMMERCE_PROJECT_ESTIMATOR))
    return (
      <Box
        justifyContent="center"
        width="100%"
        height="100vh"
        alignItems="center"
      >
        <Icon icon={iAlertOctagon} />
        <Icon icon={iGithub} />
        <Icon icon={hLoader} />
      </Box>
    );

  return (
    <NextProvider attach>
      <Box flex-direction="column" width="100%" height="100vh">
        <Box width={1} justifyContent="center" flex={1}>
          <Box
            data-testid="projectEstimatorContainer"
            width={1}
            padding={{ base: 0, desktop: `${contentPaddingInPixels}px` }}
            flexDirection="column"
          >
            <Switch location={location}>
              {routes.map((route) => {
                return (
                  // Provide notification-handling funcs to sub-route components.
                  <Route
                    exact={route.exact}
                    path={`${match.path}${route.path}`}
                    key={route.path}
                    render={(props) => (
                      <route.component
                        {...props}
                        routes={route.routes}
                        padding={contentPaddingInPixels}
                      />
                    )}
                  />
                );
              })}
            </Switch>
          </Box>
        </Box>
      </Box>
    </NextProvider>
  );
};
