import { TradeTypeEnum } from 'src/api/graphql-global-types';
import { configTemplateCollectionsForOrg_estimationConfigTemplateCollectionsForOrg_templateSections_templatesByTradeType_templates as Template } from 'src/api/types/configTemplateCollectionsForOrg';

export const templatesOfTrade = (
  trade: TradeTypeEnum,
  templates: Template[],
) => {
  return templates.filter((template) => template.tradeType === trade);
};

export const selectedTemplatesOfTrade = (
  trade: TradeTypeEnum,
  templates: Template[],
  selectedTemplateIds: number[],
) => {
  const allTemplatesOfTrade = templatesOfTrade(trade, templates);
  return allTemplatesOfTrade.filter((template) =>
    selectedTemplateIds.includes(template.id),
  );
};
