/* eslint-disable camelcase */
import * as Sentry from '@sentry/react';
import {
  call,
  select,
  put,
  all,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import {
  ListItemUpdate as ListItemUpdateParams,
  ListItemCreate as ListItemCreateParams,
} from 'src/api/graphql-global-types';
import {
  projectManagementListItemCreate as projectManagementListItemCreateType,
  projectManagementListItemCreate_projectManagementListItemCreate_listItem as listItemCreate_listItemCreate_listItem,
} from 'src/api/types/projectManagementListItemCreate';
import { projectManagementListItemUpdate as projectManagementListItemUpdateType } from 'src/api/types/projectManagementListItemUpdate';
import { getProducts } from 'src/features/exteriorEstimator/utils/gqlUtils';
import { EstimatorProductionApi } from 'src/features/projectManagement/apis/estimatorProduction';
import { fetchExternalPricingForVariation } from 'src/features/projectManagement/components/ProductionView/AddListItemFormModal/utils';
import {
  getProductsMap,
  getListItemById,
  getExternalProductIds,
  getVendorDistributorIds,
  getOrgIdParam,
} from 'src/features/projectManagement/redux/selectors';
import {
  ProductsMap,
  ListItemType,
  Product,
} from 'src/features/projectManagement/types';
import { mapProductsToListItems } from 'src/features/projectManagement/utils/ProductionListUtils';
import { getVariationsFilter, VariationsFilter } from 'src/redux/selectors';

import * as EstimatorProductionActions from '../actions';

export function* getProductsFor(listItems: ListItemType[]) {
  const productIds: string[] = yield select(getExternalProductIds(listItems));
  if (!productIds.length) return null; // if there are no productIds to fetch, don't fetch anything
  const distributorIds: string[] = yield select(
    getVendorDistributorIds(listItems),
  );

  const variationsFilter: VariationsFilter = yield select(getVariationsFilter);

  const products: Product[] = yield call(getProducts, {
    productIds,
    distributorIds,
    ...variationsFilter,
  });
  return products;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* appendProductInfoToListItem(responseListItem: any) {
  const products: Product[] | null = yield call(getProductsFor, [
    responseListItem,
  ]);
  if (!products) return responseListItem;
  const productsMap: ProductsMap = yield select(getProductsMap(products));

  const listItemsWithProduct = mapProductsToListItems({
    listItems: [responseListItem],
    productsMap,
  });
  return listItemsWithProduct[0];
}

export function* createListItem(action: {
  type: typeof EstimatorProductionActions.CREATE_LIST_ITEM;
  payload: { productionListId: number; params: ListItemCreateParams };
}) {
  const { productionListId, params } = action.payload;

  yield put(EstimatorProductionActions.toggleShouldShowSpinner(true));
  try {
    const {
      data: createListItemData,
    }: { data: projectManagementListItemCreateType } = yield call(
      EstimatorProductionApi.createListItem,
      productionListId,
      params,
    );
    if (!createListItemData.projectManagementListItemCreate) {
      return;
    }

    const responseListItem = createListItemData.projectManagementListItemCreate
      .listItem as listItemCreate_listItemCreate_listItem;
    const responseProductionList =
      responseListItem && responseListItem.productionList;

    if (!responseListItem || !responseProductionList) {
      return;
    }

    // @ts-expect-error Errors on TS 4.9 upgrade
    const listItem = yield call(appendProductInfoToListItem, responseListItem);

    yield put(
      EstimatorProductionActions.toggleShouldShowAddListItemModal(false),
    );
    yield put(EstimatorProductionActions.toggleShouldShowSpinner(false));
    yield put(EstimatorProductionActions.clearAddListItemErrors());
    yield put(
      EstimatorProductionActions.createListItemEnd({
        listItem,
        productionList: responseProductionList,
      }),
    );
  } catch (error) {
    Sentry.captureException(error);
    yield put(EstimatorProductionActions.toggleShouldShowSpinner(false));
    // @ts-expect-error Errors on TS 4.9 upgrade
    yield put(EstimatorProductionActions.receiveAddListItemErrors(error));
    // @ts-expect-error Errors on TS 4.9 upgrade
    yield put(EstimatorProductionActions.sagaError(error.message));
  }
}

export function* updateListItem(action: {
  type: typeof EstimatorProductionActions.UPDATE_LIST_ITEM;
  payload: { listItemId: number; params: ListItemUpdateParams };
}) {
  const { listItemId } = action.payload;

  let { params } = action.payload;
  let sku;
  let unitCost;

  // @ts-expect-error Errors on TS 4.9 upgrade
  const listItem = yield select(getListItemById(listItemId));
  // @ts-expect-error Errors on TS 4.9 upgrade
  const orgId = yield select(getOrgIdParam);

  if (params?.externalVariationId) {
    ({ defaultSkuId: sku, unitPrice: unitCost } = yield call(
      fetchExternalPricingForVariation,
      params.externalVariationId,
      listItem.vendor,
      orgId,
      listItem.quantityUnits,
    ));
  }

  if (sku) params = { ...params, sku };
  if (unitCost) params = { ...params, unitCost };

  try {
    const {
      data: updateListItemData,
    }: { data: projectManagementListItemUpdateType } = yield call(
      EstimatorProductionApi.updateListItem,
      listItemId,
      params,
    );
    if (!updateListItemData.projectManagementListItemUpdate) {
      return;
    }
    const responseListItem =
      updateListItemData.projectManagementListItemUpdate.listItem;
    const responseProductionList = responseListItem?.productionList;
    if (!responseListItem || !responseProductionList) {
      throw new Error(`Error updating list item ${listItemId}`);
    }

    yield put(
      EstimatorProductionActions.updateListItemEnd({
        listItem: responseListItem,
        productionList: responseProductionList,
      }),
    );
  } catch (error) {
    Sentry.captureException(error);
    yield put(EstimatorProductionActions.sagaError((error as Error).message));
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default function* sagas() {
  yield all([
    takeEvery(EstimatorProductionActions.updateListItem, updateListItem),
    takeLatest(EstimatorProductionActions.createListItem, createListItem),
  ]);
}
