import { useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import {
  Body,
  Box,
  Button,
  Heading,
  Icon,
  Image,
  LoadingOverlay,
} from '@hover/blueprint';
import { iDownload } from '@hover/icons';
import moment from 'moment/moment';
import pluralize from 'pluralize';
import { useLocation, useParams } from 'react-router-dom';

import { EstimationConfigInputInputTypeEnum } from 'src/api/graphql-global-types';
import {
  inspectionChecklist_inspectionChecklist_sections_questions as InspectionChecklistQuestion,
  inspectionChecklist_inspectionChecklist_sections_questions_images as InspectionChecklistImage,
} from 'src/api/types/inspectionChecklist';
import type { inspectionChecklists_inspectionChecklists_nodes as InspectionChecklistType } from 'src/api/types/inspectionChecklists';
import {
  ImageLightboxModal,
  LightboxImage,
} from 'src/components/blueprint/Lightbox/ImageLightboxModal';
import { GET_INSPECTION_CHECKLISTS } from 'src/features/exteriorEstimator/apis/queries';
import { GET_JOB } from 'src/features/exteriorEstimator/apis/queries/job';
import { EstimatorResponsiveWrapper } from 'src/features/exteriorEstimator/components/common/EstimatorResponsiveWrapper';
import {
  checklistAnswerFormatter,
  checklistSortOrder,
} from 'src/features/exteriorEstimator/utils/ChecklistAnswerUtils';
import { downloadImages } from 'src/utils/downloadImages';

export const InspectionChecklist = () => {
  const { checklistId } = useParams();
  const {
    query: { orgId, jobId },
  } = useLocation();

  const { data: checklists, loading: checklistsLoading } = useQuery(
    GET_INSPECTION_CHECKLISTS,
    {
      variables: { jobId, orgId },
    },
  );

  const { data: job, loading: jobLoading } = useQuery(GET_JOB, {
    variables: { ids: [Number(jobId)] },
  });

  const [initialModalImageUrl, setInitialModalImageUrl] = useState<string>('');

  const mapImagesToLightboxImages = (
    images: InspectionChecklistImage[],
    lightboxTitle: string,
  ) => {
    return images?.map((image) => {
      return {
        url: image.redirectUrl,
        title: lightboxTitle,
      };
    });
  };

  const [currentImageSet, setCurrentImageSet] = useState<LightboxImage[]>([]);

  const [isChecklistImageModalOpen, setIsChecklistImageModalOpen] =
    useState(false);

  const closeChecklistImageModal = () => setIsChecklistImageModalOpen(false);

  const inspectionChecklists: InspectionChecklistType[] =
    checklists?.inspectionChecklists?.nodes;

  const checklist = inspectionChecklists?.find(
    (list) => list.id === checklistId,
  );

  const address = job?.jobs?.results[0]?.locationLine1;
  const checklistName = `${address ?? ''} Property Checklist`;

  const checklistImageUrls = useMemo(() => {
    const imageUrls: string[] = [];

    if (checklist) {
      checklist.sections.map((section) =>
        section.questions.map((question) =>
          question.images.map((image) => imageUrls.push(image.redirectUrl)),
        ),
      );
    }

    return imageUrls;
  }, [checklist]);

  const handleImageClick = (
    question: InspectionChecklistQuestion,
    imageUrl: string,
  ) => {
    setInitialModalImageUrl(imageUrl);

    const mappedImages: LightboxImage[] = mapImagesToLightboxImages(
      question.images,
      question.questionText,
    );

    setCurrentImageSet(mappedImages);
    setIsChecklistImageModalOpen(true);
  };

  const imagesAvailable = (imageCount: number) =>
    `${imageCount} ${pluralize('image', imageCount)} available`;

  const downloadChecklistImages = () =>
    downloadImages(checklistImageUrls, checklistName);

  return (
    checklist && (
      <Box
        flexDirection="column"
        alignItems="center"
        opacity={1}
        flex={1}
        maxHeight={1}
        data-testid="inspectionChecklistShow"
      >
        <LoadingOverlay isLoading={checklistsLoading || jobLoading} />
        <Box display="block" width={1} height={500} zIndex={1}>
          <Box
            height={500}
            borderBottomWidth="1px"
            borderBottomColor="neutral400"
            alignItems="center"
            justifyContent="center"
            boxShadow="distance200"
          >
            <Body>{checklistName}</Body>
          </Box>
        </Box>
        <EstimatorResponsiveWrapper>
          <Box justifyContent="space-between" alignItems="center">
            <Body
              size={400}
              color="neutral400"
              data-testid="inspectionChecklistShow-completedAt"
            >
              Completed {moment(checklist.updatedAt).format('M/D/YYYY, h:mm a')}
            </Body>
            <Button
              label="Download all images"
              onClick={downloadChecklistImages}
              width="fit-content"
            >
              <Icon icon={iDownload} marginRight={200} />
              Download all images
            </Button>
          </Box>

          {[...checklist.sections].sort(checklistSortOrder).map((section) => (
            <Box key={section.id} flexDirection="column">
              <Heading>{section.name}</Heading>
              {[...section.questions]
                .sort(checklistSortOrder)
                .map((question) => (
                  <Box
                    flexDirection="column"
                    borderBottom="1px solid"
                    borderBottomColor="neutral400"
                    key={question?.id}
                  >
                    <Box
                      flexDirection="row"
                      key={question.id}
                      data-testid="inspectionChecklistShow-question"
                      justifyContent="space-between"
                      pt={500}
                      pb={500}
                    >
                      <Body as="span">{question.questionText}</Body>
                      <Body
                        as="span"
                        data-testid="inspectionChecklistShow-answer"
                      >
                        {question.answerValue
                          ? checklistAnswerFormatter(
                              question.answerValue,
                              question.questionType as unknown as EstimationConfigInputInputTypeEnum,
                            )
                          : '-'}
                      </Body>
                    </Box>
                    {question?.images?.length > 0 && (
                      <Box flexDirection="column">
                        <Box
                          data-testid={`inspectionChecklistShow-images-counter-${question.id}`}
                          marginBottom={200}
                          color="neutral500"
                          fontSize={200}
                        >
                          {imagesAvailable(question?.images?.length ?? 0)}
                        </Box>
                        <Box
                          data-testid="inspectionChecklistShow-images"
                          paddingBottom={500}
                          overflowX="auto"
                        >
                          {question.images?.map((image) => (
                            <Box
                              key={image.redirectUrl}
                              paddingLeft={100}
                              height={500}
                            >
                              <Image
                                src={image.redirectUrl}
                                alt="Checklist Image"
                                height={500}
                                borderRadius={300}
                                objectFit="contain"
                                data-testid={`inspectionChecklistShow-images-${question.id}-${image.redirectUrl}`}
                                onClick={() => {
                                  handleImageClick(question, image.redirectUrl);
                                }}
                              />
                            </Box>
                          ))}
                          <ImageLightboxModal
                            isOpen={isChecklistImageModalOpen}
                            onClose={closeChecklistImageModal}
                            images={currentImageSet}
                            initialImageUrl={initialModalImageUrl}
                            data-testid="modal"
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                ))}
            </Box>
          ))}
        </EstimatorResponsiveWrapper>
      </Box>
    )
  );
};
