import { Box, Input, Td } from '@hover/blueprint';
import { get } from 'lodash';
import { useFormContext } from 'react-hook-form';

import { LineItemTypeEnum } from 'src/api/graphql-global-types';
import { projectManagementProductionList_projectManagementProductionList_listItems as ListItem } from 'src/api/types/projectManagementProductionList';
import { formattedNumber } from 'src/features/project/util/utils';
import { usePrevious } from 'src/hooks';

import { ListItemCalculationDetail } from './ListItemCalculationDetail';

type Props = {
  listItem: ListItem;
  unitsMap: Map<string, string>;
  jobId: number;
  orgId: string;
  onUpdate: (inputLabel: string) => void;
};

export const ListItemQuantityColumn = ({
  listItem,
  unitsMap,
  jobId,
  orgId,
  onUpdate,
}: Props) => {
  const isMaterial = listItem?.type === LineItemTypeEnum.MATERIAL;
  const quantityErrorMessage = isMaterial
    ? 'Quantity must be whole number greater than zero'
    : 'Quantity must be greater than zero';

  const prevQuantity = usePrevious(listItem.quantity);

  const {
    register,
    setValue,
    trigger,
    formState: { errors: formErrors },
  } = useFormContext();

  /* Calculation Details Tooltip/Popover */
  const showCalculationNumerics =
    (!!listItem.measurement && listItem.measurement > 0) ||
    (!!listItem.wasteFactor && listItem.wasteFactor > 0) ||
    !!listItem.coverage;

  const showCalculationDetailTooltip =
    !!listItem.listItemContext || showCalculationNumerics;

  return (
    <Td
      paddingTop={{ base: 100, tablet: 500 }}
      paddingBottom={{ base: 0, tablet: 400 }}
      paddingLeft={0}
      paddingRight={0}
      verticalAlign="top"
      flexBasis="50%"
    >
      <>
        <Box>
          <>
            {showCalculationDetailTooltip && (
              <ListItemCalculationDetail
                listItem={listItem}
                jobId={jobId}
                orgId={orgId}
                unitsMap={unitsMap}
              />
            )}
            <Input
              data-testid="AddMaterial-quantity"
              size="small"
              borderRight="0px"
              borderRadius={
                showCalculationDetailTooltip ? '0px' : '6px 0px 0px 6px'
              }
              borderColor="neutral.500"
              textAlign="right"
              isRequired
              isInvalid={!!get(formErrors, 'quantity')}
              type="text"
              {...register('quantity', {
                valueAsNumber: true,
                validate: {
                  numberValidator: (quantityValue) => {
                    if (isMaterial) {
                      return Number.isInteger(quantityValue)
                        ? true
                        : quantityErrorMessage;
                    }
                    return Number.isFinite(quantityValue)
                      ? true
                      : quantityErrorMessage;
                  },
                  quantityValidator: (quantityValue) => {
                    return quantityValue > 0 ? true : quantityErrorMessage;
                  },
                },
              })}
              onBlur={async (event) => {
                // Set the new value based on the blur event, then trigger validation and wait for completion.
                const targetElement = event.target as HTMLInputElement;
                const newValue = parseFloat(targetElement.value);

                setValue('quantity', newValue);
                await trigger('quantity');

                // If new value has no errors, update the value. Else, revert it.
                if (!get(formErrors, 'quantity')) {
                  onUpdate('Quantity');
                } else {
                  setValue('quantity', prevQuantity);
                }
              }}
            />
          </>
        </Box>

        {!!listItem.calculatedQuantity && (
          <Box>
            <Box
              display="inline"
              fontSize={100}
              color="neutral.500"
              width="fit-content"
              marginTop={100}
            >
              {formattedNumber(listItem.calculatedQuantity)}
            </Box>
          </Box>
        )}
      </>
    </Td>
  );
};
