import { useCallback } from 'react';

import { Box, Button, Field, Modal, Select } from '@hover/blueprint';
import { startCase } from 'lodash';
import { useForm } from 'react-hook-form';

import type { productCatalogConfigOrgDistributors_productCatalogConfigOrgDistributors as Distributor } from 'src/api/types/productCatalogConfigOrgDistributors';

type DistributorSelectModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  setDistributor: (distributor: Distributor | null) => void;
  distributors?: Distributor[];
};

type DistributorSelectModalFields = {
  distributorId: string;
};

export const DistributorSelectModal: React.FC<DistributorSelectModalProps> = ({
  isOpen,
  onCancel,
  setDistributor,
  distributors,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      distributorId: '',
    },
  });

  // Reset distributor selection on modal Cancel.
  const cancel = useCallback(() => {
    reset();
    onCancel();
  }, [onCancel, reset]);

  const onSubmit = useCallback(
    (data: DistributorSelectModalFields) => {
      // Get selected distributor object.
      const selectedDistributor = distributors?.find((distributor) => {
        return distributor?.distributor.id === data.distributorId;
      }) as Distributor | null;
      // Reset the form.
      reset();
      // Set selected distributorId into redux state and continue to next step.
      setDistributor(selectedDistributor);
    },
    [distributors, reset, setDistributor],
  );

  const footer = (
    <Box testId="SelectDistributor-Actions">
      <Box>
        <Button
          testId="SelectDistributor-Cancel"
          color="primary"
          fill="minimal"
          onClick={cancel}
        >
          Cancel
        </Button>
        <Button
          testId="SelectDistributor-Save"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          isDisabled={!isDirty || !isValid}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={cancel}
        header={startCase('Select supplier')}
        isClosable
        footer={footer}
        isCentered
        size="medium"
      >
        <Field label="Supplier" name="distributorId">
          <Select
            {...register('distributorId', {
              required: 'Select a supplier',
            })}
            data-testid="SelectDistributor"
          >
            {!isValid ? (
              <option
                key="Select supplier"
                value=""
                data-testid="select-option-placeholder"
              >
                Select supplier
              </option>
            ) : null}
            {distributors?.map((distributor: Distributor) => (
              <option
                key={distributor.distributor.id}
                value={distributor.distributor.id}
              >
                {distributor.distributor.displayName}
              </option>
            ))}
          </Select>
        </Field>
      </Modal>
    </>
  );
};
