import React from 'react';

import { Td } from '@hover/blueprint';

import { projectManagementProductionList_projectManagementProductionList_listItems as ListItem } from 'src/api/types/projectManagementProductionList';

import { VariationSelect } from './Inputs/VariationSelect';

interface ListItemVariationColumnProps {
  listItem: ListItem;
  isUpdating: boolean;
  jobId: number;
  onUpdate: (inputLabel: string) => void;
}

export const ListItemVariationColumn = ({
  listItem,
  isUpdating,
  jobId,
  onUpdate,
}: ListItemVariationColumnProps) => {
  return (
    <Td
      paddingRight={{ base: 0, tablet: 800 }}
      paddingY={{ base: 0, tablet: 500 }}
      paddingLeft="0"
      verticalAlign={{
        base: '',
        tablet: 'top',
      }}
      flexBasis="100%"
    >
      <VariationSelect
        listItem={listItem as ListItem}
        isUpdating={isUpdating}
        jobId={jobId}
        onSubmit={() => {
          onUpdate('Variation Name');
        }}
      />
    </Td>
  );
};
