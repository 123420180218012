/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import {
  Body,
  Box,
  Button,
  Heading,
  Link,
  IconButton,
  useMediaQuery,
} from '@hover/blueprint';
import { Skeleton } from '@hover/blueprint/chakra';
import { iX, hClose } from '@hover/icons';
import { capitalize, toNumber, uniq } from 'lodash';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import {
  EstimationConfigTemplateCollectionTypeEnum,
  TradeTypeEnum,
} from 'src/api/graphql-global-types';
import { GET_TEMPLATE_COLLECTIONS } from 'src/api/queries/queries';
import {
  configTemplateCollectionsForOrg,
  configTemplateCollectionsForOrg_estimationConfigTemplateCollectionsForOrg_templateSections as TemplateSection,
  configTemplateCollectionsForOrg_estimationConfigTemplateCollectionsForOrg_templateSections_templatesByTradeType as TemplatesByTradeType,
  configTemplateCollectionsForOrg_estimationConfigTemplateCollectionsForOrg_templateSections_templatesByTradeType_templates as Template,
} from 'src/api/types/configTemplateCollectionsForOrg';
import { RecalculateHeader } from 'src/features/projectEstimator/components/EstimatorContainer/RecalculateHeader';
import { SearchInput } from 'src/features/projectEstimator/components/SelectTemplates/SearchInput';
import {
  ContentSection,
  Footer,
  Header,
  TemplateGrid,
} from 'src/features/projectEstimator/components/SelectTemplates/structuralComponents';
import { useTemplateStore } from 'src/features/projectEstimator/hooks';
import { selectedTemplatesOfTrade } from 'src/features/projectEstimator/utils/templateSelection';
import {
  useToastEhi,
  ToastStatusEnum,
  useEffectOnMount,
  useTracking,
} from 'src/hooks';
import { getUserOrgId } from 'src/redux/selectors';
import { EventNames } from 'src/types';
import { getParams } from 'src/utils/urlUtils';

const footerHelpLinkText = ' Need another template?';

const placeholderTemplate = {
  name: 'placeholder',
  __typename: 'EstimationConfigTemplate' as any,
  description: '',
  tradeType: TradeTypeEnum.ACCENT_SIDING,
  templateImage: null,
  searchText: null,
  brand: 'Generic',
};

const placeholderTemplates = [
  {
    id: 1,
    ...placeholderTemplate,
  },
  {
    id: 2,
    ...placeholderTemplate,
  },
  {
    id: 3,
    ...placeholderTemplate,
  },
  {
    id: 4,
    ...placeholderTemplate,
  },
  {
    id: 5,
    ...placeholderTemplate,
  },
];

enum TemplateSelectionCta {
  SelectPartialArea = 'Select partial area',
  Continue = 'Continue to area selection',
  CreateMaterialList = 'Create Material List',
}

export const SelectTemplates: React.FC<{
  padding: number;
}> = ({ padding }) => {
  const resetTemplateStore = useTemplateStore((state) => state.reset);
  useEffectOnMount(() => resetTemplateStore());

  const orgId = useSelector(getUserOrgId);
  const { useTypewriter, useCommonTrackingProps } = useTracking();

  const commonTrackingProps = useCommonTrackingProps();

  const typewriter = useTypewriter();

  const toast = useToastEhi();
  const history = useHistory();
  const location = useLocation();

  const [contentTop, setContentTop] = useState(0);
  const [contentBottom, setContentBottom] = useState(0);
  const [isHeaderCollapsed, setIsHeaderCollapsed] = useState(false);

  const urlTemplateIds = queryString.parse(location.search, {
    arrayFormat: 'comma',
  }).templateIds;
  const { returnTo } = queryString.parse(location.search);

  const initializeSelectedTemplateIds = () => {
    if (!urlTemplateIds) {
      return [];
    }

    if (Array.isArray(urlTemplateIds)) {
      return urlTemplateIds.map(toNumber);
    }

    if (typeof urlTemplateIds === 'string') {
      return [toNumber(urlTemplateIds)];
    }

    return [];
  };

  const [selectedTemplateIds, setSelectedTemplateIds] = useState<number[]>(
    initializeSelectedTemplateIds(),
  );

  const [searchTerm, setSearchTerm] = useState('');
  const { search } = useLocation();

  const [standardTemplates, setStandardTemplates] = useState<Template[]>([]);
  const [customTemplates, setCustomTemplates] = useState<Template[]>([]);
  const [tradeTypes, setTradeTypes] = useState<TradeTypeEnum[]>([]);
  const [activeTradeFilter, setActiveTradeFilter] =
    useState<TradeTypeEnum | null>(null);

  const [standardTemplatesFiltered, setStandardTemplatesFiltered] = useState<
    Template[]
  >([]);

  const [customTemplatesFiltered, setCustomTemplatesFiltered] = useState<
    Template[]
  >([]);

  const [isMobileOrTablet] = useMediaQuery('(max-width: 834px)');
  const { jobId } = getParams(search);

  const { data: templateCollectionsData } = useQuery(GET_TEMPLATE_COLLECTIONS, {
    variables: {
      orgId,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      toast({
        id: 'error-retrieving-template-collections',
        description:
          'Sorry, there was a problem retrieving your collection of templates.',
        status: ToastStatusEnum.ERROR,
      });
    },
    onCompleted: (data: configTemplateCollectionsForOrg) => {
      if (!data?.estimationConfigTemplateCollectionsForOrg?.templateSections)
        return;
      const customTemplatesByTradeType =
        data?.estimationConfigTemplateCollectionsForOrg?.templateSections?.find(
          (section: TemplateSection) =>
            section?.collectionType ===
            EstimationConfigTemplateCollectionTypeEnum.CUSTOM,
        )?.templatesByTradeType;

      const standardTemplatesByTradeType =
        data?.estimationConfigTemplateCollectionsForOrg?.templateSections?.find(
          (section: TemplateSection) =>
            section?.collectionType ===
            EstimationConfigTemplateCollectionTypeEnum.STANDARD,
        )?.templatesByTradeType;

      const standardTemplatesInitial = standardTemplatesByTradeType
        ? standardTemplatesByTradeType
            .map(
              (templatesByTradeType: TemplatesByTradeType) =>
                templatesByTradeType.templates,
            )
            .flat()
        : [];

      const customTemplatesInitial = customTemplatesByTradeType
        ? customTemplatesByTradeType
            .filter(Boolean)
            ?.map(
              (templatesByTradeType: TemplatesByTradeType) =>
                templatesByTradeType.templates,
            )
            .flat()
        : [];

      setStandardTemplates(standardTemplatesInitial);
      setCustomTemplates(customTemplatesInitial);
      setStandardTemplatesFiltered(standardTemplatesInitial);
      setCustomTemplatesFiltered(customTemplatesInitial);

      const tradeTypesInitial = uniq(
        [
          standardTemplatesByTradeType as TemplatesByTradeType[],
          customTemplatesByTradeType as TemplatesByTradeType[],
        ]
          .filter(Boolean)
          .flat()
          .map(
            (templatesByTradeType: TemplatesByTradeType) =>
              templatesByTradeType.tradeType,
          ),
      );

      setTradeTypes(tradeTypesInitial);
    },
  });

  const contentFetched = !!templateCollectionsData;
  const areTemplatesSelected = selectedTemplateIds.length > 0;

  useEffect(() => {
    setContentBottom(document.getElementById('footer')?.clientHeight || 0);
    setContentTop(document.getElementById('header')?.clientHeight || 0);
  }, []);

  useEffect(() => {
    if (jobId) return;
    toast({
      id: 'error-no-jobId',
      description: 'URL must include jobId',
      status: ToastStatusEnum.ERROR,
    });
  }, [jobId, toast]);

  useEffect(() => {
    if (tradeTypes.length > 0) {
      setContentTop(document.getElementById('header')?.clientHeight || 0);
    }
  }, [tradeTypes, padding]);

  useEffect(() => {
    if (!standardTemplates || !customTemplates) return;

    const newStandardTemplates = standardTemplates.filter(
      (template) =>
        (template.searchText
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
          template.name.toLowerCase().includes(searchTerm.toLowerCase())) &&
        (template.tradeType === activeTradeFilter || !activeTradeFilter),
    );
    const newCustomTemplates = customTemplates.filter(
      (template) =>
        (template.searchText
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
          template.name.toLowerCase().includes(searchTerm.toLowerCase())) &&
        (template.tradeType === activeTradeFilter || !activeTradeFilter),
    );

    setStandardTemplatesFiltered(newStandardTemplates);
    setCustomTemplatesFiltered(newCustomTemplates);
  }, [searchTerm, activeTradeFilter, standardTemplates, customTemplates]);

  const handleScroll = () => {
    const scrollPosition = document.getElementById(
      'SelectTemplatesContent',
    )?.scrollTop;
    if (scrollPosition && scrollPosition > 0) {
      setIsHeaderCollapsed(true);
    } else {
      setIsHeaderCollapsed(false);
    }
  };

  const handleBackToMaterialList = () => {
    if (!returnTo) return;
    window.location.href = returnTo as string;
  };

  const handleContinue = () => {
    typewriter.buttonPressed({
      button_text: TemplateSelectionCta.SelectPartialArea,
      page_or_screen_name: EventNames.projectEstimator.selectView.page,
      primary_cta: false,
      ...commonTrackingProps,
    });

    history.push({
      pathname: '/project_estimator/questions',
      search: location.search,
    });
  };

  const handleFilterClick = (tradeType: TradeTypeEnum) => {
    const isTradeTypeEqualActive = tradeType === activeTradeFilter;

    if (isTradeTypeEqualActive) {
      setActiveTradeFilter(null);
    } else {
      setActiveTradeFilter(tradeType);
    }

    typewriter.buttonPressed({
      button_text: tradeType,
      page_or_screen_name: EventNames.projectEstimator.selectView.page,
      primary_cta: false,
      button_type: isTradeTypeEqualActive
        ? `Include ${tradeType}`
        : `Exclude ${tradeType}`,
      ...commonTrackingProps,
    });
  };

  const handleClearClick = () => {
    typewriter.buttonPressed({
      button_text: 'Clear',
      page_or_screen_name: EventNames.projectEstimator.selectView.page,
      primary_cta: false,
      ...commonTrackingProps,
    });

    setSelectedTemplateIds([]);

    const newQueryString = new URLSearchParams(location.search);
    newQueryString.delete('templateIds');

    history.push({
      pathname: location.pathname,
      search: newQueryString,
    });
  };

  const renderTradeTypes = () =>
    tradeTypes.map((tradeType) => {
      const numberSelected = selectedTemplatesOfTrade(
        tradeType,
        [...customTemplates, ...standardTemplates],
        selectedTemplateIds,
      ).length;

      const isSelected = activeTradeFilter === tradeType;
      return (
        <Button
          key={tradeType}
          fill={isSelected ? 'solid' : 'outline'}
          borderColor="neutral.400"
          onClick={() => handleFilterClick(tradeType)}
        >
          <Box flexDirection="row" alignItems="center">
            <Body marginY={0} color={isSelected ? 'neutral.0' : 'neutral.900'}>
              {capitalize(tradeType)}
            </Body>
            {numberSelected > 0 && (
              <Box
                marginLeft={100}
                borderRadius="2px"
                backgroundColor={isSelected ? 'neutral.0' : 'neutral.600'}
              >
                <Body
                  marginY="2px"
                  marginX="6px"
                  color={isSelected ? 'neutral.900' : 'neutral.0'}
                >
                  {numberSelected}
                </Body>
              </Box>
            )}
          </Box>
        </Button>
      );
    });

  const renderTradeTypesSkeleton = () => (
    <Skeleton
      height="50px"
      width="100px"
      marginRight={100}
      borderRadius="25px"
    />
  );

  const renderContentSkeleton = () => (
    <>
      <Skeleton width="200px" borderRadius="25px" marginBottom={200}>
        <Heading size={500}>Standard</Heading>
      </Skeleton>
      <TemplateGrid
        data-testid="placeholder-templates"
        loading
        templates={placeholderTemplates}
      />
    </>
  );

  useEffect(() => {
    // update the url state to have the selected templates ids
    history.replace({
      search: queryString.stringify(
        {
          ...queryString.parse(location.search),
          templateIds: selectedTemplateIds,
        },
        { arrayFormat: 'comma' },
      ),
    });
  }, [history, location.search, selectedTemplateIds]);

  const continueButtonIsDisabled = !areTemplatesSelected || !jobId;

  return (
    <Box flexDirection="column" flex={1}>
      <Header padding={padding}>
        <Box flexDirection="column" width="100%">
          {!isHeaderCollapsed && isMobileOrTablet && (
            <Box justifyContent="flex-end">
              <IconButton
                icon={iX}
                label="back"
                fill="minimal"
                onClick={handleBackToMaterialList}
              />
            </Box>
          )}
          {!isHeaderCollapsed && !isMobileOrTablet && (
            <RecalculateHeader jobId={jobId} />
          )}
          <Box
            flexDirection={isMobileOrTablet ? 'column' : 'row'}
            width="100%"
            justifyContent="space-between"
          >
            <Box
              justifyContent="space-between"
              marginBottom={12}
              alignItems="center"
            >
              <Heading size={isHeaderCollapsed ? 200 : 700} marginBottom={0}>
                Choose Materials
              </Heading>
              {isHeaderCollapsed && isMobileOrTablet && (
                <IconButton
                  icon={iX}
                  label="back"
                  fill="minimal"
                  onClick={handleBackToMaterialList}
                />
              )}
            </Box>
            <Box width={isMobileOrTablet ? '100%' : '33%'} marginBottom={200}>
              {contentFetched && <SearchInput setSearchTerm={setSearchTerm} />}
            </Box>
          </Box>
          <Box
            flexDirection="row"
            width="100%"
            overflowX="auto"
            gap={100}
            marginTop={200}
          >
            {contentFetched ? renderTradeTypes() : renderTradeTypesSkeleton()}
          </Box>
        </Box>
      </Header>
      <ContentSection
        padding={padding}
        contentTop={contentTop}
        contentBottom={contentBottom}
        handleScroll={isMobileOrTablet ? undefined : handleScroll}
      >
        <Box flexDirection="column" width="100%">
          {contentFetched ? (
            <>
              {customTemplatesFiltered &&
                customTemplatesFiltered.length > 0 && (
                  <Box flexDirection="column">
                    <Heading size={500}>Custom</Heading>
                    <TemplateGrid
                      data-testid="custom-templates"
                      templates={customTemplatesFiltered}
                      setSelectedTemplateIds={setSelectedTemplateIds}
                      selectedTemplateIds={selectedTemplateIds}
                    />
                  </Box>
                )}
              {standardTemplatesFiltered &&
                standardTemplatesFiltered.length > 0 && (
                  <Box
                    flexDirection="column"
                    marginY={padding}
                    paddingBottom={200}
                  >
                    <Heading size={500}>Standard</Heading>
                    <TemplateGrid
                      data-testid="standard-templates"
                      templates={standardTemplatesFiltered}
                      setSelectedTemplateIds={setSelectedTemplateIds}
                      selectedTemplateIds={selectedTemplateIds}
                    />
                  </Box>
                )}
            </>
          ) : (
            renderContentSkeleton()
          )}
        </Box>
      </ContentSection>
      <Footer padding={padding}>
        <Box
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
          position="relative"
        >
          {isMobileOrTablet && (
            <Button
              width="fit-content"
              fill="solid"
              variant="secondary"
              onClick={handleClearClick}
              position="absolute"
              left="50%"
              transform="translateX(-50%)"
              top="-75px"
              display={areTemplatesSelected ? 'flex' : 'none'}
              paddingY={200}
              paddingX={300}
              iconAfter={hClose}
              gap={100}
            >
              Clear
            </Button>
          )}
          {!isMobileOrTablet && (
            <Box alignItems="center">
              <Link
                textDecoration="none"
                color="neutral.900"
                href="https://hover.qualtrics.com/jfe/form/SV_e9GaWLzCJ7lrP6K"
                target="_blank"
                onClick={() => {
                  typewriter.linkPressed({
                    link_text: footerHelpLinkText,
                    page_or_screen_name:
                      EventNames.projectEstimator.selectView.page,
                    primary_cta: false,
                    link_location: '/project_estimator/select_templates',
                    ...commonTrackingProps,
                  });
                }}
              >
                {footerHelpLinkText}
              </Link>
            </Box>
          )}
          <Box
            justifyContent="space-between"
            width={isMobileOrTablet ? '100%' : undefined}
            flexDirection={{ base: 'column', tablet: 'row' }}
            gap={100}
          >
            <Button
              fill="outline"
              borderColor="neutral.0"
              onClick={handleClearClick}
              display={{
                base: 'none',
                tablet: areTemplatesSelected ? 'block' : 'none',
              }}
            >
              Clear
            </Button>
            {selectedTemplateIds.length <= 1 && (
              <Button
                fill="outline"
                borderColor="neutral.400"
                disabled={continueButtonIsDisabled}
                onClick={handleContinue}
              >
                {TemplateSelectionCta.SelectPartialArea}
              </Button>
            )}
            <Button
              disabled={continueButtonIsDisabled}
              onClick={handleContinue}
            >
              {selectedTemplateIds.length > 1
                ? TemplateSelectionCta.Continue
                : TemplateSelectionCta.CreateMaterialList}
            </Button>
          </Box>
        </Box>
      </Footer>
    </Box>
  );
};
