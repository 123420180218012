/* eslint-disable camelcase */
import { useEffect } from 'react';

import { Button } from '@hover/blueprint';
import { isEmpty, isNil } from 'lodash';
import { useSelector } from 'react-redux';

import appSettings from 'src/appSettings';
import { IntegrationConnectionErrorModalContent } from 'src/components/Modals/IntegrationConnectionError/IntegrationConnectionErrorModalContent';
import { useTracking } from 'src/hooks';
import {
  GQLError,
  hasJobAccountError,
  IntegrationConnectionError,
} from 'src/lib/GraphqlClient';
import { getUserTrackingProps } from 'src/redux/selectors';
import { DefaultModal } from 'style-guide';

export const footerStyle = {
  justifyContent: 'right',
  padding: '0 32px 28px',
};

export const contentStyle = {
  padding: '0',
};

const trackingPageName = 'Oauth-integration-dialog-modal';

type Props = {
  errors?: ReadonlyArray<GQLError<IntegrationConnectionError>>;
  onCancel?: () => void;
};

export const IntegrationConnectionErrorModal: React.FC<Props> = ({
  errors,
  onCancel,
}) => {
  const hasErrors = !isNil(errors) && !isEmpty(errors);
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  useEffect(() => {
    if (!hasErrors) {
      return;
    }

    // Track initial display of OAuth connection error modal.
    typewriter.pageViewed({
      page_or_screen_name: trackingPageName,
      ...commonTrackingProps,
    });
  }, [errors, commonTrackingProps, hasErrors]);

  const handleCancelPress = () => {
    // Track Cancel button of OAuth connection error modal.
    typewriter.buttonPressed({
      page_or_screen_name: trackingPageName,
      button_text: 'Cancel',
      primary_cta: false,
      ...commonTrackingProps,
    });

    if (!isNil(onCancel)) {
      onCancel();
    }
  };

  const handleActionPress = () => {
    // Track Settings button of OAuth connection error modal.
    typewriter.buttonPressed({
      page_or_screen_name: trackingPageName,
      button_text: 'Open integration settings',
      primary_cta: false,
      ...commonTrackingProps,
    });

    // Close the modal.
    if (!isNil(onCancel)) {
      onCancel();
    }

    // Current page location, encoded as query param.
    const redirectPage = encodeURIComponent(window.location.href);

    // Redirect to Setting|Integrations page, passing current location.
    const hyperionBaseUrl = appSettings.HYPERION_API_SERVER;

    window.location.assign(
      `${hyperionBaseUrl}/ui/#/hvr/settings/integrations?redirect_loc=${redirectPage}`,
    );
  };
  const getTitle = (): string => {
    if (hasJobAccountError(errors)) {
      return 'Connect Supplier Integration';
    }

    return `Reconnect to your supplier integration${
      errors && errors.length > 1 ? 's' : ''
    }`;
  };

  return (
    <DefaultModal
      isOpen={hasErrors}
      data-testid="IntegrationConnectionErrorModal"
      textAlign="left"
      contentStyle={contentStyle}
      footerStyle={footerStyle}
      title={getTitle()}
      cancel={
        <Button onClick={handleCancelPress} fill="outline">
          Cancel
        </Button>
      }
      action={<Button onClick={handleActionPress}>Connect Integration</Button>}
    >
      <IntegrationConnectionErrorModalContent
        errors={errors!}
        trackingPageName={trackingPageName}
      />
    </DefaultModal>
  );
};
