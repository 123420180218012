import { Box, Body, TextInput } from '@hover/blueprint';

import { Label } from 'src/components/Misc';
import { ChangeEventHandler } from 'src/types';

interface Props {
  isValid?: boolean;
  onChange: ChangeEventHandler;
  value?: string;
  name: string;
  labelText: string;
  inputId: string;
  'data-testid'?: string;
  required?: boolean;
  type?: 'tel' | 'text';
}

const TextField: React.FC<Props> = ({
  isValid = true,
  onChange,
  value,
  name,
  labelText,
  inputId,
  required,
  type = 'text',
  ...rest
}) => {
  return (
    <>
      <Box justifyContent="space-between" alignItems="center">
        <Label htmlFor={inputId}>{labelText}</Label>
        {required && (
          <Body
            margin={0}
            size={200}
            color={isValid ? 'neutral600' : 'danger500'}
          >
            *
          </Body>
        )}
      </Box>
      <TextInput
        name={name}
        id={inputId}
        display="block"
        onChange={onChange}
        value={value}
        data-testid={rest['data-testid']}
        isInvalid={!isValid}
        type={type}
      />
    </>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default TextField;
