/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { toNumber, toString } from 'lodash';

import { ProjectManagementProposalDocumentStateEnum } from 'src/api/graphql-global-types';
import { EstimateGroupForInputSummary } from 'src/api/types/EstimateGroupForInputSummary';
import { estimationConfigInputCategories } from 'src/api/types/estimationConfigInputCategories';
import { estimationConfigRequiredInputs } from 'src/api/types/estimationConfigRequiredInputs';
import { estimationConfigTemplates } from 'src/api/types/estimationConfigTemplates';
import { estimationEstimateGroupsForJob } from 'src/api/types/estimationEstimateGroupsForJob';
import {
  getProducts as getProductsQuery,
  getProductsVariables,
} from 'src/api/types/getProducts';
import { projectManagementProposalDocumentsEstimateDetails } from 'src/api/types/projectManagementProposalDocumentsEstimateDetails';
import {
  QuestionResponses,
  CustomLineItem,
} from 'src/features/exteriorEstimator/types/Questions';
import { GET_PRODUCTS } from 'src/features/projectManagement/apis/graphql/queries';
import { GraphqlClient } from 'src/lib/GraphqlClient';
import { getPaginatedGql } from 'src/utils/PaginatedGqlUtils';

import {
  FETCH_PROPOSAL_DOCUMENTS,
  GET_ESTIMATE_GROUPS_FOR_JOB,
  GET_ESTIMATE_GROUP_FOR_INPUT_SUMMARY,
  GET_INPUT_CATEGORIES,
  GET_REQUIRED_INPUTS,
  GET_TEMPLATES,
} from '../apis/queries';

export const getTemplates = async (orgId: number | string) => {
  const res = await getPaginatedGql<estimationConfigTemplates>({
    query: GET_TEMPLATES,
    variables: {
      orgId,
    },
    typeName: 'estimationConfigTemplates',
  });

  return res!.estimationConfigTemplates.edges!.map((e) => e!.node!);
};

export const getRequiredInputs = async (
  orgId: number | string,
  templateIds: number[],
) => {
  const res = await getPaginatedGql<estimationConfigRequiredInputs>({
    query: GET_REQUIRED_INPUTS,
    typeName: 'estimationConfigRequiredInputs',
    variables: {
      orgId,
      templateIds,
    },
  });

  return res!.estimationConfigRequiredInputs.edges!.map((e) => e!.node!);
};

export const getCategories = async () => {
  const res = await getPaginatedGql<estimationConfigInputCategories>({
    query: GET_INPUT_CATEGORIES,
    typeName: 'estimationConfigInputCategories',
  });
  return res!.estimationConfigInputCategories.edges!.map((e) => e!.node!);
};

export const getProposals = async (estimateGroupId: string) => {
  const res =
    await getPaginatedGql<projectManagementProposalDocumentsEstimateDetails>({
      query: FETCH_PROPOSAL_DOCUMENTS,
      variables: {
        estimateGroupId,
        state: ProjectManagementProposalDocumentStateEnum.COMPLETE,
      },
      typeName: 'projectManagementProposalDocuments',
    });

  return res!.projectManagementProposalDocuments!.edges!.map((e) => e!.node!);
};

export const getProducts = async ({
  productIds,
  distributorIds,
  filterVariationsByOrg,
  orgId,
  after,
}: getProductsVariables) => {
  const res = await getPaginatedGql<getProductsQuery>({
    query: GET_PRODUCTS,
    variables: {
      productIds,
      distributorIds,
      filterVariationsByOrg,
      orgId,
      after,
    },
    typeName: 'productCatalogProducts',
  });

  return res!.productCatalogProducts!.edges!.map((e) => e!.node!);
};

type GetResponsesFromEstimateGroupSummary = {
  jobId: number;
  orgId: string;
  templateIds: number[];
};
export const getResponsesFromEstimateGroupSummary = async ({
  jobId,
  orgId,
  templateIds,
}: GetResponsesFromEstimateGroupSummary) => {
  const responsesFromSummary: QuestionResponses = {};
  let customLineItems: CustomLineItem[] = [];
  let isInitializingFromEstimateSummary = false;

  // check if estimateGroup exists for this job
  const { data: estimateGroupsForJobData } =
    await GraphqlClient.query<estimationEstimateGroupsForJob>({
      query: GET_ESTIMATE_GROUPS_FOR_JOB,
      variables: {
        jobId,
        first: 1,
      },
      fetchPolicy: 'no-cache',
    });

  // if an estimateGroup exists, get the summary
  if (!!estimateGroupsForJobData?.estimationEstimateGroups?.nodes?.length) {
    isInitializingFromEstimateSummary = true;
    const { data } = await GraphqlClient.query<EstimateGroupForInputSummary>({
      query: GET_ESTIMATE_GROUP_FOR_INPUT_SUMMARY,
      variables: {
        id: estimateGroupsForJobData?.estimationEstimateGroups?.nodes?.[0]?.id,
        orgId,
      },
      fetchPolicy: 'no-cache',
    });

    if (data.estimationEstimateGroup) {
      const { estimationEstimateGroup } = data;
      const createdAt = estimationEstimateGroup?.createdAt;
      const userAnswers = estimationEstimateGroup?.userAnswers;
      const estimateGroupTemplateIds = estimationEstimateGroup?.estimates?.map(
        (estimate) => estimate?.template?.id,
      );

      const areTemplateIdsTheSame =
        templateIds.sort().join() === estimateGroupTemplateIds.sort().join();

      // split flag was last disabled at 11/03/2023 10:42AM PDT
      const safeDate = new Date('2023-11-03T19:00:00.245Z'); // Fri Nov 03 2023 12:00:00 GMT-0700 (Pacific Daylight Time)
      if (
        // estimates created before 10/24/2023 cannot be reliably used to initalize the estimator
        new Date(createdAt) < safeDate ||
        //  or if the `templateIds` are different than the estimateGroup's templateIds, we cannot reliably use the estimateGroup to initialize the estimator
        !areTemplateIdsTheSame
      ) {
        return {
          isInitializingFromEstimateSummary: false,
          customLineItems,
          responsesFromSummary,
        };
      }

      // set all responses from summary
      // estimateSummary stores all values as strings
      userAnswers?.forEach((userAnswer) => {
        const { inputType, userValue } = userAnswer;
        if (!userAnswer?.estimationConfigInput?.id) return;

        // convert numbers
        if (inputType === 'NUMBER') {
          responsesFromSummary[userAnswer.estimationConfigInput.id] =
            toNumber(userValue);
        } else if (inputType === 'SELECT') {
          // find the option that matches the userValue (summary stores the label not the value)
          const option = userAnswer.estimationConfigInput.inputOptions?.find(
            (opt) => opt.label === userValue,
          );
          responsesFromSummary[userAnswer.estimationConfigInput.id] = toString(
            option?.value,
          );
        } else if (inputType === 'BOOLEAN') {
          responsesFromSummary[userAnswer.estimationConfigInput.id] =
            userValue === 'true';
        } else {
          // default to string value
          responsesFromSummary[userAnswer.estimationConfigInput.id] = userValue;
        }
      });

      // set all facets to true
      const facets = estimationEstimateGroup?.facets;
      facets?.forEach((facet) => {
        responsesFromSummary[facet.identifier] = true;
      });

      // set custom line items
      customLineItems = estimationEstimateGroup.estimates
        .map((estimate) => {
          const items = estimate.lineItems?.filter(
            (lineItem) => lineItem.custom,
          );
          if (!items?.length) return [];
          return items.map(
            (lineItem) =>
              ({
                key: lineItem.name,
                value: toString(lineItem.price),
                type: lineItem.type,
                tradeType: estimate.tradeType,
              } as unknown as CustomLineItem),
          );
        })
        .flat();
    }
  }

  return {
    isInitializingFromEstimateSummary,
    responsesFromSummary,
    customLineItems,
  };
};
