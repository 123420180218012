import { Box, Heading, Body, Panel } from '@hover/blueprint';

const LetterIndicatorBackgroundColorMap = {
  A: '#7232FC',
  B: '#32818D',
  C: '#548307',
  D: '#EE0000',
  P: '#919191',
};

type Props = {
  letterIndicator?: keyof typeof LetterIndicatorBackgroundColorMap;
  indent?: boolean;
};
export const CheckboxFieldUIWrapper: React.FC<Props> = ({
  letterIndicator,
  indent,
  children,
}) => (
  <Box
    flexDirection="row"
    justifyContent="space-between"
    paddingY={400}
    paddingLeft={indent ? 800 : 0}
    borderBottomWidth="1px"
    borderBottomColor="neutral200"
    borderBottomStyle="solid"
    sx={{
      '&:last-of-type': {
        borderBottom: 'none',
      },
    }}
  >
    <Box>{children}</Box>
    {letterIndicator && (
      <Box>
        <Box
          borderRadius="12px"
          width="40px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          color="neutral0"
          backgroundColor={LetterIndicatorBackgroundColorMap[letterIndicator]}
        >
          {letterIndicator}
        </Box>
      </Box>
    )}
  </Box>
);

type SectionPanelProps = {
  sidePanel?: React.ReactNode;
  headingText?: string;
  subHeadingText?: string;
  'data-testid'?: string;
  sidePanelTestId?: string;
};

export const SectionPanel: React.FC<SectionPanelProps> = ({
  children,
  headingText,
  subHeadingText,
  sidePanel,
  sidePanelTestId = 'sidePanelTestId',
  ...props
}) => (
  <>
    <Box width={1} flexDirection="column" alignItems="stretch" maxWidth={787}>
      {headingText && (
        <Heading
          size={400}
          marginBottom={0}
          data-testid="proposalSectionHeading"
        >
          {headingText}
        </Heading>
      )}
      {subHeadingText && (
        <Body color="neutral600" size={400}>
          {subHeadingText}
        </Body>
      )}
    </Box>
    <Box flexDirection="row" justifyContent="space-between">
      <Box flexDirection="column" alignItems="stretch" flexBasis={787}>
        <Panel
          boxShadow="distance500"
          marginBottom={600}
          padding={600}
          data-testid={props['data-testid']}
        >
          {children}
        </Panel>
      </Box>
      {sidePanel && (
        <Box flexDirection="column" width="225px">
          <Panel
            flex={1}
            boxShadow="distance500"
            padding={300}
            margin={0}
            marginBottom={600}
            data-testid={sidePanelTestId}
          >
            {sidePanel}
          </Panel>
        </Box>
      )}
    </Box>
  </>
);
