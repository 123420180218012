import { TemplatesState } from 'src/features/projectEstimator/types';
import { standardizeInputCategoryName } from 'src/utils/Formatters';

export const getTemplatesWithInputsFilteredByCategory = (
  category: string,
  templates: TemplatesState,
) => {
  return Object.entries(templates).reduce<TemplatesState>(
    (acc, [templateId, template]) => {
      const filteredInputs = template.inputs.filter((input) => {
        return (
          input?.inputCategory?.name &&
          standardizeInputCategoryName(input.inputCategory.name) === category
        );
      });
      const newTemplate = { ...template, inputs: filteredInputs };
      return filteredInputs.length > 0
        ? {
            ...acc,
            [templateId]: { ...newTemplate },
          }
        : acc;
    },
    {},
  );
};
