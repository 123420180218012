import { Body, Box, Label, Textarea } from '@hover/blueprint';
import { useFormContext } from 'react-hook-form';

export const ProposalTemplateNote = () => {
  const { register } = useFormContext();

  return (
    <Box flexDirection="column" marginTop={400}>
      <Body as="span" size={500} fontWeight="bold">
        Proposal Note
      </Body>
      <Label
        htmlFor="templateProposalNote"
        color="neutral600"
        size={400}
        marginY={200}
        flex={1}
      >
        Predefined text in note section, Sales rep can change it
      </Label>
      <Textarea
        data-testid="templateProposalNote"
        {...register('templateProposalNote')}
        resize="vertical"
        rows={5}
        maxWidth="723"
      />
    </Box>
  );
};
