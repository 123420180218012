import { Box } from '@hover/blueprint';
import { ProgressiveLoader } from '@hover/blueprint/extra/components';

import { useEffectOnMount, useTracking } from 'src/hooks';
import { EventNames } from 'src/types/actionTypes';

export const ProjectScopeProgressiveLoader = () => {
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  useEffectOnMount(() => {
    typewriter.pageViewed({
      page_or_screen_name: EventNames.project.scope.loaderAnimationShowed,
      ...commonTrackingProps,
    });
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <ProgressiveLoader
        marginTop="20vh"
        label="Loading Material List"
        captions={[
          'Hang tight as we build your material list!',
          'Say goodbye to number crunching...',
          'Just a few more seconds',
          'Calculating materials',
          'Calculating quantities!',
        ]}
        width="100%"
        data-testid="TemplateSelectionModal-Loader"
      />
    </Box>
  );
};
