import {
  Body,
  Box,
  Button,
  Heading,
  Icon,
  NextProvider,
  Stack,
} from '@hover/blueprint';
import { iAlertTriangle } from '@hover/icons';

import { useTracking } from 'src/hooks';
import { EventNames } from 'src/types';

export function InsufficientPermissions({ jobId }: { jobId: number }) {
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const handleContactSupport = () => {
    typewriter.buttonPressed({
      button_text: 'Contact Support',
      page_or_screen_name:
        EventNames.project.scope.limitedAccess.contactSupport,
      job_id: jobId,
      primary_cta: true,
      ...commonTrackingProps,
    });

    window.open('https://hover.to/contact/', '_blank');
  };

  const handleReturnHome = () => {
    typewriter.buttonPressed({
      button_text: 'Return home',
      page_or_screen_name: EventNames.project.scope.limitedAccess.returnHome,
      job_id: jobId,
      primary_cta: false,
      ...commonTrackingProps,
    });

    window.location.replace(`/wr/properties/${jobId}`);
  };

  return (
    <Box
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      data-testid="insufficient-permissions"
      flex={1}
      p={{ base: 500, desktop: 0 }}
    >
      <Icon icon={iAlertTriangle} size="huge" />
      <Heading size={600} mb={0}>
        Limited access
      </Heading>
      <Body size={600} mt={0} textAlign="center">
        Your account doesn&apos;t have permission to use this feature at the
        moment.
      </Body>

      <NextProvider attach fontsHost="/typography/">
        <Stack direction="column">
          <Button
            size="large"
            variant="secondary"
            onClick={handleContactSupport}
          >
            Contact Support
          </Button>
          <Button size="large" variant="ghost" onClick={handleReturnHome}>
            Return home
          </Button>
        </Stack>
      </NextProvider>
    </Box>
  );
}
