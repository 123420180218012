import React, { useCallback, useEffect, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import {
  Body,
  Box,
  Heading,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuOptionGroup,
} from '@hover/blueprint';
import { iChevronDown } from '@hover/icons';
import { groupBy, sortBy, uniqBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { TradeTypeEnum } from 'src/api/graphql-global-types';
import {
  EstimateGroupForInputSummary,
  EstimateGroupForInputSummary_estimationEstimateGroup_facets as EstimateGroupFacet,
} from 'src/api/types/EstimateGroupForInputSummary';
import { LoaderSpinner } from 'src/components/LoaderSpinner';
import { EstimatorApi } from 'src/features/exteriorEstimator/apis/estimator';
import { GET_ESTIMATE_GROUP_FOR_INPUT_SUMMARY } from 'src/features/exteriorEstimator/apis/queries';
import { EstimatorResponsiveWrapper } from 'src/features/exteriorEstimator/components/common/EstimatorResponsiveWrapper';
import { CustomLineItem } from 'src/features/exteriorEstimator/components/EstimationTool/EstimateGroupSummary/CustomLineItem';
import { SummaryHeader } from 'src/features/exteriorEstimator/components/EstimationTool/EstimateGroupSummary/SummaryHeader';
import { SUPERSCRIPT_TWO } from 'src/features/exteriorEstimator/constants/sidingConstants';
import { useGetWasteFactor } from 'src/features/exteriorEstimator/hooks/useGetWasteFactor';
import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import {
  getEstimateGroupIdFromLocation,
  getHdfMeasurements,
  getJobDetailSummary,
  getJobMeasurements,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { getParams } from 'src/features/exteriorEstimator/redux/sagas/selectors/estimatorSelectors';
import { FacetPageTradeTypes } from 'src/features/exteriorEstimator/types';
import {
  getSelectedRoofFacetTotal,
  getSelectedSidingFacetTotal,
} from 'src/features/exteriorEstimator/utils/estimateGroupUtils';
import { EstimationMeasurementParser } from 'src/features/exteriorEstimator/utils/EstimationMeasurementParser';
import PartialSidingUtils from 'src/features/exteriorEstimator/utils/PartialSidingUtils';
import {
  getRoofTrades,
  getSidingTrades,
} from 'src/features/exteriorEstimator/utils/questionsUtils';
import { useTracking } from 'src/hooks';
import { useIsMobileBreakpoint } from 'src/hooks/useIsMobileBreakpoint';
import {
  getOrgSettings,
  getTradeTypesSorted,
  getVariationsFilter,
} from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { Measurements } from 'src/types/EstimationMeasurementTypes';
import { EstimationSummarizedJsonMeasurements } from 'src/types/HdfMeasurements';

import { useGetJobDetails } from '../../hooks';
import { ModelSection } from '../PEMachete';
import { InputCategory } from './InputCategory';

export const EstimateSummary: React.FC = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobileBreakpoint();
  const { jobId } = useSelector(getParams);
  const orgSettings = useSelector(getOrgSettings);
  const { showEstimationProfitInSummary = false } = orgSettings ?? {};
  const jobDetailSummary = useSelector(getJobDetailSummary);
  const jobMeasurements = useSelector(getJobMeasurements);
  const tradeTypes = useSelector(getTradeTypesSorted);
  const estimateGroupId = useSelector(getEstimateGroupIdFromLocation);
  const variationsFilter = useSelector(getVariationsFilter);
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const hdfMeasurements = useSelector(getHdfMeasurements);
  const typewriter = useTypewriter();

  const modelSectionTrades: FacetPageTradeTypes[] = [
    TradeTypeEnum.SIDING,
    TradeTypeEnum.ROOF,
  ];

  const { data: jobDetails } = useGetJobDetails({ jobId: Number(jobId) });
  const job = jobDetails?.jobs?.results[0];

  const getMeasurements = useCallback(async () => {
    let fullMeasurements: Measurements;

    try {
      fullMeasurements = (
        (await EstimatorApi.getMeasurementsEstimation(Number(jobId))) as any
      ).data as Measurements;

      dispatch(
        estimatorActions.storeJobMeasurements({
          jobMeasurements: new EstimationMeasurementParser(
            fullMeasurements,
            orgSettings,
          ).results,
        }),
      );
    } catch (e) {
      // NO-OP
    }
  }, [dispatch, jobId, orgSettings]);

  const getHdfMeasurementsCallback = useCallback(async () => {
    try {
      const measurements = (
        await EstimatorApi.getHDFMeasurements(Number(jobId))
      ).data as EstimationSummarizedJsonMeasurements;

      measurements.siding_area_total =
        PartialSidingUtils.shouldCalculateWithOpenings(orgSettings)
          ? measurements.siding_with_openings_area_total
          : measurements.siding_area_total;

      measurements.stone_with_openings_area_measurement =
        PartialSidingUtils.shouldCalculateWithOpenings(orgSettings)
          ? measurements.stone_with_openings_area
          : measurements.stone_zero_waste_area;

      dispatch(
        estimatorActions.storeHdfMeasurements({
          hdfMeasurements: measurements,
        }),
      );
    } catch (e) {
      // NO-OP
    }
  }, [dispatch, jobId, orgSettings]);

  useEffect(() => {
    async function loadMacheteLib() {
      if (window.machete) return;
      const machete = (
        await import(
          /* webpackChunkName: "machete" */
          '@hover/machete'
        )
      ).default;
      window.machete = machete;
    }
    loadMacheteLib();
  });

  useEffect(() => {
    if (!jobMeasurements) {
      getMeasurements();
    }
    if (!hdfMeasurements) {
      getHdfMeasurementsCallback();
    }
  }, [
    getMeasurements,
    jobMeasurements,
    hdfMeasurements,
    getHdfMeasurementsCallback,
  ]);

  const { data: wasteFactorData } = useGetWasteFactor(jobMeasurements);

  const { data, loading } = useQuery<EstimateGroupForInputSummary>(
    GET_ESTIMATE_GROUP_FOR_INPUT_SUMMARY,
    {
      variables: {
        id: estimateGroupId,
        ...variationsFilter,
      },
    },
  );

  useEffect(() => {
    if (!jobDetailSummary) return;
    typewriter.pageViewed({
      page_or_screen_name:
        EventNames.estimator.estimateDetailsScreen.inputSummary.page,
      job_id: Number(jobDetailSummary?.id),
      ...commonTrackingProps,
    });
  }, [jobDetailSummary, commonTrackingProps]);

  const estimateGroup = data?.estimationEstimateGroup;
  const activeEstimates =
    estimateGroup?.estimates.filter((est) => est.active) || [];
  const roofTrades = getRoofTrades(tradeTypes).map(
    (trade) => trade.tradeTypeEnumValue,
  );
  const sidingTrades = getSidingTrades(tradeTypes).map(
    (trade) => trade.tradeTypeEnumValue,
  );
  const activeEstimatesIncludesRoof = activeEstimates.some((e) =>
    e.tradeType ? roofTrades.includes(e.tradeType) : false,
  );
  const activeEstimatesIncludesSiding = activeEstimates.some((e) =>
    e.tradeType ? sidingTrades.includes(e.tradeType) : false,
  );

  const facets: [] | EstimateGroupFacet[] = useMemo(() => {
    if (!estimateGroup || !estimateGroup.facets) {
      return [];
    }

    if (!activeEstimatesIncludesRoof) {
      return estimateGroup.facets.filter(
        (f: EstimateGroupFacet) => !f.identifier.startsWith('RF'),
      );
    }

    if (!activeEstimatesIncludesSiding) {
      return estimateGroup.facets.filter((f: EstimateGroupFacet) =>
        f.identifier.startsWith('RF'),
      );
    }

    return estimateGroup.facets;
  }, [
    estimateGroup,
    activeEstimatesIncludesRoof,
    activeEstimatesIncludesSiding,
  ]);

  if (loading) {
    return <LoaderSpinner show />;
  }

  if (!estimateGroup || !estimateGroup?.userAnswers?.length) {
    return null;
  }

  const customLineItems = activeEstimates
    .map((estimate) =>
      estimate.lineItems?.filter((lineItem) => lineItem.custom),
    )
    .flat();

  const groupedAnswers = groupBy(estimateGroup.userAnswers, (answer) => {
    return answer.inputCategory.name;
  });

  const inputCategories = estimateGroup.userAnswers.map(
    (answer) => answer.inputCategory,
  );

  const sortedUniqInputCategories = sortBy(
    uniqBy(inputCategories, 'id'),
    'sortOrder',
  );

  const selectedRoofFacetTotal = getSelectedRoofFacetTotal(estimateGroup);
  const selectedSidingFacetTotal = getSelectedSidingFacetTotal(estimateGroup);

  const renderMenuComponent = () => (
    // TODO: make this functional
    <Menu
      trigger={
        <MenuButton
          borderRadius="0px"
          color="neutral"
          fill="minimal"
          fontWeight={300}
          height="52px"
          iconAfter={iChevronDown}
          shape="box"
        >
          <Body color="neutral.900" fontWeight="semibold">
            All materials
          </Body>
        </MenuButton>
      }
    >
      <MenuOptionGroup defaultValue="foo" type="radio">
        <MenuItemOption
          onClick={() => {
            // handleChangeSortOrder(JobSortOrder.Desc);
          }}
          //   {...menuItemStyles}
          flexDir="row-reverse"
          //   value={JobSortOrder.Desc}
        >
          foo
        </MenuItemOption>
        <MenuItemOption
          //   onClick={() => {
          //     handleChangeSortOrder(JobSortOrder.Asc);
          //   }}
          flexDir="row-reverse"
          //   value={JobSortOrder.Asc}
        >
          bar
        </MenuItemOption>
      </MenuOptionGroup>
    </Menu>
  );

  return (
    <Box testId="EstimateSummary" flexDirection="column">
      {isMobile && <SummaryHeader />}
      <Box display="block">
        <EstimatorResponsiveWrapper
          data-testid="EstimateGroupSummary"
          // this is all to achieve a paddingTop={0}
          padding={0}
          paddingX={{ base: 300, desktop: 500 }}
          paddingBottom={{ base: 300, desktop: 500 }}
        >
          <Box
            flexDirection="row"
            justifyContent="space-between"
            marginY={300}
            paddingTop={900}
          >
            <Box flexDirection="column" padding={400}>
              <Body
                size={300}
                color="neutral800"
                marginY={100}
                fontFamily="Helvetica Neue"
                height="max-content"
              >
                {job?.name}
                <br />
                <Heading size={700} marginY={0}>
                  Scope Summary
                </Heading>
              </Body>
            </Box>
          </Box>
          <Box flexDirection="column" padding={400}>
            {facets.length > 0 && (
              <>
                <Box justifyContent="space-between">
                  <Box flexDirection="row">
                    {selectedRoofFacetTotal && activeEstimatesIncludesRoof && (
                      <Body
                        size={500}
                        color="neutral800"
                        fontFamily="Avenir Next"
                        marginBottom={400}
                        marginY={0}
                        marginRight={{ desktop: '10rem', mobile: '2rem' }}
                      >
                        <Body padding={0} margin={0} size={300}>
                          Total roof
                        </Body>
                        <Heading size={500}>
                          {`${selectedRoofFacetTotal} ft${SUPERSCRIPT_TWO}`}
                        </Heading>
                      </Body>
                    )}
                    {selectedSidingFacetTotal > 0 &&
                      activeEstimatesIncludesSiding && (
                        <Body
                          size={500}
                          color="neutral800"
                          fontFamily="Avenir Next"
                          marginY={0}
                        >
                          <Body padding={0} margin={0} size={300}>
                            Total siding
                          </Body>
                          <Heading size={500}>
                            {`${selectedSidingFacetTotal} ft${SUPERSCRIPT_TWO}`}
                          </Heading>
                        </Body>
                      )}
                  </Box>
                  {renderMenuComponent()}
                </Box>
                <ModelSection
                  projectEstimatorEnabled
                  facets={facets}
                  widescreen
                  trades={modelSectionTrades}
                />
              </>
            )}
            <Box
              flexDirection="column"
              width="100%"
              data-testid="InputCategories"
            >
              <Box flexDirection="column">
                {sortedUniqInputCategories.map((inputCategory) => {
                  const answers = groupedAnswers[inputCategory.name];
                  return (
                    <InputCategory
                      key={inputCategory.id}
                      answers={answers}
                      input={inputCategory}
                      predictedWasteFactor={
                        wasteFactorData?.wasteFactorRoof.predictedWasteFactor
                      }
                    />
                  );
                })}
                {customLineItems && customLineItems.length > 0 && (
                  <CustomLineItem customLineItems={customLineItems} />
                )}
              </Box>
            </Box>
          </Box>
        </EstimatorResponsiveWrapper>
      </Box>
    </Box>
  );
};
