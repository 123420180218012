/* eslint-disable camelcase */

import { PureComponent } from 'react';

import { isNil } from 'lodash';
import { Moment } from 'moment-timezone';

import { LineItemTypeEnum } from 'src/api/graphql-global-types';
import { tradeTypes_tradeTypes as TradeType } from 'src/api/types/tradeTypes';
import { FormattedNumber } from 'src/components/FormattedNumber';
import { getListItemsByPdfVendorName } from 'src/features/projectManagement/redux/selectors/estimatorProductionSelectors';
import {
  containerStyle,
  tableStyle,
  headerWrapper,
  headerText,
  logoStyle,
  vendorHeader,
  totalsTableStyleContainer,
  totalsTableTyle,
  shortCol,
  fatCol,
  labelStyle,
  grid,
  gridWithUnitCost,
  tableHeader,
  tableRow,
  col1,
  col2,
  col3,
  col4,
  col5,
  lastCol,
  bold,
  textAlignRight,
} from 'src/features/projectManagement/styles/OrderPdfStyles';
import { lineItemQuantityUnits } from 'src/utils/unitsMap';

import { VendorType, CategoryLineItems, ListItemType } from '../../../types';
import { massageItemsForMultiTrades } from '../../../utils/MultiTradesUtils';
import { PdfOutputData, PdfOutputDataType } from '../../../utils/PdfOutputData';
import { postTaxTotal } from '../../../utils/ProductionListUtils';

export interface PdfOutputParams {
  title: string;
  purchaseOrderNumber: string | null;
  vendorName?: string;
  listItems: CategoryLineItems | null;
  tradeFilter: string;
  tradeFilterList: string;
  distributionJobAccountId?: string;
  tradeTypes: TradeType[];
  distributorName?: string;
  pdf: {
    type: string | null;
    vendor: VendorType | null;
    shouldShowTotalCost: boolean;
    shouldShowItemMeasurements: boolean;
    shouldShowCostDetails: boolean;
    notes: string | null;
  };
  job: {
    homeowner_name?: string | null;
    name?: string | null;
    address_line_1?: string | null;
    address_line_2?: string | null;
    city?: string | null;
    region?: string | null;
    country?: string | null;
    zip?: string | null;
    phone?: string | null;
    email?: string | null;
    id?: number | null;
  };
  productionContact: {
    first_name?: string | null;
    last_name?: string | null;
    email?: string | null;
    phone?: string | null;
    org_name?: string | null;
    time?: Moment | null;
    logo?: string | null;
  };
  billing: {
    address_line_1?: string | null;
    address_line_2?: string | null;
    city?: string | null;
    region?: string | null;
    zip?: string | null;
    email?: string | null;
  };
}

export class PdfOutput extends PureComponent<PdfOutputParams> {
  renderHeader() {
    const {
      title,
      productionContact,
      purchaseOrderNumber,
      distributionJobAccountId,
      distributorName,
    } = this.props;
    const productionContactTyped = productionContact as Omit<
      typeof productionContact,
      'logo'
    > & { logo: string | undefined };

    return (
      <div style={headerWrapper} data-testid="PdfOutputHeader">
        <div>
          <div style={headerText} data-testid="PdfOutputTitle">
            {title}
          </div>
          {purchaseOrderNumber !== '' && (
            <div style={headerText} data-testid="PdfOutputPurchaseNum">
              PO: {purchaseOrderNumber}
            </div>
          )}
          {!isNil(distributionJobAccountId) && !isNil(distributorName) && (
            <div style={headerText} data-testid="PdfOutputDistributor">
              {`${distributorName} Job Account: ${distributionJobAccountId}`}
            </div>
          )}
        </div>
        <div>
          <img
            alt="logo"
            src={productionContactTyped.logo}
            style={logoStyle}
            data-testid="PdfOutputContactLogo"
          />
        </div>
      </div>
    );
  }

  renderJobDetailsTable() {
    const { job, productionContact, billing } = this.props;
    return (
      <>
        <table style={{ ...tableStyle, paddingTop: '10px' }}>
          <thead>
            <tr>
              <th style={{ ...shortCol, ...labelStyle }}>Delivery Address</th>
              <th style={{ ...fatCol, ...labelStyle }}>Production Contact</th>
              <th style={{ ...shortCol, ...labelStyle }}>Billing Address</th>
            </tr>
          </thead>
        </table>
        <table
          style={{
            width: '100%',
            border: '2px solid black',
            textAlign: 'left',
            padding: '8px',
          }}
        >
          <tbody>
            <tr>
              <th style={{ width: '25%', ...bold }}>
                {job.name || job.homeowner_name || 'Job'}
              </th>
              <th style={{ width: '50%', ...bold }}>
                {productionContact.first_name} {productionContact.last_name}
              </th>
              <th style={{ width: '25%', ...bold }}>
                {productionContact.org_name || 'Org Name'}
              </th>
            </tr>
            <tr>
              <td style={shortCol}>{job.address_line_1}</td>
              <td style={fatCol}>{productionContact.org_name || 'Org Name'}</td>
              <td style={shortCol}>
                {billing.address_line_1}, {billing.city}, {billing.region}{' '}
                {billing.zip}
              </td>
            </tr>
            <tr>
              <td style={shortCol}>
                {job.city}, {job.region} {job.zip}
              </td>
              <td style={fatCol}>{productionContact.email || ''}</td>
              <td style={shortCol}>{billing.email || ''}</td>
            </tr>
            <tr>
              <td style={shortCol} />
              <td style={fatCol}>
                {!isNil(productionContact.phone)
                  ? `T: ${productionContact.phone}`
                  : ''}
              </td>
              <td />
            </tr>
          </tbody>
        </table>
      </>
    );
  }

  renderWasteFactor(listItem: ListItemType) {
    const wasteFactor = Number(listItem.wasteFactor).toLocaleString(undefined, {
      style: 'percent',
      maximumFractionDigits: 2,
    });
    return (
      <div>
        {`+${wasteFactor} = `}
        <FormattedNumber
          value={
            Number(listItem.measurement) * (1 + Number(listItem.wasteFactor))
          }
        />
      </div>
    );
  }

  renderListItemsTable({ listItems }: { listItems: ListItemType[] }) {
    const { pdf, vendorName, tradeFilterList, tradeFilter, tradeTypes } =
      this.props;
    return (
      <>
        <div style={vendorHeader}>{vendorName || ''}</div>
        <div data-testid="pdfOutputTradeType">{tradeFilterList}</div>
        <div
          style={{
            ...(pdf.shouldShowCostDetails ? gridWithUnitCost : grid),
            ...tableHeader,
          }}
        >
          <div style={col1} data-testid="itemCol">
            Item
          </div>
          {pdf.type === LineItemTypeEnum.MATERIAL && (
            <div style={col2} data-testid="variantCol">
              Variant/Sku
            </div>
          )}
          {pdf.shouldShowItemMeasurements && (
            <div style={col3} data-testid="measurementsCol">
              Measurements
            </div>
          )}
          {(pdf.type === LineItemTypeEnum.MATERIAL ||
            pdf.type === LineItemTypeEnum.LABOR) && (
            <div
              style={pdf.shouldShowCostDetails ? col4 : lastCol}
              data-testid="quantityCol"
            >
              Quantity
            </div>
          )}
          {pdf.shouldShowCostDetails && (
            <>
              <div style={col5} data-testid="unitCostCol">
                Unit Cost
              </div>
              <div style={lastCol} data-testid="amountCostCol">
                Amount
              </div>
            </>
          )}
        </div>
        {listItems &&
          massageItemsForMultiTrades(listItems, tradeTypes, tradeFilter).map(
            (listItem) => (
              <div
                style={{
                  ...(pdf.shouldShowCostDetails ? gridWithUnitCost : grid),
                  ...tableRow,
                }}
                key={listItem.id}
              >
                <div style={col1} data-testid={`itemCol${listItem.id}`}>
                  {listItem.name}
                </div>
                {pdf.type === LineItemTypeEnum.MATERIAL && (
                  <div style={col2} data-testid={`variantCol${listItem.id}`}>
                    <div>{listItem.color} </div>
                    <div>{listItem.sku}</div>
                  </div>
                )}
                {pdf.shouldShowItemMeasurements && (
                  <div
                    style={col3}
                    data-testid={`measurementsCol${listItem.id}`}
                  >
                    <div>
                      {listItem.measurement ? (
                        <FormattedNumber value={listItem.measurement} />
                      ) : (
                        '--'
                      )}
                      {listItem.measurementUnits && (
                        <span style={{ marginLeft: '5px' }}>
                          {lineItemQuantityUnits(listItem.measurementUnits)}
                        </span>
                      )}
                    </div>
                    {!!listItem.wasteFactor && this.renderWasteFactor(listItem)}
                  </div>
                )}
                {(pdf.type === LineItemTypeEnum.MATERIAL ||
                  pdf.type === LineItemTypeEnum.LABOR) && (
                  <div
                    style={pdf.shouldShowCostDetails ? col4 : lastCol}
                    data-testid={`quantity${listItem.id}`}
                  >
                    {listItem.quantity}{' '}
                    {lineItemQuantityUnits(listItem.quantityUnits)}
                  </div>
                )}
                {pdf.shouldShowCostDetails && (
                  <>
                    <div style={col5} data-testid={`unitCostCol${listItem.id}`}>
                      <FormattedNumber
                        format="$0,0.00"
                        value={listItem.unitCost}
                      />
                    </div>
                    <div
                      style={lastCol}
                      data-test="amountCostColValue"
                      data-testid={`amountCostCol${listItem.id}`}
                    >
                      <FormattedNumber
                        format="$0,0.00"
                        value={listItem.pretaxCost}
                      />
                    </div>
                  </>
                )}
              </div>
            ),
          )}
      </>
    );
  }

  renderNotes() {
    const {
      pdf: { notes },
    } = this.props;
    return isNil(notes) || !notes.length ? null : (
      <div>
        <div style={headerText}>ORDER NOTES</div>
        <p>{notes}</p>
      </div>
    );
  }

  renderCostTotals({
    subtotal,
    salesTaxSubtotal,
  }: {
    subtotal: number;
    salesTaxSubtotal: number;
  }) {
    const { pdf, listItems } = this.props;
    return (
      pdf.shouldShowTotalCost && (
        <div style={totalsTableStyleContainer}>
          <table style={totalsTableTyle} data-testid="totalCostTable">
            <tbody>
              {pdf.type === LineItemTypeEnum.MATERIAL && (
                <tr>
                  <td>Subtotal</td>
                  <td style={textAlignRight}>
                    {listItems && (
                      <FormattedNumber value={subtotal} format="$0,0.00" />
                    )}
                  </td>
                </tr>
              )}
              {pdf.type === LineItemTypeEnum.MATERIAL && (
                <tr>
                  <td>Sales tax</td>
                  <td style={textAlignRight}>
                    {listItems && (
                      <FormattedNumber
                        value={salesTaxSubtotal}
                        format="$0,0.00"
                      />
                    )}
                  </td>
                </tr>
              )}
              <tr>
                <td style={headerText}>Total Cost:</td>
                <td style={{ ...headerText, ...textAlignRight }}>
                  {listItems && (
                    <FormattedNumber
                      value={postTaxTotal(subtotal, salesTaxSubtotal)}
                      format="$0,0.00"
                    />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    );
  }

  render() {
    const { vendorName, listItems } = this.props;

    if (!listItems) {
      return null;
    }
    const pdfOutputData: PdfOutputDataType = new PdfOutputData(
      listItems,
    ).build();

    const listItemsByVendor = getListItemsByPdfVendorName({
      listItems: pdfOutputData.listItems,
      vendorName,
    });

    return (
      <div style={containerStyle} id="container">
        {this.renderHeader()}
        {this.renderJobDetailsTable()}
        {this.renderListItemsTable({ listItems: listItemsByVendor })}
        {this.renderCostTotals({
          subtotal: pdfOutputData.subtotal,
          salesTaxSubtotal: pdfOutputData.salesTaxSubtotal,
        })}
        {this.renderNotes()}
      </div>
    );
  }
}
