import { Body, Button, Checkbox, useTheme, Grid } from '@hover/blueprint';
import { GridItem } from '@hover/blueprint/chakra';

import { FeatureFlag } from 'src/lib/FeatureFlag';

type Props = {
  allChecked: boolean;
  isIndeterminate: boolean;
  handleSelectAllNone: (event: React.ChangeEvent<HTMLInputElement>) => void;
  enableAllVariations: () => void;
  disableAllVariations: () => void;
};
export const ListHead: React.FC<Props> = ({
  allChecked,
  isIndeterminate,
  handleSelectAllNone,
  enableAllVariations,
  disableAllVariations,
}) => {
  const theme = useTheme();

  return (
    <Grid
      templateColumns="3fr 1fr 1fr"
      width={1}
      paddingX={500}
      alignItems="center"
      border={`1px solid ${theme.colors.neutral200}`}
      backgroundColor={`${theme.colors.neutral000}`}
    >
      <GridItem display="flex">
        <Checkbox // Select all/none - indeterminate checkbox.
          name="selectAll"
          aria-label="selectAll"
          id="selectAll"
          data-testid="checkboxSelectAll"
          isChecked={allChecked}
          isIndeterminate={isIndeterminate}
          onChange={handleSelectAllNone}
        />

        <Button
          visibility={!allChecked && !isIndeterminate ? 'hidden' : 'visible'}
          onClick={enableAllVariations}
          fill="minimal"
          size="small"
          marginLeft={300}
          data-testid="enableAllVariationsLink"
        >
          Enable All Variations
        </Button>

        <Button
          fill="minimal"
          visibility={!allChecked && !isIndeterminate ? 'hidden' : 'visible'}
          onClick={disableAllVariations}
          size="small"
          marginLeft={300}
          data-testid="disableAllVariationsLink"
        >
          Disable All Variations
        </Button>
      </GridItem>
      <GridItem>
        <Body size={300} m={300} color="secondary900" opacity={0.5}>
          Categories
        </Body>
      </GridItem>
      <GridItem display="flex" justifyContent="flex-end">
        <Body size={300} m={300} color="secondary900" opacity={0.5}>
          Variants
        </Body>
      </GridItem>
    </Grid>
  );
};
