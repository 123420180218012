import { useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { Body, Box, Link } from '@hover/blueprint';
import moment from 'moment';
import { connect } from 'react-redux';
import { useHistory, Link as RouterLink } from 'react-router-dom';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-unresolved
import { inspectionChecklist as InspectionChecklistQueryType } from 'src/api/types/inspectionChecklist';
import { LinkOrComponent } from 'src/components';
import { RoofOutline } from 'src/components/RoofOutline';
import { GET_INSPECTION_CHECKLIST } from 'src/features/exteriorEstimator/apis/queries/checklist';
import { getParams } from 'src/features/projectManagement/redux/selectors/estimatorProductionSelectors';
import { useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { RootState } from 'src/types/reduxStore';
import { jobProps } from 'src/utils/trackingUtils';

import {
  JobDetailsContainer,
  Text,
  PropertyName,
  RoofOutlineContainer,
  CreatedLine,
  CreatedRight,
} from './ProductionSummaryStyled';

export const mapStateToProps = (state: RootState) => ({
  jobDetails: state.estimatorProductionTools.jobDetails,
  estimateDetails: state.estimatorProductionTools.estimateDetails,
  estimateUser: state.estimatorProductionTools.estimateUser,
  jobId: getParams(state).jobId,
  orgId: getParams(state).orgId,
  commonProps: getUserTrackingProps(state),
});

type ComponentProps = ReturnType<typeof mapStateToProps>;
const estimateSummaryLinkText = 'Estimate Inputs Summary';

export const JobDetailsComponent: React.FC<ComponentProps> = ({
  jobDetails: job,
  estimateDetails: estimate,
  estimateUser,
  jobId,
  orgId,
  commonProps,
}) => {
  const { useTypewriter } = useTracking();
  const typewriter = useTypewriter();
  useEffect(() => {
    typewriter.pageViewed({
      page_or_screen_name: EventNames.pmp.page,
      ...jobProps(job),
    });
    // only run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const history = useHistory();

  const { data } = useQuery<InspectionChecklistQueryType>(
    GET_INSPECTION_CHECKLIST,
    {
      variables: { jobId, orgId },
      fetchPolicy: 'no-cache',
    },
  );
  const checklist = data && data.inspectionChecklist;

  const navigateToEstimateSummary = () => {
    typewriter.linkPressed({
      page_or_screen_name: EventNames.pmp.page,
      link_text: 'Estimate Inputs Summary',
      primary_cta: true,
      backend_id_value: estimate?.salesOpportunity?.soldEstimateGroupId ?? '',
      backend_id_type: 'estimate_group_id',
      ...commonProps,
    });
  };

  return (
    <JobDetailsContainer data-testid="job-details-container">
      <Text>Property production page for</Text>
      <PropertyName>{job?.name}</PropertyName>
      <Text>{job?.locationLine1}</Text>
      <Text>{`${job?.locationCity}, ${job?.locationRegion} ${job?.locationPostalCode}`}</Text>
      <RoofOutlineContainer>
        <RoofOutline jobId={job?.id} version="top" height="42vh" />
      </RoofOutlineContainer>
      <CreatedLine>
        <Text>Created Date</Text>
        <CreatedRight data-testid="createdDate">
          {moment(estimate?.createdAt).format('M/D/YYYY')}
        </CreatedRight>
      </CreatedLine>
      <CreatedLine>
        {estimateUser && (
          <>
            <Text>Sales Rep.</Text>
            <CreatedRight data-testid="estimateCreator">
              {`${estimateUser?.first_name} ${estimateUser?.last_name}`}
            </CreatedRight>
          </>
        )}
      </CreatedLine>
      <Box>
        <Link
          size={200}
          as={RouterLink}
          to={{
            pathname: `/estimator/estimates/${estimate?.id}/summary`,
            search: history.location.search,
            state: {
              prevPath: `${history.location.pathname}${history.location.search}`,
            },
          }}
          onClick={navigateToEstimateSummary}
        >
          {estimateSummaryLinkText.toUpperCase()}
        </Link>
      </Box>
      <Box marginTop={200}>
        <LinkOrComponent
          componentAs={Body}
          disabled={!checklist}
          size={200}
          to={`/estimator/inspection_checklist?jobId=${jobId}&orgId=${orgId}`}
          color={!checklist ? 'neutral500' : 'initial'}
          fontWeight={500}
          marginTop={0}
          data-testid={
            checklist
              ? 'inspectionChecklistLink'
              : 'inspectionChecklistLinkDisabled '
          }
        >
          CHECKLIST
        </LinkOrComponent>
      </Box>
    </JobDetailsContainer>
  );
};

export const JobDetails = connect(mapStateToProps)(JobDetailsComponent);
