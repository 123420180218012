import { startsWith, uniqBy } from 'lodash';

import {
  AugmentedFacade,
  AugmentedEdge,
  AugmentedPlainMeasurements,
  PlainMeasurements,
} from 'src/features/exteriorEstimator/types';
import { Measurements } from 'src/types/EstimationMeasurementTypes';

export interface WindowsForFacade {
  [key: string]: string[];
}

export interface EdgesForFacade {
  [facadeDisplayLabel: string]: AugmentedEdge[];
}

export interface FacadesForEdge {
  [edgeId: string]: AugmentedFacade[];
}

export interface EdgeTotals {
  // ie ridge_total, ridge_count
  [key: string]: number;
}

export interface EdgeTotalsByTypeForFacade {
  [facadeDisplayLabel: string]: EdgeTotals;
}

export interface EdgesByType {
  [edgeType: string]: AugmentedEdge[]; // edgeType should be an enum (rake, ridge, valley, etc)
}

export interface EdgesForFacadesByType {
  [facadeDisplayLabel: string]: EdgesByType;
}

export class PartialsMeasurementsMap {
  private rawMeasurements: PlainMeasurements | null = null;

  public estimationJson: Measurements;

  private augmentedMeasurements: AugmentedPlainMeasurements | null = null;

  /**
   * @example
   * edgesForFacade['RF-1']
   * // array of edges
   */
  public edgesForFacade: EdgesForFacade = {};

  /**
   * @example
   * edgeTotals
   * {
   *   rake_total: 348.0338102558,
   *   rake_count: 2,
   *   ridge_total: 468.8603444867,
   *   ridge_count: 1
   * }
   */
  public edgeTotals: EdgeTotals = {};

  public windowsForFacade: WindowsForFacade = {};

  constructor(
    partialMeasurements: PlainMeasurements,
    estimationJson: Measurements,
  ) {
    this.rawMeasurements = partialMeasurements;
    this.estimationJson = estimationJson;
    this.augmentedMeasurements = this.augmentMeasurements();
    this.edgesForFacade = this.createEdgesForFacade();
    this.edgeTotals = this.createEdgeTotals();
    this.windowsForFacade = this.createWindowsForFacade();
  }

  private createWindowsForFacade() {
    const map: { [key: string]: string[] } = {};
    Object.values(this.estimationJson?.facades ?? {}).forEach((facesArray) => {
      facesArray.forEach((face) => {
        if (face.facade) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          map[face.facade] = face.openings?.labels?.filter(
            (label) => label?.split('-').shift() === 'W',
          );
        }
      });
    });

    return map;
  }

  private augmentMeasurements() {
    if (!this.rawMeasurements) return null;
    const facades = this.augmentFacadesWithEdgeDisplayLabels();

    const edges = Object.values(facades)
      .flatMap((facade) => facade.edges)
      .reduce<{
        [key: string]: AugmentedEdge;
      }>((acc, edge) => {
        acc[edge.id] = edge;
        return acc;
      }, {});

    return {
      ...this.rawMeasurements,
      facades,
      edges,
    };
  }

  /**
   * adds `display_label`, `length`, `points` to the `edge` objects in `facade.edges` array
   */
  private augmentFacadesWithEdgeDisplayLabels() {
    const { edges = {} } = { ...this.rawMeasurements };

    return Object.entries(this.rawMeasurements?.facades ?? []).reduce<
      AugmentedPlainMeasurements['facades']
    >((_map, [facadeId, facade]) => {
      const map = { ..._map };
      map[facadeId] = {
        ...facade,
        edges: facade.edges.map((_edge) => {
          const edge: AugmentedEdge = {
            ..._edge,
            ...edges[_edge.id],
            fakeId: '',
          };
          // we use this generated id for de-duplication reasons
          // it turns out the ids are not unique for edges in the estimation_json
          edge.fakeId = `${_edge.id}-${edge.type}`;

          return edge;
        }),
      };
      return map;
    }, {});
  }

  private createEdgesForFacade() {
    return Object.values(
      this.augmentedMeasurements?.facades ?? [],
    ).reduce<EdgesForFacade>((acc, facade) => {
      acc[facade.display_label] = facade.edges;
      return acc;
    }, {});
  }

  // eslint-disable-next-line class-methods-use-this
  private isSidingFacade(facadeDisplayLabel: string) {
    return startsWith(facadeDisplayLabel, 'SI-');
  }

  // eslint-disable-next-line class-methods-use-this
  private isBrickFacade(facadeDisplayLabel: string) {
    return startsWith(facadeDisplayLabel, 'BR-');
  }

  // eslint-disable-next-line class-methods-use-this
  private isStoneFacade(facadeDisplayLabel: string) {
    return startsWith(facadeDisplayLabel, 'STO-');
  }

  // eslint-disable-next-line class-methods-use-this
  private isStuccoFacade(facadeDisplayLabel: string) {
    return startsWith(facadeDisplayLabel, 'STC-');
  }

  // initiliaze totals
  private createEdgeTotals() {
    const edges = uniqBy(Object.values(this.edgesForFacade).flat(), 'fakeId');

    return edges.reduce<EdgeTotals>((acc, edge) => {
      const { type, length } = edge;

      const typeCountKey = `${type}_count`; // ie ridge_count
      const typeTotalKey = `${type}_total`; // ie ridge_total
      if (acc[typeCountKey]) {
        acc[typeTotalKey] += length / 12;
        acc[typeCountKey] += 1;
      } else {
        acc[typeTotalKey] = length / 12;
        acc[typeCountKey] = 1;
      }
      return acc;
    }, {});
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default PartialsMeasurementsMap;
