import { useState, useEffect } from 'react';

import { Box, Heading, Body, Modal, Loader } from '@hover/blueprint';
import { useSelector } from 'react-redux';

import { productCatalogCategories_productCatalogCategories_nodes as Category } from 'src/api/types/productCatalogCategories';
import { productCatalogProductsByCategory_productCatalogProducts_nodes as Product } from 'src/api/types/productCatalogProductsByCategory';
import { useProductsByCategory } from 'src/features/settings/hooks/useProductsByCategory';
import { getUserOrgId, getUserTrackingProps } from 'src/redux/selectors';

export const ProductsMatchingCategoryModal = ({
  selectedCategory,
  onClose,
}: {
  selectedCategory: Category | null;
  onClose: () => void;
}) => {
  const [products, setProducts] = useState<Product[] | null>(null);
  const { fetchProductsByCategory } = useProductsByCategory();
  const orgId = useSelector(getUserOrgId);

  useEffect(() => {
    const fetchProducts = async () => {
      if (!selectedCategory) return;
      const data = await fetchProductsByCategory(orgId, selectedCategory.id);
      setProducts(data);
    };
    if (selectedCategory) {
      setProducts(null);
      fetchProducts();
    }
  }, [selectedCategory, fetchProductsByCategory, orgId]);

  return (
    <Modal
      isOpen={selectedCategory !== null}
      data-testid="orgProductCount"
      onClose={onClose}
      header={
        <Box
          borderBottomStyle="solid"
          borderBottomColor="neutral400"
          borderBottomWidth="1px"
          flexDirection="column"
        >
          <Heading size={500} marginBottom="0">
            Products Matching
          </Heading>
          <Body size={400} color="neutral900">
            {selectedCategory?.orgProductsCount} products would match in&nbsp;
            {selectedCategory?.name}&nbsp;category
          </Body>
        </Box>
      }
    >
      <Box flexDirection="column" maxHeight="400px" overflowY="scroll">
        <Body size={400} color="neutral900">
          {products ? (
            products.map((product: Product) => {
              return (
                <Box marginY={300} key={product.id}>
                  {product.name}
                </Box>
              );
            })
          ) : (
            <Loader />
          )}
        </Body>
      </Box>
    </Modal>
  );
};
