// can't use styled-components
export const containerStyle: React.CSSProperties = {
  padding: '0px 50px',
  fontWeight: 'normal',
  fontFamily:
    "ProximaNova Regular, 'Helvetica Neue', Helvetica, Arial, sans-serif",
  fontSize: '12px',
};

export const tableStyle: React.CSSProperties = {
  width: '100%',
  marginBottom: '55px',
  textAlign: 'left',
  fontSize: '12px',
  fontWeight: '400',
  color: '#0e0e0e',
};

export const headerWrapper: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
};

export const bold: React.CSSProperties = { fontWeight: 'bold' };
export const textAlignRight: React.CSSProperties = { textAlign: 'right' };

export const headerText: React.CSSProperties = {
  ...bold,
  fontSize: 'larger',
};

export const logoStyle: React.CSSProperties = {
  width: '120px',
  filter: 'grayscale(100%)',
};

export const totalsTableStyleContainer: React.CSSProperties = {
  display: 'block',
  pageBreakInside: 'avoid',
};

export const totalsTableTyle: React.CSSProperties = {
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
  alignItems: 'space-between',
  paddingTop: '30px',
  color: '#515251',
};

export const shortCol: React.CSSProperties = {
  width: '25%',
};

export const fatCol: React.CSSProperties = {
  width: '50%',
};

export const labelStyle: React.CSSProperties = {
  textTransform: 'uppercase',
  verticalAlign: 'baseline',
  color: '#a1a1a1',
  fontSize: '12px',
};

export const footerStyle: React.CSSProperties = {
  display: 'inline-block',
  marginTop: 30,
};

export const footerWrapperStyle: React.CSSProperties = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  fontSize: '9px',
  fontFamily: 'sans-serif',
  fontWeight: 'semibold',
  borderTop: '1px solid #a1a1a1',
};

export const grid: React.CSSProperties = {
  display: 'grid',
  gridTemplateColumns: '25% 20% 25% 15% [last] 15%',
};

export const gridWithUnitCost: React.CSSProperties = {
  display: 'grid',
  gridTemplateColumns: '20% 15% 20% 15% 15% [last] 15%',
};

export const col: React.CSSProperties = {
  padding: '8px',
  overflowWrap: 'break-word',
};

export const col1: React.CSSProperties = {
  ...col,
  gridColumnStart: 1,
};

export const col2: React.CSSProperties = {
  ...col,
  gridColumnStart: 2,
};

export const col3: React.CSSProperties = {
  ...col,
  gridColumnStart: 3,
};

export const col4: React.CSSProperties = {
  ...col,
  gridColumnStart: 4,
};

export const col5: React.CSSProperties = {
  ...col,
  gridColumnStart: 5,
};

export const col6: React.CSSProperties = {
  ...col,
  gridColumnStart: 6,
};

export const lastCol: React.CSSProperties = {
  ...col,
  ...textAlignRight,
  gridColumnStart: 'last',
};

export const tableRow: React.CSSProperties = {
  borderBottom: '1px solid #cfd6d9',
  padding: '.75rem 5px .75rem 0',
};

export const tableHeader: React.CSSProperties = {
  ...tableRow,
  ...bold,
  color: '#a1a1a1',
  borderBottom: '1px solid #cfd6d9',
  paddingBottom: '.75rem',
  fontSize: '12px',
};

export const vendorHeader: React.CSSProperties = {
  ...headerText,
  textTransform: 'uppercase',
  padding: '20px 0 5px',
};
