import React from 'react';

import { Icon, Image, Box, Tooltip, Body } from '@hover/blueprint';
import { iCheck, hHoverSolid } from '@hover/icons';

import { configTemplateCollectionsForOrg_estimationConfigTemplateCollectionsForOrg_templateSections_templatesByTradeType_templates as Template } from 'src/api/types/configTemplateCollectionsForOrg';

type Props = {
  template: Template;
  isSelected: boolean;
  handleCardClick: () => void;
};

export const TemplateCard = ({
  template,
  isSelected,
  handleCardClick,
}: Props) => {
  const templateToTooltipFragment = ({ name, description }: Template) => (
    <>
      <Box>{name}</Box>
      <Box>{description}</Box>
    </>
  );

  const imageUrl = template.templateImage?.image?.redirectUrl;
  const altText = template.templateImage?.image?.filename || '';

  return (
    <Tooltip label={templateToTooltipFragment(template)}>
      {/* Card Container */}
      <Box
        data-testid="MaterialListTemplateCard"
        key={`${template.id}-card`}
        onClick={handleCardClick}
        height={{ base: '86px', tablet: '135px' }}
        flexBasis={{
          base: '100%',
          tablet:
            'calc(25% - 8px)' /* 25% width for each item minus the 8px gap. if updating this, also update the gap in TemplateCardSection */,
        }}
        borderRadius="8px"
        flexDirection={{ base: 'row-reverse', tablet: 'column' }}
        justifyContent="start"
        alignItems="center"
        position="relative"
        boxSizing="border-box"
        padding={{ base: 0, tablet: 400 }}
        border={isSelected ? '2px solid' : '2px solid'}
        borderColor={isSelected ? 'neutral.900' : 'neutral.100'}
        paddingLeft={{ base: 500, tablet: undefined }}
        paddingRight={{ base: 500, tablet: undefined }}
      >
        {isSelected && (
          <Icon
            data-testid="templateCardSelectedIndicator"
            icon={iCheck}
            color="neutral.900"
            position="absolute"
            top="8px"
            right="8px"
            strokeWidth="2px"
            margin={0}
            padding={0}
          />
        )}

        {/* Icon // Logo Container */}
        <Box
          display={{ base: 'block', tablet: 'flex' }}
          width={{ base: undefined, tablet: '100%' }}
          flexDirection={{ base: undefined, tablet: 'row' }}
          alignItems={{ base: 'center', tablet: 'flex-end' }}
          paddingLeft={{ base: 200, tablet: 0 }}
          paddingTop={{ base: undefined, tablet: 200 }}
        >
          {imageUrl ? (
            <Image
              maxW="100%"
              maxH={{ base: '24px', tablet: '35px' }}
              padding={0}
              paddingBottom={{ base: 0, tablet: 200 }}
              src={imageUrl}
              alt={altText}
            />
          ) : (
            <Icon
              icon={hHoverSolid}
              color="primary.600"
              size="large"
              margin={0}
              padding={0}
              paddingBottom={{ base: 0, tablet: 200 }}
              height={{ base: '24px', tablet: '35px' }}
            />
          )}
        </Box>

        {/* Text Container */}
        <Box
          display="flex"
          flex="1"
          width="100%"
          height="50%"
          alignItems={{ base: 'center', tablet: 'normal' }}
        >
          <Body
            margin="0"
            padding="0"
            fontSize={{ base: 400, tablet: 200 }}
            fontWeight={400}
            noOfLines={{ base: 0, tablet: 3 }}
            lineHeight={{ base: '22px', tablet: '18px' }}
          >
            {template.name}
          </Body>
        </Box>
      </Box>
    </Tooltip>
  );
};
