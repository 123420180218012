import { Icon, Box, Body, Image, Link } from '@hover/blueprint';
import { hTriangleRuler, iBox, iInfo } from '@hover/icons';

import type { projectManagementProductionList_projectManagementProductionList_estimateGroup as EstimateGroup } from 'src/api/types/projectManagementProductionList';
import appSettings from 'src/appSettings';
import { RoofOutline } from 'src/components/RoofOutline';
import {
  ProjectDetailsPanel,
  SectionHeading,
  LinkBody,
} from 'src/features/project/components/ProjectScope/ProjectDetails/components';
import { buildAddressNameFromJob } from 'src/features/project/util/utils';
import { useTracking } from 'src/hooks';
import { EventNames } from 'src/types';

interface Props {
  estimateGroup: EstimateGroup;
}

export const Property: React.FC<Props> = ({ estimateGroup }) => {
  const { job } = estimateGroup?.salesOpportunity;

  const jobImageUrl = job?.bestFrontImage?.imageSmallUrl;

  /* Tracking */
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  return (
    <ProjectDetailsPanel data-testid="propertySection">
      <SectionHeading marginBottom={300}>Property</SectionHeading>
      <Body size={500} color="neutral700" mt={0} mb={400}>
        {buildAddressNameFromJob(job)}
      </Body>
      <Box flexDirection="row" flex="1">
        <Box testId="propertyImage" flex="0 0 auto" alignItems="start">
          {!jobImageUrl ? (
            <RoofOutline
              jobId={job?.id}
              version="top"
              maxWidth="168px"
              flexShrink={1}
            />
          ) : (
            <Image
              src={jobImageUrl}
              style={{ objectFit: 'contain' }}
              borderRadius="4px"
              alt="Property Image"
              flex="1"
            />
          )}
        </Box>
        <Box flex="1" justifyContent="center">
          <Box flexDirection="column" paddingLeft={300}>
            <Link
              size={200}
              data-testid="measurements-link"
              href={`${appSettings.HYPERION_API_SERVER}/api/v2/jobs/${job?.id}/measurements.pdf`}
              target="_blank"
              onClick={() => {
                typewriter.linkPressed({
                  link_text: 'Measurements',
                  page_or_screen_name:
                    EventNames.project.scope.property.measurements,
                  job_id: job.id,
                  primary_cta: false,
                  ...commonTrackingProps,
                });
              }}
            >
              <Box paddingY={100}>
                <Icon icon={hTriangleRuler} />
                <LinkBody size={200}>Measurements</LinkBody>
              </Box>
            </Link>
            <Link
              size={200}
              data-testid="3d-link"
              href={`${appSettings.HYPERION_API_SERVER}/3d/${job?.id}`}
              target="_blank"
              onClick={() => {
                typewriter.linkPressed({
                  link_text: '3D',
                  page_or_screen_name: EventNames.project.scope.property.threeD,
                  job_id: job.id,
                  primary_cta: false,
                  ...commonTrackingProps,
                });
              }}
            >
              <Box paddingY={100}>
                <Icon icon={iBox} />
                <LinkBody size={200}>3D</LinkBody>
              </Box>
            </Link>
            <Link
              size={200}
              data-testid="details-link"
              href={`${appSettings.HYPERION_API_SERVER}/wr/properties/${job?.id}`}
              target="_blank"
              onClick={() => {
                typewriter.linkPressed({
                  link_text: 'Details',
                  page_or_screen_name:
                    EventNames.project.scope.property.details,
                  job_id: job.id,
                  primary_cta: false,
                  ...commonTrackingProps,
                });
              }}
            >
              <Box paddingY={100}>
                <Icon icon={iInfo} />
                <LinkBody size={200}>Details</LinkBody>
              </Box>
            </Link>
          </Box>
        </Box>
      </Box>
    </ProjectDetailsPanel>
  );
};
