import { sortBy } from 'lodash';

import {
  EstimationConfigInputInputTypeEnum,
  TradeTypeCategoryEnum,
  TradeTypeEnum,
} from 'src/api/graphql-global-types';
import { estimationConfigTemplates_estimationConfigTemplates_nodes as Template } from 'src/api/types/estimationConfigTemplates';
import { tradeTypes_tradeTypes as TradeType } from 'src/api/types/tradeTypes';
import { Input, InputOption, Page } from 'src/features/exteriorEstimator/types';
import { isNumeric } from 'src/features/projectManagement/utils/InputValidator';
import { COMMERCE_RESET_CHILD_INPUTS, isEnabled } from 'src/lib/FeatureFlag';

import * as measurementsUtils from './measurementsUtils';

export const getQuestionDefaultValue = (question: Input) => {
  if (question.questionDefaultValue == null) {
    if (
      isEnabled(COMMERCE_RESET_CHILD_INPUTS) &&
      question.inputType === EstimationConfigInputInputTypeEnum.SELECT
    ) {
      // find the value of the inputOption with the lowest sortOrder
      const sortedOptions = sortBy(question?.inputOptions ?? [], 'sortOrder');
      return sortedOptions[0]?.value ?? '0';
    }
    return 0;
  }

  if (question.argument && measurementsUtils.isPitch(question.argument))
    return null;

  switch (question.inputType) {
    case EstimationConfigInputInputTypeEnum.NUMBER:
      return Number(question.questionDefaultValue);
    case EstimationConfigInputInputTypeEnum.BOOLEAN:
      return isNumeric(question.questionDefaultValue as string)
        ? Number(question.questionDefaultValue)
        : question.questionDefaultValue === 'true';
    default:
      // handles string and select
      return question.questionDefaultValue;
  }
};

export const sortQuestionInputOptions = (options: InputOption[]) =>
  options.sort((a, b) =>
    !!a.sortOrder && !!b.sortOrder
      ? a.sortOrder - b.sortOrder
      : Number(new Date(a.updatedAt ?? '')) -
        Number(new Date(b.updatedAt ?? '')),
  );

export const getPageForCategory = (pages: Page[], category: string) => {
  return pages.find((pageForCategory) => pageForCategory.category === category);
};

export const getRoofTrades = (tradeTypes: TradeType[]) => {
  return tradeTypes
    ? tradeTypes.filter(
        (tradeType) =>
          tradeType.tradeTypeCategoryEnumValue === TradeTypeCategoryEnum.ROOF,
      )
    : [];
};

export const getSidingTrades = (tradeTypes: TradeType[]) => {
  return tradeTypes
    ? tradeTypes.filter(
        (tradeType) =>
          tradeType.tradeTypeCategoryEnumValue === TradeTypeCategoryEnum.SIDING,
      )
    : [];
};

export const getTradeTypeEnumsOfRoofCategory = (tradeTypes: TradeType[]) => {
  return getRoofTrades(tradeTypes).map(
    (tradeType) => tradeType.tradeTypeEnumValue,
  );
};

export const getTradeTypeEnumsOfSidingCategory = (tradeTypes: TradeType[]) => {
  return getSidingTrades(tradeTypes).map(
    (tradeType) => tradeType.tradeTypeEnumValue,
  );
};

export const isRoofTradeType = (
  tradeType: TradeTypeEnum,
  tradeTypes: TradeType[],
) => {
  return getTradeTypeEnumsOfRoofCategory(tradeTypes).includes(tradeType);
};

export const isUsingRoofTemplate = (
  templates: Template[],
  tradeTypes: TradeType[],
) => {
  const tradeTypesOfTemplates = templates.map((template) => template.tradeType);
  return getTradeTypeEnumsOfRoofCategory(tradeTypes).some((roofTradeType) =>
    tradeTypesOfTemplates.includes(roofTradeType),
  );
};

export const isUsingSidingTemplate = (
  templates: Template[],
  tradeTypes: TradeType[],
) => {
  const tradeTypesOfTemplates = templates.map((template) => template.tradeType);
  return getTradeTypeEnumsOfSidingCategory(tradeTypes).some((sidingTradeType) =>
    tradeTypesOfTemplates.includes(sidingTradeType),
  );
};

export const getInputTradeType = (input: Input) => {
  const INPUT_ARG_TO_TRADE_TYPE_MAP: { [key: string]: TradeTypeEnum } = {
    ...TradeTypeEnum,
    // the following are for specific input args that dont follow the pattern of matching exactly to TradeTypeEnum
    SHINGLE: TradeTypeEnum.ROOF, // shingle_waste_factor_quantity
    GUTTER: TradeTypeEnum.GUTTERS, // gutter_waste_factor_quantity
  };

  // some input args might be 'roof_repairs_waste_factor_quantity' or 'vinyl_siding_waste_factor_quantity
  // which would want to map to TradeTypeEnum.ROOF_REPAIRS or TradeTypeEnum.VINYL_SIDING respectively
  const wordsBeforeWasteFactor = input.argument
    ?.split('_waste_factor')[0]
    .toUpperCase();

  return INPUT_ARG_TO_TRADE_TYPE_MAP[
    wordsBeforeWasteFactor as keyof typeof INPUT_ARG_TO_TRADE_TYPE_MAP
  ];
};
