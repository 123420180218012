import { Body, Box } from '@hover/blueprint';
import numeral from 'numeral';

import { TradeTypeCategoryEnum } from 'src/api/graphql-global-types';
import { EstimateGroup_estimationEstimateGroup_userAnswers as UserAnswer } from 'src/api/types/EstimateGroup';
import { lineItemQuantityUnits } from 'src/utils/unitsMap';

interface Props extends Partial<UserAnswer> {
  id: string;
  formattedUserValue: string;
  units?: string | null;
  predictedWasteFactor?: number;
}

export const Answer: React.FC<Props> = ({
  id,
  question,
  units,
  estimationConfigInput,
  categoryName,
  predictedWasteFactor,
  formattedUserValue,
}) => {
  const isRoofWasteFactorInput =
    categoryName?.toLowerCase() === 'waste_factor' &&
    estimationConfigInput?.tradeTypeCategories?.includes(
      TradeTypeCategoryEnum.ROOF,
    );

  const isPredictedWasteFactorValid =
    predictedWasteFactor && predictedWasteFactor !== 0;

  return (
    <Box
      data-testid={`answer-${id}`}
      key={id}
      flexDirection="row"
      justifyContent="space-between"
      mb={500}
    >
      <Box flexWrap="wrap" flex={1}>
        <Body size={500} margin={0}>
          {question}{' '}
          {isRoofWasteFactorInput &&
            isPredictedWasteFactorValid &&
            `(HOVER Recommended ${numeral(predictedWasteFactor).format('0%')})`}
        </Body>
      </Box>
      <Box ml={800} flexWrap="wrap" justifyContent="flex-end">
        <Box mr={100} flexShrink={0} flexWrap="wrap">
          <Body size={500} margin={0}>
            {formattedUserValue}
          </Body>
        </Box>
        <Box>
          {units && (
            <Body margin={0} size={500}>
              {lineItemQuantityUnits(units)}
            </Body>
          )}
        </Box>
      </Box>
    </Box>
  );
};
