import { PureComponent } from 'react';

import { Box } from '@hover/blueprint';

import { Label } from 'src/components/Misc';
import { Dropdown } from 'style-guide';

export interface ComponentProps {
  label: string;
  secondaryLabel?: string;
  dataIdLabel?: string;
  onChangeFunction?: (props: React.ChangeEvent<HTMLSelectElement>) => void;
  value: string | undefined;
  error?: boolean;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any;
}

export type Props = ComponentProps;

export class AddListItemDropDown extends PureComponent<Props> {
  render() {
    const {
      label,
      secondaryLabel,
      dataIdLabel,
      onChangeFunction,
      value,
      error,
      options,
    } = this.props;

    const dataTestIdForBox = label.replace(/\s/g, '').toLowerCase();

    return (
      <Box flexDirection="column">
        <Box flexDirection="row" justifyContent="space-between">
          <Label>{label}</Label>
          {secondaryLabel && <Label>{secondaryLabel}</Label>}
        </Box>
        <Box
          alignItems="center"
          data-testid={`${dataTestIdForBox}-addItemDropdown`}
        >
          <Dropdown
            data-testid={dataIdLabel || ''}
            onChange={(e) => onChangeFunction && onChangeFunction(e)}
            value={value}
            error={error}
            options={options}
          />
        </Box>
      </Box>
    );
  }
}
