import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import { StateType } from 'typesafe-actions';

import { exteriorEstimatorReducer as exteriorEstimator } from 'src/features/exteriorEstimator/redux/reducers';
import { project } from 'src/features/project/redux/reducer';
import { projectEstimatorReducer } from 'src/features/projectEstimator/redux/reducers';
import estimatorProductionTools from 'src/features/projectManagement/redux/reducers';
import { settings } from 'src/features/settings/redux/reducer';
import { hoverReducer as hover, ehiReducer as ehi } from 'src/redux/reducers'; // todo merge these into one reducer

const createRootReducer = (history: History) =>
  combineReducers({
    hover,
    ehi,
    exteriorEstimator,
    estimatorProductionTools,
    projectEstimatorReducer,
    project,
    settings,
    router: connectRouter(history),
  });

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default createRootReducer;

type returnType = ReturnType<typeof createRootReducer>;

export type RootState = StateType<returnType>;
