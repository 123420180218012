import { PureComponent } from 'react';

import autobind from 'autobind-decorator';
import styled from 'styled-components';

import FALLBACK_SRC from 'src/assets/images/fallback-image.png';

const StyledImage = styled.img`
  background-color: ${({ theme }) => theme.defaultGray};
`;

interface Props {
  onError?: (e: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  onLoad?: (e: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  src: string;
  fallBackSrc?: string;
}

interface State {
  error?: boolean;
  loaded?: boolean;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default class Image extends PureComponent<Props, State> {
  static defaultProps = {
    onError: () => null,
    onLoad: () => null,
    fallBackSrc: FALLBACK_SRC,
  };

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  @autobind
  onError(e: React.SyntheticEvent<HTMLImageElement, Event>) {
    const { onError } = this.props;
    onError?.(e);
    this.setState({ error: true });
  }

  @autobind
  onLoad(e: React.SyntheticEvent<HTMLImageElement, Event>) {
    const { onLoad } = this.props;
    onLoad?.(e);
  }

  @autobind
  getSource() {
    const { error } = this.state;
    const { fallBackSrc, src } = this.props;
    if (error) return fallBackSrc;
    return src;
  }

  render() {
    return (
      <StyledImage
        data-testid="style-guide-image"
        {...this.props}
        onError={this.onError}
        onAbort={this.onError}
        onLoad={this.onLoad}
        src={this.getSource()}
      />
    );
  }
}
