import { useCallback } from 'react';

import { TileCheckbox, Body } from '@hover/blueprint';

import { estimationConfigTemplates_estimationConfigTemplates_nodes as Template } from 'src/api/types/estimationConfigTemplates';

interface Props {
  template: Template;
  checked: boolean;
  onClickTemplate: () => void;
}

export const TemplateSelection: React.FC<Props> = (props) => {
  const { checked, template, onClickTemplate } = props;

  const handleClick = useCallback(() => {
    onClickTemplate();
  }, [onClickTemplate]);

  return (
    <TileCheckbox
      isChecked={checked}
      fontWeight="bold"
      onChange={handleClick}
      width="100%"
      data-testid="template-block-option"
    >
      <Body size={500} fontWeight={900} as="span">
        {template.name ?? ''}
      </Body>
    </TileCheckbox>
  );
};
