import { Box } from '@hover/blueprint';
import {
  SimpleGrid,
  Skeleton,
  SpaceProps,
  BoxProps,
} from '@hover/blueprint/chakra';

import { configTemplateCollectionsForOrg_estimationConfigTemplateCollectionsForOrg_templateSections_templatesByTradeType_templates as Template } from 'src/api/types/configTemplateCollectionsForOrg';
import { TemplateTile } from 'src/features/projectEstimator/components/SelectTemplates/TemplateTile';

export const Header: React.FC<{
  padding: number;
  paddingX?: number | object;
  children: React.ReactNode;
}> = ({ padding, children, ...props }) => (
  <Box
    flexDirection="row"
    justifyContent="center"
    left={0}
    position="fixed"
    top={0}
    width="100%"
    paddingX={padding}
    paddingTop={padding / 2}
    zIndex={100}
    paddingBottom={300}
    data-testid="estimator-header"
    id="header"
    {...props}
  >
    {children}
  </Box>
);

export const ContentSection: React.FC<
  {
    padding: SpaceProps['paddingX'];
    contentBottom: number;
    contentTop: number;
    children: React.ReactNode;
    handleScroll?: () => void;
  } & BoxProps
> = ({
  padding,
  contentTop,
  contentBottom,
  children,
  handleScroll,
  ...props
}) => (
  <Box
    bottom={`${contentBottom}px`} // footer height
    flex={1}
    height={`calc(100vh - 100px - ${contentBottom})`} // bottom
    justifyContent="center"
    left={0}
    data-testid="content"
    paddingX={padding}
    overflowY="auto"
    onScroll={handleScroll}
    position="absolute"
    top={`${contentTop}px`} // topbar height
    width="100vw"
    id="content-section"
    {...props}
  >
    {children}
  </Box>
);

export const Footer: React.FC<{
  padding: number;
  paddingX?: number | object;
  children: React.ReactNode;
}> = ({ padding, children, ...props }) => (
  <Box
    bottom={0}
    justifyContent="center"
    left={0}
    paddingX={padding}
    position="fixed"
    paddingY={200}
    width="100%"
    id="footer"
    {...props}
  >
    {children}
  </Box>
);

export const TemplateGrid: React.FC<{
  templates: Template[];
  loading?: boolean;
  selectedTemplateIds?: number[];
  setSelectedTemplateIds?: React.Dispatch<React.SetStateAction<number[]>>;
}> = ({ templates, loading, selectedTemplateIds, setSelectedTemplateIds }) => {
  return (
    <SimpleGrid
      data-testid="custom-templates"
      columns={{ base: 1, tablet: 3, desktop: 5 }}
      spacing={12}
    >
      {templates.map((template: Template) => (
        <Skeleton isLoaded={!loading} key={template.id}>
          <TemplateTile
            key={template.id}
            template={template}
            selectedTemplateIds={selectedTemplateIds}
            setSelectedTemplateIds={setSelectedTemplateIds}
          />
        </Skeleton>
      ))}
    </SimpleGrid>
  );
};
