import { TradeTypeEnum } from 'src/api/graphql-global-types';
import { Input, QuestionResponses } from 'src/features/exteriorEstimator/types';
import { CustomLineItem } from 'src/features/exteriorEstimator/types/Questions';
import { getResponsesFromEstimateGroupSummary } from 'src/features/exteriorEstimator/utils/gqlUtils';
import PartialSidingUtils from 'src/features/exteriorEstimator/utils/PartialSidingUtils';
import {
  COMMERCE_INIT_ESTIMATOR_FROM_SUMMARY,
  isEnabled,
  WASTE_FACTOR_DEFAULT_VALUE,
} from 'src/lib/FeatureFlag';

import {
  MEASUREMENT_NAME,
  PARTIAL_SIDING_SELECTION,
  PARTIAL_STONE_SELECTION,
  ROOF_FACET_SELECTION,
  ROOF_LINE_SEGMENT_ADJUSTMENT,
  SIDING_LINE_SEGMENT_ADJUSTMENT,
  WASTE_FACTOR_NAME,
} from '../constants/questionCategories';
import { PartialRoofUtils } from './PartialRoofUtils';
import { getInputTradeType, getQuestionDefaultValue } from './questionsUtils';

export class QuestionResponsesGenerator {
  static getInitialResponses(
    questions: Input[],
    tradeTypeToWasteFactorMap?: Map<TradeTypeEnum, number>,
  ) {
    const emptyQuestionResponses: QuestionResponses = {};
    questions.forEach((question) => {
      if (question.inputCategory.name === ROOF_LINE_SEGMENT_ADJUSTMENT) return;
      if (question.inputCategory.name === SIDING_LINE_SEGMENT_ADJUSTMENT)
        return;
      emptyQuestionResponses[question.id] = getQuestionDefaultValue(question);
      if (question.inputCategory.name === MEASUREMENT_NAME) {
        // populate it with its value
        emptyQuestionResponses[question.id] = question.answer;
      }

      // set default values of roof_facet_selection to be selected
      if (question.inputCategory.name === ROOF_FACET_SELECTION) {
        emptyQuestionResponses[question.id] = true;
      }

      // set default values of siding_facet_selection to be selected
      if (
        question.inputCategory.name === PARTIAL_SIDING_SELECTION ||
        question.inputCategory.name === PARTIAL_STONE_SELECTION
      ) {
        emptyQuestionResponses[question.id] = question.questionDefaultValue;
      }

      if (question.inputCategory.name === WASTE_FACTOR_NAME) {
        if (!isEnabled(WASTE_FACTOR_DEFAULT_VALUE)) {
          // Use trade type waste factor value if exists, otherwise default to 10
          emptyQuestionResponses[question.id] = getInputTradeType(question)
            ? tradeTypeToWasteFactorMap?.get(getInputTradeType(question)) ?? 10
            : 10;
        } else {
          // Set default value 10, if question have tradeTypes or defaultValue, it should be overwritten by these values
          emptyQuestionResponses[question.id] = 10;

          if (getInputTradeType(question)) {
            emptyQuestionResponses[question.id] =
              tradeTypeToWasteFactorMap?.get(getInputTradeType(question)) ?? 10;
          }

          if (
            question.questionDefaultValue &&
            Number(question.questionDefaultValue) !== 0
          ) {
            emptyQuestionResponses[question.id] = question.questionDefaultValue;
          }
        }
      }
    });
    return emptyQuestionResponses;
  }

  static async updateResponsesFromEstimateGroup({
    responses,
    jobId,
    orgId,
    shouldResetInputs,
    questions,
    templateIds,
  }: {
    responses: QuestionResponses;
    jobId: number;
    orgId: string;
    shouldResetInputs: boolean;
    questions: Input[];
    templateIds: number[];
  }) {
    const result: {
      responses: QuestionResponses;
      customLineItems: CustomLineItem[];
    } = { responses, customLineItems: [] };

    if (isEnabled(COMMERCE_INIT_ESTIMATOR_FROM_SUMMARY) && !shouldResetInputs) {
      const {
        responsesFromSummary,
        isInitializingFromEstimateSummary,
        customLineItems,
      } = await getResponsesFromEstimateGroupSummary({
        jobId,
        orgId,
        templateIds,
      });

      if (isInitializingFromEstimateSummary) {
        // estimate summary only sends back the selected facets so we need to manually set the other facets all to false
        // find all responses that refer to facets (SI, RF, STC, etc)
        const facetResponses =
          QuestionResponsesGenerator.getFacetResponsesFromSummary({
            responses,
            responsesFromSummary,
          });

        // zero out all measurement question responses
        const measurementQuestionResponsesSetToZero =
          QuestionResponsesGenerator.setMeasurementResponsesToZero({
            questions,
          });

        result.responses = {
          ...responses,
          ...measurementQuestionResponsesSetToZero,
          // override with any values we have from estimate summary
          ...responsesFromSummary,
          ...facetResponses,
        };

        result.customLineItems = customLineItems;
      }
    }
    return result;
  }

  static getFacetResponsesFromSummary({
    responses,
    responsesFromSummary,
  }: {
    responses: QuestionResponses;
    responsesFromSummary: QuestionResponses;
  }) {
    const allFacetsSetToFalse = Object.keys(responses)
      .filter(
        (key) =>
          PartialSidingUtils.isQuestionPartialSidingSelection(key) ||
          PartialRoofUtils.isQuestionPartialRoofFacetSelection(key),
      )
      .reduce<QuestionResponses>((acc, curr) => {
        acc[curr] = false;
        return acc;
      }, {});

    const facetsFromSummarySetToTrue = Object.keys(responsesFromSummary)
      .filter(
        (key) =>
          PartialSidingUtils.isQuestionPartialSidingSelection(key) ||
          PartialRoofUtils.isQuestionPartialRoofFacetSelection(key),
      )
      .reduce<QuestionResponses>((acc, curr) => {
        acc[curr] = true;
        return acc;
      }, {});

    return {
      ...allFacetsSetToFalse,
      ...facetsFromSummarySetToTrue,
    };
  }

  static setMeasurementResponsesToZero({ questions }: { questions: Input[] }) {
    const measurementQuestionResponses: QuestionResponses = {};

    const measurementQuestions = questions.filter(
      (q) => q.inputCategory.name === 'measurement',
    );

    measurementQuestions.forEach((question) => {
      measurementQuestionResponses[question.id] = 0;
    });

    return measurementQuestionResponses;
  }
}
