import { startCase, toLower, capitalize, uniq } from 'lodash';

import { TradeTypeEnum } from 'src/api/graphql-global-types';
import { estimationEstimateGroupsForJob_estimationEstimateGroups_nodes_estimates as Estimate } from 'src/api/types/estimationEstimateGroupsForJob';

export const sentenceCase = (string = ''): string => {
  return capitalize(toLower(startCase(string)));
};

export const formatTrades = (trades: (TradeTypeEnum | null)[]) => {
  return (
    trades
      .map((trade) => trade && sentenceCase(trade))
      .join(', ')
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-useless-concat
      .replace(/,([^,]*)$/, ' &' + '$1')
  );
};

export const formatTradesStringForEstimates = (estimates?: Estimate[]) => {
  const allTrades = uniq(estimates?.map((est) => est.tradeType));

  return formatTrades(allTrades);
};

// "test category" => TEST_CATEGORY
export const standardizeInputCategoryName = (name: string) => {
  return name.toUpperCase().replace(/ /g, '_');
};

// TEST_CATEGORY =? "Test category"
export const formatCategoryName = (name: string) => {
  return sentenceCase(name).replace('_', ' ');
};
