import { PureComponent } from 'react';

import autobind from 'autobind-decorator';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ListItemType } from 'src/features/projectManagement/types';
import { RootAction } from 'src/types/reduxStore';

import * as EstimatorProductionActions from '../../../redux/actions';
import { hasNoEmojis, isValidLength } from '../../../utils/InputValidator';
import { DumbInput } from '../Common/DumbInput';
import CustomInputValueIndicator from './CustomInputValueIndicator';

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      updateListItem: EstimatorProductionActions.updateListItem,
    },
    dispatch,
  );

interface SkuInputProps {
  listItem: ListItemType;
  key: string | undefined;
}

interface State {
  skuId: string | null;
  userSetCustomSku: boolean;
}

type Props = SkuInputProps & ReturnType<typeof mapDispatchToProps>;

class SkuInputComp extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const { key: defaultValue, listItem } = this.props;
    const skuId = defaultValue || get(listItem, 'sku', null);
    const userSetCustomSku = get(listItem, 'userSetCustomSku', false);
    this.state = {
      skuId,
      userSetCustomSku,
    };
  }

  @autobind
  handleTextBlur(): void {
    const { updateListItem, listItem } = this.props;
    const { userSetCustomSku, skuId } = this.state;
    updateListItem(listItem.id, {
      sku: skuId,
      userSetCustomSku,
    });
  }

  @autobind
  handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    const { listItem } = this.props;
    const sku = !!listItem && listItem.sku;
    this.setState({
      skuId: value,
      userSetCustomSku: sku !== value,
    });
  }

  render() {
    const { listItem } = this.props;
    const id = !!listItem ? listItem.id : '-';
    const userSetCustomSku = !!listItem ? listItem.userSetCustomSku : false;
    return (
      <CustomInputValueIndicator
        isCustomized={userSetCustomSku || false}
        parent="SkuInput"
      >
        <DumbInput
          data-testid={`skuInput-${id}`}
          placeholder="SKU ID"
          width="100%"
          margin="0"
          textAlign="left"
          validators={[hasNoEmojis, isValidLength(255)]}
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line react/destructuring-assignment
          value={this.state.skuId || ''}
          onChange={this.handleChange}
          handleBlur={this.handleTextBlur}
        />
      </CustomInputValueIndicator>
    );
  }
}

export const SkuInput = connect(undefined, mapDispatchToProps)(SkuInputComp);
