import React from 'react';

import { Box, BoxProps } from '@hover/blueprint';
import {
  DragDropContext,
  Droppable as BeautifulDroppable,
  Draggable as BeautifulDraggable,
  DropResult,
  DragStart,
  DroppableProps as BeautifulDroppableProps,
  DraggableProps as BeautifulDraggableProps,
} from 'react-beautiful-dnd';

export { DragDropContext };

export type { DropResult, DragStart };

type DroppableBoxProps = BoxProps & {
  droppableId: BeautifulDroppableProps['droppableId'];
};

export const DroppableBox: React.FC<DroppableBoxProps> = ({
  droppableId,
  children,
  ...boxProps
}) => (
  <BeautifulDroppable droppableId={droppableId}>
    {(provided) => (
      <Box
        data-testid="droppableWrapper"
        ref={provided.innerRef}
        {...provided.droppableProps}
        {...boxProps}
      >
        {children}
        {provided.placeholder}
      </Box>
    )}
  </BeautifulDroppable>
);

type DraggableBoxProps = BoxProps & {
  draggableId: BeautifulDraggableProps['draggableId'];
  index: number;
  isDragDisabled?: boolean;
};

export const DraggableBox: React.FC<DraggableBoxProps> = ({
  draggableId,
  index,
  isDragDisabled,
  children,
  ...boxProps
}) => (
  <BeautifulDraggable
    key={draggableId}
    draggableId={draggableId}
    index={index}
    isDragDisabled={isDragDisabled}
  >
    {(provided, snapshot) => (
      <Box
        data-testid="draggableWrapper"
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        {...boxProps}
      >
        {React.isValidElement(children)
          ? React.cloneElement(children as React.ReactElement<any>, {
              isDragging: snapshot.isDragging,
            })
          : children}
      </Box>
    )}
  </BeautifulDraggable>
);
