import React from 'react';

import { Button, Box } from '@hover/blueprint';
import { iArrowLeft } from '@hover/icons';
import { useHistory, useLocation } from 'react-router-dom';

import { Footer } from 'src/features/projectEstimator/components/SelectTemplates/structuralComponents';
import { SELECT_TEMPLATES_URL } from 'src/features/projectEstimator/constants';
import { useTemplateStore } from 'src/features/projectEstimator/hooks';

export const EstimatorFooter: React.FC<{ nextEnabled: boolean }> = ({
  nextEnabled,
}) => {
  const pages = useTemplateStore((state) => state.pages);
  const history = useHistory();
  const location = useLocation();

  const currentPageIndex = pages.findIndex(
    (page) => page.url === location.pathname,
  );

  const nextPage = pages[currentPageIndex + 1];
  const previousPage = pages[currentPageIndex - 1];

  const handleBackClick = () => {
    if (!previousPage) {
      history.push({
        pathname: SELECT_TEMPLATES_URL,
        search: location.search,
      });
    } else {
      history.push({
        pathname: previousPage.url,
        search: location.search,
      });
    }
  };

  const handelNextClick = () => {
    if (!nextPage) {
      alert('generate an estimate');
      // if not in the recalculate flow, none of the questions aside from measurement questions will have answers, so send the questionDefaultValue to the BE as the value
      // if in recalculate flow, all the questions should have answers
    } else {
      history.push({
        pathname: nextPage.url,
        search: location.search,
      });
    }
  };

  return (
    <Footer padding={500} paddingX={{ base: 300, tablet: 500 }}>
      <Box justifyContent="space-between" flexDirection="row" width="100%">
        <Button
          label="back"
          onClick={handleBackClick}
          fill="minimal"
          iconBefore={iArrowLeft}
        >
          Back
        </Button>
        <Button disabled={!nextEnabled} onClick={handelNextClick}>
          Continue
        </Button>
      </Box>
    </Footer>
  );
};
