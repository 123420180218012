import React, { useEffect } from 'react';

import {
  Body,
  Box,
  Heading,
  Link,
  Modal,
  Image,
  Button,
} from '@hover/blueprint';
import { iExternalLink } from '@hover/icons';

import { useTracking } from 'src/hooks';
import { EventNames } from 'src/types/actionTypes';

type Props = {
  isOpen: boolean;
  onCancel: () => void;
};

export const DirectOrderCtaModal: React.FC<Props> = ({ isOpen, onCancel }) => {
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const handleLearnMoreClick = () => {
    typewriter.buttonPressed({
      page_or_screen_name:
        EventNames.project.scope.orderMaterialsModel.learnMore,
      button_text: 'learn more',
      primary_cta: false,
      feature: 'material-list',
      ...commonTrackingProps,
    });
  };

  const handleGetStartedClick = () => {
    typewriter.buttonPressed({
      page_or_screen_name:
        EventNames.project.scope.orderMaterialsModel.getStarted,
      button_text: 'get started',
      primary_cta: true,
      feature: 'material-list',
      ...commonTrackingProps,
    });
  };

  useEffect(() => {
    if (isOpen) {
      typewriter.pageViewed({
        page_or_screen_name: EventNames.project.scope.orderMaterialsModel.page,
        feature: 'material-list',
        ...commonTrackingProps,
      });
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onCancel} isClosable size="large">
      <Box flexDir="row" testId="ProjectScope-Direct-Order-Cta-Content">
        <Box flexDir="column" flexBasis="55%" alignItems="center">
          <Heading>Order Materials Directly</Heading>
          <Body>
            With Direct Order, you can generate dependable roofing and siding
            material orders with top distribution partners in seconds and
            directly from HOVER.
          </Body>
          <Body>
            Reduce errors and unnecessary back and forth by generating accurate
            orders from HOVER&apos;s to-the-inch property measurements and your
            install preferences, distributor pricing and product catalog.
          </Body>
          <Box flexDir="column" width="100%" marginBottom={300}>
            <Box flexDirection="row">
              <Body
                minW="32px"
                width="32px"
                height="32px"
                background="#e6f1fe"
                textAlign="center"
                padding="3px"
                borderRadius="50%"
                marginRight={300}
                fontSize={200}
              >
                1
              </Body>
              <Box flexDirection="column">
                <Body fontWeight={700} fontSize={300} marginBottom={0}>
                  <Link
                    href="https://hover.to/ui/#/hvr/settings/integrations"
                    target="_blank"
                  >
                    Connect
                  </Link>{' '}
                  to your supplier account
                </Body>
              </Box>
            </Box>
            <Box flexDirection="row">
              <Body
                minW="32px"
                width="32px"
                height="32px"
                background="#e6f1fe"
                textAlign="center"
                padding="3px"
                borderRadius="50%"
                marginRight={300}
                fontSize={200}
              >
                2
              </Body>
              <Box flexDirection="column">
                <Body fontWeight={700} fontSize={300} marginBottom={0}>
                  Complete and submit your order preferences
                </Body>
              </Box>
            </Box>
            <Box flexDirection="row">
              <Body
                minW="32px"
                width="32px"
                height="32px"
                background="#e6f1fe"
                textAlign="center"
                padding="3px"
                borderRadius="50%"
                marginRight={300}
                fontSize={200}
              >
                3
              </Body>
              <Box flexDirection="column">
                <Body fontWeight={700} fontSize={300} marginBottom={0}>
                  Place orders
                </Body>
              </Box>
            </Box>
          </Box>
          <Button
            as="a"
            color="primary"
            marginBottom={600}
            width="100%"
            target="_blank"
            href="https://hover.qualtrics.com/jfe/form/SV_er4XF9JCt0ZbQDY"
            onClick={handleGetStartedClick}
          >
            Get started
          </Button>
          <Link
            href="https://help.hover.to/en/collections/3835185-material-list"
            target="_blank"
            iconAfter={iExternalLink}
            onClick={handleLearnMoreClick}
          >
            Learn More
          </Link>
        </Box>
        <Box
          flexDir="column"
          flexBasis="45%"
          justifyContent="center"
          alignItems="center"
        >
          <Image
            src="https://assets.hover.to/ehi/order-materials-modal-image-2.png"
            alt="order materials image"
            width="250px"
          />
        </Box>
      </Box>
    </Modal>
  );
};
