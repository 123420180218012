import { useEffect } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Field,
  Heading,
  Radio,
  Stack,
  Checkbox,
} from '@hover/blueprint';
import { useRadioGroup } from '@hover/blueprint/chakra';
import { iPercent } from '@hover/icons';
import { toNumber, toString } from 'lodash';
import {
  Controller,
  useForm,
  SubmitHandler,
  useController,
} from 'react-hook-form';
import { useSelector } from 'react-redux';

import { TradeTypeCategoryEnum } from 'src/api/graphql-global-types';
import {
  EHI_ORG_SETTINGS_UPDATE,
  ESTIMATION_CONFIG_TRADE_TYPE_CATEGORY_WASTE_FACTOR_UPDATE,
  GET_TRADE_TYPE_ENUM_ORGS,
} from 'src/api/queries/queries';
import {
  ehiOrgSettingsUpdate,
  ehiOrgSettingsUpdateVariables,
} from 'src/api/types/ehiOrgSettingsUpdate';
import {
  estimationConfigTradeTypeCategoryWasteFactorUpdate,
  estimationConfigTradeTypeCategoryWasteFactorUpdateVariables,
} from 'src/api/types/estimationConfigTradeTypeCategoryWasteFactorUpdate';
import {
  estimationConfigTradeTypeEnumOrgs,
  estimationConfigTradeTypeEnumOrgsVariables,
} from 'src/api/types/estimationConfigTradeTypeEnumOrgs';
import { NumberInput } from 'src/components';
import { useTracking } from 'src/hooks';
import { getOrgSettings, getUserOrgId } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

type FormValues = {
  sidingWaste: string;
  includeOpeningsLessThan33Sqft: string;
  includeSidingTrim: string;
  includeSidingOpeningsTrim: string;
};

type IncludeOpeningsLessThan33SqftValues = 'true' | 'false';

export const CalculationPreferences: React.FC = () => {
  const orgId = useSelector(getUserOrgId);
  const includeOpeningsLessThan33Sqft = toString(
    useSelector(getOrgSettings)?.includeOpeningsLessThan33Sqft,
  );

  const includeSidingTrim = toString(
    useSelector(getOrgSettings)?.includeSidingTrim,
  );
  const includeSidingOpeningsTrim = toString(
    useSelector(getOrgSettings)?.includeSidingOpeningsTrim,
  );

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const typewriter = useTypewriter();
  const commonTrackingProps = useCommonTrackingProps();

  const { data } = useQuery<
    estimationConfigTradeTypeEnumOrgs,
    estimationConfigTradeTypeEnumOrgsVariables
  >(GET_TRADE_TYPE_ENUM_ORGS, {
    variables: {
      orgId,
    },
  });

  const sidingWaste = (
    data?.estimationConfigTradeTypeEnumOrgs?.nodes?.find(
      (n) => n?.tradeType === 'SIDING',
    )?.wasteFactor ?? 0
  ).toString();

  const defaultValues = {
    sidingWaste,
    includeOpeningsLessThan33Sqft,
    includeSidingTrim,
    includeSidingOpeningsTrim,
  };

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty, errors },
    reset,
    getValues,
    setValue,
    watch,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    // this necessary because on initial load the redux values aren't available
    reset({
      sidingWaste,
      includeSidingTrim,
      includeSidingOpeningsTrim,
      includeOpeningsLessThan33Sqft,
    });
  }, [
    reset,
    sidingWaste,
    includeSidingTrim,
    includeSidingOpeningsTrim,
    includeOpeningsLessThan33Sqft,
  ]);

  const watchIncludeOpeningsLessThan33SqftField = watch(
    'includeOpeningsLessThan33Sqft',
  );

  useEffect(() => {
    const { includeOpeningsLessThan33Sqft: openingsLessThan33Sqft } =
      getValues();

    // if user excludes openings less than 33 sqft, then we are by default excluding siding openings trim,but user can override it
    if (openingsLessThan33Sqft === 'false') {
      setValue('includeSidingOpeningsTrim', 'false', {
        shouldDirty: true,
      });
    }

    if (openingsLessThan33Sqft === 'true') {
      setValue('includeSidingOpeningsTrim', 'true', {
        shouldDirty: true,
      });
      setValue('includeSidingTrim', 'true', {
        shouldDirty: true,
      });
    }

    // Excluded this rule bc react asking for adding setValue dependency which is causing infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchIncludeOpeningsLessThan33SqftField]);

  const { field: radioField } = useController({
    name: 'includeOpeningsLessThan33Sqft',
    control,
    defaultValue: defaultValues.includeOpeningsLessThan33Sqft,
  });

  const { getRootProps, getRadioProps } = useRadioGroup({
    ...radioField,
  });

  const [updateOrgSettings, { loading: loadingOrgSettings }] = useMutation<
    ehiOrgSettingsUpdate,
    ehiOrgSettingsUpdateVariables
  >(EHI_ORG_SETTINGS_UPDATE, {
    onCompleted: () => {
      reset(getValues());
    },
  });

  const [updateSidingWaste, { loading: loadingSidingWaste }] = useMutation<
    estimationConfigTradeTypeCategoryWasteFactorUpdate,
    estimationConfigTradeTypeCategoryWasteFactorUpdateVariables
  >(ESTIMATION_CONFIG_TRADE_TYPE_CATEGORY_WASTE_FACTOR_UPDATE, {
    onCompleted: () => {
      reset(getValues());
    },
  });

  const loading = loadingOrgSettings || loadingSidingWaste;

  const handleChangeIncludeSidingsTrimCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    typewriter.checkboxSelected({
      selection: e.target.checked.toString(),
      page_or_screen_name: EventNames.settings.calculationPreferences.page,
      options: 'Include siding trim SQFT',
      ...commonTrackingProps,
    });

    setValue('includeSidingTrim', e.target.checked ? 'true' : 'false', {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const handleChangeIncludeOpeningsSidingTrimCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    typewriter.checkboxSelected({
      selection: e.target.checked.toString(),
      page_or_screen_name: EventNames.settings.calculationPreferences.page,
      options: 'Include siding openings trim SQFT',
      ...commonTrackingProps,
    });

    setValue('includeSidingOpeningsTrim', e.target.checked ? 'true' : 'false', {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const handleChangeIncludeOpeningsCheckbox = (
    value: IncludeOpeningsLessThan33SqftValues,
  ) => {
    typewriter.checkboxSelected({
      selection:
        value === 'true'
          ? 'Include openings less than 33 SQFT'
          : 'Exclude openings less than 33 SQFT',
      page_or_screen_name: EventNames.settings.calculationPreferences.page,
      options: 'Include/Exclude openings less than 33 SQFT',
      ...commonTrackingProps,
    });

    setValue('includeOpeningsLessThan33Sqft', value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const handleChangeSidingWaste = (e: React.ChangeEvent<HTMLInputElement>) => {
    typewriter.textInput({
      input_value: e.target.value,
      input_label: 'Default siding waste',
      page_or_screen_name: EventNames.settings.calculationPreferences.page,
      ...commonTrackingProps,
    });

    setValue('sidingWaste', e.target.value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const handleCancel = () => {
    typewriter.buttonPressed({
      page_or_screen_name: EventNames.settings.calculationPreferences.page,
      button_text: 'Cancel',
      primary_cta: false,
      ...commonTrackingProps,
    });

    reset();
  };

  const onSubmit: SubmitHandler<FormValues> = (formData) => {
    typewriter.buttonPressed({
      page_or_screen_name: EventNames.settings.calculationPreferences.page,
      button_text: 'Submit',
      primary_cta: false,
      ...commonTrackingProps,
    });

    updateOrgSettings({
      variables: {
        orgId: toString(orgId),
        orgSettingsAttributes: {
          includeOpeningsLessThan33Sqft:
            formData.includeOpeningsLessThan33Sqft === 'true',
          includeSidingTrim: formData.includeSidingTrim === 'true',
          includeSidingOpeningsTrim:
            formData.includeSidingOpeningsTrim === 'true',
        },
      },
    });

    updateSidingWaste({
      variables: {
        orgId: toString(orgId),
        tradeTypeCategory: TradeTypeCategoryEnum.SIDING,
        wasteFactor: toNumber(formData.sidingWaste),
      },
    });
  };

  useEffect(() => {
    typewriter.pageViewed({
      page_or_screen_name: EventNames.settings.calculationPreferences.page,
      ...commonTrackingProps,
    });

    // Excluded this rule bc react asking for adding typewriter dependency which is causing infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack
      as="form"
      direction="column"
      height="100%"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack direction="column" flex={1}>
        <Box
          justifyContent="space-between"
          alignItems="center"
          marginBottom={500}
        >
          <Box alignItems="center">
            <Heading size={600} marginBottom={0}>
              Calculation Preferences
            </Heading>
          </Box>
        </Box>
        <Heading size={200}>SIDING</Heading>
        <Box
          borderColor="neutral200"
          borderWidth="1px"
          borderRadius="lg"
          borderStyle="solid"
          padding={500}
        >
          <Stack direction="column">
            <Field
              label="Default siding waste"
              name="sidingWaste"
              error={errors.sidingWaste && 'Siding waste cannot be empty'}
            >
              <Controller
                name="sidingWaste"
                control={control}
                rules={{ min: 0, max: 99, required: true }}
                render={({ field }) => (
                  <NumberInput
                    {...field}
                    iconAfter={iPercent}
                    width="150px"
                    decimalScale={0}
                    allowNegative={false}
                    isAllowed={(values) => {
                      const { floatValue } = values;

                      if (!floatValue) return true;

                      return (
                        toNumber(floatValue) < 100 && toNumber(floatValue) >= 0
                      );
                    }}
                    onChange={(e) => handleChangeSidingWaste(e)}
                  />
                )}
              />
            </Field>
            <Field
              label="Siding walls and trim calculation"
              name="includeOpeningsLessThan33Sqft"
            >
              <Stack direction="column" {...getRootProps()}>
                <Radio
                  {...getRadioProps({ value: 'true' })}
                  onChange={() => handleChangeIncludeOpeningsCheckbox('true')}
                >
                  Include openings less than 33 SQFT
                </Radio>

                <Radio
                  {...getRadioProps({ value: 'false' })}
                  onChange={() => handleChangeIncludeOpeningsCheckbox('false')}
                >
                  Exclude openings less than 33 SQFT
                </Radio>
              </Stack>
            </Field>

            <Field label="Siding Trim Area" name="includeSidingTrims">
              <Stack direction="column">
                <Controller
                  name="includeSidingTrim"
                  control={control}
                  defaultValue={defaultValues.includeSidingTrim}
                  render={({ field }) => (
                    <Checkbox
                      data-test="includeSidingTrimCheckbox"
                      isChecked={field.value === 'true'}
                      onChange={(e) =>
                        handleChangeIncludeSidingsTrimCheckbox(e)
                      }
                    >
                      Include siding trim SQFT
                    </Checkbox>
                  )}
                />

                <Controller
                  name="includeSidingOpeningsTrim"
                  control={control}
                  defaultValue={defaultValues.includeSidingOpeningsTrim}
                  render={({ field }) => (
                    <Checkbox
                      data-test="includeSidingOpeningsTrimCheckbox"
                      isChecked={field.value === 'true'}
                      onChange={(e) =>
                        handleChangeIncludeOpeningsSidingTrimCheckbox(e)
                      }
                    >
                      Include siding openings trim SQFT
                    </Checkbox>
                  )}
                />
              </Stack>
            </Field>
          </Stack>
        </Box>
      </Stack>

      <Box as="footer" gap={400}>
        <Button
          type="submit"
          isDisabled={!isValid || !isDirty || loading}
          isLoading={loading}
        >
          Save
        </Button>
        <Button
          fill="outline"
          isDisabled={!isDirty || loading}
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </Box>
    </Stack>
  );
};
