import { Box, Heading } from '@hover/blueprint';
import { lowerCase, startCase } from 'lodash';

interface Props {
  name: string;
}

export const AnswerSection: React.FC<Props> = ({ name, children }) => {
  return (
    <Box
      data-testid={`InputSummary-${name}`}
      flexDirection="column"
      my={300}
      borderBottomStyle="solid"
      borderBottomWidth="1px"
      borderBottomColor="neutral.200"
      _last={{ borderBottom: 'none' }}
    >
      <Box mb={300}>
        <Heading size={300}>
          {startCase(lowerCase(name.replace('_', ' ')))}
        </Heading>
      </Box>
      {children}
    </Box>
  );
};
