import { Body, Icon } from '@hover/blueprint';
import { iInfo } from '@hover/icons';

import { WarningContainer } from 'src/features/exteriorEstimator/components/common/CommonComponentsStyled';

interface Props {
  text: string;
}

export const EstimatorWarning: React.FC<Props> = ({ text }) => {
  return (
    <WarningContainer
      data-testid="warning"
      flexDirection="column"
      alignItems="center"
      padding={400}
    >
      <Icon icon={iInfo} size="medium" color="neutral300" />
      <Body size={300} color="neutral600">
        {text}
      </Body>
    </WarningContainer>
  );
};
