import { useEffect, useMemo, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Body,
  Box,
  Button,
  Link,
  Modal,
  useDisclosure,
} from '@hover/blueprint';
import { compact, uniq } from 'lodash';
import { useSelector } from 'react-redux';

import {
  EstimationConfigTemplateCollectionTypeEnum as TemplateCollectionTypeEnum,
  EstimationConfigTemplateFromProductionListCreateRequestCreateInput as RequestTemplateCreateAttrs,
  EstimationConfigTemplateFromProductionListCreateRequestStateEnum as RequestStateEnum,
} from 'src/api/graphql-global-types';
import type { estimationConfigTemplateFromProductionListCreateRequest as TemplateFromProductionListCreateRequest } from 'src/api/types/estimationConfigTemplateFromProductionListCreateRequest';
import type { estimationConfigTemplateFromProductionListCreateRequestCreate as TemplateFromProductionListStatus } from 'src/api/types/estimationConfigTemplateFromProductionListCreateRequestCreate';
import type { projectManagementProductionList_projectManagementProductionList_estimateGroup_estimates_template as EstimatesTemplate } from 'src/api/types/projectManagementProductionList';
import {
  CREATE_TEMPLATES_FROM_PRODUCTION_LIST,
  GET_CREATE_TEMPLATES_FROM_PRODUCTION_LIST_REQUEST,
} from 'src/features/project/apis/graphql/queries/queries';
import {
  ToastStatusEnum,
  useMaterialListTemplates,
  useToastEhi,
  useTracking,
} from 'src/hooks';
import { useIsMobileBreakpoint } from 'src/hooks/useIsMobileBreakpoint';
import { getOrgIdParam } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

import { SaveAsTemplateOption } from './SaveAsTemplateOption';

export type TemplateOption = {
  template: EstimatesTemplate;
  isSelected: boolean;
  nameInput: string;
  updateUnitCostsInput: boolean;
  overwriteInput: boolean;
  hasError: boolean;
};

export interface SaveAsTemplateModalProps {
  estimatesTemplates: EstimatesTemplate[];
  productionListId: number;
}

const sanitizeText = (text = '') => text.trim().toLowerCase();

export function SaveAsTemplateModal({
  estimatesTemplates,
  productionListId,
}: SaveAsTemplateModalProps) {
  const orgId = useSelector(getOrgIdParam);
  const isMobile = useIsMobileBreakpoint();
  const toast = useToastEhi();
  const { allTemplatesFromSections } = useMaterialListTemplates();
  const {
    isOpen: isSaveAsTemplateModalOpen,
    onOpen: onSaveAsTemplateModalOpen,
    onClose: onSaveAsTemplateModalClose,
  } = useDisclosure();
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const [saveAsTemplateOptions, setSaveAsTemplateOptions] = useState<
    TemplateOption[]
  >([]);
  const [usedTemplateNames, setUsedTemplateNames] = useState<string[]>([]);
  const [isSavingNewTemplates, setIsSavingNewTemplates] =
    useState<boolean>(false);

  const allCustomTemplateNames = useMemo(() => {
    const customTemplates = allTemplatesFromSections.filter(
      (template) =>
        template.collectionType === TemplateCollectionTypeEnum.CUSTOM,
    );

    const cleanedCustomTemplateNames = compact(
      customTemplates.map((template) => template.name) || [],
    ).map(sanitizeText);
    return cleanedCustomTemplateNames;
  }, [allTemplatesFromSections]);

  const showErrorToast = (errorId: string) => {
    toast({
      id: errorId,
      description: `Something went wrong while saving template. HOVER team has been informed of this issue.
        If you have any questions, please reach out to our support team at support@hover.to`,
      status: ToastStatusEnum.ERROR,
    });
    typewriter.pageViewed({
      page_or_screen_name:
        EventNames.project.scope.saveAsTemplateModal.failureToastViewed,
      feature: 'material-list',
      ...commonTrackingProps,
    });
  };

  const isOrgsCustomTemplate = (template: EstimatesTemplate) => {
    return template.orgId?.toString() === orgId;
  };

  const isNameAlreadyUsed = (name: string, namesToSkip: string[] = []) => {
    const sanitizedInput = sanitizeText(name);
    // add exception to prevent empty names
    if (sanitizedInput === '') {
      return true;
    }
    const santizedNamesToSkip = namesToSkip.map(sanitizeText);

    const filteredUsedTemplateNames = usedTemplateNames.filter(
      (usedName) => !santizedNamesToSkip.includes(usedName),
    );

    return filteredUsedTemplateNames.includes(sanitizedInput);
  };

  const submitButtonIsDisabled = useMemo(() => {
    const noneSelected = saveAsTemplateOptions.every(
      (templateOption) => !templateOption.isSelected,
    );

    const someSelectedHaveErrors = saveAsTemplateOptions.some(
      (templateOption) => templateOption.isSelected && templateOption.hasError,
    );

    return noneSelected || someSelectedHaveErrors;
  }, [saveAsTemplateOptions]);

  const updateTemplateOptions = (
    templateOption: TemplateOption,
    updaterFunction: (option: TemplateOption) => TemplateOption,
  ) => {
    const updatedTemplateOptions = saveAsTemplateOptions.map((option) => {
      if (option.template.id === templateOption.template.id) {
        return updaterFunction(option);
      }
      return option;
    });
    setSaveAsTemplateOptions(updatedTemplateOptions);
  };

  const handleOptionSelect = (templateOption: TemplateOption) => {
    const namesToSkipValidation = templateOption.overwriteInput
      ? [templateOption.template?.name || '']
      : [];

    updateTemplateOptions(templateOption, (option) => ({
      ...option,
      isSelected: !option.isSelected,
      hasError: isNameAlreadyUsed(option.nameInput, namesToSkipValidation),
    }));
    typewriter.checkboxToggled({
      page_or_screen_name:
        EventNames.project.scope.saveAsTemplateModal.optionPressed,
      checked: !templateOption.isSelected,
      primary_cta: true,
      feature: 'material-list',
      trade_type: templateOption.template.tradeType?.toString() || '',
      template_id: templateOption.template.id,
      template_name: templateOption.template.name || '',
      ...commonTrackingProps,
    });
  };

  const handleNameInputChange = (
    templateOption: TemplateOption,
    nameInputValue: string,
  ) => {
    const namesToSkipValidation = templateOption.overwriteInput
      ? [templateOption.template?.name || '']
      : [];

    updateTemplateOptions(templateOption, (option) => ({
      ...option,
      nameInput: nameInputValue,
      hasError: isNameAlreadyUsed(nameInputValue, namesToSkipValidation),
    }));
    typewriter.textInput({
      page_or_screen_name:
        EventNames.project.scope.saveAsTemplateModal.nameInputChanged,
      input_value: nameInputValue,
      trade_type: templateOption.template.tradeType?.toString() || '',
      template_id: templateOption.template.id,
      ...commonTrackingProps,
    });
  };

  const handleUnitCostsInputChange = (templateOption: TemplateOption) => {
    updateTemplateOptions(templateOption, (option) => ({
      ...option,
      updateUnitCostsInput: !templateOption.updateUnitCostsInput,
    }));

    typewriter.checkboxToggled({
      page_or_screen_name:
        EventNames.project.scope.saveAsTemplateModal.unitCostInputPressed,
      checked: !templateOption.updateUnitCostsInput,
      primary_cta: false,
      feature: 'material-list',
      trade_type: templateOption.template.tradeType?.toString() || '',
      template_id: templateOption.template.id,
      template_name: templateOption.template.name || '',
      ...commonTrackingProps,
    });
  };

  const handleOverwriteTemplateInputChange = (
    templateOption: TemplateOption,
  ) => {
    const updatedNameInput = !templateOption.overwriteInput
      ? templateOption.template.name
      : templateOption.nameInput;

    const namesToSkipValidation = !templateOption.overwriteInput
      ? [templateOption.template?.name || '']
      : [];

    updateTemplateOptions(templateOption, (option) => ({
      ...option,
      overwriteInput: !templateOption.overwriteInput,
      nameInput: updatedNameInput || '',
      hasError: isNameAlreadyUsed(
        templateOption.nameInput,
        namesToSkipValidation,
      ),
    }));

    typewriter.checkboxToggled({
      page_or_screen_name:
        EventNames.project.scope.saveAsTemplateModal.overwriteInputPressed,
      checked: !templateOption.overwriteInput,
      primary_cta: false,
      feature: 'material-list',
      trade_type: templateOption.template.tradeType?.toString() || '',
      template_id: templateOption.template.id,
      template_name: templateOption.template.name || '',
      ...commonTrackingProps,
    });
  };

  const handleSaveTemplatesButtonClicked = () => {
    const selectedTemplates =
      saveAsTemplateOptions.filter(
        (templateOption) =>
          templateOption.isSelected && !templateOption.hasError,
      ) || [];

    const attributes: RequestTemplateCreateAttrs = {
      projectManagementProductionListId: productionListId.toString(),
      optionsAttributes: selectedTemplates.map((templateOption) => {
        // only overwrite if flag is enabled, the template is the org's custom template, and the user has checked the to "update template" input
        const shouldOverwriteExisting =
          isOrgsCustomTemplate(templateOption.template) &&
          templateOption.overwriteInput;

        return {
          name: templateOption.nameInput,
          saveUnitCost: templateOption.updateUnitCostsInput,
          saveInputAnswers: true,
          overwriteExisting: shouldOverwriteExisting,
          originEstimationConfigTemplateId:
            templateOption.template.id.toString(),
        };
      }),
    };

    createNewTemplates({
      variables: {
        attributes,
      },
    });
    setIsSavingNewTemplates(true);

    const saveAsTemplateTrackingDetails = {
      number_of_templates: selectedTemplates.length,
      template_names: uniq(
        selectedTemplates.map((template) => template.nameInput),
      ),
      template_trade_types: uniq(
        selectedTemplates.map(
          (template) => template.template.tradeType?.toString() || '',
        ),
      ),
    };

    typewriter.buttonPressed({
      page_or_screen_name:
        EventNames.project.scope.saveAsTemplateModal.saveButtonPressed,
      feature: 'material-list',
      primary_cta: true,
      button_text: 'Save',
      save_as_template_details: saveAsTemplateTrackingDetails,
      ...commonTrackingProps,
    });
  };

  const [createNewTemplates] = useMutation(
    CREATE_TEMPLATES_FROM_PRODUCTION_LIST,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      onError: () => {
        showErrorToast('create-new-templates-request-error');
      },
      onCompleted: ({
        estimationConfigTemplateFromProductionListCreateRequestCreate,
      }: TemplateFromProductionListStatus) => {
        const requestId =
          estimationConfigTemplateFromProductionListCreateRequestCreate
            ?.templateFromProductionListCreateRequest?.id;

        if (!requestId) {
          showErrorToast('create-new-templates-no-request-id');
          setIsSavingNewTemplates(false);
        } else {
          fetchAndPollCreateNewTemplatesRequest({
            variables: {
              id: requestId,
            },
            pollInterval: 1500,
          });
        }
      },
    },
  );

  const [
    fetchAndPollCreateNewTemplatesRequest,
    {
      data: getCreateNewTemplatesRequestData,
      error: getCreateNewTemplatesRequestError,
      stopPolling: stopPollingCreateNewTemplatesRequest,
    },
  ] = useLazyQuery<TemplateFromProductionListCreateRequest>(
    GET_CREATE_TEMPLATES_FROM_PRODUCTION_LIST_REQUEST,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      onError: () => {
        showErrorToast('poll-create-new-templates-request-error');
      },
    },
  );

  useEffect(() => {
    const requestStateStatus =
      getCreateNewTemplatesRequestData
        ?.estimationConfigTemplateFromProductionListCreateRequest?.state;

    const hasError =
      getCreateNewTemplatesRequestError ||
      requestStateStatus === RequestStateEnum.FAILED;
    const isComplete = requestStateStatus === RequestStateEnum.COMPLETE;

    if (hasError) {
      stopPollingCreateNewTemplatesRequest();
      showErrorToast('poll-create-new-templates-request-error');
      setIsSavingNewTemplates(false);
    }
    if (isComplete) {
      stopPollingCreateNewTemplatesRequest();
      const options =
        getCreateNewTemplatesRequestData
          ?.estimationConfigTemplateFromProductionListCreateRequest?.options ||
        [];
      const multipleTemplatesCreated = options.length > 1;
      toast({
        id: 'save-as-template-success-toast',
        description: `Template${multipleTemplatesCreated ? 's ' : ' '}saved`,
        status: ToastStatusEnum.SUCCESS,
        props: { 'data-testid': 'Save-As-Template-Modal-Success-Toast' },
      });
      typewriter.pageViewed({
        page_or_screen_name:
          EventNames.project.scope.saveAsTemplateModal.successToastViewed,
        feature: 'material-list',
        ...commonTrackingProps,
      });

      const addedNames = options.map((option) => sanitizeText(option.name));
      setUsedTemplateNames([...usedTemplateNames, ...addedNames]);

      setIsSavingNewTemplates(false);
      onSaveAsTemplateModalClose();
    }
  }, [
    getCreateNewTemplatesRequestData,
    getCreateNewTemplatesRequestError,
    stopPollingCreateNewTemplatesRequest,
  ]);

  useEffect(() => {
    const sanitizedCustomTemplateNames =
      allCustomTemplateNames.map(sanitizeText);
    setUsedTemplateNames(
      uniq([...sanitizedCustomTemplateNames, ...usedTemplateNames]),
    );
  }, [allCustomTemplateNames]);

  useEffect(() => {
    // when closing the modal, reset the template options
    // but only do this if we are NOT saving new templates
    // so that the user could reopen the modal and see their selections being saved
    if (!isSaveAsTemplateModalOpen && !isSavingNewTemplates) {
      const templateOptions = estimatesTemplates.map((template) => ({
        template,
        isSelected: false,
        nameInput: template.name || '',
        updateUnitCostsInput: true,
        overwriteInput: true,
        hasError: false,
      }));
      setSaveAsTemplateOptions(templateOptions);
    }

    // when opening modal, send tracking
    if (isSaveAsTemplateModalOpen) {
      typewriter.pageViewed({
        page_or_screen_name:
          EventNames.project.scope.saveAsTemplateModal.viewed,
        feature: 'material-list',
        ...commonTrackingProps,
      });
    }
  }, [isSaveAsTemplateModalOpen, estimatesTemplates, isSavingNewTemplates]);

  return (
    <>
      <Modal
        maxContentHeight="70vh"
        footer={
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignSelf="self-end">
              <Button
                data-testid="SaveAsTemplateModal-CancelButton"
                fill="minimal"
                size="large"
                onClick={() => {
                  typewriter.buttonPressed({
                    page_or_screen_name:
                      EventNames.project.scope.saveAsTemplateModal
                        .cancelButtonPressed,
                    feature: 'material-list',
                    primary_cta: false,
                    button_text: 'Cancel',
                    ...commonTrackingProps,
                  });
                  onSaveAsTemplateModalClose();
                }}
              >
                {isSavingNewTemplates ? 'Close' : 'Cancel'}
              </Button>
              <Button
                data-testid="SaveAsTemplateModal-SaveButton"
                size="large"
                isDisabled={submitButtonIsDisabled}
                onClick={handleSaveTemplatesButtonClicked}
                marginLeft={400}
                backgroundColor="neutral.900"
              >
                {isSavingNewTemplates ? 'Saving...' : 'Save'}
              </Button>
            </Box>
          </Box>
        }
        header="Save as template"
        isOpen={isSaveAsTemplateModalOpen}
        onClose={() => {
          typewriter.buttonPressed({
            page_or_screen_name:
              EventNames.project.scope.saveAsTemplateModal.cancelButtonPressed,
            feature: 'material-list',
            primary_cta: false,
            button_text: 'x',
            ...commonTrackingProps,
          });
          onSaveAsTemplateModalClose();
        }}
        size="medium"
      >
        <div data-testid="SaveAsTemplateModal-Content">
          {saveAsTemplateOptions.map((templateOption) => {
            const showOverwriteTemplateCheckbox = isOrgsCustomTemplate(
              templateOption.template,
            );

            return (
              <SaveAsTemplateOption
                key={`save-as-template-${templateOption.template.name}`}
                templateOption={templateOption}
                onOptionSelect={handleOptionSelect}
                onNameInputChange={handleNameInputChange}
                onUnitCostsInputChange={handleUnitCostsInputChange}
                onOverwriteTemplateInputChange={
                  handleOverwriteTemplateInputChange
                }
                disabled={isSavingNewTemplates}
                showOverwriteTemplateCheckbox={showOverwriteTemplateCheckbox}
              />
            );
          })}
          <Body color="neutral.500" fontWeight="400" marginBottom={0}>
            This is a Beta Feature.{' '}
            <Link
              href="https://help.hover.to/en/articles/8113707-save-a-material-list"
              isExternal
              color="neutral.500"
              textDecoration="underline"
              fontWeight="400"
            >
              Learn more.
            </Link>
          </Body>
          <Body
            color="neutral.500"
            fontWeight="400"
            margin={0}
            visibility={isSavingNewTemplates ? 'visible' : 'hidden'}
          >
            You can close this and won&apos;t lose anything. We&apos;ll notify
            when done.
          </Body>
        </div>
      </Modal>

      <Button
        padding={isMobile ? 0 : undefined}
        textColor={isMobile ? 'neutral.700' : undefined}
        textDecoration={isMobile ? 'underline' : undefined}
        onClick={() => {
          onSaveAsTemplateModalOpen();
          typewriter.buttonPressed({
            page_or_screen_name: EventNames.project.scope.saveAsTemplatePressed,
            primary_cta: true,
            feature: 'material-list',
            button_text: 'Save as template',
            ...commonTrackingProps,
          });
        }}
        fill="minimal"
        data-testid="ProjectScope-Actions-SaveAsTemplate"
      >
        Save as template
      </Button>
    </>
  );
}
