import React from 'react';

import { Loader, VStack } from '@hover/blueprint';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';

import {
  useInitEstimator,
  useTemplateStore,
} from 'src/features/projectEstimator/hooks';

import { HdfErrorScreen } from './HdfErrorScreen';
import { RequiredInputsErrorScreen } from './RequiredInputsErrorScreen';

export interface RouteConfigRoute {
  path: string;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any; // TODO @nickcobbett
  routes?: RouteConfigRoute[];
}

interface OwnProps {
  routes: RouteConfigRoute[];
  padding: number;
}

export const EstimatorContainer: React.FC<OwnProps & RouteComponentProps> = ({
  routes,
  match,
  padding,
}) => {
  const { isLoading, hasEverything, getRequiredInputsError, loadingHdfError } =
    useInitEstimator();
  const history = useHistory();
  const pages = useTemplateStore((state) => state.pages);
  const location = useLocation();
  const showLoader = isLoading || !hasEverything || !pages.length;

  const goBack = () => {
    history.goBack();
  };

  if (getRequiredInputsError) {
    return <RequiredInputsErrorScreen goBack={goBack} />;
  }
  if (loadingHdfError) {
    return <HdfErrorScreen goBack={goBack} />;
  }
  if (showLoader) {
    return <Loader />;
  }

  return (
    <VStack width="100%">
      <Switch location={location}>
        <Route exact path="/project_estimator/questions">
          <Redirect to={`${pages[0].url}${location.search}`} />
        </Route>
        {routes.map((route) => (
          <Route
            path={`${match.path}${route.path}`}
            key={route.path}
            render={(props) => (
              <route.component
                {...props}
                routes={route.routes}
                padding={padding}
              />
            )}
          />
        ))}
      </Switch>
    </VStack>
  );
};
