import numeral from 'numeral';

interface Props {
  className?: string;
  value: number | number;
  format?: string;
  suffix?: string;
  'data-testid'?: string;
}

// TODO: ternary operator in numeral() because of numeral library bug
// remove when fixed
export const FormattedNumber: React.FC<Props> = ({
  className,
  value,
  format,
  suffix,
  ...rest
}) => (
  <span className={className} data-testid={rest['data-testid']}>
    {numeral(value >= 0.000001 ? value : 0).format(format || '0,0[.]00')}
    {suffix}
  </span>
);
