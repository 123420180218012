import { useEffect } from 'react';

import { Box, Button, Icon, Heading } from '@hover/blueprint';
import { iArrowLeft } from '@hover/icons';
import { isNil } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { EstimatorResponsiveWrapper } from 'src/features/exteriorEstimator/components/common/EstimatorResponsiveWrapper';
import { getJobDetails } from 'src/features/exteriorEstimator/redux/sagas/selectors';

export const SummaryHeader = () => {
  const history = useHistory();
  const jobDetails = useSelector(getJobDetails);

  useEffect(() => {
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({ action: 'hideHeader' }),
    );
    return () =>
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({ action: 'showHeader' }),
      );
  });

  const goBack = () => {
    history.push(history.location.state?.prevPath);
  };

  return (
    <Box
      position="relative"
      width={1}
      flexDirection="column"
      marginBottom={100}
      data-testid="summaryHeader"
      boxShadow="distance500"
    >
      <EstimatorResponsiveWrapper padding={0}>
        <Box
          paddingX={400}
          paddingBottom={100}
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          width={1}
        >
          <Box flex={1} display={isNil(jobDetails) ? 'none' : 'flex'}>
            <Button onClick={goBack} fill="minimal" shape="circle" label="Back">
              <Icon color="neutral600" icon={iArrowLeft} size="medium" />
            </Button>
          </Box>
          <Box
            marginX={50}
            marginY={400}
            flexDirection="column"
            alignItems="center"
          >
            <Heading
              color="primary900"
              size={300}
              textAlign="center"
              data-testid="summaryJobName"
            >
              <Box justifyContent="center">
                <Box
                  display="block"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  maxWidth="25ch"
                  whiteSpace="nowrap"
                >{`${jobDetails?.name || ''}`}</Box>
              </Box>
              <Box justifyContent="center">Estimate Inputs Summary</Box>
            </Heading>
          </Box>
          {/* empty div for flex */}
          <Box flex={1} display={isNil(jobDetails) ? 'none' : 'flex'} />
        </Box>
      </EstimatorResponsiveWrapper>
    </Box>
  );
};
