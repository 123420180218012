import { gql } from '@apollo/client';

export const UPDATE_LINE_ITEM = gql`
  mutation EstimationConfigLineItemUpdate(
    $id: ID!
    $lineItemAttributes: EstimationConfigLineItemUpdateInput!
  ) {
    estimationConfigLineItemUpdate(
      id: $id
      lineItemAttributes: $lineItemAttributes
    ) {
      lineItem {
        id
        margin
        name
        salesTax
        updatedAt
        unitCost
        quantityUnits
        type
      }
      errors {
        attribute
        errors
      }
    }
  }
`;

export const GET_LINE_ITEMS = gql`
  query estimationConfigLineItemsSettings(
    $orgId: ID!
    $after: String
    $before: String
    $search: String
    $first: Int
    $last: Int
    $active: Boolean
  ) {
    estimationConfigLineItems(
      orgId: $orgId
      after: $after
      before: $before
      search: $search
      first: $first
      last: $last
      active: $active
    ) {
      nodes {
        id
        type
        name
        unitCost
        quantityUnits
        updatedAt
        tradeTypes
        updatedAt
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        endCursor
        startCursor
      }
    }
  }
`;

export const GET_LINE_ITEM_ACTIVITY_HISTORY = gql`
  query estimationConfigLineItemVersions($lineItemId: ID!, $after: String) {
    estimationConfigLineItemVersions(
      lineItemId: $lineItemId
      after: $after
      first: 20
    ) {
      edges {
        node {
          id
          createdAt
          updatedByUserName
          attributeChanges {
            attributeName
            oldValue
            newValue
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        endCursor
        startCursor
      }
    }
  }
`;
