import { PureComponent } from 'react';

import { Body, Box, Button, Heading } from '@hover/blueprint';

interface Props {
  title: string;
  text: string;
  actionColor: string;
  handleAction: () => void;
  actionText: string;
  handleCancel: () => void;
  cancelText: string;
}

export class Modal extends PureComponent<Props> {
  public render() {
    const {
      title,
      text,
      actionText,
      cancelText,
      actionColor,
      handleAction,
      handleCancel,
    } = this.props;
    return (
      <>
        <Heading size={400} color="neutral.700" data-testid="UIComponent-title">
          {title}
        </Heading>
        <Body size={400} color="neutral.600">
          {text}
        </Body>
        <Box flexDirection="column">
          <Button
            color={actionColor === 'borderError' ? 'danger' : 'success'}
            data-testid="UIComponent-action-button"
            onClick={handleAction}
            marginBottom={400}
          >
            {actionText}
          </Button>
          <Button
            fill="outline"
            data-testid="UIComponent-cancel-button"
            onClick={handleCancel}
          >
            {cancelText}
          </Button>
        </Box>
      </>
    );
  }
}
