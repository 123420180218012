import { useEffect } from 'react';

import { Alert, Box, Button } from '@hover/blueprint';

import { useTracking } from 'src/hooks';
import { EventNames } from 'src/types/actionTypes';

interface Props {
  setClose: () => void;
  isOpen: boolean;
}

export const CaptureOnlyAlert: React.FC<Props> = ({ setClose, isOpen }) => {
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const handleClick = () => {
    setClose();
    typewriter.buttonPressed({
      page_or_screen_name:
        EventNames.project.scope.materialListJobUpgradeAlertPressed,
      button_text: 'Got it',
      primary_cta: false,
      feature: 'material-list',
      ...commonTrackingProps,
    });
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    typewriter.modalViewed({
      page_or_screen_name:
        EventNames.project.scope.materialListJobUpgradeAlertViewed,
      name: 'measurements are not ready',
      feature: 'material-list',
      ...commonTrackingProps,
    });
    // Added this rule for avoiding the unnecesssary re-rendering of the component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <Alert
          width="fit-content"
          status="warning"
          position="fixed"
          bottom={900}
          left="50%"
          transform="translateX(-50%)"
          zIndex={1}
        >
          <Box display="flex" gap={400} flexDirection="row" alignItems="center">
            To access measurements, upgrade the job. After upgrading, edit scope
            to generate a Material List.
            <Button onClick={handleClick} fill="outline">
              Got it
            </Button>
          </Box>
        </Alert>
      )}
    </>
  );
};
