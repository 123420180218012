import React from 'react';

import { Box, Body, Heading, Panel } from '@hover/blueprint';
import numeral from 'numeral';

import { EstimateGroupForInputSummary_estimationEstimateGroup as EstimateGroup } from 'src/api/types/EstimateGroupForInputSummary';

interface Props {
  estimateGroup: EstimateGroup | null;
}

export const ProfitMargin: React.FC<Props> = ({ estimateGroup }) => {
  const originalProfitMargin = estimateGroup?.profitMargin;
  if (!originalProfitMargin) return null;
  return (
    <Box flexDirection="column" width={1} testId="ProfitMargin">
      <Panel backgroundColor="neutral100" borderRadius="0">
        <Heading size={400}>Profit Margin</Heading>
      </Panel>
      <Box flexDirection="column" paddingBottom={600}>
        <Box flexDirection="column">
          <Box justifyContent="space-between">
            <Body size={400} marginBottom={0}>
              Current margin
            </Body>
            <Body size={400} marginBottom={0}>
              {numeral(originalProfitMargin).format('0,0[.]00')}%
            </Body>
          </Box>
          <Body size={400} color="neutral500">
            Promotion and additional upgrades included
          </Body>
        </Box>
      </Box>
    </Box>
  );
};
