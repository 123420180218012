import { memo } from 'react';

import {
  Box,
  Link,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  Hide,
} from '@hover/blueprint';
import { useDispatch } from 'react-redux';

import { LineItemTypeEnum, TradeTypeEnum } from 'src/api/graphql-global-types';
import { estimationQuantityUnits_estimationQuantityUnits as estimationQuantityUnit } from 'src/api/types/estimationQuantityUnits';
import type { productCatalogConfigOrgDistributors_productCatalogConfigOrgDistributors as Distributor } from 'src/api/types/productCatalogConfigOrgDistributors';
import { projectManagementProductionList_projectManagementProductionList_listItems as ListItem } from 'src/api/types/projectManagementProductionList';
import {
  toggleSelectAllTradeItems,
  removeLineItem,
} from 'src/features/project/redux/actions';
import { byDistributionOrdersSortOrderReverseCreatedAtComparator } from 'src/features/project/util/utils';
import { useIsMobileBreakpoint } from 'src/hooks/useIsMobileBreakpoint';
import { sentenceCase } from 'src/utils/Formatters';

import { ProductionListLineItemMemoized } from '../ProductionListLineItem';

type Props = {
  jobId: number;
  orgId: string;
  lineItemType: LineItemTypeEnum;
  tradeType: TradeTypeEnum;
  selectedItemsIds: string[] | null;
  items: ListItem[];
  productionListId: number;
  distributors?: Distributor[];
  quantityUnits: estimationQuantityUnit[];
};

export const ProjectScopeTradeList = ({
  orgId,
  jobId,
  lineItemType,
  tradeType,
  selectedItemsIds,
  items,
  productionListId,
  distributors,
  quantityUnits,
}: Props) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobileBreakpoint();

  const handleToggleTypeSelection = () => {
    dispatch(toggleSelectAllTradeItems(lineItemType, tradeType, items));
  };

  const handleLineItemDelete = (listItem: ListItem) => {
    dispatch(removeLineItem(lineItemType, tradeType, listItem.id));
  };

  return (
    <Box>
      {/* Anchor link for direct access via section tags */}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link
        id={`${lineItemType}.${tradeType}`}
        sx={{
          top: '-80px',
          display: 'block',
          position: 'relative',
          visibility: 'hidden',
        }}
      />
      <Box
        className="tradeTypeContainer"
        key={tradeType}
        _notFirst={{ marginTop: 300 }}
        flexDirection="column"
      >
        <Table
          size="small"
          sx={{ border: 'none' }}
          mb={{ base: 400, tablet: 0 }}
        >
          <Tbody sx={{ border: 'none' }}>
            <Tr
              data-testid={`ListItemTradeRow-${tradeType}`}
              verticalAlign="baseline"
            >
              {!isMobile && (
                <Td sx={{ width: '48px' }} paddingBottom={0}>
                  <Checkbox
                    value={`${lineItemType}:${tradeType}`}
                    aria-label={tradeType}
                    id={tradeType}
                    key={tradeType}
                    data-testid="checkboxMaterialItemGroup"
                    flex="0"
                    isChecked={
                      (selectedItemsIds &&
                        selectedItemsIds.length === items.length) ||
                      false
                    }
                    isIndeterminate={
                      (selectedItemsIds &&
                        selectedItemsIds.length > 0 &&
                        selectedItemsIds.length < items.length) ||
                      false
                    }
                    onChange={handleToggleTypeSelection}
                  />
                </Td>
              )}

              <Td
                fontWeight="bold"
                fontSize={400}
                paddingBottom={0}
                paddingLeft={{ base: '0 !important', tablet: 300 }}
              >
                {sentenceCase(tradeType)}
              </Td>
            </Tr>
          </Tbody>
        </Table>

        <Table size="small" sx={{ tableLayout: 'fixed' }} marginBottom={500}>
          <Hide below="tablet">
            <Thead>
              <Tr>
                <Hide below="tablet">
                  <Th sx={{ width: '80px' }} paddingLeft={700} />
                </Hide>

                <Th>Name</Th>
                {lineItemType === LineItemTypeEnum.MATERIAL && (
                  <Th>Color/Variation</Th>
                )}
                <Th sx={{ width: '10%' }}>Measurements</Th>

                <>
                  <Th sx={{ width: '6%' }}>Quantity</Th>
                  <Th sx={{ width: '6%' }}>Unit</Th>
                  <Th isNumeric sx={{ width: '7%' }}>
                    Unit Cost ($)
                  </Th>
                </>

                <Th isNumeric sx={{ width: '8%' }}>
                  Total ($)
                </Th>
                <Th sx={{ width: '12%' }} paddingLeft={700}>
                  Orders
                </Th>
                <Th sx={{ width: '80px' }} />
              </Tr>
            </Thead>
          </Hide>
          <Tbody data-testid="ListItemTable" borderBottom={0}>
            {items
              .sort(byDistributionOrdersSortOrderReverseCreatedAtComparator)
              .map((listItem: ListItem) => (
                <ProductionListLineItemMemoized
                  key={listItem.id}
                  lineItemType={lineItemType}
                  tradeType={tradeType}
                  jobId={jobId}
                  orgId={orgId}
                  listItem={listItem}
                  selected={
                    (selectedItemsIds &&
                      selectedItemsIds.includes(listItem.id.toString())) ||
                    false
                  }
                  distributors={distributors}
                  productionListId={productionListId}
                  quantityUnits={quantityUnits}
                  onDelete={handleLineItemDelete}
                  isMobile={isMobile}
                />
              ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export const ProjectScopeTradeListMemoized = memo(ProjectScopeTradeList);
