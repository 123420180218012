import { useCallback } from 'react';

import { Box, Heading, Body } from '@hover/blueprint';
import { truncate } from 'lodash';
import { useSelector } from 'react-redux';

import {
  getOrgSettings,
  getParentOrg,
  getIsOrgAdmin,
  combineSubOrgsWithUserOrgs,
  getIsJobManager,
  getMaterialListFeature,
  getOrderingOnlyFeatureAccess,
} from 'src/redux/selectors';
import { Dropdown } from 'style-guide';

import { SearchBar } from './SearchBar';

type Props = {
  setSelectedOrgId: React.Dispatch<React.SetStateAction<number>>;
  setSearchQuery: React.Dispatch<React.SetStateAction<string | null>>;
};

export const EstimatesHeader: React.FC<Props> = ({
  setSelectedOrgId,
  setSearchQuery,
}) => {
  const { showPricesInEstimation } = useSelector(getOrgSettings) ?? {};
  const orgs = useSelector(combineSubOrgsWithUserOrgs);
  const parentOrg = useSelector(getParentOrg);
  const isOrgAdmin = useSelector(getIsOrgAdmin);
  const isJobManager = useSelector(getIsJobManager);
  const showOrderingFlow = useSelector(getOrgSettings)?.showOrderingFlow;
  const showOrderingVersion =
    useSelector(getOrderingOnlyFeatureAccess) && showOrderingFlow;
  const materialListFeatureEnabled = useSelector(getMaterialListFeature);
  const showOrderingOrMaterialListVersion =
    showOrderingVersion || materialListFeatureEnabled;

  const total = showPricesInEstimation ? (
    <Body
      color="neutral.400"
      gridColumn={5}
      fontSize={100}
      display="flex"
      justifyContent="flex-end"
      as="span"
    >
      TOTAL
    </Body>
  ) : null;

  const dropdownOptions = orgs.map((org) => ({
    value: org?.id ?? '',
    displayValue: truncate(org?.name || '', { length: 25 }),
  }));

  const handleSelect = useCallback(
    (e) => {
      const orgId = Number(e.target.value);
      setSelectedOrgId(orgId);
    },
    [setSelectedOrgId],
  );

  const dropdown = (
    <Box
      gridColumn="7 / span end"
      alignItems="center"
      justifyContent="flex-end"
      data-testid="dropdown-wrapper"
    >
      <Body size={400} marginY={0} marginRight={300}>
        Company
      </Body>
      <Box flex={1} maxWidth={250}>
        <Dropdown
          data-testid="suborgs-select"
          onChange={handleSelect}
          options={dropdownOptions}
          defaultValue={parentOrg?.id?.toString() ?? undefined}
        />
      </Box>
    </Box>
  );

  const showDropdown = orgs.length > 1 && (isOrgAdmin || isJobManager);

  return (
    <Box
      gridColumnStart="col1"
      gridColumnEnd="end"
      display="unset"
      paddingTop="29px"
      borderBottom="1px solid"
      borderBottomColor="neutral.200"
    >
      <Box
        maxWidth="1280px"
        display="grid"
        gridTemplateColumns="[col1] 11% [col2] 12% [col3] 15% [col4] 10% [col5] 7% [col6] 3% [col7] 1fr [col8] 3%"
        gridColumnGap={300}
        gridRowGap={300}
      >
        <Heading
          data-testid="mainHeader"
          size={500}
          fontWeight={500}
          color="neutral.700"
        >
          Projects List
        </Heading>
        <Box gridColumn="3 / span 3" flexDirection="column">
          <SearchBar setSearchQuery={setSearchQuery} />
        </Box>
        {showDropdown ? dropdown : <></>}
      </Box>
      <Box
        gridArea="header"
        gridRowStart={2}
        paddingTop={500}
        maxWidth="1280px"
        display="grid"
        gridTemplateColumns="[col1] 8% [col2] 15% [col3] 15% [col4] 10% [col5] 7% [col6] 3% [col7] 1fr [col8] 3%"
        gridColumnGap={300}
        gridRowGap={300}
      >
        <Body
          color="neutral.400"
          gridColumn={1}
          fontSize={100}
          paddingLeft={200}
          as="span"
        >
          DATE
        </Body>
        <Body color="neutral.400" gridColumn={2} fontSize={100} as="span">
          PROJECT
        </Body>
        <Body color="neutral.400" gridColumn={3} fontSize={100} as="span">
          TRADE
        </Body>
        <Body color="neutral.400" gridColumn={4} fontSize={100} as="span">
          CREATED BY
        </Body>
        {total}
        {!showOrderingOrMaterialListVersion && (
          <Body color="neutral.400" gridColumn={6} fontSize={100} as="span">
            STATUS
          </Body>
        )}
      </Box>
    </Box>
  );
};
