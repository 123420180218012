import { PureComponent } from 'react';

import { Body, Box, Button, Heading, Icon } from '@hover/blueprint';
import { iEdit2, iPlus, iTrash } from '@hover/icons';
import autobind from 'autobind-decorator';
import { NumericFormat } from 'react-number-format';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { createDiscount_estimationDiscountCreate_discount as Discount } from 'src/api/types/createDiscount';
import { withTypewriter } from 'src/components/WithTypewriter';
import { CreatePromotionModal } from 'src/features/exteriorEstimator/components/EstimationTool/EstimateGroupDetail/Promotion/CreatePromotionModal';
import { EditPromotionModal } from 'src/features/exteriorEstimator/components/EstimationTool/EstimateGroupDetail/Promotion/EditPromotionModal';
import { RemovePromotionModal } from 'src/features/exteriorEstimator/components/EstimationTool/EstimateGroupDetail/Promotion/RemovePromotionModal';
import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import {
  getParams,
  getEstimationGroupEstimate,
  getActiveEstimateDiscounts,
  getIsEstimateGroupSold,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import {
  calculateDollars,
  calculatePercentage,
} from 'src/features/exteriorEstimator/utils/discountsUtils';
import {
  isUserLightWeightFlow,
  getUserTrackingProps,
} from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { RootState, RootAction } from 'src/types/reduxStore';

export const mapStateToProps = (state: RootState) => ({
  estimateGroup: state.exteriorEstimator.estimateGroup,
  discounts: getActiveEstimateDiscounts(state),
  getEstimate: (estimationEstimateId: number) =>
    getEstimationGroupEstimate(state, estimationEstimateId),
  isLightWeightFlow: isUserLightWeightFlow(state),
  estimateGroupSold: getIsEstimateGroupSold(state),
  estimateGroupId: getParams(state).estimateGroupId,
  jobId: getParams(state).jobId,
  commonProps: getUserTrackingProps(state),
});

export const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      setSelectedPromotionId: estimatorActions.setSelectedPromotionId,
    },
    dispatch,
  );

interface IProps {
  total: number;
  typewriter: any;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IProps;

interface State {
  showAddPromoModal: boolean;
  showEditPromoModal: boolean;
  showRemovePromoWarning: boolean;
}

export class PromotionComponent extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showAddPromoModal: false,
      showEditPromoModal: false,
      showRemovePromoWarning: false,
    };
  }

  @autobind
  setShowCreatePromotionModal(show: boolean) {
    this.setState({
      showAddPromoModal: show,
    });
  }

  @autobind
  setShowEditPromotionModal(show: boolean) {
    this.setState({
      showEditPromoModal: show,
    });
  }

  @autobind
  setShowDeletePromotionModal(show: boolean) {
    this.setState({
      showRemovePromoWarning: show,
    });
  }

  @autobind
  handleAddPromotionClick() {
    const { commonProps, jobId, estimateGroupSold, typewriter } = this.props;
    if (estimateGroupSold) return;
    this.setShowCreatePromotionModal(true);

    typewriter.buttonPressed({
      button_text: `Add Promotion`,
      primary_cta: false,
      page_or_screen_name: EventNames.estimator.estimateDetailsScreen.page,
      job_id: Number(jobId),
      ...commonProps,
    });
  }

  @autobind
  handleEditIconClick(promotionId: number) {
    const { setSelectedPromotionId, estimateGroupSold } = this.props;
    if (estimateGroupSold) return;

    setSelectedPromotionId({ promotionId });
    this.setShowEditPromotionModal(true);
  }

  @autobind
  handleRemovePromoClick(promotionId: number) {
    const { setSelectedPromotionId, estimateGroupSold } = this.props;
    if (estimateGroupSold) return;

    setSelectedPromotionId({ promotionId });
    this.setShowDeletePromotionModal(true);
  }

  @autobind
  getEstimateNameBy(estimationEstimateId: number) {
    const { getEstimate } = this.props;
    return estimationEstimateId && getEstimate
      ? getEstimate(estimationEstimateId)?.name
      : '';
  }

  @autobind
  getEstimateBy(estimationEstimateId: number) {
    const { getEstimate } = this.props;
    return estimationEstimateId ? getEstimate(estimationEstimateId) : null;
  }

  @autobind
  renderDiscount(discount: Discount) {
    const { estimateGroupSold } = this.props;

    const { id, name, value, discountType, estimationEstimateId } = discount;
    const estimate = this.getEstimateBy(estimationEstimateId);

    const showPricesByTemplate = !!estimate?.template?.showPricesInEstimation;
    const estimateTotal = estimate?.basePrice ?? 0;

    return (
      <Box
        width={1}
        justifyContent="space-between"
        key={id}
        data-testid={`discountRow-${id}`}
        marginY={300}
      >
        <Box
          alignItems="center"
          flexWrap="wrap"
          justifyContent="space-between"
          width={1}
        >
          <Box
            maxWidth="70%"
            marginRight={100}
            justifyContent="center"
            data-testid={`promoName-${id}`}
          >
            <Body as="span" marginTop={100}>
              {name}
            </Body>
            <Button
              shape="square"
              fill="minimal"
              margin={0}
              isDisabled={estimateGroupSold ?? false}
              onClick={() => this.handleEditIconClick(id)}
              data-testid={`promotionsV2EditButton-${id}`}
              label="faPencilIcon"
            >
              <Icon icon={iEdit2} color="neutral.400" />
            </Button>
          </Box>
          <Box alignItems="center">
            <Body as="span">
              {showPricesByTemplate && (
                <NumericFormat
                  value={calculateDollars({
                    discountType,
                    value,
                    total: estimateTotal,
                  })}
                  decimalScale={2}
                  displayType="text"
                  prefix="- $"
                  thousandSeparator
                  data-testid={`discountAmount-${id}`}
                />
              )}
            </Body>
            <Button
              shape="square"
              fill="minimal"
              margin={0}
              isDisabled={estimateGroupSold ?? false}
              onClick={() => this.handleRemovePromoClick(id)}
              data-testid={`promotionsV2DestroyButton-${id}`}
              label="faPencilIcon"
            >
              <Icon icon={iTrash} color="neutral.400" />
            </Button>
          </Box>
          <Box flexBasis="100%" height={0} />
          <Body
            as="span"
            size={300}
            color="neutral.500"
            data-testid="promoSubtext"
          >
            <NumericFormat
              value={calculatePercentage({
                discountType,
                value,
                total: estimateTotal,
              })}
              decimalScale={2}
              suffix="% "
              displayType="text"
              thousandSeparator
              data-testid={`discountPercent-${id}`}
            />
            <span>off of {this.getEstimateNameBy(estimationEstimateId)}</span>
          </Body>
        </Box>
      </Box>
    );
  }

  public renderPromo() {
    const { total, estimateGroupSold, discounts: propDiscounts } = this.props;

    const { showAddPromoModal, showEditPromoModal, showRemovePromoWarning } =
      this.state;
    const discounts: {
      [id: number]: Discount;
    } = propDiscounts;
    const discountCount =
      Object.keys(discounts).length > 0
        ? `(${Object.keys(discounts).length})`
        : '';

    return (
      <Box
        data-testid="estimate-detail-promotions"
        paddingBottom={400}
        paddingX={400}
      >
        {showAddPromoModal && (
          <CreatePromotionModal
            estimateTotal={total}
            closeModal={() => this.setShowCreatePromotionModal(false)}
          />
        )}
        {showEditPromoModal && (
          <EditPromotionModal
            estimateTotal={total}
            closeModal={() => this.setShowEditPromotionModal(false)}
          />
        )}
        {showRemovePromoWarning && (
          <RemovePromotionModal
            closeModal={() => this.setShowDeletePromotionModal(false)}
            total={total}
          />
        )}
        <Box width={1} marginTop={600} flexDirection="column">
          <Box justifyContent="space-between" alignItems="center">
            <Heading size={300}>{`Promotion ${discountCount}`}</Heading>
            <Button
              shape="square"
              fill="minimal"
              isDisabled={estimateGroupSold ?? false}
              onClick={this.handleAddPromotionClick}
              data-testid="promotionsAddButton"
              label="faPlusIcon"
            >
              <Icon icon={iPlus} color="primary.600" />
            </Button>
          </Box>
          {Object.values(discounts).map((discount) =>
            this.renderDiscount(discount),
          )}
        </Box>
      </Box>
    );
  }

  render() {
    const { isLightWeightFlow } = this.props;
    return !isLightWeightFlow ? this.renderPromo() : <></>;
  }
}

export const Promotion = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTypewriter(PromotionComponent));
