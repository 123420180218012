import { Box, Body, Label, TextInput } from '@hover/blueprint';
import { useSelector } from 'react-redux';

import { getQuestionResponses } from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { JobMeasurements } from 'src/features/exteriorEstimator/types/jobMeasurements';

import { Props } from './AdjustSidingMeasurement';

export const SidingMeasurements: React.FC<Props> = ({
  jobMeasurements,
  hdfMeasurements,
  questions,
}) => {
  const questionResponses = useSelector(getQuestionResponses);

  const measurements = hdfMeasurements || jobMeasurements;

  return (
    <Box
      paddingTop={400}
      flexDirection="column"
      data-testid="sidingMeasurementsUneditable"
    >
      <Box justifyContent="center" marginY={300}>
        <Body color="neutral.500" textTransform="uppercase" margin={0}>
          CURRENT NON-SIDING FACE TOTALS
        </Body>
      </Box>
      {questions.map(({ argument, question, id }) => {
        let measurementValue = argument
          ? measurements?.[argument as keyof JobMeasurements]
          : null;

        if (
          argument === 'brick_with_openings_area' ||
          argument === 'stone_with_openings_area' ||
          argument === 'stone_with_openings_area_measurement'
        ) {
          measurementValue = questionResponses[id] as number;
        }

        return measurementValue ||
          argument === 'brick_with_openings_area' ||
          argument === 'stone_with_openings_area' ||
          argument === 'stone_with_openings_area_measurement' ? (
          <Label
            box
            data-testid="questionContainer"
            justifyContent="space-between"
            gap={400}
            alignItems="center"
            key={id}
          >
            <Body as="span" marginY={300} flex={1} data-testid="questionText">
              {question} (SQ)
            </Body>
            <TextInput
              readOnly
              size="small"
              width="90px"
              disabled
              value={Number(measurementValue) / 100}
            />
          </Label>
        ) : null;
      })}
    </Box>
  );
};
