import { Input, Td } from '@hover/blueprint';
import { get } from 'lodash';
import { useFormContext } from 'react-hook-form';

import { projectManagementProductionList_projectManagementProductionList_listItems as ListItem } from 'src/api/types/projectManagementProductionList';
import { usePrevious } from 'src/hooks/usePrevious';

import { useProjectScopeTracker } from '../../hooks/useProjectScopeTracker';

type ListItemUnitCostColumnProps = {
  listItem: ListItem;
  isDisabled: boolean;
  jobId: number;
  onUpdate: (inputLabel: string) => void;
};

export const ListItemUnitCostColumn = ({
  listItem,
  jobId,
  onUpdate,
}: ListItemUnitCostColumnProps) => {
  const errorMessage = 'Unit cost is invalid';
  const { trackInlineEditingInputPressed } = useProjectScopeTracker({ jobId });

  const {
    setValue,
    register,
    trigger,
    formState: { errors: formErrors },
  } = useFormContext();
  const prevUnitCost = usePrevious(listItem.unitCost);

  return (
    <Td
      isNumeric
      paddingY={{ base: 100, tablet: 500 }}
      paddingLeft={{ base: 0, tablet: 400 }}
      verticalAlign="top"
      flexBasis="50%"
    >
      <Input
        borderColor="neutral.500"
        textAlign="end"
        size="small"
        borderRadius="6px"
        isRequired
        isInvalid={!!get(formErrors, 'unitCost')}
        type="text"
        data-testid="EditMaterialUnitCost"
        {...register('unitCost', {
          valueAsNumber: true,
          min: { value: 0, message: errorMessage },
          required: errorMessage,
          validate: {
            numberValidator: (unitCostValue) => {
              return Number.isFinite(unitCostValue) && unitCostValue >= 0
                ? true
                : errorMessage;
            },
          },
        })}
        onChange={() => {
          trackInlineEditingInputPressed('Unit Cost');
        }}
        onKeyDown={(event) => {
          const targetElement = event.target as HTMLInputElement;
          if (event.key === 'Enter' && targetElement?.value.length > 0) {
            targetElement.blur();
          }
        }}
        onBlur={async (event) => {
          // Set the new value based on the blur event, then trigger validation and wait for completion.
          const targetElement = event.target as HTMLInputElement;
          const newValue = parseFloat(targetElement.value);

          setValue('unitCost', newValue.toFixed(2));
          await trigger('unitCost');

          // If new value has no errors, update the value. Else, revert it.
          if (!get(formErrors, 'unitCost')) {
            onUpdate('Unit Cost');
          } else {
            setValue(
              'unitCost',
              (prevUnitCost as unknown as number)?.toFixed(2),
            );
          }
        }}
      />
    </Td>
  );
};
