import { useQuery } from '@apollo/client';
import {
  Body,
  Box,
  Heading,
  IconButton,
  LoadingOverlay,
  Panel,
} from '@hover/blueprint';
import { iChevronRight, iDownload } from '@hover/icons';
import { useHistory, useLocation } from 'react-router-dom';

import type { inspectionChecklists_inspectionChecklists_nodes as InspectionChecklist } from 'src/api/types/inspectionChecklists';
import { GET_INSPECTION_CHECKLISTS } from 'src/features/exteriorEstimator/apis/queries';
import { GET_JOB } from 'src/features/exteriorEstimator/apis/queries/job';
import { EstimatorResponsiveWrapper } from 'src/features/exteriorEstimator/components/common/EstimatorResponsiveWrapper';
import { downloadPdf } from 'src/lib/download';

export const InspectionChecklists = () => {
  const history = useHistory();
  const {
    search,
    query: { orgId, jobId },
  } = useLocation();

  const { data: checklists, loading: checklistsLoading } = useQuery(
    GET_INSPECTION_CHECKLISTS,
    {
      variables: { jobId, orgId },
    },
  );

  const { data: job, loading: jobLoading } = useQuery(GET_JOB, {
    variables: { ids: [Number(jobId)] },
  });

  const inspectionChecklists: InspectionChecklist[] =
    checklists?.inspectionChecklists?.nodes;
  const address = job?.jobs?.results[0]?.locationLine1;

  const hasPdf = (checklist: InspectionChecklist) =>
    checklist?.reportPdf?.redirectUrl && checklist?.reportPdf?.filename;

  const viewChecklist = (id: InspectionChecklist['id']) =>
    history.push({
      pathname: `/estimator/inspection_checklists/${id}`,
      search,
    });

  return (
    <Box flexDirection="column">
      <LoadingOverlay isLoading={checklistsLoading || jobLoading} />
      <Box display="block" width={1} height={500} zIndex={1}>
        <Box
          height={500}
          borderBottomWidth="1px"
          borderBottomColor="neutral400"
          alignItems="center"
          justifyContent="center"
          boxShadow="distance200"
        >
          <Body>{address ?? ''} Property Checklist</Body>
        </Box>
      </Box>
      <EstimatorResponsiveWrapper>
        <Heading size={300}>Checklist</Heading>
        {inspectionChecklists &&
          inspectionChecklists.map((checklist) => (
            <Panel
              key={checklist.id}
              backgroundColor="neutral100"
              flexDirection="row"
              justifyContent="space-between"
              marginY={100}
              width={1}
              padding={400}
            >
              <Box flexDirection="column">
                <Heading size={200}>{checklist.name}</Heading>
                <Box>{checklist.createdAt}</Box>
              </Box>
              <Box flexDirection="row" alignItems="center">
                {hasPdf(checklist) && (
                  <IconButton
                    label="download-inspection-pdf"
                    fill="minimal"
                    icon={iDownload}
                    color="neutral"
                    onClick={() =>
                      downloadPdf({
                        url: checklist.reportPdf!.redirectUrl,
                        name: checklist.reportPdf!.filename,
                      })
                    }
                  />
                )}
                <IconButton
                  label="view-checklist"
                  fill="minimal"
                  icon={iChevronRight}
                  color="neutral"
                  paddingLeft={200}
                  onClick={() => viewChecklist(checklist.id)}
                />
              </Box>
            </Panel>
          ))}
      </EstimatorResponsiveWrapper>
    </Box>
  );
};
