import { PureComponent } from 'react';

import autobind from 'autobind-decorator';
import { connect } from 'react-redux';

import { withTypewriter } from 'src/components/WithTypewriter';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { RootState } from 'src/types/reduxStore';
import { jobProps } from 'src/utils/trackingUtils';

import { VendorType, ListItemType, Product } from '../../../types';
import { SkuInput } from '../Inputs/SkuInput';
import {
  ColumnCellSizedWrapper,
  TableDataVendorDropdown,
  TableDataContent,
} from '../Tables/styled';
import { PmpDropdown as Dropdown } from './PmpDropdown';

const mapStateToProps = (state: RootState) => ({
  jobDetails: state.estimatorProductionTools.jobDetails,
  commonProps: getUserTrackingProps(state),
});

interface VendorTableDataProps {
  vendors: VendorType[];
  currentVendor: string;
  updateListItem: (listItemId: number, params: any) => void;
  listItemId: number;
  listItem: ListItemType;
  product?: Product;
  typewriter: any;
}

type Props = ReturnType<typeof mapStateToProps> & VendorTableDataProps;

class VendorTableDataComp extends PureComponent<
  Props & React.HTMLProps<HTMLInputElement>
> {
  @autobind
  private onSelectVendor(event: React.ChangeEvent<HTMLSelectElement>) {
    const selectedVendor = event.target.value;
    const {
      listItemId,
      listItem,
      updateListItem,
      vendors,
      jobDetails,
      commonProps,
      typewriter,
    } = this.props;

    const vendor: VendorType | null =
      vendors.find((f: VendorType) => f.vendorName === selectedVendor) || null;
    updateListItem(listItemId, { vendorId: vendor && vendor.id });

    typewriter.optionSelected({
      option_type: 'dropdown',
      options: `Vendors for ${listItem.name}`,
      selection: selectedVendor,
      page_or_screen_name: EventNames.pmp.page,
      ...commonProps,
      ...jobProps(jobDetails),
    });
  }

  public render() {
    const { vendors, currentVendor, listItem } = this.props;
    const options =
      vendors && vendors.map((v: VendorType) => ({ value: v.vendorName }));
    const nullOption = { displayValue: '---' };
    return (
      <TableDataVendorDropdown>
        <TableDataContent>
          <ColumnCellSizedWrapper>
            <Dropdown
              options={[nullOption, ...options]}
              value={currentVendor}
              onChange={this.onSelectVendor}
              data-testid="vendorDropdown"
              size="tiny"
            />
          </ColumnCellSizedWrapper>
          <ColumnCellSizedWrapper>
            <SkuInput key={listItem ? listItem.sku : ''} listItem={listItem} />
          </ColumnCellSizedWrapper>
        </TableDataContent>
      </TableDataVendorDropdown>
    );
  }
}

export const VendorTableData = connect(mapStateToProps)(
  withTypewriter(VendorTableDataComp),
);
