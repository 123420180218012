import { useState } from 'react';

import { Box, Body, Icon } from '@hover/blueprint';
import { iCalendar } from '@hover/icons';
import Calendar from 'react-calendar';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  deliveryDateValueField,
  deliveryDateErrorField,
} from 'src/features/projectManagement/constants';
import { getOrderDetailsForm } from 'src/features/projectManagement/redux/selectors';
import 'react-calendar/dist/Calendar.css';

import { UpdateOrderDetailsParam } from './DeliveryDetails';

const DateInputText = styled.div`
  border: 1px solid #767676;
  border-radius: 3.1111px;
  padding: 12px 16px;
  max-height: 48px;
  min-width: 170px;
  align-items: center;
  cursor: pointer;
  margin-bottom: 24px;
  display: flex;
  position: relative;
`;

export interface Props {
  minDate?: Date;
  disabledDates?: number[];
  onChange: (params: UpdateOrderDetailsParam) => void;
}

export const DeliveryDate: React.FC<Props> = ({
  minDate = new Date(),
  disabledDates = [0, 6],
  onChange,
}) => {
  const selectedDate = useSelector(getOrderDetailsForm)?.values?.deliveryDate;
  const [shouldShowCalendar, toggleShouldShowCalendar] =
    useState<boolean>(false);

  const handleChange = (date: Date) => {
    toggleShouldShowCalendar(false);
    onChange({
      valueAttribute: deliveryDateValueField,
      value: date,
      errorAttribute: deliveryDateErrorField,
      isInvalid: !date,
    });
  };

  return (
    <Box flexDirection="column" testId="delivery-date">
      <Body
        color="neutral600"
        size={500}
        marginBottom={200}
        data-testid="delivery-date-heading"
      >
        Delivery date
      </Body>
      <Box position="relative" testId="calendar-dropdown-container">
        <DateInputText
          data-testid="date-input-text-container"
          onClick={() => toggleShouldShowCalendar(!shouldShowCalendar)}
        >
          <Icon
            icon={iCalendar}
            color="neutral600"
            size="small"
            margin={0}
            marginRight={200}
            data-testid="date-input-text-icon"
          />
          <Body size={400} margin={0} data-testid="date-input-text-value">
            {!!selectedDate ? selectedDate.toDateString() : 'Select date'}
          </Body>
        </DateInputText>
        {shouldShowCalendar && (
          <Box
            position="absolute"
            left={0}
            top={46}
            data-testid="calendar-dropdown-wrapper"
            zIndex="+1"
          >
            <Calendar
              onChange={handleChange}
              value={selectedDate}
              minDate={minDate}
              tileDisabled={({ date }: { date: Date }) =>
                disabledDates.some(
                  (disabledDate) => date.getDay() === disabledDate,
                )
              }
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
