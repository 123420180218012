import { Slider, Heading, Box } from '@hover/blueprint';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';

import { TradeTypeEnum } from 'src/api/graphql-global-types';
import { wasteFactorRoof_wasteFactorRoof as WasteFactorRoof } from 'src/api/types/wasteFactorRoof';
import { FormattedNumber as Percentage } from 'src/components/FormattedNumber';
import {
  NewMeasurementDetails,
  RecommendedWaste,
} from 'src/features/exteriorEstimator/components/EstimationTool/CustomQuestionPages/WasteFactorPage/SliderWithPercentage/WasteDetails';
import {
  predictedWasteFactorRoofPercent,
  getIsPredictedWasteFactorRoofValid,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { getTradeTypeEnumsOfRoofCategory } from 'src/features/exteriorEstimator/utils/questionsUtils';
import { getTradeTypesSorted } from 'src/redux/selectors';

type Props = {
  onChange: (value: number) => void;
  heading: string;
  subText?: string;
  value: number;
  label: string;
  initialValue: number;
  tradeType: string;
  max?: number;
  min?: number;
  step?: number;
  measurementUnits: string;
  wasteFactorCalcResult: string;
  openWasteFactorCalcModal: () => void;
  onToggleChange: (
    predictedWasteFactorAnswer: WasteFactorRoof['predictedWasteFactor'],
  ) => void;
  shouldDisableWasteFactorSliderRoof: boolean;
};

export const SliderWithPercentage: React.FC<Props> = ({
  initialValue,
  value,
  onChange,
  tradeType,
  heading,
  label,
  measurementUnits,
  wasteFactorCalcResult,
  onToggleChange,
  shouldDisableWasteFactorSliderRoof,
  openWasteFactorCalcModal,
  ...sliderProps
}) => {
  const predictedWasteFactorRoof = useSelector(predictedWasteFactorRoofPercent);
  const isPredictedWasteFactorRoofValid = useSelector(
    getIsPredictedWasteFactorRoofValid,
  );
  const tradeTypes = useSelector(getTradeTypesSorted);

  const debouncedOnChange = debounce(onChange, 100);
  const isRoofTradeType = getTradeTypeEnumsOfRoofCategory(tradeTypes).includes(
    tradeType as TradeTypeEnum,
  );
  const isDisabled = isRoofTradeType && shouldDisableWasteFactorSliderRoof;

  return (
    <Box
      flexDirection="column"
      padding="1em"
      margin="1em 0"
      backgroundColor={isDisabled ? 'neutral100' : ''}
      borderStyle="solid"
      borderWidth="1px"
      borderRadius="4px"
      borderColor="neutral.200"
      data-testid="waste-factor-slider-with-per-container"
    >
      <Box textTransform="capitalize" justifyContent="space-between">
        <Heading size={200}>{heading}</Heading>
        <Heading size={200} color={isDisabled ? 'secondaryBase' : 'primary500'}>
          <Percentage
            value={value}
            suffix="%"
            data-testid="waste-factor-percentage"
          />
        </Heading>
      </Box>
      <NewMeasurementDetails
        label={label}
        measurementUnits={measurementUnits}
        wasteFactorCalcResult={wasteFactorCalcResult}
      />
      <Box marginTop={400} marginBottom={200}>
        <Slider
          defaultValue={initialValue}
          onChange={debouncedOnChange}
          isDisabled={isDisabled}
          {...sliderProps}
          suggestedValue={
            isRoofTradeType ? predictedWasteFactorRoof : undefined
          }
        />
      </Box>
      {isRoofTradeType && isPredictedWasteFactorRoofValid && (
        <RecommendedWaste
          openWasteFactorCalcModal={openWasteFactorCalcModal}
          predictedWasteFactorRoof={predictedWasteFactorRoof}
          onToggleChange={onToggleChange}
        />
      )}
    </Box>
  );
};
