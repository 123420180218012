import { TextInput, TextInputProps } from '@hover/blueprint';
import { forwardRef } from '@hover/blueprint/chakra';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

export type NumberInputProps = Omit<
  NumericFormatProps,
  'size' | 'customInput'
> &
  Omit<TextInputProps, 'onValueChange'> & {
    'data-testid'?: string;
  };

/**
 *
 * Composes [react-number-format](https://github.com/s-yadav/react-number-format)
 * along with blueprint's `TextInput`.
 *
 */
export const NumberInput: React.FC<NumberInputProps> = forwardRef(
  ({ ...props }, ref) => {
    return (
      <NumericFormat
        ref={ref}
        inputMode="decimal"
        customInput={TextInput}
        {...props}
      />
    );
  },
);
