import React, { useEffect, useCallback, useState } from 'react';

import {
  Button,
  Modal,
  Heading,
  Body,
  Spinner,
  Box,
  Flex,
  Link,
  useTheme,
} from '@hover/blueprint';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  EstimationConfigTemplateCollectionTypeEnum,
  TradeTypeEnum,
} from 'src/api/graphql-global-types';
import {
  configTemplateCollectionsForOrg_estimationConfigTemplateCollectionsForOrg_templateSections as TemplateSection,
  configTemplateCollectionsForOrg_estimationConfigTemplateCollectionsForOrg_templateSections_templatesByTradeType_templates as Template,
} from 'src/api/types/configTemplateCollectionsForOrg';
import {
  getSelectedTemplateIds,
  getTemplateSearchFilter,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { useMaterialListTemplates, useTracking } from 'src/hooks';
import { useIsMobileBreakpoint } from 'src/hooks/useIsMobileBreakpoint';
import { EventNames } from 'src/types/actionTypes';
import { templateCollectionsHaveEmptyCustomTemplates } from 'src/utils/templateUtils';

import { SearchFilterInput } from '../../../../../components/MaterialList/SearchFilterInput';
import { TemplateCardsSection } from '../../../../../components/MaterialList/TemplateCardsSection';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  isLoadingTemplateCollections: boolean;
  templateCollections: TemplateSection[];
};

const TemplateSelectionModalHeader = styled.div`
  opacity: 0.9;
  flex: 0 0 auto;
  margin-bottom: 24px;
`;

const TemplateSelectionModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex: 0 0 auto;
  padding-top: 8px;
  margin-top: 12px;
`;

export const TemplateSelectionModal = ({
  isOpen,
  onClose,
  isLoadingTemplateCollections,
  templateCollections,
}: Props) => {
  const theme = useTheme();
  const selectedTemplatesIds = useSelector(getSelectedTemplateIds) || [];
  const templateSearchFilter = useSelector(getTemplateSearchFilter);
  const { toggleTemplateAndUnselectOthersInSameTrade } =
    useMaterialListTemplates();
  const isMobile = useIsMobileBreakpoint();
  const [isClosingModal, setIsClosingModal] = useState<boolean>(false);

  /* Tracking events */
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const customTemplatesAreEmpty =
    templateCollectionsHaveEmptyCustomTemplates(templateCollections);

  const handleCardClick = useCallback(
    (template: Template, templateTradeType: TradeTypeEnum) => {
      toggleTemplateAndUnselectOthersInSameTrade(
        template.id,
        templateTradeType,
      );

      typewriter.buttonPressed({
        page_or_screen_name:
          EventNames.project.scope.templateSelectModal.selection,
        button_text: template.name || '',
        primary_cta: true,
        feature: 'material-list',
        trade: templateTradeType,
        ...commonTrackingProps,
      });
    },
    [templateCollections, selectedTemplatesIds],
  );

  const handleNeedAnotherTemplateClick = () => {
    typewriter.linkPressed({
      page_or_screen_name: EventNames.project.scope.templateSelectModal.page,
      link_text: 'Need another template?',
      primary_cta: false,
      feature: 'material-list',
      ...commonTrackingProps,
    });
  };

  const handleCalculateMaterialListClick = () => {
    setIsClosingModal(true);
    onClose();

    typewriter.buttonPressed({
      page_or_screen_name: EventNames.project.scope.templateSelectModal.confirm,
      button_text: 'Continue',
      primary_cta: true,
      feature: 'material-list',
      ...commonTrackingProps,
    });
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    typewriter.pageViewed({
      page_or_screen_name: EventNames.project.scope.templateSelectModal.page,
      feature: 'material-list',
      ...commonTrackingProps,
    });
    setIsClosingModal(false);
  }, [isOpen]);

  const showLoadingSpinner = isLoadingTemplateCollections || isClosingModal;

  return (
    <Modal
      isOpen={isOpen}
      isCentered
      isClosable={false}
      onClose={() => false}
      size={isMobile ? 'full' : 'large'}
      bodyProps={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {showLoadingSpinner ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner size="huge" data-testid="TemplateSelectionModal-Loader" />
        </Box>
      ) : (
        <Box
          testId="TemplateSelectionModal-Content"
          flexGrow={1}
          maxWidth="720px"
          height={`calc(95vh - ${theme.sizes[300]})`}
          margin="0 auto"
          display="flex"
          flexDirection="column"
        >
          <TemplateSelectionModalHeader>
            <Heading
              size={{ base: 500, tablet: 600 }}
              marginBottom={20}
              marginRight={800}
              whiteSpace="nowrap"
            >
              Select Templates
            </Heading>
            <SearchFilterInput />
          </TemplateSelectionModalHeader>

          <Flex
            flexDirection="column"
            flex="1 1 auto"
            position="relative"
            overflowY="auto"
          >
            {templateCollections?.map((section: TemplateSection) => {
              return section.collectionType ===
                EstimationConfigTemplateCollectionTypeEnum.CUSTOM &&
                customTemplatesAreEmpty ? null : (
                <TemplateCardsSection
                  templateCollection={section}
                  key={`${section.name}-template-cards-section`}
                  handleCardClick={handleCardClick}
                  searchFilterValue={templateSearchFilter}
                  enableCollapseFunctionality={!customTemplatesAreEmpty}
                />
              );
            })}
          </Flex>

          <TemplateSelectionModalFooter>
            <Link
              href="https://hover.qualtrics.com/jfe/form/SV_e9GaWLzCJ7lrP6K"
              target="_blank"
              onClick={handleNeedAnotherTemplateClick}
            >
              Need another template?
            </Link>

            <Box display="flex" alignItems="center">
              {!isMobile && (
                <Body
                  color="neutral.400"
                  padding={0}
                  margin={0}
                  textAlign="center"
                >
                  {selectedTemplatesIds.length} selected
                </Body>
              )}
              <Button
                isDisabled={!selectedTemplatesIds.length}
                height={{ base: '44px', tablet: '48px' }}
                onClick={handleCalculateMaterialListClick}
                marginLeft={500}
                fontSize={isMobile ? 200 : 300}
              >
                Continue {isMobile ? `(${selectedTemplatesIds.length})` : ''}
              </Button>
            </Box>
          </TemplateSelectionModalFooter>
        </Box> // end TemplateSelectionModal-Content
      )}
    </Modal>
  );
};
