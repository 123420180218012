import { useMemo } from 'react';

import {
  Body,
  Box,
  BoxProps,
  Button,
  Checkbox,
  Heading,
  Icon,
} from '@hover/blueprint';
import { iDownload, iPlus } from '@hover/icons';
import { capitalize, isNil, compact } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { LineItemTypeEnum } from 'src/api/graphql-global-types';
import type { projectManagementProductionList_projectManagementProductionList_estimateGroup as EstimateGroup } from 'src/api/types/projectManagementProductionList';
import { useProjectScopeTracker } from 'src/features/project/components/ProjectScope/hooks/useProjectScopeTracker';
import { toggleSelectAllTypeItems } from 'src/features/project/redux/actions';
import type {
  ListItemIdsByTrade,
  ListItemsByTrade,
} from 'src/features/project/types';
import { useIsMobileBreakpoint } from 'src/hooks/useIsMobileBreakpoint';
import { getMaterialListFeature } from 'src/redux/selectors';

import { ListControlTotals } from './ListControlTotals';
import { RecalculateMaterialListButton } from './RecalculateMaterialListButton';
import { SaveAsTemplateModal as SaveAsTemplateModalAndButton } from './SaveAsTemplateModal';

type ProjectScopeTypeListControlsProps = {
  jobId: number;
  lineItemType: LineItemTypeEnum;
  selectedListItemIds: ListItemIdsByTrade | null;
  itemsByTrade: ListItemsByTrade;
  openDownloadPdfModal: (lineItemType: LineItemTypeEnum) => void;
  openAddEditMaterialModal: (type: LineItemTypeEnum) => void;
  estimateGroup: EstimateGroup;
  productionListId: number;
};

const LineItemTypeDisplayMap = {
  [LineItemTypeEnum.MATERIAL]: 'Materials',
  [LineItemTypeEnum.LABOR]: 'Labor',
  [LineItemTypeEnum.OTHER]: 'Other',
} as const;

export const ProjectScopeTypeListControls: React.FC<
  ProjectScopeTypeListControlsProps & BoxProps
> = ({
  jobId,
  lineItemType,
  selectedListItemIds,
  itemsByTrade,
  openDownloadPdfModal,
  openAddEditMaterialModal,
  estimateGroup,
  productionListId,
  ...props
}) => {
  const dispatch = useDispatch();
  const { trackAddItemButton } = useProjectScopeTracker({ jobId });
  const isMobile = useIsMobileBreakpoint();

  const materialListFeatureEnabled = useSelector(getMaterialListFeature);
  const estimatesTemplates =
    compact(estimateGroup?.estimates?.map((estimate) => estimate.template)) ||
    [];
  const trades = useMemo(() => Object.keys(itemsByTrade), [itemsByTrade]);
  const selectedItemsTotal = useMemo(() => {
    return !selectedListItemIds
      ? 0
      : Object.values(selectedListItemIds).flat().length;
  }, [selectedListItemIds]);

  const itemsByTradeTotal = useMemo(
    () => Object.values(itemsByTrade).flat().length,
    [itemsByTrade],
  );

  const handleToggleSelection = () => {
    dispatch(toggleSelectAllTypeItems(lineItemType, itemsByTrade));
  };

  return (
    <Box
      data-testid="ProjectScope-controls"
      alignItems="baseline"
      padding={{ base: 400, tablet: 500 }}
      paddingX={500}
      flexDirection="row"
      flexWrap={{ base: 'wrap', tablet: 'nowrap' }}
      justifyContent="space-between"
      backgroundColor="neutral0"
      {...props}
    >
      <Box
        flexDir={{ base: 'column', desktop: 'row' }}
        alignItems={{ base: 'flex-start', desktop: 'center' }}
        width={{ base: '100%', tablet: 'initial' }}
      >
        {!isMobile && (
          <Box width="48px" paddingLeft={3} paddingRight={1}>
            <Checkbox
              value={`${lineItemType}`}
              aria-label={lineItemType}
              id={lineItemType}
              key={lineItemType}
              data-testid="checkboxLineItemTypeGroup"
              flex="0"
              isChecked={selectedItemsTotal === itemsByTradeTotal}
              isIndeterminate={
                selectedItemsTotal > 0 && selectedItemsTotal < itemsByTradeTotal
              }
              onChange={handleToggleSelection}
            />
          </Box>
        )}

        <Heading
          marginBottom={0}
          paddingLeft={{ base: 0, tablet: 1 }}
          size={400}
        >
          {LineItemTypeDisplayMap[lineItemType]}
        </Heading>

        <Box
          color="neutral.500"
          lineHeight={400}
          marginLeft={{ base: 0, desktop: 400 }}
        >
          {selectedItemsTotal === 0 && (
            <Body as="span" color="neutral.500">
              Select materials to order or download as PDF
            </Body>
          )}

          {selectedItemsTotal > 0 && (
            <ListControlTotals
              lineItemType={lineItemType}
              trades={trades}
              selectedListItemIds={selectedListItemIds}
            />
          )}
        </Box>
      </Box>

      <Box
        display="flex"
        flexWrap="wrap"
        width={{ base: '100%', tablet: 'initial' }}
        justifyContent={{ base: 'flex-start', tablet: 'flex-end' }}
      >
        {!isMobile && !materialListFeatureEnabled && (
          <Button
            data-testid="ProjectScope-Actions-Download"
            label="Download"
            fill="minimal"
            marginLeft={400}
            onClick={() => openDownloadPdfModal(lineItemType)}
            isDisabled={isNil(selectedListItemIds) || selectedItemsTotal === 0}
            iconBefore={iDownload}
          >
            Download
          </Button>
        )}

        {materialListFeatureEnabled && (
          <SaveAsTemplateModalAndButton
            estimatesTemplates={estimatesTemplates}
            productionListId={productionListId}
          />
        )}

        <Button
          testId="ProjectScope-Actions-AddItem"
          label={`Add ${lineItemType.toLowerCase()}`}
          aria-label={`add-${lineItemType}-button`}
          textColor={isMobile ? 'neutral.700' : undefined}
          textDecoration={isMobile ? 'underline' : undefined}
          fill={isMobile ? 'minimal' : 'outline'}
          marginLeft={400}
          onClick={() => {
            trackAddItemButton(lineItemType);
            openAddEditMaterialModal(lineItemType);
          }}
          iconBefore={isMobile ? undefined : iPlus}
        >
          {isMobile ? 'Add ' : null}
          {capitalize(lineItemType)}
        </Button>
        {materialListFeatureEnabled && !isMobile && (
          <RecalculateMaterialListButton jobId={jobId} />
        )}
      </Box>
    </Box>
  );
};
