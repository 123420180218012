import { useEffect, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import { Button } from '@hover/blueprint';
import * as Sentry from '@sentry/react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';

import {
  EstimationEstimateGroupStateEnum,
  TradeTypeEnum,
} from 'src/api/graphql-global-types';
import { projectManagementProductionList as ProductionList } from 'src/api/types/projectManagementProductionList';
import {
  CREATE_ESTIMATE_GROUP,
  GET_ESTIMATE_GROUP,
} from 'src/features/exteriorEstimator/apis/queries';
import { BackButton } from 'src/features/exteriorEstimator/components/EstimationTool/BottomBar/BackButton';
import { BottomBarLayout } from 'src/features/exteriorEstimator/components/EstimationTool/BottomBar/BottomBarLayout';
import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import {
  getCleanCustomLineItems,
  getCurrentQuestionCategory,
  getIsValidationError,
  getJobDetails,
  getQuestionResponses,
  getRouter,
  getSelectedTemplateIds,
  getSelectedTrades,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { getHdfMeasurements } from 'src/features/exteriorEstimator/redux/sagas/selectors/estimatorSelectors';
import { getAllSelectedFacetParams } from 'src/features/exteriorEstimator/redux/sagas/selectors/roofQuestionsSelectors';
import { NAVIGATION_DIRECTION } from 'src/features/exteriorEstimator/types/Navigation';
import { estimateGroupCreateParams } from 'src/features/exteriorEstimator/utils/estimateGroupUtils';
import { stripTemplateIdsFromParams } from 'src/features/exteriorEstimator/utils/miscUtils';
import { GET_PRODUCTION_LIST } from 'src/features/project/apis/graphql/queries/queries';
import { updateSelectedListItems } from 'src/features/project/redux/actions';
import {
  REACT_NATIVE_EVENTS,
  triggerReactNativeEvent,
} from 'src/features/project/util/nativeMobileEvents';
import { ToastStatusEnum, useToastEhi, useTracking } from 'src/hooks';
import { useSearchParams } from 'src/hooks/useSearchParams';
import { isEnabled, DIRECT_TO_PRODUCTION } from 'src/lib/FeatureFlag';
import {
  getMaterialListFeature,
  getOrderingOnlyFeatureAccess,
  getOrgSettings,
  getTradeTypesSorted,
  getUserOrgId,
  getVariationsFilter,
} from 'src/redux/selectors';
import { jobProps } from 'src/utils/trackingUtils';

interface Props {
  navigateCategories: (
    direction: NAVIGATION_DIRECTION,
    forcedTargetCategoryIndex: number | null,
  ) => void;
}

export const BottomBarLastPage: React.FC<Props> = (props: Props) => {
  const { searchParams } = useSearchParams();
  const toast = useToastEhi();
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const jobDetails = useSelector(getJobDetails);
  const hdfMeasurements = useSelector(getHdfMeasurements);
  const extraTrackingProps = { usesHdf: !!hdfMeasurements };

  const router = useSelector(getRouter);
  const customLineItemErrors = useSelector(getIsValidationError);
  const jobId = searchParams.get('jobId');
  const orgId = useSelector(getUserOrgId);
  const selectedTemplates = useSelector(getSelectedTemplateIds);
  const customLineItems = useSelector(getCleanCustomLineItems);
  const questionResponses = useSelector(getQuestionResponses);
  const tradeTypes = useSelector(getTradeTypesSorted);
  const selectedTrades = useSelector(getSelectedTrades) as TradeTypeEnum[];

  const facetsAttributes = useSelector(getAllSelectedFacetParams);
  const showOrderingFlow = useSelector(getOrgSettings)?.showOrderingFlow;
  const variationsFilter = useSelector(getVariationsFilter);
  const category = useSelector(getCurrentQuestionCategory);

  const [waitingForEstimateGroup, setWaitingForEstimateGroup] =
    useState<boolean>(false);

  const showOrderingVersion =
    useSelector(getOrderingOnlyFeatureAccess) && showOrderingFlow;
  const materialListFeatureEnabled = useSelector(getMaterialListFeature);
  const showOrderOrMaterialListVersion =
    showOrderingVersion || materialListFeatureEnabled;

  const dispatch = useDispatch();

  const [
    getEstimateGroup,
    {
      data: getEstimateGroupData,
      error: getEstimateGroupError,
      loading: getEstimateGroupLoading,
      stopPolling,
    },
  ] = useLazyQuery(GET_ESTIMATE_GROUP, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const [createEstimateGroup, { loading: isCreatingEstimate }] = useMutation(
    CREATE_ESTIMATE_GROUP,
    {
      onCompleted: ({ estimationEstimateGroupCreate }) => {
        const id = estimationEstimateGroupCreate?.estimateGroup?.id;
        if (!id) return;

        if (showOrderOrMaterialListVersion) {
          setWaitingForEstimateGroup(true);

          // poll for completion before redirecting anywhere
          getEstimateGroup({
            variables: {
              id,
              ...variationsFilter,
            },
            pollInterval: 1500,
          });
          return;
        }

        const isQuantityTakeoffToProdEnabled = isEnabled(DIRECT_TO_PRODUCTION);
        const search = stripTemplateIdsFromParams(router.location.search);

        const pathname = `/estimator/estimates/${id}${
          isQuantityTakeoffToProdEnabled ? '/takeoff' : ''
        }`;

        dispatch(
          push({
            pathname,
            search,
          }),
        );
      },
      onError: () => {
        toast({
          id: 'create-estimate-group-error-toast',
          description: 'Something went wrong. Please try again.',
          status: ToastStatusEnum.ERROR,
        });
      },
      update(cache) {
        const productionList: ProductionList | null = cache.readQuery({
          query: GET_PRODUCTION_LIST,
          variables: {
            jobId: parseInt(jobId as string, 10),
            orgId,
          },
        });

        if (!productionList?.projectManagementProductionList) {
          return;
        }

        const id = cache.identify({
          ...productionList.projectManagementProductionList,
        });

        cache.evict({ id });
      },
    },
  );

  useEffect(() => {
    // if showOrderOrMaterialListVersion is true, poll for estimate group creation completion, then redirect to PMP page
    const { state, failureReason } =
      getEstimateGroupData?.estimationEstimateGroup ?? {};

    if (state === EstimationEstimateGroupStateEnum.COMPLETE) {
      stopPolling();
      setWaitingForEstimateGroup(false);

      dispatch(updateSelectedListItems(null));
      window.location.href = `/ehi/#/project/${jobId}/scope?orgId=${orgId}`;
    }

    if (
      getEstimateGroupError ||
      state === EstimationEstimateGroupStateEnum.FAILED
    ) {
      stopPolling();
      setWaitingForEstimateGroup(false);
      dispatch(estimatorActions.setError(true));

      if (failureReason && failureReason !== 'None') {
        Sentry.captureException(getEstimateGroupError);
      }
    }
  }, [getEstimateGroupData, getEstimateGroupError, stopPolling, dispatch]);

  const handleGenerateEstimateClick = () => {
    typewriter.buttonPressed({
      page_or_screen_name: category ?? '',
      button_text: showOrderingVersion
        ? 'Generate Project Scope'
        : 'Generate Estimate',
      primary_cta: true,
      ...jobProps(jobDetails),
      ...commonTrackingProps,
      ...extraTrackingProps,
    });

    if (!jobId || !selectedTemplates || !selectedTrades) return;

    const variables = estimateGroupCreateParams({
      jobId,
      orgId,
      selectedTemplates,
      customLineItems,
      questionResponses,
      tradeTypes,
      selectedTrades,
      facetsAttributes,
      showOrderingVersion: !!showOrderOrMaterialListVersion,
      customizedProductionList: materialListFeatureEnabled,
    });

    triggerReactNativeEvent(REACT_NATIVE_EVENTS.RESET_NAVIGATION_HISTORY_EVENT);

    createEstimateGroup({ variables });
  };

  const { navigateCategories } = props;

  return (
    <BottomBarLayout>
      <BackButton navigateCategories={navigateCategories} />
      <Button
        shape="pill"
        size="large"
        color="success"
        onClick={handleGenerateEstimateClick}
        isDisabled={customLineItemErrors}
        isLoading={
          isCreatingEstimate ||
          getEstimateGroupLoading ||
          waitingForEstimateGroup
        }
        data-testid="estimatorNextButton"
      >
        {showOrderOrMaterialListVersion ? 'Finish calculation' : 'Generate'}
      </Button>
    </BottomBarLayout>
  );
};
