import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import {
  Body,
  Box,
  Button,
  Field,
  Modal,
  Select,
  TextInput,
} from '@hover/blueprint';
import { isNil, isNumber, startCase } from 'lodash';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { LineItemTypeEnum, TradeTypeEnum } from 'src/api/graphql-global-types';
import {
  estimationQuantityUnits,
  estimationQuantityUnits_estimationQuantityUnits as estimationQuantityUnit,
} from 'src/api/types/estimationQuantityUnits';
import type { productCatalogConfigOrgDistributors_productCatalogConfigOrgDistributors as Distributor } from 'src/api/types/productCatalogConfigOrgDistributors';
import { productCatalogProductForEditItem_productCatalogProduct_variations as Variation } from 'src/api/types/productCatalogProductForEditItem';
import { productCatalogProductsSearch_productCatalogProductsSearch_nodes as ProductSearchResult } from 'src/api/types/productCatalogProductsSearch';
import type { projectManagementProductionList_projectManagementProductionList_listItems_product as Product } from 'src/api/types/projectManagementProductionList';
import { InputWithDropdownTypeahead } from 'src/components/InputWithDropdownTypeahead';
import { messages } from 'src/constants/messages';
import {
  ESTIMATION_QUANTITY_UNITS,
  GET_PRODUCT,
  PRODUCT_CATALOG_DISTRIBUTOR_VARIATIONS_ATTRIBUTES,
} from 'src/features/project/apis/graphql/queries/queries';
import { Attributes } from 'src/features/project/components/OrderDetail/OrderDetailContent';
import { CUSTOM_VARIANT_COLOR } from 'src/features/project/components/ProjectScope/EditableVariationSelection';
import {
  getBranch,
  getDistributor,
  getJobAccount,
} from 'src/features/project/redux/selectors/projectSelectors';
import {
  getTypeAheadSuggestionsIcon,
  invertMap,
  productSearch,
} from 'src/features/project/util/utils';
import { ToastStatusEnum, useToastEhi, useTracking } from 'src/hooks';
import { useIsMobileBreakpoint } from 'src/hooks/useIsMobileBreakpoint';
import { getOrgIdParam, getVariationsFilter } from 'src/redux/selectors';
import { EventNames } from 'src/types';
import { sentenceCase } from 'src/utils/Formatters';

export type EditableItem = {
  listItem?: Attributes;
  type: LineItemTypeEnum;
};

interface AddEditMaterialModalProps {
  isOpen: boolean;
  onCancel: () => void;
  editFn: (listItem: AddEditFields) => void;
  trades: TradeTypeEnum[];
  editableItem?: EditableItem | null;
  shouldFilterByDistributor?: boolean;
  loading?: boolean;
  showSku: boolean;
  realTimePricing: boolean;
  distributors?: Distributor[];
}

export interface AddEditFields {
  trade: TradeTypeEnum;
  materialName: string;
  quantityUnits: string;
  quantity: number;
  color: string;
  externalVariationId: string;
  unitCost: number | null;
  customVariant: string;
  sku: string | null;
  productId: string;
  product: Product;
}

interface Color {
  id: string;
  name: string;
}

const enum TOAST_IDS {
  GET_PRODUCT_TOAST,
  GET_QUANTITY_UNITS_TOAST,
  GET_DISTRIBUTOR_VARIATIONS_ATTRIBUTES_TOAST,
}

export const AddEditMaterialModal: React.FC<AddEditMaterialModalProps> = ({
  isOpen,
  onCancel,
  editFn,
  trades,
  editableItem,
  shouldFilterByDistributor,
  showSku,
  realTimePricing,
  distributors,
  loading,
}) => {
  const distributorId = useSelector(getDistributor)?.id;
  const isOrderDetailPage: boolean = useMemo(() => {
    return !isNil(distributorId);
  }, [distributorId]);
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const jobAccount = useSelector(getJobAccount);
  const isMobile = useIsMobileBreakpoint();

  const distributorLogo = useSelector(getDistributor)?.logo?.url;
  const orgId = useSelector(getOrgIdParam);
  const branch = useSelector(getBranch);

  const variationsFilter = useSelector(getVariationsFilter);
  const { jobId } = useParams();

  const [searchSuggestions, setSearchSuggestions] = useState<
    ProductSearchResult[]
  >([]);
  const [colorOptions, setColorOptions] = useState<Color[]>([
    CUSTOM_VARIANT_COLOR,
  ]);
  const [productId, setProductId] = useState<string>('');
  const [sku, setSku] = useState<string | null>(null);
  const [productSearchLoading, setProductSearchLoading] =
    useState<boolean>(false);
  const [showUnitCostWarning, setShowUnitCostWarning] =
    useState<boolean>(false);

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    clearErrors,
    reset,
    formState: { errors, isDirty, isValid, dirtyFields },
  } = useForm<AddEditFields>({
    mode: 'onChange',
    defaultValues: {
      ...{
        materialName: '',
        quantityUnits: '',
        quantity: 1,
        color: '',
        externalVariationId: '',
        customVariant: '',
        unitCost: null,
      },
      ...(trades.length === 1 ? { trade: trades[0] } : {}),
    },
  });

  const clearAllFormData = useCallback(() => {
    setColorOptions([CUSTOM_VARIANT_COLOR]);
    setValue('externalVariationId', '');
    setProductId('');
    setSku(null);
    setValue('unitCost', null);
    setValue('color', '', { shouldValidate: true });
  }, [setValue]);

  const resetModal = useCallback(() => {
    clearErrors();
    clearAllFormData();
    reset();
  }, [clearAllFormData, clearErrors, reset]);

  const onClose = useCallback(() => {
    onCancel();
  }, [onCancel]);

  useEffect(() => {
    if (!!isOpen) {
      resetModal();
    }
  }, [isOpen, resetModal]);

  // track variation change
  const variationChange = useWatch({ control, name: 'externalVariationId' });
  const toast = useToastEhi();

  const [quantityUnitsMap] = useState(new Map());

  const [
    getQuantityUnits,
    { data: quantityUnitsData, loading: quantityUnitsLoading },
  ] = useLazyQuery(ESTIMATION_QUANTITY_UNITS, {
    onError: () => {
      toast({
        id: TOAST_IDS.GET_QUANTITY_UNITS_TOAST,
        description:
          messages.projectScope.errors.query.productionList
            .estimateQuantityUnits,
        status: ToastStatusEnum.ERROR,
      });
    },
    onCompleted: (data: estimationQuantityUnits) => {
      data.estimationQuantityUnits?.forEach((unit) => {
        quantityUnitsMap.set(unit.value, unit.abbreviation);
      });
    },
  });

  const [getProduct, { data: productData, loading: getProductLoading }] =
    useLazyQuery(GET_PRODUCT, {
      onError: () => {
        toast({
          id: TOAST_IDS.GET_PRODUCT_TOAST,
          description:
            messages.projectScope.errors.query.productionList.getProduct,
          status: ToastStatusEnum.ERROR,
        });
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    });

  const [
    getDistributorVariationsAttributes,
    { loading: getDistributorVariationsAttributesLoading },
  ] = useLazyQuery(PRODUCT_CATALOG_DISTRIBUTOR_VARIATIONS_ATTRIBUTES, {
    // This fetch-policy can't be `cache-first` here, because of a bug with Apollo Client:
    // https://github.com/apollographql/react-apollo/issues/2177
    // When the distributorVariationsAttributesData data will be retreived from
    // cache due to a variation having been previously selected/queried, the
    // onCompleted callback won't run.
    fetchPolicy: 'cache-and-network',
    onError: () => {
      toast({
        id: TOAST_IDS.GET_DISTRIBUTOR_VARIATIONS_ATTRIBUTES_TOAST,
        description:
          messages.projectScope.errors.query.productionList
            .getDistributorVariationsAttributes,
        status: ToastStatusEnum.ERROR,
      });
    },
    onCompleted: (data) => {
      // This code can't be in a useEffect because:
      // When switching from a "custom" variation to a variation that was
      // the last selected variation, the distributorVariationsAttributesData state
      // data value won't have changed, so the effect dependency won't cause the effect to run.
      if (data) {
        const attributes =
          data.productCatalogDistributorVariationsAttributes[0];
        const productSku = attributes?.sku;
        const productUnitCost = attributes?.unitCost;
        const showUnitCostWarningValue = productUnitCost.toString() === '0';
        setShowUnitCostWarning(showUnitCostWarningValue);
        setValue('unitCost', productUnitCost);
        setSku(productSku);
      }
    },
  });

  const getDistributorPricing = useCallback(() => {
    const quantityUnits = getValues('quantityUnits');
    const variationId = getValues('externalVariationId');
    if (!realTimePricing || !editableItem?.listItem) {
      if (!editableItem?.listItem) return;
      // For editing an item with no realtime pricing, reset back to the lineItem's unitCost.
      setValue(
        'unitCost',
        editableItem?.listItem?.unitCost
          ? editableItem?.listItem.unitCost
          : null,
      );
    } else {
      getDistributorVariationsAttributes({
        variables: {
          distributorId,
          orgId,
          distributionBranchId: branch?.id,
          distributionJobAccountId: jobAccount?.id,
          lineItemsAttributes: [
            {
              variationId,
              clientIdentifier: editableItem?.listItem.clientIdentifier,
              quantityUnits: invertMap(quantityUnitsMap).get(quantityUnits),
            },
          ],
        },
      });
    }
  }, [
    branch,
    distributorId,
    editableItem,
    getDistributorVariationsAttributes,
    orgId,
    realTimePricing,
    setValue,
    getValues,
  ]);

  useEffect(() => {
    getQuantityUnits();
  }, []);

  useEffect(() => {
    const listItem = editableItem?.listItem;
    if (isOpen && listItem && !quantityUnitsLoading) {
      reset({
        trade: listItem.tradeType,
        materialName: listItem.productName,
        quantity: listItem.quantity,
        color: listItem.userSetCustomVariationName
          ? CUSTOM_VARIANT_COLOR.name
          : listItem.variationName ?? '',
        externalVariationId: listItem.userSetCustomVariationName
          ? CUSTOM_VARIANT_COLOR.name
          : listItem.variationId ?? '',
        unitCost: isNumber(listItem.unitCost) ? listItem.unitCost : null,
        quantityUnits: quantityUnitsMap
          .get(listItem.quantityUnits)
          ?.toLowerCase(),
        customVariant:
          listItem.userSetCustomVariationName && listItem.variationName
            ? listItem.variationName
            : '',
      });

      if (listItem.productId) {
        getProduct({
          variables: {
            orgId,
            id: listItem.productId,
            filterVariationsByOrg: variationsFilter.filterVariationsByOrg,
            ...{
              distributorIds: isOrderDetailPage ? [distributorId] : [],
            },
          },
        });
      }

      if (listItem.sku) setSku(listItem.sku);
      const colors: Color[] = [CUSTOM_VARIANT_COLOR];
      if (
        listItem.variationId &&
        listItem.variationName &&
        !listItem.userSetCustomVariationName
      )
        colors.push({
          id: listItem.variationId,
          name: listItem.variationName,
        });
      setColorOptions(colors);

      if (listItem.productId) setProductId(listItem.productId);
      if (listItem.variationId) {
        getDistributorPricing();
      }
    }
  }, [
    isOpen,
    editableItem,
    setValue,
    reset,
    getProduct,
    quantityUnitsMap.size,
    orgId,
    variationsFilter.filterVariationsByOrg,
    getDistributorPricing,
    distributorId,
    isOrderDetailPage,
  ]);

  const getVariationsForProduct = useCallback(
    (product: ProductSearchResult) => {
      const variations = product?.variations;
      if (variations && variations.length > 0) {
        const colors = [
          ...variations.map((variation: Variation) => {
            return { name: variation.name, id: variation.id };
          }),
          CUSTOM_VARIANT_COLOR,
        ];
        setColorOptions(colors);
      }
    },
    [],
  );

  useEffect(() => {
    const listItem = editableItem?.listItem;
    if (
      isOpen &&
      productData &&
      productData.productCatalogProduct &&
      listItem?.productId
    ) {
      setSearchSuggestions([productData?.productCatalogProduct]);
      getVariationsForProduct(productData?.productCatalogProduct);
    }
  }, [isOpen, editableItem?.listItem, getVariationsForProduct, productData]);

  const onSubmit = (data: AddEditFields) => {
    const listItem = editableItem?.listItem;
    if (listItem) {
      // Segment tracking.
      typewriter.buttonPressed({
        button_text: 'Save',
        button_location: 'Edit-material-modal',
        page_or_screen_name: isOrderDetailPage
          ? EventNames.project.orderDetails.page
          : EventNames.project.scope.page,
        job_id: jobId,
        primary_cta: false,
        ...commonTrackingProps,
      });
    } else {
      // Segment tracking.
      typewriter.buttonPressed({
        button_text: `Add ${editableItem?.type}`,
        button_location: 'Add-material-modal',
        page_or_screen_name: EventNames.project.scope.page,
        primary_cta: false,
        job_id: jobId,
        ...commonTrackingProps,
      });
    }
    editFn({ ...data, productId, sku });
  };

  const handleSelectedSearchSuggestion = (
    suggestions: ProductSearchResult[],
    text: string,
  ) => {
    setColorOptions([CUSTOM_VARIANT_COLOR]);
    const selectedProduct = suggestions.find(
      (product: ProductSearchResult) =>
        product.name.toLowerCase() === text.toLowerCase(),
    );

    if (!selectedProduct) return;

    setValue('color', '', { shouldValidate: true });
    setValue('externalVariationId', '');
    setProductId(selectedProduct.id);
    // sku and unitCost are updated when the variation of a product is picked
    // and should be cleared if a new product is selected, but only on order details page
    if (isOrderDetailPage) {
      setSku(null);
      setValue('unitCost', null);
    }
    setColorOptions([
      ...selectedProduct.variations.map((variation: Variation) => {
        return { name: variation.name, id: variation.id };
      }),
      CUSTOM_VARIANT_COLOR,
    ]);
    // If adding/editing a listItem, and the newly-selected
    // product has only one available variation, then
    // pre-select the one variation in the add/edit item form.
    if (selectedProduct.variations.length === 1) {
      const variation = selectedProduct.variations[0];
      setValue('externalVariationId', variation.id, {
        shouldDirty: true,
      });
      setValue('color', variation.name, {
        shouldValidate: true,
        shouldDirty: true,
      });
      getDistributorPricing();
    }
  };

  const handleSearch = async (
    text: string,
    selected: boolean,
    onChange: (value: string) => void,
  ) => {
    if (!isNil(text)) onChange(text);

    const productSearchVariables = {
      searchTerm: text,
      orgId,
      distributorId: shouldFilterByDistributor ? distributorId : null,
      distributionBranchId: branch?.distributionBranchId || null,
      filterByOrg: branch?.distributionBranchId ? false : null, // if filtering by branch, don't filter to only org products
    };
    setProductSearchLoading(true);
    const results = await productSearch(productSearchVariables);
    setProductSearchLoading(false);

    if (!results) {
      // clear everything and exit when no search results, because either no products were found,
      // or it's a custom product name, either way all of these state fields or form should be cleared
      clearAllFormData();
      return;
    }

    setSearchSuggestions(results);
    // On selection of a search result, handle the selection.
    if (selected) {
      handleSelectedSearchSuggestion(results, text);
    }
  };

  // Selection of variation from selection list.
  const handleColorChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    onChange: (value: string) => void,
  ) => {
    if (e.target.value === CUSTOM_VARIANT_COLOR.name) {
      // When selecting a custom color:
      // 1. Set `CUSTOM_VARIANT` as value for externalVariationId.
      // 2. clear color fields and, if on order details, also clear sku & unit cost

      onChange(e.target.value);
      setValue('color', '');
      if (isOrderDetailPage) {
        setValue('unitCost', null);
        setSku(null);
      }
    } else {
      setValue('customVariant', '');
    }
    if (searchSuggestions.length === 0) return;
    const variation = searchSuggestions[0].variations.find(
      (suggestionVariation) => suggestionVariation.id === e.target.value,
    );
    if (variation) {
      // form onChange for `externalVariationId` field.
      onChange(e.target.value);
      // set form value for `color` field.
      setValue('color', variation.name, {
        shouldValidate: true,
        shouldDirty: true,
      });
      getDistributorPricing();
    }
  };

  // Selection of UoM from selection list.
  const uomChange = useWatch({ control, name: 'quantityUnits' });
  useEffect(() => {
    const uom = getValues('quantityUnits');
    const isUoMDirty = dirtyFields.quantityUnits;
    // Get new pricing when UoM changes (but not on initial non-dirty load).
    if (isUoMDirty && !isNil(uom)) {
      getDistributorPricing();
    }
    // Exclude watching `dirtyFields`.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues, uomChange]);

  const footer = (
    <Box testId="AddEditMaterialModal-Actions">
      <Box
        width="100%"
        justifyContent={isMobile ? 'space-between' : 'end'}
        gap={isMobile ? '20px' : 'none'}
      >
        <Button
          testId="AddEditMaterialModal-Actions-Cancel"
          color="primary"
          fill="outline"
          marginRight={200}
          onClick={onClose}
          height={isMobile ? '60px' : '32px'}
          flex={isMobile ? 1 : 'none'}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          data-testid="AddEditMaterialModal-Actions-Add"
          color="primary"
          flex={isMobile ? 1 : 'none'}
          isDisabled={!isDirty || !isValid}
          height={isMobile ? '60px' : '32px'}
          isLoading={
            loading ||
            getDistributorVariationsAttributesLoading ||
            getProductLoading ||
            productSearchLoading ||
            quantityUnitsLoading
          }
        >
          {isMobile || (!!editableItem && !!editableItem.listItem)
            ? 'Save'
            : `Add ${sentenceCase(editableItem?.type)}`}
        </Button>
      </Box>
    </Box>
  );

  const isEditableItemMaterial =
    editableItem?.type === LineItemTypeEnum.MATERIAL;

  const quantityErrorMessage = isEditableItemMaterial
    ? 'Quantity must be whole number greater than zero'
    : 'Quantity must be greater than zero';

  const typeAheadSuggestions = useMemo(() => {
    return searchSuggestions.map((result: ProductSearchResult) => {
      // get the icon
      const icon = isOrderDetailPage
        ? distributorLogo // for detail page this is always the current distributor
        : getTypeAheadSuggestionsIcon(result, distributors); // for project page, look into each result for icon

      return {
        text: result.name,
        id: result.id,
        icon,
      };
    });
  }, [searchSuggestions]);

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      header={startCase(
        `${
          !!editableItem?.listItem ? 'Edit' : 'Add '
        } ${editableItem?.type.toLowerCase()}`,
      )}
      footer={footer}
      size={isMobile ? 'full' : 'medium'}
      isClosable={!isMobile}
      contentProps={{
        as: 'form',
        onSubmit: handleSubmit(onSubmit),
        paddingTop: isMobile ? '10%' : 400,
      }}
      bodyProps={{ flex: 1 }}
    >
      <div data-testid="add-edit-material-modal">
        {isNil(editableItem?.listItem) && (
          <Field error={errors?.trade?.message} label="Trade" name="trade">
            <Select
              {...register('trade', {
                required: 'Trade is required',
              })}
              placeholder="Select trade"
              isInvalid={!!errors?.trade?.message}
              disabled={!!editableItem?.listItem}
              data-testid="AddMaterial-trade"
            >
              {trades.map((trade) => {
                return (
                  <option key={trade} value={trade}>
                    {sentenceCase(trade)}
                  </option>
                );
              })}
            </Select>
          </Field>
        )}
        {editableItem?.type === LineItemTypeEnum.MATERIAL && (
          <Field
            error={errors?.materialName?.message}
            label="Material name"
            name="materialName"
          >
            <Controller
              control={control}
              name="materialName"
              rules={{
                required: 'Name is required',
              }}
              render={({ field: { onChange } }) => (
                <InputWithDropdownTypeahead
                  suggestions={typeAheadSuggestions}
                  onChange={(text, selected) =>
                    handleSearch(text, selected, onChange)
                  }
                  initialInputValue={editableItem?.listItem?.productName || ''}
                  label="materialName"
                />
              )}
            />
          </Field>
        )}
        {editableItem?.type !== LineItemTypeEnum.MATERIAL && (
          <Field
            label="Item name"
            name="materialName"
            paddingBottom={400}
            error={errors?.materialName?.message}
            fontSize="600"
          >
            <TextInput
              data-testid="AddEditMaterialModal-ItemNameInput"
              isRequired
              {...register('materialName', {
                required: 'Item name is required',
              })}
              isInvalid={!!errors?.materialName?.message}
            />
          </Field>
        )}
        {quantityUnitsData && (
          <Field
            error={errors?.quantityUnits?.message}
            label={`UoM${
              editableItem?.type === LineItemTypeEnum.MATERIAL
                ? ''
                : ' (optional)'
            }`}
            name="quantityUnits"
          >
            <Select
              {...register(
                'quantityUnits',
                editableItem?.type === LineItemTypeEnum.MATERIAL
                  ? {
                      required: 'Units are required',
                    }
                  : {},
              )}
              isInvalid={!!errors?.quantityUnits?.message}
              placeholder="Select units"
              data-testid="AddMaterial-uom"
            >
              {quantityUnitsData?.estimationQuantityUnits?.map(
                (unit: estimationQuantityUnit) => {
                  return (
                    <option key={unit.abbreviation} value={unit.abbreviation}>
                      {unit.abbreviation}
                    </option>
                  );
                },
              )}
            </Select>
          </Field>
        )}
        <Field
          error={errors?.quantity?.message}
          label="Quantity"
          name="quantity"
        >
          <TextInput
            isRequired
            type="number"
            step={isEditableItemMaterial ? '1' : 'any'}
            data-testid="AddMaterial-quantity"
            {...register('quantity', {
              valueAsNumber: true,
              validate: {
                numberValidator: (value) => {
                  if (isEditableItemMaterial) {
                    return Number.isInteger(value)
                      ? true
                      : quantityErrorMessage;
                  }
                  return Number.isFinite(value) ? true : quantityErrorMessage;
                },
                quantityValidator: (quantity) => {
                  return quantity > 0 ? true : quantityErrorMessage;
                },
              },
            })}
            isInvalid={!!errors?.quantity?.message}
          />
        </Field>
        {editableItem?.type === LineItemTypeEnum.MATERIAL && (
          <Field
            error={errors?.color?.message}
            label={`Color/variant${isOrderDetailPage ? '' : ' (optional)'}`}
            name="color"
          >
            <Controller
              control={control}
              name="externalVariationId"
              render={({ field: { onChange, value } }) => (
                <Select
                  data-testid="AddMaterial-color"
                  onChange={(e) => handleColorChange(e, onChange)}
                  isInvalid={!!errors?.color?.message}
                  value={value}
                >
                  {isNil(variationChange) || variationChange.length === 0 ? (
                    <option key={null} value="">
                      Select variant
                    </option>
                  ) : null}
                  {colorOptions.map((colorOption) => {
                    return (
                      <option key={colorOption.id} value={colorOption.id}>
                        {colorOption.name}
                      </option>
                    );
                  })}
                </Select>
              )}
              rules={
                isOrderDetailPage
                  ? { required: 'Color/variant is required' }
                  : {}
              }
            />
          </Field>
        )}
        {editableItem?.type === LineItemTypeEnum.MATERIAL &&
          variationChange === CUSTOM_VARIANT_COLOR.name && (
            <Field
              error={errors?.customVariant?.message}
              label="Custom variant name"
              name="customVariant"
            >
              <TextInput
                {...register('customVariant', {
                  required: 'Custom variant is required',
                })}
                data-testid="AddMaterial-customVariant"
                placeholder="Enter custom variant name"
              />
            </Field>
          )}
        {editableItem?.type === LineItemTypeEnum.MATERIAL && showSku && (
          <Box flexDirection="column">
            <Body size={500} margin={0} color="neutral.600">
              SKU
            </Body>
            <Body size={500} marginTop={200} marginBottom={400}>
              {sku}
            </Body>
          </Box>
        )}
        <Field
          error={errors?.unitCost?.message}
          label={`Unit cost ($)${isOrderDetailPage ? '' : ' (optional)'}`}
          name="unitCost"
        >
          <fieldset
            disabled={realTimePricing}
            style={{ border: 'none', padding: 0 }}
          >
            <TextInput
              type="number"
              step="any"
              {...register('unitCost', {
                valueAsNumber: true,
                min: { value: 0, message: 'Unit cost is invalid' },
              })}
              isInvalid={!!errors?.unitCost?.message}
              data-testid="AddMaterial-unitCost"
              background={realTimePricing ? 'neutral100' : 'inherit'}
              border={realTimePricing ? 'disabled' : 'idle'}
              color={realTimePricing ? 'neutral600' : 'inherit'}
              cursor={realTimePricing ? 'not-allowed' : 'inherit'}
              display={showUnitCostWarning ? 'none' : 'block'}
            />

            {showUnitCostWarning && (
              <TextInput
                disabled
                type="text"
                data-testid="AddMaterial-unitCostWarning"
                background="neutral100"
                border="disabled"
                color="neutral600"
                cursor="not-allowed"
                value="Price Calculated At Invoicing"
              />
            )}
          </fieldset>
        </Field>
      </div>
    </Modal>
  );
};
