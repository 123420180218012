import { Box, Heading } from '@hover/blueprint';
import { useSelector } from 'react-redux';

import { EstimateGroup_estimationEstimateGroup_estimates as EstimateGroupEstimate } from 'src/api/types/EstimateGroup';
import { FormattedNumber } from 'src/components/FormattedNumber';
import { isUserLightWeightFlow } from 'src/redux/selectors';

type Props = {
  estimate: EstimateGroupEstimate;
};

export const EstimateSection: React.FC<Props> = ({ estimate, children }) => {
  const isLightWeightFlow = useSelector(isUserLightWeightFlow);
  const showPricesByTemplate = !!estimate?.template?.showPricesInEstimation;

  return (
    <Box flexDirection="column" padding={200}>
      <Box justifyContent="space-between" paddingBottom={400}>
        <Heading size={200}>{estimate.name}</Heading>
        {!isLightWeightFlow && showPricesByTemplate ? (
          <Heading size={200} data-testid="estimateTotal">
            <FormattedNumber value={estimate.basePrice ?? 0} format="$0,0.00" />
          </Heading>
        ) : (
          <></>
        )}
      </Box>
      {children}
    </Box>
  );
};
