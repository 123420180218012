import { EstimationConfigTemplateCollectionTypeEnum } from 'src/api/graphql-global-types';
import { configTemplateCollectionsForOrg_estimationConfigTemplateCollectionsForOrg_templateSections as TemplateSection } from 'src/api/types/configTemplateCollectionsForOrg';

export const templateCollectionsHaveEmptyCustomTemplates = (
  templateCollections: TemplateSection[],
) => {
  const customTemplatesSection = templateCollections?.find(
    (section) =>
      section.collectionType ===
      EstimationConfigTemplateCollectionTypeEnum.CUSTOM,
  );

  const numberOfCustomTemplates =
    customTemplatesSection?.templatesByTradeType.length || 0;

  return !numberOfCustomTemplates;
};
