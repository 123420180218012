import { Body, Link } from '@hover/blueprint';

import { ProjectDetailsPanel, SectionHeading } from './components';

export const FinancialsTeaser: React.FC = () => {
  return (
    <ProjectDetailsPanel
      data-testid="financialsTeaserSection"
      backgroundColor="neutral.100"
    >
      <SectionHeading marginBottom={200} color="neutral.500">
        Financials
      </SectionHeading>
      <Body m={0} marginBottom={200} size={500} color="neutral.500">
        Available only for projects with estimate(s) under the Transform
        Membership Plan
      </Body>
      <Link target="_blank" href="https://hover.to/transform-plan/">
        Learn more
      </Link>
    </ProjectDetailsPanel>
  );
};
