import { Body, Box, TileRadio, Panel, Icon } from '@hover/blueprint';
import { iAlertCircle, iCheck } from '@hover/icons';
import pluralize from 'pluralize';
import { useSelector } from 'react-redux';

import { TradeTypeEnum } from 'src/api/graphql-global-types';
import { EstimateGroupForSummary_estimationEstimateGroup_estimates as EstimationGroupEstimateWithProduct } from 'src/api/types/EstimateGroupForSummary';
import { NavButton } from 'src/components/blueprint';
import { FormattedNumber } from 'src/components/FormattedNumber';
import {
  getJobDetails,
  getRouter,
  getEstimateGroupIdFromLocation,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import {
  canAddVariations,
  getSelectedVariationNamesByEstimate,
  getUnselectedVariationsCount,
} from 'src/features/exteriorEstimator/utils/estimateGroupUtils';
import { useTracking } from 'src/hooks';
import {
  isUserLightWeightFlow,
  getUserTrackingProps,
} from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { isMobileScreenSize } from 'src/utils/BrowserUtils';
import { estimateTotalWithDiscounts } from 'src/utils/estimatesUtils';
import { jobProps } from 'src/utils/trackingUtils';

type Props = {
  estimate?: EstimationGroupEstimateWithProduct;
  isNone?: boolean;
  trade: TradeTypeEnum;
  name: string;
  id: string | number;
  isDisabled: boolean;
};

export const Estimate: React.FC<Props> = ({
  estimate,
  isNone,
  name,
  id,
  isDisabled,
  ...props
}) => {
  const router = useSelector(getRouter);
  const estimateGroupId = useSelector(getEstimateGroupIdFromLocation);
  const userLightWeightFlow = useSelector(isUserLightWeightFlow);
  const estimateTotal = estimate ? estimateTotalWithDiscounts(estimate) : 0;

  const jobDetails = useSelector(getJobDetails);
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const trackAddColor = () => {
    typewriter.buttonPressed({
      button_text: `Select Variations`,
      page_or_screen_name:
        EventNames.estimator.estimateDetailsScreen.estimates.selectEstimates,
      primary_cta: true,
      backend_id_type: 'estimate_group_id',
      backend_id_value: estimateGroupId,
      ...commonTrackingProps,
      ...jobProps(jobDetails),
    });
  };

  const renderAddColor = () => {
    return (
      <NavButton
        to={`/estimator/estimates/${estimateGroupId}/estimate/${estimate?.id}/variations${router.location.search}`}
        fill="outline"
        backgroundColor="neutral0"
        onClick={trackAddColor}
      >
        {isMobileScreenSize ? 'Select' : 'Select variations'}
      </NavButton>
    );
  };

  const selectedVariationNames = getSelectedVariationNamesByEstimate(estimate);

  const variationTagsList = selectedVariationNames?.map(
    (variationName: string) => (
      <Body
        as="span"
        backgroundColor="whiteAlpha.700"
        marginRight={200}
        padding={100}
        size={300}
        key={variationName}
      >
        {variationName}
      </Body>
    ),
  );

  const showPricesByTemplate = !!estimate?.template?.showPricesInEstimation;
  const shouldRenderEstimateTotal =
    !userLightWeightFlow && !isNone && showPricesByTemplate;
  const estimateHasVariations = canAddVariations(estimate);
  const shouldRenderAddColor = estimateHasVariations && estimate?.active;
  const unselectedVariationsCount = getUnselectedVariationsCount(estimate);

  return (
    <Box
      data-testid="estimateOption"
      flexDirection="column"
      marginTop={100}
      {...props}
    >
      <TileRadio
        value={id.toString()}
        data-testid="estimateWrapper"
        isDisabled={isDisabled}
        marginBottom={100}
      >
        <Box flexDirection="column">
          <Box justifyContent="space-between">
            <Body size={400} as="span" data-testid="estimateName">
              <strong>{name}</strong>
            </Body>
            {shouldRenderEstimateTotal && (
              <Body color="neutral.600" size={300} as="span">
                <FormattedNumber
                  format="$0,0.00"
                  value={estimateTotal}
                  data-testid="estimateTotalPrice"
                />
              </Body>
            )}
          </Box>
        </Box>
      </TileRadio>
      {!isNone && shouldRenderAddColor && (
        <Panel
          backgroundColor={
            unselectedVariationsCount > 0 ? 'warning.50' : 'neutral.100'
          }
          marginBottom={100}
          flexDirection="column"
        >
          <Box justifyContent="space-between" paddingBottom={200}>
            <Box as="span" alignItems="center">
              {unselectedVariationsCount > 0 ? (
                <>
                  <Icon
                    display={{
                      mobile: 'none',
                      tablet: 'block',
                      desktop: 'block',
                    }}
                    icon={iAlertCircle}
                    color="warning.600"
                    size="medium"
                  />
                  <Body as="span" size={400}>
                    {`${unselectedVariationsCount} ${pluralize(
                      'variation',
                      unselectedVariationsCount,
                    )} not selected`}
                  </Body>
                </>
              ) : (
                <>
                  <Icon
                    display={{
                      mobile: 'none',
                      tablet: 'block',
                      desktop: 'block',
                    }}
                    icon={iCheck}
                    size="medium"
                  />{' '}
                  All variations selected
                </>
              )}
            </Box>
            <Box>{shouldRenderAddColor && renderAddColor()}</Box>
          </Box>
          <Box flexFlow="wrap">{variationTagsList}</Box>
        </Panel>
      )}
    </Box>
  );
};
