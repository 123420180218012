import { PureComponent } from 'react';

import autobind from 'autobind-decorator';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import { RootState, RootAction } from 'src/types/reduxStore';

import { TradeTypes } from '../../../constants/multiTrades';
import * as actions from '../../../redux/actions';
import { getProductionListTradeTypes } from '../../../redux/selectors/estimatorProductionSelectors';
import Filter from './Filter';
import { Container, FiltersContainer } from './styled';

export const mapStateToProps = (state: RootState) => ({
  tradeFilter: state.estimatorProductionTools.tradeFilter,
  productionListTradeTypes: getProductionListTradeTypes(state),
});

export const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      updateTradeFilter: actions.updateTradeFilter,
    },
    dispatch,
  );

type ComponentProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export class FilterBar extends PureComponent<ComponentProps> {
  componentDidMount() {
    this.setDefaultActiveTrade();
  }

  @autobind
  setDefaultActiveTrade() {
    const { productionListTradeTypes, updateTradeFilter } = this.props;
    if (productionListTradeTypes.length === 1) {
      updateTradeFilter(productionListTradeTypes[0]);
    }
  }

  public render() {
    const { productionListTradeTypes, tradeFilter } = this.props;
    return (
      <Container data-testid="filters-container">
        <FiltersContainer>
          {productionListTradeTypes.length > 1 && (
            <Filter
              isActive={tradeFilter === TradeTypes.ALL_TRADES}
              tradeType={TradeTypes.ALL_TRADES}
              value="All trades"
            />
          )}
          {productionListTradeTypes.map((tradeType: string) => (
            <Filter
              key={tradeType}
              isActive={tradeFilter === tradeType}
              tradeType={tradeType}
            />
          ))}
        </FiltersContainer>
      </Container>
    );
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps, mapDispatchToProps)(FilterBar);
