import React from 'react';

import { Body, Box, Button, Heading, Icon } from '@hover/blueprint';
import { iAlertOctagon } from '@hover/icons';

export const HdfErrorScreen: React.FC<{ goBack: () => void }> = ({
  goBack,
}) => {
  return (
    <Box justifyContent="center" flexDirection="column" alignItems="center">
      <Icon icon={iAlertOctagon} size="large" />

      <Box>
        <Heading>Job does not support hover data format (HDF).</Heading>
      </Box>
      <Box marginBottom={200}>
        <Body>
          Update your project&apos;s measurements to create an estimate or
          material list; Please reach out to support to update.
        </Body>
      </Box>
      <Button
        onClick={() => {
          window.open('https://hover.to/contact/', '_blank');
        }}
        marginBottom={200}
      >
        Contact Us
      </Button>
      <Button fill="minimal" onClick={goBack}>
        Go back
      </Button>
    </Box>
  );
};
