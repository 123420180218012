import {
  OrderCheckType,
  ListItemType,
} from 'src/features/projectManagement/types';

import { ListItemError_Deprecated as ListItemError } from './ListItemError';
import { ErrorListContainer } from './styled';

interface Props {
  orderCheck?: OrderCheckType;
}

export const ErrorList: React.FC<Props> = ({ orderCheck }) => {
  const listItems = orderCheck?.lineItems ?? [];
  if (!listItems?.length) return null;
  return (
    <ErrorListContainer data-testid="errorListContainer">
      {listItems.map((listItem: ListItemType) => (
        <ListItemError key={JSON.stringify(listItem)} listItem={listItem} />
      ))}
    </ErrorListContainer>
  );
};
