import React from 'react';

import {
  Body,
  Box,
  Checkbox,
  Grid,
  Heading,
  HStack,
  Input,
  VStack,
} from '@hover/blueprint';
import { toNumber } from 'lodash';

import { EstimatorFooter } from 'src/features/projectEstimator/components/EstimatorFooter/EstimatorFooter';
import { useTemplateStore } from 'src/features/projectEstimator/hooks';

export const PEFacetSelection: React.FC = () => {
  const templatesFromStore = useTemplateStore((state) => state.templates);
  const updateFacets = useTemplateStore((state) => state.updateFacets);

  return (
    <Box flexDirection="column" height="100vh">
      <Heading>Facet Selection</Heading>
      <Box flex="1" overflowY="auto">
        <Grid templateColumns="repeat(3, 1fr)" gap={6}>
          {Object.entries(templatesFromStore).map(([templateId, template]) => (
            <VStack
              key={templateId}
              spacing={3}
              w="100%"
              align="flex-start"
              borderRadius={400}
              borderWidth={400}
              borderColor="neutral.200"
              border="neutral"
              padding={400}
            >
              <Heading size={400}>
                Template {templateId} - {template.name}
              </Heading>
              <Box
                key={templateId}
                borderWidth="1px"
                borderRadius="lg"
                overflowY="auto"
                maxHeight="500px"
                p={5}
                w="100%"
              >
                <Box width="100%" justifyContent="space-between">
                  <VStack alignItems="flex-start" spacing={3}>
                    {Object.values(template?.facets ?? []).map((facet) => (
                      <HStack key={facet.id} spacing={3}>
                        <Body>{facet.displayLabel}</Body>
                        <Checkbox
                          isChecked={!!facet.checked}
                          onChange={(e) => {
                            updateFacets({
                              facets: {
                                [facet.displayLabel]: {
                                  facetDisplayLabel: facet.displayLabel,
                                  checked: !!e.target.checked,
                                },
                              },
                              templateId: toNumber(templateId),
                            });
                          }}
                        />
                      </HStack>
                    ))}
                  </VStack>
                  <VStack alignItems="flex-end" spacing={3}>
                    {template?.inputs
                      ?.filter(
                        (input) => input.inputCategory?.name === 'measurement',
                      )
                      .map((input) => (
                        <HStack key={input.id} spacing={3}>
                          <Body>{input.name}</Body>
                          <Input
                            width="100px"
                            value={toNumber(input.value)}
                            min={0}
                          />
                        </HStack>
                      ))}
                  </VStack>
                </Box>
              </Box>
            </VStack>
          ))}
        </Grid>
      </Box>
      <HStack>
        <EstimatorFooter nextEnabled />
      </HStack>
    </Box>
  );
};
