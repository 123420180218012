import { useQuery } from '@apollo/client';
import { Body, Box, Heading, Icon, Link } from '@hover/blueprint';
import {
  hCalculator,
  hClipboardCheck,
  hClipboardList,
  iFileText,
} from '@hover/icons';
import { stringify } from 'query-string';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { inspectionChecklist as InspectionChecklistQueryType } from 'src/api/types/inspectionChecklist';
import type { projectManagementProductionList_projectManagementProductionList as ProjectManagementProductionList } from 'src/api/types/projectManagementProductionList';
import { LinkOrComponent } from 'src/components';
import { messages } from 'src/constants/messages';
import { GET_INSPECTION_CHECKLIST } from 'src/features/exteriorEstimator/apis/queries/checklist';
import {
  LinkBody,
  ProjectDetailsPanel,
  SectionHeading,
} from 'src/features/project/components/ProjectScope/ProjectDetails/components';
import { RecalculateMaterialListButton } from 'src/features/project/components/ProjectScope/ProjectDetails/RecalculateMaterialListButton';
import { ToastStatusEnum, useToastEhi, useTracking } from 'src/hooks';
import { COMMERCE_PROJECT_ESTIMATOR, isEnabled } from 'src/lib/FeatureFlag';
import {
  getMaterialListFeature,
  getOrderingOnlyFeatureAccess,
  getOrgSettings,
  getTradeTypesSorted,
  getUserOrgId,
} from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { sentenceCase } from 'src/utils/Formatters';

const enum TOAST_IDS {
  GET_INSPECTION_CHECKLIST_TOAST,
}

interface Props {
  productionList: ProjectManagementProductionList;
}

export const Project: React.FC<Props> = ({ productionList }) => {
  const orgId = useSelector(getUserOrgId);
  const { jobId } = useParams();
  const toast = useToastEhi();
  const showOrderingFlow = useSelector(getOrgSettings)?.showOrderingFlow;
  const { estimateGroup } = productionList;
  const tradeTypesSorted = useSelector(getTradeTypesSorted);
  const materialListFeatureEnabled = useSelector(getMaterialListFeature);
  const orderingOnlyFeatureAccess = useSelector(getOrderingOnlyFeatureAccess);
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const isCommerceProjectEstimatorEnabled = isEnabled(
    COMMERCE_PROJECT_ESTIMATOR,
  );
  const newSummaryUrl = `/project_estimator/estimates/${estimateGroup?.id}/summary?jobId=${estimateGroup?.salesOpportunity?.job?.id}`;
  const oldSummaryUrl = `/estimator/estimates/${estimateGroup?.id}/summary?jobId=${estimateGroup?.salesOpportunity?.job?.id}`;

  const templates = estimateGroup?.estimates
    .filter((estimate) => estimate.active)
    .map((estimate) => estimate.template);

  const { data } = useQuery<InspectionChecklistQueryType>(
    GET_INSPECTION_CHECKLIST,
    {
      onError: () => {
        toast({
          id: TOAST_IDS.GET_INSPECTION_CHECKLIST_TOAST,
          description:
            messages.projectScope.errors.query.productionList
              .getInspectionChecklist,
          status: ToastStatusEnum.ERROR,
        });
      },
      variables: { jobId, orgId },
    },
  );

  const checklist = data?.inspectionChecklist;
  const date = new Date(estimateGroup?.createdAt)
    .toString()
    .split(' ')
    .slice(1, 4)
    .join(' ');

  const showProposalButton = !(showOrderingFlow && orderingOnlyFeatureAccess);

  return (
    <ProjectDetailsPanel data-testid="projectSection">
      <SectionHeading marginBottom={300}>Project</SectionHeading>

      <Box padding={0} margin={0} marginBottom={400} flexDirection="column">
        <Body
          data-testid="projectCreateInfo"
          size={500}
          marginTop={0}
          marginBottom={0}
        >
          {estimateGroup?.user?.name} created project {date}
        </Body>
        <Body size={500} marginTop={0} marginBottom={0}>
          Company: {estimateGroup?.org?.name}
        </Body>
        {materialListFeatureEnabled && (
          <Link
            data-testid="materialList-estimateSummary-link"
            as={RouterLink}
            to={
              isCommerceProjectEstimatorEnabled ? newSummaryUrl : oldSummaryUrl
            }
            target="_blank"
            onClick={() => {
              typewriter.linkPressed({
                page_or_screen_name: EventNames.project.scope.page,
                primary_cta: false,
                link_text: 'Scope Summary',
                ...commonTrackingProps,
              });
            }}
          >
            <LinkBody size={200}>Scope Summary</LinkBody>
          </Link>
        )}
      </Box>

      <Box flexDirection="row" justifyContent="space-between" flex="0 0 140px">
        <Box testId="projectTrades" flexDirection="column" marginBottom={400}>
          <Heading size={300} margin={0}>
            Trades
          </Heading>
          <Box flexDirection="column">
            {tradeTypesSorted?.map((tradeType) => {
              const templateOfTradeType = templates?.find(
                (template) =>
                  template?.tradeType === tradeType.tradeTypeEnumValue,
              );
              return templateOfTradeType ? (
                <Box
                  marginBottom={200}
                  flexDirection="column"
                  key={tradeType.tradeTypeEnumValue}
                >
                  <Body
                    key={templateOfTradeType?.tradeType}
                    size={500}
                    margin={0}
                  >
                    {sentenceCase(templateOfTradeType?.tradeType ?? '')}
                  </Body>
                  <Body key={templateOfTradeType?.name} size={500} margin={0}>
                    [{templateOfTradeType?.name ?? ''}]
                  </Body>
                </Box>
              ) : null;
            })}
          </Box>
        </Box>
        {!materialListFeatureEnabled && (
          <Box flexDirection="column" paddingLeft={300}>
            {!showOrderingFlow && (
              <LinkOrComponent
                disabled={!checklist}
                to={`/estimator/inspection_checklists?jobId=${jobId}&orgId=${orgId}`}
                target="_blank"
                sx={!checklist ? { color: 'neutral500' } : {}}
                marginTop={0}
                marginBottom={0}
                data-testid={`inspectionLink${!!checklist ? '-enabled' : ''}`}
              >
                <Box paddingY={100}>
                  <Icon icon={hClipboardCheck} />
                  <LinkBody size={200} isDisabled={!checklist}>
                    Checklists
                  </LinkBody>
                </Box>
              </LinkOrComponent>
            )}
            <Link
              data-testid="estimateSummary-link"
              as={RouterLink}
              to={{
                pathname: `/estimator/estimates/${estimateGroup?.id}/summary`,
                search: stringify({ jobId, orgId }),
              }}
              target="_blank"
            >
              <Box paddingY={100}>
                <Icon icon={hClipboardList} />
                <LinkBody size={200}>
                  {showOrderingFlow
                    ? 'Calculated materials summary'
                    : 'Estimate inputs summary'}
                </LinkBody>
              </Box>
            </Link>
            {!showOrderingFlow && (
              <Link
                data-testid="estimateDetails-link"
                as={RouterLink}
                to={`/estimator/estimates/${estimateGroup?.id}/details?jobId=${estimateGroup?.salesOpportunity?.job?.id}`}
                target="_blank"
              >
                <Box paddingY={100}>
                  <Icon icon={hCalculator} />
                  <LinkBody size={200}>Estimate details</LinkBody>
                </Box>
              </Link>
            )}
            {showProposalButton && (
              <LinkOrComponent
                data-testid="estimateProposal-link"
                disabled={!estimateGroup?.proposalDocumentsCount}
                as={RouterLink}
                to={`/estimator/estimates/${estimateGroup?.id}/details?jobId=${jobId}&orgId=${orgId}&showProposalModal=true`}
                target="_blank"
                sx={
                  !estimateGroup?.proposalDocumentsCount
                    ? { color: 'neutral500' }
                    : {}
                }
              >
                <Box paddingY={100}>
                  <Icon icon={iFileText} />
                  <LinkBody
                    size={200}
                    isDisabled={!estimateGroup?.proposalDocumentsCount}
                  >
                    Proposal
                  </LinkBody>
                </Box>
              </LinkOrComponent>
            )}
            {showOrderingFlow && orderingOnlyFeatureAccess && (
              <RecalculateMaterialListButton
                salesOpportunityId={
                  productionList.estimateGroup?.salesOpportunity.id
                }
              />
            )}
          </Box>
        )}
      </Box>
    </ProjectDetailsPanel>
  );
};
