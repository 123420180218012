import { Box, Button } from '@hover/blueprint';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { getSelectedTemplateIds } from 'src/features/exteriorEstimator/redux/sagas/selectors';

interface Props {
  show?: boolean;
}

export const HeroActionsBar: React.FC<Props> = ({ show = true }) => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = queryString.parse(location.search, {
    arrayFormat: 'bracket',
  });
  const templateIds = useSelector(getSelectedTemplateIds);

  const handleEditAnswersClick = () => {
    history.replace({
      pathname: '/estimator/questions/select_templates',
      search: queryString.stringify(
        {
          ...searchParams,
          template_ids: templateIds,
        },
        { arrayFormat: 'bracket' },
      ),
    });
  };

  return (
    <Box width={1} justifyContent="flex-end">
      {show && <Button onClick={handleEditAnswersClick}>Edit Answers</Button>}
    </Box>
  );
};
