import { useMutation } from '@apollo/client';
import { Box, Button } from '@hover/blueprint';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import { Divider } from 'src/components/Misc';
import { CREATE_PROPOSAL_DOCUMENT } from 'src/features/exteriorEstimator/apis/queries/proposal';
import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import {
  getJobDetails,
  getUserProfile,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { ProposalOrgSettings } from 'src/features/exteriorEstimator/types';
import { useLocalStorage, useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { jobProps } from 'src/utils/trackingUtils';

import { CustomerDetails } from './CustomerDetails';
import { Note } from './Note';
import { ProposalOptions } from './ProposalOptions';

type Customer = {
  name: string;
  email: string;
  phoneNumber?: string;
};

type FormProps = {
  fullName?: string;
  email?: string;
  note?: string;
  phone?: string;
  includeAllEstimateItems: boolean;
  includeItemCost: boolean;
  includeItemQuantities: boolean;
  includeTradePrice: boolean;
  includeTotalPrice: boolean;
  customers: Customer[];
};

export type Props = ProposalOrgSettings & {
  estimateGroupId: string;
  isLoading: boolean;
  hidePriceToggles: boolean;
};

export const ProposalCreateBody: React.FC<Props> = ({
  estimateGroupId,
  allowDefaultOverrideIncludeAllEstimateItems,
  allowDefaultOverrideIncludeItemCost,
  allowDefaultOverrideIncludeItemQuantities,
  allowDefaultOverrideIncludeTradePrice,
  allowDefaultOverrideIncludeTotalPrice,
  includeAllEstimateItemsDefault,
  includeItemCostDefault,
  includeItemQuantitiesDefault,
  includeTradePriceDefault,
  includeTotalPriceDefault,
  maxSigneeCount,
  isLoading,
  defaultNote,
  hidePriceToggles,
}) => {
  const jobDetails = useSelector(getJobDetails);
  const jobName = jobDetails?.name ?? '';
  const captureRequest = jobDetails?.captureRequest;
  const userProfile = useSelector(getUserProfile);
  const dispatch = useDispatch();

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const [fullNameInitialValue] = useLocalStorage(
    `${jobDetails?.id}.proposalData.jobName`,
    jobName,
  );
  const [emailInitialValue] = useLocalStorage(
    `${jobDetails?.id}.proposalData.email`,
    '',
  );
  const [phoneInitialValue] = useLocalStorage(
    `${jobDetails?.id}.proposalData.phone`,
    '',
  );
  const [noteInitialValue] = useLocalStorage(
    `${jobDetails?.id}.proposalData.note`,
    defaultNote,
  );

  const [customersInitialValue] = useLocalStorage(
    `${jobDetails?.id}.proposalData.customers`,
    [
      {
        // if `Connect` job (ie capture request), prefill customer data using the captureRequest data
        name: captureRequest?.capturingUserName || jobName,
        email: captureRequest?.capturingUserEmail || '',
        phoneNumber: captureRequest?.capturingUserPhone || '',
      },
    ],
  );

  const [createProposal] = useMutation(CREATE_PROPOSAL_DOCUMENT, {
    onCompleted: ({ projectManagementProposalDocumentCreate }) => {
      const { errors, proposalDocument } =
        projectManagementProposalDocumentCreate;
      if (errors.length) {
        dispatch(estimatorActions.createProposal.failure(errors));
      } else {
        dispatch(estimatorActions.createProposal.success(proposalDocument));
      }
    },
    onError: () => {
      dispatch(estimatorActions.setError(true));
    },
  });

  const {
    register,
    handleSubmit,
    formState,
    formState: { isValid },
    reset,
    ...methods
  } = useForm({
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues: {
      fullName: fullNameInitialValue,
      email: emailInitialValue,
      customers: customersInitialValue,
      note: noteInitialValue,
      phone: phoneInitialValue,
      includeAllEstimateItems: includeAllEstimateItemsDefault,
      includeItemCost: includeItemCostDefault,
      includeItemQuantities: includeItemQuantitiesDefault,
      includeTradePrice: includeTradePriceDefault,
      includeTotalPrice: includeTotalPriceDefault,
    },
  });

  const onSubmit: SubmitHandler<FormProps> = (data) => {
    const {
      customers,
      fullName,
      email,
      note,
      phone,
      includeAllEstimateItems,
      includeItemCost,
      includeItemQuantities,
      includeTradePrice,
      includeTotalPrice,
    } = data;

    dispatch(estimatorActions.clearProposalsError());

    typewriter.buttonPressed({
      button_text: 'Generate Proposal',
      page_or_screen_name:
        EventNames.estimator.estimateDetailsScreen.proposal.page,
      primary_cta: false,
      ...jobProps(jobDetails),
      ...commonTrackingProps,
    });
    const contractorRepresentativeName = `${userProfile?.firstName} ${userProfile?.lastName}`;

    const proposalDocumentAttributes = {
      estimateGroupId: estimateGroupId.toString(),
      name: `Proposal ${jobName}`,
      notes: note,
      includeAllEstimateItems,
      includeItemCost,
      includeItemQuantities,
      includeTradePrice,
      includeTotalPrice,
      customerName: fullName ?? '',
      customerEmail: email || customers?.[0]?.email,
      customerPhoneNumber: phone,
      customersAttributes: customers,

      contractorName: userProfile?.orgs[0]?.name ?? '',
      contractorRepresentativeName,
      contractorRepresentativeEmail: userProfile?.email ?? '',

      customerAddressCity: jobDetails?.locationCity,
      customerAddressLine1: jobDetails?.locationLine1,
      customerAddressLine2: jobDetails?.locationLine2,
      customerAddressPostalCode: jobDetails?.locationPostalCode,
      customerAddressState: jobDetails?.locationRegion,

      contractorAddressCity: userProfile?.orgs?.[0]?.custServCity,
      contractorAddressLine1: userProfile?.orgs?.[0]?.custServLine1,
      contractorAddressLine2: userProfile?.orgs?.[0]?.custServLine2,
      contractorAddressPostalCode: userProfile?.orgs?.[0]?.custServPostalCode,
      contractorAddressState: userProfile?.orgs?.[0]?.custServRegion,
      contractorPhoneNumber: userProfile?.orgs?.[0]?.custServPhone,
      contractorRepresentativePhoneNumber:
        userProfile?.mobilePhone ?? userProfile?.officePhone,
    };

    dispatch(
      estimatorActions.createProposal.request(proposalDocumentAttributes),
    );
    createProposal({
      variables: {
        proposalDocumentAttributes,
      },
    });
  };

  return (
    <FormProvider {...{ register, formState, handleSubmit, reset, ...methods }}>
      <Box
        width={1}
        flex={1}
        flexShrink={0}
        flexDirection="column"
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          flexDirection="column"
          flex={1}
          padding={400}
          flexShrink={0}
          as="section"
        >
          <CustomerDetails
            maxSigneeCount={maxSigneeCount}
            estimateGroupId={estimateGroupId}
          />
          <Note />
          <ProposalOptions
            allowDefaultOverrideIncludeTotalPrice={
              allowDefaultOverrideIncludeTotalPrice
            }
            allowDefaultOverrideIncludeTradePrice={
              allowDefaultOverrideIncludeTradePrice
            }
            allowDefaultOverrideIncludeAllEstimateItems={
              allowDefaultOverrideIncludeAllEstimateItems
            }
            allowDefaultOverrideIncludeItemCost={
              allowDefaultOverrideIncludeItemCost
            }
            allowDefaultOverrideIncludeItemQuantities={
              allowDefaultOverrideIncludeItemQuantities
            }
            estimateGroupId={estimateGroupId}
            includeTradePriceDefault={includeTradePriceDefault}
            includeItemCostDefault={includeItemCostDefault}
            includeItemQuantitiesDefault={includeItemQuantitiesDefault}
            hidePriceToggles={hidePriceToggles}
          />
        </Box>
        <Divider margin="1em 0 0 0" />
        <Box padding={400} flexShrink={0}>
          <Button
            size="large"
            flex={1}
            type="submit"
            data-testid="submitButton"
            isDisabled={!isValid || isLoading}
          >
            Generate
          </Button>
        </Box>
      </Box>
    </FormProvider>
  );
};
