import { createSelector } from 'reselect';

import {
  PARTIAL_SIDING_SELECTION,
  ROOF_FACET_SELECTION,
  ROOF_FACET_SELECTION_3D,
  ROOF_LINE_SEGMENT_ADJUSTMENT,
  SIDING_FACET_SELECTION_3D,
  SIDING_LINE_SEGMENT_ADJUSTMENT,
  STONE_FACET_SELECTION_3D,
} from 'src/features/exteriorEstimator/constants/questionCategories';

import { getPages } from './estimatorSelectors';

export const getSidingFacetInputs = createSelector(getPages, (pages) => {
  return (
    pages
      ?.find?.((page) => page.category === SIDING_FACET_SELECTION_3D)
      ?.tabs?.find?.((tab) => tab.category === PARTIAL_SIDING_SELECTION)
      ?.questions ?? []
  );
});

export const getStoneFacetInputs = createSelector(getPages, (pages) => {
  return (
    pages
      ?.find?.((page) => page.category === STONE_FACET_SELECTION_3D)
      ?.tabs?.find?.((tab) => tab.category === PARTIAL_SIDING_SELECTION)
      ?.questions ?? []
  );
});

export const getSidingLineSegmentInputs = createSelector(getPages, (pages) => {
  return (
    pages
      ?.find?.((page) => page.category === SIDING_FACET_SELECTION_3D)
      ?.tabs?.find?.((tab) => tab.category === SIDING_LINE_SEGMENT_ADJUSTMENT)
      ?.questions ?? []
  );
});

export const getStoneLineSegmentInputs = createSelector(getPages, (pages) => {
  return (
    pages
      ?.find?.((page) => page.category === STONE_FACET_SELECTION_3D)
      ?.tabs?.find?.((tab) => tab.category === SIDING_LINE_SEGMENT_ADJUSTMENT)
      ?.questions ?? []
  );
});

export const getRoofLineSegmentInputs = createSelector(getPages, (pages) => {
  return (
    pages
      ?.find?.((page) => page.category === ROOF_FACET_SELECTION_3D)
      ?.tabs?.find?.((tab) => tab.category === ROOF_LINE_SEGMENT_ADJUSTMENT)
      ?.questions ?? []
  );
});

export const getRoofFacetInputs = createSelector(getPages, (pages) => {
  return (
    pages
      ?.find?.((page) => page.category === ROOF_FACET_SELECTION_3D)
      ?.tabs?.find?.((tab) => tab.category === ROOF_FACET_SELECTION)
      ?.questions ?? []
  );
});
