import { isNil } from 'lodash';
import { createSelector } from 'reselect';

import { ACTIVE_COMMERCE_PRODUCT_ORDERING_ONLY } from 'src/lib/FeatureFlag';
import { getCommonProps } from 'src/lib/tracking';
import { RootState } from 'src/types/reduxStore';

export const getUserProfile = (state: RootState) => state.hover.userProfile;
export const getAuthStatus = (state: RootState) =>
  state.hover.authenticationStatus;
export const getSplitInitStatus = (state: RootState) =>
  state.hover.isSplitInitialized;

export const getUserTrackingProps = createSelector(
  getUserProfile,
  (userProfile) => {
    if (isNil(userProfile)) return {};
    return getCommonProps(userProfile);
  },
);

export const getImpersonator = (state: RootState) => state.hover?.impersonator;

export const getOrgFeatures = (state: RootState) => {
  const orgFeatureAccesses =
    state.hover?.userProfile?.orgs?.[0]?.featureAccesses || [];

  return orgFeatureAccesses;
};

export const getActiveCommerceProduct = (state: RootState) => {
  return state.hover?.userProfile?.activeCommerceProduct;
};

export const getOrderingOnlyFeatureAccess = (state: RootState) =>
  state.hover?.userProfile?.activeCommerceProduct === 'ordering_only';

export const getMaterialListFeature = (state: RootState) =>
  state.hover?.userProfile?.activeCommerceProduct ===
  'out_of_the_box_configuration';
