import { useCallback } from 'react';

import { HStack, IconButton, TextInput } from '@hover/blueprint';
import { InputGroup, InputRightElement } from '@hover/blueprint/chakra';
import { iSearch, iX } from '@hover/icons';
import { Controller, useForm } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form';

type Props = {
  searchQuery?: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string | null>>;
};

export const SearchBar: React.FC<Props> = ({
  searchQuery = '',
  setSearchQuery,
}) => {
  const {
    setValue,
    control,
    formState: { isValid, isDirty },
    handleSubmit,
    setFocus,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      search: searchQuery,
    },
  });

  const handleClear = useCallback(() => {
    setFocus('search');
    setSearchQuery(null);
    setValue('search', '', { shouldDirty: true });
  }, [setFocus, setSearchQuery, setValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setSearchQuery(null);
    }
  };

  const onSubmit: SubmitHandler<{ search: string }> = ({ search }) => {
    setSearchQuery(search);
  };

  return (
    <HStack as="form" onSubmit={handleSubmit(onSubmit)}>
      <InputGroup>
        <Controller
          control={control}
          name="search"
          rules={{
            minLength: 3,
          }}
          render={({ field: { onChange, ...rest } }) => (
            <TextInput
              {...rest}
              placeholder="Search for project or address..."
              type="text"
              onChange={(e) => {
                onChange(e);
                handleChange(e);
              }}
              data-testid="searchBar"
            />
          )}
        />

        <InputRightElement>
          {isDirty && (
            <IconButton
              icon={iX}
              shape="circle"
              onClick={handleClear}
              fill="minimal"
              label="clear"
              color="neutral"
              data-testid="clearButtonIcon"
            />
          )}
        </InputRightElement>
      </InputGroup>
      <IconButton
        type="submit"
        icon={iSearch}
        shape="square"
        isDisabled={!isDirty || !isValid}
        label="search"
        size="large"
        data-testid="submitButton"
      />
    </HStack>
  );
};
