import { Loader, LoaderProps } from '@hover/blueprint';
import styled, { css } from 'styled-components';

export const OverlayContainer = styled.div<{
  width?: string;
  height?: string;
  show: boolean;
}>`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: ${({ show }) => (show ? 1002 : -1002)};
  background-color: ${({ show }) =>
    show ? 'rgba(255, 255, 255, 0.4)' : 'rgba(255, 255, 255, 0)'};
  transition: background-color 0.15s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ width, height }) => css`
    width: ${width};
    height: ${height};
  `}
`;

interface Props extends LoaderProps {
  show?: boolean;
  width?: string;
  height?: string;
}

export const LoaderSpinner: React.FC<Props> = ({
  width = '100vw',
  height = '100vh',
  show = false,
  label = 'loading',
  size = 'huge',
  ...loaderProps
}) => {
  return (
    <OverlayContainer
      data-testid="loader-spinner-overlay"
      width={width}
      height={height}
      show={show}
    >
      {show && <Loader label={label} size={size} {...loaderProps} />}
    </OverlayContainer>
  );
};
