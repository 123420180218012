import { TradeTypeCategoryEnum } from 'src/api/graphql-global-types';
import { tradeTypes_tradeTypes as TradeType } from 'src/api/types/tradeTypes';
import { TemplateStateType } from 'src/features/projectEstimator/types';

export const isRoofTemplate = (
  template: TemplateStateType,
  tradeTypesSorted: TradeType[],
) => {
  return !!tradeTypesSorted.find(
    (tradeType) =>
      tradeType.tradeTypeCategoryEnumValue === TradeTypeCategoryEnum.ROOF &&
      tradeType.tradeTypeEnumValue === template.tradeType,
  );
};

export const isSidingTemplate = (
  template: TemplateStateType,
  tradeTypesSorted: TradeType[],
) => {
  return !!tradeTypesSorted.find(
    (tradeType) =>
      tradeType.tradeTypeCategoryEnumValue === TradeTypeCategoryEnum.SIDING &&
      tradeType.tradeTypeEnumValue === template.tradeType,
  );
};
