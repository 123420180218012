import { useCallback, useEffect, useState } from 'react';

import { ApolloError, isApolloError } from '@apollo/client';
import { Box, Body, Heading, Image } from '@hover/blueprint';
import { useFormContext } from 'react-hook-form';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import PONumberInput from 'src/features/projectManagement/components/ProductionView/OrderModal/Content/OrderDetailsForm/PONumber';
import * as actions from 'src/features/projectManagement/redux/actions';
import {
  getVendorForOrder,
  getVendorForOrderMaterialListItems,
  getJobDetails,
  getDistributorCapabilities,
} from 'src/features/projectManagement/redux/selectors';
import { RootAction, RootState } from 'src/types/reduxStore';
import { SelectOrText as EHISelect } from 'style-guide';

import { Loading } from '../../OrderModal/Content/Loading';
import type { OrderFormFields } from '../Checkout';
import { BillingField } from './BillingField';

export const getJobAccountsForBranch = (
  branchesList: ReadonlyArray<Branch>,
  defaultBranch: string | undefined,
): ReadonlyArray<JobAccount> => {
  if (branchesList.length === 1) {
    // Only one branch
    return branchesList[0].jobAccounts;
  }
  if (!!defaultBranch && branchesList.length >= 1) {
    // More than one branch with default branch
    const foundBranch = branchesList.find((item) => item.id === defaultBranch);
    if (!!foundBranch) {
      return foundBranch.jobAccounts;
    }
    return [];
  }
  // No branches, or No default branch
  return [];
};

const mapStateToProps = (state: RootState) => ({
  billingFormFields: state.estimatorProductionTools?.billingFormValues,
  jobAccount: undefined,
  jobName: getJobDetails(state)?.name || undefined,
  listItems: getVendorForOrderMaterialListItems(state),
  vendor: getVendorForOrder(state),
});

export const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      updateBillingForm: actions.updateBillingForm,
    },
    dispatch,
  );

export type JobAccount = { displayName: string; id: string };
export type Branch = {
  name: string;
  id: string;
  jobAccounts: ReadonlyArray<JobAccount>;
  defaultJobAccount: JobAccount;
};

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    branches: ReadonlyArray<Branch>;
    loadingBranches: boolean;
    error?: ApolloError;
  };

export const BillingComponent: React.FC<Props> = ({
  vendor,
  jobName: initialJobName,
  jobAccount: initialJobAccount = 'MAIN ACCOUNT',
  updateBillingForm,
  billingFormFields,
  branches = [],
  loadingBranches = false,
  error,
}) => {
  const logo = vendor?.distributor?.logo?.redirectUrl;
  const branchId = vendor?.branch?.id;
  const vendorName = vendor?.vendorName;

  const jobAccount = billingFormFields?.values?.jobAccount;
  const jobName = billingFormFields?.values?.jobName;

  const requiresOrderJobAccount: boolean =
    useSelector(getDistributorCapabilities)?.requiresOrderJobAccount ?? false;
  const NO_BRANCHES_TEXT = 'No existing branches';
  const NO_JOB_ACCOUNTS_TEXT = 'No existing job accounts';

  const [loadingJobAccounts, setLoadingJobAccounts] = useState(true);

  const [jobAccounts, setJobAccounts] = useState<ReadonlyArray<JobAccount>>([]);
  const [selectedBranchId, setSelectedBranchId] = useState<string>(
    branchId ?? '',
  );
  const [selectedJobAccountId, setSelectedJobAccountId] = useState<string>();

  const handleBlur = useCallback(
    (
      event:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLSelectElement>,
    ) => {
      const { name, value } = event.target;
      updateBillingForm({ values: { [name]: value } });
    },
    [updateBillingForm],
  );

  useEffect(() => {
    // form values update onBlur,
    // so need to update any initialized values user might not interact with on component mount
    updateBillingForm({
      values: {
        jobAccount: initialJobAccount,
        jobName: initialJobName,
      },
    });
  }, [updateBillingForm, jobAccount, initialJobName, initialJobAccount]);

  const { setValue } = useFormContext<OrderFormFields>();
  /**
   * On branch change, obtain the set of jobAccounts associated
   * with the newly-selected branch, provide to jobAccounts dropdown.
   */
  const onBranchChange = useCallback(
    (selectedBranch: string) => {
      setSelectedBranchId(selectedBranch);
      const foundBranch = branches.find((item: Branch) => {
        return item.id === selectedBranch;
      });
      const foundJobAccounts = foundBranch?.jobAccounts;
      setJobAccounts(foundJobAccounts || []);
      const defaultAccount = foundBranch?.defaultJobAccount;
      setSelectedJobAccountId(defaultAccount?.id ?? '');
      setValue('distributionJobAccountId', defaultAccount?.id ?? '', {
        shouldValidate: true,
      });
    },
    [branches, setValue],
  );
  const handleBranchChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedBranch = event.currentTarget.value;
      onBranchChange(selectedBranch);
    },
    [onBranchChange],
  );
  /**
   * On job account change, update the state value used by the jobAccounts dropdown.
   */
  const handleJobAccountChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const jobAccountId = event.currentTarget.value;
      setSelectedJobAccountId(jobAccountId ?? '');
    },
    [],
  );

  const selectDefaultBranch = (
    hasBranches: boolean,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    branches: ReadonlyArray<Branch>,
  ) => {
    if (hasBranches && branches.length === 1) {
      const branch = branches[0].id;
      setValue('distributionBranchId', branch, {
        shouldValidate: true,
      });
      onBranchChange(branch);
    } else {
      setValue('distributionBranchId', '', { shouldValidate: true });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const setDefaultJobAccount = (branches: ReadonlyArray<Branch>) => {
    // When a default job account is provided, find the job account in the
    // job accounts list, to select it in the dropdown.
    const foundBranch = branches.find((item: Branch) => {
      return item.id === branchId;
    });
    const defaultAccount = foundBranch?.defaultJobAccount;
    setSelectedJobAccountId(defaultAccount?.id);
    // If there's no default job account provided, then force validation
    // to trigger validation error.
    setValue('distributionJobAccountId', defaultAccount?.id ?? '', {
      shouldValidate: !defaultAccount,
    });
  };

  /**
   * On first render, resolve the available job accounts based on the
   * current/default branch.  (With no default branch or with a default branch,
   * there may or may not be an associated job account.)  Set the form state
   * to reflect initial selection state and triger validation when applicable.
   */
  useEffect(() => {
    const isError = !(!error || !isApolloError(error));
    if (loadingBranches || isError) {
      return;
    }
    const hasBranches = branches?.length > 0;
    const isJobAccountsSet = !(!jobAccounts || jobAccounts.length === 0);
    if (hasBranches && !isJobAccountsSet) {
      // If there's no default branch provided, then force validation
      // to trigger validation error; unless there's only one branch
      // available, then select that branch.
      if (!branchId) {
        selectDefaultBranch(hasBranches, branches);
      } else {
        setValue('distributionBranchId', branchId);
        // Get job accounts for the branch, and save in local state, trigger
        // form validation if no default account.
        const distrJobAccounts = getJobAccountsForBranch(branches, branchId);
        setJobAccounts(distrJobAccounts);
        setDefaultJobAccount(branches);
      }
    }
    setLoadingJobAccounts(false);

    // We don't want this effect to run when the jobAccounts state changes,
    // so omitting that from the effect deps.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchId, branches, error, loadingBranches, setJobAccounts]);
  return (
    <Box display="block" flex={1} testId="billingContainer">
      <>
        {loadingJobAccounts ? (
          <Loading style={{ position: 'absolute' }} />
        ) : null}
        <Box>
          <Heading size={500}>Branch</Heading>
        </Box>
        <Box maxHeight="62px" height="62px" alignItems="flex-end">
          <Box width={0.7} flexDirection="column" alignSelf="center">
            <EHISelect<'name', 'id'>
              name="distributionBranchId"
              id="distributionBranchId"
              data-testid="distributionBranchId"
              options={
                (!error || !isApolloError(error)) && branches?.length > 0
                  ? branches
                  : [{ name: NO_BRANCHES_TEXT, id: '' }]
              }
              onChange={handleBranchChange}
              value={selectedBranchId}
              displayProperty="name"
              valueProperty="id"
              rules={{ required: 'Branch is required' }}
            />
            <Body size={400} margin={0}>
              {/* render distributor.address data once available */}
            </Body>
          </Box>
          <Box width={0.3} height="40px" alignSelf="center">
            <Image src={logo} alt="logo" />
          </Box>
        </Box>
        <Box paddingTop={400}>
          <Heading size={500}>Billing</Heading>
        </Box>
        <Box flexDirection="column" width={0.7}>
          <EHISelect<'displayName', 'id'>
            name="distributionJobAccountId"
            id="distributionJobAccountId"
            data-testid="distributionJobAccountId"
            label="Job account"
            options={
              (!error || !isApolloError(error)) && jobAccounts?.length > 0
                ? jobAccounts
                : [{ displayName: NO_JOB_ACCOUNTS_TEXT, id: '' }]
            }
            value={selectedJobAccountId || ''}
            onChange={handleJobAccountChange}
            displayProperty="displayName"
            valueProperty="id"
            // jobAccount form field is required based on value of distributor capability 'requiresOrderJobAccount'
            isRequired={requiresOrderJobAccount}
          />
        </Box>
        <Box flexDirection="row" width={0.7} justifyContent="space-between">
          <PONumberInput width={0.4} labelSize={500} />
          <BillingField
            width={0.55}
            label="Job Name (optional)"
            name="jobName"
            value={jobName}
            initialValue={initialJobName}
            onBlur={handleBlur}
          />
        </Box>
      </>
    </Box>
  );
};

export const Billing = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BillingComponent);
