import {
  Alert,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@hover/blueprint';
import { iAlertTriangle } from '@hover/icons';
import { useSelector } from 'react-redux';

import { TradeTypeEnum } from 'src/api/graphql-global-types';
import { UpdateAnswer } from 'src/features/exteriorEstimator/redux/actions/answerActions';
import {
  getCurrentQuestionCategory,
  getRoofLineSegmentInputs,
  getSidingLineSegmentInputs,
  getStoneLineSegmentInputs,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import {
  FacetPageTradeTypes,
  QuestionResponses,
} from 'src/features/exteriorEstimator/types';
import { useTracking } from 'src/hooks';

import { LineSegmentsPageInputs } from './LineSegmentsPageInputs';
import { SelectionTiles } from './SelectionTiles';

type Props = {
  trade: FacetPageTradeTypes;
  updateAnswer: (updateProps: UpdateAnswer) => void;
  updateAnswers: (responses: QuestionResponses) => void;
  measurementsWarning?: string;
};

export const TabsSections: React.FC<Props> = ({
  trade,
  updateAnswer,
  updateAnswers,
  measurementsWarning,
}) => {
  const sidingLineSegmentInputs = useSelector(getSidingLineSegmentInputs);
  const stoneLineSegmentInputs = useSelector(getStoneLineSegmentInputs);
  const roofLineSegmentInputs = useSelector(getRoofLineSegmentInputs);

  const currentQuestionCategory = useSelector(getCurrentQuestionCategory);
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const inputsToRender = {
    ROOF: roofLineSegmentInputs,
    SIDING: sidingLineSegmentInputs,
    STONE: stoneLineSegmentInputs,
  }[trade];

  const selectionTabText = {
    ROOF: 'Facets Selection',
    SIDING: 'Facades Selection',
    STONE: 'Facades Selection',
  }[trade];

  const handleClick = (text: string) => {
    typewriter.linkPressed({
      link_text: text,
      page_or_screen_name: currentQuestionCategory ?? '',
      primary_cta: false,
      link_location: 'Tabs',
      ...commonTrackingProps,
    });
  };

  return (
    <Tabs
      isLazy // ensures line segment values get updated when selecting facets
      flex={1}
      overflowY="scroll"
      flexDirection="column"
      padding={300}
    >
      {trade === TradeTypeEnum.SIDING && (
        <Alert icon={iAlertTriangle} status="warning" size="small">
          Select all primary and accent siding facades to be included in this
          project.
        </Alert>
      )}

      <TabList backgroundColor="background" gap={500} padding={300}>
        <Tab onClick={() => handleClick(selectionTabText)} paddingX={0}>
          {selectionTabText}
        </Tab>
        <Tab onClick={() => handleClick('Line Segments')} paddingX={0}>
          Line Segments
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <SelectionTiles trade={trade} updateAnswers={updateAnswers} />
        </TabPanel>
        <TabPanel>
          <LineSegmentsPageInputs
            updateAnswer={updateAnswer}
            questions={inputsToRender}
            measurementsWarning={measurementsWarning}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
