import { useCallback, useState } from 'react';

import { Button } from '@hover/blueprint';
import * as Sentry from '@sentry/react';
import { useDispatch, useSelector } from 'react-redux';

import { BottomBarLayout } from 'src/features/exteriorEstimator/components/EstimationTool/BottomBar/BottomBarLayout';
import { useEstimate } from 'src/features/exteriorEstimator/hooks';
import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import {
  getTemplates,
  getSelectedTemplateIds,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { generateEstimatorWithTemplatesUrl } from 'src/features/exteriorEstimator/utils/miscUtils';
import { useIsMobileBreakpoint } from 'src/hooks/useIsMobileBreakpoint';
import {
  getMaterialListFeature,
  getOrderingOnlyFeatureAccess,
  getOrgSettings,
} from 'src/redux/selectors';

interface Props {
  children: React.ReactNode;
  setIsInitializing: (value: boolean) => void;
}

export const BottomBarSelectTemplates: React.FC<Props> = (props: Props) => {
  const { children, setIsInitializing } = props;
  const templates = useSelector(getTemplates);
  const selectedTemplateIds = useSelector(getSelectedTemplateIds);
  const orgSettings = useSelector(getOrgSettings);
  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(false);
  const { setupEstimator } = useEstimate();
  const dispatch = useDispatch();
  const isMobile = useIsMobileBreakpoint();

  const showOrderingFlow = orgSettings?.showOrderingFlow;
  const showOrderingVersion =
    useSelector(getOrderingOnlyFeatureAccess) && showOrderingFlow;
  const materialListFeatureEnabled = useSelector(getMaterialListFeature);
  const showOrderOrMaterialListVersion =
    showOrderingVersion || materialListFeatureEnabled;

  const handleDeselectAll = useCallback(() => {
    dispatch(estimatorActions.deselectAllTemplates());
  }, [dispatch]);

  const handleNextClick = useCallback(async () => {
    setIsNextDisabled(true);
    window.location.href = generateEstimatorWithTemplatesUrl(
      window.location.href,
      selectedTemplateIds || [],
    );

    if (!selectedTemplateIds || !templates) return;
    try {
      await setupEstimator({
        currentlySelectedTemplates: selectedTemplateIds,
        templates,
        setIsInitializing,
        showOrderingVersion: !!showOrderOrMaterialListVersion,
        orgSettings,
      });
    } catch (e) {
      dispatch(estimatorActions.setError(e as boolean));
      Sentry.captureException(e);
    }
  }, [
    selectedTemplateIds,
    templates,
    setupEstimator,
    setIsInitializing,
    showOrderOrMaterialListVersion,
    orgSettings,
    dispatch,
  ]);

  const needTemplateSelection =
    !selectedTemplateIds || selectedTemplateIds.length === 0;
  const shouldDisableNextAction = needTemplateSelection;

  return (
    <BottomBarLayout>
      <Button
        fill="outline"
        shape="pill"
        size="large"
        onClick={handleDeselectAll}
        data-testid="resetAllTemplates"
        isDisabled={needTemplateSelection}
      >
        Reset all
      </Button>
      {isMobile ? null : children}
      <Button
        shape="pill"
        size="large"
        onClick={handleNextClick}
        isDisabled={shouldDisableNextAction}
        isLoading={isNextDisabled}
        data-testid="estimatorNextButton"
      >
        Continue {isMobile ? `(${selectedTemplateIds?.length})` : ''}
      </Button>
    </BottomBarLayout>
  );
};
