import { PureComponent } from 'react';

import autobind from 'autobind-decorator';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch, bindActionCreators } from 'redux';

import { RootState, RootAction } from 'src/types/reduxStore';

import * as actions from '../../../redux/actions';
import { Modal, Button } from './styled';

export const mapStateToProps = (state: RootState) => ({
  errorModal: state.estimatorProductionTools.errorModal,
});

export const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      updateErrorModal: actions.updateErrorModal,
    },
    dispatch,
  );

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export class ErrorModal extends PureComponent<Props> {
  @autobind
  handleCancelClick() {
    const { updateErrorModal } = this.props;
    updateErrorModal({
      isOpen: false,
      message: null,
    });
  }

  public render() {
    const {
      errorModal: { isOpen, message },
    } = this.props;
    const mes = message || 'An unknown error occurred';
    return (
      <Modal
        isOpen={isOpen}
        closeButton={this.handleCancelClick}
        action={
          <Link to="estimates">
            <Button testId="errorModalButton">Okay</Button>
          </Link>
        }
      >
        {mes}
      </Modal>
    );
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);
