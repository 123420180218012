import { useCallback } from 'react';

import { useMutation } from '@apollo/client';
import { Box, Button, Form, LoadingOverlay, Panel } from '@hover/blueprint';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';

import type { projectManagementConfigOrgProposalSettingSelfService_projectManagementConfigOrgProposalSetting as OrgProposalSetting } from 'src/api/types/projectManagementConfigOrgProposalSettingSelfService';
import { UPDATE_PROPOSAL_SETTING } from 'src/features/settings/api/queries/proposals';
import type { ViewMode } from 'src/features/settings/components/Proposal/Proposal';
import { ProposalActiveStatus } from 'src/features/settings/components/Proposal/ProposalActiveStatus';
import { ProposalMaxSignee } from 'src/features/settings/components/Proposal/ProposalMaxSignee';
import { ProposalTemplateNote } from 'src/features/settings/components/Proposal/ProposalTemplateNote';
import { useToastEhi, ToastStatusEnum } from 'src/hooks';
import { getUserOrgId } from 'src/redux/selectors';

type Props = {
  viewMode: ViewMode;
  projectManagementConfigOrgProposalSetting?: OrgProposalSetting | null;
};

export const ProposalSettings: React.FC<Props> = ({
  viewMode,
  projectManagementConfigOrgProposalSetting,
}) => {
  const orgId = useSelector(getUserOrgId);
  const toast = useToastEhi();

  const { maxSigneeCount, proposalsEnabled, defaultNote } =
    projectManagementConfigOrgProposalSetting ?? {};

  const { ...methods } = useForm({
    mode: 'onChange',
    defaultValues: {
      proposalsEnabled,
      maxSigneeCount: maxSigneeCount?.toString(), // Select component deals in strings
      templateProposalNote: defaultNote,
    },
  });

  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = methods;

  const onCancel = useCallback(() => reset(), [reset]);

  const [updateProposalSetting, { loading: loadingUpdateProposal }] =
    useMutation(UPDATE_PROPOSAL_SETTING, {
      onCompleted: () => {
        toast({
          description: 'Proposal changes saved',
          position: 'bottom',
          status: ToastStatusEnum.SUCCESS,
        });
        reset(getValues());
      },
      onError: () => {
        toast({
          description: 'Proposal changes not saved. Try again',
          position: 'bottom',
          status: ToastStatusEnum.ERROR,
        });
      },
    });

  const submitProposalSettings: SubmitHandler<{
    templateProposalNote: string;
    maxSigneeCount: string;
    proposalsEnabled: boolean;
  }> = useCallback(
    (data) => {
      updateProposalSetting({
        variables: {
          orgId,
          attributes: {
            defaultNote: data.templateProposalNote,
            maxSigneeCount: Number(data.maxSigneeCount),
            proposalsEnabled: data.proposalsEnabled,
          },
        },
      });
    },
    [orgId, updateProposalSetting],
  );

  return (
    <FormProvider {...methods}>
      <LoadingOverlay isLoading={loadingUpdateProposal} />
      <Panel
        as={Form}
        flexDirection="column"
        boxShadow="0px 2px 6px rgba(118, 118, 118, 0.4)"
        marginBottom={400}
        padding={600}
        onSubmit={handleSubmit(submitProposalSettings)}
      >
        <ProposalActiveStatus viewMode={viewMode} />
        <ProposalMaxSignee />
        <ProposalTemplateNote />
        <Box flexDirection="row" marginTop={500}>
          <Button
            data-testid="templateProposalNoteSave"
            type="submit"
            isDisabled={!isDirty}
          >
            Save
          </Button>
          <Button
            data-testid="templateProposalNoteCancel"
            marginLeft={300}
            fill="outline"
            isDisabled={!isDirty}
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Box>
      </Panel>
    </FormProvider>
  );
};
