import HoverHeader from '@hover/header';
import { useSelector } from 'react-redux';

import appSettings from 'src/appSettings';
import { useIsMobileBreakpoint } from 'src/hooks/useIsMobileBreakpoint';
import { getIsOrgAdmin } from 'src/redux/selectors';

type Props = {
  hideOnMobile?: boolean;
};

/**
 * use this instead of importing @hover/header directly
 */
export const EHIHoverHeader: React.FC<Props> = ({
  children,
  hideOnMobile = false,
  ...props
}) => {
  const isMobile = useIsMobileBreakpoint({ ssr: false });

  const isOrgAdmin = useSelector(getIsOrgAdmin);

  const additionalMenuItems = isOrgAdmin
    ? [
        {
          label: 'Manage workflows',
          url: `${window.location.origin}/ehi/#/workflows`,
        },
      ]
    : [];

  if (isOrgAdmin) {
    additionalMenuItems.push({
      label: 'Checklist templates',
      url: `${window.location.origin}/ehi/#/workflows/inspection_templates`,
    });
  }

  if (isMobile && hideOnMobile) return null;

  return (
    <HoverHeader
      apiServer={appSettings.HYPERION_API_SERVER}
      additionalMenuItems={additionalMenuItems}
      {...props}
    >
      {children}
    </HoverHeader>
  );
};
