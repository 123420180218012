import { Body, Box, Collapse, Heading, Loader, Modal } from '@hover/blueprint';
import { capitalize } from 'lodash';

import {
  estimationConfigLineItemVersions_estimationConfigLineItemVersions_edges as LineItemVersion,
  estimationConfigLineItemVersions_estimationConfigLineItemVersions_edges_node_attributeChanges as LineItemAttribute,
} from 'src/api/types/estimationConfigLineItemVersions';
import {
  estimationConfigTemplateVersions_estimationConfigTemplateVersions_edges as TemplateVersion,
  estimationConfigTemplateVersions_estimationConfigTemplateVersions_edges_node_attributeChanges as TemplateAttribute,
} from 'src/api/types/estimationConfigTemplateVersions';

/*
  Customer friendly attribute names
 */
export const formatAttributeName = (attribute: string) => {
  const ATTRIBUTE_STRINGS: { [key: string]: string } = {
    name: 'name',
    unit_cost: 'unit cost',
    active: 'visibility',
  };

  return ATTRIBUTE_STRINGS[attribute] ?? attribute;
};

type Version = LineItemVersion | TemplateVersion;

type Versions = Version[];

type Attribute = LineItemAttribute | TemplateAttribute;

interface HistoryModalProps {
  name: string | null | undefined;
  versions: Versions;
  isLoadingPages: boolean;
  isOpen: boolean;
  onClose: () => void;
  attributeFormatter?: (attribute: Attribute) => string;
}

/*
  Changed attributes row language
 */
export const getChangedAttributes = (version: Version) => {
  const changes: string[] = [];
  (version?.node?.attributeChanges || []).forEach((attribute) => {
    const formattedAttributeName = formatAttributeName(attribute.attributeName);
    if (formattedAttributeName) {
      changes.push(formattedAttributeName);
    }
  });

  let changed = '';
  if (changes.length) {
    if (changes.length > 1) {
      const last = changes.pop();
      changed = `Changed ${changes.join(', ')} and ${last}`;
    } else {
      changed = `Changed ${changes[0]}`;
    }
  }

  return changed;
};

export const defaultAttributeFormatter = (attribute: Attribute) =>
  `${capitalize(formatAttributeName(attribute.attributeName))} changed from ${
    attribute.oldValue
  } to ${attribute.newValue}`;

export const HistoryModal: React.FC<HistoryModalProps> = ({
  name,
  versions,
  isLoadingPages,
  isOpen,
  onClose,
  attributeFormatter = defaultAttributeFormatter,
}) => {
  const collapsible = (version: Version) => {
    const formattedDate = new Date(version?.node?.createdAt).toLocaleString();
    const changed = getChangedAttributes(version);

    return (
      <Box width="100%" justifyContent="space-between">
        {changed}
        <Box testId="versionHistoryDate" color="neutral600">
          {formattedDate}
        </Box>
      </Box>
    );
  };

  const ModalBody = (
    <Box data-testid="versionHistoryList" display="flex" flexDirection="column">
      <Heading size={500}>{name || ''}</Heading>
      {versions.length > 0 ? (
        versions.map((version) => {
          const attributeChanges = version?.node?.attributeChanges || [];
          return (
            <Box key={version?.node?.id} display="flex" flexDirection="column">
              <Heading data-testid="versionHistoryUserName" size={200}>
                {version?.node?.updatedByUserName || ''}
              </Heading>
              <Box
                data-testid="versionHistory"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Collapse
                  width="100%"
                  maxWidth="100%"
                  title={collapsible(version)}
                  paddingBottom={500}
                  paddingRight={300}
                >
                  <Box flexDirection="column">
                    {attributeChanges.map((attribute) => (
                      <Box key={attribute.attributeName}>
                        {attributeFormatter(attribute)}
                      </Box>
                    ))}
                  </Box>
                </Collapse>
              </Box>
            </Box>
          );
        })
      ) : (
        <Body size={400}>No change activity recorded yet.</Body>
      )}
    </Box>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="large"
      header="Activity History"
      scrollBehavior="inside"
      maxContentHeight="calc(100vh - 240px)"
    >
      {isLoadingPages ? (
        <Loader data-testid="LineItemsSpinner" size="large" />
      ) : (
        ModalBody
      )}
    </Modal>
  );
};
