import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { Box } from '@hover/blueprint';

import { LineItemTypeEnum } from 'src/api/graphql-global-types';
import type { productCatalogConfigOrgDistributors_productCatalogConfigOrgDistributors as Distributor } from 'src/api/types/productCatalogConfigOrgDistributors';
import type { projectManagementProductionList_projectManagementProductionList_estimateGroup as EstimateGroup } from 'src/api/types/projectManagementProductionList';
import { messages } from 'src/constants/messages';
import { ESTIMATION_QUANTITY_UNITS } from 'src/features/project/apis/graphql/queries/queries';
import type {
  ListItemIdsByTypeAndTrade,
  ListItemsByTypeAndTrade,
} from 'src/features/project/types';
import { ToastStatusEnum, useToastEhi } from 'src/hooks';
import { byLineItemTypeComparator } from 'src/utils/comparators';

import { ProjectScopeTypeList } from './ContentTable/ProjectScopeTypeList';

type ProjectScopeContentTableProps = {
  orgId: string;
  jobId: number;
  distributors?: Distributor[];
  listItemsByTypeAndTrade: ListItemsByTypeAndTrade;
  selectedListItemIds: ListItemIdsByTypeAndTrade | null;
  openAddEditMaterialModal: (lineItemType: LineItemTypeEnum) => void;
  openDownloadPdfModal: (lineItemType: LineItemTypeEnum) => void;
  productionListId: number;
  estimateGroup: EstimateGroup;
};

const enum TOAST_IDS {
  GET_QUANTITY_UNITS_TOAST,
}

export const ProjectScopeContentTable = ({
  orgId,
  jobId,
  distributors,
  listItemsByTypeAndTrade,
  selectedListItemIds,
  openAddEditMaterialModal,
  openDownloadPdfModal,
  productionListId,
  estimateGroup,
}: ProjectScopeContentTableProps) => {
  const toast = useToastEhi();
  const lineItemsTypeSorted = useMemo(() => {
    const keys = Object.keys(listItemsByTypeAndTrade) as LineItemTypeEnum[];
    return keys.sort(byLineItemTypeComparator);
  }, [listItemsByTypeAndTrade]);

  const { data: quantityUnitsData } = useQuery(ESTIMATION_QUANTITY_UNITS, {
    onError: () => {
      toast({
        id: TOAST_IDS.GET_QUANTITY_UNITS_TOAST,
        description:
          messages.projectScope.errors.query.productionList
            .estimateQuantityUnits,
        status: ToastStatusEnum.ERROR,
      });
    },
  });

  return (
    <Box
      marginTop={600}
      flexDirection="column"
      data-testid="ProjectScope-ListItemsTable-Container"
    >
      {lineItemsTypeSorted.map((lineItemType) => (
        <ProjectScopeTypeList
          key={lineItemType}
          orgId={orgId}
          jobId={jobId}
          lineItemType={lineItemType}
          selectedListItemIds={
            selectedListItemIds && selectedListItemIds[lineItemType]
          }
          itemsByTrade={listItemsByTypeAndTrade[lineItemType]}
          openDownloadPdfModal={openDownloadPdfModal}
          openAddEditMaterialModal={openAddEditMaterialModal}
          distributors={distributors}
          productionListId={productionListId}
          quantityUnits={quantityUnitsData?.estimationQuantityUnits ?? []}
          estimateGroup={estimateGroup}
        />
      ))}
    </Box>
  );
};
