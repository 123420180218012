import React from 'react';

import { VStack, Heading, Box } from '@hover/blueprint';

import { EstimatorFooter } from 'src/features/projectEstimator/components/EstimatorFooter';
import { ContentSection } from 'src/features/projectEstimator/components/SelectTemplates/structuralComponents';

export const PEWasteFactor: React.FC = () => {
  return (
    <VStack width="100%">
      <Heading>Waste Factor</Heading>
      <ContentSection
        paddingTop={700}
        paddingBottom={700}
        padding={{ base: 300, desktop: 900 }}
        contentBottom={100}
        contentTop={100}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        handleScroll={() => {}}
        left="50%"
        transform="translateX(-50%)" // center content
      >
        <Box
          flexDirection="column"
          width={{ base: 1, tablet: 0.8, desktop: 0.5 }}
          gap={700}
        >
          {/* Here you can add body of waste factor questions */}
        </Box>
      </ContentSection>

      <EstimatorFooter nextEnabled />
    </VStack>
  );
};
