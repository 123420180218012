/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-shadow */
import { findIndex, toNumber, isNil } from 'lodash';

import { tradeTypes_tradeTypes as TradeType } from 'src/api/types/tradeTypes';
import {
  SIDING_ARGUMENT_MAP,
  PARTIAL_ROOF_LINE_SEGMENT_FEATURES_MAP,
} from 'src/features/exteriorEstimator/constants/questionArgumentMappings';
import { QuestionResponses } from 'src/features/exteriorEstimator/types';
import { LineSegmentCalculatorHdf } from 'src/features/exteriorEstimator/utils/LineSegmentCalculatorHdf';
import {
  isPitch,
  getPitchTotal,
  findMeasurement,
  isMeasurementInput,
  isRoofTotal,
} from 'src/features/projectEstimator/hooks/utils/hdfUtils';
import {
  InputWithValue,
  TemplateIdToInputs,
  InputValue,
  TemplatesState,
  TemplateStore,
  ProjectEstimatorFacets,
} from 'src/features/projectEstimator/types';
import {
  isRoofTemplate,
  isSidingTemplate,
} from 'src/features/projectEstimator/utils/tradeTypes';
import { EhiOrgSettings } from 'src/redux/reducers/ehiReducer';
import { EstimationSummarizedJsonMeasurements } from 'src/types/HdfMeasurements';
import { getSidingFacetsArea } from 'src/utils/PartialSidingUtils';

const combinedArgumentToTypeMap = {
  ...PARTIAL_ROOF_LINE_SEGMENT_FEATURES_MAP,
  ...SIDING_ARGUMENT_MAP,
};

export const setTemplateStoreAndMeasurementValuesFromRequiredInputs = (
  templateIdInputs: TemplateIdToInputs,
  hdf: EstimationSummarizedJsonMeasurements,
) => {
  const newTemplates: TemplatesState = {};
  Object.entries(templateIdInputs).forEach(
    ([templateId, templateWithInputs]) => {
      newTemplates[templateId] = {
        ...templateWithInputs,
        inputs: templateWithInputs.inputs.map((input) => {
          const value = findMeasurement(hdf, input);
          return { ...input, value };
        }),
        facets: null,
      };
    },
  );
  return newTemplates;
};

export const updateInputForTemplateStore = ({
  state,
  inputId,
  templateId,
  value,
}: {
  state: TemplateStore;
  inputId: number;
  templateId: number;
  value: InputValue;
}) => {
  const template = state.templates[templateId];
  const { inputs } = template;

  if (!template) {
    throw new Error(`Template with id ${templateId} not found`);
  }
  const foundInput = inputs.find((input) => input.id === toNumber(inputId));
  const foundInputIndex = findIndex(
    inputs,
    (input) => input.id === toNumber(foundInput?.id),
  );
  const updatedInputs = [...inputs];
  if (foundInputIndex !== -1 && foundInput) {
    updatedInputs[foundInputIndex] = {
      ...foundInput,
      value,
    };
  }

  const newState = {
    ...state,
    templates: {
      ...state.templates,
      [templateId]: { ...template, inputs: updatedInputs },
    },
  };

  return newState;
};

export const convertFacetsToQuestionResponses = (
  facets: ProjectEstimatorFacets | null,
) => {
  // turns {'RF-1': {id: 1, displayLabel: "RF-1", area: 100, checked: true}} into {'RF-1': true}
  return facets
    ? Object.entries(facets).reduce<QuestionResponses>(
        (acc, [facetLabel, facet]) => {
          return {
            ...acc,
            [facetLabel]: facet.checked,
          };
        },
        {},
      )
    : {};
};

const getCalculatedEdgeTotals = (
  measurements: EstimationSummarizedJsonMeasurements,
  orgSettings: EhiOrgSettings,
  facets: ProjectEstimatorFacets | null,
) => {
  const { edges, facades, windows_for_facade } = measurements;

  const hdfLineSegmentCalculator = new LineSegmentCalculatorHdf({
    edges,
    facades,
    windowsForFacade: windows_for_facade,
    questionResponses: convertFacetsToQuestionResponses(facets),
    hdfMeasurements: measurements,
    orgSettings,
    isStone: false,
  });

  const { calculatedEdgeTotals } = hdfLineSegmentCalculator;
  return calculatedEdgeTotals;
};

export const hydrateInputsWithPitchMeasurementsAndLineSegments = (
  inputs: InputWithValue[],
  measurements: EstimationSummarizedJsonMeasurements,
  orgSettings: EhiOrgSettings,
  facets: ProjectEstimatorFacets | null,
) => {
  const calculatedEdgeTotals = getCalculatedEdgeTotals(
    measurements,
    orgSettings,
    facets,
  );

  const newInputs = inputs.map((input) => {
    const pitchValue = input.argument ?? '';
    let measurementValue;

    const lineSegmentType =
      combinedArgumentToTypeMap[
        input.argument as keyof typeof combinedArgumentToTypeMap
      ];

    const calculatedTotal = calculatedEdgeTotals[lineSegmentType];

    if (isMeasurementInput(input) && isPitch(pitchValue)) {
      // if the input is a pitch measurement, calculate the total pitch area
      measurementValue = getPitchTotal(pitchValue, measurements.roof);
    } else if (isRoofTotal(input.argument as string)) {
      // if the input's argument is the roof total, get the total from the measurements
      measurementValue = measurements.roof.roof_total ?? 0;
    } else if (!isNil(calculatedTotal)) {
      // if the input's argument is one of the line segment arguments, get the total from the LineSegmentCalculator
      measurementValue = calculatedTotal;
    } else if (!!lineSegmentType) {
      // the input does not have a calculated edge total, but is still a line segment, initialize it to 0
      measurementValue = 0;
    }
    return { ...input, value: measurementValue };
  });
  return newInputs;
};

export const addFacetsToTemplates = (
  hdf: EstimationSummarizedJsonMeasurements,
  templates: TemplatesState,
  tradeTypesSorted: TradeType[],
  orgSettings: EhiOrgSettings,
) => {
  // Roof facets come from hdf.roof.pitch
  // Siding facets come from either hdf.combinedAreaWithTrim or hdf.combinedAreaWithTrimNoOpenings

  const roofFacets = hdf.roof.pitch.map((pitch) => {
    return {
      display_label: pitch.label,
      id: pitch.label,
      area: pitch.area,
    };
  });

  const sidingFacets = getSidingFacetsArea(orgSettings, hdf);

  let alreadyAddedRoof = false;
  let alreadyAddedSiding = false;
  const newTemplates = Object.entries(templates).reduce<TemplatesState>(
    (acc, [templateId, template]) => {
      // assign facets to the templates
      const isRoof = isRoofTemplate(template, tradeTypesSorted);
      const isSiding = isSidingTemplate(template, tradeTypesSorted);
      let facets = {};
      if (isRoof) {
        // only add roof facets to the roof templates
        facets = roofFacets.reduce<ProjectEstimatorFacets>((acc, facade) => {
          acc[facade.display_label] = {
            id: facade.id,
            displayLabel: facade.display_label,
            area: facade.area,
            checked: !alreadyAddedRoof, // they should all be selected on the first roof template by default
          };
          return acc;
        }, {});
        alreadyAddedRoof = true;
      } else if (isSiding) {
        // only add siding facets to the siding templates
        facets = sidingFacets.reduce<ProjectEstimatorFacets>((acc, facade) => {
          acc[facade.display_label] = {
            id: facade.id,
            displayLabel: facade.display_label,
            area: facade.area,
            checked: !alreadyAddedSiding, // they should all be selected on the first roof template by default
          };

          return acc;
        }, {});
        alreadyAddedSiding = true;
      }
      return {
        ...acc,
        [templateId]: { ...template, facets },
      };
    },
    {},
  );
  return newTemplates;
};

export const initializePitchAndLineSegmentInputsOnTemplates = (
  templates: TemplatesState,
  hdf: EstimationSummarizedJsonMeasurements,
  orgSettings: EhiOrgSettings,
) => {
  return Object.entries(templates).reduce<TemplatesState>(
    (acc, [templateId, template]) => {
      const { inputs } = template;
      const newInputs = hydrateInputsWithPitchMeasurementsAndLineSegments(
        inputs,
        hdf,
        orgSettings,
        template.facets,
      );
      const newTemplate = { ...template, inputs: newInputs };
      return {
        ...acc,
        [templateId]: { ...newTemplate },
      };
    },
    {},
  );
};
