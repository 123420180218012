/* eslint-disable no-underscore-dangle */

import { useState, useEffect } from 'react';

import { TextInput } from '@hover/blueprint';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { inputs } from 'src/features/projectManagement/constants';
import * as actions from 'src/features/projectManagement/redux/actions';
import { isEmpty } from 'src/features/projectManagement/utils/InputValidator';
import { RootAction, ChangeEvent } from 'src/types';

import InputWrapper from '../InputWrapper';

export const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      updateOrderDetailsForm: actions.updateOrderDetailsForm,
    },
    dispatch,
  );

type Props = ReturnType<typeof mapDispatchToProps> & AddressInputProps;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Validators = any[];

type InputType =
  | 'number'
  | 'text'
  | 'time'
  | 'search'
  | 'tel'
  | 'url'
  | 'email'
  | 'password'
  | 'date'
  | 'month'
  | 'datetime-local'
  | undefined;

interface AddressInputProps {
  initialValue?: string;
  validators?: Validators;
  dataTestId?: string;
  inputId: string;
  isRequired?: boolean;
  labelText?: string;
  width?: number;
  marginRight?: 0 | 500;
  placeholder?: string;
  inputType?: InputType;
}

export const AddressInput: React.FC<Props> = ({
  initialValue = '',
  validators,
  dataTestId,
  inputId = '',
  updateOrderDetailsForm,
  isRequired,
  labelText,
  width = 200,
  marginRight = 0,
  placeholder = '',
  inputType = 'text',
}) => {
  const [value, setValue] = useState(initialValue);
  const [isPristine, setIsPristine] = useState(true);
  const [isInvalid, setIsInvalid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const checkIfEmpty = (targetValue: string): void => {
    const _isEmpty = isEmpty(targetValue);
    const _errorMessage = _isEmpty ? `${labelText} cannot be empty.` : '';
    if (_isEmpty) {
      setIsInvalid(true);
      setErrorMessage(_errorMessage);
      updateOrderDetailsForm({
        errors: {
          [inputs[inputId].errorField]: _isEmpty,
        },
      });
    }
  };

  // Returns array of validation errors, or null.
  const isValidUserInput = (
    inputValue: string,
  ): Array<{ valid: boolean; error?: string }> | null => {
    if (!validators || !validators.length) return null;
    const validationResults = validators.map((validator) =>
      validator(inputValue),
    );
    const filteredValidationResult = validationResults.filter(
      (validationResult) => {
        return (
          !!validationResult &&
          !validationResult.valid &&
          !!validationResult.error
        );
      },
    );
    return filteredValidationResult;
  };

  useEffect(() => {
    // Update redux with prefilled data on component mount
    updateOrderDetailsForm({
      values: {
        [inputs[inputId].valueField]: value,
      },
    });
    // validate on page load.
    const errors = isValidUserInput(value);
    if (!!errors && errors.length > 0) {
      setIsPristine(false);
      setIsInvalid(true);
      setErrorMessage(`${labelText} ${errors[0].error}`);
    } else {
      if (!isRequired) return;
      // check required field on page load.
      checkIfEmpty(value);
      if (isEmpty(value)) setIsPristine(false);
      updateOrderDetailsForm({
        errors: {
          [inputs[inputId].errorField]: isEmpty(value),
        },
      });
    }
    // Runs only on first render/load.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateOrderDetailsForm({
      values: { [inputs[inputId].valueField]: value },
    });
  }, [value, updateOrderDetailsForm, inputId]);

  const onChange = ({ target: { value: _value } }: ChangeEvent) => {
    setIsPristine(false);
    const errors = isValidUserInput(_value);
    if (!!errors && errors.length > 0) {
      setIsInvalid(true);
      setErrorMessage(`${labelText} ${errors[0].error}`);
      updateOrderDetailsForm({
        errors: {
          [inputs[inputId].errorField]: true,
        },
      });
    } else {
      setIsInvalid(false);
      setErrorMessage('');
      updateOrderDetailsForm({
        errors: {
          [inputs[inputId].errorField]: false,
        },
      });
    }
    setValue(_value);
    // eslint-disable-next-line no-unused-expressions
    isRequired && checkIfEmpty(_value);
    return null;
  };

  return (
    <InputWrapper
      isRequired={isRequired}
      isPristine={isPristine}
      labelText={labelText}
      isInvalid={isInvalid}
      errorMessage={errorMessage}
      inputId={inputId}
      width={width}
      marginRight={marginRight}
    >
      <TextInput
        name={inputId}
        id={inputId}
        display="block"
        onChange={onChange}
        value={value}
        data-testid={dataTestId}
        isInvalid={isInvalid}
        placeholder={placeholder}
        type={inputType}
      />
    </InputWrapper>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default connect(undefined, mapDispatchToProps)(AddressInput);
