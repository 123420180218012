import { PureComponent } from 'react';

import { Button, Icon } from '@hover/blueprint';
import { iLock } from '@hover/icons';
import autobind from 'autobind-decorator';

import { withTypewriter } from 'src/components/WithTypewriter';
import { ButtonStatus } from 'src/features/exteriorEstimator/constants/tradeTypes';
import { EventNames } from 'src/types/actionTypes';

import { SalesOpModal } from '../SalesOpModal/SalesOpModal';

interface ComponentProps {
  status: ButtonStatus;
  jobId: number | string;
  typewriter?: any;
}

type Props = ComponentProps;
type State = {
  isModalOpen: boolean;
};

export class SoldStatusButtonComponent extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  @autobind
  setShowModal(show: boolean) {
    this.setState({
      isModalOpen: show,
    });
  }

  @autobind
  handleMarkAsSoldClick() {
    const { jobId, typewriter } = this.props;
    this.setShowModal(true);

    typewriter.buttonPressed({
      button_text: 'Mark as Sold',
      primary_cta: false,
      page_or_screen_name: EventNames.estimator.estimateDetailsScreen.page,
      job_id: Number(jobId),
    });
  }

  @autobind
  handleSoldClick() {
    const { jobId, typewriter } = this.props;
    this.setShowModal(true);

    typewriter.buttonPressed({
      button_text: 'Sold',
      primary_cta: false,
      page_or_screen_name: EventNames.estimator.estimateDetailsScreen.page,
      job_id: Number(jobId),
    });
  }

  public renderButton() {
    const { status } = this.props;
    switch (status) {
      case ButtonStatus.Sold:
        return (
          <Button
            size={{ base: 'default', tablet: 'large', desktop: 'large' }}
            data-testid="marked-as-sold-button"
            onClick={this.handleSoldClick}
            flex={1}
            shape="circle"
            label="lock"
            color="success"
          >
            <Icon icon={iLock} />
          </Button>
        );
      case ButtonStatus.Unsold:
        return (
          <Button
            size={{ base: 'default', tablet: 'large', desktop: 'large' }}
            data-testid="mark-as-sold-button"
            onClick={this.handleMarkAsSoldClick}
          >
            Mark As Sold
          </Button>
        );
      default:
        return (
          <Button
            size={{ base: 'default', tablet: 'large', desktop: 'large' }}
            data-testid="mark-as-sold-loading-button"
            isDisabled
            isLoading
          />
        );
    }
  }

  render() {
    const { isModalOpen } = this.state;
    return (
      <>
        <SalesOpModal setShowModal={this.setShowModal} isOpen={isModalOpen} />
        {this.renderButton()}
      </>
    );
  }
}

export const SoldStatusButton = withTypewriter(SoldStatusButtonComponent);
