import * as React from 'react';

import {
  Box,
  Body,
  BoxProps,
  RadioGroup as ChakraRadioGroup,
} from '@hover/blueprint';
import type { RadioGroupProps } from '@hover/blueprint';
import { isNil, omit } from 'lodash';
import styled, { CSSObject } from 'styled-components';

const HeadingBody = styled(Body)<{ headingStyles: CSSObject }>`
  ${({ headingStyles }) => headingStyles}
`;

type Props = {
  heading?: string | React.ReactNode;
  onChange?: RadioGroupProps['onChange'];
  selectedValue?: RadioGroupProps['value'];
  headingStyles?: CSSObject;
  'data-testid'?: string;
} & Omit<BoxProps, 'onChange'>;

/* TODO: This entire component should not be necessary, and RadioGroup from Chakra/Blueprint should just be used directly; that will refactoring the consumer (Variations) of this. */
export const RadioGroup: React.FC<Props> = ({
  children,
  heading,
  onChange,
  headingStyles = {},
  selectedValue,
  flexDirection = 'row',
  ...props
}) => {
  const onRadioChange = (value: string) => {
    if (!isNil(onChange)) onChange(value);
  };

  return (
    <Box
      flexDirection="column"
      data-testid={props['data-testid'] || 'radio-group-container'}
      as="form"
      {...omit(props, 'data-testid')}
    >
      {heading && (
        <HeadingBody
          color="neutral600"
          size={500}
          marginY={200}
          headingStyles={headingStyles}
          data-testid="radio-group-heading"
        >
          {heading}
        </HeadingBody>
      )}

      <Box testId="radio-buttons-wrapper" flexDirection={flexDirection}>
        <ChakraRadioGroup
          defaultValue={selectedValue}
          sx={{ display: 'flex' }}
          onChange={onRadioChange}
        >
          {children}
        </ChakraRadioGroup>
      </Box>
    </Box>
  );
};
