import { useState } from 'react';

import { Box, Heading, Image, Icon } from '@hover/blueprint';
import { iMapPin } from '@hover/icons';

import appSettings from 'src/appSettings';
import { EstimatorResponsiveWrapper } from 'src/features/exteriorEstimator/components/common/EstimatorResponsiveWrapper';
import { HeroActionsBar } from 'src/features/exteriorEstimator/components/EstimationTool/HeroActionsBar/HeroActionsBar';

interface Props {
  jobDetailSummary: {
    id?: number;
    name?: string | null;
    locationLine1?: string | null;
    locationCity?: string | null;
  };
  hideEdit: boolean;
}

export const PropertyHeroImage: React.FC<Props> = ({
  jobDetailSummary: { id, name, locationLine1, locationCity },
  hideEdit,
}) => {
  const [isImageLoading, setIsImageLoading] = useState<boolean>(false);

  const handleImageLoaded = () => {
    setIsImageLoading(false);
  };

  const renderHeroImageTextResponsive = () => {
    return (
      <Box
        position="absolute"
        top="0"
        width="100%"
        height="224px"
        justifyContent="center"
        py={100}
      >
        <EstimatorResponsiveWrapper>
          <HeroActionsBar show={!hideEdit} />
          <Box
            min-height="32px"
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            data-testid="property-name"
          >
            <Heading marginBottom={0} color="neutral.0" size={500}>
              Estimate for
            </Heading>
            <Heading marginBottom={0} color="neutral.0" size={500}>
              {name}
            </Heading>
          </Box>
          <Box
            color="neutral.0"
            height="35px"
            left="32px"
            marginTop="1rem"
            alignItems="center"
          >
            <Icon icon={iMapPin} size="medium" />
            <Heading
              marginBottom={0}
              color="neutral.0"
              size={200}
              fontWeight={100}
            >
              {locationLine1}, {locationCity}
            </Heading>
          </Box>
        </EstimatorResponsiveWrapper>
      </Box>
    );
  };

  return (
    <Box
      display="inline"
      height="100%"
      position="relative"
      maxHeight="224px"
      width="100%"
      data-testid="property-hero-image"
    >
      <Box
        display="inline"
        opacity={isImageLoading ? '0' : '1'}
        background="linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7))"
        transition="300ms"
        height="60px"
        position="absolute"
        top="0"
        right="0"
        width="100%"
      />
      <Box height="224px">
        {id ? (
          <Image
            alt="job"
            src={`${appSettings.HYPERION_API_SERVER}/api/v2/jobs/${id}.jpg`}
            onLoad={handleImageLoaded}
            width="100%"
            maxHeight="224px"
            objectFit="cover"
          />
        ) : (
          <Box
            display="inline"
            background="neutral.200"
            height="224px"
            maxHeight="350px"
            position="absolute"
            pointerEvents="none"
            top="0"
            width="100%"
          />
        )}
      </Box>
      <Box
        display="inline"
        opacity={isImageLoading ? '0' : '1'}
        background="linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6))"
        width="100%"
        height="180px"
        position="absolute"
        bottom="0"
        right="0"
        transition="300ms"
      />
      {id && renderHeroImageTextResponsive()}
    </Box>
  );
};
