import { Box, Heading, Icon, Panel, Table, Tbody } from '@hover/blueprint';
import { iLock } from '@hover/icons';

import { estimationConfigTemplate_estimationConfigTemplate_templateGroups_lineItems as LineItem } from 'src/api/types/estimationConfigTemplate';
import { useTemplateTristateCheckbox } from 'src/features/settings/components/Templates/TemplateEdit/hooks';

import { TemplateGroupThead, LineItemTr } from './TemplateGroupTable';
import type { DisplayedTemplateGroup } from './types';

type UnassignedLineItemsProps = {
  lineItems: LineItem[] | null;
  group: DisplayedTemplateGroup;
};

export const UnassignedLineItems: React.FC<UnassignedLineItemsProps> = ({
  lineItems,
  group,
}) => {
  const { allChecked, isIndeterminate, handleSelectAllNone } =
    useTemplateTristateCheckbox(lineItems);

  return (
    <Panel backgroundColor="neutral.0" padding={500} boxShadow="distance500">
      <Box flexDirection="column" testId="unassignedLineItems">
        <Box borderBottom="1px solid" borderColor="neutral.300">
          <Heading size={400} data-testid="UnassignedGroupName">
            <Box alignItems="baseline">
              <Icon icon={iLock} marginX={300} color="neutral.600" />
              <Box>{`Unassigned line items (${
                lineItems ? lineItems.length : 0
              })`}</Box>
            </Box>
          </Heading>
        </Box>
        {!!lineItems?.length && (
          <Table size="small" borderBottom="0px">
            <TemplateGroupThead
              allChecked={allChecked}
              isIndeterminate={isIndeterminate}
              onChange={handleSelectAllNone}
              group={group}
            />
            <Tbody data-testid="UnassignedLineItemsTable" borderBottom="0px">
              {lineItems?.map((lineItem: LineItem) => (
                <LineItemTr key={lineItem.id} lineItem={lineItem} />
              ))}
            </Tbody>
          </Table>
        )}
      </Box>
    </Panel>
  );
};
