import {
  ActionButtonTexts,
  OrderModalStates,
} from 'src/features/projectManagement/constants';

import { Button } from './styled';

interface Props {
  isDisabled?: boolean;
  orderModalState: string;
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const ActionButton: React.FC<Props> = ({
  isDisabled,
  orderModalState,
  handleClick,
}) => {
  return (
    <Button
      onClick={handleClick}
      data-testid="orderCheckAction"
      disabled={
        (orderModalState === OrderModalStates.OrderDetailsForm && isDisabled) ||
        orderModalState === OrderModalStates.Loading
      }
    >
      {ActionButtonTexts[orderModalState]}
    </Button>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default ActionButton;
