import {
  Link,
  IconButton,
  Box,
  SimpleGrid,
  Image,
  Body,
  Button,
  Tooltip,
} from '@hover/blueprint';
import { iPlus } from '@hover/icons';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import type { jobsWithSalesOpportunity_jobs_results as JobsWithSalesOpportunityResultsType } from 'src/api/types/jobsWithSalesOpportunity';
import appSettings from 'src/appSettings';
import { ESTIMATOR } from 'src/constants/urls';
import { useTracking } from 'src/hooks';
import {
  getMaterialListFeature,
  getOrgSettings,
  getOrderingOnlyFeatureAccess,
} from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

import EstimateGroup from './EstimateGroup';
import { EstimateGroupList } from './EstimateGroupList';

type Props = {
  selectedOrgId: number;
  job: JobsWithSalesOpportunityResultsType;
};

export const Job: React.FC<Props> = ({ job, selectedOrgId }) => {
  const { useTypewriter } = useTracking();
  const typewriter = useTypewriter();
  const showOrderingFlow = useSelector(getOrgSettings)?.showOrderingFlow;
  const showOrderingVersion =
    useSelector(getOrderingOnlyFeatureAccess) && showOrderingFlow;
  const materialListFeatureEnabled = useSelector(getMaterialListFeature);
  const showOrderingOrMaterialListVersion =
    showOrderingVersion || materialListFeatureEnabled;

  let soldEstimateGroup;
  let user;
  if ('salesOpportunity' in job) {
    soldEstimateGroup = job.salesOpportunity?.soldEstimateGroup;
    user = soldEstimateGroup?.user;
  }

  let imageUrl;
  if ('bestFrontImage' in job) {
    imageUrl = job?.bestFrontImage?.imageSmallUrl || '';
  }

  return (
    <Box flexDirection="column" paddingY={300}>
      <SimpleGrid
        maxWidth="1280px"
        paddingY={300}
        alignItems="center"
        gridColumnGap="300"
        gridRowGap="300"
        gridTemplateColumns="[col1] 8% [col2] 15% [col3] 15% [col4] 10% [col5] 7% [col6] 3% [col7] 1fr [col8] 3%"
        data-testid={`job-row-${job.id}`}
      >
        <Box gridColumn="1" paddingLeft={200}>
          <Image
            height="400"
            maxWidth="500"
            borderRadius="3px"
            src={imageUrl}
            alt={job.name || 'job'}
          />
        </Box>
        <Body
          gridColumn="2"
          size={400}
          color="neutral700"
          fontWeight={700}
          data-testid="job-name"
        >
          {job.name}
        </Body>
        <Body gridColumn="3 / span 3" size={400} color="neutral.500">
          {`${job.locationLine1}, ${job.locationCity}, ${job.locationRegion}`}
        </Body>
        <Box gridColumn="end">
          {showOrderingOrMaterialListVersion ? (
            <Tooltip
              label={
                isEmpty(soldEstimateGroup) ? (
                  'Create a new material calculation for job'
                ) : (
                  <span>
                    Property can have one calculation. <br />
                    You can edit the existing calculation.
                  </span>
                )
              }
              placement="top"
            >
              <Box // this is necessary so the tooltip shows on a disabled button
              >
                <Button
                  fill="minimal"
                  iconBefore={iPlus}
                  data-testid="CalculateMaterialsButton"
                  color={isEmpty(soldEstimateGroup) ? 'primary' : 'neutral'}
                  disabled={!isEmpty(soldEstimateGroup)}
                  onClick={() => {
                    typewriter.buttonPressed({
                      button_text: 'Calculate Materials',
                      page_or_screen_name: EventNames.projectList.page,
                      primary_cta: false,
                    });
                    window.open(
                      `/ehi/#${ESTIMATOR}?jobId=${job.id}`,
                      window.Cypress ? '_self' : '_blank',
                    );
                  }}
                >
                  Calculate Materials
                </Button>
              </Box>
            </Tooltip>
          ) : (
            <Link
              data-testid="jobEstimatorLink"
              as={RouterLink}
              to={`${ESTIMATOR}?jobId=${job.id}`}
              target="_blank"
              marginRight="400"
            >
              <IconButton
                data-testid="plusIcon"
                label="create-estimate"
                icon={iPlus}
                fill="minimal"
                color="neutral"
              />
            </Link>
          )}
        </Box>
      </SimpleGrid>
      {!!soldEstimateGroup?.id && (
        <EstimateGroup
          key={soldEstimateGroup.id}
          selectedOrgId={selectedOrgId}
          isSold
          {...soldEstimateGroup}
          user={user as any}
        />
      )}
      <EstimateGroupList
        jobId={job.id}
        selectedOrgId={selectedOrgId}
        excludeSoldEstimateId={soldEstimateGroup?.id}
        user={user}
      />
    </Box>
  );
};
