export const ROOF_FACET_SELECTION = 'ROOF_FACET_SELECTION';
export const ROOF_FACET_SELECTION_3D = 'ROOF_FACET_SELECTION_3D';
export const SIDING_FACET_SELECTION_3D = 'SIDING_FACET_SELECTION_3D';
export const STONE_FACET_SELECTION_3D = 'STONE_FACET_SELECTION_3D';
export const ROOF_LINE_SEGMENT_ADJUSTMENT = 'ROOF_LINE_SEGMENT_ADJUSTMENT';
export const SIDING_LINE_SEGMENT_ADJUSTMENT = 'SIDING_LINE_SEGMENT_ADJUSTMENT';
export const STONE_LINE_SEGMENT_ADJUSTMENT = 'STONE_LINE_SEGMENT_ADJUSTMENT';
export const CUSTOM_LINE_ITEMS = 'CUSTOM_LINE_ITEMS';
export const OTHER = 'OTHER';
export const SELECT_TEMPLATES = 'SELECT_TEMPLATES';
export const PARTIAL_SIDING_SELECTION = 'PARTIAL_SIDING_SELECTION';
export const ADJUST_SIDING_MEASUREMENT = 'ADJUST_SIDING_MEASUREMENT';
export const PARTIAL_STONE_SELECTION = 'PARTIAL_STONE_SELECTION';
export const MEASUREMENT = 'MEASUREMENT'; // standardized category name
export const WASTE_FACTOR = 'WASTE_FACTOR'; // standarized category name

// we will need to update this const to use inputCategory.id instead of name
export const MEASUREMENT_NAME = 'measurement';
export const WASTE_FACTOR_NAME = 'waste_factor';
