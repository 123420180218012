import React, { useEffect } from 'react';

import {
  Body,
  Heading,
  Button,
  Popover,
  useDisclosure,
} from '@hover/blueprint';
import { iEdit } from '@hover/icons';
import { useSelector } from 'react-redux';

import { useLocalStorage, useTracking } from 'src/hooks';
import { useIsMobileBreakpoint } from 'src/hooks/useIsMobileBreakpoint';
import { isEnabled, COMMERCE_PROJECT_ESTIMATOR } from 'src/lib/FeatureFlag';
import { getMaterialListFeature } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

import {
  REACT_NATIVE_EVENTS,
  triggerReactNativeEvent,
} from '../../../util/nativeMobileEvents';

type Props = {
  jobId: number;
};

type FTUXProps = {
  jobId: number;
  isMobile: boolean;
  localShowFTUXPopover: boolean;
  setLocalShowFTUXPopover: (show: boolean) => void;
};

type EditScopePopoverProps = {
  jobId: number;
  isMobile: boolean;
};

type RecalculateButtonProps = {
  ref?: any;
  jobId: number;
  isMobile: boolean;
  typewriter: any;
  commonTrackingProps: any;
  setLocalShowFTUXPopover: (show: boolean) => void;
};

const navigateToEstimator = (
  isMaterialListEnabled: boolean,
  isProjectEstimatorEnabled: boolean,
  jobId: number,
) => {
  if (isMaterialListEnabled && isProjectEstimatorEnabled) {
    window.location.href = `/ehi/#/project_estimator/select_templates?jobId=${jobId}&returnTo=${window.location.href}`;
  } else {
    window.location.href = `/ehi/#/estimator/questions/select_templates?jobId=${jobId}`;
  }
};

const RecalculateButton: React.FC<RecalculateButtonProps> = ({
  ref,
  jobId,
  isMobile,
  typewriter,
  commonTrackingProps,
  setLocalShowFTUXPopover,
}) => {
  const isProjectEstimatorEnabled = isEnabled(COMMERCE_PROJECT_ESTIMATOR);
  const isMaterialListEnabled = useSelector(getMaterialListFeature);

  const recalculateButtonText = `Edit ${!isMobile ? 'Scope' : ''}`;

  return (
    <Button
      ref={ref}
      fill="outline"
      data-testid="customizeMaterialList"
      iconBefore={isMobile ? undefined : iEdit}
      marginLeft={isMobile ? '0px' : '24px'}
      height={{ base: '42px', tablet: 'auto' }}
      onClick={() => {
        setLocalShowFTUXPopover(false);
        typewriter.buttonPressed({
          page_or_screen_name:
            EventNames.project.scope.customizeMaterialListPressed,
          button_text: 'Recalculate Material List',
          primary_cta: false,
          feature: 'material-list',
          ...commonTrackingProps,
        });

        triggerReactNativeEvent(REACT_NATIVE_EVENTS.START_RECALCULATION_EVENT);

        navigateToEstimator(
          isProjectEstimatorEnabled,
          isMaterialListEnabled,
          jobId,
        );
      }}
    >
      {recalculateButtonText}
    </Button>
  );
};

const FirstTimeUserExperienecePopover: React.FC<FTUXProps> = ({
  jobId,
  isMobile,
  localShowFTUXPopover,
  setLocalShowFTUXPopover,
}) => {
  const { useTypewriter, useCommonTrackingProps } = useTracking();

  const {
    isOpen: isRecalcPopoverOpen,
    onOpen: onRecalcPopoverOpen,
    onClose: onRecalcPopoverClose,
  } = useDisclosure();

  useEffect(() => {
    // when mounting, if on desktop, show the popover if it's enabled
    if (localShowFTUXPopover && !isMobile) onRecalcPopoverOpen();
  }, []);

  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  return (
    <Popover
      isOpen={isRecalcPopoverOpen}
      placement="left"
      colorScheme="dark"
      inPortal
      borderRadius="10px"
      w={750}
      pt={400}
      pb={500}
      px={600}
      anchor={
        <div>
          <RecalculateButton
            jobId={jobId}
            isMobile={isMobile}
            typewriter={typewriter}
            commonTrackingProps={commonTrackingProps}
            setLocalShowFTUXPopover={setLocalShowFTUXPopover}
          />
        </div>
      }
    >
      <Heading
        margin={0}
        fontSize={600}
        color="neutral.0"
        testId="edit-scope-popover-heading"
      >
        Edit Scope
      </Heading>
      <Body margin={0} color="neutral.300">
        Edit project scope by modifying templates, partial or complete scope,
        waste, or install preferences.
      </Body>
      <Button
        marginTop={200}
        color="light"
        onClick={() => {
          setLocalShowFTUXPopover(false);
          typewriter.buttonPressed({
            page_or_screen_name:
              EventNames.project.scope.customizeMaterialListPressed,
            button_text: 'Got it',
            primary_cta: true,
            feature: 'material-list',
            ...commonTrackingProps,
          });
          onRecalcPopoverClose();
        }}
      >
        Got it
      </Button>
    </Popover>
  );
};

const EditScopePopover: React.FC<EditScopePopoverProps> = ({
  jobId,
  isMobile,
}) => {
  const { useTypewriter, useCommonTrackingProps } = useTracking();

  const isProjectEstimatorEnabled = isEnabled(COMMERCE_PROJECT_ESTIMATOR);
  const isMaterialListEnabled = useSelector(getMaterialListFeature);
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const recalculateButtonText = `Edit ${!isMobile ? 'Scope' : ''}`;

  return (
    <Popover
      isHover
      placement="left"
      colorScheme="dark"
      inPortal
      borderRadius="10px"
      w={750}
      pt={400}
      pb={500}
      px={600}
      onOpen={() => {
        typewriter.moduleViewed({
          page_or_screen_name: 'Edit Scope Popover',
          job_id: jobId,
          ...commonTrackingProps,
        });
      }}
      trigger={
        <Button
          fill="outline"
          data-testid="customizeMaterialList"
          iconBefore={isMobile ? undefined : iEdit}
          marginLeft={isMobile ? '0px' : '24px'}
          height={{ base: '42px', tablet: 'auto' }}
          onClick={() => {
            typewriter.buttonPressed({
              page_or_screen_name:
                EventNames.project.scope.customizeMaterialListPressed,
              button_text: 'Edit Scope',
              primary_cta: false,
              feature: 'material-list',
              ...commonTrackingProps,
            });

            triggerReactNativeEvent(
              REACT_NATIVE_EVENTS.START_RECALCULATION_EVENT,
            );

            navigateToEstimator(
              isProjectEstimatorEnabled,
              isMaterialListEnabled,
              jobId,
            );
          }}
        >
          {recalculateButtonText}
        </Button>
      }
    >
      <Heading margin={0} fontSize={600} color="neutral.0">
        Edit Scope
      </Heading>
      <Body margin={0} color="neutral.300">
        Edit project scope by modifying templates, partial or complete scope,
        waste, or install preferences.
      </Body>
    </Popover>
  );
};

export const RecalculateMaterialListButton = ({ jobId }: Props) => {
  const isMobile = useIsMobileBreakpoint({ ssr: false });

  /* Recalculate Popover */
  const [localShowFTUXPopover, setLocalShowFTUXPopover] =
    useLocalStorage<boolean>('showRecalculatePopover', true);

  return (
    <>
      {localShowFTUXPopover ? (
        <FirstTimeUserExperienecePopover
          jobId={jobId}
          isMobile={isMobile}
          localShowFTUXPopover={localShowFTUXPopover}
          setLocalShowFTUXPopover={setLocalShowFTUXPopover}
        />
      ) : (
        <EditScopePopover jobId={jobId} isMobile={isMobile} />
      )}
    </>
  );
};
