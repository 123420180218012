import { useState } from 'react';

import { useQuery } from '@apollo/client';
import { Box, LoadingOverlay } from '@hover/blueprint';
import { useSelector } from 'react-redux';

import type { projectManagementConfigOrgProposalSettingSelfService } from 'src/api/types/projectManagementConfigOrgProposalSettingSelfService';
import { GET_PROPOSAL_SETTING } from 'src/features/settings/api/queries/proposals';
import { ProposalHeader } from 'src/features/settings/components/Proposal/ProposalHeader';
import { ProposalSections } from 'src/features/settings/components/Proposal/ProposalSections';
import { ProposalSettings } from 'src/features/settings/components/Proposal/ProposalSettings';
import { getUserOrgId } from 'src/redux/selectors';

export type ViewMode = 'EDIT' | 'SHOW';

export const Proposal: React.FC = () => {
  const [viewMode, setViewMode] = useState<ViewMode>('SHOW');
  const orgId = useSelector(getUserOrgId);

  const {
    data: { projectManagementConfigOrgProposalSetting } = {},
    loading: loadingProposalSetting,
  } = useQuery<projectManagementConfigOrgProposalSettingSelfService>(
    GET_PROPOSAL_SETTING,
    {
      variables: { orgId },
      fetchPolicy: 'cache-first', // explicitly setting to override the cypress override of 'no-cache'
    },
  );

  return loadingProposalSetting ? (
    <LoadingOverlay
      isLoading={loadingProposalSetting}
      data-testid="ProposalSpinner"
    />
  ) : (
    <Box testId="proposalContainer" width={1} flexDirection="column">
      <ProposalHeader viewMode={viewMode} />
      <ProposalSettings
        viewMode={viewMode}
        projectManagementConfigOrgProposalSetting={
          projectManagementConfigOrgProposalSetting
        }
      />
      <ProposalSections viewMode={viewMode} setViewMode={setViewMode} />
    </Box>
  );
};
