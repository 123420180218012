import { Box, Select, Td } from '@hover/blueprint';
import { get, isNil } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';

import { LineItemTypeEnum } from 'src/api/graphql-global-types';
import { projectManagementProductionList_projectManagementProductionList_listItems as ListItem } from 'src/api/types/projectManagementProductionList';

import { useProjectScopeTracker } from '../../hooks/useProjectScopeTracker';

type Props = {
  listItem: ListItem;
  isDisabled: boolean;
  unitsMap: Map<string, string>;
  quantityUnit: string;
  jobId: number;
  onUpdate: (inputLabel: string) => void;
};

export const ListItemUnitOfMeasureColumn = ({
  listItem,
  isDisabled,
  unitsMap,
  quantityUnit,
  jobId,
  onUpdate,
}: Props) => {
  const { trackInlineEditingInputPressed } = useProjectScopeTracker({ jobId });

  const units = Array.from(unitsMap, ([id, name]) => ({
    id,
    name: name.toUpperCase(),
  }));

  const {
    control,
    formState: { errors: formErrors },
  } = useFormContext();

  return (
    <Td
      paddingY={{ base: 100, tablet: 500 }}
      paddingLeft={0}
      verticalAlign="top"
      paddingRight={{ base: 0, tablet: 200 }}
      flexBasis="50%"
    >
      <>
        <Box>
          <Controller
            control={control}
            name="quantityUnits"
            render={({ field: { onChange, value: unitsIdValue } }) => (
              <Select
                size="small"
                borderRadius="0px 6px 6px 0px"
                borderColor="neutral.500"
                minWidth="56px"
                fontSize="inherit"
                defaultValue={quantityUnit}
                isDisabled={isDisabled}
                isInvalid={!!get(formErrors, 'quantityUnits')}
                data-testid="AddMaterial-uom"
                value={unitsIdValue as string}
                onChange={(e) => {
                  onChange(e);
                  trackInlineEditingInputPressed('Quantity');
                  onUpdate('Quantity Unit');
                }}
              >
                {isNil(listItem.quantityUnits) ||
                listItem.quantityUnits.length === 0 ? (
                  <option key={null} value="">
                    Select units
                  </option>
                ) : null}
                {units.map((unit) => {
                  return (
                    <option key={unit.id} value={unit.id}>
                      {unit.name}
                    </option>
                  );
                })}
              </Select>
            )}
            rules={
              listItem.type === LineItemTypeEnum.MATERIAL
                ? {
                    required: 'Units are required',
                  }
                : {}
            }
          />
        </Box>
      </>
    </Td>
  );
};
