export const PROJECT_ESTIMATOR = 'project_estimator';

export const SELECT_TEMPLATES_URL = '/project_estimator/select_templates';
export const SELECT_ROOF_FACETS_URL =
  '/project_estimator/questions/select_roof_facets';
export const SELECT_SIDING_FACETS_URL =
  '/project_estimator/questions/select_siding_facets';
export const WASTE_FACTOR_URL = '/project_estimator/questions/waste_factor';
export const GENERIC_PAGE_URL = '/project_estimator/questions/category/';

export const ROOF_FACET_SELECTION_SCREEN = 'ROOF_FACET_SELECTION_SCREEN';
export const SIDING_FACET_SELECTION_SCREEN = 'SIDING_FACET_SELECTION_SCREEN';
export const MEASUREMENT_SCREEN = 'MEASUREMENT';
