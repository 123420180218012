import { ChangeEvent, useCallback } from 'react';

import { Field, Select as BlueprintSelect } from '@hover/blueprint';
import type { SelectProps as BlueprintSelectProps } from '@hover/blueprint';
import { Controller, useFormContext } from 'react-hook-form';
import type { RegisterOptions } from 'react-hook-form';

// This type generic is intended to provide a string value for the
// valid display value and data value property names for the options in the Select.
export type SelectProps<
  TDisplayProperty extends string,
  TValueProperty extends string,
> = BlueprintSelectProps & {
  label?: string;
  error?: boolean;
  valueProperty: TValueProperty;
  displayProperty: TDisplayProperty;
  placeholder?: string;
  options: ReadonlyArray<
    {
      [T in TDisplayProperty]: string;
    } & {
      [T in TValueProperty]: string;
    }
  >;
  rules?: RegisterOptions;
};

// temporary until Blueprint provides
export const Select = <
  TDisplayProperty extends string,
  TValueProperty extends string,
>({
  options = [],
  value,
  defaultValue,
  valueProperty,
  displayProperty,
  label = '',
  name = '',
  placeholder = ' ',
  onChange,
  isRequired,
  isDisabled,
  rules,
  ...props
}: SelectProps<TDisplayProperty, TValueProperty>) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>): void => {
      // Update the form state value. (Unusual typing is due to RHF string literal types.)
      setValue(`${name}` as const, event.currentTarget.value, {
        shouldValidate: true,
      });
      // Call consumer's onChange if it exists.
      if (!!onChange) {
        onChange(event);
      }
    },
    [name, onChange, setValue],
  );

  return (
    <Field
      name={name}
      flex={1}
      marginRight={500}
      label={label}
      error={errors[name]?.message ?? undefined}
      isDisabled={isDisabled}
    >
      <Controller
        control={control}
        name={`${name}` as const} //  (Unusual typing is due to RHF string literal types.)
        defaultValue={defaultValue || value}
        rules={{
          ...(isRequired ? { required: `${label} is required` } : {}),
          ...rules,
        }}
        render={({ field: { ref } }) => (
          <BlueprintSelect
            id={name}
            value={value}
            defaultValue={defaultValue}
            onChange={handleChange}
            ref={ref}
            {...props}
          >
            <option
              key={name}
              value=""
              hidden
              data-testid="select-option-placeholder"
            >
              {placeholder}
            </option>
            {options.map((option) => (
              <option
                data-testid="select-option"
                key={`${name}-${option[valueProperty]}`}
                value={option[valueProperty]}
              >
                {option[displayProperty]}
              </option>
            ))}
          </BlueprintSelect>
        )}
      />
    </Field>
  );
};
