import {
  Label as _Label,
  Body,
  Box,
  useTheme,
  Radio,
  RadioProps,
} from '@hover/blueprint';
import styled, { css } from 'styled-components';

import { getIsChecked } from '../utils/radioUtils';

const Label = styled(_Label)<{ checked?: boolean; showBorder?: boolean }>`
  align-items: center;
  transition-duration: 150;
  transition-timing-function: ease-in;
  transition-property: background-color, border;
  border: 1px solid ${({ theme }) => theme.colors.neutral000};
  border-radius: 6px;
  padding: 6px;

  @media (hover: hover) and (pointer: fine) {
    :hover {
      background-color: ${({ theme }) => theme.colors.primary100};
      cursor: pointer;
    }
  }

  ${({ checked, showBorder }) =>
    checked &&
    showBorder &&
    css`
      background-color: ${({ theme }) => theme.colors.primary100};
      border: 1px solid ${({ theme }) => theme.colors.primary600};
      box-shadow: ${({ theme }) => theme.shadows.glowPrimary};
    `}
`;

type Props = RadioProps & {
  rightContent?: React.ReactNode;
  label: string;
  width?: number | string;
  selectedValue?: RadioProps['value'];
  'data-testid'?: string;
  showBorder?: boolean;
};

export const RadioButtonCard: React.FC<Props> = ({
  rightContent,
  label,
  selectedValue,
  showBorder = true,
  ...props
}) => {
  const isChecked = getIsChecked({
    selectedValue,
    value: props.value,
    checked: props.isChecked,
  });

  return (
    <Label
      box
      checked={isChecked}
      showBorder={showBorder}
      data-testid={props['data-testid'] || 'radio-button-card'}
      theme={useTheme()}
    >
      <Radio
        display="block"
        {...props}
        value={props.value}
        onChange={props.onChange}
        margin="0px 8px 0px 0px"
      />
      <Box flex={1} justifyContent="space-between" alignItems="center">
        <Body size={300} margin={0} color="primary900">
          <strong>{label}</strong>
        </Body>
        {rightContent}
      </Box>
    </Label>
  );
};
