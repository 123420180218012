import { useState } from 'react';

import { Form, TextInput } from '@hover/blueprint';
import { iSearch } from '@hover/icons';

import { useTracking } from 'src/hooks';
import { EventNames } from 'src/types';

export const SearchInput: React.FC<{
  setSearchTerm: (searchTerm: string) => void;
}> = ({ setSearchTerm }) => {
  const { useCommonTrackingProps, useTypewriter } = useTracking();
  const typewriter = useTypewriter();
  const commonTrackingProps = useCommonTrackingProps();
  const [searchText, setSearchText] = useState('');

  const handleSearchTextSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const searchValue = (e.currentTarget.elements[0] as HTMLInputElement).value;
    if ((searchValue && searchValue.length > 2) || searchValue === '') {
      // only do search if searchValue at least 3 chars
      setSearchTerm(searchValue);
    }
  };

  const handleChangeText = (newValue: string) => {
    typewriter.textInput({
      input_value: newValue,
      input_label: 'Search by name or brand',
      page_or_screen_name: EventNames.projectEstimator.selectView.page,
      ...commonTrackingProps,
    });

    setSearchText(newValue);
  };

  return (
    <Form onSubmit={handleSearchTextSubmit} width="100%">
      <TextInput
        iconBefore={iSearch}
        placeholder="Search by name or brand"
        marginRight={300}
        width="100%"
        value={searchText}
        data-testid="search-input"
        onChange={(e) => handleChangeText(e.target.value)}
        backgroundColor="neutral.100"
        border="none"
      />
    </Form>
  );
};
