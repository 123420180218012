import { useCallback } from 'react';

import { Box, Button } from '@hover/blueprint';
import { connect, useSelector } from 'react-redux';

import { NavButton } from 'src/components/blueprint';
import { ButtonStatus } from 'src/features/exteriorEstimator/constants/tradeTypes';
import {
  getParams,
  getEstimateGroupIdFromLocation,
  getIsEstimateGroupSold,
  getEstimateGroup,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { ProposalViews } from 'src/features/exteriorEstimator/types';
import { useTracking } from 'src/hooks';
import { COMMERCE_PROJECT_SCOPE, FeatureFlag } from 'src/lib/FeatureFlag';
import {
  getUserTrackingProps,
  isUserLightWeightFlow,
  getCanViewProductionConsole,
  getOrgSettings,
  getMaterialListFeature,
  getOrderingOnlyFeatureAccess,
} from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { RootState } from 'src/types/reduxStore';

import { SoldStatusButton } from './SoldStatusButton';

export const mapStateToProps = (state: RootState) => ({
  estimateGroupId: getEstimateGroupIdFromLocation(state),
  jobId: getParams(state).jobId,
  selectedOrgId: getParams(state).orgId,
  isLightWeightFlow: isUserLightWeightFlow(state),
  canViewProductionConsole: getCanViewProductionConsole(state),
  estimateGroupSold: getIsEstimateGroupSold(state),
  estimateGroup: getEstimateGroup(state),
  orgSettings: getOrgSettings(state),
  showOrderingFlow: getOrgSettings(state)?.showOrderingFlow,
});

type OwnProps = {
  setProposalModalView: (view: ProposalViews | null) => void;
};

type Props = ReturnType<typeof mapStateToProps> & OwnProps;

const DetailFooterComponent: React.FC<Props> = (props) => {
  const { setProposalModalView } = props;
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const getStatus = useCallback(() => {
    const { estimateGroupSold } = props;

    if (estimateGroupSold) {
      return ButtonStatus.Sold;
    }
    return ButtonStatus.Unsold;
  }, [props]);

  const trackProjectScopePage = useCallback(() => {
    const { jobId } = props;
    typewriter.buttonPressed({
      button_text: 'Project Scope',
      job_id: Number(jobId),
      page_or_screen_name: EventNames.estimator.estimateDetailsScreen.page,
      primary_cta: false,
      ...commonTrackingProps,
    });
  }, [commonTrackingProps]);

  const renderProductionButton = useCallback(() => {
    const { jobId, canViewProductionConsole, selectedOrgId, estimateGroup } =
      props;
    const status = getStatus();
    const shouldRender =
      status === ButtonStatus.Sold && canViewProductionConsole;

    if (!shouldRender || !estimateGroup) return <></>;
    const orgId = selectedOrgId || estimateGroup.orgId;
    const productionUrl = `/estimator_production?jobId=${jobId}&orgId=${orgId}`;
    return (
      <FeatureFlag
        flagName={COMMERCE_PROJECT_SCOPE}
        whenOn={
          <NavButton
            to={`/project/${jobId}?orgId=${orgId}`}
            onClick={trackProjectScopePage}
            size={{
              base: 'default',
              tablet: 'large',
              desktop: 'large',
            }}
            fill="outline"
            data-testid="projectScopeButton"
          >
            Project Scope
          </NavButton>
        }
        whenOff={
          <NavButton
            to={productionUrl}
            onClick={() =>
              typewriter.buttonPressed({
                button_text: 'Production Console',
                primary_cta: false,
                page_or_screen_name:
                  EventNames.estimator.estimateDetailsScreen.page,
                job_id: Number(jobId),
                ...commonTrackingProps,
              })
            }
            fill="outline"
            size={{ base: 'default', tablet: 'large', desktop: 'large' }}
            paddingX={{
              base: 200,
              tablet: undefined,
              desktop: undefined,
            }}
            data-testid="productionButton"
          >
            Production Console
          </NavButton>
        }
      />
    );
  }, [getStatus, props, trackProjectScopePage, commonTrackingProps]);

  const handleGetProposalClick = useCallback(() => {
    const { jobId } = props;
    typewriter.buttonPressed({
      button_text: 'Create Proposal',
      primary_cta: false,
      page_or_screen_name: EventNames.estimator.estimateDetailsScreen.page,
      job_id: Number(jobId),
      ...commonTrackingProps,
    });

    setProposalModalView(ProposalViews.CREATE);
  }, [props, setProposalModalView, commonTrackingProps]);

  const renderProposalButton = useCallback(() => {
    const { isLightWeightFlow, orgSettings } = props;
    const showGetProposal = !isLightWeightFlow && orgSettings?.proposalsEnabled;
    return (
      showGetProposal && (
        <Button
          size={{ base: 'default', tablet: 'large', desktop: 'large' }}
          paddingX={{ base: 200, tablet: undefined, desktop: undefined }}
          marginX={100}
          fill="outline"
          onClick={handleGetProposalClick}
          data-testid="proposalButton"
        >
          Get Proposal
        </Button>
      )
    );
  }, [handleGetProposalClick, props]);

  const status = getStatus();
  const { showOrderingFlow, jobId } = props;
  const showOrderingVersion =
    useSelector(getOrderingOnlyFeatureAccess) && showOrderingFlow;
  const materialListFeatureEnabled = useSelector(getMaterialListFeature);
  const showOrderOrMaterialListVersion =
    showOrderingVersion || materialListFeatureEnabled;

  if (!jobId) return null;

  return (
    <>
      <Box
        flex={1}
        justifyContent="space-between"
        data-testid="estimateDetailsFooter"
      >
        <Box flex={5}>
          {renderProductionButton()}
          {renderProposalButton()}
        </Box>

        {!showOrderOrMaterialListVersion && (
          <SoldStatusButton status={status} jobId={jobId as string} />
        )}
      </Box>
    </>
  );
};

export const DetailFooter = connect(mapStateToProps)(DetailFooterComponent);
