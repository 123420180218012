import {
  Box,
  TextInput,
  Checkbox,
  Body,
  Select,
  RadioGroup,
  SimpleGrid,
  TileRadio,
  StepperInput,
  Label,
} from '@hover/blueprint';

import { EstimationConfigInputInputTypeEnum } from 'src/api/graphql-global-types';
import { sortQuestionInputOptions } from 'src/features/exteriorEstimator/utils/questionsUtils';
import { useTemplateStore } from 'src/features/projectEstimator/hooks';
import { isMeasurementInput } from 'src/features/projectEstimator/hooks/utils/hdfUtils';
import { InputWithValue } from 'src/features/projectEstimator/types';

export const ProjectEstimatorInput: React.FC<{
  input: InputWithValue;
  templateId: string;
}> = ({ input, templateId }) => {
  const updateInput = useTemplateStore((state) => state.updateInput);

  const renderMeasurementInput = () => {
    return (
      <Label
        box
        flexDirection="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Body>{input.question}</Body>
        </Box>
        <TextInput
          value={(input.value as number) ?? ''}
          onChange={(e) =>
            updateInput({
              inputId: input.id,
              value: Number(e.target.value),
              templateId: Number(templateId),
            })
          }
          width={600}
          type="number"
          backgroundColor="neutral.100"
          border="none"
        />
      </Label>
    );
  };

  const renderBooleanInput = () => {
    return (
      <Label
        box
        flexDirection="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Body>{input.question}</Body>
        </Box>
        <Checkbox
          isChecked={!!input.value}
          onChange={(e) => {
            updateInput({
              inputId: input.id,
              value: e.target.checked,
              templateId: Number(templateId),
            });
          }}
        />
      </Label>
    );
  };

  const renderNumberInput = () => {
    return (
      <Label
        box
        flexDirection="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Body>{input.question}</Body>
        </Box>
        <StepperInput
          min={0}
          onChange={(stringValue: string, floatValue: number) =>
            updateInput({
              inputId: input.id,
              value: Number(floatValue || 0),
              templateId: Number(templateId),
            })
          }
          defaultValue={(input.value as number) ?? 0}
          size="small"
          width={600}
          data-testid="stepper-input"
        />
      </Label>
    );
  };

  const renderStringInput = () => {
    return (
      <Label
        box
        flexDirection="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Body>{input.question}</Body>
        </Box>
        <TextInput
          type="text"
          backgroundColor="neutral.100"
          border="none"
          value={(input.value as string) ?? ''}
          onChange={(e) =>
            updateInput({
              inputId: input.id,
              value: e.target.value,
              templateId: Number(templateId),
            })
          }
        />
      </Label>
    );
  };

  const renderSelectInput = () => {
    const sortedOptions = sortQuestionInputOptions(input?.inputOptions ?? []);
    const shouldUseSelect = sortedOptions.length >= 5;

    return (
      <Box flexDirection="column" width="100%">
        <Box>
          <Body fontWeight="bold">{input.question}</Body>
        </Box>
        <Label box flexDirection="row" width="100%" gap={200}>
          {shouldUseSelect ? (
            <Select
              value={input.value as string}
              onChange={(e) =>
                updateInput({
                  inputId: input.id,
                  value: e.target.value,
                  templateId: Number(templateId),
                })
              }
            >
              {sortedOptions.map((option) => (
                <option value={option.value} key={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          ) : (
            <RadioGroup
              value={input.value as string}
              onChange={(value: string) =>
                updateInput({
                  inputId: input.id,
                  value,
                  templateId: Number(templateId),
                })
              }
              width="100%"
            >
              <SimpleGrid gap={400} minChildWidth="128px">
                {sortedOptions.map((option) => (
                  <TileRadio value={option.value} key={option.value}>
                    {option.label}
                  </TileRadio>
                ))}
              </SimpleGrid>
            </RadioGroup>
          )}
        </Label>
      </Box>
    );
  };

  if (
    input.inputType === EstimationConfigInputInputTypeEnum.NUMBER &&
    isMeasurementInput(input)
  ) {
    return renderMeasurementInput();
  }
  if (input.inputType === EstimationConfigInputInputTypeEnum.NUMBER) {
    return renderNumberInput();
  }
  if (input.inputType === EstimationConfigInputInputTypeEnum.BOOLEAN) {
    return renderBooleanInput();
  }
  if (input.inputType === EstimationConfigInputInputTypeEnum.STRING) {
    return renderStringInput();
  }
  if (input.inputType === EstimationConfigInputInputTypeEnum.SELECT) {
    return renderSelectInput();
  }

  return null;
};
