import { intersection } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  TradeTypeEnum,
  EstimationConfigTemplateCollectionTypeEnum as TemplateCollectionTypeEnum,
} from 'src/api/graphql-global-types';
import {
  configTemplateCollectionsForOrg_estimationConfigTemplateCollectionsForOrg_templateSections as TemplateSection,
  configTemplateCollectionsForOrg_estimationConfigTemplateCollectionsForOrg_templateSections_templatesByTradeType as templatesByTradeType,
  configTemplateCollectionsForOrg_estimationConfigTemplateCollectionsForOrg_templateSections_templatesByTradeType_templates as Template,
} from 'src/api/types/configTemplateCollectionsForOrg';
import {
  getTemplatesEnd,
  toggleSelectedTemplate,
  unselectTemplates,
} from 'src/features/exteriorEstimator/redux/actions/templatesActions';
import {
  getSelectedTemplateIds,
  getSelectedTemplates,
  getTemplates,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';

export interface TemplateWithCollectionType extends Template {
  collectionType: TemplateCollectionTypeEnum;
}

export function useMaterialListTemplates() {
  const dispatch = useDispatch();
  const selectedTemplates = useSelector(getSelectedTemplates);
  const selectedTemplateIds = useSelector(getSelectedTemplateIds);
  const allTemplates = useSelector(getTemplates) || [];

  /**
   *  This function checks to see if the clicked template's tradeType is the same as any of the selected templates
   *  and then returns an array of any templateIds that are in both the selectedTemplates and the templates with the same tradeType
   *
   *  @param clickedTemplateTradeType - a value representing the tradeType of a template that was clicked
   *
   *  @return {number[]} an array of all the selected templates id's that are within the same tradeType as the clicked template
   */
  const tradeAndSelectedIntersection = (
    clickedTemplateTradeType: TradeTypeEnum,
  ) => {
    const templatesWithSameTrade =
      selectedTemplates?.filter((template) => {
        return template.tradeType === clickedTemplateTradeType;
      }) || [];

    const templateIds = templatesWithSameTrade.map((template) => template.id);

    return intersection(templateIds, selectedTemplateIds);
  };

  /**
   *  A function that toggles the selected template on or off and then unselects any other templates that are within the same tradeType
   *
   *  @param templateId - a value representing the tradeType of a template that was clicked
   *  @param templateTradeType - a value representing the tradeType of a template that was clicked
   *
   */
  const toggleTemplateAndUnselectOthersInSameTrade = (
    templateId: number,
    templateTradeType: TradeTypeEnum,
  ) => {
    dispatch(toggleSelectedTemplate({ id: templateId }));

    const intersectionIds = tradeAndSelectedIntersection(templateTradeType);
    if (intersectionIds.length > 0) {
      dispatch(unselectTemplates({ ids: intersectionIds }));
    }
  };

  /**
   *  a function that takes in an array of templateSections and flattens them into an array of templates
   *  and then dispatches the flattned list of templates to the redux store
   *
   *  @param templateSections - an array of all the template sections within the configTemplateCollectionsForOrg query
   */
  const dispatchTemplatesFromSections = (
    templateSections: TemplateSection[],
  ) => {
    const templatesFlattened: Template[] = templateSections
      ?.flatMap((section: TemplateSection) => {
        const { collectionType } = section; // Get the collectionType
        return section.templatesByTradeType.map(
          (templatesByTradeTypeFlattened: templatesByTradeType) => {
            return templatesByTradeTypeFlattened.templates.map(
              (template: Template) => ({
                ...template,
                collectionType, // Add the collectionType to the template
              }),
            );
          },
        );
      })
      .flat();

    dispatch(
      getTemplatesEnd({
        templates: templatesFlattened as any,
      }),
    );
  };

  return {
    dispatchTemplatesFromSections,
    toggleTemplateAndUnselectOthersInSameTrade,
    //  overwrite typing because template data is structured differently in material list vs estimator template queries and redux state
    allTemplatesFromSections:
      allTemplates as unknown as TemplateWithCollectionType[], // TODO: https://hoverinc.atlassian.net/browse/EC-2005 and remove this typing
  };
}
