import { PureComponent } from 'react';

import { Box } from '@hover/blueprint';

import {
  InputWithDropdownTypeahead,
  Suggestion,
} from 'src/components/InputWithDropdownTypeahead';
import { Label } from 'src/components/Misc';

export interface ComponentProps {
  label: string;
  secondaryLabel?: string;
  onChangeFunction?: (input: string, selected: boolean) => void;
  placeholder?: string;
  suggestions: Suggestion[];
}

export type Props = ComponentProps;

export class AddListItemTextInputWithTypeahead extends PureComponent<Props> {
  render() {
    const {
      label,
      secondaryLabel,
      onChangeFunction,
      placeholder,
      suggestions,
    } = this.props;

    const dataTestIdForBox = label.replace(/\s/g, '').toLowerCase();

    return (
      <Box flexDirection="column">
        <Box flexDirection="row" justifyContent="space-between">
          <Label>{label}</Label>
          {secondaryLabel && <Label>{secondaryLabel}</Label>}
        </Box>
        <div
          style={{
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
          }}
          data-testid={`${dataTestIdForBox}-addItemInput`}
        >
          <InputWithDropdownTypeahead
            suggestions={suggestions}
            onChange={onChangeFunction}
            label={label}
            placeholder={placeholder}
          />
        </div>
      </Box>
    );
  }
}
