import { PureComponent } from 'react';

import { Body, Label, TileCheckboxProps } from '@hover/blueprint';
import autobind from 'autobind-decorator';
import { connect } from 'react-redux';

import { EstimationConfigInputInputTypeEnum } from 'src/api/graphql-global-types';
import { withTypewriter } from 'src/components/WithTypewriter';
import { UpdateAnswer } from 'src/features/exteriorEstimator/redux/actions/answerActions';
import { getPages } from 'src/features/exteriorEstimator/redux/sagas/selectors';
import {
  CustomInputTypes,
  Input,
  QuestionAnswer,
} from 'src/features/exteriorEstimator/types';
import { getQuestionDefaultValue } from 'src/features/exteriorEstimator/utils/questionsUtils';
import { getUserTrackingProps } from 'src/redux/selectors';
import { RootState } from 'src/types/reduxStore';
import { jobProps } from 'src/utils/trackingUtils';
import { UNITS_MAP } from 'src/utils/unitsMap';

import {
  MEASUREMENT_NAME,
  ROOF_LINE_SEGMENT_ADJUSTMENT,
  SIDING_LINE_SEGMENT_ADJUSTMENT,
} from '../../../../constants/questionCategories';
import { CheckboxQuestion } from './CheckboxQuestion';
import { NumberQuestion } from './NumberQuestion';
import { SelectTileQuestion } from './SelectTileQuestion';
import { StepperQuestion } from './StepperQuestion';
import { TileCheckboxQuestion } from './TileCheckboxQuestion';

interface OwnProps {
  question: Input;
  hideText?: boolean;
  htmlId?: string;
  tileDescription?: TileCheckboxProps['description'];
  updateAnswer: (updateProps: UpdateAnswer) => void;
  typewriter?: any;
}

export const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  allQuestionResponses: state.exteriorEstimator.inputs.questionResponses,
  jobDetails: state.exteriorEstimator.job.jobDetails,
  questionResponse:
    state.exteriorEstimator.inputs.questionResponses[ownProps.question.id],
  userTrackingProps: getUserTrackingProps(state),
  category: state.exteriorEstimator.inputs.currentQuestionCategory,
  pages: getPages(state),
});

type Props = ReturnType<typeof mapStateToProps> & OwnProps;

export class QuestionComponent extends PureComponent<Props> {
  formatQuestionText(question: string | null, measurementUnits?: string) {
    if (!question) return '';
    const formatedMeasurementUnits = measurementUnits
      ? UNITS_MAP[measurementUnits]
      : null;

    return `${question}${
      formatedMeasurementUnits ? ` (${formatedMeasurementUnits})` : ''
    }`;
  }

  @autobind
  handleInputChange(answer: QuestionAnswer) {
    const {
      question: { id },
      question,
      updateAnswer,
      userTrackingProps,
      category,
      pages,
      jobDetails,
      typewriter,
    } = this.props;

    updateAnswer({
      questionId: id,
      answer,
    });

    // after updating an answer, look thru the dependentInputConditions
    question?.dependentInputConditions?.forEach((condition) => {
      const foundPage = pages.find((page) => page.category === category);
      const foundQuestionInput = foundPage?.questions?.find(
        (input) => input.id.toString() === condition.estimationInputId,
      );

      if (!foundQuestionInput) return;

      // then update all the questions of the dependentInputConditions with their default value
      updateAnswer({
        questionId: condition.estimationInputId,
        answer: getQuestionDefaultValue(foundQuestionInput),
      });
    });

    typewriter.textInput({
      input_value: answer ? answer.toString() : '',
      input_label: question.name ?? '',
      page_or_screen_name: category,
      ...userTrackingProps,
      ...jobProps(jobDetails),
    });
  }

  render() {
    const {
      questionResponse,
      question,
      question: {
        question: _question,
        inputType,
        measurementUnits,
        inputCategory: { name: category },
        inputConditions,
      },
      hideText,
      htmlId,
      allQuestionResponses,
      jobDetails,
      tileDescription,
    } = this.props;

    const questionText = this.formatQuestionText(_question, measurementUnits);
    const questionDescription = question.description;

    if (inputConditions && inputConditions.length > 0) {
      if (
        !inputConditions.some(
          (condition) =>
            String(allQuestionResponses[condition.parentInputId]) ===
            condition.parentValue,
        )
      ) {
        return null;
      }
    }

    if (inputType === EstimationConfigInputInputTypeEnum.SELECT) {
      return (
        <SelectTileQuestion
          question={question}
          value={questionResponse as string}
          questionText={questionText}
          jobDetails={jobDetails}
          handleInputChange={this.handleInputChange}
        />
      );
    }

    if (inputType === CustomInputTypes.TILE_CHECKBOX) {
      return (
        <TileCheckboxQuestion
          handleInputChange={this.handleInputChange}
          description={tileDescription}
          isChecked={!!questionResponse}
          data-testid="tileCheckboxQuestion"
        >
          {question.name}
        </TileCheckboxQuestion>
      );
    }

    const isMeasurementsTypeQuestion =
      category === MEASUREMENT_NAME ||
      category === ROOF_LINE_SEGMENT_ADJUSTMENT ||
      category === SIDING_LINE_SEGMENT_ADJUSTMENT;

    return (
      <Label
        box
        data-testid="questionContainer"
        justifyContent="space-between"
        gap={400}
        alignItems="center"
      >
        <Body
          as="span"
          marginY={300}
          flex={1}
          data-testid="questionText"
          display={hideText ? 'none' : 'initial'}
        >
          {questionText}
          {questionDescription && (
            <Body as="sup" color="neutralTextLight" display="block">
              {questionDescription}
            </Body>
          )}
        </Body>
        {inputType === EstimationConfigInputInputTypeEnum.BOOLEAN && (
          <CheckboxQuestion
            handleInputChange={this.handleInputChange}
            isChecked={!!questionResponse}
          />
        )}
        {inputType === EstimationConfigInputInputTypeEnum.NUMBER &&
          isMeasurementsTypeQuestion && (
            <NumberQuestion
              key={`${_question}_${questionResponse}`}
              id={htmlId}
              value={questionResponse as number}
              handleInputChange={this.handleInputChange}
            />
          )}
        {inputType === EstimationConfigInputInputTypeEnum.NUMBER &&
          !isMeasurementsTypeQuestion && (
            <StepperQuestion
              value={questionResponse as number}
              updateAnswer={this.handleInputChange}
            />
          )}
      </Label>
    );
  }
}

export const Question = connect(mapStateToProps)(
  withTypewriter(QuestionComponent),
);
