import { useEffect, useState } from 'react';

import { IconButton } from '@hover/blueprint';
import { iArrowUpCircle } from '@hover/icons';

export const GoToTopButton = () => {
  const [windowWasScrolledDown, setWindowWasScrolledDown] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      return setWindowWasScrolledDown(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  if (!windowWasScrolledDown) {
    return null;
  }

  return (
    <IconButton
      testId="go-to-top-button"
      label="Go to top"
      size="large"
      icon={iArrowUpCircle}
      position="fixed"
      bottom={{ base: '70px', tablet: '60px' }}
      right={{ base: '16px', tablet: '5px' }}
      zIndex="1000"
      iconSize="huge"
      onClick={handleClick}
    />
  );
};
