import queryString from 'query-string';

// locationSearch should be a string like '?key1=value1&key2=value2'
export const getParams = (locationSearch: string) => {
  const parsedQuery = queryString.parse(locationSearch);
  return Object.entries(parsedQuery).reduce((acc, [key, value]) => {
    if (key.endsWith('[]') && !Array.isArray(value)) {
      acc[key] = [value ?? ''];
    }
    return acc;
  }, parsedQuery);
};
