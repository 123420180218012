import { Box, Heading, Link } from '@hover/blueprint';
import { useSelector } from 'react-redux';

import { HowItWorksSection } from 'src/features/settings/components/Proposal/HowItWorksSection';
import { Preview } from 'src/features/settings/components/Proposal/Preview';
import type { ViewMode } from 'src/features/settings/components/Proposal/Proposal';
import { useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

type Props = {
  viewMode: ViewMode;
};

export const ProposalHeader: React.FC<Props> = ({ viewMode }) => {
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const trackLearnMore = () => {
    typewriter.linkPressed({
      link_text: 'Learn More',
      primary_cta: false,
      page_or_screen_name: EventNames.settings.proposal.page,
      ...commonTrackingProps,
    });
  };

  return (
    <Box justifyContent="space-between" alignItems="center" my={500}>
      <Box alignItems="center">
        <Heading data-testid="proposalHeading" size={600}>
          Proposal
        </Heading>
      </Box>
      <Box alignItems="center">
        <Link
          fontWeight="bold"
          href="https://help.hover.to/en/articles/5535283-proposal-management-customization"
          onClick={trackLearnMore}
        >
          Learn More
        </Link>
        <HowItWorksSection viewMode={viewMode} />
        <Preview viewMode={viewMode} />
      </Box>
    </Box>
  );
};
