import React, { PureComponent } from 'react';

import {
  Body,
  Box,
  Button,
  TileRadio,
  TextInput,
  Modal,
  RadioGroup,
} from '@hover/blueprint';
import { iDollarSign, iPercent } from '@hover/icons';
import autobind from 'autobind-decorator';
import { NumericFormat } from 'react-number-format';
import { connect } from 'react-redux';

import {
  EstimationDiscountCreate,
  EstimationDiscountUpdate,
  EstimationDiscountDiscountTypeEnum,
} from 'src/api/graphql-global-types';
import { withTypewriter } from 'src/components/WithTypewriter';
import {
  getAllActiveEstimates,
  getEstimationGroupEstimate,
  getDiscount,
  getParams,
  getEstimateGroupIdFromLocation,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { DiscountCalculatorGenerator } from 'src/features/exteriorEstimator/utils/DiscountCalculator';
import {
  calculatePercentage,
  calculateDollars,
} from 'src/features/exteriorEstimator/utils/discountsUtils';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { RootState } from 'src/types/reduxStore';
import { Dropdown } from 'style-guide';

export const mapStateToProps = (state: RootState) => ({
  selectedPromotionId: state.exteriorEstimator.inputs.selectedPromotionId,
  activeEstimates: getAllActiveEstimates(state),
  getEstimate: (estimationEstimateId: number) =>
    getEstimationGroupEstimate(state, estimationEstimateId),
  fetchDiscount: (discountId: number) => getDiscount(state, discountId),
  estimateGroupId: getEstimateGroupIdFromLocation(state),
  jobId: getParams(state).jobId,
  commonProps: getUserTrackingProps(state),
});

interface IProps {
  closeModal: () => void;
  percentValue: number | string;
  dollarValue: number | string;
  discountType: EstimationDiscountDiscountTypeEnum;
  name: string;
  displayVerb: string;
  createPromotion?: (params: EstimationDiscountCreate) => void;
  updatePromotion?: (params: EstimationDiscountUpdate) => void;
  typewriter: any;
}

interface IState {
  percentValue?: number | string;
  dollarValue?: number | string;
  discountType: EstimationDiscountDiscountTypeEnum;
  amountValidationError: boolean;
  nameValidationError: boolean;
  name?: string;
  estimateId: string | null | undefined;
}

type Props = IProps & ReturnType<typeof mapStateToProps>;

export class PromotionModalComponent extends PureComponent<Props, IState> {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  amountElement: any;

  constructor(props: Props) {
    super(props);
    const {
      percentValue,
      dollarValue,
      name,
      discountType,
      name: nameProps,
    } = this.props;
    this.amountElement = React.createRef();

    this.state = {
      estimateId: null,
      percentValue,
      dollarValue,
      discountType,
      name,
      amountValidationError: false,
      nameValidationError: !nameProps,
    };
  }

  componentDidMount() {
    const { commonProps, jobId, typewriter } = this.props;

    typewriter.pageViewed({
      page_or_screen_name:
        EventNames.estimator.estimateDetailsScreen.promotion.page,
      job_id: Number(jobId),
      ...commonProps,
    });
  }

  @autobind
  public handleChangeDiscountType(nextValue: string) {
    this.setState({
      discountType: nextValue as EstimationDiscountDiscountTypeEnum,
    });
  }

  @autobind
  private getEstimateForDiscount(estimateId: string | undefined | null) {
    const { getEstimate, selectedPromotionId, fetchDiscount } = this.props;
    const discount = fetchDiscount(selectedPromotionId ?? 0);
    let estimate;
    if (estimateId) {
      estimate = getEstimate(Number(estimateId));
    } else if (discount) {
      estimate = getEstimate(Number(discount.estimationEstimateId));
    }
    return estimate;
  }

  @autobind
  calculateTotal(estimateId: string | undefined | null) {
    const { activeEstimates } = this.props;
    let total = 0;

    const estimate = this.getEstimateForDiscount(estimateId);
    const discountCalculator = new DiscountCalculatorGenerator().generate(
      estimate,
      activeEstimates,
    );
    total = discountCalculator.getTotalWithDiscount();
    return total;
  }

  @autobind
  public validate(value: number, estimateId: string | undefined | null) {
    const { discountType } = this.state;
    const { activeEstimates } = this.props;

    const total = this.calculateTotal(estimateId);

    const estimate = this.getEstimateForDiscount(estimateId);
    const discountCalculator = new DiscountCalculatorGenerator().generate(
      estimate,
      activeEstimates,
    );
    const amountValidationError = discountCalculator.discountAmountForbidden(
      total,
      value,
      discountType,
    );
    return amountValidationError;
  }

  @autobind
  public handleUpdateValue({ floatValue: value }: { floatValue?: number }) {
    const { discountType, estimateId } = this.state;

    const amountValidationError = this.validate(Number(value), estimateId);
    const total = this.calculateTotal(estimateId);

    this.setState({
      dollarValue: calculateDollars({
        value: Number(value),
        discountType,
        total: total ?? 0,
      }),
      percentValue: calculatePercentage({
        value: Number(value),
        discountType,
        total: total ?? 0,
      }),
      amountValidationError,
    });
  }

  @autobind
  public validateName(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    this.setState({
      name: value,
      nameValidationError: !value,
    });
  }

  @autobind
  public handleApplyPromotion() {
    const { discountType, percentValue, dollarValue, name, estimateId } =
      this.state;
    const { createPromotion, updatePromotion, commonProps, jobId, typewriter } =
      this.props;
    const value =
      discountType === EstimationDiscountDiscountTypeEnum.DOLLARS
        ? (dollarValue as number)
        : (percentValue as number);

    if (!value || !name) return;

    typewriter.buttonPressed({
      button_text: `Apply Promotion`,
      primary_cta: false,
      page_or_screen_name:
        EventNames.estimator.estimateDetailsScreen.promotion.page,
      job_id: Number(jobId),
      ...commonProps,
    });

    const params: EstimationDiscountCreate = {
      estimationEstimateId: estimateId as string,
      value,
      name,
      discountType,
    };
    if (createPromotion) {
      createPromotion(params);
    } else if (updatePromotion) {
      updatePromotion({ value, name, discountType });
    }
  }

  @autobind
  public selectEstimate(selection: string) {
    const { activeEstimates } = this.props;

    const estimateByName = activeEstimates.find(
      (estimate) => estimate.name === selection,
    );

    const selectionId = estimateByName ? estimateByName.id : null;
    const amountValidationError = this.validate(
      this.amountElement.current.value,
      selectionId?.toString(),
    );
    this.setState({
      estimateId: selectionId?.toString(),
      amountValidationError,
    });
  }

  @autobind
  public isApplyPromotionButtonDisabled() {
    const { amountValidationError, nameValidationError, dollarValue } =
      this.state;
    const isDisabled =
      amountValidationError || nameValidationError || !dollarValue;
    return isDisabled;
  }

  @autobind
  public handleCloseModalButtonClick() {
    const { closeModal, commonProps, jobId, typewriter } = this.props;

    typewriter.buttonPressed({
      button_text: `Cancel Add Promotion`,
      primary_cta: false,
      page_or_screen_name:
        EventNames.estimator.estimateDetailsScreen.promotion.page,
      job_id: Number(jobId),
      ...commonProps,
    });
    closeModal();
  }

  public render() {
    const {
      discountType,
      dollarValue,
      percentValue,
      amountValidationError,
      name,
    } = this.state;
    const { displayVerb, activeEstimates, createPromotion } = this.props;

    const selectionOptions = activeEstimates.map((estimate) => ({
      value: estimate.name ?? '',
    }));
    selectionOptions.unshift({
      value: 'Applies to Project',
    });

    const isDollarMode =
      discountType === EstimationDiscountDiscountTypeEnum.DOLLARS;
    const isPercentMode =
      discountType === EstimationDiscountDiscountTypeEnum.PERCENTAGE;

    return (
      <Modal
        size="small"
        onClose={this.handleCloseModalButtonClick}
        isOpen
        header={`${displayVerb} Promotion`}
        footer={
          <Box gap={400}>
            <Button fill="outline" onClick={this.handleCloseModalButtonClick}>
              Cancel
            </Button>
            <Button
              data-testid={`applyPromoButton-${displayVerb}`}
              isDisabled={this.isApplyPromotionButtonDisabled()}
              onClick={this.handleApplyPromotion}
            >
              Apply Promotion
            </Button>
          </Box>
        }
      >
        <Box
          flexDirection="column"
          width={1}
          data-testid={`promotionsV2ModalContent-${displayVerb}`}
        >
          {createPromotion && (
            <Box flex={1} alignItems="center" width={1} marginBottom={400}>
              <Box width={1}>
                <Dropdown
                  options={selectionOptions}
                  defaultValue="Applies to Opportunity"
                  onChange={(e) => this.selectEstimate(e.target.value)}
                />
              </Box>
            </Box>
          )}
          <Box flex={1} alignItems="center" width={1} marginBottom={400}>
            <TextInput
              onChange={this.validateName}
              placeholder="Enter promotion name..."
              data-testid={`promoNameInput-${displayVerb}`}
              defaultValue={name}
            />
          </Box>
          <RadioGroup
            flex={1}
            marginBottom={400}
            onChange={this.handleChangeDiscountType}
            value={discountType}
          >
            <Box flexDirection="row" gap={400}>
              <TileRadio
                flex={1}
                value={EstimationDiscountDiscountTypeEnum.PERCENTAGE}
                data-testid={`percentageButton-${displayVerb}`}
              >
                (%) Percentage
              </TileRadio>

              <TileRadio
                flex={1}
                value={EstimationDiscountDiscountTypeEnum.DOLLARS}
                data-testid={`dollarButton-${displayVerb}`}
              >
                ($) Fixed Amount
              </TileRadio>
            </Box>
          </RadioGroup>
          <NumericFormat
            customInput={TextInput}
            thousandSeparator
            decimalScale={2}
            allowNegative={false}
            iconBefore={isDollarMode ? iDollarSign : iPercent}
            inputMode="decimal"
            onValueChange={this.handleUpdateValue}
            value={isDollarMode ? dollarValue : percentValue}
            data-testid={`promoAmountInput-${displayVerb}`}
            getInputRef={this.amountElement}
          />
          <Box
            flex={1}
            alignItems="center"
            width={1}
            marginBottom={400}
            justifyContent="center"
            minHeight={100}
          >
            {!!dollarValue && !amountValidationError && (
              <Body size={300} color="neutral.500" margin=".5em 0 .5em 0">
                {isPercentMode ? (
                  <NumericFormat
                    value={dollarValue}
                    decimalScale={2}
                    displayType="text"
                    prefix="-$"
                    thousandSeparator
                    data-testid="promoDiffAmount"
                  />
                ) : (
                  <NumericFormat
                    value={percentValue}
                    decimalScale={2}
                    displayType="text"
                    prefix="-"
                    suffix="%"
                    thousandSeparator
                    data-testid="promoDiffAmount"
                  />
                )}
              </Body>
            )}
            {amountValidationError && (
              <Body
                size={300}
                color="danger.500"
                margin=".5em 0 .5em 0"
                maxWidth="320px"
                data-testid="promoAmountValidationError"
              >
                Promotional value cannot exceed the total of the target
                estimate(s). Target estimate total cannot be 0.
              </Body>
            )}
          </Box>
        </Box>
      </Modal>
    );
  }
}

export const PromotionModal = connect(mapStateToProps)(
  withTypewriter(PromotionModalComponent),
);
