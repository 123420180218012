import { SIDING_ARGUMENT_MAP } from 'src/features/exteriorEstimator/constants/questionArgumentMappings';
import {
  Input,
  QuestionResponses,
  PlainMeasurements,
  FacetPageTradeTypes,
} from 'src/features/exteriorEstimator/types';
import { LineSegmentCalculator } from 'src/features/exteriorEstimator/utils/LineSegmentCalculator';
import { LineSegmentCalculatorHdf } from 'src/features/exteriorEstimator/utils/LineSegmentCalculatorHdf';
import { EhiOrgSettings } from 'src/redux/reducers/ehiReducer';
import { Measurements } from 'src/types/EstimationMeasurementTypes';
import {
  HDFMeasurements,
  PartialsMeasurements,
} from 'src/types/HdfMeasurements';

// returns updated totals and/or edge QuestionResponses based on a partial selection
export const getUpdatedLineSegmentValues = ({
  facetLabel,
  answer,
  answers,
  plainMeasurements,
  fullMeasurements,
  lineSegmentInputs,
  partialsMeasurements,
  hdfMeasurements,
  type,
  questionResponses,
  sidingTotalQuestion,
  sidingWithOpeningsAreaQuestion,
  stoneTotalQuestion,
  orgSettings,
}: {
  facetLabel?: string | null;
  answer?: boolean | null;
  answers?: QuestionResponses | null;
  lineSegmentInputs: Input[];
  type: FacetPageTradeTypes;
  partialsMeasurements: PartialsMeasurements | null;
  hdfMeasurements: HDFMeasurements | null;
  plainMeasurements: PlainMeasurements | null;
  fullMeasurements: Measurements | null;
  questionResponses: QuestionResponses;
  sidingTotalQuestion?: Input | null | undefined;
  stoneTotalQuestion?: Input | null | undefined;
  sidingWithOpeningsAreaQuestion?: Input | null | undefined;
  orgSettings: EhiOrgSettings;
}) => {
  let calculator;
  const newAnswers: QuestionResponses = {};

  if (partialsMeasurements && hdfMeasurements) {
    const { edges, facades, windowsForFacade } = partialsMeasurements;
    if (facetLabel) {
      calculator = new LineSegmentCalculatorHdf({
        edges,
        facades,
        windowsForFacade,
        hdfMeasurements,
        questionResponses: {
          ...questionResponses,
          [facetLabel]: answer,
        },
        orgSettings,
        isStone: type === 'STONE',
      });
    } else if (answers) {
      calculator = new LineSegmentCalculatorHdf({
        edges,
        facades,
        windowsForFacade,
        hdfMeasurements,
        questionResponses: {
          ...questionResponses,
          ...answers,
        },
        orgSettings,
        isStone: type === 'STONE',
      });
    }
  } else if (plainMeasurements && fullMeasurements) {
    if (facetLabel) {
      calculator = new LineSegmentCalculator({
        pristinePlainMeasurements: plainMeasurements,
        estimationJson: fullMeasurements,
        questionResponses: {
          ...questionResponses,
          [facetLabel]: answer,
        },
        orgSettings,
        trade: type,
      });
    } else if (answers) {
      calculator = new LineSegmentCalculator({
        pristinePlainMeasurements: plainMeasurements,
        estimationJson: fullMeasurements,
        questionResponses: {
          ...questionResponses,
          ...answers,
        },
        orgSettings,
        trade: type,
      });
    }
  }

  if (!calculator) return {};

  const { calculatedEdgeTotals, selectedAreaWithTrim } = calculator;

  if (type === 'SIDING' && sidingTotalQuestion) {
    newAnswers[sidingTotalQuestion.id] = selectedAreaWithTrim;
  }

  if (type === 'SIDING' && sidingWithOpeningsAreaQuestion) {
    newAnswers[sidingWithOpeningsAreaQuestion.id] = selectedAreaWithTrim;
  }

  if (type === 'STONE' && stoneTotalQuestion) {
    newAnswers[stoneTotalQuestion.id] = selectedAreaWithTrim;
  }

  const edgeAnswers: QuestionResponses = {};
  Object.entries(calculatedEdgeTotals).forEach(
    ([lineSegmentArgument, value]) => {
      // lineSegmentArgument = ridge_total | ridge_count | etc...

      // see if line segment maps to a specfic input / question
      let lineSegmentInput;
      if (type === 'SIDING' || type === 'STONE') {
        lineSegmentInput = lineSegmentInputs.find(
          (question) =>
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            SIDING_ARGUMENT_MAP[question.argument ?? ''] ===
            lineSegmentArgument,
        );
      } else if (type === 'ROOF') {
        lineSegmentInput = lineSegmentInputs.find(
          (question) => question.argument === lineSegmentArgument,
        );
      }

      if (lineSegmentInput) {
        edgeAnswers[lineSegmentInput.id] = parseFloat(value.toFixed(2));
      }
    },
  );
  return { ...newAnswers, ...edgeAnswers };
};
