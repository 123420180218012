import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Drawer,
  Flex,
  Heading,
  Icon,
  Input,
  Link,
  Popover,
  Textarea,
  useDisclosure,
} from '@hover/blueprint';
import { NumberInputField, NumberInput } from '@hover/blueprint/chakra';
import { hCalculator, iPercent, iX } from '@hover/icons';
import autosize from 'autosize';
import { get, isNil } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { EditedStatusEnum } from 'src/api/graphql-global-types';
import { projectManagementProductionList_projectManagementProductionList_listItems as ListItem } from 'src/api/types/projectManagementProductionList';
import { messages } from 'src/constants/messages';
import {
  CREATE_LIST_ITEM_MANUAL_ADJUSTMENT,
  DELETE_LIST_ITEM_MANUAL_ADJUSTMENT,
  GET_PRODUCTION_LIST,
} from 'src/features/project/apis/graphql/queries/queries';
import { formattedNumber } from 'src/features/project/util/utils';
import { useTracking, useToastEhi, ToastStatusEnum } from 'src/hooks';
import { useIsMobileBreakpoint } from 'src/hooks/useIsMobileBreakpoint';
import { getMaterialListFeature } from 'src/redux/selectors';
import { EventNames } from 'src/types';
import { lineItemQuantityUnits } from 'src/utils/unitsMap';

type Props = {
  listItem: ListItem;
  jobId: number;
  orgId: string;
  unitsMap: Map<string, string>;
};

const enum TOAST_IDS {
  CREATE_LIST_ITEM_MANUAL_ADJUSTMENT_FAILED,
  DELETE_LIST_ITEM_MANUAL_ADJUSTMENT_FAILED,
}

export const ListItemCalculationDetail = ({
  listItem,
  jobId,
  orgId,
  unitsMap,
}: Props) => {
  // #region Tracking
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const triggerCalculationDetailsEvent = () => {
    typewriter.moduleViewed({
      page_or_screen_name: EventNames.project.scope.calculationDetailsHovered,
      job_id: jobId,
      ...commonTrackingProps,
    });
  };

  const triggerContextEvent = () => {
    typewriter.textInput({
      input_label: 'Context',
      input_value: getValues('context')?.toString() ?? '',
      page_or_screen_name: EventNames.project.scope.calculationDetailsHovered,
      ...commonTrackingProps,
    });
  };

  const triggerWasteFactorEvent = () => {
    typewriter.textInput({
      input_label: 'Waste',
      input_value: getValues('wasteFactor').toString(),
      page_or_screen_name: EventNames.project.scope.calculationDetailsHovered,
      ...commonTrackingProps,
    });
  };

  const triggerCoverageEvent = () => {
    typewriter.textInput({
      input_label: 'Coverage',
      input_value: getValues('netCoveragePerSellingUnit').toString(),
      page_or_screen_name: EventNames.project.scope.calculationDetailsHovered,
      ...commonTrackingProps,
    });
  };

  const triggerApplyEvent = () => {
    typewriter.buttonPressed({
      button_text: 'Apply',
      page_or_screen_name: EventNames.project.scope.calculationDetailsHovered,
      primary_cta: false,
      ...commonTrackingProps,
    });
  };

  const toast = useToastEhi();
  // #endregion

  /* Flags */
  const materialListFeatureEnabled = useSelector(getMaterialListFeature);
  const isMobile = useIsMobileBreakpoint();

  /* #region Form */
  const formDefaultValues = useMemo(
    () => ({
      context: listItem.listItemContext,
      netCoveragePerSellingUnit: listItem.coverage || 0,
      wasteFactor: (listItem.wasteFactor || 0) * 100,
    }),
    // eslint-disable-next-line
    [
      listItem,
      listItem.listItemContext,
      listItem.coverage,
      listItem.wasteFactor,
    ],
  );

  useEffect(() => {
    reset(formDefaultValues);
  }, [
    listItem,
    listItem.listItemContext,
    listItem.coverage,
    listItem.wasteFactor,
  ]);

  const {
    control,
    formState: { isDirty, errors: formErrors },
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
    trigger,
    watch,
  } = useForm({
    defaultValues: formDefaultValues,
    mode: 'onBlur',
  });
  // #endregion

  // #region Popover Logic
  const { isOpen, onToggle, onClose } = useDisclosure();

  const [createManualAdjustment] = useMutation(
    CREATE_LIST_ITEM_MANUAL_ADJUSTMENT,
    {
      onError: () => {
        toast({
          id: TOAST_IDS.CREATE_LIST_ITEM_MANUAL_ADJUSTMENT_FAILED,
          description:
            messages.projectScope.errors.mutation.productionList
              .listItemManualAdjustment,
          status: ToastStatusEnum.ERROR,
        });
      },
      refetchQueries: [
        {
          query: GET_PRODUCTION_LIST,
          variables: {
            orgId,
            jobId,
          },
        },
      ],
    },
  );

  const [deleteManualAdjustment] = useMutation(
    DELETE_LIST_ITEM_MANUAL_ADJUSTMENT,
    {
      onError: () => {
        toast({
          id: TOAST_IDS.DELETE_LIST_ITEM_MANUAL_ADJUSTMENT_FAILED,
          description:
            messages.projectScope.errors.mutation.productionList
              .listItemManualAdjustment,
          status: ToastStatusEnum.ERROR,
        });
      },
      refetchQueries: [
        {
          query: GET_PRODUCTION_LIST,
          variables: {
            orgId,
            jobId,
          },
        },
      ],
    },
  );

  const handleCalculationDetailsOpen = () => {
    // Autosize can only calculate the width of a textarea that is not hidden.
    // This runs the autosizer as soon as the element is revealed.
    if (contextRef.current) {
      autosize.update(contextRef.current);
    }

    triggerCalculationDetailsEvent();
  };

  const {
    onBlur: contextFormOnBlur,
    ref: contextFormRef,
    ...contextFormMethods
  } = register('context', {
    validate: {
      lessThan128Chars: (value) => {
        return (
          !value ||
          value.length <= 256 ||
          'Context cannot be more than 256 characters'
        );
      },
    },
  });

  const contextRef = useRef<HTMLTextAreaElement>();

  useEffect(() => {
    if (contextRef.current && !isMobile) {
      autosize(contextRef.current);
    }
    return () => {
      if (contextRef.current && !isMobile) {
        autosize.destroy(contextRef.current);
      }
    };
  }, []);

  const handleContextBlur = (
    event: React.FocusEvent<HTMLTextAreaElement, Element>,
  ) => {
    contextFormOnBlur(event);

    triggerContextEvent();
  };

  const handleContextRef = (instance: HTMLTextAreaElement | null) => {
    contextFormRef(instance);
    contextRef.current = instance ?? undefined;
  };

  const measurementQuantityUnits = lineItemQuantityUnits(
    listItem.measurementUnits,
  );

  // Our usePrevious hook doesn't work here because it captures every onChange,
  // so we have to manually specify when to mark a value as previous state.
  const [prevWasteFactor, setPrevWasteFactor] = useState(
    getValues('wasteFactor'),
  );

  const { onBlur: wasteFactorOnBlur, ...wasteFactorFormMethods } = register(
    'wasteFactor',
    {
      valueAsNumber: true,
      required: true,
      validate: {
        lessThanOrEquals99: (value) => {
          return value <= 99 || 'Waste factor must be less than or equal to 99';
        },
        nonNegative: (value) => {
          return value >= 0 || 'Waste factor cannot be negative';
        },
        nonDecimal: (value) => {
          return Number.isInteger(value) || 'Waste factor cannot be a decimal';
        },
      },
    },
  );

  const handleWasteFactorBlur = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    // Technically this triggers a double validation because the blur event should trigger a validation,
    // but awaiting the blur event does not actually await any validations.
    // Awaiting on trigger is necessary to enforce that formErrors are updated.
    // Technically could bypass the normal blur hook by just using setValue but this seems like a hacky
    // alternative to a solution that should work on its own, and if any additional functionality exists
    // in the default blur hook it will be silently bypassed.

    // Could investigate further if there's a better way to do this but timeboxing for now.

    wasteFactorOnBlur(event);
    await trigger('wasteFactor');

    triggerWasteFactorEvent();

    if (!get(formErrors, 'wasteFactor')) {
      setPrevWasteFactor(getValues('wasteFactor'));
    } else {
      setValue('wasteFactor', prevWasteFactor);
    }
  };

  const watchWasteFactor = watch('wasteFactor');
  const wasteValue = ((listItem.measurement || 0) * watchWasteFactor) / 100;

  const wasteFactorDescription = `${formattedNumber(
    (listItem.measurement || 0) + wasteValue,
    null,
    measurementQuantityUnits ?? null,
  )} (${formattedNumber(
    wasteValue,
    null,
    measurementQuantityUnits ?? null,
  )} waste included)`;

  const [prevCoverage, setPrevCoverage] = useState(
    getValues('netCoveragePerSellingUnit'),
  );

  const { onBlur: coverageOnBlur } = register('netCoveragePerSellingUnit', {
    valueAsNumber: true,
    required: true,
    validate: {
      greaterThan0: (value) => {
        return value > 0 || 'Coverage must be greater than 0';
      },
    },
  });

  const handleCoverageBlur = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    coverageOnBlur(event);
    await trigger('netCoveragePerSellingUnit');

    triggerCoverageEvent();

    if (!get(formErrors, 'netCoveragePerSellingUnit')) {
      setPrevCoverage(getValues('netCoveragePerSellingUnit'));
    } else {
      setValue('netCoveragePerSellingUnit', prevCoverage);
    }
  };

  const watchCoverage = watch('netCoveragePerSellingUnit');
  const coverageDescription =
    watchCoverage >= 0.001
      ? formattedNumber(
          watchCoverage,
          null,
          `${measurementQuantityUnits}/${unitsMap
            .get(listItem.quantityUnits ?? '')
            ?.toUpperCase()}`,
        )
      : formattedNumber(
          1 / watchCoverage,
          null,
          `${unitsMap
            .get(listItem.quantityUnits ?? '')
            ?.toUpperCase()}/${measurementQuantityUnits}`,
        );

  const applyManualAdjustments = () => {
    const values = getValues();

    const attributes = {
      projectManagementListItemId: listItem.id,
      context: values.context ?? null,
      netCoveragePerSellingUnit: Number(values.netCoveragePerSellingUnit),
      wasteFactor: Number(values.wasteFactor),
    };

    createManualAdjustment({ variables: { attributes } });

    triggerApplyEvent();

    onClose();
  };

  const handleRevert = () => {
    deleteManualAdjustment({
      variables: {
        projectManagementListItemId: listItem.id,
      },
    });

    onClose();
  };
  // #endregion

  const handleClose = () => {
    reset();
    onClose();
  };

  const calculationDetailFragment = (
    <form onSubmit={handleSubmit(applyManualAdjustments)}>
      <Flex flexDir="column" padding={200}>
        <Heading size={300} mb={0}>
          Calculation Details
        </Heading>
        <Textarea
          mt={400}
          height="200px"
          resize="none"
          isInvalid={!!get(formErrors, 'context')}
          onBlur={handleContextBlur}
          ref={handleContextRef}
          {...contextFormMethods}
          data-testid="calculation-details-context-input"
        />
        {!!listItem.measurement && listItem.measurement > 0 && (
          <Flex alignItems="center" justifyContent="space-between" mt={500}>
            <Box fontSize={200} fontWeight={400} letterSpacing="1px">
              Measurements:
            </Box>
            <Box fontSize={300} fontWeight={400}>{`${formattedNumber(
              listItem.measurement,
              null,
              measurementQuantityUnits,
            )}`}</Box>
          </Flex>
        )}
        {!isNil(listItem.wasteFactor) && (
          <Flex flexDir="column">
            <Flex alignItems="center" justifyContent="space-between" mt={500}>
              <Box fontSize={200} fontWeight={400} letterSpacing="1px">
                Waste:
              </Box>
              <Flex alignItems="stretch">
                <Input
                  size="small"
                  type="number"
                  step="1"
                  textAlign="end"
                  min="0"
                  max="100"
                  width="75px"
                  borderColor="neutral.500"
                  borderRadius="6px 0px 0px 6px"
                  data-testid="calculation-details-waste-input"
                  isInvalid={!!get(formErrors, 'wasteFactor')}
                  onBlur={handleWasteFactorBlur}
                  {...wasteFactorFormMethods}
                />
                <Box
                  border="1px solid"
                  borderColor="neutral.500"
                  borderRadius="0px 6px 6px 0px"
                  borderLeft="0px"
                  alignItems="center"
                  padding={50}
                >
                  <Icon icon={iPercent} />
                </Box>
              </Flex>
            </Flex>
            <Flex
              mt={100}
              justifyContent="end"
              color="neutral.600"
              fontSize={100}
            >
              {wasteFactorDescription}
            </Flex>
          </Flex>
        )}
        {!!listItem.coverage && (
          <Flex flexDir="column" mt={200}>
            <Flex alignItems="center" justifyContent="space-between">
              <Box fontSize={200} fontWeight={400} letterSpacing="1px">
                Coverage:
              </Box>
              <Flex alignItems="stretch">
                <Controller
                  name="netCoveragePerSellingUnit"
                  control={control}
                  render={({ field }) => (
                    <NumberInput
                      defaultValue={listItem.coverage || 0}
                      min={0.001}
                      step={0.001}
                      width="75px"
                      precision={3}
                      isInvalid={!!get(formErrors, 'netCoveragePerSellingUnit')}
                      {...field}
                      onBlur={handleCoverageBlur}
                    >
                      <NumberInputField
                        textAlign="end"
                        borderColor="neutral.500"
                        borderRadius="6px 0px 0px 6px"
                        data-testid="calculation-details-coverage-input"
                        paddingInlineStart={300}
                        paddingInlineEnd={300}
                        height={32}
                      />
                    </NumberInput>
                  )}
                />
                <Box
                  border="1px solid"
                  borderColor="neutral.500"
                  borderRadius="0px 6px 6px 0px"
                  borderLeft="0px"
                  alignItems="center"
                  padding={50}
                >
                  <Icon icon={iX} />
                </Box>
              </Flex>
            </Flex>
            {!!measurementQuantityUnits && !!listItem.quantityUnits && (
              <Flex
                mt={100}
                justifyContent="end"
                color="neutral.600"
                fontSize={100}
              >
                {coverageDescription}
              </Flex>
            )}
          </Flex>
        )}
        {!!listItem.manualAdjustment && (
          <Box fontSize={200} mt={500} color="warning.500" display="block">
            {`HOVER cannot confirm the accuracy of manual updates. `}
            {/* TODO: reenable this once BE fix is up */}
            {/* <Link
              display="inline"
              color="warning.500"
              fontSize={200}
              fontWeight={400}
              textDecorationColor="warning.500"
              as="button"
              type="button"
              onClick={handleRevert}
            >
              Revert
            </Link> */}
          </Box>
        )}
        <Box display="flex" justifyContent="end" mt={500}>
          <Button fill="outline" onClick={handleClose} type="button">
            Cancel
          </Button>
          <Button
            ml={200}
            color="primary"
            data-testid="calculation-details-apply"
            // Right now, context is the only field that doesn't revert on a bad value.
            isDisabled={!isDirty || !!get(formErrors, 'context')}
            type="submit"
          >
            Update
          </Button>
        </Box>
      </Flex>
    </form>
  );

  return isMobile ? (
    <>
      <Drawer
        size="full"
        trapFocus={false}
        placement="bottom"
        isOpen={isOpen}
        onClose={handleClose}
      >
        {calculationDetailFragment}
      </Drawer>

      <Button
        border="1px solid"
        borderColor="neutral.500"
        color="neutral"
        fill="outline"
        display="flex"
        flex="0 0 28px"
        borderRadius="6px 0px 0px 6px"
        borderRight="0px"
        alignItems="center"
        padding={50}
        onClick={onToggle}
        data-testid="calculation-details-trigger"
      >
        <Icon
          icon={hCalculator}
          color={
            materialListFeatureEnabled &&
            listItem.editedStatus === EditedStatusEnum.EDITED
              ? 'warning.500'
              : undefined
          }
        />
      </Button>
    </>
  ) : (
    <Popover
      placement="top"
      onOpen={handleCalculationDetailsOpen}
      isOpen={isOpen}
      onClose={handleClose}
      isClosable
      width="350px"
      trigger={
        <Button
          border="1px solid"
          borderColor="neutral.500"
          color="neutral"
          fill="outline"
          display="flex"
          flex="0 0 28px"
          borderRadius="6px 0px 0px 6px"
          borderRight="0px"
          alignItems="center"
          padding={50}
          onClick={onToggle}
          data-testid="calculation-details-trigger"
        >
          <Icon
            icon={hCalculator}
            color={
              materialListFeatureEnabled &&
              listItem.editedStatus === EditedStatusEnum.EDITED
                ? 'warning.500'
                : undefined
            }
          />
        </Button>
      }
    >
      {calculationDetailFragment}
    </Popover>
  );
};
