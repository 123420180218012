import { useEffect } from 'react';

import { Alert, Box, Button } from '@hover/blueprint';
import { get } from 'lodash';
import { useParams } from 'react-router-dom';

import { useTracking } from 'src/hooks';
import { EventNames } from 'src/types/actionTypes';

import {
  REACT_NATIVE_EVENTS,
  triggerReactNativeEvent,
} from '../../util/nativeMobileEvents';

interface Props {
  setClose: () => void;
  isOpen: boolean;
}

export const MaterialListAlert: React.FC<Props> = ({ setClose, isOpen }) => {
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const jobId = Number(get(useParams(), 'jobId'));

  const handleClick = () => {
    setClose();

    typewriter.buttonPressed({
      page_or_screen_name:
        EventNames.project.scope.measurmentsAlertButtonPressed,
      button_text: 'Edit scope',
      primary_cta: false,
      feature: 'material-list',
      ...commonTrackingProps,
    });

    triggerReactNativeEvent(REACT_NATIVE_EVENTS.START_RECALCULATION_EVENT);

    window.location.href = `/ehi/#/estimator/questions/select_templates?jobId=${jobId}`;
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    typewriter.modalViewed({
      page_or_screen_name: EventNames.project.scope.materialListAlertViewed,
      name: 'job measurements have been reprocessed',
      feature: 'material-list',
      ...commonTrackingProps,
    });
    // Added this rule for avoiding the unnecesssary re-rendering of the component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <Alert
          width="fit-content"
          status="warning"
          position="fixed"
          bottom={900}
          left="50%"
          transform="translateX(-50%)"
          zIndex={1}
        >
          <Box display="flex" gap={400} flexDirection="row" alignItems="center">
            Measurements got updated. Click on ‘Edit scope&apos; to regenerate
            the Material List.
            <Button onClick={handleClick} fill="outline">
              Edit scope
            </Button>
          </Box>
        </Alert>
      )}
    </>
  );
};
