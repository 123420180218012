import { useEffect, useState } from 'react';

import { TemplateIdToInputs } from 'src/features/projectEstimator/types/index';
import { GraphqlClient } from 'src/lib/GraphqlClient';

import { GET_REQUIRED_INPUTS_FOR_TEMPLATE } from '../api/queries/queries';

export const useGetRequiredInputs = ({
  templateIds,
  orgId,
  jobId,
}: {
  jobId: string;
  orgId: string;
  templateIds: string[];
}) => {
  const [inputs, setInputs] = useState<TemplateIdToInputs | null>(null);
  const [error, setError] = useState<boolean>(false);

  const data: TemplateIdToInputs = {};

  useEffect(() => {
    const promises = templateIds.map((id) => {
      return GraphqlClient.query({
        query: GET_REQUIRED_INPUTS_FOR_TEMPLATE,
        variables: { templateId: id, orgId, jobId },
        fetchPolicy: 'no-cache',
      });
    });

    Promise.all<any>(promises)
      .then((results) => {
        results.forEach((result) => {
          const template = result.data?.estimationConfigTemplate;
          data[template.id] = {
            name: template.name,
            tradeType: template.tradeType,
            inputs:
              result.data?.estimationConfigRequiredInputsForTemplate || [],
          };
        });

        setInputs(data);
      })
      .catch(() => {
        setError(true);
      });
  }, []);

  return { inputs, error };
};
