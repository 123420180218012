import { GET_TRADE_TYPE_ENUM_ORGS } from 'src/api/queries/queries';
import appSettings from 'src/appSettings';
import { GraphqlClient } from 'src/lib/GraphqlClient';
import { HoverRequest } from 'src/lib/HoverRequestWrapper';
import hyperion from 'src/lib/request';
import { isDevelopment } from 'src/utils/EnvUtils';

import { GET_INSPECTION_CHECKLIST } from './queries';

export class EstimatorApi {
  // HYPERION

  // get machete measurements
  static getJobMeasurements(url: string) {
    return HoverRequest.get({
      path: url,
      baseUrl: appSettings.HYPERION_API_SERVER,
      options: { withCredentials: true },
    });
  }

  static getHDFMeasurements(jobId: number) {
    // raw HDF can be found at /api/v1/models/${modelId}/hover_data_format.json (Hyperion)
    // summarized HDF can be found at /api/v2/jobs/${jobId}/estimation_summarized_hdf.json (Note: jobID not modelID)
    // to mock out the response, uncomment the following lines, replace hdf.json with the summarized HDF

    // const hdf = require('./hdf.json');
    // return { data: hdf };

    return HoverRequest.get({
      path: `/api/v2/jobs/${jobId}/estimation_summarized_hdf.json`,
      baseUrl: appSettings.HYPERION_API_SERVER,
      options: { withCredentials: true },
    });
  }

  static getMeasurementsPlainJson(jobId: number) {
    return HoverRequest.get({
      path: `/api/v2/jobs/${jobId}/measurements.json`,
      baseUrl: appSettings.HYPERION_API_SERVER,
      options: { withCredentials: true },
    });
  }

  static getMeasurementsEstimation(jobId: number) {
    return HoverRequest.get({
      path: `/api/v2/jobs/${jobId}/measurements.json?version=estimation_json`,
      baseUrl: appSettings.HYPERION_API_SERVER,
      options: { withCredentials: true },
    });
  }

  static getModels(jobId: number) {
    return HoverRequest.get({
      path: `/api/v1/models?job_id=${jobId}`,
      baseUrl: appSettings.HYPERION_API_SERVER,
      options: { withCredentials: true },
    });
  }

  static getMacheteJSON(url: string | null) {
    if (!url) return '';
    let thisUrl = url;
    if (isDevelopment()) {
      thisUrl = thisUrl.replace('staging.hover.to', 'local.hover.to:5006');
    }

    return hyperion.get(thisUrl, {
      baseURL: appSettings.HYPERION_API_SERVER,
    });
  }

  // GRAPHQL

  static getChecklist({ jobId, orgId }: { jobId: string; orgId: string }) {
    return GraphqlClient.query({
      query: GET_INSPECTION_CHECKLIST,
      variables: { jobId, orgId },
    });
  }

  static getTradeTypeEnumOrgs(orgId: string) {
    return GraphqlClient.query({
      query: GET_TRADE_TYPE_ENUM_ORGS,
      variables: { orgId },
    });
  }
}
