import { Box, Heading, Field, Textarea } from '@hover/blueprint';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { getJobDetails } from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { jobProps } from 'src/utils/trackingUtils';

export const Note: React.FC = () => {
  const jobDetails = useSelector(getJobDetails);
  const { register } = useFormContext();

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const handleBlurNote = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    typewriter.textInput({
      input_label: 'proposal modal: text box (note)',
      input_value: e.target.value,
      page_or_screen_name:
        EventNames.estimator.estimateDetailsScreen.proposal.page,
      ...jobProps(jobDetails),
      ...commonTrackingProps,
    });
  };

  return (
    <Box
      flexDirection="column"
      marginTop={400}
      marginBottom={400}
      flexShrink={0}
      as="section"
    >
      <Heading size={200}>Advanced Options</Heading>
      <Field name="note" label="Note">
        <Textarea
          {...register('note')}
          rows={5}
          resize="vertical"
          onBlur={handleBlurNote}
          data-testid="noteTextarea"
        />
      </Field>
    </Box>
  );
};
