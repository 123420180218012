import { useCallback, useEffect, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import {
  Body,
  Box,
  Button,
  Heading,
  Icon,
  Image,
  Link,
  Loader,
} from '@hover/blueprint';
import {
  iAlertTriangle,
  iCheckCircle,
  iClock,
  iExternalLink,
} from '@hover/icons';
import { isNil } from 'lodash';
import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  DistributionDeliveryMethodEnum,
  DistributionOrderStateEnum,
} from 'src/api/graphql-global-types';
import type {
  distributionOrderConfirmation,
  distributionOrderConfirmation_distributionOrder as DistributionOrder,
} from 'src/api/types/distributionOrderConfirmation';
import { messages } from 'src/constants/messages';
import { DISTRIBUTION_ORDER_CONFIRMATION } from 'src/features/project/apis/graphql/queries/queries';
import { ProjectOrderNavbar } from 'src/features/project/components/common/Navbar';
import { ToastStatusEnum, useToastEhi, useTracking } from 'src/hooks';
import { EventNames } from 'src/types';

import { SubmittedOrderActions } from './SubmittedOrderActions';

interface Props {
  distributionOrder: DistributionOrder;
}

const OrderConfirmationSection: React.FC<Props> = ({ distributionOrder }) => {
  const { jobId } = useParams();
  const history = useHistory();
  const location = useLocation();

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const trackOrderReviewLink = useCallback(() => {
    typewriter.linkPressed({
      link_text: 'Review Order',
      page_or_screen_name: EventNames.project.orderConfirmation.page,
      primary_cta: false,
      job_id: jobId,
      ...commonTrackingProps,
    });
  }, [jobId, commonTrackingProps]);

  const navigateToProjectPage = () => {
    trackOrderReviewLink();
    const isConfirmation = location.pathname.includes('/confirmation/');
    history.push({
      pathname: `/project/${jobId}/scope`,
      search: history.location.search,
      state: { refetch: isConfirmation },
    });
  };

  const {
    shippingAddressLine1,
    shippingAddressLine2,
    shippingAddressCity,
    shippingAddressState,
    shippingAddressCountry,
    shippingAddressPostalCode,
    purchaseOrderNumber,
    distributor: { displayName, authenticationConnectionStrategy, logo },
    branch,
    jobAccount,
    state,
    externalIdentifier,
    confirmationNumber,
    createdAt,
    orderContactEmail,
    orderContactName,
    orderContactPhoneNumber,
    deliveryContactEmail,
    deliveryContactName,
    deliveryContactPhoneNumber,
    deliveryDate,
    deliveryTime,
    deliveryType,
    deliveryMethod,
  } = useMemo(() => distributionOrder, [distributionOrder]);
  const url = logo?.url;

  const deliveryAddress = useMemo(
    () =>
      [
        shippingAddressLine1,
        shippingAddressLine2,
        shippingAddressCity,
        shippingAddressState,
        shippingAddressCountry,
        shippingAddressPostalCode,
      ]
        .filter(Boolean)
        .join(', '),
    [
      shippingAddressCity,
      shippingAddressCountry,
      shippingAddressLine1,
      shippingAddressLine2,
      shippingAddressPostalCode,
      shippingAddressState,
    ],
  );

  const headerIcon = useMemo(() => {
    switch (state) {
      case DistributionOrderStateEnum.submitted:
        return (
          <Icon
            icon={iCheckCircle}
            color="success500"
            size="large"
            margin={0}
            data-testid="iCheckCircle"
          />
        );
      case DistributionOrderStateEnum.pending_submitted:
        return (
          <Icon
            icon={iClock}
            color="warning500"
            size="large"
            margin={0}
            data-testid="iClock"
          />
        );
      case DistributionOrderStateEnum.canceled:
        return (
          <Icon
            icon={iAlertTriangle}
            color="danger500"
            size="large"
            margin={0}
            data-testid="iAlertTriangle"
          />
        );
      default:
        return (
          <Icon
            icon={iCheckCircle}
            color="success500"
            size="large"
            margin={0}
            data-testid="iCheckCircle"
          />
        );
    }
  }, [state]);

  const disclaimerText = useMemo(() => {
    switch (state) {
      case DistributionOrderStateEnum.submitted:
        return messages.projectScope.orderConfirmation.messages.disclaimerText.orderConfirmationSubmitted(
          { displayName },
        );
      case DistributionOrderStateEnum.pending_submitted:
        return messages.projectScope.orderConfirmation.messages.disclaimerText.orderConfirmationPendingSubmitted(
          { displayName },
        );
      case DistributionOrderStateEnum.canceled:
        return messages.projectScope.orderConfirmation.messages.disclaimerText.orderConfirmationCanceled(
          { displayName },
        );
      default:
        return messages.projectScope.orderConfirmation.messages.disclaimerText.orderConfirmationSubmitted(
          { displayName },
        );
    }
  }, [state, displayName]);

  const disclaimerBackgroundColor = useMemo(() => {
    switch (state) {
      case DistributionOrderStateEnum.submitted:
        return 'neutral200';
      case DistributionOrderStateEnum.pending_submitted:
      case DistributionOrderStateEnum.canceled:
        return 'warning50';
      default:
        return 'neutral200';
    }
  }, [state]);

  const reviewOrderColor = useMemo(() => {
    switch (state) {
      case DistributionOrderStateEnum.submitted:
        return 'primary600';
      case DistributionOrderStateEnum.canceled:
        return 'neutral600';
      default:
        return 'primary600';
    }
  }, [state]);

  /**
   * Display depends on the delivery method:
   * Branch pick up: <method>, <date>, <time>
   * Deliver to address: <method>, <date>, <time>, <type>
   * On hold: <method>, <type>
   * If any field is null/undefined, it is ignored.
   * Enum values are formatted so that first letter is capitalized (e.g. Capitalized words)
   */
  const requestedDeliveryText = useMemo(() => {
    if (!deliveryMethod) {
      return '';
    }
    let str = `${
      deliveryMethod[0].toUpperCase() +
      deliveryMethod.slice(1).replaceAll('_', ' ').toLowerCase()
    }`;

    switch (deliveryMethod) {
      case DistributionDeliveryMethodEnum.BRANCH_PICK_UP:
      case DistributionDeliveryMethodEnum.DELIVER_TO_ADDRESS:
        if (deliveryDate) {
          str += `, ${moment(deliveryDate).format('l')}`;
        }
        if (deliveryTime) {
          str += `, ${
            deliveryTime[0].toUpperCase() +
            deliveryTime.slice(1).replaceAll('_', ' ').toLowerCase()
          }`;
        }
        break;
      default:
        break;
    }

    switch (deliveryMethod) {
      case DistributionDeliveryMethodEnum.DELIVER_TO_ADDRESS:
      case DistributionDeliveryMethodEnum.ON_HOLD:
        if (deliveryType) {
          str += `, ${
            deliveryType[0].toUpperCase() +
            deliveryType.slice(1).replaceAll('_', ' ').toLowerCase()
          }`;
        }
        break;
      default:
        break;
    }

    return str;
  }, [deliveryMethod, deliveryDate, deliveryTime, deliveryType]);

  return (
    <Box justifyContent="center" testId="orderConfirmation">
      <Box flexDirection="row" flex=".8" margin={800}>
        <Box flexDirection="column" flex="1" marginRight={600}>
          <Box mb={500}>
            <Box flex="1" mr={400}>
              {headerIcon}
              <Heading
                data-testid="displayName-text"
                size={500}
                mx={0}
                ml={300}
              >
                {messages.projectScope.orderConfirmation.messages.heading({
                  displayName,
                })}
              </Heading>
            </Box>
            <Box ml={400} flex="1">
              <Image src={url} alt={`${displayName} logo`} height={400} />
            </Box>
          </Box>
          <Box>
            <Box flexDirection="column" mr={400} flex="1">
              <Box flexDirection="column" testId="poNumber-text" mb={400}>
                <Box color="neutral600" mb={200} fontSize={200}>
                  PO Number
                </Box>
                <Box>{purchaseOrderNumber}</Box>
              </Box>
              {branch?.name && (
                <Box
                  mt={300}
                  flexDirection="column"
                  data-testid="branchName-text"
                  mb={400}
                >
                  <Box color="neutral600" mb={200} fontSize={200}>
                    Branch
                  </Box>
                  <Box>{branch?.name}</Box>
                </Box>
              )}
              {jobAccount?.displayName && (
                <Box
                  mt={300}
                  mb={400}
                  flexDirection="column"
                  data-testid="jobAccountName-text"
                >
                  <Box color="neutral600" mb={200} fontSize={200}>
                    Job Account
                  </Box>
                  <Box>{`${jobAccount?.id}-${jobAccount?.displayName}`}</Box>
                </Box>
              )}
              <Box
                mt={300}
                flexDirection="column"
                data-testid="address-text"
                mb={400}
              >
                <Box color="neutral600" mb={200} fontSize={200}>
                  Delivery address
                </Box>
                <Box testId="orderSummaryAddress">{deliveryAddress}</Box>
              </Box>
              <Box
                mt={300}
                flexDirection="column"
                data-testid="address-text"
                mb={400}
              >
                {deliveryMethod ? (
                  <>
                    <Box color="neutral600" mb={200} fontSize={200}>
                      Requested Delivery
                    </Box>
                    <Box>{requestedDeliveryText}</Box>
                  </>
                ) : null}
              </Box>

              {distributionOrder?.notes &&
              distributionOrder?.notes?.length > 0 ? (
                <Box
                  mt={300}
                  flexDirection="column"
                  data-testid="notes-container"
                  mb={400}
                >
                  <Box color="neutral600" mb={200} fontSize={200}>
                    Order Instructions
                  </Box>
                  <Box testId="notes-text">{distributionOrder.notes}</Box>
                </Box>
              ) : null}
            </Box>
            <Box flexDirection="column" ml={400} flex="1">
              <Box
                flexDirection="column"
                data-testid="orderSubmitted-text"
                mb={400}
              >
                <Box color="neutral600" mt={600} mb={200} fontSize={200}>
                  Order Submitted
                </Box>
                {confirmationNumber ? (
                  <Box mb={100}>Confirmation No.: {confirmationNumber}</Box>
                ) : null}
                <Box mb={100}>
                  Order ID.: {externalIdentifier || 'Waiting for confirmation'}
                </Box>
                <Box>{moment(createdAt).format('l LT')}</Box>
              </Box>
              <Box flexDirection="column" mb={400}>
                <Box color="neutral600" mt={300} mb={200} fontSize={200}>
                  Submitter Contact
                </Box>
                <Box mb={100}>{orderContactName || ''}</Box>
                <Box mb={100}>{orderContactEmail || ''}</Box>
                <Box>{orderContactPhoneNumber || ''}</Box>
              </Box>
              {!isNil(deliveryContactName) ? (
                <Box flexDirection="column" mt={300} mb={400}>
                  <Box color="neutral600" mb={200} fontSize={200}>
                    Additional Contact
                  </Box>
                  {orderContactEmail === deliveryContactEmail ? (
                    <Box color="neutral600">Same as submitter</Box>
                  ) : (
                    <>
                      {deliveryContactName ? (
                        <Box mb={100}>{deliveryContactName}</Box>
                      ) : null}
                      {deliveryContactEmail ? (
                        <Box mb={100}>{deliveryContactEmail}</Box>
                      ) : null}
                      {deliveryContactPhoneNumber ? (
                        <Box>{deliveryContactPhoneNumber}</Box>
                      ) : null}
                    </>
                  )}
                </Box>
              ) : null}
            </Box>
          </Box>
          <Box
            flexDir="column"
            padding={500}
            mb={600}
            mt={400}
            backgroundColor={disclaimerBackgroundColor}
            color="neutral600"
            data-testid="disclaimer-text"
          >
            <Body size={400} margin={0} color="neutral550">
              {disclaimerText}
            </Body>
            {state === DistributionOrderStateEnum.submitted && (
              <SubmittedOrderActions
                distributionOrder={distributionOrder}
                jobId={jobId}
                reviewOrderColor={reviewOrderColor}
              />
            )}
            {state === DistributionOrderStateEnum.pending_submitted ? (
              <Box
                mt={300}
                color="warning500"
                lineHeight={300}
                fontWeight={700}
              >
                <Icon icon={iClock} size="small" />
                {`Waiting for ${displayName} to confirm order`}
              </Box>
            ) : null}
            {state === DistributionOrderStateEnum.canceled ? (
              <Box mt={300} color="neutral600">
                {!isNil(distributionOrder?.url) ? (
                  <Link
                    target="_blank"
                    href={distributionOrder.url}
                    lineHeight={300}
                    color={reviewOrderColor}
                    display="flex"
                  >
                    <Icon
                      icon={iExternalLink}
                      size="small"
                      color="neutral600"
                    />
                    Review Order
                  </Link>
                ) : null}
              </Box>
            ) : null}
          </Box>
          <Box>
            <Button onClick={navigateToProjectPage}>
              Return to Project Scope
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const enum TOAST_IDS {
  DISTRIBUTION_ORDER_CONFIRMATION_ERROR_TOAST,
}

export const OrderConfirm = () => {
  const { orderId, jobId } = useParams();

  const toast = useToastEhi();
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const { data, loading } = useQuery<distributionOrderConfirmation>(
    DISTRIBUTION_ORDER_CONFIRMATION,
    {
      onError: () => {
        toast({
          id: TOAST_IDS.DISTRIBUTION_ORDER_CONFIRMATION_ERROR_TOAST,

          description:
            messages.projectScope.errors.query.productionList
              .getDistributionOrder,
          status: ToastStatusEnum.ERROR,
        });
      },
      variables: { id: orderId },
    },
  );

  const location = useLocation();
  const isOrderReviewPage = location.pathname.includes('/order/');

  const distributionOrder = data?.distributionOrder;

  // Segment tracking.
  useEffect(() => {
    typewriter.pageViewed({
      page_or_screen_name: EventNames.project.orderConfirmation.page,
      job_id: Number(jobId),
      ...commonTrackingProps,
    });
  }, [jobId, commonTrackingProps]);

  return loading || !distributionOrder ? (
    <Loader
      data-testid="ProjectScopeSpinner"
      size="large"
      display="flex"
      position="absolute"
      justifyContent="center"
      alignItems="center"
      width="100vw"
      height="100vh"
      top="0"
      right="0"
      left="0"
      bottom="0"
    />
  ) : (
    <Box flexDirection="column" testId="orderConfirm">
      {!isOrderReviewPage && <ProjectOrderNavbar />}
      <OrderConfirmationSection distributionOrder={distributionOrder} />
    </Box>
  );
};
