import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { Box } from '@hover/blueprint';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { estimationEstimate_estimationEstimate_lineItems as LineItem } from 'src/api/types/estimationEstimate';
import { LoaderSpinner } from 'src/components/LoaderSpinner';
import { ESTIMATION_ESTIMATE } from 'src/features/exteriorEstimator/apis/queries';
import { EstimatorResponsiveWrapper } from 'src/features/exteriorEstimator/components/common/EstimatorResponsiveWrapper';
import { VariationSelectionFooter } from 'src/features/exteriorEstimator/components/VariationSelection/Footer';
import { Variations } from 'src/features/exteriorEstimator/components/VariationSelection/Variations';
import {
  getLineItemsWithVariationsForVariations,
  lineItemComparator,
} from 'src/features/exteriorEstimator/utils/estimateGroupUtils';
import { useEffectOnMount, useTracking } from 'src/hooks';
import { useSearchParams } from 'src/hooks/useSearchParams';
import { getVariationsFilter } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

import { VariationsHeader } from './VariationsHeader';

export const VariationSelection: React.FC = () => {
  const { estimateId, estimateGroupId } = useParams();
  const { searchParams } = useSearchParams();
  const jobId = searchParams.get('jobId');
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const variationsFilter = useSelector(getVariationsFilter);

  const { data, loading } = useQuery(ESTIMATION_ESTIMATE, {
    variables: { id: estimateId, ...variationsFilter },
  });

  useEffectOnMount(() => {
    typewriter.pageViewed({
      page_or_screen_name: EventNames.estimator.colorSelection,
      job_id: Number(jobId),
      ...commonTrackingProps,
    });
  });

  const estimate = data?.estimationEstimate;
  const lineItemsWithVariations = useMemo(
    () =>
      getLineItemsWithVariationsForVariations(estimate)?.sort(
        lineItemComparator,
      ),
    [estimate],
  );

  return (
    <Box flexDirection="column" height="100vh">
      <LoaderSpinner show={loading} />
      <EstimatorResponsiveWrapper>
        <Box width={1} flexDirection="column">
          <VariationsHeader
            estimateName={estimate?.name ?? ''}
            estimateGroupId={estimateGroupId}
          />
          <Box
            flexDirection="column"
            data-testid="variation-groups-container"
            paddingY={500}
          >
            {lineItemsWithVariations?.map((lineItem: LineItem) => (
              <Box key={lineItem.id} flexDirection="column">
                <Variations key={lineItem.id} lineItem={lineItem} />
              </Box>
            ))}
          </Box>
        </Box>
      </EstimatorResponsiveWrapper>
      <VariationSelectionFooter
        lineItemsWithVariations={lineItemsWithVariations}
      />
    </Box>
  );
};
