import { useCallback } from 'react';

import { Box, Button, Heading, Icon } from '@hover/blueprint';
import { iChevronRight, iFileText } from '@hover/icons';
import { connect } from 'react-redux';

import { useProposals } from 'src/features/exteriorEstimator/hooks/useProposals';
import {
  getParams,
  getEstimateGroupIdFromLocation,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { ProposalViews } from 'src/features/exteriorEstimator/types';
import { useEffectOnMount, useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { RootState } from 'src/types/reduxStore';

export const mapStateToProps = (state: RootState) => ({
  proposals: state.exteriorEstimator.proposalsData.proposals,
  estimateGroupId: getEstimateGroupIdFromLocation(state),
  jobId: getParams(state).jobId,
  commonProps: getUserTrackingProps(state),
});

type OwnProps = {
  toggleProposalModalView: (view: ProposalViews) => void;
};

type Props = ReturnType<typeof mapStateToProps> & OwnProps;

export const EstimateProposalsButtonComponent: React.FC<Props> = ({
  proposals,
  toggleProposalModalView,
  estimateGroupId,
  jobId,
  commonProps,
}) => {
  const isDisabled = !proposals.length;
  const [fetchProposals] = useProposals();
  const { useTypewriter } = useTracking();
  const typewriter = useTypewriter();

  useEffectOnMount(() => {
    fetchProposals(estimateGroupId);
  });

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      toggleProposalModalView(ProposalViews.LIST);
      typewriter.buttonPressed({
        button_text: `Estimate Proposals for ${estimateGroupId}`,
        primary_cta: false,
        page_or_screen_name: EventNames.estimator.estimateDetailsScreen.page,
        job_id: Number(jobId),
        ...commonProps,
      });
    },
    [estimateGroupId, jobId, toggleProposalModalView, commonProps],
  );

  return (
    <Box display="inline-block" width="100%">
      <Box
        flex={1}
        data-testid="EstimateProposalsButton"
        sx={
          isDisabled
            ? { backgroundColor: '#efefef' }
            : { boxShadow: '0 0 4px rgba(0, 0, 0, 0.15)' }
        }
      >
        <Button
          flex={1}
          fill="minimal"
          shape="box"
          size="large"
          onClick={handleClick}
          isDisabled={isDisabled}
          data-testid="EstimateProposalsButton-Button"
          label="EstimateProposalsButton-Button"
        >
          <Box flex={1} justifyContent="space-between" alignItems="center">
            <Box justifyContent="flex-start" alignItems="center">
              <Icon
                icon={iFileText}
                color={isDisabled ? 'neutral500' : 'primary500'}
                margin={0}
                marginRight={200}
              />
              <Heading
                size={200}
                marginBottom={0}
                color={isDisabled ? 'neutral500' : 'primary800'}
                data-testid="EstimateProposalsButton-Heading"
              >
                {isDisabled
                  ? 'No proposal generated'
                  : `Proposal (${proposals.length})`}
              </Heading>
            </Box>
            {!isDisabled && (
              <Icon
                icon={iChevronRight}
                color="neutral500"
                margin={0}
                marginRight={200}
              />
            )}
          </Box>
        </Button>
      </Box>
    </Box>
  );
};

export const EstimateProposalsButton = connect(mapStateToProps)(
  EstimateProposalsButtonComponent,
);
