import { Body, Flex, Link } from '@hover/blueprint';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';

import type { projectManagementProductionList_projectManagementProductionList_estimateGroup as EstimateGroup } from 'src/api/types/projectManagementProductionList';
import {
  LinkBody,
  SectionHeading,
} from 'src/features/project/components/ProjectScope/ProjectDetails/components';
import { useTracking } from 'src/hooks';
import { COMMERCE_PROJECT_ESTIMATOR, isEnabled } from 'src/lib/FeatureFlag';
import {
  getTradeTypesSorted,
  getMaterialListFeature,
} from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { sentenceCase } from 'src/utils/Formatters';

export interface MobileProjectBoxProps {
  estimateGroup: EstimateGroup;
}

export function MobileProjectBox({ estimateGroup }: MobileProjectBoxProps) {
  const materialListFeatureEnabled = useSelector(getMaterialListFeature);
  const tradeTypesSorted = useSelector(getTradeTypesSorted);
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const isCommerceProjectEstimatorEnabled = isEnabled(
    COMMERCE_PROJECT_ESTIMATOR,
  );
  const newSummaryUrl = `/project_estimator/estimates/${estimateGroup?.id}/summary?jobId=${estimateGroup?.salesOpportunity?.job?.id}`;
  const oldSummaryUrl = `/estimator/estimates/${estimateGroup?.id}/summary?jobId=${estimateGroup?.salesOpportunity?.job?.id}`;

  const templates = estimateGroup?.estimates
    .filter((estimate) => estimate.active)
    .map((estimate) => estimate.template);

  const date = moment(estimateGroup?.createdAt).format('M/D/YYYY');

  return (
    <Flex
      flexDirection="column"
      borderRadius={12}
      backgroundColor="white"
      padding="19px 16px 16px"
      width="175px"
      flex={1}
    >
      <SectionHeading
        color="neutral.600"
        marginBottom={300}
        textTransform="uppercase"
        fontSize="200"
      >
        Scope
      </SectionHeading>
      <Flex flexDirection="column" data-testid="templates">
        {tradeTypesSorted?.map((tradeType) => {
          const templateOfTradeType = templates?.find(
            (template) => template?.tradeType === tradeType.tradeTypeEnumValue,
          );
          return templateOfTradeType ? (
            <Flex
              flexDirection="column"
              marginBottom={200}
              key={tradeType.tradeTypeEnumValue}
            >
              <Body
                key={templateOfTradeType?.tradeType}
                size={500}
                margin={0}
                fontSize="200"
              >
                {sentenceCase(templateOfTradeType?.tradeType ?? '')}:
              </Body>
              <Body
                key={templateOfTradeType?.name}
                size={500}
                margin={0}
                fontSize="200"
              >
                {templateOfTradeType?.name ?? ''}
              </Body>
            </Flex>
          ) : null;
        })}
      </Flex>

      <Body
        data-testid="projectCreateInfo"
        fontSize="100"
        mt={0}
        mb={400}
        color="neutral.600"
      >
        {date}
        <br />
        {estimateGroup?.user?.name}
      </Body>

      {materialListFeatureEnabled && (
        <Link
          data-testid="materialList-estimateSummary-link"
          as={RouterLink}
          to={isCommerceProjectEstimatorEnabled ? newSummaryUrl : oldSummaryUrl}
          target="_blank"
          onClick={() => {
            typewriter.linkPressed({
              page_or_screen_name: EventNames.project.scope.page,
              primary_cta: false,
              link_text: 'Scope Summary',
              ...commonTrackingProps,
            });
          }}
          fontSize="300"
        >
          Scope Summary
        </Link>
      )}
    </Flex>
  );
}
