import { Body, Box, Button, NextProvider } from '@hover/blueprint';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import type { projectManagementProductionList_projectManagementProductionList as ProjectManagementProductionList } from 'src/api/types/projectManagementProductionList';
import {
  ProjectDetailsPanel,
  SectionHeading,
} from 'src/features/project/components/ProjectScope/ProjectDetails/components';
import { useToastEhi, useTracking } from 'src/hooks';
import { isEnabled, COMMERCE_PROJECT_ESTIMATOR } from 'src/lib/FeatureFlag';
import { getMaterialListFeature, getUserOrgId } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

interface Props {
  productionList: ProjectManagementProductionList;
}

export const ProjectEstimatorProject: React.FC<Props> = ({
  productionList,
}) => {
  const orgId = useSelector(getUserOrgId);

  const { jobId } = useParams();
  const { estimateGroup } = productionList;
  const materialListFeatureEnabled = useSelector(getMaterialListFeature);
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const isCommerceProjectEstimatorEnabled = isEnabled(
    COMMERCE_PROJECT_ESTIMATOR,
  );
  const newSummaryUrl = `/project_estimator/estimates/${estimateGroup?.id}/summary?jobId=${estimateGroup?.salesOpportunity?.job?.id}`;
  const oldSummaryUrl = `/estimator/estimates/${estimateGroup?.id}/summary?jobId=${estimateGroup?.salesOpportunity?.job?.id}`;

  const date = new Date(estimateGroup?.createdAt)
    .toString()
    .split(' ')
    .slice(1, 4)
    .join(' ');

  const handleClickButton = () => {
    // TODO: Update this path after creating new estimates summary page
    window.location.href = isCommerceProjectEstimatorEnabled
      ? newSummaryUrl
      : oldSummaryUrl;

    typewriter.linkPressed({
      page_or_screen_name: EventNames.project.scope.page,
      primary_cta: false,
      link_text: 'Scope Summary',
      ...commonTrackingProps,
    });
  };

  const templates = estimateGroup?.estimates
    .filter((estimate) => estimate.active)
    .map((estimate) => estimate.template);

  const tradesText = `${
    Array.from(new Set(templates?.map((template) => template?.tradeType)))
      .length
  } trades`;

  const templatesText = `${templates?.length} templates`;

  return (
    <NextProvider attach fontsHost="/typography/">
      <ProjectDetailsPanel data-testid="projectSection" padding={600}>
        <SectionHeading marginBottom={400}>Project details</SectionHeading>

        <Box flexDirection="row" flex="1">
          <Box
            flex="0 0 auto"
            alignItems="start"
            padding={0}
            margin={0}
            flexDirection="column"
          >
            <Body
              data-testid="projectCreateInfo"
              size={500}
              marginTop={0}
              marginBottom={0}
              color="neutral.600"
            >
              Created {date}
            </Body>
            <Body
              size={500}
              marginTop={0}
              marginBottom={400}
              color="neutral.600"
            >
              {estimateGroup?.user?.name}, {estimateGroup?.org?.name}
            </Body>
            <Body
              size={500}
              marginTop={0}
              marginBottom={0}
              fontWeight={700}
              color="neutral.900"
            >
              {tradesText}
            </Body>
            <Body
              size={500}
              marginTop={0}
              marginBottom={500}
              fontWeight={700}
              color="neutral.900"
            >
              {templatesText}
            </Body>
            {materialListFeatureEnabled && (
              <Button
                data-testid="materialList-estimateSummary-link"
                onClick={handleClickButton}
                variant="tertiary"
                size="small"
                fontWeight={700}
              >
                Summary
              </Button>
            )}
          </Box>
          <Box flex="1">{/* TODO: Add here 3d static photo of model */}</Box>
        </Box>
      </ProjectDetailsPanel>
    </NextProvider>
  );
};
