import { Body, Box, Link } from '@hover/blueprint';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { LineItemTypeEnum } from 'src/api/graphql-global-types';
import {
  getParams,
  getSubmittedOrders,
} from 'src/features/projectManagement/redux/selectors';
import { VendorType } from 'src/features/projectManagement/types';
import { RootState } from 'src/types/reduxStore';

export const mapStateToProps = (state: RootState) => ({
  jobId: getParams(state).jobId,
  orgId: getParams(state).orgId,
  getOrders: (vendorName: string) => getSubmittedOrders(state, vendorName),
});

interface ParentProps {
  vendor?: VendorType;
  type: LineItemTypeEnum;
}

type Props = ParentProps & ReturnType<typeof mapStateToProps>;

export const OrderLinkComponent: React.FC<Props> = ({
  vendor,
  type,
  getOrders,
  jobId,
  orgId,
}) => {
  const distributor = vendor?.distributor;
  const identifier = distributor?.identifier ?? '';
  const firstSubmittedOrder = !!vendor ? getOrders(identifier)[0] : null;

  const shouldRender = () =>
    distributor?.supportsProductOrdering &&
    type === LineItemTypeEnum.MATERIAL &&
    !!firstSubmittedOrder;

  return shouldRender() ? (
    <Box margin={0} mr={500} alignItems="center">
      <Link
        as={RouterLink}
        to={`/estimator_production/order/summary?jobId=${jobId}&orderId=${firstSubmittedOrder.id}&orgId=${orgId}`}
        display="flex"
      >
        <Box
          display="flex"
          alignItems="center"
          data-testid="submittedOrder-textLink"
        >
          <Body margin={0} mr={200} color="neutral600" size={400}>
            Submitted {firstSubmittedOrder.distributor.displayName} order
          </Body>
          <Body margin={0} size={400} color="primary600">
            (PO:{firstSubmittedOrder.purchaseOrderNumber})
          </Body>
        </Box>
      </Link>
    </Box>
  ) : null;
};

export const OrderLink = connect(mapStateToProps)(OrderLinkComponent);
