import React, { useEffect, useState, forwardRef, KeyboardEvent } from 'react';

import { Box, Input, Select, Tooltip } from '@hover/blueprint';
import { get } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';

import type {
  projectManagementProductionList_projectManagementProductionList_listItems as ListItem,
  projectManagementProductionList_projectManagementProductionList_listItems_product as Product,
} from 'src/api/types/projectManagementProductionList';

import { useProjectScopeTracker } from '../../../hooks/useProjectScopeTracker';

const CUSTOM_VARIANT = 'Custom variant';

export const CUSTOM_VARIANT_COLOR = {
  name: CUSTOM_VARIANT,
  id: CUSTOM_VARIANT,
};

type VariationSelectProps = {
  listItem: ListItem;
  isUpdating: boolean;
  jobId: number;
  onSubmit: () => any;
};

type Color = {
  id: string;
  name: string;
};

export const VariationSelect: React.FC<VariationSelectProps> = forwardRef<
  HTMLDivElement,
  VariationSelectProps
>(({ listItem, isUpdating, jobId, onSubmit }, ref) => {
  const {
    control,
    register,
    trigger,
    setValue,
    getValues,
    formState: { errors: formErrors },
  } = useFormContext();
  const formExternalVariationId = getValues().externalVariationId;
  const formColor = getValues().color;
  const userSetCustom = getValues().userSetCustomColor;

  const [colorOptions, setColorOptions] = useState<Color[]>([]);

  const renderCustomVariationField = userSetCustom;
  const showWarningBorder =
    (formColor === null && !userSetCustom) || formColor === '';

  const { trackInlineEditingInputPressed } = useProjectScopeTracker({
    jobId,
  });

  const handleTracking = () => {
    trackInlineEditingInputPressed('Variation Name');
  };

  const checkErrorsAndSubmit = () => {
    if (get(formErrors, 'color')) {
      return;
    }
    (document.activeElement as HTMLElement).blur();
    onSubmit();
  };

  const setCustomVariation = (
    onChange: (value: string) => void,
    newValue: string,
  ) => {
    onChange(newValue);
    setValue('userSetCustomColor', true);
    setValue('color', null, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const setAndSubmitNormalVariation = (
    onChange: (value: string) => void,
    newValue: string,
  ) => {
    const product: Product = getValues('product') || {};
    const variation = product.variations?.find(
      (suggestionVariation) => suggestionVariation.id === newValue,
    );
    if (!variation) {
      return;
    }
    onChange(newValue);
    setValue('userSetCustomColor', false);
    setValue('color', variation.name, {
      shouldValidate: true,
      shouldDirty: true,
    });
    checkErrorsAndSubmit();
  };

  const handleVariationDropdownChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    onChange: (value: string) => void,
  ) => {
    const { value } = e.target;

    if (value === CUSTOM_VARIANT_COLOR.name) {
      setCustomVariation(onChange, value);
    } else {
      setAndSubmitNormalVariation(onChange, value);
    }
  };

  const handleCustomVariationInputBlur = (
    e: React.FocusEvent<HTMLInputElement>,
  ) => {
    const { value } = e.target;
    // early return if already updating, or if value is already current color
    if (isUpdating || value === listItem.color) {
      return;
    }
    checkErrorsAndSubmit();
  };

  const handleCustomVariationInputChange = (
    e: React.FocusEvent<HTMLInputElement>,
    onChange: (value: string) => void,
  ) => {
    const { value } = e.target;

    setValue('requiresProductVariationSelection', false);
    setValue('userSetCustomColor', true);
    setValue('color', value, {
      shouldValidate: true,
      shouldDirty: true,
    });
    onChange(value);
  };

  const handleCustomVariationInputKeyUp = (
    e: KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (e.key.toLowerCase() !== 'enter') {
      return;
    }

    checkErrorsAndSubmit();
  };

  // Populate the primary dropdown-select with options from the product's variations
  useEffect(() => {
    const variations = get(listItem, 'product.variations') || [];
    setColorOptions([...variations, CUSTOM_VARIANT_COLOR]);
  }, [listItem, listItem.product?.variations?.length]);

  // Pre-select primary dropdown-select with custom variation option, if user has already set it
  // this will, down the line, trigger the secondary custom variation name field to be rendered
  useEffect(() => {
    if (userSetCustom) {
      setValue('externalVariationId', CUSTOM_VARIANT_COLOR.name);
    }
  }, [colorOptions.length, userSetCustom, setValue]);

  // When primary dropdown-select changes to the custom variant selection
  // and, if the  secondary custom variation is empty, focus the secondary field
  useEffect(() => {
    if (
      formExternalVariationId === CUSTOM_VARIANT_COLOR.name &&
      formColor === null
    ) {
      trigger('color', { shouldFocus: true });
    }
  }, [listItem, formExternalVariationId, trigger]);

  return (
    <Box as="span" flexDirection="column" ref={ref}>
      <Controller
        control={control}
        name="externalVariationId"
        render={({
          field: { onChange: onControllerChange, value: variationIdValue },
        }) => (
          <Select
            data-testid="EditLineItemColor"
            fontSize="inherit"
            onChange={(e) => {
              handleTracking();
              handleVariationDropdownChange(e, onControllerChange);
            }}
            size="small"
            borderColor={showWarningBorder ? 'warning.500' : 'neutral.500'}
            borderRadius="6px"
            value={variationIdValue}
          >
            <option key={null} value="">
              Select variant
            </option>
            {colorOptions.map((colorOption) => {
              return (
                <option key={colorOption.id} value={colorOption.id}>
                  {colorOption.name}
                </option>
              );
            })}
          </Select>
        )}
      />

      {renderCustomVariationField && (
        <Controller
          control={control}
          name="color"
          render={({ field: { onChange: onControllerChange, value } }) => {
            return (
              <Tooltip
                label={get(formErrors, 'color.message')}
                placement="bottom"
                background="danger300"
              >
                <Input
                  {...register('color', {
                    required: 'Custom variant is required',
                  })}
                  isInvalid={!!get(formErrors, 'color')}
                  size="small"
                  borderColor="neutral.500"
                  marginTop={1}
                  data-testid="AddMaterial-customVariant"
                  placeholder="Enter custom variant name"
                  onKeyUp={handleCustomVariationInputKeyUp}
                  onBlur={handleCustomVariationInputBlur}
                  onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                    handleTracking();
                    handleCustomVariationInputChange(e, onControllerChange);
                  }}
                  defaultValue={value}
                />
              </Tooltip>
            );
          }}
        />
      )}
    </Box>
  );
});
