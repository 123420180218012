import { Heading as BlueprintHeading, Box } from '@hover/blueprint';

interface Props {
  'data-testid'?: string;
}

export const Heading: React.FC<Props> = (props) => {
  const { children, 'data-testid': dataTestId } = props;
  return (
    <Box testId={dataTestId} width={1} justifyContent="left">
      <BlueprintHeading
        textTransform="capitalize"
        color="primary900"
        size={500}
        marginBottom={300}
      >
        {children}
      </BlueprintHeading>
    </Box>
  );
};
