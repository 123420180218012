import { PureComponent } from 'react';

import { Link, Box } from '@hover/blueprint';
import autobind from 'autobind-decorator';
import { get as lodashGet } from 'lodash';
import { connect } from 'react-redux';

import appSettings from 'src/appSettings';
import { withTypewriter } from 'src/components/WithTypewriter';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { RootState } from 'src/types/reduxStore';
import { jobProps } from 'src/utils/trackingUtils';

import MeasurementPitches from './MeasurementPitches';
import MeasurementUnit from './MeasurementUnit';
import {
  MeasurementDetailsContainer,
  MeasurementDetailsHeader,
  Table,
  TableHeader,
  Row,
  TableData,
} from './ProductionSummaryStyled';

const MEASUREMENTS_TABLE = [
  {
    name: 'Roof Facets',
    path: 'roof.roof_facets.total',
    measurementPath: 'roof.roof_facets.area',
    measurementUnit: 'sqft',
    emptyValueTestId: 'facetsEmptyValue',
  },
  {
    name: 'Ridges/Hips',
    path: 'roof.ridges_hips.total',
    measurementPath: 'roof.ridges_hips.length',
    measurementUnit: 'lf',
    emptyValueTestId: 'ridgesEmptyValue',
  },
  {
    name: 'Valleys',
    path: 'roof.valleys.total',
    measurementPath: 'roof.valleys.length',
    measurementUnit: 'lf',
    emptyValueTestId: 'valleysEmptyValue',
  },
  {
    name: 'Rakes',
    path: 'roof.rakes.total',
    measurementPath: 'roof.rakes.length',
    measurementUnit: 'lf',
    emptyValueTestId: 'rakesEmptyValue',
  },
  {
    name: 'Gutters/Eaves',
    path: 'roof.gutters_eaves.total',
    measurementPath: 'roof.gutters_eaves.length',
    measurementUnit: 'lf',
    emptyValueTestId: 'guttersEmptyValue',
  },
  {
    name: 'Flashing',
    path: 'roof.flashing.total',
    measurementPath: 'roof.flashing.length',
    measurementUnit: 'lf',
    emptyValueTestId: 'flashingEmptyValue',
  },
  {
    name: 'Step Flashing',
    path: 'roof.step_flashing.total',
    measurementPath: 'roof.step_flashing.length',
    measurementUnit: 'lf',
    emptyValueTestId: 'stepFlashingEmptyValue',
  },
];

export const mapStateToProps = (state: RootState) => ({
  jobDetails: state.estimatorProductionTools.jobDetails,
  jobMeasurements: state.estimatorProductionTools.jobMeasurements,
  commonProps: getUserTrackingProps(state),
});

type MeasurementDetailsProps = ReturnType<typeof mapStateToProps> & {
  typewriter: any;
};

export class MeasurementDetailsComponent extends PureComponent<MeasurementDetailsProps> {
  @autobind
  private handleClick() {
    const { jobDetails, commonProps, typewriter } = this.props;

    typewriter.buttonPressed({
      button_text: 'Measurements',
      page_or_screen_name: EventNames.pmp.page,
      primary_cta: false,
      ...commonProps,
      ...jobProps(jobDetails),
    });
  }

  public render() {
    const { jobDetails: job, jobMeasurements } = this.props;
    return (
      <MeasurementDetailsContainer data-testid="measurement-details-container">
        <MeasurementDetailsHeader>
          Property Measurements
        </MeasurementDetailsHeader>
        <Table>
          <tbody>
            <Row>
              <TableHeader>Roof</TableHeader>
              <TableHeader>Total</TableHeader>
              <TableHeader>Measurement</TableHeader>
            </Row>
            {MEASUREMENTS_TABLE.map((measurement) => (
              <Row key={measurement.name}>
                <TableData>{measurement.name}</TableData>
                <TableData>
                  {lodashGet(jobMeasurements, measurement.path, '')}
                </TableData>
                {jobMeasurements ? (
                  <TableData data-testid="measurement-unit">
                    {lodashGet(
                      jobMeasurements,
                      measurement.measurementPath,
                      '',
                    )}
                    <MeasurementUnit unit={measurement.measurementUnit} />
                  </TableData>
                ) : (
                  <TableData data-testid={measurement.emptyValueTestId}>
                    --
                  </TableData>
                )}
              </Row>
            ))}
            {jobMeasurements && (
              <MeasurementPitches
                pitches={lodashGet(jobMeasurements, 'roof.pitch')}
              />
            )}
          </tbody>
        </Table>
        <Box pl={400}>
          <Link
            size={200}
            data-testid="measurements-link"
            href={`${appSettings.HYPERION_API_SERVER}/api/v2/jobs/${job?.id}/measurements.pdf`}
            target="_blank"
            onClick={this.handleClick}
          >
            VIEW MEASUREMENTS
          </Link>
        </Box>
      </MeasurementDetailsContainer>
    );
  }
}

export const MeasurementDetails = connect(mapStateToProps)(
  withTypewriter(MeasurementDetailsComponent),
);
