import { PureComponent } from 'react';

import { connect } from 'react-redux';

import { FormattedNumber } from 'src/components/FormattedNumber';
import { isUserLightWeightFlow } from 'src/redux/selectors';
import { RootState } from 'src/types/reduxStore';

import { Container, Group, Header, Row, Cell } from './ProjectFinancialsStyled';

export const mapStateToProps = (state: RootState) => ({
  estimateDetails: state.estimatorProductionTools.estimateDetails,
  productionList: state.estimatorProductionTools.productionList,
  isUserLightWeightFlow: isUserLightWeightFlow(state),
});

type ProjectFinancialsProps = ReturnType<typeof mapStateToProps>;

export class ProjectFinancialsComponent extends PureComponent<ProjectFinancialsProps> {
  render() {
    const {
      estimateDetails: estimateGroup,
      productionList,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      isUserLightWeightFlow,
    } = this.props;

    if (isUserLightWeightFlow) return null;

    const totalDiscountAmount = productionList.totalDiscount;

    const discounts = [];
    estimateGroup?.estimates.forEach((estimate) => {
      if (estimate.discounts) discounts.push(...estimate.discounts);
    });

    return (
      <Container data-testid="projectFinancials-container">
        <Group>
          <Header>Project Financials</Header>
          <Row data-testid="pmpEstimateRow">
            <Cell>Estimated Price</Cell>
            <Cell data-testid="pmpEstimateTotal">
              <FormattedNumber
                value={productionList.contractPreDiscountPrice}
                format="$0,0.00"
              />
            </Cell>
          </Row>
          <Row data-testid="pmpPromotionRow">
            <Cell data-testid="pmpPromotionLabel">
              Promotions({discounts.length})
            </Cell>
            <Cell data-testid="pmpPromotion">
              {totalDiscountAmount > 0 && '- '}
              <FormattedNumber
                data-testid="pmpPromotionTotal"
                value={productionList.totalDiscount || 0}
                format="$0,0.00"
              />
            </Cell>
          </Row>
          <Row data-testid="pmpContractPriceRow">
            <Cell bold>Contract Price</Cell>
            <Cell bold data-testid="pmpContractPrice">
              <FormattedNumber
                value={productionList.contractTotalPrice}
                format="$0,0.00"
              />
            </Cell>
          </Row>
        </Group>
        <Group>
          <Row>
            <Cell bold data-testid="profitMargin">
              Profit Margin (
              <FormattedNumber
                value={productionList.profitMargin / 100.0}
                format="00.00%"
              />
              )
            </Cell>
            <Cell bold data-testid="profitAmount">
              <FormattedNumber
                value={productionList.profitAmount}
                format="$0,0.00"
              />
            </Cell>
          </Row>
        </Group>
        <Group>
          <Row>
            <Cell>Material Total</Cell>
            <Cell data-testid="detailsMaterialTotal">
              <FormattedNumber
                value={productionList.materialTotalCost}
                format="$0,0.00"
              />
            </Cell>
          </Row>
          <Row>
            <Cell>Labor Total</Cell>
            <Cell data-testid="detailsLaborTotal">
              <FormattedNumber
                value={productionList.laborTotalCost}
                format="$0,0.00"
              />
            </Cell>
          </Row>
          <Row>
            <Cell>Other Total</Cell>
            <Cell data-testid="detailsOtherTotal">
              <FormattedNumber
                value={productionList.otherTotalCost}
                format="$0,0.00"
              />
            </Cell>
          </Row>
          <Row>
            <Cell bold>Total Job Cost</Cell>
            <Cell bold data-testid="detailsTotalCost">
              <FormattedNumber
                value={productionList.totalCost}
                format="$0,0.00"
              />
            </Cell>
          </Row>
        </Group>
      </Container>
    );
  }
}

export const ProjectFinancials = connect(mapStateToProps)(
  ProjectFinancialsComponent,
);
