import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Box } from '@hover/blueprint';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { EstimationEstimateGroupStateEnum } from 'src/api/graphql-global-types';
import { GET_ESTIMATE_GROUP } from 'src/features/exteriorEstimator/apis/queries'; // TODO use one that does not need lineItems
import { EstimateGroupBottomBar } from 'src/features/exteriorEstimator/components/common/EstimateGroupBottomBar';
import { EstimatorResponsiveWrapper } from 'src/features/exteriorEstimator/components/common/EstimatorResponsiveWrapper';
import { EstimateGroupDetailName } from 'src/features/exteriorEstimator/components/EstimationTool/EstimateGroupDetail/EstimateGroupDetailName';
import { EstimateSectionsByTemplateGroup } from 'src/features/exteriorEstimator/components/EstimationTool/EstimateGroupDetail/EstimateSections/EstimateSectionsByTemplateGroup';
import { DetailFooter } from 'src/features/exteriorEstimator/components/EstimationTool/EstimateGroupDetail/Footer/DetailFooter';
import { MissingVariationsWarning } from 'src/features/exteriorEstimator/components/EstimationTool/EstimateGroupDetail/MissingVariationsWarning/MissingVariationsWarning';
import { Navbar } from 'src/features/exteriorEstimator/components/EstimationTool/EstimateGroupDetail/Navbar/NavBar';
import { Overview } from 'src/features/exteriorEstimator/components/EstimationTool/EstimateGroupDetail/Overview/Overview';
import { Promotion } from 'src/features/exteriorEstimator/components/EstimationTool/EstimateGroupDetail/Promotion/Promotion';
import { ProposalModals } from 'src/features/exteriorEstimator/components/EstimationTool/Proposal/ProposalModals';
import { estimatorActions } from 'src/features/exteriorEstimator/redux/actions';
import {
  getAreSelectedVariationsMissing,
  getEstimateGroup,
  getEstimateGroupIdFromLocation,
  getEstimateGroupTotalPrice,
  getHasNoActiveEstimate,
  getParams,
} from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { ProposalViews } from 'src/features/exteriorEstimator/types';
import { useEffectOnMount, useTracking } from 'src/hooks';
import {
  getOrderingOnlyFeatureAccess,
  getOrgSettings,
  getVariationsFilter,
} from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

export const EstimateGroupDetail = () => {
  const areVariationsMissing = useSelector(getAreSelectedVariationsMissing);
  const estimateGroupId = useSelector(getEstimateGroupIdFromLocation);
  const estimateGroupTotalPrice = useSelector(getEstimateGroupTotalPrice);
  const hasNoActiveEstimate = useSelector(getHasNoActiveEstimate);

  const urlParams = useSelector(getParams);
  const showOrderingFlow = useSelector(getOrgSettings)?.showOrderingFlow;
  const showOrderingVersion =
    useSelector(getOrderingOnlyFeatureAccess) && showOrderingFlow;

  const { jobId } = urlParams ?? {};
  const { search } = useLocation();
  const variationsFilter = useSelector(getVariationsFilter);
  const dispatch = useDispatch();
  const estimateGroup = useSelector(getEstimateGroup);
  const history = useHistory();
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const { data, error } = useQuery(GET_ESTIMATE_GROUP, {
    variables: {
      id: estimateGroupId,
      ...variationsFilter,
    },
  });

  useEffect(() => {
    const { state } = data?.estimationEstimateGroup ?? {};

    if (state === EstimationEstimateGroupStateEnum.COMPLETE) {
      dispatch(
        estimatorActions.receiveEstimateGroup(data.estimationEstimateGroup),
      );
    }
    if (error || state === EstimationEstimateGroupStateEnum.FAILED) {
      // do something
    }
  }, [data, dispatch, error, estimateGroupId]);

  useEffect(() => {
    if (estimateGroup && hasNoActiveEstimate)
      history.push({
        pathname: `/estimator/estimates/${estimateGroupId}`,
        search,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimateGroup, hasNoActiveEstimate]);

  const [shouldShowEstimatePrices, setShouldShowEstimatePrices] =
    useState(false);

  const [proposalModalView, setProposalModalView] =
    useState<ProposalViews | null>(null);

  useEffectOnMount(() => {
    if (urlParams.showProposalModal) {
      setProposalModalView(ProposalViews.LIST);
    }

    typewriter.pageViewed({
      page_or_screen_name: EventNames.estimator.estimateDetailsScreen.page,
      job_id: Number(jobId),
      ...commonTrackingProps,
    });
  });

  useEffect(() => {
    if (!proposalModalView && !!urlParams?.showProposalModal) {
      // re-open modal if it was closed.
      setProposalModalView(ProposalViews.LIST);
    }
  }, [urlParams?.showProposalModal, proposalModalView]);

  const toggleProposalModalView = (newView: ProposalViews) => {
    if (proposalModalView) setProposalModalView(null);
    else setProposalModalView(newView);
  };

  const handleClickEstimatePriceToggle = () => {
    typewriter.buttonPressed({
      button_text: 'Estimate Price Toggle',
      primary_cta: false,
      page_or_screen_name: EventNames.estimator.estimateDetailsScreen.page,
      job_id: Number(jobId),
      ...commonTrackingProps,
    });
    setShouldShowEstimatePrices(!shouldShowEstimatePrices);
  };

  const hideTotalPrice = estimateGroup?.estimates
    ?.map((e) => e.template)
    .some((template) => !template?.showPricesInEstimation); // do any templates in this group have showPricesByTemplate, if not, hide the total price

  return (
    <Box flex={1} flexDirection="column" testId="estimateDetailWrapper">
      <Navbar />
      <Box
        flex={1}
        width={1}
        overflowY="auto"
        sx={{
          '&::-webkit-scrollbar': {
            display: 'none',
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
          },
        }}
      >
        <EstimatorResponsiveWrapper>
          <Box flexDirection="column" width={1}>
            <Overview
              total={estimateGroupTotalPrice || 0}
              shouldShowEstimatePrices={shouldShowEstimatePrices}
              onClickEstimatePriceToggle={handleClickEstimatePriceToggle}
              toggleProposalModalView={toggleProposalModalView}
              hideTotalPrice={!!hideTotalPrice}
            />
            <EstimateGroupDetailName />
            <Promotion total={estimateGroupTotalPrice || 0} />
            <EstimateSectionsByTemplateGroup
              shouldShowEstimatePrices={shouldShowEstimatePrices}
            />
          </Box>
        </EstimatorResponsiveWrapper>
      </Box>
      {areVariationsMissing && !showOrderingVersion && (
        <MissingVariationsWarning />
      )}
      <EstimateGroupBottomBar>
        <DetailFooter setProposalModalView={setProposalModalView} />
      </EstimateGroupBottomBar>
      {proposalModalView !== null && (
        <ProposalModals
          setProposalModalView={setProposalModalView}
          modalView={proposalModalView}
          hideTotalPrice={!!hideTotalPrice}
        />
      )}
    </Box>
  );
};
