import { gql } from '@apollo/client';

import { estimationEstimateFields, JOB_DETAILS } from 'src/api/queries/queries';

export { JOB_DETAILS };

export const productionListBaseFields = gql`
  fragment ProductionListBaseFields on ProductionList {
    id
    contractPreDiscountPrice
    contractTotalCost
    contractTotalPrice
    materialPretaxCost
    materialSalesTax
    materialTotalCost
    laborTotalCost
    otherTotalCost
    profitAmount
    profitMargin
    totalCost
    totalPrice
    totalDiscount
    materialTotal
    laborTotal
    otherTotal
  }
`;

export const distributorCapabilities = gql`
  fragment distributorCapabilities on ProductCatalogDistributorCapabilities {
    supportsDeliveryMethod
    supportsDeliveryDate
    supportsDeliveryTime
    supportsDeliveryType
    deliveryMethods {
      id
      label
      sortOrder
      deliveryMethod
    }
    deliveryTimes {
      id
      label
      sortOrder
      deliveryTime
    }
    deliveryTypes {
      id
      label
      sortOrder
      deliveryType
    }
    requiresOrderJobAccount
  }
`;

export const vendorBaseFields = gql`
  fragment VendorBaseFields on Vendor {
    id
    vendorName
    accountId
    branch {
      id
    }
    distributorId
    distributor {
      capabilities {
        ...distributorCapabilities
      }
      supportsShowingOrderCheckResults
      id
      identifier
      supportsProductCatalog
      supportsProductOrdering
      url
      logo {
        redirectUrl
      }
      displayName
      websiteDisplayName
    }
  }
  ${distributorCapabilities}
`;

export const listItemBaseFields = gql`
  fragment ListItemBaseFields on ListItem {
    id
    type
    name
    measurement
    wasteFactor
    quantity
    quantityUnits
    calculatedQuantity
    unitCost
    taxPercent
    pretaxCost
    isManualMeasurement
    externalProductId
    externalVariationId
    skuChangedByUser
    userSetCustomColor
    userSetCustomSku
    userSetCustomUnitCost
    sortOrder
    notes
    color
    createdAt
    tradeType
    sku
    vendor {
      ...VendorBaseFields
    }
  }
  ${vendorBaseFields}
`;

export const unitsQuery = gql`
  fragment UnitsQuery on ListItem {
    measurementUnits
    quantityUnits
  }
`;

export const vendorQuery = gql`
  fragment VendorQuery on Query {
    vendors(orgId: $orgId) {
      ...VendorBaseFields
    }
  }
  ${vendorBaseFields}
`;

export const GET_SALES_OPPORTUNITIES = gql`
  query getSalesOpportunities(
    $orgId: ID!
    $jobId: ID
    $active: Boolean = true
  ) {
    salesOpportunities(orgId: $orgId, jobId: $jobId) {
      id
      soldEstimateGroup {
        ...estimationEstimateFields
      }
      productionList {
        ...ProductionListBaseFields
        listItems {
          ...ListItemBaseFields
          ...UnitsQuery
          tradeType
        }
        orders {
          id
          distributionOrderId
          distributionOrder {
            id
            state
            purchaseOrderNumber
            distributor {
              capabilities {
                ...distributorCapabilities
              }
              displayName
              supportsShowingOrderCheckResults
              identifier
            }
          }
          vendor {
            vendorName
          }
        }
      }
    }
    ...VendorQuery
  }
  ${estimationEstimateFields}
  ${productionListBaseFields}
  ${listItemBaseFields}
  ${unitsQuery}
  ${vendorQuery}
  ${distributorCapabilities}
`;

export const UPDATE_LIST_ITEM = gql`
  mutation projectManagementListItemUpdateOld(
    $listItemId: Int!
    $listItemAttributes: ListItemUpdate!
    $preventAutoMatch: Boolean
  ) {
    projectManagementListItemUpdate(
      listItemId: $listItemId
      listItemAttributes: $listItemAttributes
      preventAutoMatch: $preventAutoMatch
    ) {
      listItem {
        ...ListItemBaseFields
        ...UnitsQuery
        productionList {
          ...ProductionListBaseFields
        }
      }
      errors {
        attribute
        errors
      }
    }
  }
  ${listItemBaseFields}
  ${unitsQuery}
  ${productionListBaseFields}
  ${distributorCapabilities}
`;

export const CREATE_LIST_ITEM = gql`
  mutation projectManagementListItemCreateOld(
    $productionListId: Int!
    $listItemAttributes: ListItemCreate!
  ) {
    projectManagementListItemCreate(
      productionListId: $productionListId
      listItemAttributes: $listItemAttributes
    ) {
      listItem {
        ...ListItemBaseFields
        productionList {
          ...ProductionListBaseFields
        }
      }
      errors {
        attribute
        errors
      }
    }
  }
  ${listItemBaseFields}
  ${productionListBaseFields}
`;

export const ORDER_DOCUMENT_CREATE = gql`
  mutation projectManagementOrderDocumentCreate(
    $orderDocumentAttributes: OrderDocumentCreate!
    $externalIdentifier: String
    $listItemIds: [ID!]
  ) {
    projectManagementOrderDocumentCreate(
      orderDocumentAttributes: $orderDocumentAttributes
      externalIdentifier: $externalIdentifier
      listItemIds: $listItemIds
    ) {
      orderDocument {
        id
        state
        pdf {
          url
          filename
          contentType
          byteSize
          checksum
        }
      }
    }
  }
`;

export const PROJECT_MANAGEMENT_ORDER_DOCUMENTS = gql`
  query projectManagementOrderDocuments(
    $productionListId: ID!
    $orderDocumentIds: [ID!]
  ) {
    projectManagementOrderDocuments(
      productionListId: $productionListId
      orderDocumentIds: $orderDocumentIds
    ) {
      id
      createdAt
      state
      stateDeprecated
      order {
        purchaseOrderNumber
        vendor {
          vendorName
        }
      }
      pdf {
        url
        filename
        contentType
        byteSize
        checksum
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  query getProducts(
    $productIds: [ID!]
    $distributorIds: [ID!]
    $filterVariationsByOrg: Boolean
    $orgId: ID!
    $after: String
  ) {
    productCatalogProducts(
      productIds: $productIds
      distributorIds: $distributorIds
      orgId: $orgId
      after: $after
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          provider {
            id
            name
          }
          name
          variations(orgId: $orgId, filterByOrg: $filterVariationsByOrg) {
            id
            name
            externalVariationIdentifiers(kind: DISTRIBUTOR_JSON) {
              sku
              kind
              distributorId
              displayName
              id
            }
          }
        }
      }
    }
  }
`;

export const PRODUCT_SEARCH_BY_DISTRIBUTOR_V2 = gql`
  query productsSearch(
    $searchTerm: String!
    $orgId: ID!
    $distributorIds: [ID!]
    $distributionBranchIds: [ID!]
    $after: String
    $id: ID!
  ) {
    productCatalogProductsSearch(
      orgId: $orgId
      searchTerm: $searchTerm
      distributorIds: $distributorIds
      distributionBranchIds: $distributionBranchIds
      after: $after
      first: 20
    ) {
      nodes {
        id
        name
        variations(orgId: $orgId) {
          id
          name
        }
      }
      edges {
        node {
          id
          name
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    productCatalogDistributor(id: $id) {
      name
      logo {
        redirectUrl
      }
    }
  }
`;

export const PRODUCT_CATALOG_DISTRIBUTOR_VARIATION_ATTRIBUTES = gql`
  query productCatalogDistributorVariationAttributes(
    $distributorId: ID!
    $accountId: String!
    $distributionBranchId: ID
    $lineItemAttributes: DistributionVariationAttributesInput!
    $orgId: ID!
  ) {
    productCatalogDistributorVariationAttributes(
      distributorId: $distributorId
      accountId: $accountId
      lineItemAttributes: $lineItemAttributes
      distributionBranchId: $distributionBranchId
      orgId: $orgId
    ) {
      sku
      unitCost
    }
  }
`;

export const PRODUCT_CATALOG_DISTRIBUTOR_VARIATIONS_ATTRIBUTES = gql`
  query productCatalogDistributorVariationsAttributes(
    $distributorId: ID!
    $accountId: String
    $distributionBranchId: ID
    $lineItemsAttributes: [DistributionVariationAttributesInput!]!
    $orgId: ID!
  ) {
    productCatalogDistributorVariationsAttributes(
      distributorId: $distributorId
      accountId: $accountId
      lineItemsAttributes: $lineItemsAttributes
      distributionBranchId: $distributionBranchId
      orgId: $orgId
    ) {
      sku
      unitCost
      clientIdentifier
    }
  }
`;

export const GET_BRANCHES_FOR_DISTRIBUTOR = gql`
  query distributionApprovedBranches($distributorId: ID!, $orgId: ID!) {
    distributionApprovedBranches(distributorId: $distributorId, orgId: $orgId) {
      id
      name
      orgId
      jobAccounts {
        id
        displayName
      }
      defaultJobAccount {
        id
        displayName
      }
    }
  }
`;
