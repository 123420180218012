import { isNil } from 'lodash';

import { tradeTypes_tradeTypes as TradeType } from 'src/api/types/tradeTypes';
import {
  SELECT_ROOF_FACETS_URL,
  SELECT_SIDING_FACETS_URL,
  WASTE_FACTOR_URL,
  ROOF_FACET_SELECTION_SCREEN,
  SIDING_FACET_SELECTION_SCREEN,
  GENERIC_PAGE_URL,
  MEASUREMENT_SCREEN,
} from 'src/features/projectEstimator/constants/routesAndPages';
import { getTemplatesWithInputsFilteredByCategory } from 'src/features/projectEstimator/hooks/utils/selectors';
import {
  InputWithValue,
  Page,
  TemplatesState,
  TemplateStore,
} from 'src/features/projectEstimator/types';
import {
  isRoofTemplate,
  isSidingTemplate,
} from 'src/features/projectEstimator/utils/tradeTypes';
import { standardizeInputCategoryName } from 'src/utils/Formatters';

const addUrlsToPages = (pages: Page[]) => {
  return pages.map((page) => {
    if (page.name === ROOF_FACET_SELECTION_SCREEN) {
      return {
        ...page,
        url: SELECT_ROOF_FACETS_URL,
      };
    }
    if (page.name === SIDING_FACET_SELECTION_SCREEN) {
      return {
        ...page,
        url: SELECT_SIDING_FACETS_URL,
      };
    }
    if (page.name === 'WASTE_FACTOR') {
      return {
        ...page,
        url: WASTE_FACTOR_URL,
      };
    }
    return {
      ...page,
      url: `${GENERIC_PAGE_URL}${page.name.toLowerCase()}`,
    };
  });
};

export const areAllInputsAnswered = (inputs: InputWithValue[]) => {
  return inputs.every((input) => !isNil(input.value));
};

export const areAllMeasurementInputsAnswered = (
  measurementPage: Page,
  templates: TemplatesState,
) => {
  const measurementInputs = Object.values(
    getTemplatesWithInputsFilteredByCategory(measurementPage.name, templates),
  )
    .map((template) => template.inputs)
    .flat();

  return areAllInputsAnswered(measurementInputs);
};

export const createPagesFromTemplates = ({
  templates,
  tradeTypesSorted,
  isRecalculateFlow,
}: {
  templates: TemplatesState;
  tradeTypesSorted: TradeType[];
  isRecalculateFlow: boolean;
}) => {
  let newPages: Page[] = [];
  let inputCategories: { name: string; sortOrder: number }[] = [];

  newPages = Object.values(templates).reduce<TemplateStore['pages']>(
    (acc, template) => {
      template.inputs.forEach((input) => {
        if (!input?.inputCategory?.name) return;

        if (
          !acc.find(
            (page) =>
              page.name ===
              standardizeInputCategoryName(input?.inputCategory?.name ?? ''),
          )
        ) {
          acc.push({
            name: standardizeInputCategoryName(input.inputCategory.name),
          });
          inputCategories.push({
            name: standardizeInputCategoryName(input.inputCategory.name),
            sortOrder: input.inputCategory?.sortOrder ?? 0,
          });
        }
      });
      return acc;
    },
    [],
  );

  if (!isRecalculateFlow) {
    // filter out everything but measurements
    newPages = newPages.filter((page) => page.name === MEASUREMENT_SCREEN);
    inputCategories = inputCategories.filter(
      (category) => category.name === MEASUREMENT_SCREEN,
    );
  }

  // sort newPages by the inputCategory.sortOrder property
  // gather all the input categories and sort them by sortOrder
  inputCategories.sort((a, b) => a.sortOrder - b.sortOrder);

  // then sort the newPages array by the sortOrder of the input categories
  newPages.sort((a, b) => {
    const aSortOrder = inputCategories.find(
      (category) => category.name === a.name,
    )?.sortOrder;
    const bSortOrder = inputCategories.find(
      (category) => category.name === b.name,
    )?.sortOrder;
    return (aSortOrder ?? 0) - (bSortOrder ?? 0);
  });

  // manually add siding and roofing pages to the beginning of the newPages array
  // NOTE: the order of these two if statements is important, since we want roofing to be first
  if (
    Object.values(templates).some((template) =>
      isSidingTemplate(template, tradeTypesSorted),
    )
  ) {
    newPages.unshift({
      name: SIDING_FACET_SELECTION_SCREEN,
    });
  }

  if (
    Object.values(templates).some((template) =>
      isRoofTemplate(template, tradeTypesSorted),
    )
  ) {
    newPages.unshift({
      name: ROOF_FACET_SELECTION_SCREEN,
    });
  }

  const measurementPage = newPages.find(
    (page) => page.name === MEASUREMENT_SCREEN,
  );
  if (measurementPage) {
    // remove measurement page
    newPages = newPages.filter((page) => page.name !== MEASUREMENT_SCREEN);
    if (!areAllMeasurementInputsAnswered(measurementPage, templates)) {
      // make it the first page if not all measurement inputs have values
      newPages.unshift(measurementPage);
    }
  }

  return addUrlsToPages(newPages);
};
