import * as Sentry from '@sentry/react';

import appSettings from 'src/appSettings';

interface InitialScope {
  user: {
    id: string;
    username?: string;
    email: string;
    orgId: number | undefined;
  };
}

export const initializeSentry = (initialScope: InitialScope) => {
  Sentry.init({
    dsn: appSettings.SENTRY_DSN,
    initialScope,
    release: appSettings.GIT_SHA,
    environment: appSettings.NODE_ENV,
    normalizeDepth: 6,
    denyUrls: [
      /localhost:5006\/ehi/i,
      /local\.hover\.to:5006\/ehi/i,
      /api\.segment\.io/i,
      /bam-cell\.nr-data\.net/i,
      /cdn\.cookielaw\.org/i,
      /(privacyportal|geolocation)\.onetrust\.com/i,
      /(sdk|events)\.split\.io/i,
    ],
    ignoreErrors: ['ChunkLoadError'],
    beforeSend: (event) => {
      const isNewRelicChunkErrorForSmokeTestUser =
        event.user?.email === 'mikeb+ehiprod+test@hoverinc.com' &&
        event.message?.match(/Loading chunk [0-9]+ failed/);
      if (
        appSettings.NODE_ENV.toLowerCase() === 'test' ||
        isNewRelicChunkErrorForSmokeTestUser
      ) {
        return null;
      }
      return event;
    },
  });
};
