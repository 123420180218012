import { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Field,
  Heading,
  Panel,
  Form,
  LoadingOverlay,
} from '@hover/blueprint';
import {
  useForm,
  Controller,
  FormProvider,
  FieldValues,
} from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { TradeTypeEnum } from 'src/api/graphql-global-types';
import { projectManagementConfigOrgProposalSettingSelfService_projectManagementConfigOrgProposalSetting as OrgProposalSettings } from 'src/api/types/projectManagementConfigOrgProposalSettingSelfService';
import { projectManagementConfigProposalTemplatePageCreate as ProjectManagementConfigProposalTemplatePageCreateResp } from 'src/api/types/projectManagementConfigProposalTemplatePageCreate';
import { projectManagementConfigProposalTemplatePageUpdate as ProjectManagementConfigProposalTemplatePageUpdateResp } from 'src/api/types/projectManagementConfigProposalTemplatePageUpdate';
import { WysiwygEditor } from 'src/components/WysiwygEditor';
import {
  CREATE_PROPOSAL_PAGE,
  GET_PROJECT_MANAGEMENT_CONFIG_PROPOSAL_TEMPLATE,
  UPDATE_PROPOSAL_PAGE,
} from 'src/features/settings/api/queries/proposals';
import { CancelModal } from 'src/features/settings/components/Proposal/Section/CancelModal';
import { SectionHeaderPanel } from 'src/features/settings/components/Proposal/Section/SectionHeaderPanel';
import { generateSignatureMenuItems } from 'src/features/settings/utils/proposals';
import { useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

type Props = {
  tradeOptions: ReadonlyArray<{ value: string; displayValue: string }>;
  showOrgLogo: boolean;
  showTitle: boolean;
  title: string | null | undefined;
  content: string | null | undefined;
  tradeType: TradeTypeEnum;
  viewMode: 'VIEW_EDIT' | 'CREATE';
  orgId: string;
  setSuccessNotification: () => void;
  setFailureNotification: () => void;
  navigateToProposalList: () => void;
  projectManagementConfigOrgProposalSetting: OrgProposalSettings;
};

export const Section: React.FC<Props> = ({
  tradeOptions,
  showOrgLogo,
  showTitle,
  title = '',
  content = '',
  tradeType,
  viewMode,
  orgId,
  setSuccessNotification,
  setFailureNotification,
  navigateToProposalList,
  projectManagementConfigOrgProposalSetting: { maxSigneeCount },
}) => {
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const {
    getValues,
    formState,
    formState: { errors, isDirty, isValid },
    control,
    handleSubmit,
    setValue,
    ...methods
  } = useForm({
    defaultValues: {
      sectionTitle: viewMode === 'CREATE' ? '' : title,
      appliesTo: tradeType,
      showBrandingOnHeader: showOrgLogo,
      showSectionTitle: showTitle,
      editorContent: content,
    },
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const { sectionId } = useParams();

  const onError = () => {
    setFailureNotification();
  };

  const onCompletedCreate = ({
    projectManagementConfigProposalTemplatePageCreate,
  }: ProjectManagementConfigProposalTemplatePageCreateResp) => {
    if (
      projectManagementConfigProposalTemplatePageCreate?.errors?.length &&
      projectManagementConfigProposalTemplatePageCreate.errors.length > 0
    ) {
      setFailureNotification();
    } else {
      setSuccessNotification();
      navigateToProposalList();
    }
  };

  const onCompletedUpdate = ({
    projectManagementConfigProposalTemplatePageUpdate,
  }: ProjectManagementConfigProposalTemplatePageUpdateResp) => {
    if (
      projectManagementConfigProposalTemplatePageUpdate?.errors?.length &&
      projectManagementConfigProposalTemplatePageUpdate.errors.length > 0
    ) {
      setFailureNotification();
    } else {
      setSuccessNotification();
      navigateToProposalList();
    }
  };

  const [createTemplatePage, { loading: loadingCreate }] = useMutation(
    CREATE_PROPOSAL_PAGE,
    {
      onCompleted: onCompletedCreate,
      onError,
    },
  );

  const [updateTemplatePage, { loading: loadingUpdate }] = useMutation(
    UPDATE_PROPOSAL_PAGE,
    {
      onCompleted: onCompletedUpdate,
      onError,
    },
  );

  useEffect(() => {
    typewriter.pageViewed({
      page_or_screen_name: EventNames.settings.section.page,
      ...commonTrackingProps,
    });
  }, [commonTrackingProps]);

  const onSubmit = (data: FieldValues) => {
    const id = viewMode === 'CREATE' ? { orgId } : { id: sectionId };
    const options = {
      variables: {
        ...id,
        proposalTemplatePageAttributes: {
          content: data.editorContent,
          showOrgLogo: data.showBrandingOnHeader,
          showTitle: data.showSectionTitle,
          title: data.sectionTitle,
          tradeType: data.appliesTo,
        },
      },
      refetchQueries: [
        {
          query: GET_PROJECT_MANAGEMENT_CONFIG_PROPOSAL_TEMPLATE,
          variables: { orgId },
        },
      ],
    };

    if (viewMode === 'CREATE') {
      createTemplatePage(options);
    } else {
      updateTemplatePage(options);
    }
    typewriter.buttonPressed({
      button_text: 'Save',
      page_or_screen_name: EventNames.settings.section.page,
      primary_cta: true,
      ...commonTrackingProps,
    });
  };

  const handleChangeContent = (newContent: string) => {
    setValue('editorContent', newContent, {
      shouldDirty: true,
    });
  };

  const handleCancelPressed = () => {
    typewriter.buttonPressed({
      button_text: 'Cancel',
      page_or_screen_name: EventNames.settings.section.page,
      primary_cta: false,
      ...commonTrackingProps,
    });
  };
  const [shouldShowCancelModal, setShouldShowCancelModal] = useState(false);
  const openCancelModal = () => {
    setShouldShowCancelModal(true);
    handleCancelPressed();
  };
  const closeCancelModal = () => setShouldShowCancelModal(false);
  const cancel = () => {
    return isDirty ? openCancelModal() : navigateToProposalList();
  };

  return (
    <>
      <LoadingOverlay isLoading={loadingCreate || loadingUpdate} />
      <CancelModal
        navigateToProposalList={navigateToProposalList}
        shouldShowCancelModal={shouldShowCancelModal}
        closeCancelModal={closeCancelModal}
      />
      <FormProvider
        {...{
          getValues,
          formState,
          control,
          handleSubmit,
          setValue,
          ...methods,
        }}
      >
        <Form
          width={1}
          flexDirection="column"
          alignItems="stretch"
          maxWidth={793}
          onSubmit={handleSubmit(onSubmit, (er) => console.log(er))}
        >
          <Heading data-testid="proposalAddSectionHeading" my={500} size={650}>
            {viewMode === 'CREATE' ? 'Add Section' : title}
          </Heading>
          <Box flexDirection="column" overflowY="auto" maxHeight="70vh">
            <SectionHeaderPanel
              tradeOptions={tradeOptions}
              page={EventNames.settings.section.page}
            />
            <Heading
              data-testid="proposalSectionContentHeading"
              size={400}
              marginBottom={400}
            >
              Content
            </Heading>
            <Panel
              boxShadow="distance500"
              marginBottom={600}
              paddingX={600}
              paddingBottom={600}
            >
              <Field
                error={errors?.editorContent?.message}
                name="editorContent"
                label="Add in text or images to include in your organization’s Proposal."
              >
                <Controller
                  name="editorContent"
                  control={control}
                  render={() => (
                    <div
                      onClickCapture={(event) => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        if (event.target?.innerText === 'Signature') {
                          typewriter.buttonPressed({
                            page_or_screen_name:
                              EventNames.settings.proposal.editSectionInput,
                            button_text: 'Signature',
                            primary_cta: false,
                            ...commonTrackingProps,
                          });
                        }
                      }}
                    >
                      <WysiwygEditor
                        initialValue={content ?? undefined}
                        onEditorChange={handleChangeContent}
                        onDirty={(e) => {
                          handleChangeContent(e.target.getContent());
                        }}
                        orgId={orgId}
                        additionalPlugins={['noneditable']}
                        additionalToolbarItems={[['signatureToolbarButton']]}
                        setup={(editor) => {
                          editor.ui.registry.addMenuButton(
                            'signatureToolbarButton',
                            {
                              text: 'Signature',
                              fetch(cb) {
                                const items = generateSignatureMenuItems(
                                  maxSigneeCount,
                                ).map(({ label, value }) => ({
                                  type: 'menuitem',
                                  text: label,
                                  onAction() {
                                    editor.insertContent(value);
                                    typewriter.optionSelected({
                                      option_type: 'list',
                                      selection: label,
                                      page_or_screen_name:
                                        EventNames.settings.proposal
                                          .editSectionInput,
                                      primary_cta: false,
                                      options: 'Signature Menu Items',
                                      ...commonTrackingProps,
                                    });
                                  },
                                }));
                                // TODO: Fix this the next time the file is edited.
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                cb(items as any); // there is a bug in the tiny types for this
                              },
                            },
                          );
                        }}
                      />
                    </div>
                  )}
                />
              </Field>
            </Panel>
          </Box>
          <Box justifyContent="space-between" marginTop={300}>
            <Box>
              <Button
                data-testid="saveButton"
                isDisabled={!isDirty || !isValid}
                marginRight={400}
                type="submit"
              >
                Save
              </Button>
              <Button
                onClick={cancel}
                type="button"
                fill="outline"
                data-testid="proposalCancelButton"
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Form>
      </FormProvider>
    </>
  );
};
