import { useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import {
  Body,
  Box,
  Button,
  Heading,
  Icon,
  IconButton,
  Link,
  Loader,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Toggle,
  Tooltip,
  Tr,
} from '@hover/blueprint';
import { iEdit, iInfo, iPlus, iTrash2 } from '@hover/icons';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { productCatalogMatchGroupDelete as ProductCatalogMatchGroupDeleteType } from 'src/api/types/productCatalogMatchGroupDelete';
import { productCatalogMatchGroups_productCatalogMatchGroups_nodes as MatchGroup } from 'src/api/types/productCatalogMatchGroups';
import { productCatalogMatchGroupUpdate as ProductCatalogMatchGroupUpdateType } from 'src/api/types/productCatalogMatchGroupUpdate';
import { PageDirection, Pagination } from 'src/components/blueprint/Pagination';
import {
  MATCH_GROUPS,
  MATCH_GROUP_DELETE,
  MATCH_GROUP_UPDATE,
} from 'src/features/settings/api/queries/calculationRules';
import { ColorGroupingRulesEmpty } from 'src/features/settings/components/ColorGroupingRules/ColorGroupingRulesEmpty';
import { ConfirmDeleteModal } from 'src/features/settings/components/common/ConfirmDeleteModal';
import { useProductCatalogCategories } from 'src/features/settings/hooks/useCategories';
import { getCategories } from 'src/features/settings/redux/selectors';
import {
  ToastStatusEnum,
  useEffectOnMount,
  useToastEhi,
  useTracking,
} from 'src/hooks';
import { usePagination } from 'src/hooks/usePagination';
import { useSearchParams } from 'src/hooks/useSearchParams';
import { getUserOrgId } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

export const ColorGroupingRulesList: React.FC = () => {
  const toast = useToastEhi();
  const history = useHistory();
  const orgId = useSelector(getUserOrgId);
  const categories = useSelector(getCategories);
  const { fetchProductCatalogCategories } = useProductCatalogCategories();
  const { updateParams, searchParams } = useSearchParams();
  const { onPaginate, paginationQueryParams } = usePagination();
  const [selectedMatchGroup, setSelectedMatchGroup] =
    useState<MatchGroup | null>(null);

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  useEffectOnMount(() => {
    typewriter.pageViewed({
      page_or_screen_name: EventNames.settings.colorGrouping.page,
      ...commonTrackingProps,
    });
  });

  useEffect(() => {
    if (!categories) fetchProductCatalogCategories(orgId);
  }, [orgId, fetchProductCatalogCategories, categories]);

  const navigateToCreate = () => {
    typewriter.buttonPressed({
      page_or_screen_name: EventNames.settings.colorGrouping.page,
      button_text: 'Create Rule',
      primary_cta: false,
      ...commonTrackingProps,
    });
    history.push(`/workflows/color_grouping_rules/create`);
  };

  const after = searchParams.get('after');
  const before = searchParams.get('before');

  const { data, fetchMore } = useQuery(MATCH_GROUPS, {
    fetchPolicy: 'no-cache',
    variables: {
      orgId,
      ...paginationQueryParams({ after, before }),
    },
  });

  const onMatchGroupError = (type: 'updating' | 'deleting') => {
    toast({
      id: 'on-match-group-error-toast',
      description: `Error ${type} rule. Try again`,
      status: ToastStatusEnum.ERROR,
    });
  };

  const onMatchGroupDeleteComplete = (
    deleteData: ProductCatalogMatchGroupDeleteType,
  ) => {
    if (
      deleteData.productCatalogMatchGroupDelete?.errors &&
      deleteData.productCatalogMatchGroupDelete?.errors.length > 0
    ) {
      onMatchGroupError('deleting');
    } else {
      toast({
        id: 'on-match-group-delete-complete-toast',
        description: `${selectedMatchGroup?.name} successfully deleted`,
        status: ToastStatusEnum.SUCCESS,
      });
      setSelectedMatchGroup(null);
    }
  };

  const onMatchGroupUpdateComplete = (
    updateData: ProductCatalogMatchGroupUpdateType,
  ) => {
    if (
      updateData.productCatalogMatchGroupUpdate?.errors &&
      updateData.productCatalogMatchGroupUpdate?.errors.length > 0
    ) {
      onMatchGroupError('updating');
    } else {
      toast({
        id: 'on-match-group-update-complete-toast',
        description: `${updateData.productCatalogMatchGroupUpdate?.matchGroup?.name} successfully updated`,
        status: ToastStatusEnum.SUCCESS,
      });
    }
  };

  const [matchGroupDelete, { loading: matchGroupDeleteLoading }] = useMutation(
    MATCH_GROUP_DELETE,
    {
      onCompleted: onMatchGroupDeleteComplete,
      onError: () => {
        onMatchGroupError('deleting');
      },
      refetchQueries: [
        {
          query: MATCH_GROUPS,
          variables: {
            orgId,
            ...paginationQueryParams({ after, before }),
          },
        },
      ],
    },
  );

  const [matchGroupUpdate, { loading: matchGroupUpdateLoading }] = useMutation(
    MATCH_GROUP_UPDATE,
    {
      onCompleted: onMatchGroupUpdateComplete,
      onError: () => {
        onMatchGroupError('updating');
      },
      refetchQueries: [
        {
          query: MATCH_GROUPS,
          variables: {
            orgId,
            ...paginationQueryParams({ after, before }),
          },
        },
      ],
    },
  );

  const noCategoriesConfigured =
    categories === null || (categories != null && categories.length === 0);

  const endCursor = data?.productCatalogMatchGroups?.pageInfo?.endCursor;
  const startCursor = data?.productCatalogMatchGroups?.pageInfo?.startCursor;

  const matchGroups = data?.productCatalogMatchGroups?.nodes;

  const trackLearnMore = () => {
    typewriter.linkPressed({
      link_text: 'Learn More',
      primary_cta: false,
      page_or_screen_name: EventNames.settings.colorGrouping.page,
      ...commonTrackingProps,
    });
  };

  const handlePageClick = (direction: PageDirection) => {
    onPaginate({ direction, updateParams, fetchMore, startCursor, endCursor });
  };

  const toggleActive = (group: MatchGroup) => {
    matchGroupUpdate({
      variables: {
        matchGroupId: group.id,
        matchGroupAttributes: {
          active: !group.active,
        },
      },
    });

    typewriter.optionSelected({
      option_type: 'radio',
      selection: group.active ? 'inactive' : 'active', // flipped because it hasn't toggled yet
      page_or_screen_name: EventNames.settings.colorGrouping.page,
      primary_cta: false,
      options: 'Toggle Active',
      ...commonTrackingProps,
    });
  };

  const editGroup = (group: MatchGroup) => {
    typewriter.buttonPressed({
      page_or_screen_name: EventNames.settings.colorGrouping.page,
      button_text: 'Edit Rule',
      backend_id_type: 'MatchGroup',
      backend_id_value: group.id,
      primary_cta: false,
      ...commonTrackingProps,
    });
    history.push(`/workflows/color_grouping_rules/${group.id}/edit`);
  };

  const handleDelete = (group: MatchGroup) => {
    typewriter.buttonPressed({
      page_or_screen_name: EventNames.settings.colorGrouping.page,
      button_text: 'Delete Rule',
      primary_cta: false,
      backend_id_type: 'MatchGroup',
      backend_id_value: group.id,
      ...commonTrackingProps,
    });
    setSelectedMatchGroup(group);
  };

  const deleteGroup = () => {
    if (!selectedMatchGroup) return;
    typewriter.buttonPressed({
      page_or_screen_name: EventNames.settings.colorGrouping.page,
      button_text: 'Delete Rule (Confirm) ',
      primary_cta: false,
      backend_id_type: 'MatchGroup',
      backend_id_value: selectedMatchGroup.id,
      ...commonTrackingProps,
    });
    matchGroupDelete({
      variables: {
        id: selectedMatchGroup.id,
      },
    });
  };

  const closeDeleteModal = () => {
    setSelectedMatchGroup(null);
  };

  const groupsList = () => {
    return (
      <Box flexDirection="column">
        <ConfirmDeleteModal
          isOpen={!!selectedMatchGroup}
          isLoading={matchGroupDeleteLoading}
          message="Are you sure you want to delete this rule?"
          onCancel={closeDeleteModal}
          onConfirm={deleteGroup}
          header="Remove Rule"
        />
        <Box>
          <Table size="small" borderBottom="0px">
            <Thead>
              <Tr data-testid="MatchGroupsHeader">
                <Th width="60%">
                  <Body
                    color="neutral.600"
                    size={400}
                    fontWeight={500}
                    marginBottom="0px"
                    marginTop="0px"
                  >
                    Name
                  </Body>
                </Th>
                <Th>
                  <Box alignItems="center">
                    <Body
                      color="neutral.600"
                      size={400}
                      fontWeight={500}
                      marginBottom="0px"
                      marginTop="0px"
                    >
                      Status
                    </Body>
                    <Tooltip
                      hasArrow
                      placement="top-start"
                      label="Active rules will be visible and accessible to all users. Inactive rules will not be visible to any user."
                    >
                      <Icon icon={iInfo} color="neutral.600" marginLeft={200} />
                    </Tooltip>
                  </Box>
                </Th>
              </Tr>
            </Thead>
            <Tbody data-testid="MatchGroupsTable">
              {matchGroups.map((group: MatchGroup) => {
                return (
                  <Tr key={group.id} data-testid="MatchGroup">
                    <Td width="60%" marginBottom={0}>
                      <Box flexDirection="column">
                        <Body
                          size={400}
                          marginTop="0px"
                          marginBottom="0px"
                          fontWeight={500}
                          data-testid="MatchGroupName"
                        >
                          {group.name}
                        </Body>
                        <Body
                          size={400}
                          marginTop="0px"
                          marginBottom="0px"
                          fontWeight={100}
                          color="neutral.600"
                          data-testid="MatchGroupDescription"
                        >
                          {group.description}
                        </Body>
                      </Box>
                    </Td>
                    <Td>
                      <Box flexDirection="row">
                        <Toggle
                          value={group.active}
                          onChange={() => toggleActive(group)}
                          isDisabled={matchGroupUpdateLoading}
                          data-testid="toggleActive"
                        />
                        <Body marginLeft={200}>
                          {group.active ? 'Active' : 'Inactive'}
                        </Body>
                      </Box>
                    </Td>
                    <Td width="10%">
                      <Box flexDirection="row">
                        <IconButton
                          label="edit"
                          fill="minimal"
                          icon={iEdit}
                          data-testid="editGroup"
                          color="neutral"
                          onClick={() => editGroup(group)}
                        />
                        <IconButton
                          label="delete"
                          fill="minimal"
                          marginLeft={200}
                          icon={iTrash2}
                          data-testid="deleteGroup"
                          color="neutral"
                          onClick={() => handleDelete(group)}
                        />
                      </Box>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
        <Pagination
          hasPrevious={
            !!data?.productCatalogMatchGroups?.pageInfo?.hasPreviousPage
          }
          hasNext={!!data?.productCatalogMatchGroups?.pageInfo?.hasNextPage}
          handlePageClick={handlePageClick}
          resultsCount={data?.productCatalogMatchGroups?.nodes?.length ?? 0}
          hideButtons
        />
      </Box>
    );
  };

  const content = () => {
    return matchGroups.length > 0 ? (
      groupsList()
    ) : (
      <ColorGroupingRulesEmpty
        noCategoriesConfigured={noCategoriesConfigured}
      />
    );
  };

  return (
    <>
      <Box
        justifyContent="space-between"
        alignItems="center"
        marginBottom={500}
      >
        <Box alignItems="center">
          <Heading
            data-testid="CalculationRulesHeader"
            size={600}
            marginBottom={0}
          >
            Color Grouping Rules
          </Heading>
        </Box>
        <Box>
          <Box alignSelf="center" marginRight={600}>
            <Link
              display="flex"
              alignItems="center"
              onClick={trackLearnMore}
              href="https://help.hover.to/en/articles/6505886-color-grouping-interface "
            >
              Learn More
            </Link>
          </Box>
          <Box alignItems="center">
            <Button
              onClick={navigateToCreate}
              label="Create Rule"
              isDisabled={noCategoriesConfigured}
              color={noCategoriesConfigured ? 'neutral' : 'primary'}
            >
              <Icon icon={iPlus} />
              Create Rule
            </Button>
          </Box>
        </Box>
      </Box>
      <Body marginTop={0}>
        Personalize and manage preferences for color matching between product
        categories.
      </Body>
      {matchGroups ? content() : <Loader />}
    </>
  );
};
