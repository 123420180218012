import { PureComponent } from 'react';

import { Box, Body, Button, Icon } from '@hover/blueprint';
import { iX } from '@hover/icons';
import autobind from 'autobind-decorator';
import { compact } from 'lodash';
import { connect } from 'react-redux';

import {
  LineItemTypeEnum,
  TradeTypeEnum,
  ListItemCreate as ListItemCreateParams,
} from 'src/api/graphql-global-types';
import type { productsSearch_productCatalogProductsSearch_nodes as ProductSearchResult } from 'src/api/types/productsSearch';
import { FormattedNumber } from 'src/components/FormattedNumber';
import { Suggestion } from 'src/components/InputWithDropdownTypeahead';
import { withTypewriter } from 'src/components/WithTypewriter';
import { EstimatorProductionApi } from 'src/features/projectManagement/apis/estimatorProduction';
import {
  getProductionListTradeTypes,
  getJobDetails,
  getOrgIdParam,
} from 'src/features/projectManagement/redux/selectors/estimatorProductionSelectors';
import { AddListItemErrors } from 'src/features/projectManagement/types';
import { getUserTrackingProps } from 'src/redux/selectors';
import { getVariationsFilter } from 'src/redux/selectors/orgSelectors';
import { EventNames } from 'src/types/actionTypes';
import { RootState } from 'src/types/reduxStore';
import { Modal } from 'style-guide';

import { Text, Header } from './indexStyled';
import { SearchForm, CUSTOM_COLOR, INPUT_NAMES } from './SearchForm';
import {
  calculateTotal,
  getParamsForCreate,
  buildSearchResults,
  fetchExternalPricingForVariation,
} from './utils';

interface AddListItemFormModalProps {
  closeModalFn: () => void;
  createListItemFn: (props: ListItemCreateParams | undefined) => void;
  addListItemErrors?: AddListItemErrors;
  typewriter: any;
}

export interface AddListItemFormModalState {
  type: LineItemTypeEnum | null;
  itemName: string | null;
  vendor?: string;
  color: string;
  skuId: string;
  quantity: number | null;
  unitPrice: number | null;
  quantityUnit?: string;
  tradeType: TradeTypeEnum | null;
  searchSuggestions: Suggestion[];
  selectedProduct?: ProductSearchResult;
  showColorDropdown: boolean;
  externalVariationId?: string;
  externalProductId?: string;
  userSetCustomColor: boolean;
  userSetCustomSku: boolean;
  userSetCustomUnitCost: boolean;
}

export const mapStateToProps = (state: RootState) => ({
  userProfile: state?.hover?.userProfile,
  shouldShowAddListItemModal:
    state.estimatorProductionTools.shouldShowAddListItemModal,
  vendors: state.estimatorProductionTools.vendors,
  addListItemErrors: state.estimatorProductionTools.addListItemErrors,
  productionListTradeTypes: getProductionListTradeTypes(
    state,
  ) as TradeTypeEnum[],
  jobDetails: getJobDetails(state),
  orgId: getOrgIdParam(state), // This retrieves the orgId from the job/estimate to accommodate a suborg use case; should be changed to use a globally-set orgId in future
  variationsFilter: getVariationsFilter(state),
  commonProps: getUserTrackingProps(state),
});

export interface ColorSelection {
  id: string;
  value: string;
}

type Props = ReturnType<typeof mapStateToProps> & AddListItemFormModalProps;

export class AddListItemModal extends PureComponent<
  Props,
  AddListItemFormModalState
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      type: LineItemTypeEnum.MATERIAL,
      itemName: null,
      searchSuggestions: [],
      color: '',
      skuId: '',

      userSetCustomColor: false,
      userSetCustomSku: false,
      userSetCustomUnitCost: false,

      showColorDropdown: false,
      quantity: 1,
      unitPrice: null,
      tradeType:
        props.productionListTradeTypes.length === 1
          ? (props.productionListTradeTypes[0] as TradeTypeEnum)
          : null,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { shouldShowAddListItemModal, jobDetails, commonProps, typewriter } =
      this.props;
    if (!prevProps.shouldShowAddListItemModal && shouldShowAddListItemModal) {
      // if true, modal appeared
      this.resetState();

      typewriter.pageViewed({
        page_or_screen_name: EventNames.pmp.addListItemModal.page,
        job_id: Number(jobDetails?.id),
        ...commonProps,
      });
    }
  }

  @autobind
  resetState() {
    const { productionListTradeTypes } = this.props;
    this.setState({
      type: LineItemTypeEnum.MATERIAL,
      itemName: null,
      searchSuggestions: [],
      color: '',
      skuId: '',
      userSetCustomColor: false,
      userSetCustomSku: false,
      userSetCustomUnitCost: false,
      showColorDropdown: false,
      quantity: 1,
      unitPrice: null,
      vendor: undefined,
      externalVariationId: undefined,
      externalProductId: undefined,
      tradeType:
        productionListTradeTypes.length === 1
          ? (productionListTradeTypes[0] as TradeTypeEnum)
          : null,
    });
  }

  @autobind
  closeModal() {
    const { closeModalFn, commonProps, jobDetails, typewriter } = this.props;

    typewriter.buttonPressed({
      button_text: 'Cancel',
      page_or_screen_name: EventNames.pmp.addListItemModal.page,
      job_id: Number(jobDetails?.id),
      primary_cta: false,
      ...commonProps,
    });
    closeModalFn();
  }

  @autobind
  private handleChangeTextInput(e: React.ChangeEvent<HTMLInputElement>) {
    const { value, name } = e.target;

    if (name === INPUT_NAMES.sku) {
      this.setState({ userSetCustomSku: true });
    } else if (name === INPUT_NAMES.color) {
      this.setState({ userSetCustomColor: true });
    } else if (name === INPUT_NAMES.unitPrice) {
      this.setState({ userSetCustomUnitCost: true });
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.setState({ [name]: value } as any);
  }

  @autobind
  private selectDropdownInput(selection: string, name: string) {
    const { jobDetails, commonProps, typewriter } = this.props;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.setState({ [name]: selection } as any);

    typewriter.optionSelected({
      option_type: 'dropdown',
      options: 'product name search',
      selection,
      page_or_screen_name: EventNames.pmp.addListItemModal.page,
      job_id: Number(jobDetails?.id),
      primary_cta: true,
      ...commonProps,
    });
  }

  @autobind
  private async selectColorDropdownInput(
    selection: string,
    name: string,
    colors: ColorSelection[],
  ) {
    if (selection === CUSTOM_COLOR) this.setState({ showColorDropdown: false });
    const variationId = colors.find((color) => color.value === selection)?.id;
    if (variationId) {
      this.fetchAndSetExternalPriceAndSku(variationId);
      this.setState({ externalVariationId: variationId });
    }

    this.selectDropdownInput(selection, name);
  }

  @autobind
  private async fetchAndSetExternalPriceAndSku(variationId: string) {
    const selectedVendor = this.getSelectedVendor();
    const { orgId } = this.props;

    const pricing = await fetchExternalPricingForVariation(
      variationId,
      selectedVendor,
      orgId,
    );
    const { defaultSkuId, unitPrice } = pricing;
    this.setState({
      skuId: defaultSkuId,
      unitPrice,
    });
  }

  @autobind
  private handleClick() {
    const { createListItemFn, vendors, commonProps, jobDetails, typewriter } =
      this.props;
    const params: ListItemCreateParams | undefined = getParamsForCreate(
      this.state,
      vendors ?? undefined,
    );
    createListItemFn(params);
    this.resetState();

    typewriter.buttonPressed({
      button_text: 'Submit',
      page_or_screen_name: EventNames.pmp.addListItemModal.page,
      job_id: Number(jobDetails?.id),
      primary_cta: true,
      ...commonProps,
    });
  }

  @autobind
  private getSelectedVendor() {
    const { vendors } = this.props;
    const { vendor } = this.state;

    if (!vendor) return null;
    return vendors && vendors.find((v) => v.vendorName === vendor);
  }

  @autobind
  private handleSearch(input: string, selected: boolean) {
    const { orgId } = this.props;

    const selectedVendor = this.getSelectedVendor();
    if (!selectedVendor) return;

    const variationsFilter = { orgId, filterByOrg: false };
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const searchFunc = async (input: string, selected: boolean) => {
      if (input) {
        const searchVariables = {
          searchTerm: input,
          orgId,
          distributorIds: compact([selectedVendor.distributorId]),
          distributionBranchIds: [],
          id: selectedVendor.distributorId ?? '',
        };

        try {
          const searchData = await EstimatorProductionApi.productSearch(
            searchVariables,
          );

          const results = searchData?.data?.productCatalogProductsSearch?.nodes;
          const distributor = searchData?.data?.productCatalogDistributor;

          if (!results) return;
          // render search suggestions
          this.setState({
            searchSuggestions: buildSearchResults(
              results,
              distributor,
              !!selectedVendor?.distributor?.supportsProductOrdering,
            ),
          });

          if (selected) this.handleSearchSelection(results, input);
        } catch (error) {
          // NO OP
        }
      }
    };
    searchFunc(input, selected);
  }

  private async handleSearchSelection(
    results: ProductSearchResult[],
    input: string,
  ) {
    // update state based on the selected product

    const selectedProduct = results.find(
      (product: ProductSearchResult) =>
        product.name.toLowerCase() === input.toLowerCase(),
    );

    if (!selectedProduct) return;

    // clear color, sku, variation etc when a new product is selected
    this.setState({
      selectedProduct,
      itemName: selectedProduct.name,
      externalProductId: selectedProduct.id,
      showColorDropdown: true,
      color: '',
      skuId: '',
      unitPrice: null,
      externalVariationId: undefined,
    });

    if (selectedProduct.variations.length === 1) {
      const defaultColor = selectedProduct.variations[0].name || '';
      const externalVariationId = selectedProduct.variations[0].id;

      this.setState({
        color: defaultColor,
        externalVariationId,
      });

      this.fetchAndSetExternalPriceAndSku(externalVariationId);
    }
  }

  @autobind
  private skuInvalid() {
    const { skuId } = this.state;
    return !(skuId.length <= 255);
  }

  @autobind
  private colorInvalid() {
    const { color } = this.state;
    return !(color.length <= 255);
  }

  @autobind
  private quantityInvalid() {
    const { quantity } = this.state;
    return !!quantity && Number(quantity) === 0;
  }

  @autobind
  private nameInvalid() {
    const { itemName } = this.state;
    if (!itemName) return false;
    return !(itemName.length <= 255);
  }

  @autobind
  private formInvalid() {
    const { type, itemName } = this.state;
    const isInvalidCategory = !type;
    const isInvalidName = !itemName || this.nameInvalid();
    const isInvalidQuantity = this.quantityInvalid();
    const isInvalidColor = this.colorInvalid();
    const isInvalidSku = this.skuInvalid();
    return (
      isInvalidCategory ||
      isInvalidName ||
      isInvalidColor ||
      isInvalidSku ||
      isInvalidQuantity
    );
  }

  render() {
    const {
      shouldShowAddListItemModal,
      addListItemErrors,
      vendors,
      productionListTradeTypes,
    } = this.props;
    const { quantity, unitPrice } = this.state;

    const total = calculateTotal(quantity, unitPrice);
    return (
      <Modal
        isOpen={shouldShowAddListItemModal}
        contentStyle={{
          padding: 0,
          width: '90%',
          minWidth: '320px',
          maxWidth: '550px',
          minHeight: '600px',
          textAlign: 'initial',
        }}
        data-testid="add-list-item-modal"
      >
        <Box
          justifyContent="space-between"
          alignItems="center"
          padding={400}
          flexShrink={0}
          data-testid="addListItemModal"
        >
          <Box testId="add-list-item-modal-header">
            <Header>Add order item</Header>
          </Box>
          <Button
            fill="minimal"
            shape="circle"
            data-testid="add-list-item-modal-Close"
            onClick={() => this.closeModal()}
          >
            <Icon icon={iX} color="neutral700" />
          </Button>
        </Box>
        <Box
          flex={1}
          justifyContent="flex-start"
          flexDirection="column"
          overflowY="scroll"
          paddingLeft={400}
          paddingRight={400}
          paddingBottom={400}
          data-testid="add-list-item-modal-body"
        >
          <SearchForm
            vendors={vendors}
            addListItemErrors={addListItemErrors}
            productionListTradeTypes={productionListTradeTypes}
            componentState={this.state}
            selectDropdownInput={this.selectDropdownInput}
            selectColorDropdownInput={this.selectColorDropdownInput}
            getSelectedVendor={this.getSelectedVendor}
            handleSearch={this.handleSearch}
            handleChangeTextInput={this.handleChangeTextInput}
            nameInvalid={this.nameInvalid}
            skuInvalid={this.skuInvalid}
            colorInvalid={this.colorInvalid}
            quantityInvalid={this.quantityInvalid}
          />
          {Object.keys(addListItemErrors).length !== 0 && (
            <Body size={300} color="danger500">
              An error has occurred. Please try again.
            </Body>
          )}
          <Box
            flexDirection="row"
            justifyContent="space-between"
            paddingTop={300}
          >
            <Box>
              <Body
                size={300}
                marginTop={200}
                marginBottom={100}
                color="neutral700"
              >
                Total cost{' '}
                <Text>
                  <FormattedNumber
                    value={total}
                    format="$0,0[.]00"
                    data-testid="addListItemModal-total"
                  />
                </Text>
              </Body>
            </Box>
            <Button
              data-testid="add-item-button"
              onClick={() => this.handleClick()}
              isDisabled={this.formInvalid()}
            >
              Add item
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  }
}

export const AddListItemFormModal = connect(mapStateToProps)(
  withTypewriter(AddListItemModal),
);
