import { gql } from '@apollo/client';

export const GET_REQUIRED_INPUTS_FOR_TEMPLATE = gql`
  query EstimationConfigRequiredInputsForTemplate(
    $orgId: ID!
    $templateId: ID!
    $jobId: ID
  ) {
    estimationConfigTemplate(id: $templateId) {
      id
      name
      tradeType
    }
    estimationConfigRequiredInputsForTemplate(
      orgId: $orgId
      templateId: $templateId
      jobId: $jobId
    ) {
      id
      name
      question
      questionDefaultValue
      description
      inputType
      inputCategory {
        name
        id
        description
        sortOrder
      }
      inputConditions {
        id
        parentInputId
        parentValue
      }
      dependentInputConditions {
        id
        estimationInputId
        parentValue
      }
      inputOptions(orgId: $orgId) {
        label
        value
        sortOrder
        createdAt
        updatedAt
        id
      }
      argument
      measurementUnits
      sortOrder
    }
  }
`;
