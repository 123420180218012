import { useState } from 'react';

import { Image, ImageProps } from '@hover/blueprint';
import styled from 'styled-components';

import appSettings from 'src/appSettings';
import FALLBACK_SRC from 'src/assets/images/house-placeholder.png';
import { Image as ImageStyleGuide } from 'style-guide';

export const Roof = styled(ImageStyleGuide)<{ failedToLoad: boolean }>`
  border: ${({ failedToLoad, theme }) =>
    failedToLoad ? `solid 2px ${theme.colors.lightGray}` : 'none'};
  margin: 0;
  border-radius: 1px;
  object-fit: contain;
  width: 100%;
  height: 42vh;
`;

type Props = Partial<ImageProps> & {
  jobId?: number;
  version?: string;
  alt?: string;
};

export const RoofOutline: React.FC<Props> = ({
  jobId,
  version = 'top',
  alt = 'roof',
  ...imageProps
}) => {
  const [imgErr, setImgErr] = useState<boolean>(false);

  const BASE_URL = `${appSettings.HYPERION_API_SERVER}/api/v2/jobs/`;
  const url = `${BASE_URL}${jobId}/wireframe_images/wireframe_roof.png?version=${version}`;
  const dataTestString = version ? `roof-outline-${version}` : 'roof-outline';

  const handleImgError = () => setImgErr(true);

  return (
    <Image
      src={url}
      onError={handleImgError}
      fallbackSrc={FALLBACK_SRC}
      data-testid={dataTestString}
      alt={alt}
      border={imgErr ? 'solid 2px' : 'none'}
      borderColor="neutral.300"
      margin="0"
      borderRadius="1px"
      objectFit="contain"
      width="100%"
      {...imageProps}
    />
  );
};
