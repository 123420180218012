import { TradeTypeCategoryEnum } from 'src/api/graphql-global-types';

import { Input } from '../types';

// organize questions by trade type. Includes questions in multiple trade types
export const generateMeasurementQuestions = (questions: Input[]) => {
  const questionsByTrade: {
    [key in TradeTypeCategoryEnum]?: Input[];
  } = {};
  if (!questions) return {};
  questions.forEach((question) => {
    question.tradeTypeCategories?.forEach((trade) => {
      if (Array.isArray(questionsByTrade[trade])) {
        questionsByTrade[trade]?.push(question);
      } else {
        questionsByTrade[trade] = [question];
      }
    });
  });
  return questionsByTrade;
};
