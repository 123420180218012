import { get } from 'lodash';

import { EstimationConfigRequiredInputsForTemplate_estimationConfigRequiredInputsForTemplate as Input } from 'src/api/types/EstimationConfigRequiredInputsForTemplate';
import { ROOF_TOTAL } from 'src/features/exteriorEstimator/constants/questionArgumentMappings';
import { HDFMeasurements, HDFRoof } from 'src/types/HdfMeasurements';

export const isMeasurementInput = (input: Input) => {
  return input?.inputCategory?.name === 'measurement';
};

export const findMeasurement = (hdf: HDFMeasurements, input: Input) => {
  if (!isMeasurementInput(input)) return null;
  const hdfRoofMeasurements = hdf.roof;
  if (!input.argument) return null;

  return get(hdf, input.argument) ?? get(hdfRoofMeasurements, input.argument);
};

export const isPitch = (argument: string) =>
  argument && argument.startsWith('pitch_area');

export const getPitchTotal = (argument: string, jobMeasurements: HDFRoof) => {
  const argParts = argument.replace('pitch_area_', '').split('_');
  const isGreater = argParts[0] === 'greater';
  const pitchValue = parseInt(argParts[isGreater ? 1 : 0], 10);
  if (!jobMeasurements?.pitch) return 0;
  return jobMeasurements.pitch.reduce((total, { area, pitch }) => {
    if (pitch == null || area == null) return total;
    const thisPitch = Number(pitch);
    if (
      (isGreater && thisPitch > pitchValue) ||
      (!isGreater && thisPitch === pitchValue)
    ) {
      return total + area;
    }
    return total;
  }, 0);
};

export const isRoofTotal = (argument: string) => argument === ROOF_TOTAL;
