import React from 'react';

import * as Sentry from '@sentry/react';
import { replace } from 'connected-react-router';
import { upperFirst, isNil } from 'lodash';
import { renderToStaticMarkup } from 'react-dom/server';
import { all, call, takeLatest, select, put, delay } from 'redux-saga/effects';

import {
  DistributionOrderStateEnum,
  DistributionDeliveryMethodEnum,
  LineItemTypeEnum,
} from 'src/api/graphql-global-types';
import type { ProjectManagementOrderCreateInput } from 'src/api/graphql-global-types';
import type { distributionOrder_distributionOrder as DistributionOrder } from 'src/api/types/distributionOrder';
import { jobDetails_jobs_results as JobDetails } from 'src/api/types/jobDetails';
import { profile_profile_user as UserProfile } from 'src/api/types/profile';
import { projectManagementOrderDocumentCreateVariables as orderDocumentCreateVariables } from 'src/api/types/projectManagementOrderDocumentCreate';
import { tradeTypes_tradeTypes as TradeTypes } from 'src/api/types/tradeTypes';
import type { VendorQuery_vendors as VendorType } from 'src/api/types/VendorQuery';
import { SUMMARY_PATH } from 'src/features/exteriorEstimator/constants/urls';
import { EstimatorProductionApi } from 'src/features/projectManagement/apis/estimatorProduction';
import { PdfFooter } from 'src/features/projectManagement/components/ProductionView/PdfOutput/PdfFooter';
import { PdfOutput } from 'src/features/projectManagement/components/ProductionView/PdfOutput/PdfOutput';
import {
  OrderModalStates,
  deliveryMethodValueField,
  deliveryDateValueField,
  deliveryDateErrorField,
  deliveryTimeValueField,
  deliveryTimeErrorField,
  LineItemTypeToLowercase,
} from 'src/features/projectManagement/constants';
import * as actions from 'src/features/projectManagement/redux/actions';
import type { ProjectManagementStatePdf } from 'src/features/projectManagement/redux/initialState';
import { getProductionListTradeTypes } from 'src/features/projectManagement/redux/selectors/estimatorProductionSelectors';
import {
  ProjectManagementOrderDataType,
  ProjectManagementOrderBranchAccountType,
  ListItemTypeWithError,
  LineItemCategoriesSortedByVendorsType,
  OrderDetailsFormType,
} from 'src/features/projectManagement/types';
import { stringifyProductionListTradeTypesForPDF } from 'src/features/projectManagement/utils/MultiTradesUtils';
import {
  getInvalidListItemIdsFromOrder,
  createErrorMessage,
} from 'src/features/projectManagement/utils/orderUtils';
import {
  getJob,
  getProductionContact,
  getBilling,
} from 'src/features/projectManagement/utils/PdfUtils';
import { getUserProfile, getTradeTypesSorted } from 'src/redux/selectors';

import {
  getOrderDetailsForm,
  getVendorForOrder,
  getProviderOrderAttributes,
  getDistributionOrder,
  getProjectManagementOrder,
  getJobDetails,
  getParams,
  getDistributorCapabilities,
  getItemsSortedByCategoriesAndVendors,
  getPdf,
  getTradeFilter,
  GetProviderOrderAttributesResults,
} from '../selectors';

/*
 * erp flow with pc migration
 *
 * call projectManagementOrderCreate to create an order. This is where you’ll  * send the shipping info, list items, and so on.
 *
 * call projectManagementOrderFulfillmentStart with the ID of the order created
 * in step 1. This will create a ProductCatalogDistributorOrder record and star * the order check process on it.
 *
 * poll distributionOrder will the distributor order ID from step 2 to wait
 * until the check process is complete.
 *
 * Once you’ve shown the user any errors resulting from the check process ask
 * them if they want to submit the order.
 *
 * call productCatalogDistributorOrderSubmit to actually submit the order to
 * the distributor.
 */

export function* projectManagementOrderCreateSaga(action: {
  payload: ProjectManagementOrderBranchAccountType;
}) {
  try {
    const vendorForOrder: VendorType = yield select(getVendorForOrder);
    const orderAttributes: GetProviderOrderAttributesResults = yield select(
      getProviderOrderAttributes(vendorForOrder),
    );

    const { distributionBranchId, distributionJobAccountId, orgId } =
      action?.payload ?? {};

    const {
      data: {
        projectManagementOrderCreate: { errors, order: projectManagementOrder },
      },
    } = yield call(EstimatorProductionApi.projectManagementOrderCreate, {
      orderAttributes: {
        ...orderAttributes,
        ...(distributionBranchId ? { distributionBranchId } : {}),
        ...(distributionJobAccountId ? { distributionJobAccountId } : {}),
      },
      orgId,
    });

    if (errors?.length) {
      /* Significant errors from api are caught so these errors may not
       * require updating the modal ui content. Evaluate errors as they
       * happen for now and uncomment the dispatch put below if needed
       */

      // yield put(
      //   actions.updateProjectManagementOrderData({
      //     orderModalState: OrderModalStates.GenericError
      //   }),
      // );

      // eslint-disable-next-line no-console
      console.log('projectManagementOrderCreateSaga errors: ', errors);
      // eslint-disable-next-line no-console
      console.error('projectManagementOrderCreateSaga errors: ', errors);
    }

    yield put(
      actions.updateProjectManagementOrderData({ projectManagementOrder }),
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

export function* projectManagementOrderFulfillmentStartSaga() {
  try {
    const { id: orderId } = yield select(getProjectManagementOrder);
    // distributionOrder is null at this point, but fetching & spreading into redux below for TS linter
    const distributionOrder: DistributionOrder = yield select(
      getDistributionOrder,
    );

    const {
      data: {
        projectManagementOrderFulfillmentStart: {
          errors,
          order: projectManagementOrder,
        },
      },
    } = yield call(
      EstimatorProductionApi.projectManagementOrderFulfillmentStart,
      { orderId },
    );

    let payload: ProjectManagementOrderDataType = {
      projectManagementOrder,
      distributionOrder: {
        ...distributionOrder,
        id: projectManagementOrder.distributionOrderId,
      },
    };
    if (errors?.length) {
      payload = {
        ...payload,
        orderModalState: OrderModalStates.OrderErrors,
        errorMessage: createErrorMessage(errors),
      };
    }

    yield put(actions.updateProjectManagementOrderData(payload));
  } catch (error) {
    Sentry.captureException(error);
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* pollDistributionOrderSaga(): any {
  try {
    const { id } = yield select(getDistributionOrder);
    const {
      data: {
        distributionOrder,
        distributionOrder: { state },
      },
    } = yield call(EstimatorProductionApi.distributionOrder, {
      id,
    });

    yield put(actions.updateProjectManagementOrderData({ distributionOrder }));

    if (
      state === DistributionOrderStateEnum.checking ||
      state === DistributionOrderStateEnum.queued
    ) {
      // @TODO: hook up modal's cancel button to stop polling onClick
      yield delay(1500);
      yield call(pollDistributionOrderSaga);
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

export function* handleCheckFailedSaga(action: {
  payload: ProjectManagementOrderBranchAccountType;
}) {
  const {
    distributor: { supportsShowingOrderCheckResults },
  } = yield select(getVendorForOrder);
  if (!supportsShowingOrderCheckResults) {
    // bypass handleFailed modal update & submit order immediately
    yield put(actions.submitAndPollDistributionOrder(action.payload));
    return;
  }

  const distributionOrder: DistributionOrder = yield select(
    getDistributionOrder,
  );
  const { errors = [], lineItems = [] } = distributionOrder?.orderCheck ?? {};
  const lineItemsWithErrors = getInvalidListItemIdsFromOrder(
    lineItems as unknown as ListItemTypeWithError[],
  );

  let orderModalState = OrderModalStates.GenericError;
  if (errors?.length || lineItemsWithErrors?.length)
    orderModalState = OrderModalStates.OrderErrors;

  yield put(actions.toggleOrderModal({ show: true })); // for beacon agnostic checkout flow

  yield put(
    actions.updateProjectManagementOrderData({
      orderModalState,
      errorMessage: distributionOrder?.failureReason ?? undefined,
    }),
  );
}

export function* handleSubmitFailedSaga() {
  const distributionOrder: DistributionOrder = yield select(
    getDistributionOrder,
  );
  yield put(actions.toggleOrderModal({ show: true }));
  yield put(
    actions.updateProjectManagementOrderData({
      orderModalState: OrderModalStates.GenericError,
      errorMessage:
        distributionOrder?.failureReason ||
        'We’re unable to connect with the supplier at the moment.',
    }),
  );
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getListItems(itemsByCategoryAndVendor: any, vendor: VendorType) {
  const { materialItems } = itemsByCategoryAndVendor;
  const { vendorName } = vendor;
  return { [vendorName]: materialItems[vendorName] };
}

function getPdfTitle(pdf: ProjectManagementStatePdf, vendor: VendorType) {
  const title = 'list order';

  let fullTitle = upperFirst(
    `${
      isNil(pdf.type)
        ? 'Distributor'
        : LineItemTypeToLowercase[pdf.type as LineItemTypeEnum]
    } ${title}`,
  );

  if (!!vendor) {
    fullTitle += ` - ${vendor.vendorName}`;
  }
  return fullTitle;
}

export function* handleSubmitSuccessSaga(action: {
  payload: ProjectManagementOrderBranchAccountType;
}) {
  // Generate the PDF order summary for the submitted order.
  // First, create the order doc HTML.
  const distributionOrder: DistributionOrder = yield select(
    getDistributionOrder,
  );
  const jobDetails: JobDetails = yield select(getJobDetails);
  const { id } = jobDetails;
  const { orgId } = yield select(getParams);
  const tradeTypes: TradeTypes[] = yield select(getTradeTypesSorted);

  // Get distributionJobAccountId from action payload.
  const { distributionJobAccountId } = action.payload;

  const job = getJob(jobDetails);
  const pdf: ProjectManagementStatePdf = yield select(getPdf);
  pdf.type = LineItemTypeEnum.MATERIAL;
  const itemsByCategoryAndVendor: LineItemCategoriesSortedByVendorsType =
    yield select(getItemsSortedByCategoriesAndVendors);
  const vendorForOrder: VendorType = yield select(getVendorForOrder);
  const listItems = getListItems(itemsByCategoryAndVendor, vendorForOrder);
  const userProfile: UserProfile = yield select(getUserProfile);
  const productionContact = getProductionContact(userProfile);
  const billing = getBilling(userProfile);
  const tradeFilter: string = yield select(getTradeFilter);
  const productionListTradeTypes: string[] = yield select(
    getProductionListTradeTypes,
  );
  const tradeFilterList = stringifyProductionListTradeTypesForPDF(
    productionListTradeTypes,
    tradeFilter,
  );
  const orderAttributes: ProjectManagementOrderCreateInput = yield select(
    getProviderOrderAttributes(vendorForOrder),
  );
  const { id: orderId } = yield select(getProjectManagementOrder);

  try {
    const output = renderToStaticMarkup(
      React.createElement(PdfOutput, {
        title: getPdfTitle(pdf, vendorForOrder),
        purchaseOrderNumber: distributionOrder.purchaseOrderNumber,
        job,
        productionContact,
        billing,
        tradeTypes,
        vendorName: vendorForOrder.vendorName,
        listItems,
        pdf,
        tradeFilter,
        tradeFilterList,
        distributionJobAccountId,
        distributorName: distributionOrder.distributor.name,
      }),
    );
    const footer = renderToStaticMarkup(
      React.createElement(PdfFooter, {
        jobId: job.id,
        productionContactTime: productionContact.time,
      }),
    );
    // Compile the attributes required for PDF order document generation.
    const orderDocumentParams: orderDocumentCreateVariables = {
      externalIdentifier: orderAttributes.externalIdentifier,
      orderDocumentAttributes: {
        externalIdentifier: orderAttributes.externalIdentifier,
        inputHtml: output,
        footerHtml: footer,
        notes: pdf.notes,
        productionListId: orderAttributes.productionListId ?? '',
        vendorId: vendorForOrder.id.toString(),
        orderId,
      },
      listItemIds: orderAttributes.listItemIds,
    };
    // Then call the GQL to generate the PDF.
    yield call(EstimatorProductionApi.orderDocumentCreate, orderDocumentParams);
  } catch (error) {
    Sentry.captureException(error);
  } finally {
    // Forward to the order summary page in EHI in all cases as error would be related to PDF generation, not the order.
    yield put(
      replace(
        `${SUMMARY_PATH}?jobId=${id}&orderId=${distributionOrder.id}&orgId=${orgId}`,
      ),
    );
  }
}

export function* submitDistributionOrderSaga() {
  try {
    const { id: distributionOrderId } = yield select(getDistributionOrder);
    const {
      data: {
        distributionOrderSubmit: { errors, order: distributionOrder },
      },
    } = yield call(EstimatorProductionApi.distributionOrderSubmit, {
      distributionOrderId,
    });
    if (errors.length) {
      yield put(
        actions.updateProjectManagementOrderData({
          orderModalState: OrderModalStates.GenericError,
        }),
      );
    }
    yield put(actions.updateProjectManagementOrderData({ distributionOrder }));
  } catch (error) {
    Sentry.captureException(error);
  }
}

export function* submitAndPollDistributionOrderSaga(action: {
  payload: ProjectManagementOrderBranchAccountType;
}) {
  try {
    yield put(
      actions.updateProjectManagementOrderData({
        orderModalState: OrderModalStates.Loading,
      }),
    );

    yield put(actions.toggleOrderModal({ show: false }));
    yield call(submitDistributionOrderSaga);
    yield call(pollDistributionOrderSaga);

    const { state } = yield select(getDistributionOrder);

    if (state === DistributionOrderStateEnum.failed) {
      yield call(handleSubmitFailedSaga);
    } else if (state === DistributionOrderStateEnum.submitted) {
      yield call(handleSubmitSuccessSaga, action);
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

export function* projectManagementOrderFlowControllerSaga(action: {
  payload: ProjectManagementOrderBranchAccountType;
}) {
  try {
    yield put(
      actions.updateProjectManagementOrderData({
        orderModalState: OrderModalStates.Loading,
        errorMessage: undefined,
      }),
    );

    yield call(projectManagementOrderCreateSaga, action);
    yield call(projectManagementOrderFulfillmentStartSaga);
    yield call(pollDistributionOrderSaga);

    const { state } = yield select(getDistributionOrder);

    if (state === DistributionOrderStateEnum.check_failed) {
      yield call(handleCheckFailedSaga, action);
    } else if (state === DistributionOrderStateEnum.check_success) {
      yield put(actions.submitAndPollDistributionOrder(action.payload));
    }

    yield put(actions.endOrderFlow());
  } catch (error) {
    Sentry.captureException(error);
  }
}

export function* deliveryMethodUpdatedSaga() {
  const orderDetailsForm: OrderDetailsFormType = yield select(
    getOrderDetailsForm,
  );
  const { supportsDeliveryDate, supportsDeliveryTime } = yield select(
    getDistributorCapabilities,
  );
  const selectedDeliveryMethod =
    orderDetailsForm?.values?.[deliveryMethodValueField];

  let isDeliveryDateInvalid;
  let isDeliveryTimeInvalid;

  if (selectedDeliveryMethod === DistributionDeliveryMethodEnum.ON_HOLD) {
    // if the selected delivery method is `ON_HOLD`, the delivery is on hold so no delivery date/time is needed
    isDeliveryDateInvalid = false;
    isDeliveryTimeInvalid = false;
  } else {
    // if selectedDeliveryMethod is falsey or not ON_HOLD, we need to check the presence of the delivery date/time
    if (supportsDeliveryDate) {
      isDeliveryDateInvalid =
        !orderDetailsForm.values?.[deliveryDateValueField];
    }
    if (supportsDeliveryTime) {
      isDeliveryTimeInvalid =
        !orderDetailsForm.values?.[deliveryTimeValueField];
    }
  }

  yield put(
    actions.updateOrderDetailsForm({
      errors: {
        [deliveryDateErrorField]: isDeliveryDateInvalid,
        [deliveryTimeErrorField]: isDeliveryTimeInvalid,
      },
    }),
  );
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default function* sagas() {
  yield all([
    takeLatest(
      actions.startOrderFlow,
      projectManagementOrderFlowControllerSaga,
    ),
    takeLatest(
      actions.submitAndPollDistributionOrder,
      submitAndPollDistributionOrderSaga,
    ),
    takeLatest(actions.deliveryMethodUpdated, deliveryMethodUpdatedSaga),
  ]);
}
