import React, { useMemo } from 'react';

import { Box, Heading, Icon } from '@hover/blueprint';
import { Collapse } from '@hover/blueprint/chakra';
import { iChevronDown, iChevronUp } from '@hover/icons';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  EstimationConfigTemplateCollectionTypeEnum as CollectionTypeEnum,
  TradeTypeEnum,
} from 'src/api/graphql-global-types';
import {
  configTemplateCollectionsForOrg_estimationConfigTemplateCollectionsForOrg_templateSections as TemplateSection,
  configTemplateCollectionsForOrg_estimationConfigTemplateCollectionsForOrg_templateSections_templatesByTradeType_templates as Template,
} from 'src/api/types/configTemplateCollectionsForOrg';
import { getSelectedTemplateIds } from 'src/features/exteriorEstimator/redux/sagas/selectors';
import { replaceUnderscoresWithSpace } from 'src/features/project/util/utils';

import { TemplateCard } from './TemplateCard';

const TemplateCardsSectionContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral300};
  padding-bottom: 32px;
  margin-bottom: 24px;
`;

const TemplateCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px; // if updating this, also update the flexBasis in TemplateCard
`;

type Props = {
  templateCollection: TemplateSection;
  handleCardClick: (
    template: Template,
    templateTradeType: TradeTypeEnum,
  ) => void;
  searchFilterValue: string;
  enableCollapseFunctionality: boolean;
};

export const TemplateCardsSection = ({
  templateCollection,
  handleCardClick,
  searchFilterValue,
  enableCollapseFunctionality,
}: Props) => {
  const selectedTemplatesIds = useSelector(getSelectedTemplateIds) || [];
  const templatesByTrade = templateCollection?.templatesByTradeType || [];
  const { collectionType } = templateCollection;

  const filterBySearchFilter = (template: Template) => {
    const templateSearchText: string =
      template.searchText || template.name || '';

    return templateSearchText
      .toLowerCase()
      .includes(searchFilterValue.toLowerCase());
  };

  // memoize total template count for all templates within
  // templateByTradeType that match the search filter value
  const totalTemplateCount = useMemo(() => {
    return templatesByTrade.reduce((accumulatorNumber, trade) => {
      const filteredTemplates = trade.templates.filter(filterBySearchFilter);
      return accumulatorNumber + filteredTemplates.length;
    }, 0);
  }, [templatesByTrade, filterBySearchFilter]);

  // if collapse functionality is enabled, and the collection type is NOT custom, then default to collapsed
  // else default to expanded
  const [isCollapsed, setIsCollapsed] = React.useState(
    enableCollapseFunctionality
      ? collectionType !== CollectionTypeEnum.CUSTOM
      : false,
  );

  const handleToggleCollapse = () => {
    if (enableCollapseFunctionality) {
      setIsCollapsed(!isCollapsed);
    }
  };

  return (
    <TemplateCardsSectionContainer>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        onClick={handleToggleCollapse}
      >
        <Box>
          <Heading
            marginBottom={0}
            size={{ base: 300, tablet: 400 }}
            fontWeight={700}
          >
            {templateCollection?.name}
          </Heading>
          <Heading
            marginBottom={0}
            marginLeft={400}
            size={{ base: 400, tablet: 500 }}
            fontWeight={700}
            color="neutral.500"
          >
            {totalTemplateCount}
          </Heading>
        </Box>
        <Box>
          {enableCollapseFunctionality && (
            <Icon
              icon={isCollapsed ? iChevronDown : iChevronUp}
              size="small"
              margin="0"
              color="neutral.900"
            />
          )}
        </Box>
      </Box>

      <Box width="100%">
        <Collapse in={!isCollapsed} style={{ width: '100%' }}>
          {templatesByTrade.map((trade) => {
            // filter templates by search filter value
            const filteredTemplates =
              trade.templates.filter(filterBySearchFilter) || [];

            if (filteredTemplates.length === 0) {
              return null;
            }
            return (
              <React.Fragment key={`${trade.tradeType}-template-card-section`}>
                <Heading
                  fontWeight="600"
                  size={200}
                  marginTop={{ base: 400, tablet: 600 }}
                  marginBottom={200}
                  color="neutral.600"
                >
                  {replaceUnderscoresWithSpace(trade.tradeType)}
                </Heading>

                <TemplateCardContainer>
                  {filteredTemplates.map((template) => {
                    const isSelected = selectedTemplatesIds.some(
                      (templateId) => templateId === template.id,
                    );
                    return (
                      <TemplateCard
                        key={`${template.id}-card`}
                        template={template}
                        handleCardClick={() =>
                          handleCardClick(template, template.tradeType)
                        }
                        isSelected={isSelected}
                      />
                    );
                  })}
                </TemplateCardContainer>
              </React.Fragment>
            );
          })}
        </Collapse>
      </Box>
    </TemplateCardsSectionContainer>
  );
};
