import { Body, Box } from '@hover/blueprint';
import numeral from 'numeral';

import { TradeTypeCategoryEnum } from 'src/api/graphql-global-types';
import { EstimateGroup_estimationEstimateGroup_userAnswers as UserAnswer } from 'src/api/types/EstimateGroup';

interface AnswerComponentProps extends Partial<UserAnswer> {
  id: string;
  formattedUserValue: string;
  units?: string | null;
  predictedWasteFactor?: number;
}

export const AnswerComponent: React.FC<AnswerComponentProps> = ({
  id,
  question,
  units,
  estimationConfigInput,
  categoryName,
  predictedWasteFactor,
  formattedUserValue,
}) => {
  const isRoofWasteFactorInput =
    categoryName?.toLowerCase() === 'waste_factor' &&
    estimationConfigInput?.tradeTypeCategories?.includes(
      TradeTypeCategoryEnum.ROOF,
    );

  const isPredictedWasteFactorValid =
    predictedWasteFactor && predictedWasteFactor !== 0;

  const convertEnumToString = (enumValue: string) => {
    return enumValue.replace(/_/g, ' ').toLowerCase();
  };

  return (
    <Box
      data-testid={`answer-${id}`}
      key={id}
      flexDirection="row"
      justifyContent="space-between"
      borderBottomStyle="solid"
      borderBottomWidth="1px"
      borderBottomColor="neutral.200"
      _last={{ borderBottom: 'none' }}
      pb={500}
      mb={400}
    >
      <Box flexWrap="wrap" flex={1}>
        <Body size={400} fontWeight="bodySemiBold" margin={0} p={0}>
          {question}{' '}
          {isRoofWasteFactorInput &&
            isPredictedWasteFactorValid &&
            `(HOVER Recommended ${numeral(predictedWasteFactor).format('0%')})`}
          {units && (
            <Body p={0} margin={0} color="neutral.400" size={300}>
              {convertEnumToString(units)}
            </Body>
          )}
        </Body>
      </Box>
      <Box ml={800} flexWrap="wrap" justifyContent="flex-end">
        <Box mr={100} flexShrink={0} flexWrap="wrap">
          <Body size={400} margin={0}>
            {formattedUserValue}
          </Body>
        </Box>
      </Box>
    </Box>
  );
};
