import {
  useCallback,
  useEffect,
  useState,
  forwardRef,
  useRef,
  DOMElement,
} from 'react';

import { Box, Input, Select, Tooltip } from '@hover/blueprint';
import { useEditableState, useEditableControls } from '@hover/blueprint/chakra';
import { get, isNil, debounce } from 'lodash';
import { Controller, useWatch, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { LineItemTypeEnum } from 'src/api/graphql-global-types';
import {
  productCatalogProductsSearch_productCatalogProductsSearch_nodes as ProductSearchResult,
  productCatalogProductsSearch_productCatalogProductsSearch_nodes_variations as Variation,
} from 'src/api/types/productCatalogProductsSearch';
import type {
  projectManagementProductionList_projectManagementProductionList_listItems as ListItem,
  projectManagementProductionList_projectManagementProductionList_listItems_product as Product,
} from 'src/api/types/projectManagementProductionList';
import type {
  AddEditFields,
  AddEditFormData,
} from 'src/features/project/components/ProjectScope/ProjectScope';

const INPUT_DEBOUNCE_DELAY_MS = 500;
const CUSTOM_VARIANT = 'Custom variant';
export const CUSTOM_VARIANT_COLOR = {
  name: CUSTOM_VARIANT,
  id: CUSTOM_VARIANT,
};

type EditableVariationSelectionProps = {
  listItem: ListItem;
  // orgId: string;
  // refetchListItems: () => void;
  // setSelectedEditListItem: (listItem: ListItem) => void;
  // setIsEditPaused: (isPaused: boolean) => void;
  getDistributorPricing: (listItem: AddEditFields) => void;
  // handleColorChange: (
  //   e: React.ChangeEvent<HTMLSelectElement>,
  //   onChange: (value: string) => void,
  // ) => void;
};

type Color = {
  id: string;
  name: string;
};

/**
 * Custom component used inside a Chakra `Editable` component.
 * Knows when to hide/show itself based on the EditableState.
 */
export const EditableVariationSelection: React.FC<EditableVariationSelectionProps> =
  forwardRef<HTMLDivElement, EditableVariationSelectionProps>(
    ({ listItem, getDistributorPricing }, ref) => {
      const variationSelectInput = useRef<HTMLSelectElement>(null);

      /* Form configuration */
      const {
        register,
        control,
        setValue,
        formState: { errors },
        getValues,
        trigger,
      } = useFormContext<AddEditFormData>();
      // FIXME: Temp override of setValue() while working through TS complications.
      const setFormValue = useCallback(
        (
          field: `${string}.keyof AddEditFields` | string,
          value: any,
          options?: any,
        ) => {
          setValue(field as `${string}`, value, options);
        },
        [],
      );
      // FIXME: Temp override of setValue() while working through TS complications.

      // observe change to variation, to detect selection of custom variation.
      const watchedVariation = useWatch({
        control,
        name: `${listItem.id}.externalVariationId`,
      }) as string;
      useEffect(() => {
        if (!isNil(listItem)) {
          // Explicitly register "hidden" form fields.
          register(`${listItem.id}.color` as const, {
            value: listItem.color,
          });
          register(
            `${listItem.id}.requiresProductVariationSelection` as const,
            { value: listItem.requiresProductVariationSelection },
          );
        }
      }, [listItem, register]);

      const [colorOptions, setColorOptions] = useState<Color[]>([
        CUSTOM_VARIANT_COLOR,
      ]);
      useEffect(() => {
        if (!isNil(listItem)) {
          setColorOptions([
            CUSTOM_VARIANT_COLOR,
            ...(listItem.product?.variations || []),
          ]);
        }
      }, [listItem]);

      const editableState = useEditableState();

      useEffect(() => {
        // Validate the custom variation name field when switching to editing mode.
        if (
          editableState.isEditing &&
          !!listItem.userSetCustomColor &&
          isNil(listItem.externalVariationId)
        ) {
          trigger(`${listItem.id}.color`, { shouldFocus: true });
        }
      }, [
        listItem.id,
        trigger,
        editableState.isEditing,
        listItem.externalVariationId,
        listItem.userSetCustomColor,
      ]);

      // Selection of variation from selection list.
      const handleColorChange = (
        e: React.ChangeEvent<HTMLSelectElement>,
        onChange: (value: string) => void,
      ) => {
        if (e.target.value === CUSTOM_VARIANT_COLOR.name) {
          // When selecting a custom color:
          // 1. Set `CUSTOM_VARIANT` as value for externalVariationId.
          // 2. Clear UnitCost and sku (only for OrderDetails page) and color fields.
          onChange(e.target.value);
          setFormValue(`${listItem.id}.color`, null, {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
        const product: Product = getValues(`${listItem.id}.product`);
        if (!product) return;
        const variation = product.variations.find(
          (suggestionVariation) => suggestionVariation.id === e.target.value,
        );
        if (variation) {
          // form onChange for `externalVariationId` field.
          onChange(e.target.value);
          // set form value for `color` field.
          setFormValue(`${listItem.id}.color`, variation.name, {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
      };

      const onBlur = useCallback(
        (
          e?:
            | React.FocusEvent<HTMLInputElement>
            | React.FocusEvent<HTMLSelectElement>,
        ) => {
          if (
            e?.relatedTarget?.tagName.toLowerCase() === 'select' ||
            (e?.target.tagName.toLowerCase() === 'select' &&
              e?.relatedTarget?.tagName.toLowerCase() === 'input' &&
              watchedVariation === CUSTOM_VARIANT_COLOR.name)
          ) {
            // Don't blur if the blur event was triggered by clicking on the variation Select after the Custom Variation input had been focused;
            // or if auto-focusing the Custom Variation input after the variation Select had been focused.
            return;
          }
          // else, blur the Editable.
          if (!isNil(e)) {
            editableState.onCancel();
          }
        },
        [watchedVariation],
      );

      useEffect(() => {
        if (!isNil(watchedVariation) && editableState.isEditing) {
          // Validate and focus the custom variation name field when changing variation to "Custom variant".
          if (watchedVariation === CUSTOM_VARIANT_COLOR.name) {
            trigger(`${listItem.id}.color`, { shouldFocus: true });
          }
        }
        // Don't want to wait for onBlur change detection.
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [listItem.id, trigger, watchedVariation]);

      useEffect(() => {
        // Focus the variation selection Select field when not in "Custom variant" mode.
        if (editableState.isEditing) {
          if (
            watchedVariation !== CUSTOM_VARIANT_COLOR.name &&
            !listItem.userSetCustomColor
          ) {
            variationSelectInput.current?.focus();
          }
        }
      }, [
        editableState.isEditing,
        listItem.userSetCustomColor,
        watchedVariation,
      ]);

      const getVariationsForProduct = useCallback(
        (product: ProductSearchResult) => {
          const variations = product?.variations;
          if (variations && variations.length > 0) {
            const colors = [
              ...variations.map((variation: Variation) => {
                return { name: variation.name, id: variation.id };
              }),
              CUSTOM_VARIANT_COLOR,
            ];
            setColorOptions(colors);
          }
        },
        [],
      );

      useEffect(() => {
        if (listItem.product && listItem?.productCatalogProductId) {
          getVariationsForProduct(listItem.product as ProductSearchResult);
        }
      }, [getVariationsForProduct, listItem]);

      return (
        <Box
          as="span"
          ref={ref}
          display={editableState.isEditing ? 'flex' : 'none'}
          flexDirection="column"
        >
          <Controller
            control={control}
            name={`${listItem.id}.externalVariationId`}
            render={({ field: { onChange, value: variationIdValue } }) => (
              <Select
                data-testid="EditLineItemColor"
                onChange={(e) => handleColorChange(e, onChange)}
                // isInvalid={!!get(errors, `${listItem.id}.color.message`)}
                value={variationIdValue ?? undefined}
                size="tiny"
                fontSize="inherit"
                ref={variationSelectInput}
                onBlur={onBlur}
              >
                {(isNil(watchedVariation) || watchedVariation.length === 0) &&
                watchedVariation !== CUSTOM_VARIANT_COLOR.name &&
                !(
                  isNil(listItem.externalVariationId) &&
                  !!listItem.userSetCustomColor
                ) ? (
                  <option key={null} value="">
                    Select variant
                  </option>
                ) : null}
                {colorOptions.map((colorOption) => {
                  return (
                    <option
                      key={colorOption.id}
                      value={colorOption.id}
                      selected={
                        colorOption === CUSTOM_VARIANT_COLOR &&
                        !variationIdValue &&
                        !!listItem.userSetCustomColor
                      }
                    >
                      {colorOption.name}
                    </option>
                  );
                })}
              </Select>
            )}
          />
          {listItem?.type === LineItemTypeEnum.MATERIAL &&
            (watchedVariation === CUSTOM_VARIANT_COLOR.name ||
              !!listItem.userSetCustomColor) &&
            editableState.isEditing && (
              <Controller
                control={control}
                name={`${listItem.id}.color`}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Tooltip
                      label={get(errors, `${listItem.id}.color.message`)}
                      placement="top"
                      background="danger300"
                    >
                      <Input
                        {...register(`${listItem.id}.color`, {
                          required: 'Custom variant is required',
                        })}
                        isInvalid={!!get(errors, `${listItem.id}.color`)}
                        size="tiny"
                        marginTop={1}
                        data-testid="AddMaterial-customVariant"
                        placeholder="Enter custom variant name"
                        onBlur={onBlur}
                        onChange={debounce(onChange, INPUT_DEBOUNCE_DELAY_MS)}
                        // @ts-expect-error Errors on TS 4.9 upgrade
                        defaultValue={value}
                        ref={(input) => {
                          // The ref for a ReactHookForm element is required to be regsitered
                          // with that framework, so to use ref functions like `focus()` or `select()`, we
                          // must use the ref provided by RHF.  This focuses on the Custom Variant
                          // when that field becomes visible.
                          if (!!input) {
                            input.focus();
                          }
                        }}
                      />
                    </Tooltip>
                  );
                }}
              />
            )}
        </Box>
      );
    },
  );
