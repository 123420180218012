import { Box, Body } from '@hover/blueprint';
import { isNil } from 'lodash';
import moment from 'moment-timezone';

import { LineItemTypeEnum } from 'src/api/graphql-global-types';
import { profile_profile_user as UserProfile } from 'src/api/types/profile';
import type {
  projectManagementProductionList_projectManagementProductionList_estimateGroup_salesOpportunity_job as JobData,
  projectManagementProductionList_projectManagementProductionList_listItems as ListItem,
} from 'src/api/types/projectManagementProductionList';
import { FormattedNumber } from 'src/components/FormattedNumber';
import {
  containerStyle,
  tableStyle,
  headerWrapper,
  headerText,
  logoStyle,
  totalsTableStyleContainer,
  totalsTableTyle,
  shortCol,
  fatCol,
  labelStyle,
  tableHeader,
  tableRow,
  bold,
  textAlignRight,
  footerStyle,
  footerWrapperStyle,
} from 'src/features/project/styles/OrderPdfStyles';
import { getListItemsByTrade } from 'src/features/project/util/utils';
import { lineItemQuantityUnits } from 'src/utils/unitsMap';

export interface PdfOutputParams {
  title: string;
  purchaseOrderNumber: string | null;
  listItems?: ListItem[];
  shouldShowTotalCost: boolean;
  shouldShowItemMeasurements: boolean;
  shouldShowCostDetails: boolean;
  notes: string | null;
  job?: JobData;
  lineItemType: LineItemTypeEnum | null;
  userProfile?: UserProfile;
}

const getProductionContact = (userProfile?: UserProfile) => {
  return {
    firstName: userProfile?.firstName,
    lastName: userProfile?.lastName,
    email: userProfile?.email,
    phone: userProfile?.officePhone || userProfile?.mobilePhone,
    orgName: userProfile?.orgs[0]?.name,
    logo: userProfile?.orgs[0]?.brand?.logoUrl,
  };
};

const getBilling = (userProfile?: UserProfile) => {
  return {
    addressLine1: userProfile?.orgs[0]?.wallet?.billingAddressLine1,
    city: userProfile?.orgs[0]?.wallet?.billingAddressCity,
    region: userProfile?.orgs[0]?.wallet?.billingAddressRegion,
    zip: userProfile?.orgs[0]?.wallet?.billingAddressPostalCode,
    email: userProfile?.orgs[0]?.wallet?.billingEmail,
  };
};

export interface PdfFooterParams {
  jobId?: number;
}

export const PdfFooter: React.FC<PdfFooterParams> = ({ jobId }) => {
  const timeZone = moment.tz.guess(true); // get the users timezone from browsers
  return (
    <div data-testid="footer" style={footerWrapperStyle}>
      <div style={footerStyle}>
        <span>
          ID: {jobId} | Created:{' '}
          {moment().tz(timeZone)?.format('MM/DD/YYYY-hh:mm A')}
        </span>
      </div>
    </div>
  );
};

export const PdfOutput: React.FC<PdfOutputParams> = ({
  title,
  purchaseOrderNumber,
  listItems,
  notes,
  job,
  shouldShowTotalCost,
  shouldShowItemMeasurements,
  shouldShowCostDetails,
  userProfile,
  lineItemType,
}) => {
  const productionContact = getProductionContact(userProfile);
  const billing = getBilling(userProfile);

  const renderHeader = () => {
    const productionContactTyped = productionContact as Omit<
      typeof productionContact,
      'logo'
    > & { logo: string | undefined };

    return (
      <Box testId="PdfOutputHeader">
        <Box style={headerWrapper}>
          <Box
            style={{
              fontSize: '50px',
              fontWeight: 'bold',
              width: '50%',
            }}
            data-testid="PdfOutputTitle"
          >
            {title}
          </Box>
          <Box style={{ marginTop: '1rem' }}>
            <img
              alt="logo"
              src={productionContactTyped.logo}
              style={logoStyle}
              data-testid="PdfOutputContactLogo"
            />
          </Box>
        </Box>
        <Box>
          <Box
            flexDirection="row"
            style={{ display: 'flex ' }}
            data-testid="PdfOutputPurchaseNum"
          >
            {lineItemType === LineItemTypeEnum.MATERIAL && (
              <Box style={{ fontSize: '16px', fontWeight: 'bold' }}>
                Material List
              </Box>
            )}
            {lineItemType === LineItemTypeEnum.LABOR && (
              <Box style={{ fontSize: '16px', fontWeight: 'bold' }}>
                Work Order
              </Box>
            )}
            {purchaseOrderNumber !== '' && (
              <Box
                style={{
                  marginLeft: '20px',
                  fontSize: '16px',
                  fontWeight: 'bold',
                }}
              >
                PO: {purchaseOrderNumber}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  const renderJobDetails = () => {
    return (
      <>
        <Box
          style={{
            display: 'flex',
            width: '100%',
            marginTop: '40px',
            marginBottom: '40px',
          }}
          flexDirection="row"
        >
          <Box style={{ width: '50%' }}>
            <Box style={{ ...labelStyle, fontWeight: 'bold' }}>
              Delivery Address
            </Box>
            <Box>{job?.locationLine1}</Box>
            <Box>
              {job?.locationCity}, {job?.locationRegion}{' '}
              {job?.locationPostalCode}
            </Box>
          </Box>
          <Box display="flex" flexDir="column">
            <Box style={{ marginBottom: '20px' }}>
              <Box style={{ ...labelStyle, fontWeight: 'bold' }}>Billing</Box>
              <Box>{productionContact.orgName || 'Org Name'}</Box>
              <Box>{productionContact.email || billing.email || ''}</Box>
              <Box>
                {!!billing.addressLine1 && <>{billing.addressLine1},</>}
                {!!billing.city && <>{billing.city},</>}
              </Box>
              <Box>
                {!!billing.region && <>{billing.region}, </>}
                {!!billing.zip && <>{billing.zip}</>}
              </Box>
            </Box>
            <Box>
              <Box style={{ ...labelStyle, fontWeight: 'bold' }}>Contact</Box>
              <Box>
                {productionContact.firstName} {productionContact.lastName}
              </Box>
              <Box>{productionContact.orgName || 'Org Name'}</Box>
              <Box>
                {!!productionContact.phone
                  ? `T: ${productionContact.phone}`
                  : ''}
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  const renderWasteFactor = (listItem: ListItem) => {
    const wasteFactor = Number(listItem.wasteFactor).toLocaleString(undefined, {
      style: 'percent',
      maximumFractionDigits: 2,
    });
    return (
      <span>
        {` + ${wasteFactor} = `}
        <FormattedNumber
          value={
            Number(listItem.measurement) * (1 + Number(listItem.wasteFactor))
          }
        />
        {listItem.measurementUnits && (
          <span style={{ marginLeft: '5px' }}>
            {lineItemQuantityUnits(listItem.measurementUnits)}
          </span>
        )}
      </span>
    );
  };

  const renderTableHeader = () => (
    <thead>
      <th style={{ ...tableHeader }} data-testid="itemCol">
        ITEM
      </th>
      {lineItemType === LineItemTypeEnum.MATERIAL && (
        <th style={{ ...tableHeader }} data-testid="variantCol">
          VARIANT/SKU
        </th>
      )}
      <th style={{ ...tableHeader }} data-testid="quantityCol">
        QUANTITY
      </th>
      {shouldShowCostDetails && (
        <th
          style={{ ...tableHeader, textAlign: 'right' }}
          data-testid="amountCostCol"
        >
          AMOUNT
        </th>
      )}
    </thead>
  );

  const capitalizeTrade = (trade: string) =>
    trade.charAt(0).toUpperCase() + trade.slice(1).toLowerCase();

  const renderListItemsTable = () => {
    if (!listItems) return null;
    const listItesmByTrade = getListItemsByTrade(listItems);
    return (
      <>
        {listItesmByTrade &&
          Object.entries(listItesmByTrade).map(([trade, tradeCollection]) => {
            return (
              <Box>
                <Body
                  style={{
                    fontSize: '18px',
                    fontWeight: '500',
                  }}
                >
                  {capitalizeTrade(trade)}
                </Body>
                <table
                  style={{
                    ...tableStyle,
                    marginBottom: '40px',
                    borderCollapse: 'collapse',
                  }}
                >
                  {renderTableHeader()}
                  {tradeCollection.map((listItem) => {
                    return (
                      <tr style={{ verticalAlign: 'baseline' }}>
                        <td style={{ width: '50%', ...tableRow }}>
                          <Box
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              fontWeight: 'normal',
                            }}
                          >
                            {listItem.name}
                            {!!listItem.measurement &&
                              shouldShowItemMeasurements && (
                                <div
                                  style={{ color: '#a1a1a1', marginTop: '5px' }}
                                >
                                  <FormattedNumber
                                    value={listItem.measurement}
                                  />
                                  {listItem.measurementUnits && (
                                    <span style={{ marginLeft: '5px' }}>
                                      {lineItemQuantityUnits(
                                        listItem.measurementUnits,
                                      )}
                                    </span>
                                  )}
                                  {!!listItem.wasteFactor &&
                                    renderWasteFactor(listItem)}
                                </div>
                              )}
                          </Box>
                        </td>
                        {lineItemType === LineItemTypeEnum.MATERIAL && (
                          <td style={{ width: '25%', ...tableRow }}>
                            {listItem.color} {listItem.sku}
                          </td>
                        )}
                        <td style={{ width: '12%', ...tableRow }}>
                          {listItem.quantity}{' '}
                          {lineItemQuantityUnits(listItem.quantityUnits)}
                        </td>
                        {shouldShowCostDetails && (
                          <td
                            style={{
                              ...tableRow,
                              width: '12%',
                              textAlign: 'right',
                            }}
                          >
                            <Box
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <FormattedNumber
                                format="$0,0.00"
                                value={listItem?.pretaxCost}
                              />
                              <Box
                                style={{ color: '#a1a1a1', marginTop: '5px' }}
                              >
                                <FormattedNumber
                                  format="$0,0.00"
                                  value={listItem.unitCost}
                                />
                                /{lineItemQuantityUnits(listItem.quantityUnits)}
                              </Box>
                            </Box>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </table>
              </Box>
            );
          })}
      </>
    );
  };

  const renderNotes = () => {
    return isNil(notes) || !notes.length ? null : (
      <div>
        <div style={headerText}>ORDER NOTES</div>
        <p>{notes}</p>
      </div>
    );
  };

  const renderCostTotals = () => {
    if (!listItems) return null;
    const subtotal = listItems.reduce(
      (acc, listItem) => acc + listItem.pretaxCost,
      0,
    );
    const postTaxTotal = listItems.reduce(
      (acc, listItem) => acc + listItem.totalCost,
      0,
    );
    return (
      shouldShowTotalCost && (
        <div style={totalsTableStyleContainer}>
          <table style={totalsTableTyle} data-testid="totalCostTable">
            <tbody>
              <tr>
                <td style={{ paddingRight: '3rem' }}>Subtotal</td>
                <td style={textAlignRight}>
                  {listItems && (
                    <FormattedNumber value={subtotal} format="$0,0.00" />
                  )}
                </td>
              </tr>
              <tr>
                <td style={{ paddingRight: '3rem' }}>Sales tax</td>
                <td style={textAlignRight}>
                  {listItems && (
                    <FormattedNumber
                      value={postTaxTotal - subtotal}
                      format="$0,0.00"
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td style={{ paddingRight: '3rem', fontWeight: 'bold' }}>
                  Total Cost:
                </td>
                <td style={{ fontWeight: 'bold', ...textAlignRight }}>
                  {listItems && (
                    <FormattedNumber value={postTaxTotal} format="$0,0.00" />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    );
  };

  if (!listItems) {
    return null;
  }

  return (
    <Box style={containerStyle} id="container">
      {renderHeader()}
      {renderJobDetails()}
      {renderListItemsTable()}
      {renderCostTotals()}
      {renderNotes()}
    </Box>
  );
};
