import {
  Icon,
  Badge,
  Box,
  Button,
  Link,
  Tr,
  Td,
  useTheme,
} from '@hover/blueprint';
import { iClock } from '@hover/icons';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { getOrgTemplates_estimationConfigTemplates_edges_node as TemplateType } from 'src/api/types/getOrgTemplates';
import { useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';
import { sentenceCase } from 'src/utils/Formatters';

interface TemplateProps {
  template: TemplateType;
  openVersionHistoryModal: (template: TemplateType) => void;
}

export const Template: React.FC<TemplateProps> = ({
  template,
  openVersionHistoryModal,
}) => {
  const theme = useTheme();

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const onClickHistory = () => {
    openVersionHistoryModal(template);
    typewriter.buttonPressed({
      page_or_screen_name: EventNames.settings.templates.page,
      button_text: `${template.name} Activity History`,
      primary_cta: false,
      item_type: 'Template',
      ...commonTrackingProps,
    });
  };

  const trackTemplateClicked = () => {
    typewriter.linkPressed({
      page_or_screen_name: EventNames.settings.templates.page,
      link_text: template.name ?? '',
      primary_cta: false,
      ...commonTrackingProps,
    });
  };

  return (
    <Tr
      key={template.id}
      data-testid="TemplateRow"
      _hover={{ backgroundColor: theme.colors.primary100 }}
    >
      <Td width="35%">
        <Link
          as={RouterLink}
          to={`/workflows/template_management/template/${template.id}`}
          onClick={trackTemplateClicked}
        >
          {template.name}
        </Link>
      </Td>
      <Td>{template.tradeType ? sentenceCase(template.tradeType) : ''}</Td>
      <Td>
        <Badge colorScheme={template.active ? 'success' : 'neutral'}>
          {template.active ? 'Active' : 'Inactive'}
        </Badge>
      </Td>
      <Td>
        <Box display="inline-flex" testId="template-updatedAt">
          {new Date(template.updatedAt).toLocaleString()}
        </Box>
      </Td>
      <Td>
        <Button
          label="historyIcon"
          data-testid="historyIcon"
          type="button"
          fill="minimal"
          shape="square"
          onClick={onClickHistory}
        >
          <Icon color="neutral600" icon={iClock} />
        </Button>
      </Td>
    </Tr>
  );
};
