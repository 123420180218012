import React from 'react';

import { Body, Box, IconButton, Link } from '@hover/blueprint';
import { hMeasure, iX } from '@hover/icons';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import appSettings from 'src/appSettings';

export const RecalculateHeader: React.FC<{
  jobId: string | (string | null)[] | null;
}> = ({ jobId }) => {
  const location = useLocation();

  const { returnTo } = queryString.parse(location.search);
  const isRecalculateFlow = returnTo && returnTo !== '';

  const handleBackToMaterialList = () => {
    if (!returnTo) return;
    window.location.href = returnTo as string;
  };

  return (
    <Box
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      marginBottom={200}
      width="100%"
    >
      <Box>
        <Body size={400} marginBottom={8}>
          {isRecalculateFlow ? 'Recalculate ' : ''}Material list
        </Body>
      </Box>
      {isRecalculateFlow && (
        <Box>
          <Link
            href={`${appSettings.HYPERION_API_SERVER}/api/v2/jobs/${jobId}/measurements.pdf`}
            download={`measurements_${jobId}.pdf`}
            type="application/pdf"
            target="_blank"
            color="neutral.900"
            _hover={{ color: 'primary.900' }}
          >
            <IconButton label="measurements" fill="minimal" icon={hMeasure} />
          </Link>
          <IconButton
            icon={iX}
            label="back"
            fill="minimal"
            onClick={handleBackToMaterialList}
          />
        </Box>
      )}
    </Box>
  );
};
