import { CustomInputTypes } from 'src/features/exteriorEstimator/types';

import {
  PARTIAL_SIDING_SELECTION,
  SIDING_LINE_SEGMENT_ADJUSTMENT,
} from './questionCategories';

export const SUPERSCRIPT_TWO = `\u00B2`;

export const PARTIAL_SIDING_SELECTION_ID = 'PARTIAL_SIDING_SELECTION';
export const PARTIAL_SIDING_SELECTION_QUESTION = {
  id: '',
  name: PARTIAL_SIDING_SELECTION,
  question: '',
  inputType: CustomInputTypes.TILE_CHECKBOX,
  face: '',
  questionDefaultValue: false,
  value: null,
  inputCategory: {
    name: PARTIAL_SIDING_SELECTION,
    id: PARTIAL_SIDING_SELECTION,
  },
};

export { SIDING_LINE_SEGMENT_ADJUSTMENT };

export const SIDING_MEASUREMENTS = {
  Brick: 'brick_with_openings_area',
  Stucco: 'stucco_with_openings_area',
  Metal: 'metal_with_openings_area',
  Stone: 'stone_with_openings_area',
  Wrap: 'wrap_with_openings_area',
  Tudor: 'tudor_with_openings_area',
  Unknown: 'unknown_with_openings_area',
};

// facade types are labeled with a 2-3 letter code
// this map lets us use the full name for UI labels
export const FACADE_TYPES: { [key: string]: string } = {
  BR: 'Brick',
  STC: 'Stucco',
  MT: 'Metal',
  SI: 'Siding',
  STO: 'Stone',
  WR: 'Wrap',
  TD: 'Tudor',
  UN: 'Unknown',
};
