import { useEffect, useCallback, useState } from 'react';

import { Box, Body } from '@hover/blueprint';
import type { InputProps } from '@hover/blueprint/chakra';

import { ListItemUpdate as ListItemUpdateParams } from 'src/api/graphql-global-types';
import { FormattedInput } from 'src/features/projectManagement/components/ProductionView/Common/FormattedInput';

interface ComponentProps {
  supportsProductCatalog?: boolean;
  quantity?: string | number;
  updateListItem: (listItemId: number, params: ListItemUpdateParams) => void;
  id: number;
}

export const validateQuantity = (
  value?: string | number,
  supportsProductCatalog = false,
) => {
  const floatValue = Number.parseFloat(value);
  const isInteger = Number.isInteger(floatValue);
  const isValueInvalid = !value || !isInteger || floatValue <= 0;
  const isInputInvalid = !!supportsProductCatalog && isValueInvalid;
  return !isInputInvalid;
};

export const QuantityInput: React.FC<ComponentProps & InputProps> = ({
  quantity,
  supportsProductCatalog = false,
  updateListItem,
  id,
  ...props
}) => {
  const [isInvalid, setIsInvalid] = useState(false);

  const validate = useCallback(
    (value?: string | number) => {
      setIsInvalid(!validateQuantity(value, supportsProductCatalog));
    },
    [supportsProductCatalog],
  );

  useEffect(() => {
    validate(quantity);
    // This effect runs once and only once on first render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBlur = useCallback(
    (event, fieldName) => {
      const targetValue = event?.target?.value;
      validate(targetValue);
      const params = { [fieldName]: targetValue };
      updateListItem(Number(id), params);
    },
    [validate, updateListItem, id],
  );

  return (
    <Box flexDirection="column">
      <FormattedInput
        type="text"
        step="any"
        value={quantity}
        data-testid="itemQuantity"
        handleBlur={(e: React.FormEvent<HTMLInputElement>) =>
          handleBlur(e, 'quantity')
        }
        isError={isInvalid}
        {...props}
      />
      {isInvalid && (
        <Box
          position="relative"
          justifyContent="flex-end"
          margin={0}
          data-testid="qtyInput-error"
        >
          <Box maxWidth="120px">
            <Body size={300} color="danger500" margin={0}>
              Qty must be non-zero whole number
            </Body>
          </Box>
        </Box>
      )}
    </Box>
  );
};
