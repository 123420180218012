import { EventNames } from 'src/types/actionTypes';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const eventNameCurrentPage = (location: any) => {
  if (location.pathname.includes('edit'))
    return EventNames.settings.productVariantEdit.page;
  if (location.pathname.includes('products'))
    return EventNames.settings.productVariantShow.page;
  if (location.pathname.includes('proposal'))
    return EventNames.settings.proposal.page;
  if (location.pathname.includes('line_items'))
    return EventNames.settings.lineItems.page;
  if (location.pathname.includes('template'))
    return EventNames.settings.templates.page;
  if (location.pathname.includes('calculation_preferences'))
    return EventNames.settings.calculationPreferences.page;
  return EventNames.settings.materialsList.page;
};
