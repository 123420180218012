import {
  Body,
  BodyProps,
  Box,
  BoxProps,
  Heading,
  HeadingProps,
  Panel,
  PanelProps,
} from '@hover/blueprint';

export const ProjectDetailsPanel: React.FC<PanelProps> = ({
  children,
  ...props
}) => (
  <Panel
    flexDirection="column"
    paddingY={400}
    paddingX={500}
    minHeight={{ base: 0, tablet: '255px' }}
    flex={{ base: 1, tablet: 0.3 }}
    borderRadius={{ base: 12, tablet: 4 }}
    {...props}
  >
    {children}
  </Panel>
);

export const SectionHeading: React.FC<HeadingProps> = ({
  children,
  ...props
}) => (
  <Heading size={400} margin={0} mb={200} {...props}>
    {children}
  </Heading>
);

export const MobileSectionHeading: React.FC<HeadingProps> = ({
  children,
  ...props
}) => (
  <Heading
    size={400}
    margin={0}
    mb={200}
    color="neutral.600"
    marginBottom={300}
    textTransform="uppercase"
    fontSize="200"
    letterSpacing="1.5px"
    {...props}
  >
    {children}
  </Heading>
);

interface LinkTextPropsType {
  isDisabled?: boolean;
}

type LinkTextProps = LinkTextPropsType & HeadingProps;

export const LinkBody: React.FC<LinkTextProps> = ({
  children,
  isDisabled,
  ...props
}) => {
  const color = isDisabled ? 'neutral500' : 'primary600';
  return (
    <Heading size={300} color={color} margin={0} {...props}>
      {children}
    </Heading>
  );
};

export const FinancialsSection: React.FC<BoxProps> = ({
  children,
  ...props
}) => (
  <Box borderBottom="1px solid neutral500" flexDirection="column" {...props}>
    {children}
  </Box>
);

export const FinancialsRow: React.FC<BoxProps> = ({ children, ...props }) => (
  <Box justifyContent="space-between" my={50} {...props}>
    {children}
  </Box>
);

export const FinancialsBody: React.FC<BodyProps> = ({ children, ...props }) => (
  <Body m={0} {...props}>
    {children}
  </Body>
);
