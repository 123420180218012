import { Box } from '@hover/blueprint';

interface Props {
  children: React.ReactNode;
}

export const BottomBarLayout: React.FC<Props> = ({ children }) => (
  <Box
    minHeight="85px"
    width="100vw"
    display="flex"
    justifyContent="center"
    alignItems="center"
    borderTop="1px solid"
    borderColor="neutral.500"
    padding={{ base: 300, tablet: 400, desktop: 500 }}
    paddingBottom={{ base: 500, tablet: 400, desktop: 500 }}
    as="aside"
    data-testid="bottomBarContainer"
  >
    <Box width="1" display="flex" justifyContent="space-between">
      {children}
    </Box>
  </Box>
);
