import { Breaker } from '@hover/breaker-react';

import { SplitToggle } from 'src/components/SplitToggle';
import type { IProps as SplitToggleProps } from 'src/components/SplitToggle';

import { isEnabled, shouldUseBreaker } from './utils';

type RenderFunc = (props: {
  isEnabled: boolean;
}) => JSX.Element[] | JSX.Element | null | false;

type BreakerChildren = JSX.Element[] | JSX.Element | RenderFunc;

interface Props extends Partial<Omit<SplitToggleProps, 'featureName'>> {
  flagName: string;
}

const SplitAdapter: React.FC<Props> = ({
  flagName,
  whenOn,
  whenOff = <></>,
  children,
  ...splitToggleProps
}) => {
  if (!whenOn && !children) return null;
  if (children && typeof children === 'function') {
    return (
      <SplitToggle
        featureName={flagName}
        whenOn={children({ isEnabled: isEnabled(flagName) })}
        whenOff={children({ isEnabled: isEnabled(flagName) })}
        {...splitToggleProps}
      />
    );
  }

  return (
    <SplitToggle
      featureName={flagName}
      whenOn={<>{whenOn || children}</>}
      whenOff={<>{whenOff}</>}
      {...splitToggleProps}
    />
  );
};

const BreakerAdapter: React.FC<Props> = ({
  whenOn,
  whenOff = null,
  flagName,
  children,
}) => {
  const child = (whenOn as BreakerChildren) || (children as BreakerChildren);

  if (child && typeof children === 'function') {
    return <Breaker feature={flagName}>{child}</Breaker>;
  }

  return (
    <Breaker feature={flagName}>
      {
        (({ isEnabled: breakerIsEnabled }) =>
          breakerIsEnabled ? whenOn || children : whenOff) as RenderFunc
      }
    </Breaker>
  );
};

/**
 * @description children render when feature is enabled
 * @example
 * <FeatureFlag flagName="some feature">
 *   <ChildrenRenderWhenEnabled />
 * </FeatureFlag>
 *
 *
 * @description alternatively you can pass optional whenOn and/or whenOff props
 * @example
 * <FeatureFlag
 *   flagName="some feature"
 *   whenOn={<WhenOn />}
 *   whenOff={<WhenOff />}
 * />
 */
export const FeatureFlag: React.FC<Props> = (props) => {
  return shouldUseBreaker() ? BreakerAdapter(props) : SplitAdapter(props);
};
