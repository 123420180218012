import { useCallback, useEffect, useMemo, useState } from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Body,
  Box,
  BoxProps,
  Button,
  Checkbox,
  Field,
  Heading,
  Image,
  Link,
  LoadingOverlay,
  Panel,
  Radio,
  RadioGroup,
  Textarea,
  TextInput,
} from '@hover/blueprint';
import * as EmailValidator from 'email-validator';
import { isNil, pullAll, sortBy } from 'lodash';
import numeral from 'numeral';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import PhoneNumberInput from 'react-phone-number-input/input';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';

import {
  DistributionDeliveryMethodEnum,
  DistributionOrderStateEnum,
  LineItemTypeEnum,
  TradeTypeEnum,
} from 'src/api/graphql-global-types';
import { distributionOrderCheck_distributionOrderCheck_lineItems as LineItem } from 'src/api/types/distributionOrderCheck';
import {
  distributorCapabilities_deliveryMethods as DeliveryMethod,
  distributorCapabilities_deliveryTimes as DeliveryTime,
  distributorCapabilities_deliveryTypes as DeliveryType,
} from 'src/api/types/distributorCapabilities';
import { productCatalogDistributorCapabilities } from 'src/api/types/productCatalogDistributorCapabilities';
import { projectManagementOrderCreateAndSubmitToDistributor as CreateOrderAndSubmit } from 'src/api/types/projectManagementOrderCreateAndSubmitToDistributor';
import type { projectManagementProductionList as ProductionList } from 'src/api/types/projectManagementProductionList';
import { messages } from 'src/constants/messages';
import {
  DISTRIBUTOR_CAPABILITIES,
  GET_DISTRIBUTION_ORDER,
  GET_PRODUCTION_LIST,
  ORDER_CHECK,
  ORDER_CHECK_CREATE_AND_SUBMIT_TO_DISTRIBUTOR,
} from 'src/features/project/apis/graphql/queries/queries';
import { DeliveryDate } from 'src/features/project/components/Checkout/DeliveryDate';
import { ProjectOrderNavbar } from 'src/features/project/components/common/Navbar';
import { useCreateOrderDocument } from 'src/features/project/hooks/useCreateOrderDocument';
import { updateSelectedListItems } from 'src/features/project/redux/actions';
import {
  getBranch,
  getDistributor,
  getJobAccount,
  getSelectedListItemIds,
} from 'src/features/project/redux/selectors/projectSelectors';
import {
  getListItemsByTypeAndTrade,
  getListItemsByTypeAndTradeIds,
} from 'src/features/project/util/utils';
import { ToastStatusEnum, useToastEhi, useTracking } from 'src/hooks';
import { getOrgIdParam, getUserProfile } from 'src/redux/selectors';
import { EventNames } from 'src/types';

interface OrderCheckoutFormFields {
  deliveryDate: string;
  deliveryMethod: string;
  deliveryTime: string;
  deliveryType: string;
  shippingAddressLine1: string;
  shippingAddressLine2: string;
  shippingAddressCity: string;
  shippingAddressState: string;
  shippingAddressPostalCode: string;
  notes: string;
  poNumber: string;
  jobName: string;
  orderContact: {
    orderContactName: string;
    orderContactEmail: string;
    orderContactPhoneNumber: string;
  };
  deliveryContact: {
    deliveryContactName: string;
    deliveryContactEmail: string;
    deliveryContactPhoneNumber: string;
  };
}

type OrderCheckoutControlsProps = {
  jobId: string;
  orgId: string;
  orderCheckId: string;
  submitOrder: (submitOrderData: OrderCheckoutFormFields) => void;
  loading: boolean;
};

const OrderCheckoutControls: React.FC<
  OrderCheckoutControlsProps & BoxProps
> = ({ jobId, orgId, orderCheckId, submitOrder, loading, ...props }) => {
  const {
    handleSubmit,
    formState: { isValid },
  } = useFormContext();

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const trackBackLink = useCallback(() => {
    typewriter.linkPressed({
      link_text: 'Back',
      page_or_screen_name: EventNames.project.checkout.page,
      primary_cta: false,
      job_id: Number(jobId),
      ...commonTrackingProps,
    });
  }, [jobId, commonTrackingProps]);

  // Segment tracking.
  const trackOrderButton = useCallback(() => {
    typewriter.buttonPressed({
      button_text: 'Submit order',
      page_or_screen_name: EventNames.project.checkout.page,
      job_id: Number(jobId),
      primary_cta: false,
      ...commonTrackingProps,
    });
  }, [jobId, commonTrackingProps]);
  return (
    <Box
      id="OrderCheckout-controls"
      alignItems="baseline"
      padding={500}
      flexDirection="row"
      justifyContent="flex-end"
      backgroundColor="neutral0"
      boxShadow="0px 2px 8px rgba(0, 0, 0, .08);"
      marginTop="6px"
      {...props}
    >
      <Box>
        <Body margin={0} fontSize={300} color="neutral.600">
          Pricing subject to change before invoicing
        </Body>
      </Box>
      <Box marginLeft={600} justifyContent="center">
        <Link
          as={RouterLink}
          to={`/project/${jobId}/detail/${orderCheckId}?orgId=${orgId}`}
          data-testid="back-button"
          onClick={trackBackLink}
        >
          Back
        </Link>
      </Box>
      <Box marginLeft={600}>
        <Button
          testId="submitOrder-button"
          flex={1}
          isDisabled={!isValid}
          onClick={(e) => {
            trackOrderButton();
            handleSubmit(submitOrder)(e);
          }}
          isLoading={loading}
        >
          Submit order
        </Button>
      </Box>
    </Box>
  );
};

const enum TOAST_IDS {
  ORDER_CHECK_TOAST,
  GET_PRODUCTION_LIST_TOAST,
  DISTRIBUTOR_CAPABILITIES_TOAST,
  GET_DISTRIBUTION_ORDER_TOAST,
}
const GENERIC_ERROR =
  'We’re unable to process your order at the moment. Please try again later or contact HOVER support.';

export const OrderCheckout: React.FC = () => {
  const { jobId, orderCheckId } = useParams<{
    jobId: string;
    orderCheckId: string;
  }>();
  const history = useHistory();
  const dispatch = useDispatch();

  const orderSubmitterUser = useSelector(getUserProfile);
  const branch = useSelector(getBranch);
  const jobAccount = useSelector(getJobAccount);
  const orgId = useSelector(getOrgIdParam);
  const distributorId = useSelector(getDistributor)?.id;
  const [error, setError] = useState<string | null>(null);
  const toast = useToastEhi();
  const { createOrderDocument } = useCreateOrderDocument();
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  // Segment tracking.
  useEffect(() => {
    typewriter.pageViewed({
      page_or_screen_name: EventNames.project.checkout.page,
      job_id: Number(jobId),
      ...commonTrackingProps,
    });
  }, [jobId, commonTrackingProps]);

  // Prevent scroll carryover from previous page.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data, loading } = useQuery(ORDER_CHECK, {
    onError: () => {
      toast({
        id: TOAST_IDS.ORDER_CHECK_TOAST,
        description:
          messages.projectScope.errors.query.productionList.orderCheck,
        status: ToastStatusEnum.ERROR,
      });
    },
    fetchPolicy: 'cache-first',
    variables: { id: orderCheckId },
  });

  const {
    data: productionListData,
    loading: productionListLoading,
  }: {
    data?: ProductionList;
    loading: boolean;
  } = useQuery(GET_PRODUCTION_LIST, {
    onError: () => {
      toast({
        id: TOAST_IDS.GET_PRODUCTION_LIST_TOAST,
        description:
          messages.projectScope.errors.query.productionList.getProductionList,
        status: ToastStatusEnum.ERROR,
      });
    },
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    variables: {
      orgId,
      jobId,
    },
  });

  const { data: distributorData, loading: distributorLoading } =
    useQuery<productCatalogDistributorCapabilities>(DISTRIBUTOR_CAPABILITIES, {
      onError: () => {
        toast({
          id: TOAST_IDS.DISTRIBUTOR_CAPABILITIES_TOAST,
          description:
            messages.projectScope.errors.query.productionList
              .distributorCapabilities,
          status: ToastStatusEnum.ERROR,
        });
      },
      variables: { id: distributorId, branchId: branch?.id },
    });
  const distributor = distributorData?.productCatalogDistributor;

  const job =
    productionListData?.projectManagementProductionList?.estimateGroup
      ?.salesOpportunity?.job;

  const distributionOrderCheck = data?.distributionOrderCheck;

  const [
    getDistributionOrder,
    {
      stopPolling: stopPollingDistributionOrder,
      loading: getDistributionOrderLoading,
      error: getDistributionOrderError,
      data: distributionOrderData,
    },
  ] = useLazyQuery(GET_DISTRIBUTION_ORDER, {
    onError: () => {
      toast({
        id: TOAST_IDS.GET_DISTRIBUTION_ORDER_TOAST,
        description:
          messages.projectScope.errors.query.productionList
            .getDistributionOrder,
        status: ToastStatusEnum.ERROR,
      });
    },
    pollInterval: 500,
    notifyOnNetworkStatusChange: true,
  });

  const submittedOrderStatus = distributionOrderData?.distributionOrder?.state;
  const submittedOrderFailureReason =
    distributionOrderData?.distributionOrder?.failureReason;
  const orderId = distributionOrderData?.distributionOrder?.id;

  const selectedListItemIdsByTypeAndTrade = useSelector(getSelectedListItemIds);

  useEffect(() => {
    if (isNil(stopPollingDistributionOrder)) return;

    // success, de-select the successfully-ordered items and route to confirmation
    // ABC distributor uses pending_submitted as a valid success status.
    // Canceled is a valid success state
    if (
      [
        DistributionOrderStateEnum.submitted,
        DistributionOrderStateEnum.pending_submitted,
        DistributionOrderStateEnum.canceled,
      ].includes(submittedOrderStatus)
    ) {
      // all productionListItems
      const listItemsByTypeAndTrade = getListItemsByTypeAndTrade(
        productionListData?.projectManagementProductionList.listItems,
      );
      const listItemsByTypeAndTradeIds = getListItemsByTypeAndTradeIds(
        listItemsByTypeAndTrade,
      );

      // de-select the items from the order.
      const deselectedListItemIdsByTypeAndTrade = {
        ...listItemsByTypeAndTradeIds,
      };

      Object.keys(
        deselectedListItemIdsByTypeAndTrade[LineItemTypeEnum.MATERIAL],
      ).forEach((trade) => {
        const tradeType = trade as TradeTypeEnum;

        const allTradeItems =
          deselectedListItemIdsByTypeAndTrade[LineItemTypeEnum.MATERIAL][
            tradeType
          ];

        const selectedItems =
          selectedListItemIdsByTypeAndTrade?.[LineItemTypeEnum.MATERIAL][
            tradeType
          ];

        pullAll(allTradeItems, selectedItems);
      });

      // Update the selected items in redux with the new deselected items.
      dispatch(updateSelectedListItems(deselectedListItemIdsByTypeAndTrade));

      stopPollingDistributionOrder();

      if (
        productionListData?.projectManagementProductionList &&
        selectedListItemIdsByTypeAndTrade
      ) {
        // Call the creation of Order Document
        createOrderDocument({
          purchaseOrderNumber: distributionOrderData?.purchaseOrderNumber,
          notes: distributionOrderData?.notes,
          productionList: productionListData.projectManagementProductionList,
          listItemIds: selectedListItemIdsByTypeAndTrade,
          projectManagementOrderId,
        });
      }

      history.replace(
        `/project/${jobId}/confirmation/${orderId}?orgId=${orgId}`,
      );
    }

    if (submittedOrderStatus === DistributionOrderStateEnum.failed) {
      stopPollingDistributionOrder();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittedOrderStatus, getDistributionOrderError]);

  const [projectManagementOrderId, setProjectManagementOrderId] = useState('');

  const onOrderSubmitComplete = (response: CreateOrderAndSubmit) => {
    const id =
      response?.projectManagementOrderCreateAndSubmitToDistributor?.order
        ?.distributionOrder?.id;

    setProjectManagementOrderId(
      response?.projectManagementOrderCreateAndSubmitToDistributor?.order?.id ??
        '',
    );

    if (!id) {
      return;
    }

    getDistributionOrder({ variables: { id } });
  };

  const onOrderSubmitError = () => {
    setError(GENERIC_ERROR);
  };

  const [orderSubmit, { loading: orderSubmitLoading }] = useMutation(
    ORDER_CHECK_CREATE_AND_SUBMIT_TO_DISTRIBUTOR,
    {
      onCompleted: onOrderSubmitComplete,
      onError: onOrderSubmitError,
    },
  );

  const defaultValues = {
    deliveryDate: '',
    deliveryMethod: '',
    deliveryTime: '',
    deliveryType: '',
    shippingAddressLine1: job?.locationLine1 ?? '',
    shippingAddressLine2: job?.locationLine2 ?? '',
    shippingAddressCity: job?.locationCity ?? '',
    shippingAddressState: job?.locationRegion ?? '',
    shippingAddressPostalCode: job?.locationPostalCode ?? '',
    notes: '',
    poNumber: '',
    jobName: job?.name ?? '',
    useOrderContactAsDeliveryContact: true,
    orderContact: {
      orderContactName: orderSubmitterUser?.name ?? '',
      orderContactEmail: orderSubmitterUser?.email ?? '',
      orderContactPhoneNumber: orderSubmitterUser?.mobilePhone ?? '',
    },
    deliveryContact: {
      deliveryContactName: '',
      deliveryContactEmail: '',
      deliveryContactPhoneNumber: '',
    },
  };

  const {
    setValue,
    register,
    handleSubmit,
    control,
    getValues,
    formState,
    reset,
    trigger,
    formState: { errors },
    watch,
    ...methods
  } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const useOrderContactAsDeliveryContactValue = useWatch({
    control,
    name: 'useOrderContactAsDeliveryContact',
  });

  // State tracking required when resetting form values asynchronously.
  const [initialRenderReady, setInitialRenderReady] = useState(false);

  useEffect(() => {
    if (!job) return;

    reset(defaultValues); // on load, re-initialize the form once the job data comes in

    if (!initialRenderReady) {
      setInitialRenderReady(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job]);

  useEffect(() => {
    if (initialRenderReady) {
      trigger(); // Do initial form validation on page load.
    }
  }, [initialRenderReady, trigger]);

  const deliveryMethodValue = useWatch({
    control,
    name: 'deliveryMethod',
  });

  useEffect(() => {
    if (useOrderContactAsDeliveryContactValue) {
      const { orderContactName, orderContactEmail, orderContactPhoneNumber } =
        getValues('orderContact');

      setValue('deliveryContact.deliveryContactName', orderContactName, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('deliveryContact.deliveryContactEmail', orderContactEmail, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue(
        'deliveryContact.deliveryContactPhoneNumber',
        orderContactPhoneNumber,
        {
          shouldDirty: true,
          shouldValidate: true,
        },
      );
    } else {
      setValue(
        'deliveryContact.deliveryContactName',
        '',

        { shouldDirty: true, shouldValidate: true },
      );
      setValue('deliveryContact.deliveryContactEmail', '', {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('deliveryContact.deliveryContactPhoneNumber', '', {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
    // eslint-disable-next-line
  }, [useOrderContactAsDeliveryContactValue]);

  useEffect(() => {
    // trigger form validation when the deliveryMethod values change.
    trigger();
  }, [deliveryMethodValue, trigger]);

  const supportsDeliveryType = distributor?.capabilities?.supportsDeliveryType;
  const deliveryTypes = distributor?.capabilities?.deliveryTypes;

  const supportsDeliveryDate = distributor?.capabilities?.supportsDeliveryDate;

  const supportsDeliveryMethod =
    distributor?.capabilities?.supportsDeliveryMethod;
  const deliveryMethods = distributor?.capabilities?.deliveryMethods;

  const supportsDeliveryTime = distributor?.capabilities?.supportsDeliveryTime;
  const deliveryTimes = distributor?.capabilities?.deliveryTimes;

  const submitting =
    getDistributionOrderLoading ||
    orderSubmitLoading ||
    submittedOrderStatus === DistributionOrderStateEnum.queued;

  const deliveryMethodIsCustomerPickup =
    getValues('deliveryMethod') ===
    DistributionDeliveryMethodEnum.BRANCH_PICK_UP;

  const shouldShowDeliveryDate =
    supportsDeliveryDate &&
    getValues('deliveryMethod') !== DistributionDeliveryMethodEnum.ON_HOLD;

  const shouldShowDeliveryTime =
    supportsDeliveryTime &&
    getValues('deliveryMethod') !== DistributionDeliveryMethodEnum.ON_HOLD;

  const shouldShowDeliveryType =
    supportsDeliveryType &&
    getValues('deliveryMethod') !==
      DistributionDeliveryMethodEnum.BRANCH_PICK_UP;

  const shouldShowDeliveryDetails =
    shouldShowDeliveryDate || shouldShowDeliveryTime || shouldShowDeliveryType;

  const shouldShowDeliveryContact =
    distributor?.capabilities?.supportsDeliveryContact;

  const shouldShowDeliveryDisclaimer =
    getValues('deliveryMethod') ===
    DistributionDeliveryMethodEnum.DELIVER_TO_ADDRESS;

  const sortedDeliveryMethods = useMemo(
    () => sortBy(deliveryMethods, (a) => a.sortOrder ?? Number.MAX_VALUE),
    [deliveryMethods],
  );
  const sortedDeliveryTypes = useMemo(
    () => sortBy(deliveryTypes, (a) => a.sortOrder ?? Number.MAX_VALUE),
    [deliveryTypes],
  );
  const sortedDeliveryTimes = useMemo(
    () => sortBy(deliveryTimes, (a) => a?.sortOrder ?? Number.MAX_VALUE),
    [deliveryTimes],
  );

  const submitOrder = (submitOrderData: OrderCheckoutFormFields) => {
    setError(null);

    // deliveryContact properties are not always sent to backend
    let deliveryContact = {};
    // but are sent and set when DeliveryContact is possible
    if (shouldShowDeliveryContact) {
      deliveryContact = submitOrderData.deliveryContact;
    }

    orderSubmit({
      variables: {
        orgId,
        orderAttributes: {
          deliveryDate: shouldShowDeliveryDate
            ? submitOrderData.deliveryDate
            : undefined,
          deliveryMethod: submitOrderData.deliveryMethod,
          deliveryTime: shouldShowDeliveryTime
            ? submitOrderData.deliveryTime
            : undefined,
          deliveryType: shouldShowDeliveryType
            ? submitOrderData.deliveryType
            : undefined,
          distributionBranchId: branch?.id,
          distributionJobAccountId: jobAccount?.id,
          distributorId: distributor?.id,
          orderSessionId: orderCheckId,
          orderListItemsAttributes: distributionOrderCheck.lineItems.map(
            (lineItem: LineItem) => {
              return {
                name: lineItem.productName,
                projectManagementListItemId: lineItem.clientIdentifier,
                quantity: lineItem.quantity,
                quantityUnits: lineItem.quantityUnits,
                type: LineItemTypeEnum.MATERIAL,
                variantName: lineItem.variationName,
                externalVariationId: lineItem.variationId,
                productCatalogProductId: lineItem.productId,
                sku: lineItem.sku,
                sortOrder: lineItem.sortOrder,
              };
            },
          ),
          notes: submitOrderData.notes,
          shippingAddressCity: submitOrderData.shippingAddressCity,
          shippingAddressLine1: submitOrderData.shippingAddressLine1,
          shippingAddressLine2: submitOrderData.shippingAddressLine2,
          shippingAddressPostalCode: submitOrderData.shippingAddressPostalCode,
          shippingAddressState: submitOrderData.shippingAddressState,
          purchaseOrderNumber: submitOrderData.poNumber,

          // for all distributors send over orderContact properites
          ...submitOrderData.orderContact,

          // for distributors with supportsDeliveryContact (ie, ABC), send  over deliveryContact
          ...deliveryContact,
        },
      },
    });
  };

  useEffect(() => {
    if (submittedOrderStatus === DistributionOrderStateEnum.failed) {
      setError(
        submittedOrderFailureReason !== ''
          ? submittedOrderFailureReason
          : GENERIC_ERROR,
      );
    }
  }, [submittedOrderFailureReason, submittedOrderStatus]);

  // Segment tracking.
  const trackDeliveryTime = useCallback(
    (value: string) => {
      typewriter.optionSelected({
        option_type: 'radio',
        selection: value,
        page_or_screen_name: EventNames.project.checkout.page,
        primary_cta: false,
        job_id: jobId,
        options: 'Requested delivery time',
        ...commonTrackingProps,
      });
    },
    [commonTrackingProps, jobId],
  );

  return (
    <>
      <LoadingOverlay
        isLoading={
          loading || productionListLoading || distributorLoading || submitting
        }
        isAnimated={false}
        data-testid="CheckoutSpinner"
      />
      <FormProvider
        {...{
          setValue,
          control,
          formState,
          handleSubmit,
          getValues,
          register,
          reset,
          watch,
          trigger,
          ...methods,
        }}
      >
        <ProjectOrderNavbar
          sx={{ position: 'sticky', top: '0px', zIndex: 1 }}
        />
        <Box
          flexDirection="column"
          flex="auto"
          data-testid="orderCheckout"
          paddingX={700}
          paddingY={500}
          backgroundColor="neutral.100"
        >
          <Box position="relative">
            <Box top="70px" flex="1" flexDirection="row">
              <Box flexDirection="column" marginX="200px" flex="1">
                <Box flexDirection="row" justifyContent="space-between">
                  {/* Branch/JobAccount */}
                  <Box
                    flexDirection="column"
                    flex="3"
                    paddingTop={600}
                    data-testid="branchAndJobInfo"
                  >
                    <Box>
                      <Heading size={500} marginBottom={0}>
                        Branch
                      </Heading>
                    </Box>
                    <Box
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      width="75%"
                      marginBottom={200}
                    >
                      <Body size={500} data-testid="branchName">
                        {branch?.name}
                      </Body>
                      <Image
                        height="40px"
                        src={distributor?.logo?.url}
                        alt="logo"
                      />
                    </Box>
                    <Box>
                      <Heading size={500} marginBottom={0}>
                        Billing
                      </Heading>
                    </Box>
                    <Box>
                      <Body size={500} data-testid="JobAccount">
                        {`Job Account: ${jobAccount?.id}-${jobAccount?.displayName}`}
                      </Body>
                    </Box>
                    <Box flexDirection="row" justifyContent="space-between">
                      <Field
                        label="Purchase order number"
                        flex={0.45}
                        aria-required
                        name="poNumber"
                        paddingRight={500}
                        error={errors?.poNumber?.message}
                      >
                        <TextInput
                          {...register('poNumber', {
                            required: 'PO number is required',
                          })}
                          data-testid="poNumber"
                        />
                      </Field>
                      <Field
                        label="Job name (optional)"
                        flex={0.55}
                        name="jobName"
                        paddingRight={500}
                        error={errors?.jobName?.message}
                      >
                        <TextInput
                          {...register('jobName')}
                          data-testid="jobName"
                        />
                      </Field>
                    </Box>
                  </Box>
                  {/* Summary Panel */}
                  <Box paddingTop={600} paddingBottom={500} flex="1">
                    <Box paddingLeft={600}>
                      <Panel
                        boxShadow="distance600"
                        alignItems="stretch"
                        padding={600}
                        data-testid="orderTotalsPanel"
                      >
                        <Box justifyContent="space-between">
                          <Body size={300} margin={0} color="neutral600">
                            Subtotal
                          </Body>
                          <Body
                            size={300}
                            margin={0}
                            color="neutral600"
                            data-testid="orderSubTotal"
                          >
                            {numeral(
                              data?.distributionOrderCheck.pretaxCost,
                            ).format('$0,0.00')}
                          </Body>
                        </Box>
                        <Box justifyContent="space-between">
                          <Body size={300} margin={0} color="neutral600">
                            {`Tax (${numeral(
                              data?.distributionOrderCheck.salesTax /
                                data?.distributionOrderCheck.pretaxCost,
                            ).format('0.00%')})`}
                          </Body>
                          <Body
                            size={300}
                            margin={0}
                            color="neutral600"
                            data-testid="orderTax"
                          >
                            {numeral(
                              data?.distributionOrderCheck.salesTax,
                            ).format('$0,0.00')}
                          </Body>
                        </Box>
                        <Box
                          justifyContent="space-between"
                          marginY={400}
                          paddingTop={400}
                          borderTop="1px solid"
                          borderColor="neutral.300"
                        >
                          <Heading size={400} marginRight={400}>
                            Total:
                          </Heading>
                          <Heading size={400} data-testid="orderTotal">
                            {numeral(
                              data?.distributionOrderCheck.totalCost,
                            ).format('$0,0.00')}
                          </Heading>
                        </Box>
                        <Box flexDirection="column">
                          <Body margin={0} fontSize={100} color="neutral.600">
                            Sales Tax is subject to change and applied by the
                            branch
                          </Body>
                          <Body
                            margin={0}
                            fontSize={100}
                            color="neutral.600"
                            mt={200}
                          >
                            Order quantities and Invoice quantities may differ
                            due to unit of measure conversion
                          </Body>
                        </Box>
                        <Box
                          width={1}
                          maxWidth="208px"
                          marginY={400}
                          marginX={0}
                          data-testid="orderSubmit-errorMessage"
                        >
                          {error && (
                            <Body size={300} margin={0} color="danger500">
                              {error}
                            </Body>
                          )}
                        </Box>
                      </Panel>
                    </Box>
                  </Box>
                </Box>
                {/* Form */}
                <Box flexDirection="column" data-testid="deliveryMethodSection">
                  <Box>
                    <Heading color="primary900" size={500} marginBottom={400}>
                      Delivery Method
                    </Heading>
                  </Box>
                  {supportsDeliveryMethod && (
                    <Box>
                      <Controller
                        control={control}
                        name="deliveryMethod"
                        rules={{
                          required: 'Delivery method is required',
                        }}
                        render={({ field: { onChange } }) => (
                          <RadioGroup
                            onChange={onChange}
                            justifyContent="space-between"
                            data-testid="deliveryMethod"
                          >
                            {sortedDeliveryMethods &&
                              sortedDeliveryMethods.map(
                                (deliveryMethod: DeliveryMethod) => {
                                  return (
                                    <Radio
                                      value={deliveryMethod.deliveryMethod}
                                      key={deliveryMethod.id}
                                      marginRight={500}
                                    >
                                      <Body margin={0} fontWeight="bold">
                                        {deliveryMethod.label}
                                      </Body>
                                    </Radio>
                                  );
                                },
                              )}
                          </RadioGroup>
                        )}
                      />
                    </Box>
                  )}
                  <Panel
                    boxShadow="distance500"
                    marginY={500}
                    paddingX={600}
                    paddingY={800}
                    data-testid="addressPanel"
                    // TODO: revisit hiding the address panel for certain types of delivery methods,
                    // when mobile team fixes address inputs during capture state
                    // display={
                    //   deliveryMethodValue ===
                    //   DistributionDeliveryMethodEnum.DELIVER_TO_ADDRESS
                    //     ? 'inherit'
                    //     : 'none'
                    // }
                  >
                    <Box flexDirection="row">
                      <Field
                        label="Address line 1"
                        width="50%"
                        aria-required
                        name="shippingAddressLine1"
                        paddingRight={500}
                        error={errors?.shippingAddressLine1?.message}
                      >
                        <TextInput
                          {...register('shippingAddressLine1', {
                            required: 'Address is required',
                            maxLength: {
                              value: 30,
                              message:
                                'Address must be maximum of 30 characters',
                            },
                          })}
                          maxLength={30}
                          isInvalid={!!errors?.shippingAddressLine1?.message}
                          data-testid="shippingAddressLine1"
                        />
                      </Field>
                      <Field
                        label="Address line 2 (optional)"
                        name="shippingAddressLine2"
                        width="50%"
                        error={errors?.shippingAddressLine2?.message}
                      >
                        <TextInput
                          {...register('shippingAddressLine2', {
                            maxLength: {
                              value: 30,
                              message:
                                'Address must be maximum of 30 characters',
                            },
                          })}
                          maxLength={30}
                          isInvalid={!!errors?.shippingAddressLine2?.message}
                          data-testid="shippingAddressLine2"
                        />
                      </Field>
                    </Box>
                    <Box
                      flexDirection="row"
                      sx={{
                        // TODO: waiting for Blueprint fix https://hoverinc.atlassian.net/browse/BLUE-387?atlOrigin=eyJpIjoiY2I0MTY5ODQ4OGU5NDMyMzg2NDBjNjAwMzg5Njc2YmYiLCJwIjoiamlyYS1zbGFjay1pbnQifQ
                        '& .chakra-form__error-message': {
                          top: '80px',
                        },
                      }}
                    >
                      <Field
                        label="City"
                        name="shippingAddressCity"
                        width="50%"
                        paddingRight={500}
                        error={errors?.shippingAddressCity?.message}
                      >
                        <TextInput
                          {...register('shippingAddressCity', {
                            required: 'City is required',
                            maxLength: {
                              value: 25,
                              message: 'City must be maximum of 25 characters',
                            },
                          })}
                          maxLength={25}
                          isInvalid={!!errors?.shippingAddressCity?.message}
                          data-testid="shippingAddressCity"
                        />
                      </Field>
                      <Field
                        label="State"
                        name="shippingAddressState"
                        width="25%"
                        paddingRight={500}
                        error={errors?.shippingAddressState?.message}
                      >
                        <TextInput
                          {...register('shippingAddressState', {
                            required: 'State is required',
                            minLength: {
                              value: 2,
                              message: 'State must be 2 characters',
                            },
                            maxLength: {
                              value: 2,
                              message: 'State must be 2 characters',
                            },
                          })}
                          maxLength={2}
                          isInvalid={!!errors?.shippingAddressState?.message}
                          data-testid="shippingAddressState"
                        />
                      </Field>
                      <Field
                        label="Zip code"
                        name="shippingAddressPostalCode"
                        width="25%"
                        error={errors?.shippingAddressPostalCode?.message}
                      >
                        <TextInput
                          {...register('shippingAddressPostalCode', {
                            required: 'Zip code is required',
                            minLength: {
                              value: 5,
                              message: 'Zip code must be at least 5 digits',
                            },
                            maxLength: {
                              value: 10,
                              message: 'Zip code must be maximum of 10 digits',
                            },
                          })}
                          maxLength={10}
                          isInvalid={
                            !!errors?.shippingAddressPostalCode?.message
                          }
                          data-testid="shippingAddressPostalCode"
                        />
                      </Field>
                    </Box>
                  </Panel>
                </Box>
                <Box>
                  {shouldShowDeliveryDetails && (
                    <Box
                      flexDirection="column"
                      data-testid="deliveryDetails"
                      flex=".5 .5 50%"
                    >
                      <Heading color="primary900" size={500} marginBottom={400}>
                        {`${
                          deliveryMethodIsCustomerPickup
                            ? 'Pick-up'
                            : 'Delivery'
                        } Details`}
                      </Heading>
                      <Panel
                        boxShadow="distance500"
                        marginTop={0}
                        marginBottom={500}
                        paddingX={600}
                        paddingY={800}
                      >
                        {shouldShowDeliveryDate && (
                          <Box>
                            <Field
                              label={`${
                                deliveryMethodIsCustomerPickup
                                  ? 'Pick-up'
                                  : 'Delivery'
                              } Date`}
                              name="deliveryDate"
                            >
                              <Controller
                                control={control}
                                name="deliveryDate"
                                rules={{
                                  required: 'Delivery date is required',
                                }}
                                render={({ field: { onChange } }) => (
                                  <DeliveryDate onChange={onChange} />
                                )}
                              />
                            </Field>
                          </Box>
                        )}

                        {shouldShowDeliveryTime && (
                          <Box>
                            <Field
                              label={`Requested ${
                                deliveryMethodIsCustomerPickup
                                  ? 'Pick-up'
                                  : 'Delivery'
                              } Time`}
                              name="deliveryTime"
                            >
                              <Controller
                                control={control}
                                name="deliveryTime"
                                rules={{
                                  required: 'Delivery time is required',
                                }}
                                render={({ field: { onChange } }) => (
                                  <RadioGroup
                                    onChange={(e) => {
                                      onChange(e);
                                      trackDeliveryTime(e);
                                    }}
                                    justifyContent="space-between"
                                    data-testid="deliveryTime"
                                  >
                                    {sortedDeliveryTimes &&
                                      sortedDeliveryTimes.map(
                                        (deliveryTime: DeliveryTime) => {
                                          return (
                                            <Radio
                                              value={deliveryTime.deliveryTime}
                                              key={deliveryTime.id}
                                              marginRight={500}
                                            >
                                              <Body
                                                margin={0}
                                                fontWeight="bold"
                                              >
                                                {deliveryTime.label}
                                              </Body>
                                            </Radio>
                                          );
                                        },
                                      )}
                                  </RadioGroup>
                                )}
                              />
                            </Field>
                          </Box>
                        )}
                        {shouldShowDeliveryType && (
                          <Box>
                            <Field label="Delivery type" name="deliveryType">
                              <Controller
                                control={control}
                                name="deliveryType"
                                rules={{
                                  required: 'Delivery type is required',
                                }}
                                render={({ field: { onChange } }) => (
                                  <RadioGroup
                                    onChange={onChange}
                                    justifyContent="space-between"
                                    data-testid="deliveryType"
                                  >
                                    {sortedDeliveryTypes &&
                                      sortedDeliveryTypes.map(
                                        (deliveryType: DeliveryType) => {
                                          return (
                                            <Radio
                                              value={deliveryType.deliveryType}
                                              key={deliveryType.id}
                                              marginRight={500}
                                            >
                                              <Body
                                                margin={0}
                                                fontWeight="bold"
                                              >
                                                {deliveryType.label}
                                              </Body>
                                            </Radio>
                                          );
                                        },
                                      )}
                                  </RadioGroup>
                                )}
                              />
                            </Field>
                          </Box>
                        )}
                        {shouldShowDeliveryDisclaimer && (
                          <Body
                            fontSize={300}
                            color="neutral600"
                            data-testid="deliveryDisclaimer"
                          >
                            Your requested date and time are subject to change.
                            Your branch manager will contact you for any
                            delivery schedule changes.
                          </Body>
                        )}
                      </Panel>
                    </Box>
                  )}

                  {shouldShowDeliveryContact && (
                    <Box
                      flexDirection="column"
                      data-testid="deliveryContact"
                      flex=".5 .5 50%"
                      paddingLeft={600}
                    >
                      <Heading color="primary900" size={500} marginBottom={400}>
                        Delivery Contact
                      </Heading>
                      <Panel
                        boxShadow="distance500"
                        marginTop={0}
                        marginBottom={500}
                        paddingX={600}
                        paddingTop={800}
                        paddingBottom={400}
                      >
                        <Checkbox
                          data-testid="useOrderContactAsDeliveryContactCheckBox"
                          {...register('useOrderContactAsDeliveryContact')}
                          fontSize={200}
                          marginBottom={500}
                        >
                          Delivery and Submitter contact are the same
                        </Checkbox>

                        {!useOrderContactAsDeliveryContactValue && (
                          <>
                            <Field
                              data-testid="deliveryContactNameField"
                              label="Full Name"
                              name="deliveryContactName"
                              error={
                                errors?.deliveryContact?.deliveryContactName
                                  ?.message
                              }
                            >
                              <TextInput
                                {...register(
                                  `deliveryContact.deliveryContactName` as const,
                                  {
                                    required: 'name is required',
                                  },
                                )}
                              />
                            </Field>

                            <Field
                              data-testid="deliveryContactEmailField"
                              label="Email"
                              name="deliveryContactEmail"
                              error={
                                errors?.deliveryContact?.deliveryContactEmail
                                  ?.message
                              }
                            >
                              <TextInput
                                {...register(
                                  `deliveryContact.deliveryContactEmail` as const,
                                  {
                                    required: 'email is required',
                                    validate: {
                                      emailValidator: (value) => {
                                        if (!value) {
                                          return 'Email is required';
                                        }
                                        return (
                                          EmailValidator.validate(value) ||
                                          'Must be valid email'
                                        );
                                      },
                                    },
                                  },
                                )}
                                type="email"
                              />
                            </Field>

                            <Field
                              label="Phone (optional)"
                              name="deliveryContactPhoneNumber"
                              error={
                                errors?.deliveryContact
                                  ?.deliveryContactPhoneNumber?.message
                              }
                            >
                              <Controller
                                name="deliveryContact.deliveryContactPhoneNumber"
                                control={control}
                                rules={{
                                  validate: {
                                    phoneValidator: (value) => {
                                      if (!value) {
                                        return true;
                                      }

                                      return (
                                        isPossiblePhoneNumber(value) ||
                                        'Please enter a valid phone number'
                                      );
                                    },
                                  },
                                }}
                                render={({ field: { onChange, value } }) => (
                                  <TextInput
                                    as={PhoneNumberInput}
                                    value={value}
                                    onChange={onChange}
                                    international
                                    withCountryCallingCode
                                    // TODO: use country value from browser locale
                                    country="US"
                                    id="phone-input"
                                  />
                                )}
                              />
                            </Field>
                          </>
                        )}
                      </Panel>
                    </Box>
                  )}
                </Box>
                <Box flexDirection="column">
                  <Heading color="primary900" size={500} marginBottom={400}>
                    Instructions
                  </Heading>
                  <Field label="Order instructions (optional)" name="notes">
                    <Textarea
                      {...register('notes')}
                      resize="vertical"
                      rows={5}
                      maxLength={234}
                      data-testid="orderInstructions"
                    />
                  </Field>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <OrderCheckoutControls
          jobId={jobId}
          orgId={orgId}
          orderCheckId={orderCheckId}
          submitOrder={submitOrder}
          loading={submitting}
          sx={{ position: 'sticky', bottom: '0px', zIndex: 1 }}
        />
      </FormProvider>
    </>
  );
};
