import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
  from,
  fromPromise,
  split,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { relayStylePagination } from '@apollo/client/utilities';
import * as Sentry from '@sentry/react';
import { print } from 'graphql';
import { GraphQLError } from 'graphql/error';
import Cookies from 'js-cookie';
import { uniqBy } from 'lodash';
import { Subject, Subscription } from 'rxjs';

import appSettings from 'src/appSettings';
import { HoverRequest } from 'src/lib/HoverRequestWrapper';

const PROD_DEFAULT_FETCH_POLICY = 'cache-first';
const TEST_DEFAULT_FETCH_POLICY = 'network-only';

// Concrete string union of 'type' values in GQL error.extensions.
export const OAUTH_ERROR = 'distribution_oauth_token_invalid';
export const DISTRIBUTION_JOB_ACCOUNT_ERROR =
  'distribution_job_account_authentication_invalid';
export const NETWORK_ERROR = 'network';

type GQLErrors =
  | typeof OAUTH_ERROR
  | typeof NETWORK_ERROR
  | typeof DISTRIBUTION_JOB_ACCOUNT_ERROR;

export type IntegrationConnectionError = {
  distributorId: string;
  distributorDisplayName: string;
  distributorWebsiteDisplayName: string;
  type: string;
};
// Type with properties specific to an OAuth error.
export type OAuthError = IntegrationConnectionError & {
  distributorOauthAuthorizationUrl: string;
};

type AppErrorError = any;
export type AppError = {
  type: string;
  error: AppErrorError;
};

// Type for other/general GQL error.
export type Error = Record<string, unknown>;

// Union type of possible GQL error types.
type GraphQLErrorTypes = IntegrationConnectionError | OAuthError | Error;
// All GQL errors should have a 'type' property in the error.extensions object; therefore,
// all error extension objects should be an intersection of this type with a specific error type.
type GraphQLErrorExtensions<T extends GraphQLErrorTypes> = T & {
  type: GQLErrors;
};
// The full error type that contains the Apollo error properties, combined
// with our custom extensions.
export type GQLError<T extends GraphQLErrorTypes> = {
  extensions: GraphQLErrorExtensions<T>;
} & Omit<GraphQLError, 'extensions'>;
// Type of the subscriber function provided to Observable.subscribe.
type ErrorSubscriber<T extends GraphQLErrorTypes> = (
  value: ReadonlyArray<GQLError<T>>,
) => void;

/* Private singleton Observable that can emit events on OAuthError occurrence, or other errors. */
const GQLErrorObservable = new Subject<
  ReadonlyArray<GQLError<GraphQLErrorTypes>> | AppError
>();

/**
 * Subscribe function for all Graphql errors, optionally filtered by error type.
 * */
export const subscribeToGQLErrors = <T extends GraphQLErrorTypes>(
  subscriber: ErrorSubscriber<T>,
  eventTypes?: GQLErrors[],
): Subscription => {
  return GQLErrorObservable.subscribe((errors) => {
    // Filter the errors based on eventType and emit event.
    const filteredErrors = (
      errors as ReadonlyArray<GQLError<GraphQLErrorTypes>>
    ).filter((error) => {
      return (
        !eventTypes ||
        eventTypes.some((eventType) => eventType === error.extensions.type)
      );
    });

    // If there are matching/filtered errors, notify subscriber.
    if (filteredErrors?.length > 0) {
      subscriber(filteredErrors as ReadonlyArray<GQLError<T>>);
    }
  });
};

/**
 * Subscribe function specifically for Integration Connection GQL errors.
 * */
export const subscribeToGQLIntegrationConnectionErrors = (
  subscriber: ErrorSubscriber<IntegrationConnectionError>,
): Subscription => {
  return subscribeToGQLErrors<IntegrationConnectionError>(subscriber, [
    DISTRIBUTION_JOB_ACCOUNT_ERROR,
    OAUTH_ERROR,
  ]);
};

export const hasJobAccountError = (
  errors: readonly GQLError<IntegrationConnectionError>[] | undefined,
): boolean => {
  if (!errors) {
    return false;
  }

  return errors.some(
    (error: GQLError<IntegrationConnectionError>) =>
      error.extensions.type === DISTRIBUTION_JOB_ACCOUNT_ERROR,
  );
};

/**
 * The event emitter function for OAuthErrors, provides stream events to
 * the Observable via its next() function.
 */
export const handleOAuthErrors = (errors: readonly Partial<GraphQLError>[]) => {
  // Filter for unique errors on 'extensions.distributor_id' field; queries
  // may generate multiple OAuth errors for the same distributor connection.
  const uniqueErrors = uniqBy(errors, 'extensions.distributor_id');
  // Transform GQL response type to client type.
  const oAuthErrors = uniqueErrors.map((error) => {
    return {
      message: error.message,
      extensions: {
        type: error.extensions?.type,
        distributorId: error.extensions?.distributor_id,
        distributorDisplayName: error.extensions?.distributor_display_name,
        distributorWebsiteDisplayName:
          error.extensions?.distributor_website_display_name,
        distributorOauthAuthorizationUrl:
          error.extensions?.distributor_oauth_authorization_url,
      },
    } as GQLError<OAuthError>;
  });
  // Emit an OAuthError event on the Observable, containing the array of errors.
  if (errors && errors.length > 0) {
    GQLErrorObservable.next(oAuthErrors);
  }
};

const asyncAuthLink = setContext(
  (request, { headers }) =>
    new Promise((success) => {
      HoverRequest.retrieveToken().then(() => {
        const authHeaders = {
          ...headers,
          'X-JWT-AUTH': `Bearer ${HoverRequest.token}`,
        };

        if (Cookies.get('estimatorTest') === 'true')
          authHeaders['X-SMOKE-TEST'] = true;

        if (request.operationName === 'profile')
          authHeaders['Cache-Control'] = 'no-cache';

        success({
          headers: authHeaders,
        });
      });
    }),
);

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors && graphQLErrors[0]) {
      const graphQLError = graphQLErrors[0];
      if (
        graphQLError.message.includes('Requires authentication') ||
        graphQLError.message.includes('Must be authenticated')
      ) {
        return fromPromise(HoverRequest.retrieveToken({ force: true }))
          .filter((value) => Boolean(value))
          .flatMap((token) => {
            operation.setContext(({ headers = {} }) => ({
              headers: {
                ...headers,
                'X-JWT-AUTH': `Bearer ${token}`,
              },
            }));
            return forward(operation);
          });
      }

      graphQLErrors.forEach((error) => {
        Sentry.withScope((scope) => {
          scope.setTag('kind', operation?.operationName);

          const queryStr = print(operation?.query);

          scope.setContext('GraphQLError', {
            operationName: operation?.operationName,
            query: queryStr,
            variables: operation?.variables,
            errorMessage: error?.message,
            locations: error?.locations,
            extensions: error?.extensions,
          });

          if (!(error instanceof GraphQLError)) {
            scope.setTag(
              'Non-Error-exception',
              'error node is not Error object',
            );
            // @ts-expect-error error provided by gql is not error type
            const errorObject = new GraphQLError(error?.message, {
              // @ts-expect-error error provided by gql is not error type
              ...error,
            });

            Sentry.captureException(errorObject);
          } else {
            Sentry.captureException(error);
          }
        });
      });

      handleOAuthErrors(graphQLErrors);
    }
    if (networkError) {
      Sentry.captureException(networkError);
    }
    return undefined;
  },
);

const operationNameLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    uri: `${appSettings.GRAPHQL_SERVER}/graphql?operation=${operation.operationName}`,
  });
  return forward(operation);
});

const uri = `${appSettings.GRAPHQL_SERVER}/graphql`;
const splitLink = split(
  (operation) => operation.getContext().allowBatching === true,
  new BatchHttpLink({
    uri,
  }),
  createHttpLink({
    uri,
    credentials: 'include',
  }),
);

export const GraphqlClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          estimationConfigTemplates: relayStylePagination(),
          estimationEstimateGroups: relayStylePagination(['jobId', 'orgId']),
          estimationConfigLineItems: relayStylePagination(),
          estimationConfigLineItemVersions: relayStylePagination(),
          estimationConfigRequiredInputs: relayStylePagination(),
          estimationConfigRequiredInputsForTemplates: relayStylePagination(),
          estimationConfigInputCategories: relayStylePagination(),
          productCatalogProducts: relayStylePagination(),
          productCatalogCategories: relayStylePagination(),
          productCatalogMatchGroups: relayStylePagination(),
          projectManagementProposalDocumentsEstimateDetails:
            relayStylePagination(),
          jobs: {
            keyArgs: ['search'],
            merge(existing, incoming) {
              return {
                ...incoming,
                pagination: incoming.pagination,
                // using uniqBy because pagination isn't always perfect and was getting react duplicate key errors
                results: uniqBy(
                  [...(existing?.results ?? []), ...incoming.results],
                  '__ref',
                ),
              };
            },
          },
        },
      },
    },
  }),
  link: from([asyncAuthLink, operationNameLink, errorLink, splitLink]),
  name: 'ehi-frontend',
  version: appSettings.GIT_REVISION || '1.0',
  defaultOptions: {
    query: {
      errorPolicy: 'all',
      fetchPolicy:
        window.Cypress || appSettings.NODE_ENV === 'test'
          ? TEST_DEFAULT_FETCH_POLICY
          : PROD_DEFAULT_FETCH_POLICY,
    },
    watchQuery: {
      errorPolicy: 'all',
      fetchPolicy:
        window.Cypress || appSettings.NODE_ENV === 'test'
          ? TEST_DEFAULT_FETCH_POLICY
          : PROD_DEFAULT_FETCH_POLICY,
      nextFetchPolicy:
        window.Cypress || appSettings.NODE_ENV === 'test'
          ? TEST_DEFAULT_FETCH_POLICY
          : PROD_DEFAULT_FETCH_POLICY,
    },
  },
});
