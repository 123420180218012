import { Box, BoxProps } from '@hover/blueprint';

type Props = BoxProps & { 'data-testid'?: string };

export const EstimatorResponsiveWrapper: React.FC<Props> = ({
  children,
  ...props
}) => (
  <Box width={1} justifyContent="center" flex={1}>
    <Box
      width={{ base: 1, tablet: 0.8, desktop: 0.66 }}
      maxWidth={900}
      padding={{ base: 300, desktop: 500 }}
      flexDirection="column"
      data-testid={props['data-testid'] || 'estimator-responsive-wrapper'}
      {...props}
    >
      {children}
    </Box>
  </Box>
);
