import React, { useCallback, useEffect, useState } from 'react';

import { NetworkStatus, useLazyQuery, useQuery } from '@apollo/client';
import { useBoolean } from '@hover/blueprint';
import { Location } from 'history';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { TradeTypeEnum, JobLabels } from 'src/api/graphql-global-types';
import { GET_TEMPLATE_COLLECTIONS } from 'src/api/queries/queries';
import type {
  productCatalogConfigOrgDistributors as Distributors,
  productCatalogConfigOrgDistributors_productCatalogConfigOrgDistributors as Distributor,
} from 'src/api/types/productCatalogConfigOrgDistributors';
import type {
  projectManagementProductionList_projectManagementProductionList_estimateGroup as EstimateGroup,
  projectManagementProductionList_projectManagementProductionList_listItems_product as Product,
} from 'src/api/types/projectManagementProductionList';
import { messages } from 'src/constants/messages';
import {
  GET_DISTRIBUTORS,
  GET_PRODUCTION_LIST,
} from 'src/features/project/apis/graphql/queries/queries';
import { ProjectScopeContent } from 'src/features/project/components/ProjectScope/ProjectScopeContent';
import { useMaterialListFeature } from 'src/features/project/hooks/useMaterialListFeature';
import {
  ToastStatusEnum,
  useMaterialListTemplates,
  useLocalStorage,
  useToastEhi,
  useTracking,
} from 'src/hooks';
import {
  getMaterialListCreateParam,
  getMaterialListFeature,
  getOrgIdParam,
} from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

import {
  updateBranch,
  updateDistributor,
  updateJobAccount,
} from '../../redux/actions';
import {
  REACT_NATIVE_EVENTS,
  triggerReactNativeEvent,
} from '../../util/nativeMobileEvents';
import { CaptureOnlyAlert } from './CaptureOnlyAlert';
import { GoToTopButton } from './GoToTopButton';
import { useDistributorSelectModal } from './hooks/useDistributorSelectModal';
import { useDownloadPdfModal } from './hooks/useDownloadPdfModal';
import { InsufficientPermissions } from './InsufficientPermissions';
import { TemplateSelectionModal } from './MaterialList/TemplateSelectionModal';
import { MaterialListAlert } from './MaterialListAlert';
import { MeasurementsAlert } from './MeasurementsAlert';
import { ProjectScopeFooter } from './ProjectScopeFooter';

const enum TOAST_IDS {
  GET_PRODUCTION_LIST_ERROR_TOAST,
  GET_TEMPLATE_COLLECTIONS_ERROR_TOAST,
}

export enum DeliverableType {
  UNKNOWN,
  WALL_ONLY,
  ROOF_ONLY,
  COMPLETE,
  NOW,
  TOTAL_LIVING_AREA_PLUS,
  TOTAL_LIVING_AREA,
  CAPTURE_ONLY,
  INTERIOR_FLOOR_PLAN,
  BUILD_ALL,
}

export type AddEditFields = {
  id: string;
  trade: TradeTypeEnum;
  name: string;
  quantityUnits: string;
  quantity: number;
  calculatedQuantity: number;
  color: string | null;
  externalVariationId?: string | null;
  unitCost: number | null;
  totalCost: number;
  customVariant: string;
  sku: string | null;
  productCatalogProductId: string;
  product: Product;
  measurement: number;
  wasteFactor: number;
  type: string;
  userSetCustomColor: boolean;
  requiresProductVariationSelection: boolean;
};
export type AddEditFormData = Record<`${string}`, AddEditFields>;

export const ProjectScope: React.FC = () => {
  const orgId = useSelector(getOrgIdParam);
  const jobId = Number(get(useParams(), 'jobId'));
  const location: Location<{ refetch: boolean }> = useLocation();
  const toast = useToastEhi();
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const [materialListCreate, setMaterialListCreate] = useState<boolean>(
    useSelector(getMaterialListCreateParam),
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const isMaterialListEnabled = useSelector(getMaterialListFeature);
  const [showTemplateSelectionModal, setShowTemplateSelectionModal] =
    useState<boolean>(false);
  const [insufficientPermissions, setInsufficientPermissions] = useState(false);

  const [shouldMeasurementsAlertShows, setShouldMeasurementsAlertShows] =
    useLocalStorage<boolean>(`not-ready-measurmets-alert-${jobId}`, false);

  const [showMeasurementsAlert, setShowMeasurementsAlert] = useBoolean(true);

  const [materialListAlertShow, setMaterialListAlertShow] = useBoolean(false);
  const [captureOnlyAlertShow, setCaptureOnlyAlertShow] = useBoolean(false);

  const [fetchProductionList, { data, loading, refetch, networkStatus }] =
    useLazyQuery(GET_PRODUCTION_LIST, {
      variables: {
        orgId,
        jobId,
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: (error) => {
        if (error.message === 'Must have access to org') {
          setInsufficientPermissions(true);
          return;
        }

        if (
          error.message === 'ActiveRecord::RecordNotFound' &&
          isMaterialListEnabled
        ) {
          // If we can't find an existing material list, show modal to create a new one
          setMaterialListCreate(true);
        } else {
          toast({
            id: TOAST_IDS.GET_PRODUCTION_LIST_ERROR_TOAST,
            description:
              messages.projectScope.errors.query.productionList
                .getProductionList,
            status: ToastStatusEnum.ERROR,
          });
        }
      },
    });

  const estimateGroup: EstimateGroup =
    data?.projectManagementProductionList?.estimateGroup ??
    ({} as EstimateGroup);

  const {
    recreateEstimateGroupWithTemplates,
    isRecreatingMaterialList,
    isDoneRecreatingMaterialList,
    materialListCreateError,
  } = useMaterialListFeature({
    onMaterialListCreateError: () => {
      setShowTemplateSelectionModal(true);
    },
    onMaterialListCreateSuccess: () => {
      const queryParams = new URLSearchParams(location.search);
      queryParams.delete('materialListCreate');
      history.replace({
        search: queryParams.toString(),
      });

      triggerReactNativeEvent(REACT_NATIVE_EVENTS.MATERIAL_LIST_CREATED_EVENT);

      setMaterialListCreate(false);
    },
  });
  const { dispatchTemplatesFromSections } = useMaterialListTemplates();

  // Segment tracking.
  useEffect(() => {
    typewriter.pageViewed({
      page_or_screen_name: EventNames.project.scope.page,
      job_id: jobId,
      ...commonTrackingProps,
    });
    // Track only on page load/view.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Template data fetching for MaterialList Features */
  const [
    fetchTemplateCollections,
    { data: templateCollectionsData, loading: templateCollectionsLoading },
  ] = useLazyQuery(GET_TEMPLATE_COLLECTIONS, {
    variables: {
      orgId,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      toast({
        id: TOAST_IDS.GET_TEMPLATE_COLLECTIONS_ERROR_TOAST,
        description:
          messages.projectScope.errors.query.productionList.estimateTemplates,
        status: ToastStatusEnum.ERROR,
      });
    },
    onCompleted: (completedData) => {
      const {
        estimationConfigTemplateCollectionsForOrg: { templateSections },
      } = completedData;
      dispatchTemplatesFromSections(templateSections);
    },
  });

  const {
    showDownloadPdfModal,
    downloadPdfLineItemType,
    openDownloadPdfModal,
    closeDownloadPdfModal,
  } = useDownloadPdfModal({ jobId });

  useEffect(() => {
    if (!data) {
      return;
    }
    typewriter.pageViewed({
      page_or_screen_name: EventNames.project.scope.dataLoaded,
      job_id: jobId,
      ...commonTrackingProps,
    });
    // Excluded this rule to disable unnecessary dependencies like typewriter,jobId and etc, just to avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    let timeoutId: string | number | NodeJS.Timeout | undefined;

    if (materialListCreate && isMaterialListEnabled) {
      timeoutId = setTimeout(() => {
        setShowTemplateSelectionModal(true);
      }, 1000);
    } else {
      // fetch the prod list normally
      fetchProductionList();
    }

    // Clear timeout on unmount
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
    // Disable this rule just for avoding unnessary dependencies and infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialListCreate]);

  useEffect(() => {
    if (isMaterialListEnabled) {
      fetchTemplateCollections();
    }
  }, [isMaterialListEnabled]);

  useEffect(() => {
    // On revisiting the page, refetch the production list after an order;
    // can't use `refetchQueries` in mutation because polling is required.
    const shouldRefetch = location.state?.refetch ?? false;

    if (shouldRefetch) {
      refetch();
    }
  }, [location, refetch]);

  useEffect(() => {
    const measurementsJobUpdatedAtDate = new Date(
      estimateGroup?.salesOpportunity?.updatedAt,
    );
    const materialListCreatedAtDate = new Date(estimateGroup?.updatedAt);

    if (
      measurementsJobUpdatedAtDate < materialListCreatedAtDate &&
      isMaterialListEnabled
    ) {
      setMaterialListAlertShow.toggle();
    }
    // Added this rule to disable unnecessary dependencies that could cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimateGroup]);

  useEffect(() => {
    const isMeasurmentsJobDone =
      estimateGroup?.salesOpportunity?.job?.labels?.includes(JobLabels.DONE);

    const isMaterialListProcessingComplete =
      data && isMaterialListEnabled && isDoneRecreatingMaterialList;

    if (!isMeasurmentsJobDone && isMaterialListProcessingComplete) {
      setShouldMeasurementsAlertShows(true);
    }

    if (isMeasurmentsJobDone && isMaterialListProcessingComplete) {
      setShouldMeasurementsAlertShows(false);
    }

    // Added this rule to disable unnecessary dependencies like setShowMeasurmentsAlert and etc, just to avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, estimateGroup, materialListCreate]);

  useEffect(() => {
    const deliverableId =
      estimateGroup.salesOpportunity?.job?.deliverable?.id ?? 0;

    if (deliverableId === DeliverableType.CAPTURE_ONLY) {
      setCaptureOnlyAlertShow.toggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, estimateGroup]);

  // Get available distributors for org, for distributor selection menu.
  const {
    data: distributorsData,
    loading: loadingDistributors,
  }: { data?: Distributors; loading: boolean } = useQuery(GET_DISTRIBUTORS, {
    variables: {
      orgId,
    },
    fetchPolicy: 'cache-first', // default
  });

  const distributors = distributorsData?.productCatalogConfigOrgDistributors;

  const {
    showDistributorSelectModal,
    openDistributorSelectModal,
    closeDistributorSelectModal,
  } = useDistributorSelectModal();

  const setDistributor = useCallback(
    (distributor: Distributor | null) => {
      const selectedDistributor = distributor?.distributor || null;
      // Store distributor object in redux.
      dispatch(updateDistributor(selectedDistributor));
      // Clear branch/jobAccount when distributor is changed.
      dispatch(updateBranch(null));
      dispatch(updateJobAccount(null));
      // Navigate to OrderDetail screen for next step.
      history.push(`/project/${jobId}/detail?orgId=${orgId}`);
    },
    [dispatch, history, jobId, orgId],
  );

  // If only one distributor exists, bypass distributor selection.
  const selectDistributor = useCallback(() => {
    if (distributors?.length === 1) {
      setDistributor(distributors[0]);
    } else {
      openDistributorSelectModal();
    }
    // Segment tracking.
    typewriter.buttonPressed({
      button_text: 'Order',
      page_or_screen_name: EventNames.project.scope.page,
      job_id: jobId,
      primary_cta: false,
      ...commonTrackingProps,
    });
  }, [
    distributors,
    jobId,
    openDistributorSelectModal,
    setDistributor,
    commonTrackingProps,
  ]);

  const isLoadingProjectScopeContent =
    loading ||
    loadingDistributors ||
    isRecreatingMaterialList ||
    networkStatus === NetworkStatus.refetch ||
    !data?.projectManagementProductionList?.id ||
    (isMaterialListEnabled && templateCollectionsLoading);

  if (insufficientPermissions) {
    return <InsufficientPermissions jobId={jobId} />;
  }

  return (
    <>
      {isMaterialListEnabled && (
        <TemplateSelectionModal
          isLoadingTemplateCollections={templateCollectionsLoading}
          templateCollections={
            templateCollectionsData?.estimationConfigTemplateCollectionsForOrg
              ?.templateSections
          }
          isOpen={showTemplateSelectionModal}
          onClose={() => {
            setShowTemplateSelectionModal(false);
            recreateEstimateGroupWithTemplates();
          }}
        />
      )}

      <ProjectScopeContent
        showLoader={isLoadingProjectScopeContent}
        showProgressiveLoader={
          (isLoadingProjectScopeContent && isDoneRecreatingMaterialList) ||
          (!!isRecreatingMaterialList && !materialListCreateError)
        }
        data={data}
        estimateGroup={estimateGroup}
        jobId={jobId}
        orgId={orgId}
        distributors={distributors}
        showDownloadPdfModal={showDownloadPdfModal}
        downloadPdfLineItemType={downloadPdfLineItemType}
        openDownloadPdfModal={openDownloadPdfModal}
        closeDownloadPdfModal={closeDownloadPdfModal}
        showDistributorSelectModal={showDistributorSelectModal}
        closeDistributorSelectModal={closeDistributorSelectModal}
        setDistributor={setDistributor}
      />

      {data && (
        <ProjectScopeFooter
          openDownloadPdfModal={openDownloadPdfModal}
          selectDistributor={selectDistributor}
          distributors={distributors}
          listItems={data.projectManagementProductionList.listItems}
        />
      )}

      <GoToTopButton />

      <MeasurementsAlert
        setClose={setShowMeasurementsAlert.off}
        isOpen={
          showMeasurementsAlert &&
          shouldMeasurementsAlertShows &&
          !isLoadingProjectScopeContent
        }
      />
      <MaterialListAlert
        isOpen={materialListAlertShow && !isLoadingProjectScopeContent}
        setClose={setMaterialListAlertShow.off}
      />
      <CaptureOnlyAlert
        isOpen={captureOnlyAlertShow && !isLoadingProjectScopeContent}
        setClose={setCaptureOnlyAlertShow.off}
      />
    </>
  );
};
