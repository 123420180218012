import { findIndex as _findIndex, findLastIndex } from 'lodash';
import queryString from 'query-string';
import { createSelector } from 'reselect';

import { EstimationConfigInputInputTypeEnum } from 'src/api/graphql-global-types';
import { organizeTemplatesByTrade as organizeTemplatesByTradeUtil } from 'src/features/exteriorEstimator/utils/templateUtils';
import { RootState } from 'src/types/reduxStore';

import {
  ADJUST_SIDING_MEASUREMENT,
  MEASUREMENT,
} from '../../../constants/questionCategories';
import { CustomInputTypes } from '../../../types';
import { PartialRoofUtils } from '../../../utils/PartialRoofUtils';

export const getRouter = (state: RootState) => state.router;
export const getError = (state: RootState) => state?.exteriorEstimator.error;

export const getEstimateGroupIdFromLocation = createSelector(
  getRouter,
  (router) => {
    // finds estimateGroupId for routes: estimator/estimates/:estimateGroupId/<subRoute>
    const paths = router.location.pathname.split('/');
    const indexOfEstimates = paths.findIndex((path) => path === 'estimates');
    return paths[indexOfEstimates + 1];
  },
);
export const getUserProfile = (state: RootState) => state?.hover?.userProfile;
export const getParams = (state: RootState) => {
  const parsedQuery = queryString.parse(state?.router?.location.search);
  return Object.entries(parsedQuery).reduce((acc, [key, value]) => {
    if (key.endsWith('[]') && !Array.isArray(value)) {
      acc[key] = [value ?? ''];
    }
    return acc;
  }, parsedQuery);
};
export const getPath = (state: RootState) => state.router.location.pathname;
export const getQuestionResponses = (state: RootState) =>
  state.exteriorEstimator.inputs.questionResponses;

export const getJobIdFromInputs = (state: RootState) =>
  state.exteriorEstimator.inputs.jobId;

export const getTemplates = (state: RootState) =>
  state.exteriorEstimator.config.templates;
export const getTemplateById = (state: RootState, id: number) =>
  state.exteriorEstimator.config.templates?.find((t) => t.id === id);
export const getSelectedTemplateIds = (state: RootState) =>
  state.exteriorEstimator.inputs.selectedTemplates;

export const getSelectedTemplates = (state: RootState) => {
  return state.exteriorEstimator?.config?.templates?.filter((template) =>
    state.exteriorEstimator.inputs.selectedTemplates?.includes(template.id),
  );
};

export const getTemplateSearchFilter = (state: RootState) => {
  return state.exteriorEstimator?.inputs?.templateSearchFilter;
};

export const getOriginalSelectedTemplates = (state: RootState) => {
  return state.exteriorEstimator?.config?.templates?.filter((template) =>
    state.exteriorEstimator.inputs.originalSelectedTemplates?.includes(
      template.id,
    ),
  );
};

export const getSelectedTrades = createSelector(
  getSelectedTemplates,
  (templates) => {
    return [...new Set(templates?.map?.((template) => template.tradeType))];
  },
);

export const getPages = (state: RootState) =>
  state.exteriorEstimator.pages.pages;

export const getMeasurementQuestions = createSelector(getPages, (pages) => {
  return (
    pages?.find?.((page) => page.category === MEASUREMENT)?.questions ?? []
  );
});

export const getAdjustSidingMeasurementQuestions = createSelector(
  getPages,
  (pages) => {
    return (
      pages?.find?.((page) => page.category === ADJUST_SIDING_MEASUREMENT)
        ?.questions ?? []
    );
  },
);

export const getPristineMeasurements = (state: RootState) =>
  state.exteriorEstimator.job.pristineMeasurements;

export const getJob = (state: RootState) => state.exteriorEstimator.job;

export const getPartialsMeasurements = (state: RootState) =>
  state.exteriorEstimator.job.partialsMeasurements;

export const getJobDetails = (state: RootState) =>
  state.exteriorEstimator.job.jobDetails;

export const getModelId = (state: RootState) =>
  state.exteriorEstimator.job.jobDetails?.modelId;

export const getJobDetailSummary = (state: RootState) => ({
  id: state.exteriorEstimator.job.jobDetails?.id,
  name: state.exteriorEstimator.job.jobDetails?.name,
  locationLine1: state.exteriorEstimator.job.jobDetails?.locationLine1,
  locationCity: state.exteriorEstimator.job.jobDetails?.locationCity,
  locationLine2: state.exteriorEstimator.job.jobDetails?.locationLine2,
  locationCountry: state.exteriorEstimator.job.jobDetails?.locationCountry,
  postalCode: state.exteriorEstimator.job.jobDetails?.locationPostalCode,
});

export const getCurrentQuestionCategory = (state: RootState) =>
  state.exteriorEstimator.inputs?.currentQuestionCategory;

export const getCategories = createSelector(getPages, (pages) =>
  pages.map((page) => page.category),
);

export const getCurrentQuestionCategoryIndex = createSelector(
  getCurrentQuestionCategory,
  getPages,
  (category, pages) =>
    pages.findIndex((pageSet) => pageSet.category === category),
);

export const nextRenderablePage = createSelector(
  getCurrentQuestionCategoryIndex,
  getPages,
  (index, pages) => _findIndex(pages, (page) => page.shouldRender, index + 1),
);

export const prevRenderablePage = createSelector(
  getCurrentQuestionCategoryIndex,
  getPages,
  (index, pages) =>
    findLastIndex(pages, (page) => page.shouldRender, index - 1),
);

export const getQuestionIndexByCategoryName = (
  state: RootState,
  category: string,
) =>
  createSelector(getPages, (pages) => {
    return pages.findIndex((page) => page.category === category);
  })(state);

export const getQuestionByCategory = (state: RootState, category: string) =>
  createSelector(getPages, (pages) => {
    const index = getQuestionIndexByCategoryName(state, category);
    return pages[index];
  });

export const getRoofTotalQuestion = createSelector(
  getMeasurementQuestions,
  (questions) =>
    questions
      ? questions.find((question) => question.argument === 'roof_total')
      : null,
);

// gets the generic input for siding_area_total
export const getSidingTotalQuestion = createSelector(
  [getMeasurementQuestions],
  (questions) => {
    return (
      questions?.find(
        (question) => question.argument === 'siding_area_total',
      ) ??
      questions?.find(
        (question) => question.argument === 'siding_with_openings_area_total',
      )
    );
  },
);

export const getSidingWithOpeningsAreaQuestion = createSelector(
  getMeasurementQuestions,
  (questions) => {
    return questions
      ? questions.find(
          (question) => question.argument === 'siding_with_openings_area_total',
        )
      : null;
  },
);

export const getBrickTotalQuestion = createSelector(
  getAdjustSidingMeasurementQuestions,
  (questions) => {
    return questions
      ? questions.find(
          (question) => question.argument === 'brick_with_openings_area',
        )
      : null;
  },
);

export const getStoneTotalQuestion = createSelector(
  getMeasurementQuestions,
  (questions) => {
    return questions
      ? questions.find(
          (question) =>
            question.argument === 'stone_with_openings_area_measurement',
        )
      : null;
  },
);

export const getSidingTotalArea = createSelector(
  getSidingTotalQuestion,
  getQuestionResponses,
  (question, questionResponses) => {
    if (!question) return null;
    return question.id ? questionResponses[question.id] : 0;
  },
);

export const getSidingWithOpeningsTotalArea = createSelector(
  getSidingWithOpeningsAreaQuestion,
  getQuestionResponses,
  (question, questionResponses) => {
    if (!question) return null;
    return question.id ? questionResponses[question.id] : 0;
  },
);

export const getStoneWithOpeningsTotalArea = createSelector(
  getStoneTotalQuestion,
  getQuestionResponses,
  (question, questionResponses) => {
    if (!question) return null;
    return question.id ? questionResponses[question.id] : 0;
  },
);

export const getRoofTotalFromPitches = createSelector(
  getMeasurementQuestions,
  getQuestionResponses,
  (questions, responses) => {
    return questions.reduce((sum, question) => {
      if (PartialRoofUtils.isPitch(question)) {
        const response = responses[question.id];
        return sum + (Number(response) || 0);
      }
      return sum;
    }, 0);
  },
);

export const getQuestionsForCategory = createSelector(
  getCurrentQuestionCategory,
  getPages,
  (category, pages) => {
    const page = pages.find((pageSet) => pageSet.category === category);
    return page?.questions ?? [];
  },
);

export const getIsFinalCategory = createSelector(
  getCurrentQuestionCategory,
  getPages,
  (category, pages) =>
    pages.length ? pages[pages.length - 1].category === category : false,
);

export const getIsFirstCategory = createSelector(
  getCurrentQuestionCategory,
  getPages,
  (category, pages) => (pages.length ? pages[0].category === category : true),
);

export const getCustomLineItems = (state: RootState) =>
  state.exteriorEstimator.inputs.customLineItems;

// change value to 0 if it is an empty string
export const getCleanCustomLineItems = createSelector(
  getCustomLineItems,
  (items) =>
    items
      ? items.map((item) =>
          item.value.length === 0 ? { ...item, value: '0' } : item,
        )
      : [],
);

export const getErrorsFromCustomLineItems = createSelector(
  getCustomLineItems,
  (items) => (items ? items.filter((item) => item.error) : []),
);

export const getIsValidationError = createSelector(
  getErrorsFromCustomLineItems,
  (items) => items.length > 0,
);

export const getAreAnyCheckboxesAnswered = createSelector(
  getCurrentQuestionCategory,
  getPages,
  getQuestionResponses,
  (currentQuestionCategory, pages, questionResponses) => {
    const currentPage = pages.find(
      (question) => question.category === currentQuestionCategory,
    );
    if (!currentPage) return true;
    const checkboxes = currentPage.questions?.filter(
      (question) =>
        question.inputType === CustomInputTypes.TILE_CHECKBOX ||
        question.inputType === EstimationConfigInputInputTypeEnum.BOOLEAN,
    );
    return !checkboxes?.length
      ? true // if there are no checkboxes to answer, all the checkboxes are answered
      : checkboxes?.some(({ id }) => questionResponses[id]);
  },
);

export const organizeTemplatesByTrade = createSelector(
  getTemplates,
  (templates = []) => {
    return organizeTemplatesByTradeUtil(templates);
  },
);

export const getModels = (state: RootState) =>
  state.exteriorEstimator.job.models;

export const getFullMeasurements = (state: RootState) =>
  state.exteriorEstimator.job.fullMeasurements;

export const getHdfMeasurements = (state: RootState) =>
  state.exteriorEstimator.job.hdfMeasurements;

export const getDiscount = (state: RootState, discountId: number) => {
  return state.exteriorEstimator.discounts[discountId];
};
