import { EstimateGroup_estimationEstimateGroup as EstimationEstimateGroup } from 'src/api/types/EstimateGroup';
import {
  JobMeasurements,
  Model,
  Page,
  PristineMeasurement,
  QuestionResponses,
  CustomLineItem,
  ProposalsType,
  PlainMeasurements,
  JobDetails,
  InputCategory,
  RequiredInput,
  WasteFactorRoof,
  Template,
  Discount,
  Checklist,
} from 'src/features/exteriorEstimator/types';
import { Measurements } from 'src/types/EstimationMeasurementTypes';
import {
  HDFMeasurements,
  PartialsMeasurements,
} from 'src/types/HdfMeasurements';

interface EstimateGroup {
  data: EstimationEstimateGroup | null;
  error?: Error | null;
  hasDismissedMissingVariationsWarning: boolean;
}

type keyType = number | string;

export interface InitialState {
  pages: {
    pages: Page[];
    isManualMeasurementsNeeded: boolean;
  };
  config: {
    requiredInputs: RequiredInput[];
    inputCategories: InputCategory[];
    templates: Template[] | null;
    checklist: Checklist | null;
  };
  job: {
    jobDetails?: JobDetails | null;
    jobMeasurements: JobMeasurements | null; // Deprecated with HDF
    models: Model[];
    geometry: object | null;
    metadata: object | null;
    fullMeasurements: Measurements | null; // Deprecated with HDF
    pristineMeasurements: PristineMeasurement[];
    plainMeasurements: PlainMeasurements | null; // Deprecated with HDF
    hdfMeasurements: HDFMeasurements | null;
    partialsMeasurements: PartialsMeasurements | null;
  };
  inputs: {
    questionResponses: QuestionResponses;
    currentQuestionCategory: string | null;
    customLineItems: CustomLineItem[] | null;
    selectedPromotionId?: number | null;
    originalSelectedTemplates: number[] | null;
    selectedTemplates: number[] | null;
    templateSearchFilter: string;
    jobId: number | null;
  };
  discounts: {
    // deprecated
    [key in keyType]: Discount;
  };
  estimateGroup: EstimateGroup;
  error: boolean;
  proposalsData: ProposalsType;
  wasteFactor: {
    predictedWasteFactorRoof?: WasteFactorRoof['predictedWasteFactor'];
  };
}

export const initialState: InitialState = {
  inputs: {
    questionResponses: {},
    currentQuestionCategory: null,
    customLineItems: [{ key: '', value: '' }],
    selectedPromotionId: null,
    originalSelectedTemplates: null,
    selectedTemplates: null,
    templateSearchFilter: '',
    jobId: null,
  },
  pages: {
    pages: [],
    isManualMeasurementsNeeded: false,
  },
  config: {
    templates: [],
    requiredInputs: [],
    inputCategories: [],
    checklist: null,
  },
  job: {
    jobDetails: null,
    jobMeasurements: null,
    models: [],
    geometry: null,
    metadata: null,
    fullMeasurements: null,
    pristineMeasurements: [],
    plainMeasurements: null,
    hdfMeasurements: null,
    partialsMeasurements: null,
  },
  discounts: {},
  estimateGroup: {
    data: null,
    error: null,
    hasDismissedMissingVariationsWarning: false,
  },
  error: false,
  proposalsData: {
    proposals: [],
    isLoadingProposals: true,
    selectedProposalId: null,
    signatureRequest: {},
    error: undefined,
  },
  wasteFactor: {
    predictedWasteFactorRoof: undefined,
  },
};
