import { useEffect } from 'react';

import { Box, Body, Icon, Heading, Link } from '@hover/blueprint';
import { iAlertTriangle } from '@hover/icons';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { useTracking } from 'src/hooks';
import { getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

const ErrorContainer = styled(Box)`
  background-color: ${({ theme }) => theme.colors.grey000};
`;

export const Error = () => {
  const contactSupportText = 'Contact Support';

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  useEffect(() => {
    typewriter.pageViewed({
      page_or_screen_name: EventNames.settings.materialsList.error.page,
      ...commonTrackingProps,
    });
  }, [commonTrackingProps]);

  const handleContactSupport = () => {
    typewriter.linkPressed({
      link_text: contactSupportText,
      page_or_screen_name: EventNames.settings.materialsList.error.page,
      primary_cta: false,
      ...commonTrackingProps,
    });
  };

  return (
    <ErrorContainer
      data-testid="MaterialListError"
      padding={700}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Icon size="large" icon={iAlertTriangle} color="neutral600" />
      <Heading size={400} marginTop={400}>
        Product Catalog Not Found
      </Heading>
      <Body size={400} margin={0} color="neutral600">
        To resolve this issue, please contact HOVER support
      </Body>
      <Link href="https://hover.to/contact/" onClick={handleContactSupport}>
        <Body size={500} color="primary600" margin={0} marginTop={700}>
          {contactSupportText}
        </Body>
      </Link>
    </ErrorContainer>
  );
};
