import { PureComponent } from 'react';

import { TextInput } from '@hover/blueprint';
import autobind from 'autobind-decorator';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import styled from 'styled-components';

import { formatUnitCost } from 'src/features/projectManagement/utils/ProductionListUtils';
import { RootAction } from 'src/types/reduxStore';

import * as EstimatorProductionActions from '../../../redux/actions';

const Container = styled.div`
  position: relative;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 1em;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 1;
`;

interface FormattedCostInputWithSaveOnBlurProps {
  dataTest: string;
  fieldName: string;
  listItemId: number;
  format?: string;
  trackInput: (fieldName: string, targetValue: string) => void;
}

export const mapDispatchToProps = (dispatch: Dispatch<RootAction>) =>
  bindActionCreators(
    {
      updateListItem: EstimatorProductionActions.updateListItem,
    },
    dispatch,
  );

type Props = FormattedCostInputWithSaveOnBlurProps &
  ReturnType<typeof mapDispatchToProps> &
  React.HTMLProps<HTMLInputElement>;

export class FormattedCostInputWithSaveOnBlurComponent extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    const { value } = this.props;
    this.state = {
      value: Number(value).toFixed(2),
      error: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { value } = this.props;
    if (value !== prevProps.value) {
      // eslint-disable-next-line
      this.setState({ value: formatUnitCost(value) });
    }
  }

  @autobind
  public onFieldBlur(fieldName: string) {
    return (event: React.FormEvent<HTMLInputElement>) => {
      const { error, value } = this.state;
      if (error) return;
      this.setState({ value: formatUnitCost(value) });
      const { listItemId, updateListItem, trackInput } = this.props;
      const targetValue = event.currentTarget.value;
      const params = { [fieldName]: targetValue };
      if (fieldName === 'unitCost') {
        params.userSetCustomUnitCost = true;
        trackInput(fieldName, targetValue);
      }
      updateListItem(listItemId, params);
    };
  }

  @autobind
  handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    const error = !value;
    this.setState({ value, error });
  }

  public render() {
    // Need to filter out listItemId, updateListItem, and trackInput from the TextInput props.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {
      dataTest,
      fieldName,
      listItemId,
      trackInput,
      updateListItem,
      ...props
    } = this.props;
    const { value, error } = this.state;

    return (
      <Container>
        <IconWrapper>$</IconWrapper>
        <TextInput
          {...props}
          fontFamily="Gotham Book, Helvetica Neue, Arial, sans-serif"
          fontSize="0.75rem"
          textAlign="end"
          size="tiny"
          onChange={this.handleChange}
          onBlur={this.onFieldBlur(fieldName)}
          isInvalid={error}
          value={value}
          data-testid={dataTest}
        />
      </Container>
    );
  }
}

export const FormattedCostInputWithSaveOnBlur = connect(
  null,
  mapDispatchToProps,
)(FormattedCostInputWithSaveOnBlurComponent);
