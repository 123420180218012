/* eslint-disable no-console */
import { upperCase } from 'lodash';
import pluralize from 'pluralize';

import { EHI_ESTIMATOR_DEBUG_KEY } from 'src/constants';
import { facadesSortOrderByType as sidingPrefixes } from 'src/features/exteriorEstimator/constants/SidingPartialSortOrder';
import {
  ClassificiationFacade,
  GeometryEdge,
  QuestionResponses,
} from 'src/features/exteriorEstimator/types';
import {
  COMMERCE_BRICK_AND_STONE_ESTIMATION,
  isEnabled,
} from 'src/lib/FeatureFlag';
import { EhiOrgSettings } from 'src/redux/reducers/ehiReducer';
import { HDFMeasurements } from 'src/types/HdfMeasurements';

import { FACADE_TYPES } from '../constants/sidingConstants';
import PartialSidingUtils from './PartialSidingUtils';
import { WindowsForFacade } from './PartialsMeasurementsMap';

interface GeometryEdgeWithEdgeId extends GeometryEdge {
  edgeId: string;
}

interface EdgesForFacade {
  [facadeDisplayLabel: string]: string[];
}

interface EdgeTotals {
  // ie ridge_total, ridge_count
  [key: string]: number;
}

export class LineSegmentCalculatorHdf {
  public windowsForFacade: WindowsForFacade = {};

  public edges: GeometryEdge[] = [];

  public facades: ClassificiationFacade[] = [];

  /**
   * @example
   * calculatedEdgeTotals
   * {
   *   rake_total: 348.0338102558,
   *   rake_count: 2,
   *   ridge_total: 468.8603444867,
   *   ridge_count: 1
   * }
   */
  public calculatedEdgeTotals: EdgeTotals = {};

  private edgesForFacade: EdgesForFacade = {};

  private questionResponses: QuestionResponses = {};

  private hdfMeasurements: HDFMeasurements | null = null;

  public selectedSidingFacades: string[] = [];

  private orgSettings: EhiOrgSettings;

  public selectedAreaWithTrim = 0;

  public isStone = false;

  constructor({
    edges,
    facades,
    windowsForFacade,
    questionResponses,
    hdfMeasurements,
    orgSettings,
    isStone,
  }: {
    edges: GeometryEdge[];
    facades: ClassificiationFacade[];
    windowsForFacade: WindowsForFacade;
    questionResponses: QuestionResponses;
    hdfMeasurements: HDFMeasurements | null;
    orgSettings: EhiOrgSettings;
    isStone: boolean;
  }) {
    this.edges = edges;
    this.facades = facades;
    this.windowsForFacade = windowsForFacade;
    this.hdfMeasurements = hdfMeasurements;
    this.orgSettings = orgSettings;
    this.questionResponses = questionResponses;
    this.isStone = isStone;
    this.edgesForFacade = this.createEdgesForFacade();
    this.calculatedEdgeTotals = this.calculateEdgeTotals();
    this.selectedSidingFacades = this.getSelectedSidingFacades();
    this.selectedAreaWithTrim = this.getSelectedAreaWithTrim();
  }

  private createEdgesForFacade() {
    const edgesForFacade = this.facades.reduce<EdgesForFacade>(
      (_edgesForFacade, facade) => {
        const eff = { ..._edgesForFacade };
        eff[facade.display_label] = facade.edges;
        return eff;
      },
      {},
    );
    return edgesForFacade;
  }

  public calculateEdgeTotals() {
    const isEstimatorDebugOn =
      localStorage.getItem(EHI_ESTIMATOR_DEBUG_KEY) === 'on';

    // first initialize all the edgeTotals with 0's
    const edgeTotals = this.edges.reduce<EdgeTotals>((_map, { type }) => {
      const map = { ..._map };
      const typeKey =
        type === 'inside_corner' ||
        type === 'outside_corner' ||
        type === 'rake' ||
        type === 'eave'
          ? pluralize(type)
          : type;
      const typeCountKey = `${typeKey}_count`; // ie ridge_count
      const typeTotalKey = `${typeKey}_total`; // ie ridge_total
      if (!map[typeCountKey]) {
        map[typeTotalKey] = 0;
        map[typeCountKey] = 0;
      }
      return map;
    }, {});

    const originalSummableEdgesForWall = this.getSummableEdgesForWall();
    const originalSummableEdgesForRoof = this.getSummableEdgesForRoof();

    // dedupe edges that appear in both roof and wall facets
    const commonEdges = originalSummableEdgesForWall
      .map((geometryEdgeWithEdgeId) => geometryEdgeWithEdgeId.edgeId)
      .filter((value) =>
        originalSummableEdgesForRoof
          .map((geometryEdgeWithEdgeId) => geometryEdgeWithEdgeId.edgeId)
          .includes(value),
      );

    // if there are edges that appear in both roof and wall objects, the one attached to the face that comes first alphabetically should remain
    // e.g. an edge is both on SI-1 (from wallEdges) and RF-2 (from roofEdges), in this case RF-2 comes first, so the one in walledges should be removed
    // this maintains parity with estimation_json where the deduping was based on which face made it into the array first, and the faces were sorted alphabetically

    const newSummableEdgesForWall = [...originalSummableEdgesForWall];
    const newSummableEdgesForRoof = [...originalSummableEdgesForRoof];

    commonEdges.forEach((commonEdgeId) => {
      const edgeInRoof = newSummableEdgesForRoof.find(
        (edge) => edge.edgeId === commonEdgeId,
      );
      const edgesInWall = newSummableEdgesForWall.filter(
        (edge) => edge.edgeId === commonEdgeId,
      );
      edgesInWall.forEach((edgeInWall) => {
        // dont de-dupe if the edge types are different
        if (edgeInWall.type !== edgeInRoof?.type) return;

        const facadeForRoof = edgeInRoof?.classifiedInContext?.faceId?.name;
        const facadeForWall = edgeInWall?.classifiedInContext?.faceId?.name;

        // alphabetic comparison between the facades
        if (facadeForRoof && facadeForWall && facadeForRoof < facadeForWall) {
          // delete the common element from wall edges
          const index = newSummableEdgesForWall.findIndex(
            (edge) => edge === edgeInWall,
          );
          newSummableEdgesForWall.splice(index, 1);
        } else {
          // delete the common element from roof edges
          const index = newSummableEdgesForRoof.findIndex(
            (edge) => edge === edgeInWall,
          );
          newSummableEdgesForRoof.splice(index, 1);
        }
      });
    });

    const usingSidingTemplate = Object.keys(this.questionResponses).some(
      (key) => {
        let isSidingPrefix = false;
        sidingPrefixes.forEach((prefix) => {
          if (key.startsWith(prefix)) isSidingPrefix = true;
        });
        return isSidingPrefix;
      },
    );
    const usingRoofTemplate = Object.keys(this.questionResponses).some((key) =>
      key.startsWith('RF'),
    );

    let combinedSummableEdges: (GeometryEdge | GeometryEdgeWithEdgeId)[] = [];
    if (usingSidingTemplate)
      combinedSummableEdges = [
        ...combinedSummableEdges,
        ...newSummableEdgesForWall,
      ];

    if (usingRoofTemplate)
      combinedSummableEdges = [
        ...combinedSummableEdges,
        ...newSummableEdgesForRoof,
      ];

    // combine the deduped wallEdge and roofEdge objects and sum up each type up (in feet)
    combinedSummableEdges.forEach(({ type, length = 0, source }) => {
      const typeKey =
        (source === 'wall' && (type === 'eave' || type === 'rake')) || // eaves_total/rakes_total denotes a siding edge, eave_total/rake_total denotes roof edge
        type === 'inside_corner' ||
        type === 'outside_corner'
          ? pluralize(type)
          : type;
      const typeCountKey = `${typeKey}_count`; // ie ridge_count
      const typeTotalKey = `${typeKey}_total`; // ie ridge_total
      if (!edgeTotals[typeTotalKey]) edgeTotals[typeTotalKey] = 0;
      if (!edgeTotals[typeCountKey]) edgeTotals[typeCountKey] = 0;
      edgeTotals[typeTotalKey] += length / 12;
      edgeTotals[typeCountKey] += 1;
    });

    edgeTotals.window_count_total = this.getWindowCount();
    edgeTotals.level_frieze_board_length_total =
      this.getLevelFriezeTotalLength();
    edgeTotals.level_transition_total = this.getLevelTransitions();

    if (isEstimatorDebugOn) {
      /* eslint-disable no-console */

      console.log('all edges:', this.edges);
      console.log('summableEdgesForWall:', originalSummableEdgesForWall);
      console.log('deduped summableEdgesForWall:', newSummableEdgesForWall);

      console.log('summableEdgesForRoof:', originalSummableEdgesForRoof);
      console.log('deduped summableEdgesForRoof:', newSummableEdgesForRoof);
      console.log('edgetotals', edgeTotals);
      /* eslint-enable no-console */
    }
    return edgeTotals;
  }

  /**
   * returns a map of edges which can be summed
   * @example
   * getSummableEdges()
   * {
   *   'E-1': { type: 'rake', length: 10 ... },
   *   'E-2': { type: 'ridge', length: 20 ... },
   * }
   */
  public getSummableEdgesForWall() {
    const wallEdges = this.edges.filter((ed) => ed.source === 'wall');

    // for siding edges, we remove trim on non-siding facets, because otherwise it will not match the measurement
    const nonSidingEdgesToRemove = [
      'opening_sides',
      'opening_bottoms',
      'opening_tops',
      'sloped_bases',
      'perimeter',
      'level_bases',
      'non_siding_level_bases',
      'non_siding_perimeter',
    ];

    return Object.entries(this.getSummableEdgesForFacade()).reduce<
      GeometryEdgeWithEdgeId[]
    >((acc, [facade, edges]) => {
      // for each array of edgeIds attached to a selected facade
      edges.forEach((edgeId) => {
        // find the corresponding walledge
        // we currently do not handle cornice edges
        // if there is no corresponding edge found, it is a roofEdge
        const wallEdgesFound = wallEdges.filter(
          (ed) =>
            (ed.id === edgeId ||
              ed.classifiedInContext?.edgeId?.uuid === edgeId) &&
            ed.classifiedInContext?.faceId?.name === facade,
        );

        // add it to the object only once, so that in case there are multiple faces that share the same edge, it doesn't get added multiple times
        // dedupe by both edgeId and wallEdge.id, but edges with two different types will not be deduped
        wallEdgesFound.forEach((wallEdge) => {
          let isNonSidingFacadeAndIsEdgeToRemove =
            !facade.startsWith('SI-') &&
            wallEdges.length &&
            nonSidingEdgesToRemove.includes(wallEdge.type);

          if (isEnabled(COMMERCE_BRICK_AND_STONE_ESTIMATION) && this.isStone) {
            isNonSidingFacadeAndIsEdgeToRemove = false;
          }

          if (
            wallEdge &&
            acc.filter(
              (ed) => ed.id === wallEdge.id && ed.type === wallEdge.type,
            ).length === 0 &&
            !isNonSidingFacadeAndIsEdgeToRemove
          ) {
            acc.push({ ...wallEdge, edgeId });
          }
        });
      });
      return acc;
    }, []);
  }

  public getSummableEdgesForRoof() {
    const roofEdges = this.edges.filter((ed) => ed.source === 'roof');

    return Object.entries(this.getSummableEdgesForFacade()).reduce<
      GeometryEdgeWithEdgeId[]
    >((acc, [facade, edges]) => {
      // for each array of edgeIds attached to a selected facade
      edges.forEach((edgeId) => {
        // find the corresponding roofEdge
        // if there is no corresponding edge found, it means it was a wallEdge
        const roofEdge = roofEdges.find((ed) => ed.id === edgeId);

        // add it to the object only once, so that in case there are multiple faces that share the same edge, it doesn't get added multiple times
        if (!acc.find((edge) => edge.edgeId === edgeId) && roofEdge) {
          const newRoofEdge = {
            ...roofEdge,
            classifiedInContext: {
              faceId: {
                name: facade,
                sortIndex: 0,
                suPersistentId: 0,
                uuid: '',
              },
            },
            edgeId,
          };
          acc.push(newRoofEdge);
        }
      });
      return acc;
    }, []);
  }

  /**
   * returns a edgesForFacade map only including facades that are currently selected
   */
  public getSummableEdgesForFacade() {
    return Object.entries(this.edgesForFacade).reduce<EdgesForFacade>(
      (_edgesForFacade, [facadeDisplayLabel, edges]) => {
        const edgesForFacade = { ..._edgesForFacade };
        // if the facade is selected
        if (this.questionResponses[facadeDisplayLabel]) {
          const filteredEdges = edges;
          edgesForFacade[facadeDisplayLabel] = filteredEdges;
        }
        return edgesForFacade;
      },
      {},
    );
  }

  public getLevelFriezeTotalLength() {
    // sum up all the lengths of wall edges of type "eave"
    const levelFriezeEdgesSum = Object.values(
      this.getSummableEdgesForWall(),
    ).reduce((sum, edge) => {
      if (edge.type === 'eave') {
        return sum + edge.length;
      }
      return sum;
    }, 0);

    return levelFriezeEdgesSum / 12;
  }

  public getLevelTransitions() {
    return Object.values(this.getSummableEdgesForWall()).reduce((acc, edge) => {
      if (edge.type === 'level_transition') {
        return acc + edge.length / 12;
      }
      return acc;
    }, 0);
  }

  private getWindowCount() {
    return Object.entries(this.questionResponses).reduce<number>(
      (count, [id, isSelected]) => {
        const windowsForFacade = this.windowsForFacade[id];
        const len = windowsForFacade?.length;

        if (isSelected && len) {
          return count + len;
        }
        return count;
      },
      0,
    );
  }

  private getSelectedSidingFacades() {
    return Object.entries(this.questionResponses).reduce<string[]>(
      (acc, [id, isSelected]) => {
        if (FACADE_TYPES[upperCase(id.split('-').shift())] && isSelected) {
          acc.push(id);
        }
        return acc;
      },
      [],
    );
  }

  private getSelectedAreaWithTrim() {
    const facadesWithOpenings = this.hdfMeasurements?.combinedAreaWithTrim;
    const facadesWithoutOpenings =
      this.hdfMeasurements?.combinedAreaWithTrimNoOpenings;

    const facades = PartialSidingUtils.shouldCalculateWithOpenings(
      this.orgSettings,
    )
      ? facadesWithOpenings
      : facadesWithoutOpenings;

    if (!facades) return 0;

    return Object.entries(facades)
      .filter(([id]) => this.selectedSidingFacades.includes(id))
      .reduce<number>((acc, [, val]) => acc + val.combinedArea, 0);
  }
}
