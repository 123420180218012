import { Body, Radio } from '@hover/blueprint';
import type { RadioProps } from '@hover/blueprint/chakra';

import { getIsChecked } from '../utils/radioUtils';

export interface RadioButtonProps extends RadioProps {
  label: string;
  width?: number | string;
  selectedValue?: RadioProps['value'];
  'data-testid'?: string;
}

export const RadioButton: React.FC<RadioButtonProps> = ({
  width = 190,
  selectedValue,
  isChecked,
  value,
  label,
  ...props
}) => {
  const checked = getIsChecked({ checked: isChecked, selectedValue, value });

  return (
    <Radio
      data-testid={props['data-testid'] || 'radio-button'}
      {...props}
      isChecked={checked}
      value={value}
      width={width}
    >
      <Body margin={0} size={500} color="neutral700">
        <strong>{label}</strong>
      </Body>
    </Radio>
  );
};
