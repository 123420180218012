/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable camelcase */
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import {
  updateInputForTemplateStore,
  addFacetsToTemplates,
  initializePitchAndLineSegmentInputsOnTemplates,
} from 'src/features/projectEstimator/hooks/utils/templateStoreUtils';
import {
  TemplatesState,
  ProjectEstimatorFacets,
  TemplateStore,
} from 'src/features/projectEstimator/types';

import { createPagesFromTemplates } from './utils/pageStoreUtils';

export const useTemplateStore = create<TemplateStore>()(
  devtools(
    (set) => ({
      templates: {},
      setTemplatesFacetsAndFacetMeasurements: ({
        templates,
        hdf,
        tradeTypesSorted,
        orgSettings,
      }) =>
        set(
          (state) => {
            const newTemplates = addFacetsToTemplates(
              hdf,
              templates,
              tradeTypesSorted,
              orgSettings,
            );

            const newTemplatesWithUpdatedInputs =
              initializePitchAndLineSegmentInputsOnTemplates(
                newTemplates,
                hdf,
                orgSettings,
              );

            return { ...state, templates: newTemplatesWithUpdatedInputs };
          },
          false,
          { type: 'setTemplatesFacetsAndFacetMeasurements', hdf, templates },
        ),

      updateInput: ({ inputId, templateId, value }) =>
        set(
          (state) => {
            const newState = updateInputForTemplateStore({
              state,
              inputId,
              templateId,
              value,
            });

            return newState;
          },
          false,
          { type: 'updateInput', inputId, templateId, value },
        ),
      updateFacets: ({ facets, templateId }) =>
        set(
          (state) => {
            // first find the template in the store with this templateId and update the facets checked value that match the facets in this action
            const template = state.templates[templateId];
            if (!template) {
              return { ...state };
            }
            const newFacets = Object.entries(
              template?.facets ?? {},
            ).reduce<ProjectEstimatorFacets>((acc, [facetId, facet]) => {
              const facetFromAction = facets[facetId];
              if (facetFromAction) {
                acc[facetId] = {
                  ...facet,
                  checked: facetFromAction.checked,
                };
              } else {
                acc[facetId] = facet;
              }
              return acc;
            }, {});
            // then for all the other templates in the store, set the facets that match the facets in this action to false
            const otherTemplates = Object.entries(
              state.templates,
            ).reduce<TemplatesState>((acc, [_templateId, template]) => {
              if (_templateId !== templateId.toString()) {
                const templateFacets = template?.facets ?? {};
                Object.values(facets).forEach((facet) => {
                  if (facet.checked) {
                    templateFacets[facet.facetDisplayLabel].checked = false;
                  }
                });
                return {
                  ...acc,
                  [_templateId]: {
                    ...template,
                    facets: templateFacets,
                  },
                };
              }
              return acc;
            }, {});

            return {
              ...state,
              templates: {
                ...otherTemplates,
                [templateId]: { ...template, facets: newFacets },
              },
            };
          },
          false,
          { type: 'updateFacets', facets, templateId },
        ),
      reset: () => set({ templates: {}, pages: [] }, false, { type: 'reset' }),
      pages: [],
      setPages: ({ templates, tradeTypesSorted, isRecalculateFlow }) =>
        set(
          (state) => {
            return {
              ...state,
              pages: createPagesFromTemplates({
                templates,
                tradeTypesSorted,
                isRecalculateFlow,
              }),
            };
          },
          false,
          { type: 'setPages' },
        ),
    }),
    { name: 'project-estimator-template-store' },
  ),
);
