import { useEffect, useMemo } from 'react';

import { toNumber, isEmpty } from 'lodash';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  getTradeTypesSorted,
  getUserOrgId,
  getOrgSettings,
} from 'src/redux/selectors';

import { useGetHdf } from './useGetHdf';
import { useGetJobDetails } from './useGetJobDetails';
import { useGetRequiredInputs } from './useGetRequiredInputs';
import { useTemplateStore } from './useTemplateStore';
import { setTemplateStoreAndMeasurementValuesFromRequiredInputs } from './utils/templateStoreUtils';

export const useInitEstimator = () => {
  const location = useLocation();
  const orgId = useSelector(getUserOrgId);
  const queryParams = queryString.parse(location.search, {
    arrayFormat: 'comma',
  });
  const selectedTemplateIds = queryParams?.templateIds as string[] | string;
  const jobId = toNumber(queryParams?.jobId);
  const { returnTo } = queryString.parse(location.search);
  const isRecalculateFlow = !!(returnTo && returnTo !== '');

  const setTemplatesFacetsAndFacetMeasurements = useTemplateStore(
    (state) => state.setTemplatesFacetsAndFacetMeasurements,
  );
  const tradeTypesSorted = useSelector(getTradeTypesSorted);
  const orgSettings = useSelector(getOrgSettings);

  const setPages = useTemplateStore((state) => state.setPages);
  const templatesFromStore = useTemplateStore((state) => state.templates);
  const areTemplatesFacetsEmpty = useMemo(() => {
    return Object.values(templatesFromStore).every((template) => {
      return isEmpty(template.facets);
    });
  }, [templatesFromStore]);

  const { data: jobDetails, loading: loadingJobDetails } = useGetJobDetails({
    jobId,
  });
  const { inputs, error: getRequiredInputsError } = useGetRequiredInputs({
    jobId: jobId?.toString(),
    templateIds: Array.isArray(selectedTemplateIds)
      ? selectedTemplateIds
      : [selectedTemplateIds],
    orgId,
  });

  const {
    data: hdf,
    loading: loadingHdf,
    error: loadingHdfError,
  } = useGetHdf({ jobId });

  const isLoading = loadingJobDetails || !inputs || loadingHdf;
  const hasEverything = !!jobDetails && !isEmpty(inputs) && !!hdf;

  useEffect(() => {
    if (
      hdf &&
      hasEverything &&
      areTemplatesFacetsEmpty &&
      isEmpty(templatesFromStore)
    ) {
      if (!inputs) return;

      const newTemplates =
        setTemplateStoreAndMeasurementValuesFromRequiredInputs(inputs, hdf);

      if (newTemplates) {
        setTemplatesFacetsAndFacetMeasurements({
          templates: newTemplates,
          hdf,
          tradeTypesSorted,
          orgSettings,
        });
        setPages({
          templates: newTemplates,
          tradeTypesSorted,
          isRecalculateFlow,
        });
      }
    }
  }, [
    hdf,
    hasEverything,
    templatesFromStore,
    setTemplatesFacetsAndFacetMeasurements,
    setPages,
    inputs,
    areTemplatesFacetsEmpty,
    tradeTypesSorted,
    orgSettings,
    isRecalculateFlow,
  ]);

  return {
    isLoading,
    hasEverything,
    jobDetails,
    jobId,
    orgId,
    hdf,
    getRequiredInputsError,
    loadingHdfError,
  };
};
