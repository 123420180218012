import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  Body,
  Box,
  BoxProps,
  Field,
  Image,
  Link,
  LoadingOverlay,
  Select,
  Table,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@hover/blueprint';
import { isNil, map, omit } from 'lodash';
import pluralize from 'pluralize';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { HashLink as RouterLink } from 'react-router-hash-link';

import { LineItemTypeEnum, TradeTypeEnum } from 'src/api/graphql-global-types';
import {
  distributionApprovedBranchesQuery_distributionApprovedBranches as Branch,
  distributionApprovedBranchesQuery_distributionApprovedBranches_jobAccounts as JobAccount,
} from 'src/api/types/distributionApprovedBranchesQuery';
import type {
  distributionOrderCheck as OrderCheckData,
  distributionOrderCheck_distributionOrderCheck as OrderCheck,
  distributionOrderCheck_distributionOrderCheck_lineItems as OrderCheckLineItem,
} from 'src/api/types/distributionOrderCheck';
import type { distributionOrderCheckCreate as DistributionOrderCheckCreate } from 'src/api/types/distributionOrderCheckCreate';
import { estimationQuantityUnits } from 'src/api/types/estimationQuantityUnits';
import type { productCatalogConfigOrgDistributors_productCatalogConfigOrgDistributors_distributor as Distributor } from 'src/api/types/productCatalogConfigOrgDistributors';
import { productCatalogDistributorCapabilities } from 'src/api/types/productCatalogDistributorCapabilities';
import type { projectManagementProductionList_projectManagementProductionList_listItems as ListItem } from 'src/api/types/projectManagementProductionList';
import { NavButton } from 'src/components/blueprint';
import { messages } from 'src/constants/messages';
import {
  DISTRIBUTOR_CAPABILITIES,
  ESTIMATION_QUANTITY_UNITS,
  ORDER_CHECK,
  ORDER_CHECK_CREATE,
} from 'src/features/project/apis/graphql/queries/queries';
import {
  getDistributionOrderCheck,
  getProductionList,
} from 'src/features/project/apis/graphql/services';
import type { AddEditFields } from 'src/features/project/components/common/AddEditMaterialModal';
import { AddEditMaterialModal } from 'src/features/project/components/common/AddEditMaterialModal';
import { ItemErrorIcon } from 'src/features/project/components/common/CommonIcons';
import { ProjectOrderNavbar } from 'src/features/project/components/common/Navbar';
import { OrderDetailListItem } from 'src/features/project/components/OrderDetail/OrderDetailListItem';
import { CUSTOM_VARIANT_COLOR } from 'src/features/project/components/ProjectScope/EditableVariationSelection';
import {
  updateBranch,
  updateJobAccount,
} from 'src/features/project/redux/actions';
import {
  getBranch,
  getDistributor,
  getJobAccount,
  getSelectedListItemIds,
} from 'src/features/project/redux/selectors/projectSelectors';
import {
  ListItemsByTrade,
  OrderCheckLineItemWithTradeType,
  OrderLineItemsByTrade,
} from 'src/features/project/types';
import {
  getAttributesFromListItems,
  getListItemsByTrade,
  hasJobAccountForOrder,
  lineItemsWithOmittedAttributes,
} from 'src/features/project/util/utils';
import { ToastStatusEnum, useToastEhi, useTracking } from 'src/hooks';
import { getOrgIdParam, getTradeTypesSorted } from 'src/redux/selectors';
import { EventNames } from 'src/types';
import { bySortOrderAndCreatedAtComparator } from 'src/utils/comparators';
import { sentenceCase } from 'src/utils/Formatters';

const enum TOAST_IDS {
  GET_QUANTITY_UNITS_TOAST,
  ORDER_CHECK_CREATE_TOAST,
  ORDER_CHECK_POLL_TOAST,
}

type OrderDetailsControlsProps = {
  jobId: number;
  orgId: string;
  orderCheckId: string;
  isSubmittable: boolean;
  orderCheckLineItems?: OrderCheckLineItem[];
};
const OrderDetailsControls: React.FC<OrderDetailsControlsProps & BoxProps> = ({
  jobId,
  orgId,
  orderCheckId,
  isSubmittable = false,
  orderCheckLineItems = [],
  ...props
}) => {
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  // Segment tracking.
  const trackOrderButton = useCallback(() => {
    typewriter.buttonPressed({
      button_text: 'Continue to billing & delivery',
      page_or_screen_name: EventNames.project.orderDetails.page,
      primary_cta: false,
      job_id: jobId,
      ...commonTrackingProps,
    });
  }, [jobId, commonTrackingProps]);

  // // Keep count of blocking errors preventing user from navigating to Billing & Delivery
  const failureCount: number = useMemo(() => {
    return orderCheckLineItems.filter((item) => item.checkResult === 'FAILURE')
      .length;
  }, [orderCheckLineItems]);

  return (
    <Box
      id="OrderDetail-controls"
      alignItems="baseline"
      padding={500}
      flexDirection="row"
      justifyContent="space-between"
      backgroundColor="neutral0"
      marginTop="6px"
      boxShadow="0px 2px 8px rgba(0, 0, 0, .08);"
      {...props}
    >
      {failureCount > 0 && (
        <Box
          marginLeft={400}
          paddingY={300}
          paddingX={400}
          backgroundColor="danger100"
          borderRadius={200}
          alignItems="center"
        >
          <ItemErrorIcon />
          <Body as="span" color="neutral700" marginLeft={200}>
            {`${failureCount} ${pluralize('issue', failureCount)} found`}
          </Body>
        </Box>
      )}
      <Box flex={1} aria-hidden="true" />
      <Box justifyContent="flex-end">
        <Box>
          <Body margin={0} fontSize={300} color="neutral.600">
            Pricing subject to change before invoicing
          </Body>
          <Box color="neutral.500" lineHeight={700} marginLeft={700} />
        </Box>
        <Box>
          <NavButton
            data-testid="OrderDetail-Actions-Order"
            isDisabled={!isSubmittable}
            to={`/project/${jobId}/checkout/${orderCheckId}?orgId=${orgId}`}
            onClick={trackOrderButton}
          >
            Continue to billing &amp; delivery
          </NavButton>
        </Box>
      </Box>
    </Box>
  );
};

export type Attributes = {
  measurement: number | null;
  measurementUnits: string | null;
  productId: string | null;
  productName: string;
  quantity: number;
  quantityUnits: string | null;
  requiresProductVariationSelection: boolean;
  sku: string | null;
  tradeType: TradeTypeEnum;
  unitCost: number;
  userSetCustomSku: boolean;
  userSetCustomVariationName: boolean;
  variationId: string | null;
  variationName: string | null;
  sortOrder: number | null;
  clientIdentifier: string | null; // id for correlation of distributionOrderLineItem to productionListItem.
};

type Props = {
  branches: Branch[] | undefined;
  loadingBranches: boolean;
  jobId: number;
};

export const OrderDetailContent: React.FC<Props> = ({
  branches,
  loadingBranches,
  jobId,
}) => {
  const apolloClient = useApolloClient();
  const dispatch = useDispatch();
  // Select only the MATERIALS type from the selectedListItems.
  const listItemIds = useSelector(getSelectedListItemIds)?.[
    LineItemTypeEnum.MATERIAL
  ];
  const distributor = useSelector(getDistributor);
  const branch = useSelector(getBranch);
  const jobAccount = useSelector(getJobAccount);
  const orgId = useSelector(getOrgIdParam);
  const { orderCheckId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const tradeTypesSorted = useSelector(getTradeTypesSorted);
  const toast = useToastEhi();

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  // If user re-loads page we can't proceed, because this view requires selection
  // of a distributor from previous page.
  if (isNil(distributor)) {
    history.replace(`/project/${jobId}/scope?orgId=${orgId}`);
  }

  /**
   * Add/Edit Material
   */
  const [showAddEditMaterialModal, setShowAddEditMaterialModal] =
    useState<boolean>(false);
  const [selectedEditListItem, setSelectedEditListItem] =
    useState<OrderCheckLineItem | null>(null);

  const closeAddEditMaterialModal = useCallback(() => {
    setSelectedEditListItem(null);
    setShowAddEditMaterialModal(false);
  }, [setShowAddEditMaterialModal]);

  // Segment tracking.
  const trackEditItemButton = useCallback(
    (itemType: string) => {
      typewriter.buttonPressed({
        button_text: `Edit ${itemType}`,
        page_or_screen_name: EventNames.project.orderDetails.page,
        job_id: jobId,
        primary_cta: false,
        ...commonTrackingProps,
      });
    },
    [jobId, commonTrackingProps],
  );

  useEffect(() => {
    if (selectedEditListItem) {
      setShowAddEditMaterialModal(true);
      trackEditItemButton(LineItemTypeEnum.MATERIAL);
    }
  }, [selectedEditListItem, trackEditItemButton]);

  /**
   * 1. Get distributionApprovedBranches for distributor/org.
   * 2. Get the data for productionList from Apollo cache.
   * 3. Convert initial productionListItems to lineItemsAttributes for order check.
   * 4. Do initial order check create.
   * 5. Poll for order check response.
   * 6. On reponse complete, save order check ID in state (or use from cache).
   * 7. On change of lineItems, branch, account, do order check,
   *    with new lineItemsAttributes from current/updated order check data.
   */

  const [jobAccounts, setJobAccounts] = useState<Array<JobAccount> | undefined>(
    [],
  );
  // Initialization effect to get default branch and associated defaultJobAccount.
  useEffect(() => {
    if (!isNil(branches)) {
      const defaultBranch = branches?.find((item) => !!item.default) || null;
      setJobAccounts(defaultBranch?.jobAccounts);
      dispatch(updateBranch(defaultBranch));
      dispatch(
        updateJobAccount(
          defaultBranch?.defaultJobAccount || // select default job account
            (defaultBranch?.jobAccounts.length === 1
              ? defaultBranch?.jobAccounts[0] // or select only job account
              : null), // or don't select job account
        ),
      );
    }
  }, [branches, dispatch]);
  // Retreive the set of ListItems queried by the ProjectScope page from the cache.
  const productionList = useMemo(() => {
    return isNil(orderCheckId) && !isNil(listItemIds)
      ? getProductionList(apolloClient, orgId, jobId)
      : null;
    // Effect runs only on first page render, to get productionList from Project Scope page.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedItems: ListItem[] = useMemo(() => {
    const listItems: ListItem[] =
      productionList?.projectManagementProductionList?.listItems;
    return isNil(orderCheckId) && !isNil(listItems) && !isNil(listItemIds)
      ? listItems.filter((item: ListItem) => {
          return Object.values(listItemIds)
            .flat()
            .includes(item?.id.toString());
        })
      : [];
  }, [
    listItemIds,
    orderCheckId,
    productionList?.projectManagementProductionList?.listItems,
  ]);

  const [isPolling, setIsPolling] = useState<boolean>(false);
  const [orderCheckLineItems, setOrderCheckLineItems] =
    useState<OrderLineItemsByTrade>({} as OrderLineItemsByTrade);
  const [isSubmittable, setIsSubmittable] = useState<boolean>(false);

  const [quantityUnitsMap] = useState(new Map());

  const [getQuantityUnits] = useLazyQuery(ESTIMATION_QUANTITY_UNITS, {
    onError: () => {
      toast({
        id: TOAST_IDS.GET_QUANTITY_UNITS_TOAST,
        description:
          messages.projectScope.errors.query.productionList
            .estimateQuantityUnits,
        status: ToastStatusEnum.ERROR,
      });
    },
    onCompleted: (data: estimationQuantityUnits) => {
      data.estimationQuantityUnits?.forEach((unit) => {
        quantityUnitsMap.set(unit.abbreviation, unit.value);
      });
    },
  });

  useEffect(() => {
    getQuantityUnits();
  }, []);

  const onOrderCheckComplete = (orderCheckData: OrderCheckData) => {
    // When an order check is complete:
    // 1. Stop polling for the order check.
    // 2. Set the orderCheck ID in the URL.
    // 3. Update the displayed list using the lineITems from the order check.
    const orderCheck = orderCheckData?.distributionOrderCheck;
    if (orderCheck && orderCheck.checkComplete) {
      // Stop polling after order check complete.
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const stopPoll = stopPolling; // To avoid two eslint ignores.
      if (!isNil(stopPoll)) {
        stopPoll();
        setIsPolling(false);
      }
      // Set the order check ID in route path.
      history.replace(
        `/project/${jobId}/detail/${orderCheck.id}?orgId=${orgId}`,
      );
      // Update the displayed list with the order check results.
      const { lineItems, submittable } = orderCheck;
      setIsSubmittable(submittable);
      // Transform lineItems into mapped structure by trade.
      const lineItemsByTrade = getListItemsByTrade<
        OrderCheckLineItemWithTradeType,
        OrderLineItemsByTrade
      >(lineItems as unknown as OrderCheckLineItemWithTradeType[]);
      setOrderCheckLineItems(lineItemsByTrade);
    }
  };

  const [
    pollOrderCheck,
    { stopPolling, data: orderCheckData, error: orderCheckError },
  ] = useLazyQuery(ORDER_CHECK, {
    pollInterval: 500,
    onCompleted: onOrderCheckComplete,
    onError: () => {
      if (!isNil(stopPolling)) {
        stopPolling();
        setIsPolling(false);
      }
      toast({
        id: TOAST_IDS.ORDER_CHECK_POLL_TOAST,
        description:
          messages.projectScope.errors.query.productionList.orderCheck,
        status: ToastStatusEnum.ERROR,
      });
    },
  });

  const onOrderCheckCreateCompleted = (
    orderCheckCreateData: DistributionOrderCheckCreate,
  ) => {
    const order = orderCheckCreateData?.distributionOrderCheckCreate?.order;
    // After ordercheck, start polling for completion.
    pollOrderCheck({
      variables: {
        id: order?.id,
      },
    });
  };

  const onOrderCheckCreateError = () => {
    setIsPolling(false);
    toast({
      id: TOAST_IDS.ORDER_CHECK_CREATE_TOAST,
      description:
        messages.projectScope.errors.query.productionList.orderCheckCreate,
      status: ToastStatusEnum.ERROR,
    });
  };

  const [
    orderCheckCreate,
    { loading: orderCheckCreateLoading, error: orderCheckCreateError },
  ] = useMutation<DistributionOrderCheckCreate>(ORDER_CHECK_CREATE, {
    onError: onOrderCheckCreateError,
    onCompleted: onOrderCheckCreateCompleted,
  });

  useEffect(() => {
    // When doing an order check for editing a line item, after the successful
    // order check, close the modal and show a success toast, or show an error toast.
    if (showAddEditMaterialModal) {
      if (!!orderCheckCreateError || !!orderCheckError) {
        toast({
          id: 'error-updating-trade-type-order-item-toast',
          description: sentenceCase(
            `Error updating ${selectedEditListItem?.tradeType} material order item`,
          ),
          status: ToastStatusEnum.ERROR,
          props: { 'data-testid': 'Order-Check-Edit-Error-Toast' },
        });
      } else if (!!orderCheckData?.distributionOrderCheck?.checkComplete) {
        toast({
          id: 'success-updating-trade-type-order-item-toast',
          description: sentenceCase(
            `${selectedEditListItem?.tradeType} material order item successfully updated`,
          ),
          status: ToastStatusEnum.SUCCESS,
          props: { 'data-testid': 'Order-Check-Edit-Success-Toast' },
        });
        closeAddEditMaterialModal();
      }
    }
    // Only apply effect when the order check state changes to `complete` or error.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    orderCheckCreateError,
    orderCheckData?.distributionOrderCheck?.checkComplete,
  ]);

  // Distributor capabilities to check whether jobAccount required.
  const { data: distributorData, loading: distributorLoading } =
    useQuery<productCatalogDistributorCapabilities>(DISTRIBUTOR_CAPABILITIES, {
      variables: { id: distributor?.id, branchId: branch?.id },
    });

  useEffect(() => {
    if (
      !orderCheckCreateLoading &&
      !isNil(distributor) &&
      !isNil(branch) &&
      hasJobAccountForOrder(
        jobAccount,
        distributorData?.productCatalogDistributor.capabilities,
      )
    ) {
      // Do initial order check, based on either selected production list items (from ProjectScope page).
      if (!isNil(productionList) && isNil(orderCheckId)) {
        const listItemsByTrade = getListItemsByTrade<
          ListItem,
          ListItemsByTrade
        >(selectedItems);

        if (Object.values(listItemsByTrade).length > 0) {
          const sortedSelectedItems = selectedItems.sort(
            bySortOrderAndCreatedAtComparator,
          );

          const attributes = getAttributesFromListItems(
            sortedSelectedItems,
          ).map((item) => {
            return omit(item, ['requiresProductVariationSelection']);
          });

          setIsPolling(true);
          orderCheckCreate({
            variables: {
              orgId,
              attributes: {
                distributorId: distributor.id,
                distributionJobAccountId: jobAccount?.id,
                distributionApprovedBranchId: branch.id,
                lineItemsAttributes: attributes,
              },
            },
          });
        }
      } else if (!isNil(orderCheckId) && isNil(productionList)) {
        // When returning back to this page from Checkout, update the displayed list with the order check results.
        const savedOrderCheck = getDistributionOrderCheck(
          apolloClient,
          orderCheckId,
        );

        const { lineItems, submittable } =
          savedOrderCheck?.distributionOrderCheck as OrderCheck;
        // Transform lineItems into mapped structure by trade.
        const lineItemsByTrade = getListItemsByTrade<
          OrderCheckLineItemWithTradeType,
          OrderLineItemsByTrade
        >(lineItems as unknown as OrderCheckLineItemWithTradeType[]);
        setOrderCheckLineItems(lineItemsByTrade);
        setIsSubmittable(submittable);
      }
    }
    // This effect runs only when page loads and distributor value is set, or page is re-visited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderCheckId, distributor, branch, jobAccount]);

  const doOrderCheck = useCallback(
    (
      _distributor: Distributor | null,
      _branch: Branch | null,
      _jobAccount: JobAccount | null,
      _orgId: string,
      _orderLineItems: OrderLineItemsByTrade,
    ) => {
      // Do order check on a subsequent order check (modifying order items, branches, or job accounts.
      const lineItems = lineItemsWithOmittedAttributes(_orderLineItems);
      if (lineItems.length > 0 && !isNil(_branch) && !isNil(_jobAccount))
        orderCheckCreate({
          variables: {
            orgId: _orgId,
            attributes: {
              distributorId: _distributor?.id,
              distributionJobAccountId: _jobAccount?.id,
              distributionApprovedBranchId: _branch?.id,
              lineItemsAttributes: lineItems,
            },
          },
        });
    },
    [orderCheckCreate],
  );

  const updateOrderCheck = useCallback(
    (data: AddEditFields) => {
      // transform form field data to lineItemAttributes.
      const lineItem = {
        ...(selectedEditListItem as OrderCheckLineItem),
        variationName:
          data.externalVariationId === CUSTOM_VARIANT_COLOR.name
            ? data.customVariant
            : data.color,
        variationId:
          data.externalVariationId === CUSTOM_VARIANT_COLOR.name
            ? null
            : data.externalVariationId,
        userSetCustomVariationName:
          data.externalVariationId === CUSTOM_VARIANT_COLOR.name,
        productId: data.productId,
        productName: data.materialName,
        sku: data.sku,
        tradeType: data.trade,
        quantity: data.quantity,
        unitCost: data.unitCost,
        quantityUnits: quantityUnitsMap.get(data.quantityUnits),
      } as Attributes;

      setIsPolling(true);
      const lineItems = lineItemsWithOmittedAttributes(orderCheckLineItems);

      // Find the updated item in the array.
      const foundItem = lineItems.find((item) => {
        return item.clientIdentifier === lineItem.clientIdentifier;
      });

      /**
       * nil checking `foundItem` here because .find() can return undefined, and .indexOf does
       * not accept undefined as a valid parameter type. This should never happen, and if it does,
       * that means something went haywire way before this step, so the failure should have been
       * handled long before we get here, but it's probably a good idea to handle this edge case
       * in the future
       * */
      if (isNil(foundItem)) {
        setIsPolling(false);
        return;
      }

      const foundIndex = lineItems.indexOf(foundItem);

      const attributesToUpdate = omit(lineItem, [
        '__typename',
        'id',
        'pretaxCost',
        'price',
        'errors',
        'checkResult',
        'requiresProductVariationSelection',
      ]) as Attributes;
      // Replace the updated item in the array.
      lineItems[foundIndex] = attributesToUpdate;

      // Do order check on the updated lineItem (modifying order items)
      if (
        lineItems.length > 0 &&
        !isNil(branch) &&
        hasJobAccountForOrder(
          jobAccount,
          distributorData?.productCatalogDistributor.capabilities,
        )
      ) {
        orderCheckCreate({
          variables: {
            orgId,
            attributes: {
              distributorId: distributor?.id,
              distributionJobAccountId: jobAccount?.id,
              distributionApprovedBranchId: branch?.id,
              orderSessionId:
                orderCheckData?.distributionOrderCheck?.orderSessionId,
              lineItemsAttributes: lineItems,
            },
          },
        });
      }
    },
    [
      branch,
      distributor?.id,
      distributorData?.productCatalogDistributor.capabilities,
      jobAccount,
      orderCheckCreate,
      orderCheckData?.distributionOrderCheck?.orderSessionId,
      orderCheckLineItems,
      orgId,
      selectedEditListItem,
    ],
  );

  // Select branch
  const selectBranch = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.currentTarget;
      const selectedBranch =
        branches?.find((item) => item.id === value) || null;

      // Segment tracking.
      typewriter.optionSelected({
        option_type: 'list',
        selection: selectedBranch?.name ?? '',
        page_or_screen_name: EventNames.project.orderDetails.page,
        job_id: jobId,
        primary_cta: false,
        options: 'Branch',
        ...commonTrackingProps,
      });

      setJobAccounts(selectedBranch?.jobAccounts);
      dispatch(updateBranch(selectedBranch));
      const defaultJobAccount = selectedBranch?.defaultJobAccount || null;
      dispatch(updateJobAccount(defaultJobAccount));
      setIsPolling(true);
      doOrderCheck(
        distributor,
        selectedBranch,
        defaultJobAccount,
        orgId,
        orderCheckLineItems,
      );
    },
    [
      branches,
      dispatch,
      distributor,
      doOrderCheck,
      jobId,
      orderCheckLineItems,
      orgId,
      commonTrackingProps,
    ],
  );
  // Select jobAccount
  const selectJobAccount = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.currentTarget;
      const selectedBranch =
        branches?.find((item) => item.id === branch?.id) || null;
      const selectedJobAccount =
        selectedBranch?.jobAccounts.find(
          (item: JobAccount) => item.id === value,
        ) || null;

      // Segment tracking.
      typewriter.optionSelected({
        option_type: 'list',
        selection: selectedJobAccount?.displayName ?? '',
        page_or_screen_name: EventNames.project.orderDetails.page,
        job_id: jobId,
        primary_cta: false,
        options: 'Job Account',
        ...commonTrackingProps,
      });

      dispatch(updateJobAccount(selectedJobAccount));
      setIsPolling(true);
      doOrderCheck(
        distributor,
        selectedBranch,
        selectedJobAccount,
        orgId,
        orderCheckLineItems,
      );
    },
    [
      branch?.id,
      branches,
      dispatch,
      distributor,
      doOrderCheck,
      jobId,
      orderCheckLineItems,
      orgId,
      commonTrackingProps,
    ],
  );

  const NO_JOB_ACCOUNTS_TEXT = 'No existing job accounts';

  return (
    <>
      <LoadingOverlay
        isLoading={loadingBranches || distributorLoading || isPolling}
        isAnimated={false}
        data-testid="OrderDetailsSpinner"
      />
      <>
        <Box flexDirection="column" flex="1" paddingBottom={500}>
          <AddEditMaterialModal
            shouldFilterByDistributor
            isOpen={showAddEditMaterialModal}
            onCancel={closeAddEditMaterialModal}
            editFn={updateOrderCheck}
            trades={[]}
            editableItem={{
              listItem: selectedEditListItem as Attributes,
              type: LineItemTypeEnum.MATERIAL,
            }}
            showSku
            realTimePricing
          />
          <Box
            flexDirection="column"
            backgroundColor="neutral.100"
            sx={{ position: 'sticky', top: '0px', zIndex: 1 }}
          >
            <ProjectOrderNavbar />
            {/* Project scope */}
            <Box
              alignItems="baseline"
              paddingTop={500}
              marginX={700}
              paddingX={500}
              flexDirection="row"
              justifyContent="space-between"
              flex="0 0 auto"
            >
              <Box
                alignItems="center"
                flex="auto"
                minHeight="100px"
                justifyContent="space-between"
              >
                <Box>
                  {/* Page title */}
                  <Body margin={0} fontSize={500} fontWeight="bold">
                    Order Details
                  </Body>
                  {/* Item count */}
                  <Box lineHeight={700} marginLeft={700}>
                    {map(tradeTypesSorted, (tradeTypeObj) => {
                      // Map over the global set of sorted trade types, rendering the trade types
                      // in order that exist in the pre-mapped `listItemsByTypeAndTrade` object.
                      const key = tradeTypeObj.tradeTypeEnumValue;
                      const listItemTradeTypes = (listItemIds ?? {})[key];
                      return (
                        listItemTradeTypes && (
                          <Tag key={key} colorScheme="dark" marginRight={2}>
                            <Link
                              as={RouterLink}
                              to={{ ...location, ...{ hash: key } }}
                              color="neutral.0"
                              _active={{
                                color: 'neutral.0',
                                textDecorationColor: 'transparent',
                              }}
                              _hover={{
                                color: 'neutral.0',
                                textDecorationColor: 'transparent',
                              }}
                            >
                              {`${sentenceCase(key)}
                        (${
                          Object.values(
                            listItemIds?.[key as TradeTypeEnum] ?? {},
                          ).flat().length
                        })`}
                            </Link>
                          </Tag>
                        )
                      );
                    })}
                  </Box>
                </Box>
                {!loadingBranches && (
                  <Box testId="OrderDetails-branch-account-controls">
                    {/* Distributor logo */}
                    <Box
                      alignSelf="center"
                      justifyContent="right"
                      marginRight={700}
                      paddingBottom="28px"
                    >
                      {!isNil(distributor) && !isNil(distributor?.logo) && (
                        <Image
                          height="40px"
                          src={distributor.logo.url}
                          alt="logo"
                        />
                      )}
                    </Box>
                    {/* Branch and Job Account */}
                    <Field
                      name="branch"
                      label="Branch"
                      justifyContent="center"
                      paddingRight={600}
                      flex={1}
                      noOfLines={1}
                      display="flex"
                      flexDirection="column"
                      minWidth="350px"
                    >
                      {!isNil(branches) && branches?.length > 1 ? (
                        <Select
                          size="default"
                          onChange={selectBranch}
                          value={branch?.id}
                        >
                          <option
                            key="Select branch"
                            value=""
                            hidden
                            data-testid="select-option-placeholder"
                          >
                            Select branch
                          </option>
                          {branches?.map((branchOption) => (
                            <option
                              key={branchOption.id}
                              value={branchOption.id}
                            >
                              {branchOption.name}
                            </option>
                          ))}
                        </Select>
                      ) : (
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          fontWeight="600"
                          data-testid="OrderDetails-branchName-text"
                        >
                          <Box display="block" noOfLines={1}>
                            {isNil(branches) ? '' : branches[0]?.name}
                          </Box>
                        </Box>
                      )}
                    </Field>
                    <Field
                      name="jobAccount"
                      label="Job Account"
                      justifyContent="center"
                      flex={1}
                      noOfLines={1}
                      display="flex"
                      flexDirection="column"
                      minWidth="350px"
                    >
                      {!isNil(jobAccounts) && jobAccounts?.length > 1 ? (
                        <Select
                          size="default"
                          onChange={selectJobAccount}
                          value={jobAccount?.id}
                        >
                          <option
                            key="Select job account"
                            value=""
                            hidden
                            data-testid="select-option-placeholder"
                          >
                            Select job account
                          </option>
                          {jobAccounts?.map((jobAccountOption) => (
                            <option
                              key={jobAccountOption.id}
                              value={jobAccountOption.id}
                            >
                              {`${jobAccountOption.id}-${jobAccountOption.displayName}`}
                            </option>
                          ))}
                        </Select>
                      ) : (
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          fontWeight="600"
                          data-testid="OrderDetails-jobAccountName-text"
                        >
                          <Box display="block" noOfLines={1}>
                            {isNil(jobAccounts) || jobAccounts?.length < 1
                              ? NO_JOB_ACCOUNTS_TEXT
                              : `${jobAccounts[0]?.id}-${jobAccounts[0]?.displayName}`}
                          </Box>
                        </Box>
                      )}
                    </Field>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            backgroundColor="neutral.0"
            marginX={700}
            flexDirection="column"
            flex="1"
          >
            <Box paddingX={500} flex="1 1 auto" flexDirection="column">
              {map(tradeTypesSorted, (tradeTypeObj) => {
                // Map over the global set of sorted trade types, rendering the trade types
                // in order that exist in the pre-mapped `listItemsByTypeAndTrade` object.
                const tradeType = tradeTypeObj.tradeTypeEnumValue;
                const orderCheckItems = orderCheckLineItems[tradeType];
                return (
                  orderCheckItems && (
                    <Box key={tradeType} paddingTop={500}>
                      {/* Anchor link for direct access via section tags */}
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <Link
                        id={tradeType}
                        sx={{
                          top: '-194px',
                          display: 'block',
                          position: 'relative',
                          visibility: 'hidden',
                        }}
                      />
                      <Box
                        className="tradeTypeContainer"
                        key={tradeType}
                        _notFirst={{ marginTop: 400 }}
                        _notLast={{ marginBottom: 400 }}
                        flexDirection="column"
                      >
                        {/* tradeType group row */}
                        <Table
                          size="small"
                          sx={{ tableLayout: 'fixed', border: 'none' }}
                        >
                          <Tbody sx={{ border: 'none' }}>
                            <Tr
                              data-testid={`ListItemTradeRow-${tradeType}`}
                              verticalAlign="baseline"
                            >
                              <Td
                                fontWeight="bold"
                                fontSize={400}
                                paddingBottom={0}
                              >
                                {sentenceCase(tradeType)}
                              </Td>
                            </Tr>
                          </Tbody>
                        </Table>
                        <Table size="small" sx={{ tableLayout: 'fixed' }}>
                          <Thead>
                            <Tr>
                              <Th sx={{ width: '48px' }} />
                              <Th width="*">Name</Th>
                              <Th sx={{ width: '12%' }}>Color/Variation</Th>
                              <Th sx={{ width: '20%' }}>SKU</Th>
                              <Th sx={{ width: '12%' }}>Measurements</Th>
                              <Th sx={{ width: '8%' }}>Quantity</Th>
                              <Th isNumeric sx={{ width: '8%' }}>
                                Unit Cost ($)
                              </Th>
                              <Th isNumeric sx={{ width: '8%' }}>
                                Total ($)
                              </Th>
                              <Th sx={{ width: '108px' }} />
                            </Tr>
                          </Thead>
                          <Tbody data-testid="ListItemTable">
                            {
                              // Map over the line items in the trade.
                              orderCheckLineItems[tradeType].map(
                                (lineItem: OrderCheckLineItem) =>
                                  lineItem && (
                                    <OrderDetailListItem
                                      key={lineItem.id}
                                      lineItem={lineItem}
                                      setSelectedEditListItem={
                                        setSelectedEditListItem
                                      }
                                    />
                                  ),
                              )
                            }
                          </Tbody>
                        </Table>
                      </Box>
                    </Box>
                  )
                );
              })}
            </Box>
          </Box>
        </Box>
        <OrderDetailsControls
          jobId={jobId}
          orgId={orgId}
          orderCheckId={orderCheckId}
          isSubmittable={isSubmittable}
          orderCheckLineItems={
            orderCheckData?.distributionOrderCheck?.lineItems
          }
          sx={{ position: 'sticky', bottom: '0px', zIndex: 1 }}
        />
      </>
    </>
  );
};
