import { Box, Checkbox, Body, TextInput, Field } from '@hover/blueprint';

import { replaceUnderscoresWithSpace } from 'src/features/project/util/utils';

import { TemplateOption } from './SaveAsTemplateModal';

export interface SaveAsTemplateOptionProps {
  templateOption: TemplateOption;
  onOptionSelect: (templateOption: TemplateOption) => void;
  onNameInputChange: (
    templateOption: TemplateOption,
    nameInputValue: string,
  ) => void;
  onUnitCostsInputChange: (templateOption: TemplateOption) => void;
  onOverwriteTemplateInputChange: (templateOption: TemplateOption) => void;
  disabled: boolean;
  showOverwriteTemplateCheckbox: boolean;
}

export function SaveAsTemplateOption({
  templateOption,
  onOptionSelect,
  onNameInputChange,
  onUnitCostsInputChange,
  onOverwriteTemplateInputChange,
  disabled,
  showOverwriteTemplateCheckbox,
}: SaveAsTemplateOptionProps) {
  const isNameInputDisabled =
    disabled ||
    (templateOption.overwriteInput && showOverwriteTemplateCheckbox);

  return (
    <Box
      data-testid={`SaveAsTemplateModal-TemplateOption-${templateOption.template.id}`}
      backgroundColor={templateOption.isSelected ? 'neutral.0' : 'neutral.100'}
      border="1px solid"
      borderColor={templateOption.isSelected ? 'neutral.900' : 'neutral.0'}
      paddingLeft={600}
      paddingRight={600}
      borderRadius={500}
      marginBottom={400}
      display="flex"
    >
      <Checkbox
        name="template-option"
        marginTop={600}
        marginBottom={600}
        isChecked={templateOption.isSelected}
        onChange={() => {
          onOptionSelect(templateOption);
        }}
        isDisabled={disabled}
      />
      <Box display="flex" flex="1" flexDirection="column" padding={500}>
        <Body
          color={templateOption.isSelected ? 'neutral.800' : 'neutral.500'}
          margin="0"
          padding="0"
        >
          {replaceUnderscoresWithSpace(templateOption.template.tradeType || '')}
        </Body>
        {templateOption.isSelected ? (
          <>
            <Field
              name="template-name"
              error={
                templateOption.hasError
                  ? 'To save a new template, please use a unique name.'
                  : ''
              }
            >
              <TextInput
                placeholder="template name"
                borderRadius={500}
                value={templateOption.nameInput}
                onChange={(e) => {
                  onNameInputChange(templateOption, e.target.value);
                }}
                disabled={isNameInputDisabled}
              />
            </Field>
            <Checkbox
              name="unit-costs"
              marginTop={300}
              isChecked={templateOption.updateUnitCostsInput}
              onChange={() => {
                onUnitCostsInputChange(templateOption);
              }}
              isDisabled={disabled}
            >
              Update unit costs
            </Checkbox>

            {showOverwriteTemplateCheckbox && (
              <Checkbox
                name="update-overwrite-template"
                marginTop={300}
                isChecked={templateOption.overwriteInput}
                onChange={() => {
                  onOverwriteTemplateInputChange(templateOption);
                }}
                isDisabled={disabled}
              >
                Update template
              </Checkbox>
            )}
          </>
        ) : (
          <Body fontWeight={700} margin="0" padding="0">
            {templateOption.template.name}
          </Body>
        )}
      </Box>
    </Box>
  );
}
