import { Body, Icon, Panel } from '@hover/blueprint';
import { iInfo } from '@hover/icons';

interface Props {
  distributorDisplayName: string;
  websiteDisplayName: string;
}

const OrderDisclaimer: React.FC<Props> = ({
  distributorDisplayName,
  websiteDisplayName,
}) => {
  return (
    <Panel
      data-testid="orderDisclaimer"
      backgroundColor="neutral200"
      color="primary800"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Icon icon={iInfo} size="large" color="inherit" marginRight={400} />
      <Body size={400} color="inherit" textAlign="left">
        To view the order, please go to {websiteDisplayName}. To make changes or
        corrections to your {distributorDisplayName} Order please contact your{' '}
        {distributorDisplayName} rep.
      </Body>
    </Panel>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default OrderDisclaimer;
