import {
  ListItemUpdate as ListItemUpdateParams,
  ListItemCreate as ListItemCreateParams,
} from 'src/api/graphql-global-types';
import { ORG_SETTINGS_QUERY } from 'src/api/queries/queries';
import { distributionOrderVariables } from 'src/api/types/distributionOrder';
import { distributionOrderSubmitVariables } from 'src/api/types/distributionOrderSubmit';
import { productCatalogDistributorVariationAttributesVariables } from 'src/api/types/productCatalogDistributorVariationAttributes';
import { productCatalogDistributorVariationsAttributesVariables } from 'src/api/types/productCatalogDistributorVariationsAttributes';
import { productCatalogProductsSearchVariables } from 'src/api/types/productCatalogProductsSearch';
import { projectManagementOrderCreateVariables } from 'src/api/types/projectManagementOrderCreate';
import { projectManagementOrderDocumentCreateVariables as orderDocumentCreateVariables } from 'src/api/types/projectManagementOrderDocumentCreate';
import { projectManagementOrderFulfillmentStartVariables } from 'src/api/types/projectManagementOrderFulfillmentStart';
import appSettings from 'src/appSettings';
import {
  UPDATE_LIST_ITEM,
  CREATE_LIST_ITEM,
  GET_SALES_OPPORTUNITIES,
  ORDER_DOCUMENT_CREATE,
  PROJECT_MANAGEMENT_ORDER_DOCUMENTS,
  GET_PRODUCTS,
  JOB_DETAILS,
  PRODUCT_CATALOG_DISTRIBUTOR_VARIATION_ATTRIBUTES,
  PRODUCT_CATALOG_DISTRIBUTOR_VARIATIONS_ATTRIBUTES,
  PROJECT_MANAGEMENT_ORDER_CREATE,
  PROJECT_MANAGEMENT_ORDER_FULFILLMENT_START,
  DISTRIBUTION_ORDER,
  DISTRIBUTION_ORDER_SUBMIT,
  PRODUCT_SEARCH_BY_DISTRIBUTOR_V2,
} from 'src/features/projectManagement/apis/graphql/queries';
import { GraphqlClient } from 'src/lib/GraphqlClient';
import request from 'src/lib/request';

import {
  SalesOpportunityType,
  VendorType,
  GetProductsApiParams,
  PollProjectManagementOrderDocumentsPayload,
} from '../types';

export interface GetSalesOpportunitiesData {
  salesOpportunities: SalesOpportunityType[];
  vendors: VendorType[];
}

export class EstimatorProductionApi {
  public static getJobDetails(jobId: string) {
    return GraphqlClient.query({
      query: JOB_DETAILS,
      variables: { ids: [Number(jobId)] },
      fetchPolicy: 'no-cache',
    });
  }

  public static getJobMeasurements(jobId: string) {
    return request.get(
      `/api/v2/jobs/${jobId}/measurements?version=summarized_json`,
      { baseURL: appSettings.HYPERION_API_SERVER },
    );
  }

  public static getEstimateUser(userId: number | null) {
    return request.get(`/api/v1/users/${userId}`, {
      baseURL: appSettings.HYPERION_API_SERVER,
    });
  }

  public static updateListItem(
    listItemId: number,
    params: ListItemUpdateParams,
  ) {
    return GraphqlClient.mutate({
      mutation: UPDATE_LIST_ITEM,
      variables: {
        listItemId,
        preventAutoMatch: true,
        listItemAttributes: params,
      },
    });
  }

  public static createListItem(
    productionListId: number,
    params: ListItemCreateParams,
  ) {
    return GraphqlClient.mutate({
      mutation: CREATE_LIST_ITEM,
      variables: { productionListId, listItemAttributes: params },
    });
  }

  public static getSalesOpportunities(orgId: number, jobId: number) {
    return GraphqlClient.query({
      query: GET_SALES_OPPORTUNITIES,
      variables: { orgId, jobId },
    });
  }

  public static getProducts({
    productIds,
    distributorIds,
    filterVariationsByOrg,
    orgId,
    after,
  }: GetProductsApiParams) {
    return GraphqlClient.query({
      query: GET_PRODUCTS,
      variables: {
        productIds,
        distributorIds,
        filterVariationsByOrg,
        orgId,
        after,
      },
    });
  }

  public static orderDocumentCreate(variables: orderDocumentCreateVariables) {
    return GraphqlClient.mutate({
      mutation: ORDER_DOCUMENT_CREATE,
      variables,
    });
  }

  public static projectManagementOrderDocuments(
    variables: PollProjectManagementOrderDocumentsPayload,
  ) {
    return GraphqlClient.query({
      query: PROJECT_MANAGEMENT_ORDER_DOCUMENTS,
      variables,
      fetchPolicy: 'no-cache',
    });
  }

  public static getOrgSettings() {
    return GraphqlClient.query({
      query: ORG_SETTINGS_QUERY,
      variables: {},
    });
  }

  public static productSearch(
    variables: productCatalogProductsSearchVariables,
  ) {
    if (!variables.searchTerm) {
      return null;
    }

    return GraphqlClient.query({
      query: PRODUCT_SEARCH_BY_DISTRIBUTOR_V2,
      variables,
    });
  }

  public static productCatalogVariationAttributes(
    variables: productCatalogDistributorVariationAttributesVariables,
  ) {
    return GraphqlClient.query({
      query: PRODUCT_CATALOG_DISTRIBUTOR_VARIATION_ATTRIBUTES,
      variables,
    });
  }

  public static productCatalogVariationsAttributes(
    variables: productCatalogDistributorVariationsAttributesVariables,
  ) {
    return GraphqlClient.query({
      query: PRODUCT_CATALOG_DISTRIBUTOR_VARIATIONS_ATTRIBUTES,
      variables,
    });
  }

  public static projectManagementOrderCreate(
    variables: projectManagementOrderCreateVariables,
  ) {
    return GraphqlClient.mutate({
      mutation: PROJECT_MANAGEMENT_ORDER_CREATE,
      variables,
    });
  }

  public static projectManagementOrderFulfillmentStart(
    variables: projectManagementOrderFulfillmentStartVariables,
  ) {
    return GraphqlClient.mutate({
      mutation: PROJECT_MANAGEMENT_ORDER_FULFILLMENT_START,
      variables,
    });
  }

  public static distributionOrder(variables: distributionOrderVariables) {
    return GraphqlClient.query({
      query: DISTRIBUTION_ORDER,
      variables,
      fetchPolicy: 'no-cache',
    });
  }

  public static distributionOrderSubmit(
    variables: distributionOrderSubmitVariables,
  ) {
    return GraphqlClient.mutate({
      mutation: DISTRIBUTION_ORDER_SUBMIT,
      variables,
    });
  }
}
