import { Link, LinkProps } from '@hover/blueprint';

import { useIsMobileBreakpoint } from 'src/hooks/useIsMobileBreakpoint';

export function MobileExternalLink({ children, ...props }: LinkProps) {
  const isMobile = useIsMobileBreakpoint();

  const handleOnClickWrapper = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    if (!isMobile && props.onClick) {
      return props.onClick(event);
    }

    event.preventDefault();

    const eventPayload = {
      type: 'openExternalLink',
      url: props.href,
    };

    window.ReactNativeWebView.postMessage(JSON.stringify(eventPayload));
    return undefined;
  };

  return (
    <Link {...props} target="_blank" onClick={handleOnClickWrapper}>
      {children}
    </Link>
  );
}
