import {
  estimationConfigRequiredInputs_estimationConfigRequiredInputs_edges_node as Question,
  estimationConfigRequiredInputs_estimationConfigRequiredInputs_edges_node_inputOptions as InputOption,
} from 'src/api/types/estimationConfigRequiredInputs';
import { JobDetails } from 'src/features/exteriorEstimator/types/JobDetails';
import { Input } from 'src/features/exteriorEstimator/types/Questions';
import { getInputTradeType } from 'src/features/exteriorEstimator/utils/questionsUtils';
import { ListItemType } from 'src/features/projectManagement/types';

export const jobProps = (jobDetails: JobDetails | undefined | null) => {
  return {
    job_id: jobDetails?.id,
    job_address: `${jobDetails?.locationLine1}, ${jobDetails?.locationCity}`,
  };
};

export const questionProps = (
  jobDetails: JobDetails | undefined | null,
  question: Question | Input | undefined | null,
  answer: string | number | boolean,
  optionalProps?: Record<string, unknown>,
) => {
  /* eslint-disable camelcase */
  let input_value = answer;
  if (question && question.inputOptions) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const labelledAnswer = (question.inputOptions as any).find(
      (option: InputOption) => option.value.toString() === answer.toString(),
    )?.label;
    if (labelledAnswer) input_value = labelledAnswer;
  }
  return {
    input_value,
    measurement_name: question?.argument,
    question: question?.question,
    question_screen_name: question?.inputCategory?.name,
    ui_item_type: question?.inputType,
    default_value: question?.questionDefaultValue,
    trade_type: getInputTradeType(question as Input),
    ...jobProps(jobDetails),
    ...optionalProps,
  };
};

export const listItemProps = (listItem: ListItemType) => ({
  input_value: listItem.color,
  line_item_name: listItem.name,
  line_item_price: listItem.unitCost,
  line_item_type: listItem.type,
  trade_type: listItem.tradeType,
});
