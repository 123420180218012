import { Box, Heading, Body, Icon } from '@hover/blueprint';
import { iMail } from '@hover/icons';

import { projectManagementProposalDocumentCreate_projectManagementProposalDocumentCreate_proposalDocument as Proposal } from 'src/api/types/projectManagementProposalDocumentCreate';
import { Container } from 'src/components/Misc';

export const SentEmailESign: React.FC<{ proposal: Proposal }> = ({
  proposal,
}) => {
  return (
    <Box flexShrink={0} flex={1} testId="sentEmailESignBody">
      <Container>
        <Icon icon={iMail} margin={600} size="huge" />
        <Heading size={300}>Request Sent</Heading>
        <Box padding={400} width={1} flexDirection="column" alignItems="center">
          <Body as="span" size={200}>
            Email was sent to
          </Body>
          {proposal.customers.map(({ emailAddress }) => (
            <Body as="span" size={200}>
              <strong>{emailAddress}</strong>
            </Body>
          ))}
          <Body as="span" size={200}>
            to review and sign proposal
          </Body>
        </Box>
      </Container>
    </Box>
  );
};
