import { PureComponent } from 'react';

import { Body, Box } from '@hover/blueprint';

import { EstimateGroup_estimationEstimateGroup_estimates_templateGroups_lineItems as TemplateGroupLineItem } from 'src/api/types/EstimateGroup';
import { FormattedNumber } from 'src/components/FormattedNumber';
import { lineItemComparator } from 'src/features/exteriorEstimator/utils/estimateGroupUtils';
import {
  lineItemMaterialQuantity,
  lineItemMeasurementDisplay,
  lineItemUnits,
  lineItemMeasurementNumber,
} from 'src/features/exteriorEstimator/utils/measurementLineItemUtils';
import { formattedNumber } from 'src/features/project/util/utils';
import {
  adjustedMeasurementWithWasteFactor,
  wasteFactorDisplay,
  normalizedWasteFactor,
} from 'src/utils/estimatesUtils';

export interface LineItemsProps {
  lineItems: TemplateGroupLineItem[];
  shouldShowEstimatePrices: boolean;
  showPricesByTemplate: boolean;
}

export const LineItem: React.FC<{ name: string | null }> = ({ name }) =>
  !name ? null : <Box testId="lineItem">{name}</Box>;

export class LineItems extends PureComponent<LineItemsProps> {
  public renderLineItemSubtext(_lineItem: TemplateGroupLineItem) {
    const lineItem = {
      ..._lineItem,
      results: JSON.parse(_lineItem.results),
    };

    return (
      <Box ml={300}>
        <Box
          width="100%"
          color="neutral500"
          data-testid="estimate-line-item-subtext"
        >
          <Box width={0.4}>
            <Body
              as="span"
              size={400}
              color="neutral500"
              marginRight="1rem"
              textTransform="uppercase"
              data-testid="estimate-line-item-measurement"
            >
              {this.measurement(_lineItem)}
            </Body>
          </Box>
          <Box>
            <Body
              as="span"
              size={400}
              color="neutral500"
              marginRight={600}
              textTransform="uppercase"
              data-testid="estimate-line-item-quantity"
            >
              {lineItemMaterialQuantity(
                lineItem.results.material_quantity_post_waste_decimal,
                lineItem.quantityUnits?.toUpperCase() ?? '',
              )}
            </Body>
          </Box>
        </Box>
      </Box>
    );
  }

  public measurement(_lineItem: TemplateGroupLineItem) {
    const lineItem = {
      ..._lineItem,
      results: JSON.parse(_lineItem.results),
    };
    const wasteFactorDispl = wasteFactorDisplay(lineItem.wasteFactor);
    const wasteFactor = normalizedWasteFactor(lineItem.wasteFactor);
    const measurementDisplay = lineItemMeasurementDisplay(
      lineItem.results.manual_measurements,
      lineItem.results.automatic_measurements,
    );
    const measurement = lineItemMeasurementNumber(
      lineItem.results.manual_measurements,
      lineItem.results.automatic_measurements,
    );
    const units = lineItemUnits(
      lineItem.measurementUnits?.toUpperCase() ?? null,
    );
    const measurementWithWasteFactor = adjustedMeasurementWithWasteFactor(
      measurement.value() ?? 0,
      wasteFactor ?? 0,
    );
    const formattedMeasurementWithWasteFactor = formattedNumber(
      measurementWithWasteFactor,
      null,
      units,
    );
    if (measurementDisplay === '0.00') return '--';
    return (
      <>
        {wasteFactor && wasteFactor > 0 ? (
          <Box>
            {`${formattedMeasurementWithWasteFactor}  (${measurementDisplay} ${units} +${wasteFactorDispl})`}
          </Box>
        ) : (
          <Box>{`${measurementDisplay} ${units} `}</Box>
        )}
      </>
    );
  }

  public renderVariationIfPresent(
    lineItem: TemplateGroupLineItem,
    shouldShowEstimatePrices: boolean,
  ) {
    if (!lineItem?.productCatalogVariationName && !lineItem?.selectedVariation)
      return null;
    const mt = 200;
    const mb = shouldShowEstimatePrices ? 400 : 200;
    return (
      <Box ml={300} mb={mb} mt={mt}>
        {lineItem?.productCatalogVariationName ||
          lineItem?.selectedVariation?.name}
      </Box>
    );
  }

  public render() {
    const {
      lineItems: _lineItems,
      shouldShowEstimatePrices,
      showPricesByTemplate,
    } = this.props;
    const lineItems = [..._lineItems].sort(lineItemComparator);
    return (
      <Box flexDirection="column">
        {lineItems.map((lineItem) => {
          return (
            <Box key={lineItem.id} flexDirection="column" fontSize={200}>
              <Box padding="12px 0px 8px 0">
                <LineItem key={lineItem.id} name={lineItem.name} />

                <Box
                  opacity={
                    shouldShowEstimatePrices && showPricesByTemplate ? '1' : '0'
                  }
                  flex={1}
                  justifyContent="flex-end"
                  color="neutral500"
                  data-testid="estimate-line-item-price"
                >
                  <FormattedNumber
                    value={lineItem.price ?? 0}
                    format="$0,0.00"
                  />
                </Box>
              </Box>
              <Box flexDirection="column">
                {this.renderVariationIfPresent(
                  lineItem,
                  shouldShowEstimatePrices,
                )}
                {shouldShowEstimatePrices &&
                  this.renderLineItemSubtext(lineItem)}
              </Box>
            </Box>
          );
        })}
      </Box>
    );
  }
}
