import React from 'react';

import { Box, Body } from '@hover/blueprint';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-unresolved
import { distributionOrder_distributionOrder_orderCheck as orderCheckType } from 'src/features/projectManagement/apis/graphql/queries/types/distributionOrder';

interface Props {
  orderCheck: orderCheckType;
}

export const OrderErrorMessages: React.FC<Props> = ({ orderCheck }) => {
  const errors = orderCheck?.errors ?? [];
  if (!errors?.length) return null;

  return (
    <Box flexDirection="column" testId="OrderErrorMessages">
      {errors.map((error) => (
        <Box
          key={error.attribute}
          flexDirection="column"
          alignItems="flex-start"
          paddingX={600}
          marginBottom={400}
        >
          {error.errors.map((errorMessage: string) => (
            <Body size={400} margin={0} color="danger500" textAlign="left">
              {errorMessage}
            </Body>
          ))}
        </Box>
      ))}
    </Box>
  );
};
