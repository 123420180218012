import { FormattedNumber } from 'src/components/FormattedNumber';
import { formattedNumber } from 'src/features/project/util/utils';
import {
  adjustedMeasurementWithWasteFactor,
  wasteFactorDisplay,
} from 'src/utils/estimatesUtils';
import { lineItemQuantityUnits } from 'src/utils/unitsMap';

import { ListItemType } from '../../../types';
import {
  TableDataContent,
  TableDataText,
  TableCellDisplayUnit,
  TableSmallText,
} from '../Tables/styled';

interface MeasurementCellProps {
  listItem: ListItemType;
}

export const MeasurementCell: React.FC<MeasurementCellProps> = ({
  listItem: { measurementUnits, measurement, wasteFactor },
}) => {
  const showMeasurement = measurement > 0;

  return (
    <TableDataContent>
      <TableDataText>
        {showMeasurement ? <FormattedNumber value={measurement} /> : '--'}
        {showMeasurement && measurementUnits && (
          <TableCellDisplayUnit data-testid="measurementCellUnits">
            {lineItemQuantityUnits(measurementUnits)}
          </TableCellDisplayUnit>
        )}
      </TableDataText>
      {/* TODO neglected to capture manual measurement state
        {isManualMeasurement && <TableSmallText>manual</TableSmallText>} */}
      {!!wasteFactor && (
        <TableSmallText data-testid="measurementCellWasteFactor">
          {`+${wasteFactorDisplay(wasteFactor)} = ${formattedNumber(
            adjustedMeasurementWithWasteFactor(measurement, wasteFactor),
          )}`}
        </TableSmallText>
      )}
    </TableDataContent>
  );
};
