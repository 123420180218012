/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */

type ExtendedMacheteViewer = {
  resize: (transitionTime?: number, delay?: number) => void;
};

interface MacheteType {
  building: object;
  viewer: object;
  originalFeaturesStyles: object;
}

const DEFAULT_FOCUS_OPTS = {
  moneyshotConfig: {
    offset: 1.2,
  },
};

const createSegmentationTree = (machete: any, building: any) => {
  machete.Machete.Tools.WebSegmentation.configure({});
  machete.Machete.Tools.WebSegmentation.prepareModel(building);

  const tree = building.segmentationTree;

  tree.autoUpdate = true;
  tree.configure({ defaultDecorators: [] });

  const { createNodesFromModel } = machete.Machete.SegmentationUtils;

  tree.addNodes(createNodesFromModel(building));

  tree.start();
  tree.update();

  return building;
};

const initFeatures = (building: MacheteType.Building, labels?: any) => {
  building.initFeatureAPI(building.segmentationTree, labels);
};

const load = async (machete: any, { geometry, metadata, products }: any) => {
  const building = new machete.Building();

  return new Promise((resolve) => {
    building.load({
      geometry,
      metadata,
      products,
      dimensionality: 2, // make this 3 to have more geometric windows? and some other stuff
      onComplete: () => resolve(building),
      useSyntheticGlassAnnotations: true,
      autotexture: true,
    });
  });
};
let theViewer;
const initViewer = (machete: any, element: HTMLElement, grey: boolean) => {
  // const viewer = new machete.Machete.Viewer(element, {
  //   webgl: {
  //     context: {
  //       antialias: true,
  //       alpha: true,
  //       preserveDrawingBuffer: true,
  //       premultipliedAlpha: true,
  //       autoClear: false,
  //     },
  //   },
  //   useOrbit: true,
  //   useProductsLibraryForTexturing: false,
  //   useGradientEnvironment: true,
  //   clearColorAlpha: 0.0,
  //   shadowOnlyBG: true,
  // });
  // this renders a more 3d viewer with a blue background

  const SHADING_CONFIGS = {
    useSky: false,
    useTerrain: false,
    useShadowMap: false,
    backgroundColor: grey ? 0xeeeeee : 0xffffff,
    useNavigationControls: false,
  };

  if (!theViewer) {
    theViewer = new machete.Machete.Viewer(element, SHADING_CONFIGS);
  } else {
    const oldDOM = theViewer.viewport.container;
    theViewer.viewport.container = element;
    theViewer.viewport.container.appendChild(theViewer.renderer.domElement);
    theViewer.viewport.resize();
    theViewer.resize();
    theViewer.renderer.domElement.style.width = '100%';
    oldDOM.parentElement.removeChild(oldDOM);
  }

  return theViewer;
};

const setBuilding = (viewer: any, building: any) => {
  viewer.modelContainer.addModel(building);
  viewer.focus(building, DEFAULT_FOCUS_OPTS);
};

const listenForEventFactory =
  (viewer: any) => (event: string, callback: () => void) => {
    viewer.controls.addEventListener(event, callback);

    // unsubscriber
    return () => {
      viewer.controls.removeEventListener(event, callback);
    };
  };

const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const changeCameraFocus = async (viewer, focusElement, focusOptions, delay) => {
  // allow menu open/close transition to complete
  await timeout(delay);

  viewer.focus(focusElement, focusOptions);
  viewer.controls.useOrbitMode = false;
  viewer.controls.update();

  await timeout(focusOptions.duration);

  viewer.controls.useOrbitMode = true;
  viewer.controls.update();
};

const renderMachete = async ({
  canvasRef,
  geometry,
  metadata,
  grey,
}): Promise<MacheteType> => {
  const { machete } = window;
  if (!machete) return {};

  const viewer = initViewer(machete, canvasRef.current, grey);
  const building = await load(machete, {
    geometry,
    metadata,
    products: null,
  });

  setBuilding(viewer, building);

  // The `createSegmentationTree` method ultimately applies textures to the
  // model which is performance intensive so we may want to optimize this later
  createSegmentationTree(machete, building);
  initFeatures(building);

  await building.isReady();

  return { building, viewer };
};

export {
  changeCameraFocus,
  ExtendedMacheteViewer,
  renderMachete,
  listenForEventFactory,
};
