import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  ApolloError,
  ApolloQueryResult,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  Box,
  CheckboxGroup,
  Heading,
  Link,
  Select,
  Tag,
} from '@hover/blueprint';
import * as Sentry from '@sentry/react';
import { isNil } from 'lodash';
import numeral from 'numeral';
import { useSelector } from 'react-redux';

import {
  estimationConfigLineItems_estimationConfigLineItems_nodes as LineItem,
  estimationConfigLineItems_estimationConfigLineItems_nodes_product as ProductType,
} from 'src/api/types/estimationConfigLineItems';
import { productCatalogProducts_productCatalogProducts_pageInfo as PageInfoType } from 'src/api/types/productCatalogProducts';
import { Search } from 'src/components/blueprint/Search';
import { NoResultsFound } from 'src/components/NoResultsFound';
import { getCombinedEstimationConfigLineItems } from 'src/features/settings/api/getCombinedEstimationConfigLineItems';
import { PRODUCT_CATALOG_ORG_SETTINGS_FILTER_TOGGLE } from 'src/features/settings/api/queries/orgSettings';
import {
  GET_PRODUCT_CATALOG_PRODUCTS,
  PRODUCT_CATALOG_PRODUCT_SEARCH_V2,
} from 'src/features/settings/api/queries/products';
import { ListBody } from 'src/features/settings/components/MaterialList/Listbody';
import { ListHead } from 'src/features/settings/components/MaterialList/ListHead';
import { groupProductsByProvider } from 'src/features/settings/utils/materials';
import { useTracking } from 'src/hooks';
import { useSearchParams } from 'src/hooks/useSearchParams';
import { getOrgSettings, getUserOrgId } from 'src/redux/selectors';
import { EventNames } from 'src/types/actionTypes';

import type { NotificationHandlers } from '../Settings';
import { EnableVariantsFiltering } from './EnableVariantsFiltering';
import { Error } from './Error';
import { Loading } from './Loading';
import { VariantsFiltering } from './VariantsFiltering';

export type LineItems = LineItem[];

type ItemsListProps = {
  products: ProductType[];
  isProductFilteringEnabled: boolean;
  handleRefetch: () => void;
  setIsFetching: (fetching: boolean) => void;
};

const ItemsList: React.FC<ItemsListProps & NotificationHandlers> = ({
  products,
  isProductFilteringEnabled,
  setIsFetching,
  setNotification,
  handleRefetch,
}) => {
  const orgId = useSelector(getUserOrgId);

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const itemsGroupedByProvider = useMemo(() => {
    return Object.entries(groupProductsByProvider(products)).sort(
      (entry1, entry2) => {
        // Sort "Other" group to end of list.
        if (entry1[0] === 'Other' || entry1[0] > entry2[0]) return 1;
        if (entry2[0] === 'Other' || entry1[0] <= entry2[0]) return -1;
        return 0;
      },
    );
  }, [products]);
  // State of the "Select all/none" checkbox
  const [selectedMaterials, setSelectedMaterials] = useState<Array<string>>([]);
  const allChecked = selectedMaterials.length === products?.length;
  const isIndeterminate =
    selectedMaterials.length !== 0 &&
    selectedMaterials.length !== products?.length;

  const handleSelectAllNone = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    typewriter.buttonPressed({
      button_text: 'Select Deselect All',
      page_or_screen_name: EventNames.settings.materialsList.page,
      primary_cta: false,
      ...commonTrackingProps,
    });
    const { checked } = event.target;
    if (checked) {
      // Set all variations as selected
      const allProductIds = products?.map(({ id }) => id);
      setSelectedMaterials(allProductIds);
    } else {
      // Set all variations as unselected
      setSelectedMaterials([]);
    }
  };

  const handleCheckboxChange = (value: Array<string>) => {
    setSelectedMaterials(value);
  };

  const onSaveError = (error: ApolloError) => {
    setIsFetching(false);
    setNotification({
      show: true,
      notification: 'Something went wrong while saving. Try again.',
      severity: 'Error',
    });
    Sentry.captureException(error);
  };

  const onSaved = () => {
    setIsFetching(false);
    handleRefetch();
    setNotification({
      show: true,
      notification: 'Enabled variants successfully updated.',
      severity: 'Confirmation',
    });
  };

  const [toggleVariations] = useMutation(
    PRODUCT_CATALOG_ORG_SETTINGS_FILTER_TOGGLE,
    {
      onCompleted: onSaved,
      refetchQueries: [
        {
          query: GET_PRODUCT_CATALOG_PRODUCTS,
          variables: { orgId },
        },
      ],
      onError: onSaveError,
    },
  );
  const enableVariations = () => {
    typewriter.buttonPressed({
      button_text: 'Enable All Variations',
      page_or_screen_name: EventNames.settings.materialsList.page,
      primary_cta: false,
      ...commonTrackingProps,
    });
    setIsFetching(true);
    toggleVariations({
      variables: {
        orgId,
        enable: true,
        productIds: selectedMaterials,
      },
    });
  };
  const disableVariations = () => {
    typewriter.buttonPressed({
      button_text: 'Disable All Variations',
      page_or_screen_name: EventNames.settings.materialsList.page,
      primary_cta: false,
      ...commonTrackingProps,
    });
    setIsFetching(true);
    toggleVariations({
      variables: {
        orgId,
        enable: false,
        productIds: selectedMaterials,
      },
    });
  };

  if (products?.length === 0) {
    return (
      <Box padding={400}>
        <Heading size={200}>No items to show</Heading>
      </Box>
    );
  }
  return (
    <Box testId="ItemsList" flexDirection="column">
      <ListHead
        allChecked={allChecked}
        isIndeterminate={isIndeterminate}
        handleSelectAllNone={handleSelectAllNone}
        enableAllVariations={enableVariations}
        disableAllVariations={disableVariations}
      />
      <Box
        flexDirection="column"
        overflowY="auto"
        maxHeight={
          isProductFilteringEnabled // Additional offset required when "Enable filtering" notification is visible.
            ? 'calc(100vh - 295px)'
            : 'calc(100vh - 295px - 74px - 16px)'
        }
      >
        {itemsGroupedByProvider.map(([providerName, items]) => {
          return (
            <Box flexDirection="column" key={providerName}>
              <Box
                paddingTop={300}
                paddingLeft={300}
                data-testid="manufacturerHeading"
              >
                <Heading size={200}>{providerName}</Heading>
              </Box>
              <CheckboxGroup
                onChange={handleCheckboxChange}
                value={selectedMaterials}
                defaultValue={[]}
              >
                {items.map((product) => {
                  if (!product) return null;
                  const {
                    id,
                    name,
                    variationsCount,
                    orgVariationsCount,
                    productCatalogCategories,
                  } = product as ProductType;
                  return (
                    <ListBody
                      key={id}
                      name={name}
                      variationsCount={variationsCount}
                      orgVariationsCount={orgVariationsCount}
                      id={id}
                      categories={productCatalogCategories}
                    />
                  );
                })}
              </CheckboxGroup>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

interface ContentProps {
  searchTerm?: string | null;
  products: ProductType[];
  isFetching: boolean;
  hasError: boolean;
  isProductFilteringEnabled: boolean;
  handleRefetch: () => void;
  setIsFetching: (fetching: boolean) => void;
}

const Content: React.FC<ContentProps & NotificationHandlers> = ({
  searchTerm,
  products,
  isFetching,
  hasError,
  isProductFilteringEnabled,
  setIsFetching,
  setNotification,
  handleRefetch,
}) => {
  if (hasError) return <Error />;
  if (isFetching) return <Loading />;
  if (!!searchTerm && products?.length < 1)
    return <NoResultsFound searchTerm={searchTerm} />;
  return (
    <ItemsList
      products={products}
      isProductFilteringEnabled={isProductFilteringEnabled}
      setIsFetching={setIsFetching}
      setNotification={setNotification}
      handleRefetch={handleRefetch}
    />
  );
};

export const MaterialList: React.FC<NotificationHandlers> = ({
  setNotification,
}) => {
  const orgId = useSelector(getUserOrgId);
  const orgSettings = useSelector(getOrgSettings);
  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [showProductsLoader, setShowProductsLoader] = useState<boolean>(false);

  const [productsToRender, setProductsToRender] = useState<ProductType[]>([]);

  const {
    data: productsData,
    loading: isGetProductsLoading,
    error: getProductsError,
    fetchMore: fetchMoreProducts,
  } = useQuery(GET_PRODUCT_CATALOG_PRODUCTS, {
    variables: { orgId, filterByOrg: true },
  });

  const [pageInfo, setPageInfo] = useState<PageInfoType | null>(null);
  const [cursor, setCursor] = useState<string | null>(null);

  const [products, setProducts] = useState<ProductType[]>([]);
  useEffect(() => {
    if (!isGetProductsLoading && !getProductsError) {
      setProducts(productsData?.productCatalogProducts?.nodes);
      setPageInfo(productsData?.productCatalogProducts?.pageInfo);
      setCursor(productsData?.productCatalogProducts?.pageInfo?.endCursor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsData]);

  if (!isNil(getProductsError)) {
    Sentry.captureException(getProductsError);
  }

  const [filteredProducts, setFilteredProducts] = useState<ProductType[]>([]);

  const fetchCombinedLineItems = useCallback(async () => {
    setIsFetching?.(true);
    const { data, errors } = await getCombinedEstimationConfigLineItems(orgId);
    if (!isNil(errors)) {
      Sentry.captureException(errors[0]);
    }
    const mappedProducts = data
      .map((product) => product?.product as ProductType)
      .filter((product) => !!product);
    setFilteredProducts?.(mappedProducts);
    setIsFetching?.(false);
  }, [orgId]);

  useEffect(() => {
    fetchCombinedLineItems();
  }, [fetchCombinedLineItems]);

  useEffect(() => {
    typewriter.pageViewed({
      page_or_screen_name: EventNames.settings.materialsList.page,
      ...commonTrackingProps,
    });
    // Runs once on page load for Segment tracking.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isFiltered, setIsFiltered] = useState(true);

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    // Segment tracking.
    typewriter.optionSelected({
      option_type: 'list',
      selection: e.currentTarget.options[e.currentTarget.selectedIndex].text,
      page_or_screen_name: EventNames.settings.materialsList.page,
      primary_cta: false,
      options: 'Line Items',
      ...commonTrackingProps,
    });

    setIsFiltered(e.target.value === 'lineItems');
  };

  const { updateParams, searchParams, removeParam } = useSearchParams();
  const search = searchParams.get('search');

  const searchVariables = {
    orgId,
    searchTerm: search,
    distributorIds: [],
    distributionBranchIds: [],
  };

  const {
    data: searchProductsData,
    loading: isProductSearchLoading,
    refetch: refetchSearchProducts,
  } = useQuery(PRODUCT_CATALOG_PRODUCT_SEARCH_V2, {
    fetchPolicy: 'no-cache',
    skip: isNil(search) || search === '',
    variables: searchVariables, // default variables hydrated from url via hook
  });

  const onSearchSubmit = (updatedSearch: string) => {
    // sync url
    updateParams({
      search: updatedSearch,
      before: undefined,
      after: undefined,
    });
    // update list items
    refetchSearchProducts({ searchTerm: updatedSearch });
  };

  const onSearchClear = () => {
    removeParam('search');
  };

  useEffect(() => {
    if (!!search) {
      const searchData =
        searchProductsData?.productCatalogProductsSearch?.nodes;
      setProductsToRender(searchData);

      setShowProductsLoader(false);
    } else if (isFiltered) {
      setProductsToRender(filteredProducts);
      setShowProductsLoader(false);
    } else {
      setProductsToRender(products);
      if (pageInfo?.hasNextPage) setShowProductsLoader(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFiltered,
    products,
    filteredProducts,
    searchProductsData,
    pageInfo?.hasNextPage,
  ]);

  const handleRefetch = () => {
    fetchCombinedLineItems();
    refetchSearchProducts();
  };

  const formattedCount = numeral(productsToRender?.length).format('0,0');

  useEffect(() => {
    if (pageInfo?.hasNextPage) {
      fetchMoreProducts({
        variables: {
          orgId,
          after: cursor,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }).then((res: ApolloQueryResult<any>) => {
        const response = res?.data?.productCatalogProducts;
        setPageInfo(response?.pageInfo);
        setProducts([...products, ...response?.nodes]);
        setCursor(response?.pageInfo?.endCursor);
      });
    } else {
      setShowProductsLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursor]);

  return (
    <>
      <nav style={{ height: '25px' }} />
      <Box flexDirection="column" width={1} data-testid="materialListContainer">
        <Box justifyContent="space-between" alignItems="center" my={400}>
          <Box alignItems="center">
            <Heading marginBottom={0} size={650}>
              Materials:
            </Heading>
            <Tag
              data-testid="backgroundCircle"
              marginLeft="200"
              borderRadius="full"
              backgroundColor="neutral.200"
            >
              {formattedCount}
            </Tag>
          </Box>
          <Box flexDirection="row" justifyContent="end" alignItems="center">
            <Link
              target="_blank"
              href="https://help.hover.to/en/articles/6550714-self-service-materials "
              fontWeight="bold"
              marginRight={500}
            >
              Learn more
            </Link>
            {orgSettings && (
              <VariantsFiltering
                isProductFilteringEnabled={orgSettings?.productFilteringEnabled}
              />
            )}
          </Box>
        </Box>
        {orgSettings && (
          <EnableVariantsFiltering
            isProductFilteringEnabled={orgSettings?.productFilteringEnabled}
          />
        )}
        <Box justifyContent="space-between">
          <Select
            defaultValue="lineItems"
            flex="0 0 150px"
            onChange={handleSelect}
            marginBottom={500}
            data-testid="productsFilter"
          >
            <option value="all">All</option>
            <option value="lineItems">Line items</option>
          </Select>
          <Search
            initialValue={search || ''}
            onSubmit={onSearchSubmit}
            onClear={onSearchClear}
          />
        </Box>
        <Box flexDirection="column">
          <Content
            searchTerm={search}
            products={productsToRender}
            hasError={!isNil(getProductsError)}
            isFetching={
              isFetching ||
              isGetProductsLoading ||
              isProductSearchLoading ||
              showProductsLoader
            }
            isProductFilteringEnabled={
              orgSettings?.productFilteringEnabled ?? false
            }
            setIsFetching={setIsFetching}
            setNotification={setNotification}
            handleRefetch={handleRefetch}
          />
        </Box>
      </Box>
    </>
  );
};
