import { useCallback, useMemo } from 'react';

import { Box, BoxProps, Heading, Icon, Link } from '@hover/blueprint';
import { iChevronRight } from '@hover/icons';
import { useSelector } from 'react-redux';
import { useLocation, useParams, Link as RouterLink } from 'react-router-dom';

import {
  DistributionAuthenticationConnectionStrategyEnum,
  DistributionOrderStateEnum,
} from 'src/api/graphql-global-types';
import { useTracking } from 'src/hooks';
import { getOrgIdParam, getUserTrackingProps } from 'src/redux/selectors';
import { EventNames } from 'src/types';

const MATERIAL_SELECTION_PATH = '/scope';
const ORDER_DETAILS_PATH = '/detail';
const CHECKOUT_PATH = '/checkout';
const CONFIRMATION_PATH = '/confirmation';

const DefaultStyle: React.CSSProperties = {
  color: 'neutral500',
  fontWeight: 400,
};
const ActiveStyle: React.CSSProperties = {
  color: 'primary900',
  fontWeight: 700,
  borderBottom: '3px solid black',
};

export const ProjectOrderNavbar = (props: BoxProps) => {
  const location = useLocation();
  const { jobId } = useParams();
  const orgId = useSelector(getOrgIdParam);

  const { useTypewriter, useCommonTrackingProps } = useTracking();
  const commonTrackingProps = useCommonTrackingProps();
  const typewriter = useTypewriter();

  const isActive = useCallback(
    (path: string) => {
      return location.pathname.includes(path);
    },
    [location.pathname],
  );

  const trackCancelLink = useCallback(() => {
    typewriter.linkPressed({
      link_location: 'Nav bar',
      link_text: 'Cancel Order',
      page_or_screen_name: isActive(CHECKOUT_PATH)
        ? EventNames.project.checkout.page
        : EventNames.project.orderDetails.page,
      job_id: jobId,
      primary_cta: false,
      ...commonTrackingProps,
    });
  }, [isActive, jobId, commonTrackingProps]);

  return (
    <Box
      data-testid="navbar-panel"
      height={70}
      minHeight="70px"
      boxShadow="0px 2px 8px rgba(0, 0, 0, .08);"
      justifyContent="space-between"
      marginBottom="6px"
      backgroundColor="neutral0"
      {...props}
    >
      <Box marginLeft={500} justifyContent="center" alignItems="center">
        <Heading
          data-testid="material-selection-heading"
          marginX={400}
          marginTop={100}
          size={200}
          sx={isActive(MATERIAL_SELECTION_PATH) ? ActiveStyle : DefaultStyle}
        >
          Material selection
        </Heading>
        <Icon icon={iChevronRight} color="secondary300" />
        <Heading
          data-testid={`order-heading-${
            isActive(ORDER_DETAILS_PATH) ? 'active' : 'inactive'
          }`}
          marginX={400}
          marginTop={100}
          size={200}
          sx={isActive(ORDER_DETAILS_PATH) ? ActiveStyle : DefaultStyle}
        >
          Order details
        </Heading>
        <Icon icon={iChevronRight} color="secondary300" />
        <Heading
          data-testid={`billingDelivery-heading-${
            isActive(CHECKOUT_PATH) ? 'active' : 'inactive'
          }`}
          marginX={400}
          marginTop={100}
          size={200}
          sx={isActive(CHECKOUT_PATH) ? ActiveStyle : DefaultStyle}
        >
          Billing & delivery
        </Heading>
        <Icon icon={iChevronRight} color="secondary300" />
        <Heading
          data-testid={`orderConfirmation-heading-${
            isActive(CONFIRMATION_PATH) ? 'active' : 'inactive'
          }`}
          marginX={400}
          marginTop={100}
          size={200}
          sx={isActive(CONFIRMATION_PATH) ? ActiveStyle : DefaultStyle}
        >
          Order Confirmation
        </Heading>
      </Box>
      <Box marginRight={500} alignItems="center">
        {!isActive(CONFIRMATION_PATH) && (
          <Link
            as={RouterLink}
            to={`/project/${jobId}/scope?orgId=${orgId}`}
            marginX={400}
            onClick={trackCancelLink}
          >
            Cancel order
          </Link>
        )}
      </Box>
    </Box>
  );
};
