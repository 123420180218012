export const COMMERCE_PROJECT_SCOPE = 'commerce_project_scope_p1';
export const DIRECT_TO_PRODUCTION =
  'growth-quantity-takeoff-direct-to-production';

export const COMMERCE_BRICK_AND_STONE_ESTIMATION =
  'commerce-brick-and-stone-estimation';

export const COMMERCE_INIT_ESTIMATOR_FROM_SUMMARY =
  'commerce-init-estimator-from-summary';

// Hyperion active commerce products
export const ACTIVE_COMMERCE_PRODUCT_ORDERING_ONLY = 'ordering_only';
export const ACTIVE_COMMERCE_PRODUCT_OUT_OF_THE_BOX_CONFIG =
  'out_of_the_box_configuration';

export const COMMERCE_PROJECT_ESTIMATOR = 'commerce-mixing-materials'; // was named this way originally

export const ESTIMATOR_WORKFLOW_DROPDOWN_IN_SELECT_QUESTIONS =
  'estimator_workflow_dropdown_in_select_questions';

export const COMMERCE_RESET_CHILD_INPUTS = 'commerce-reset-child-inputs';

export const WASTE_FACTOR_DEFAULT_VALUE = 'waste-factor-default-value';
